import React from "react";

import { Sidebar } from "react-admin";

import { withStyles } from "@material-ui/core/styles";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

const MurfySidebarStyle = (theme: { palette: { primary: { main: any } } }) => ({
  drawer: {
    background: theme.palette.primary.main,
    overflow: "auto",
  },
});

const MurfySideBar = ({ classes, ...otherProps }: { classes: CSSProperties }) => (
  <Sidebar {...otherProps} className={classes.drawer} />
);

export default withStyles(MurfySidebarStyle)(MurfySideBar);
