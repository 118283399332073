import React from "react";

import { AppBar } from "react-admin";

import { withStyles } from "@material-ui/core/styles";

const styles = {
  logoMurfy: {
    width: 72,
    height: 26,
  },
  spacer: {
    flex: 1,
  },
};

const MurfyAppBar = withStyles(styles)(({ classes, ...props }) => (
  <AppBar {...props} color="primary">
    <img
      alt="logo jaune Murfy"
      className={classes.logoMurfy}
      src="https://res.cloudinary.com/cobble/image/upload/v1/MASTER/Cobble%20Heroes/Homepage/Murfy-Jaune.png"
    />
    <span className={classes.spacer} />
  </AppBar>
));

export default MurfyAppBar;
