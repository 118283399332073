import get from "lodash/get";
import groupBy from "lodash/groupBy";
import map from "lodash/map";

export default function groupElementsByKeys(elementsArray, keys) {
  /**
   * Groups elements by keys
   * Inputs :
   *  - keys = [
   *    {keyName: 'firstKey', pathToKey: 'firstField'},
   *    {keyName: 'secondKey', pathToKey: 'secondField.subfield', isNumber: true}]
   *  - elementsToFormat = [
   *    {firstField: 'Première catégorie', secondField: {subfield: 1}, otherField: 'Pierrick'}
   *    {firstField: 'Première catégorie', secondField: {subfield: 1}, otherField: 'Jean Denis'},
   *    {firstField: 'Première catégorie', secondField: {subfield: 2}, otherField: 'Jacques Pierre'},
   *    {firstField: 'Première catégorie', secondField: {subfield: 2}, otherField: 'Marie Odile'},
   *    {firstField: 'Deuxième catégorie', secondField: {subfield: 1}, otherField: 'Marie Odile'}
   * ]
   *
   * WARNING: If your key is a number, you should add isNumber: true in the keys
   *
   * Output :
   *  {firstKey: 'Première catégorie', secondKey: 1, elements: [
   *    {firstField: 'Première catégorie', secondField: {subfield: 1}, otherField: 'Pierrick'}
   *    {firstField: 'Première catégorie', secondField: {subfield: 1}, otherField: 'Jean Denis'}
   *  ]}
   *  {firstKey: 'Première catégorie', secondKey: 2, elements: [
   *   {firstField: 'Première catégorie', secondField: {subfield: 2}, otherField: 'Jacques Pierre'},
   *   {firstField: 'Première catégorie', secondField: {subfield: 2}, otherField: 'Marie Odile'}
   *  ]}
   *  {firstKey: 'Deuxième catégorie', secondKey: 2, elements: [
   *    {firstField: 'Deuxième catégorie', secondField: {subfield: 1}, otherField: 'Marie Odile'}
   *  ]}
   */
  const elementsGroupedByKeys = groupBy(elementsArray, (element) =>
    keys.map((key) => get(element, `${key.pathToKey}`))
  );

  const formattedElementsGroups = map(elementsGroupedByKeys, (elements, stringifiedKeyValues) => {
    const splittedKeyValues = stringifiedKeyValues.split(",");

    let elementsGroup = { elements: elements };

    keys.forEach((key, index) => {
      elementsGroup = {
        ...elementsGroup,
        [key.keyName]: key.isNumber
          ? splittedKeyValues[index]
            ? parseInt(splittedKeyValues[index], 10)
            : null
          : splittedKeyValues[index],
      };
    });

    return elementsGroup;
  });
  return formattedElementsGroups;
}
