import React, { useEffect, useState } from "react";

import { FunctionField } from "react-admin";

import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const ListingStatusField = ({ record, source, label }) => {
  const classes = statusStyles();
  const [status, setStatus] = useState("offline");
  const getListingStatus = () => {
    if (status === "online") {
      return "En ligne";
    } else if (status === "sold") {
      return "Vendu";
    } else {
      return "Hors ligne";
    }
  };

  useEffect(() => {
    if (record.published_at !== null) {
      setStatus("online");
    } else if (record.sold_at !== null) {
      setStatus("sold");
    } else {
      setStatus("offline");
    }
  }, [record]);
  return (
    <Box display="flex">
      <FunctionField
        source={source}
        record={record}
        render={getListingStatus}
        label={label}
        className={`${classes[status]} ${classes.badge}`}
      />
    </Box>
  );
};

export default ListingStatusField;

const statusStyles = makeStyles((theme) => ({
  badge: {
    padding: "4px 8px",
    borderRadius: "4px",
  },
  sold: {
    color: "#81D89F",
    backgroundColor: "rgba(129, 216, 159, 0.15)",
  },
  offline: {
    color: "#858585",
    backgroundColor: "rgba(133, 133, 133, 0.15)",
  },
  online: {
    color: "#FEA877",
    backgroundColor: "rgba(254, 168, 119, 0.15)",
  },
}));
