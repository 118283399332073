import * as React from "react";

import { TopToolbar } from "react-admin";

import Box from "@material-ui/core/Box";
import CancelIcon from "@material-ui/icons/Cancel";

import ConvertToHomeRepair from "components/ConvertToHomeRepair";
import HomeRepairCovered from "components/HomeRepairCovered";
import CreateDelivery from "components/OrderShow/components/CreateDelivery/CreateDelivery";
import GenerateVoucher from "components/OrderShow/components/GenerateVoucher";
import OrderLineCancelButton from "components/OrderShow/components/OrderLine/OrderLineCancelButton";
import { CANCELLED, CANCELLED_REPUBLISHED } from "components/OrderShow/utils";

const OrderShowActions = ({ data }) => {
  if (!data) {
    return null;
  }

  const disabled = data.intent.state === CANCELLED;
  const orderLines =
    data.intent.transactions &&
    data.intent.transactions.filter((t) => ![CANCELLED, CANCELLED_REPUBLISHED].includes(t.state));

  return (
    <TopToolbar>
      <Box display="flex" alignItems="center" justifyContent="flexStart">
        <HomeRepairCovered postalCode={data.zipcode} />
        <GenerateVoucher orderId={data.id} />
        <ConvertToHomeRepair orderId={data.id} transactions={data.intent.transactions} />
        <CreateDelivery order={data} />
        <OrderLineCancelButton orderLines={orderLines} startIcon={<CancelIcon />} disabled={disabled}>
          Annuler tout
        </OrderLineCancelButton>
      </Box>
    </TopToolbar>
  );
};

export default OrderShowActions;
