import React, { Component } from "react";

import PropTypes from "prop-types";

import "./style.css";

export default class Button extends Component {
  static propTypes = {
    clickHandler: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    isButtonDisabled: PropTypes.bool,
  };

  static defaultProps = {
    backgroundColor: "#016bff",
    textColor: "#ffffff",
    isButtonDisabled: false,
  };

  render() {
    return (
      <button
        className={`buttonContainer ${this.props.isButtonDisabled ? "buttonDisabled" : ""}`}
        style={{ backgroundColor: this.props.backgroundColor, color: this.props.textColor }}
        onClick={this.props.clickHandler}
        disabled={this.props.isButtonDisabled}
      >
        {this.props.text}
      </button>
    );
  }
}
