export type Color =
  | "brown"
  | "dark"
  | "dark_blue"
  | "green"
  | "grey"
  | "light_blue"
  | "orange"
  | "purple"
  | "red"
  | "turquoise"
  | "yellow";

export const getHexColor = (color: Color): string => {
  switch (color) {
    case "grey":
      return "#858585";
    case "purple":
      return "#CB77FE";
    case "dark_blue":
      return "#4A55FE";
    case "orange":
      return "#FEA877";
    case "yellow":
      return "#F0BB00";
    case "light_blue":
      return "#4AC8FE";
    case "green":
      return "#81D89F";
    case "red":
      return "#F85B5B";
    case "dark":
      return "#FFFFFF";
    case "brown":
      return "#D69F4E";
    case "turquoise":
      return "#1A8E96";
  }
};
