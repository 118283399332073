import React, { useState } from "react";

import { SelectInput, SimpleForm, TabbedForm } from "react-admin";

import { Box, Typography, withStyles } from "@material-ui/core";

import { setProduct } from "murfy/pages/QuickBooking/module";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";

import dataProvider from "dataProvider";

import ProductCaracteristicsInputs from "components/ProductCaracteristicsInputs";
import ProductDescriptionInputs from "components/ProductDescriptionInputs";
import ProductLogisticInputs from "components/ProductLogisticInputs";
import ProductModelForm from "components/ProductModelForm/ProductModelForm";
import ProductSellingInputs from "components/ProductSellingInputs";
import ProductTab from "components/molecules/ProductTab";

import { storeProductAction } from "./actions/storeProduct";
import AutoSave from "./autoSave";

const FIELD_NAMES = {
  brand: "Marque",
  barcode: "Code-barres",
  market_selling_price: "Prix du marché (TTC)",
  selling_price_withtax: "Prix de vente TTC",
  appearance: "Apparence",
  model_reference: "Modèle",
};

export const PRODUCT_FUNCTIONAL_STATUS = [
  { id: "working", name: "Fonctionnel" },
  { id: "not_working", name: "Non fonctionnel" },
];

const getErrorMessage = (response) => {
  // Check 400 response for required fields
  if (response.status === 400) {
    const errors = Object.keys(response.data).map((key) => ({
      field: key,
      error: response.data[key],
    }));

    const missingFields = errors.filter((f) => f.error && f.error.indexOf("required") !== -1);
    if (missingFields.length > 0) {
      const missingFieldsText = missingFields
        .map((f) => (f.field in FIELD_NAMES ? FIELD_NAMES[f.field] : f.field))
        .join(", ");
      return `Les champs suivants doivent être remplis pour publier le produit : ${missingFieldsText}`;
    }

    if (errors.length > 0) {
      return "Les champs suivants sont invalides: " + errors.map((e) => `${e.field} (${e.error})`).join(", ");
    }
  }

  return null;
};

const FileProduct = ({
  record,
  classes,
  products,
  isWorkshopFile,
  getCustomerFileDetail,
  getWorkshopFile,
  idEmployee,
  murfyFile,
}) => {
  const [savingStatus, setSavingStatus] = useState("");
  const dispatch = useDispatch();

  const save = (difference, callback) => {
    dataProvider
      .update("products", {
        id: record.id,
        data: { ...difference, employee: idEmployee },
      })
      .then((response) => {
        if (response.status === 200) {
          if (!isWorkshopFile) {
            dispatch(storeProductAction(murfyFile, response.data, difference));
            getCustomerFileDetail(murfyFile.id);
          } else {
            // Updating workshop-file informations display by retriggering the get request
            getWorkshopFile(murfyFile.file_number);
          }
          setSavingStatus("Enregistrement...");
          setProduct(record.location);
        } else {
          toastr.error("La mise à jour a échoué", getErrorMessage(response), { timeOut: 10000 });
          setSavingStatus("Erreur d'enregistrement");
          callback();
        }
      });
  };

  const setStatus = (status) => {
    setSavingStatus(status);
  };

  const refreshWorkshopFileData = () => {
    getWorkshopFile(murfyFile.file_number);
  };

  const getProductType = (product) => {
    if (product.product_model && product.product_model.product_type) {
      return product.product_model.product_type.name;
    } else {
      return product.product_type;
    }
  };

  const getSubtitle = (product) => {
    let subtitle =
      product.product_model && product.product_model.product_type
        ? product.product_model.product_type.name
        : product.product_type;
    subtitle +=
      product.product_model && product.product_model.brand
        ? " " + product.product_model.brand.name
        : product.brand
        ? " " + product.brand
        : "";
    subtitle +=
      product.product_model && product.product_model.reference
        ? " " + product.product_model.reference
        : product.model_reference
        ? " " + product.model_reference
        : "";
    return subtitle;
  };

  const productType = products.find((product) => product.name === getProductType(record));
  return (
    <Box bgcolor="#ffffff" borderRadius="16px" p={4} mt={3} maxWidth="800px">
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle1">{getSubtitle(record)}</Typography>
          <Typography variant="caption" className={classes.productId}>
            {record.barcode}
          </Typography>
          {savingStatus && (
            <Typography variant="caption" className={classes.productId}>
              ({savingStatus})
            </Typography>
          )}
        </Box>
        {!isWorkshopFile && (
          <SimpleForm record={record} resource="products" redirect={false} toolbar={null} save={save}>
            <SelectInput
              label="État"
              source="functional_status"
              choices={PRODUCT_FUNCTIONAL_STATUS}
              onChange={(event) => save({ functional_status: event.target.value })}
            />
          </SimpleForm>
        )}
      </Box>
      {record.is_online && (
        <Box className={classes.productOnline}>
          Note : ce produit est publié sur le eCommerce, vous ne pouvez donc pas le modifier. Vous pouvez dé-publier ce
          produit dans l'onglet Ventes pour le modifier.
        </Box>
      )}
      <TabbedForm
        redirect={false}
        toolbar={<></>}
        onSubmit={() => true}
        record={record}
        initialValues={record}
        productsNomenclature={products}
        isWorkshopFile={isWorkshopFile}
        className={classes.customForm}
      >
        <ProductTab label="Identification & Panne">
          <ProductModelForm id={record.product_model} save={save} product={record} />
          <ProductDescriptionInputs
            refreshWorkshopFileData={refreshWorkshopFileData}
            tasks={murfyFile.tasks}
            isWorkshopFile={isWorkshopFile}
            save={save}
            productType={productType}
            productsNomenclature={products}
          />
          {!record.is_online && <AutoSave setStatus={setStatus} debounce={1000} save={save} />}
        </ProductTab>
        <ProductTab label="Caractéristiques">
          <ProductCaracteristicsInputs
            refreshWorkshopFileData={refreshWorkshopFileData}
            tasks={murfyFile.tasks}
            productsNomenclature={products}
            isWorkshopFile={isWorkshopFile}
          />
        </ProductTab>

        {/* loosing context of the form if not a direct child of TabbedForm */}
        {isWorkshopFile && (
          <ProductTab label="Logistique">
            <ProductLogisticInputs
              refreshWorkshopFileData={refreshWorkshopFileData}
              tasks={murfyFile.tasks}
              isWorkshopFile={isWorkshopFile}
              productId={record.id}
            />
          </ProductTab>
        )}
        {isWorkshopFile && (
          <ProductTab label="Vente">
            <ProductSellingInputs
              refreshWorkshopFileData={refreshWorkshopFileData}
              tasks={murfyFile.tasks}
              isWorkshopFile={isWorkshopFile}
              productsNomenclature={products}
              save={save}
            />
          </ProductTab>
        )}
      </TabbedForm>
    </Box>
  );
};

const stylesTab = {
  productId: {
    color: "#8795B3",
    marginLeft: "24px",
    fontSize: "15px",
  },
  toolbar: {
    backgroundColor: "white",
    float: "right",
  },
  cardStyle: {
    boxShadow: "none",
  },
  title: {
    color: "#2B3B5C",
    margin: "16px 0px",
    fontWeight: 600,
    fontSize: 13,
  },
  productOnline: {
    fontWeight: 600,
    fontSize: 11,
    marginTop: 10,
  },
  customForm: {
    "& > hr": {
      display: "none",
    },
    "& > div:first-child": {
      maxWidth: "max-content",
      minHeight: 40,
      height: 40,
      border: "1px solid #DBDFE8",
      boxSizing: "border-box",
      borderRadius: 12,
      "& > div > span": {
        display: "none",
      },
      "& > MuiTab": {
        width: 10,
      },
    },
  },
};

export default withStyles(stylesTab)(FileProduct);
