export const quoteDetailsStyles = {
  acceptedQuoteButtonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 24,
  },
  addQuoteButtonWrapper: {
    paddingTop: "24px",
  },
  newQuoteElementsContainer: {
    padding: "24px 24px 8px 24px",
    marginTop: 10,
    marginBottom: 10,
    border: "1px solid #F7B349",
    borderRadius: 10,
    textAlign: "left",
  },
  newElementsTitle: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: "120%",
    color: "#2B3B5C",
    marginBottom: 10,
  },
  buttonWrapper: {
    marginTop: 24,
    marginLeft: 26,
  },
  addNewQuoteButton: {
    color: "#2B3B5C",
    marginTop: "24px 32px 32px 0",
    boxShadow: "none",
  },
  editQuoteButton: {
    color: "#8795B3",
    background: "#fff",
    boxShadow: "none",
    marginLeft: 16,
  },
  cancelButton: {
    color: "#8795B3",
    cursor: "pointer",
    padding: "14px",
    margin: "13px",
    textTransform: "uppercase",
  },
  validateNewQuoteButton: {
    fontWeight: 600,
    lineHeight: "150%",
    backgroundColor: "#4A55FE",
    "&:hover": {
      backgroundColor: "#3b44cb",
    },
  },
  detailsWrapper: {
    display: "flex",
    flexDirection: "column",
    textAlign: "right",
  },
  createIcon: {
    marginLeft: "12px",
    fontSize: 16,
  },
  buttonPaiementReceived: {
    background: "#4A55FE",
    color: "#fff",
    boxShadow: "none",
    marginLeft: 16,
    "&:hover": {
      backgroundColor: "#3b44cb",
    },
  },
  alreadyPaid: {
    color: "#81D89F",
    display: "flex",
    justifyContent: "space-between",
    padding: "22px 8px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "140%",
  },
  toPay: {
    background: "#F4F3F8",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "space-between",
    padding: "22px 8px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "140%",
    color: "#2B3B5C",
  },
  toRePay: {
    background: "#F4F3F8",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "space-between",
    padding: "22px 8px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "140%",
    color: "#F85B5B",
  },
};

export const styledDatagrid = {
  table: {
    width: "590px",
  },
  headerRow: {
    display: "none",
  },
  expandIconCell: {
    display: "none",
  },
  rowCell: {
    background: "#FFFFFF",
    border: "none",
    "&:last-child": {
      padding: 0,
    },
  },
  row: {
    "&:first-child span:first-child": {
      borderTop: "none",
    },
  },
};
