export const VISIT_TYPES = [
  { value: "first_visit", label: "Premier passage" },
  { value: "reopening", label: "Réouverture" },
  { value: "sp_installation", label: "Pose de pièce" },
  { value: "reopening_sp_installation", label: "Réouverture et pose de pièce" },
  { value: "previ", label: "Previ" },
  { value: "other", label: "Autre" },
  { value: "administrative", label: "Administratif" },
];

export const VISIT_STATUSES = [
  { value: "intervention", label: "Intervention" },
  { value: "tbc", label: "TBC" },
  { value: "tbm", label: "TBM" },
  { value: "canceled", label: "Annulé" },
  { value: "done", label: "Terminée" },
];

export const ADMINISTRATIVE = "administrative";
