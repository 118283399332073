// React imports
import React, { useEffect, useState } from "react";

import { Box, Typography } from "@material-ui/core";
// Style imports
import { makeStyles } from "@material-ui/core/styles";
import CreditCardIcon from "@material-ui/icons/CreditCard";

import Moment from "moment";
import "moment/locale/fr";
import { toastr } from "react-redux-toastr";

import dataProvider from "dataProvider";

import CustomerFileTargetStyles from "pages/customerFiles/components/CustomerFileTargetStatesInput/CustomerFileTargetStyles";

// Component imports
import ChargeSetupCardModal from "../ChargeSetupCardModal/ChargeSetupCardModal";

const useStyles = makeStyles(CustomerFileTargetStyles);

export default function ChargeCustomerComponent({ customerFile, getCustomerFileDetail }) {
  const [isChargeSetupCardModalOpen, setIsChargeSetupCardModalOpen] = useState(false);
  const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
  const [isCardBeingCharged, setIsCardBeingCharged] = useState(false);
  const classes = useStyles();

  const chargeCard = (customerFileId, amount) => {
    setIsCardBeingCharged(true);
    const PaymentPayload = {
      data: {
        customer_file: customerFileId,
        amount: amount,
        payment_method: "credit_card_deferred",
        payment_nature: "payment",
        solution_reference: "stripe",
        payment_date: Moment().locale("fr").toISOString(),
      },
    };

    dataProvider.create("charge-setup-card", PaymentPayload).then(({ data, status }) => {
      if (status === 400) {
        if (data.error === "Amount to charge can't be 0") {
          toastr.error("Erreur", "le paiement a échoué car le montant à débiter est égal à 0");
        } else if (data.error === "Amount displayed and amount to charge are different") {
          toastr.error(
            "Erreur",
            "le paiement a échoué car le montant indiqué est différent de la balance comptable du dossier"
          );
        } else if (data.code === "stripe_customer_not_found") {
          toastr.error("Erreur", "le paiement a échoué car le client stripe n'a pas été retrouvé");
        } else if (data.code === "stripe_payment_method_not_found") {
          toastr.error("Erreur", "le paiement a échoué car carte associé au client n'a pas été retrouvée");
        } else {
          toastr.error(
            "Erreur",
            "le payment a échoué pour une raison lié au client (fonds insuffisants, etc...) /n" + data.message
          );
        }
      } else if (status === 500) {
        toastr.error(
          "Erreur",
          "problème technique (attention dans ce cas il est possible que le payement ait réussi) /n" + data.message
        );
      } else if (data.payment_date) {
        toastr.success("Bravo", "Le débit est effectué");
        handleCloseChargeSetupCardModal();
        setHasPaymentMethod(false);
        setIsChargeSetupCardModalOpen(false);
      } else {
        toastr.error("Erreur", data.message);
      }
      setIsCardBeingCharged(false);
      getCustomerFileDetail(customerFile.id);
    });
  };

  const handleCloseChargeSetupCardModal = () => {
    setIsChargeSetupCardModalOpen(false);
  };

  useEffect(() => {
    if (customerFile.id) {
      setHasPaymentMethod(customerFile.has_payplug_cards || customerFile.has_valid_stored_cards);
      if (customerFile.has_setup_intent) {
        dataProvider
          .getOne("customer-file", { id: String(customerFile.id) + "/setup-intent" })
          .then(({ data }) => {
            if (data.has_payment_method) {
              setHasPaymentMethod(true);
            }
          })
          .catch((e) => {
            console.error(e);
          });
      }
    }
  }, [
    customerFile.id,
    customerFile.has_payplug_cards,
    customerFile.has_valid_stored_cards,
    customerFile.has_setup_intent,
  ]);

  return (
    <>
      {hasPaymentMethod && (
        <Box
          width="fit-content"
          className={classes.actionButton}
          onClick={() => {
            setIsChargeSetupCardModalOpen(true);
          }}
        >
          <CreditCardIcon className="ico ico-small" />
          <Typography className={classes.body2} variant="body2">
            Débiter le client
          </Typography>
        </Box>
      )}
      {isChargeSetupCardModalOpen && (
        <ChargeSetupCardModal
          isOpen={isChargeSetupCardModalOpen}
          handleCloseChargeSetupCardModal={handleCloseChargeSetupCardModal}
          customerFileId={customerFile.id}
          chargeCard={chargeCard}
          disableButton={isCardBeingCharged}
        />
      )}
    </>
  );
}
