import React from "react";

import { CustomerFileTag as CustomerFileTagType } from "types/customer-file-tag";

import ColorChip from "components/atoms/ColorChip";

type CustomerFileTagProps = {
  tagId: number;
  customerFileTags: any;
};

const CustomerFileTag: React.FunctionComponent<CustomerFileTagProps> = ({ tagId, customerFileTags }) => {
  const tag: CustomerFileTagType = customerFileTags.find((tag: CustomerFileTagType) => tag.id === tagId);

  return tag && <ColorChip label={tag.text} hexColor={tag.color} />;
};

export default CustomerFileTag;
