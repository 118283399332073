import { downloadCSV } from "react-admin";

import moment from "moment";
import { unparse as convertToCSV } from "papaparse/papaparse.min";

export const objectToData = (data) => {
  var result = data;
  for (var key = 0; key < result.length; key++) {
    result[key].dossier_client = result[key].demand && result[key].demand.customer_file_number;
    result[key].sku_reference = result[key].sku_model && result[key].sku_model.sku_reference;
    result[key].reference_fournisseur = result[key].sku_model && result[key].sku_model.supplier_reference;
    result[key].designation = result[key].sku_model && result[key].sku_model.designation;
    result[key].emplacement = result[key].location && result[key].location.name;
    result[key].technicien = result[key].demand && result[key].demand.employee_firstname;
    result[key].nom_client = result[key].demand && result[key].demand.customer_name;
  }
  return result;
};

export const groupBy = (list) => {
  return list.reduce((result, currentValue) => {
    if ((currentValue.demand !== null) | currentValue.demand && currentValue.demand.employee_firstname !== "") {
      (result[currentValue.demand.employee_firstname] = result[currentValue.demand.employee_firstname] || []).push(
        currentValue
      );
    } else {
      if (!result.empty) {
        result.empty = [];
      }
      result.empty.push(currentValue);
    }
    return result;
  }, {});
};

function delay() {
  return new Promise((resolve) => setTimeout(resolve, 300));
}

async function delayedDownload(item, i, array) {
  await delay();
  downloader(Object.values(array)[i]);
}

async function processArrayDownloader(array) {
  let i = 0;
  for (const value of Object.keys(array)) {
    //eslint-disable-line
    await delayedDownload(value, i, array);
    i++;
  }
}

export const exporter = async (data) => {
  data = objectToData(data);
  overallDownloader(data);
  data = groupBy(data);
  processArrayDownloader(data);
};

export const downloader = (data) => {
  let repairman = data[0].technicien;
  if (!repairman) {
    repairman = "N/A";
  }
  const currentDate = moment(Date()).format("DD/MM/YYYY");
  const header = convertToCSV({ fields: [repairman, currentDate], data: [] }, { header: true });
  const spacer = convertToCSV({ fields: [""], data: [] }, { header: true });
  const csv = convertToCSV({
    data,
    fields: ["reference_fournisseur", "sku_reference", "designation", "dossier_client", "nom_client", "emplacement"],
  });
  downloadCSV(header + spacer + csv, repairman + "/" + currentDate);
};

export const overallDownloader = (data) => {
  const currentDate = moment(Date()).format("DD/MM/YYYY");
  const csv = convertToCSV({
    data,
    fields: ["dossier_client", "emplacement", "reference_fournisseur", "technicien", "designation"],
  });
  downloadCSV(csv, "Export/" + currentDate);
};
