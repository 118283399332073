import React, { Component } from "react";

import PropTypes from "prop-types";

import "./style.css";

export class SwitchButton extends Component {
  static propTypes = {
    activeValue: PropTypes.string.isRequired,
    onSwitch: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  };

  render() {
    return (
      <div
        className={`switch-button ${this.props.value === this.props.activeValue ? "active-switch" : "unactive-switch"}`}
        onClick={this.props.onSwitch}
      >
        {this.props.text}
      </div>
    );
  }
}

export default class Switch extends Component {
  static propTypes = {
    firstSwitch: PropTypes.shape({
      text: PropTypes.string.isRequired,
      activeValue: PropTypes.string.isRequired,
      onSwitch: PropTypes.func.isRequired,
    }),
    secondSwitch: PropTypes.shape({
      text: PropTypes.string.isRequired,
      activeValue: PropTypes.string.isRequired,
      onSwitch: PropTypes.func.isRequired,
    }),
    value: PropTypes.string.isRequired,
  };

  render() {
    return (
      <div className="switch-container">
        <SwitchButton
          activeValue={this.props.firstSwitch.activeValue}
          onSwitch={this.props.firstSwitch.onSwitch}
          text={this.props.firstSwitch.text}
          value={this.props.value}
        />
        <SwitchButton
          activeValue={this.props.secondSwitch.activeValue}
          onSwitch={this.props.secondSwitch.onSwitch}
          text={this.props.secondSwitch.text}
          value={this.props.value}
        />
      </div>
    );
  }
}
