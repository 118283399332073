import React from "react";

import { DateInput, Filter, ReferenceInput, SearchInput, SelectInput } from "react-admin";

import { Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

import theme from "customTheme";
import CustomFiltersTheme from "shared/themes/customFiltersTheme.js";

import { ORDER_STATES } from "components/OrderStateField";

import { MARKETPLACES, STORES } from "constants/store";

export default function OrdersFilter(props) {
  const { ...filterProps } = props;
  const classes = useStyles();

  return (
    <ThemeProvider theme={CustomFiltersTheme}>
      <Box>
        <Filter {...filterProps}>
          <SearchInput label="Information client" source="q_client" alwaysOn />
          <SearchInput label="Ref commande/produits" source="q_ref" alwaysOn />
          <SelectInput
            source="state"
            choices={ORDER_STATES.map((state) => {
              return { id: state.id, name: state.label };
            })}
            alwaysOn
            emptyText="Tout"
            InputProps={{ disableUnderline: true }}
            label="Statut"
          />
          <DateInput
            label="Date de livraison"
            source="date"
            alwaysOn
            options={{ clearable: true }}
            InputProps={{ disableUnderline: true }}
          />
          <DateInput
            label="Date de préparation"
            source="preparation_date"
            alwaysOn
            options={{ clearable: true }}
            InputProps={{ disableUnderline: true }}
          />
          <ReferenceInput
            label="Prestataire (dernier km)"
            reference="shipping-providers"
            source="last_mile_shipping_provider_id"
            alwaysOn
            allowEmpty
            emptyText="Tout"
            className={classes.customSelect}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <ReferenceInput
            label="Prestataire interrégional"
            reference="shipping-providers"
            source="interregional_shipping_provider_id"
            alwaysOn
            allowEmpty
            emptyText="Tout"
            className={classes.customSelect}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <SelectInput
            source="store"
            choices={STORES}
            alwaysOn
            emptyText="Tout"
            InputProps={{ disableUnderline: true }}
            label="Revendeur"
          />
          <SelectInput
            source="marketplace"
            choices={MARKETPLACES}
            alwaysOn
            emptyText="Tout"
            InputProps={{ disableUnderline: true }}
            label="Marketplace"
          />
        </Filter>
        {props.context !== "button" && (
          <Box mb={1} height={40} display="flex">
            <Box mb={2} mr={1}>
              <Button
                onClick={() =>
                  props.setFilters({
                    date: "",
                    interregional_shipping_provider_id: "",
                    last_mile_shipping_provider_id: "",
                    preparation_date: "",
                    q_client: "",
                    q_ref: "",
                    state: "",
                  })
                }
              >
                <CloseIcon />
                Réinitialiser les filtres
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
}

const useStyles = makeStyles(() => ({
  customSelect: {
    minWidth: theme.spacing(30),
  },
}));
