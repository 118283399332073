import { useQuery } from "react-admin";

import { CustomerFileState } from "types/customer-file-states";

export const useCustomerFileStates = (): { customerFileStates: CustomerFileState[] } => {
  const { data: customerFileStates } = useQuery({
    type: "getList",
    resource: "customer-file-states-unpaginated",
    payload: {
      sort: { field: "id", order: "DESC" },
    },
  }) as { data: CustomerFileState[] };
  return { customerFileStates };
};
