import { getSparePartPriceWithTax } from "pages/customerFiles/components/CustomerFileBillingQuotes/services/sparePartPriceCalculator.js";

import { getPricePretax } from "utils/price";

/**
 * Return list of quote newElements with spare parts prices recomputed based on the newQuantityToBeAdded
 * and on the type of the element added or removed (the change element).
 * Spare parts prices are only refreshed if the type of change element is 'spare_part'
 * @param {array} elementsList
 * @param {number} newQuantityToBeAdded
 * @param {string} changeElementType
 */
export function refreshSparePartsElementsPrices(elementsList, newQuantityToBeAdded, changeElementType) {
  const numberOfSparePartsElements = elementsList.filter((element) => element.element_type === "spare_part").length;
  return elementsList.map((quoteElement) => {
    if (changeElementType === "spare_part" && quoteElement.element_type === "spare_part") {
      const newPriceWithTaxInCents =
        getSparePartPriceWithTax(
          parseFloat(quoteElement.base_price / 100),
          newQuantityToBeAdded,
          numberOfSparePartsElements
        ) * 100;
      return {
        ...quoteElement,
        base_price: quoteElement.base_price,
        amount_withtax: newPriceWithTaxInCents,
        amount_pretax: getPricePretax(newPriceWithTaxInCents),
      };
    }
    return quoteElement;
  });
}
