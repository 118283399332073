import { styled } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

export default styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  marginTop: 0,
  marginBottom: "0px",
  fontSize: "32px",
  lineHeight: "110%",
  fontWeight: 500,
  letterSpacing: "-.05em",
}));
