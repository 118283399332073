import { connect } from "react-redux";

import TextEditionField from "./TextEditionField.component";

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(TextEditionField);
