import React from "react";

import { Box } from "@material-ui/core";

export default ({ color, ...props }: { color: string }) => {
  const getBgColor = () => {
    switch (color) {
      case "grey":
        return "#0000001f";
      default:
        return "#000000";
    }
  };

  return (
    <Box width="100%" {...props} mr={1} display="inline-block" height="1px" borderRadius={3} bgcolor={getBgColor()} />
  );
};
