import React from "react";

import { FunctionField } from "react-admin";

import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import CreateIcon from "@material-ui/icons/Create";

import TicketTagsList from "pages/customerFiles/components/CustomerFileRepairReports/RepairReportTagsList";

const tagStyle = {
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    paddingTop: "20px",
  },
  tagField: {
    border: 3,
    margin: "2px 2px 2px 0",
    lineHeight: "2",
    borderRadius: 5,
    color: "#fff",
    textAlign: "center",
    padding: "0 10px",
  },
  editButton: {
    margin: "0 0 0 20px",
    display: "flex",
    flexDirection: "horizontal",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
      userSelect: "none",
    },
  },
};

const TicketTagsInput = ({ canEdit = true, classes, choices, displaystyle = "", record, updateTicketDetail }) => {
  const patchTicketTags = (TicketId, tags) => updateTicketDetail(TicketId, { ticket_tags: tags });

  return (
    <div className={classes.root}>
      {choices.length > 0 &&
        record.ticket_tags.map((tagId) => {
          var tag = choices.find((tag) => tag.id === tagId);
          if (tag) {
            return (
              <FunctionField
                key={`${tag.id} - ${tag.text}`}
                variant="body1"
                record={record}
                style={{ ...tagStyle.tagField, backgroundColor: tag.color, display: displaystyle }}
                render={(record) => `${tag.text}`}
              />
            );
          } else {
            return null;
          }
        })}
      {canEdit ? (
        <div className={classes.editButton}>
          <CreateIcon />
          <TicketTagsList
            canEdit
            ticketId={record.id}
            ticketTags={record.ticket_tags}
            patchTicketTags={patchTicketTags}
            tagsList={choices}
          />
        </div>
      ) : null}
    </div>
  );
};

TicketTagsInput.propTypes = {
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      text: PropTypes.string,
      color: PropTypes.string,
    })
  ),
  record: PropTypes.any,
};

export default withStyles(tagStyle)(TicketTagsInput);
