import React, { useState } from "react";

import { Box } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { Transaction } from "types/transaction";

import { getWarrantyStatus } from "utils/warranty";

import {
  StyledButton,
  StyledCheckbox,
  StyledModal,
  StyledTableCell,
  StyledTableCellBold,
  StyledTableRow,
  SubTitle,
  Title,
} from "./StyledElements";

type ConvertHomeRepairModalProps = {
  isOpen: boolean;
  handleCloseModal: () => void;
  transactions: Transaction[];
  handleSubmit: (transactions_ids: number[]) => void;
};

const ConvertHomeRepairModal: React.FC<ConvertHomeRepairModalProps> = ({
  isOpen,
  handleCloseModal,
  transactions,
  handleSubmit,
}) => {
  const [selected, setSelected] = useState<number[]>([]);
  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const handleClick = (_: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  return (
    <StyledModal
      isOpen={isOpen}
      handleCloseModal={handleCloseModal}
      customStyle={{
        content: {
          padding: 0,
          top: "50%",
          left: "50%",
          transform: "translateX(-50%) translateY(-50%)",
          bottom: 0,
          width: "70%",
        },
      }}
    >
      <Box p={3}>
        <Title variant="h4">Cette commande contient plusieurs appareils</Title>
        <SubTitle>Sélectionnez le(s) appareil(s) pour le(s)quel(s) vous souhaitez créer une demande SAV</SubTitle>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell align="left" />
                <StyledTableCell align="left">Appareil</StyledTableCell>
                <StyledTableCell align="left">Marque</StyledTableCell>
                <StyledTableCell align="left">Référence</StyledTableCell>
                <StyledTableCell align="left">Date limite de garantie</StyledTableCell>
                <StyledTableCell align="left">Statut de la garantie </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.map((transaction) => {
                const isItemSelected = isSelected(transaction.id);
                const labelId = `enhanced-table-checkbox-${transaction.id}`;

                return (
                  <StyledTableRow
                    hover
                    onClick={(event) => handleClick(event, transaction.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={transaction.id}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <StyledCheckbox checked={isItemSelected} inputProps={{ "aria-labelledby": labelId }} />
                    </TableCell>
                    <StyledTableCell>{transaction.product.product_type}</StyledTableCell>
                    <StyledTableCell>{transaction.product.brand}</StyledTableCell>
                    <StyledTableCellBold>{transaction.product.barcode}</StyledTableCellBold>
                    <StyledTableCell>{transaction.warranty_end_date}</StyledTableCell>
                    <StyledTableCellBold>{getWarrantyStatus(transaction.warranty_end_date)}</StyledTableCellBold>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box mt={3} display="flex" justifyContent="center">
          <StyledButton
            variant="contained"
            color="secondary"
            onClick={() => handleSubmit(selected)}
            disabled={!selected.length}
          >
            Créer le dossier
          </StyledButton>
        </Box>
      </Box>
    </StyledModal>
  );
};

export default ConvertHomeRepairModal;
