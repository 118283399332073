import React from "react";

import { ImageField } from "react-admin";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  field: {
    opacity: (props) => (props.dimmed ? 0.3 : 1),
    "& > img": {
      maxWidth: "100%",
    },
  },
});

const ProductImageField = ({ record = {}, className = "", ...rest }) => {
  const uploadInProgress = record.rawFile && !record.uploadComplete;
  const classes = useStyles({ dimmed: uploadInProgress });
  const objectRecord = record.rawFile
    ? {
        url: record.undefined,
        rawFile: record.rawFile,
      }
    : {
        url: record,
      };

  return <ImageField record={objectRecord} source="url" className={`${className} ${classes.field}`} {...rest} />;
};

export default ProductImageField;
