import * as React from "react";

import { Box } from "@material-ui/core";

import { CustomerFile } from "types/customer-files";

import ColorChip from "components/atoms/ColorChip";

import { homeRepairPaletteArray } from "pages/customerFiles/components/CustomerFileBillingQuotes/services/utils";

type HomeRepairTagProps = {
  productId: number;
  customerFile: CustomerFile;
};

const HomeRepairTag = ({ productId, customerFile }: HomeRepairTagProps) => {
  const productObject = customerFile.products.find((product) => product.id === productId);

  if (!productObject) return null;

  const color = homeRepairPaletteArray[customerFile.products.findIndex((product) => product.id === productId)];
  const label = `${productObject.product_type} ${productObject.brand}`;
  return (
    <Box pb={1}>
      <ColorChip label={label} hexColor={color} />
    </Box>
  );
};

export default HomeRepairTag;
