import React, { Component } from "react";

import PropTypes from "prop-types";

import "./style.css";

export default class YoutubeVideoField extends Component {
  static propTypes = {
    title: PropTypes.string,
    youtubeVideoURL: PropTypes.string,
    onYoutubeVideoURLChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    displayTitle: true,
    title: "Youtube",
    youtubeVideoURL: "",
  };

  onYoutubeVideoURLChange = (event) => this.props.onYoutubeVideoURLChange(event.target.value);

  render() {
    return (
      <div className="youtube-video-field-container">
        {this.props.displayTitle && <p className="youtube-video-field-title">{this.props.title}</p>}
        <input
          className="youtube-video-input"
          placeholder="URL de la vidéo"
          value={this.props.youtubeVideoURL}
          onChange={this.onYoutubeVideoURLChange}
        />
      </div>
    );
  }
}
