export const PAYMENT = "payment";
export const REFUND = "refund";

// quote elements types
export const ADDITIONAL_PACKAGE = "additional_package";
export const BASIC_PACKAGE = "basic_package";
export const COMMERCIAL_GESTURE = "commercial_gesture";
export const CONSUMABLE = "consumable";
export const DISCOUNTED_PACKAGE = "discounted_package";
export const HERMETIC_UNIT = "hermetic_unit";
export const LEGACY_QUOTE = "legacy_quote";
export const NEW_PRODUCT = "additional_package";
export const REDUCTION_CODE = "reduction_code";
export const SPARE_PART = "spare_part";
export const SPARE_PART_STOCK = "spare_part_stock";
export const SPONSORSHIP_REWARD = "sponsorship_reward";
export const AFTER_SALES_PACKAGE = "after_sales_package";
export const REPAIR_BONUS = "repair_bonus";
export const VISIO_DISCOUNT = "visio_discount";

// package types names
export const BASIC_PACKAGE_NAME = "Forfait réparation à domicile";

// package price ids
export const ADDITIONAL_PACKAGE_PRICE_NAME = "murfy_repair_additional_package_price";
export const BASIC_PACKAGE_PRICE_NAME = "murfy_repair_package_price";

// hermetic unit price
export const HERMETIC_UNIT_PRICE = 12500;
export const SPONSORSHIP_REWARD_PRICE_WITH_TAX = -1000;
// visio discount price
export const VISIO_DISCOUNT_PRICE_WITHTAX = -3500;

// emails templates
export const NO_TEMPLATE = "no_template";
export const TEMPLATE_7 = "template7";

export const VAT_RATE = 0.2;
