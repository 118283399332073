export const listRepairReportLabel = [
  { id: "finished", name: "Terminé" },
  { id: "finished-stock", name: "Terminé stock" },
  { id: "finished-without-spare-part", name: "Terminé sans pièce" },
  { id: "TPP-previ", name: "TPP previ" },
  { id: "diag", name: "Diag" },
  { id: "good-diag", name: "Bon diag" },
  { id: "good-spare-part", name: "Bon PP" },
  { id: "wrong-diag", name: "Mauvais diag" },
  { id: "wrong-spare-part", name: "Mauvais PP" },
  { id: "quote-refused", name: "Refus devis" },
  { id: "wrong-command", name: "Mauvaise commande" },
  { id: "not-repaired", name: "Non réparable" },
  { id: "intervention-impossible", name: "Intervention impossible" },
  { id: "help-wanted", name: "Aide demandée" },
  { id: "CR-useless", name: "CR inutile" },
  { id: "superfluous-visit", name: "Passage superflu" },
];

export const newListRepairReportLabel = [
  { id: "finished", name: "Terminé" },
  { id: "diag", name: "Diag" },
  { id: "quote-refused", name: "Refus devis" },
  { id: "wrong-command", name: "Mauvaise commande" },
  { id: "not-repaired", name: "Non réparable" },
  { id: "intervention-impossible", name: "Intervention impossible" },
  { id: "help-wanted", name: "Aide demandée" },
  { id: "CR-useless", name: "CR inutile" },
  { id: "superfluous-visit", name: "Passage superflu" },
];

export const qualificationLabelAsOptions = newListRepairReportLabel.map((item) => ({
  value: item.id,
  label: item.name,
}));
