import axios from "axios";

export const cloudinaryUpload = async (key: string, file: { rawFile: any; uploadComplete: boolean }) => {
  // Deleted photos need an empty URL and not null
  if (file === null) {
    return { key, url: "" };
  }

  // Do not touch existing files
  if (!file.rawFile) {
    return { key, url: file };
  }

  const formData = new FormData();
  formData.append("file", file.rawFile);
  formData.append("upload_preset", process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET_NAME || "");

  const { data } = await axios.post(
    `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );

  // Mark file as uploaded
  file.uploadComplete = true;

  return { key, url: data.secure_url };
};
