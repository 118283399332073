export const SparePartListStyles = {
  container: {
    padding: "8px 48px",
  },
  delayField: {
    width: 10,
  },
  CustomerField: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  linkIcon: {
    marginLeft: 8,
    fontSize: 20,
    color: "#8795B3",
  },
};
