import { CREATE } from "react-admin";

export const createPayment = (dataPayment = {}, customerFile = {}, getPaymentListAndQuoteList) => ({
  type: "CRUD_CREATE",
  payload: { data: dataPayment },
  meta: {
    resource: "payment/paymentbilling",
    fetch: CREATE,
    redirectTo: false,
    onSuccess: {
      callback: ({ payload, requestPayload }) => {
        getPaymentListAndQuoteList(customerFile.id);
      },
    },
  },
});
