import React, { useState } from "react";

import { useCreate, useNotify, useRefresh } from "react-admin";

import { Button } from "@material-ui/core";

import SkuModelReferencesModal from "components/organisms/SkuModelReferencesModal";

import DemandItemStatus from "constants/demandItemStatus";

export default function ReorderDemandItem({ record }) {
  const [isReferencesModalOpen, setIsReferencesModalOpen] = useState(false);
  const [create] = useCreate(`murfy-erp/demand-item/${record.id}/reset`);
  const notify = useNotify();
  const refresh = useRefresh();

  const onSkuModelReferencesModalClose = () => {
    setIsReferencesModalOpen(false);
    refresh();
  };

  const shouldDisplayReorderButton = () =>
    [DemandItemStatus.PACKED, DemandItemStatus.LOSS_DECLARED].includes(record.status);

  const onClick = async () => {
    await create(
      {},
      {
        onSuccess: ({ data, status }) => {
          if (status === 500 || data.error) {
            const error = status ? `Erreur ${status}` : data.error;
            notify(`Impossible de recommander cette pièce: ${error}`, "error");
          } else if (data.status === DemandItemStatus.RESERVED) {
            notify("Une nouvelle pièce a été réservée", "success");
            refresh();
          } else if (data.status === DemandItemStatus.WAITING_FOR_SKU) {
            notify(`Demande en attente de réception d'une pièce: ${record.sku_model.sku_reference}`, "info");
            setIsReferencesModalOpen(true);
          }
        },
      }
    );
  };

  return (
    shouldDisplayReorderButton() && (
      <>
        <Button id="button-reorder" onClick={onClick}>
          Re-commander
        </Button>
        <SkuModelReferencesModal
          isOpen={isReferencesModalOpen}
          skuId={record.sku}
          designation={record.designation}
          onModalClose={onSkuModelReferencesModalClose}
          title="Commande de pièce requise"
        />
      </>
    )
  );
}
