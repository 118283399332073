import React from "react";

import { getNotRepairableReason } from "./utils";

const getFilenameShortened = (url) => {
  return url && url.substring(url.lastIndexOf("/") + 1);
};

const PrequalificationDetails = ({ record }) => (
  <>
    <div className="col-12">
      <span className="ticket-detail ticket-detail-key-bullet-point">Plaque signalétique : </span>
      <div className="col-12">
        <a
          className="ticket-detail ticket-detail-value"
          href={record.product.identification_plate}
          target="_blank"
          rel="noopener noreferrer"
        >
          {`${getFilenameShortened(record.product.identification_plate)}`}
        </a>
      </div>
    </div>
    <div className="col-12">
      <span className="ticket-detail ticket-detail-key-bullet-point">Symptomes : </span>
      <span className="ticket-detail ticket-detail-value"> &nbsp; {record.symptoms}</span>
    </div>
    <div className="col-12">
      <span className="ticket-detail ticket-detail-key-bullet-point">Diagnostic : </span>
      <span className="ticket-detail ticket-detail-value"> &nbsp; {record.diagnostic}</span>
    </div>
    <div className="col-12">
      <span className="ticket-detail ticket-detail-key-bullet-point">Infos complémentaires : </span>
      <span className="ticket-detail ticket-detail-value"> &nbsp; {record.additional_information}</span>
    </div>
    <div className="col-12">
      <span className="ticket-detail ticket-detail-key-bullet-point">Réparable : </span>
      <span className="ticket-detail ticket-detail-value"> &nbsp; {record.is_repairable ? "Oui" : "Non"}</span>
    </div>
    {!record.is_repairable && (
      <div className="col-12">
        <span className="ticket-detail ticket-detail-key-bullet-point">Raison de non réparablité : </span>
        <span className="ticket-detail ticket-detail-value">
          {" "}
          &nbsp; {getNotRepairableReason(record.irreparable_reason)}
        </span>
      </div>
    )}
    <div className="col-12">
      <span className="ticket-detail ticket-detail-key-bullet-point">Besoin d'une commande de pièces : </span>
      <span className="ticket-detail ticket-detail-value"> &nbsp; {record.need_skus ? "Oui" : "Non"}</span>
    </div>
    {record.need_skus && (
      <div className="col-12">
        <span className="ticket-detail ticket-detail-key-bullet-point">Pièce(s) à commander :</span>
        {record.sku_need.length > 0 && (
          <div className="col-12">
            {record.sku_need.map((part) => (
              <div key={part} className="col-12">
                <span className="ticket-detail ticket-detail-value">
                  {" "}
                  &nbsp;{`${part.designation}(ref :${part.reference})`}
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
    )}
  </>
);

export default PrequalificationDetails;
