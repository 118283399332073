import axios, { AxiosInstance, AxiosRequestConfig, Method } from "axios";

import { getAPIUrl } from "utils/networking";

const getToken = (): string | null => localStorage.getItem("token");

const axiosInstance: AxiosInstance = axios.create({
  baseURL: getAPIUrl(),
  headers: {
    "Content-Type": "application/json",
    Accept: "*/*",
  },
});

axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Token ${token}`;
  }
  return config;
});

axiosInstance.interceptors.response.use((response) => response);

const fetchData = async <T>(method: Method, url: string, body?: Record<string, unknown>): Promise<T> => {
  const response = await axiosInstance.request<T>({ method, url, data: body });
  return response.data;
};
export const get = <T>(url: string): Promise<T> => fetchData<T>("GET", url);

export const post = <T>(url: string, body: Record<string, unknown>): Promise<T> => fetchData<T>("POST", url, body);

export const put = <T>(url: string, body: Record<string, unknown>): Promise<T> => fetchData<T>("PUT", url, body);

export const destroy = <T>(url: string): Promise<T> => fetchData<T>("DELETE", url);
