import React from "react";

import { IconButton } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import OpenInNew from "@material-ui/icons/OpenInNew";

const CustomerFileLink = ({ fileNumber, isWorkshopFile }) => {
  const classes = useStyle();
  const link = isWorkshopFile ? "workshop-files" : "customer-files";
  return (
    <IconButton
      onClick={(e) => e.stopPropagation()}
      target="_blank"
      href={`/${link}/${fileNumber}`}
      rel="noreferrer noopener"
      className={classes.iconButton}
    >
      <Tooltip title={"Atteindre le dossier"}>
        <OpenInNew className={classes.icon} />
      </Tooltip>
    </IconButton>
  );
};

export default CustomerFileLink;

const useStyle = makeStyles((theme) => ({
  iconButton: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    marginLeft: 8,
  },
  icon: {
    fontSize: 20,
  },
}));
