import React from "react";

import { diff } from "deep-object-diff";
import { FormSpy } from "react-final-form";

export class AutoSave extends React.Component {
  constructor(props) {
    super(props);
    this.state = { values: props.values };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.values !== this.props.values) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(this.save, this.props.debounce);
      this.props.setStatus("Enregistré");
    }
  }

  save = async () => {
    if (this.promise) {
      await this.promise;
    }
    const { values, save, form } = this.props;

    const difference = diff(this.state.values, values);
    Object.keys(difference).forEach((key) => {
      // For nested fields (especially JSONField), we need to submit the entire data and not just updated fields
      if (typeof difference[key] === "object") {
        difference[key] = values[key];
      }
      // If a field was removed, return the name of the missing field as key and an empty string for its value
      if (difference[key] === undefined) {
        difference[key] = "";
      }
    });
    this.setState({ valuesBeforeDiff: this.state.values });

    if (Object.keys(difference).length) {
      // values have changed
      this.setState({ values });
      // call save method and pass reset function as second argument
      this.promise = save(difference, () =>
        form.change(Object.keys(difference)[0], this.state.valuesBeforeDiff[Object.keys(difference)[0]])
      );
      await this.promise;
      delete this.promise;
    }
  };

  render() {
    // This component doesn't have to render anything
    return <></>;
  }
}

// This is not the only way to accomplish auto-save, but it does let us:
// - Use built-in React lifecycle methods to listen for changes
// - Pass in debounce and save props nicely
export default (props) => <FormSpy {...props} subscription={{ values: true }} component={AutoSave} />;
