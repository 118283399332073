import { connect } from "react-redux";

import YoutubeVideoField from "./YoutubeVideoField.component";

function mapStateToProps() {
  return {};
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(YoutubeVideoField);
