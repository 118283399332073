import React, { useState } from "react";

import { Box, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { useTheme } from "@material-ui/core/styles";

import Modal from "components/Modal";

import { getNumberOfSparePartElements } from "pages/customerFiles/components/CustomerFileBillingQuotes/services/QuoteHelper.js";

import { useAswoSupplier } from "utils/api";

import {
  BASIC_PACKAGE,
  BASIC_PACKAGE_NAME,
  COMMERCIAL_GESTURE,
  HERMETIC_UNIT,
  HERMETIC_UNIT_PRICE,
  NEW_PRODUCT,
  REPAIR_BONUS,
  SPARE_PART,
  SPARE_PART_STOCK,
  SPONSORSHIP_REWARD,
  SPONSORSHIP_REWARD_PRICE_WITH_TAX,
  VISIO_DISCOUNT,
  VISIO_DISCOUNT_PRICE_WITHTAX,
} from "../../services/constants";
import { getPretaxPrice } from "../../services/utils";
import AddCommercialGesture from "../elementCreation/AddCommercialGesture";
import AddSparePart from "../elementCreation/AddSparePart";
import { ProductSelectionModal } from "../elementCreation/ProductSelectionModal";

export default function AddElementInQuote({
  addNewElementsInQuote,
  newElements,
  postalCode,
  actualPackageSelected,
  packageTypeList,
  productId,
}) {
  const [isElementModalOpen, setIsElementModalOpen] = useState(false);
  const [isCommercialGestureModalOpen, setIsCommercialGestureModalOpen] = useState(false);
  const [isNewProductModalOpen, setIsNewProductModalOpen] = useState(false);
  const [isStockInstallation, setIsStockInstallation] = useState(false);
  const [isSparePartModalOpen, setIsSparePartModalOpen] = useState();
  const [radioChoice, setRadioChoice] = useState(SPARE_PART);
  const aswoSupplier = useAswoSupplier();
  const theme = useTheme();

  const basicPackage = packageTypeList.find((element) => element.package_name === BASIC_PACKAGE_NAME);

  const DEFAULT_QUOTE_ELEMENTS = [
    { value: SPARE_PART, label: "Une pièce détachée" },
    { value: SPONSORSHIP_REWARD, label: "Une récompense pour parrainage" },
    { value: COMMERCIAL_GESTURE, label: "Un geste commercial" },
    { value: NEW_PRODUCT, label: "Un nouvel appareil" },
    { value: HERMETIC_UNIT, label: "Intervention sur unité hermétique" },
    { value: BASIC_PACKAGE, label: "Forfait - Fausse réouverture" },
    { value: REPAIR_BONUS, label: "Bonus réparation" },
  ];

  const REGULARIZATION_ELEMENTS = [
    { value: SPARE_PART_STOCK, label: "Déclarer une pose pièce du stock" },
    { value: VISIO_DISCOUNT, label: "Réduction RD à distance" },
  ];

  const handleValidateElement = (values) => {
    addNewElementsInQuote(values);
    setIsStockInstallation(false);
    setIsElementModalOpen(false);
  };

  const handleContinueClick = () => {
    if (radioChoice === COMMERCIAL_GESTURE) {
      setIsCommercialGestureModalOpen(true);
    } else if (radioChoice === SPARE_PART) {
      setIsSparePartModalOpen(true);
      setIsStockInstallation(false);
    } else if (radioChoice === SPARE_PART_STOCK) {
      setIsSparePartModalOpen(true);
      setIsStockInstallation(true);
    } else if (radioChoice === NEW_PRODUCT || (radioChoice === BASIC_PACKAGE && packageTypeList)) {
      setIsNewProductModalOpen(true);
    } else if (radioChoice === HERMETIC_UNIT) {
      handleValidateElement([
        {
          home_repair_product: productId,
          element_type: HERMETIC_UNIT,
          amount_pretax: getPretaxPrice(HERMETIC_UNIT_PRICE),
          amount_withtax: HERMETIC_UNIT_PRICE,
          quote_billing: null,
          admin_comments: null,
        },
      ]);
    } else if (radioChoice === SPONSORSHIP_REWARD) {
      handleValidateElement([
        {
          home_repair_product: productId,
          element_type: SPONSORSHIP_REWARD,
          amount_pretax: getPretaxPrice(SPONSORSHIP_REWARD_PRICE_WITH_TAX),
          amount_withtax: SPONSORSHIP_REWARD_PRICE_WITH_TAX,
        },
      ]);
    } else if (radioChoice === REPAIR_BONUS) {
      handleValidateElement([
        {
          home_repair_product: productId,
          element_type: REPAIR_BONUS,
          amount_pretax: 0,
          amount_withtax: 0,
        },
      ]);
    } else if (radioChoice === VISIO_DISCOUNT) {
      handleValidateElement([
        {
          home_repair_product: productId,
          element_type: VISIO_DISCOUNT,
          amount_pretax: getPretaxPrice(VISIO_DISCOUNT_PRICE_WITHTAX),
          amount_withtax: VISIO_DISCOUNT_PRICE_WITHTAX,
        },
      ]);
    }
  };

  return (
    <Box height={64} textAlign="right" pt={2}>
      <Box margin="auto 0px" color={theme.palette.secondary.honey}>
        <Button
          size="medium"
          id="add-element-to-quote"
          color="inherit"
          variant="text"
          onClick={() => setIsElementModalOpen(true)}
        >
          + Ajouter un élément au devis
        </Button>
      </Box>
      <Modal
        modalClassName="modal-small-content"
        isOpen={isElementModalOpen}
        handleCloseModal={() => setIsElementModalOpen(false)}
      >
        <Box textAlign="center" py={4}>
          <Typography variant="h2">Ajouter un élément au devis</Typography>
        </Box>
        <Box width={420}>
          <RadioGroup onChange={(event) => setRadioChoice(event.target.value)} value={radioChoice}>
            <Box border="1px solid #DBDFE8" borderRadius={8} p={2} mb={1}>
              <Box color={theme.palette.secondary.grey900} mb={2}>
                <Typography color="inherit" variant="subtitle2">
                  Je souhaite ajouter
                </Typography>
              </Box>
              {DEFAULT_QUOTE_ELEMENTS.map((quoteElement) => {
                return (
                  <FormControlLabel
                    key={quoteElement.value}
                    value={quoteElement.value}
                    control={<Radio />}
                    label={quoteElement.label}
                    color="secondary"
                    variant="dark"
                    disabled={quoteElement.disabled}
                  />
                );
              })}
            </Box>
            <Box border="1px solid #DBDFE8" borderRadius={8} p={2} mb={1}>
              <Box color={theme.palette.secondary.grey900} mb={2}>
                <Typography color="inherit" variant="subtitle2">
                  Régularisation
                </Typography>
              </Box>
              {REGULARIZATION_ELEMENTS.map((quoteElement) => {
                return (
                  <FormControlLabel
                    key={quoteElement.value}
                    value={quoteElement.value}
                    control={<Radio />}
                    label={quoteElement.label}
                    color="secondary"
                    variant="dark"
                    disabled={quoteElement.disabled}
                  />
                );
              })}
            </Box>
          </RadioGroup>
        </Box>
        <Box justifyContent="space-evenly" pt={5} display="flex">
          <Box>
            <Button id="continue-button" variant="contained" color="secondary" onClick={handleContinueClick}>
              Continuer
            </Button>
          </Box>
          <Box color={theme.palette.secondary.grey900} display="contents">
            <Button
              height="100%"
              id="cancel-button"
              color="inherit"
              variant="text"
              onClick={() => setIsElementModalOpen(false)}
            >
              Annuler
            </Button>
          </Box>
        </Box>
      </Modal>
      {isCommercialGestureModalOpen && (
        <AddCommercialGesture
          productId={productId}
          addCommercialGesture={handleValidateElement}
          handleCloseGesture={() => setIsCommercialGestureModalOpen(false)}
        />
      )}
      {isSparePartModalOpen && aswoSupplier && (
        <AddSparePart
          productId={productId}
          defaultSupplier={aswoSupplier}
          addSparePart={handleValidateElement} //ok
          newSparePartElementsCount={getNumberOfSparePartElements(newElements)}
          handleCloseSparePart={() => setIsSparePartModalOpen(false)}
          actualPackageSelected={actualPackageSelected}
          isStockInstallation={isStockInstallation}
        />
      )}
      {isNewProductModalOpen && (
        <ProductSelectionModal
          package_name={radioChoice === BASIC_PACKAGE ? basicPackage.package_name : ""}
          package_type_id={radioChoice === BASIC_PACKAGE ? basicPackage.id : ""}
          package_id={radioChoice}
          postalCode={postalCode}
          productId={productId}
          submit={handleValidateElement}
          onClose={() => setIsNewProductModalOpen(false)}
        />
      )}
    </Box>
  );
}
