import React from "react";

import { Box, Dialog, DialogContent, Theme, styled } from "@material-ui/core";

export type BaseModalProps = {
  children: React.ReactElement;
  isOpen: boolean;
  onClose: () => void;
  width?: "xs" | "md" | "lg" | "xl";
};

const StyledContent = styled(DialogContent)<Theme>(({ theme }) => ({
  padding: theme.spacing(3),
}));

const BaseModal: React.FC<BaseModalProps> = ({ children, isOpen, onClose, width = "md" }: BaseModalProps) => {
  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth={width}>
      <Box>
        <StyledContent>{children}</StyledContent>
      </Box>
    </Dialog>
  );
};

export default BaseModal;
