import { useQuery } from "@tanstack/react-query";
import { get } from "lib/http";
import { SparePart } from "types/spare-part";

export const useSparePart = (sparePartId: number) => {
  const getSparePartDetail = async () => {
    const url = `/murfy-erp/spare-parts/${sparePartId}/`;
    const sparePart: SparePart = await get(url);
    return sparePart;
  };

  const { data, isLoading, isError } = useQuery(["get-spare-part-detail", sparePartId], getSparePartDetail);

  return { data, isLoading, isError };
};
