import React, { Component } from "react";

import { PropTypes } from "prop-types";

import "./style.css";

const colorList = [
  "#26374C",
  "#0668F5",
  "#60BC4E",
  "#4C4D4C",
  "#50C2E5",
  "#6AE898",
  "#989A97",
  "#C376DF",
  "#F5AA48",
  "#EA5945",
  "#EE7ECD",
  "#F2D748",
];

export default class PostTagForm extends Component {
  static propTypes = {
    postTag: PropTypes.func.isRequired,
    isTagSuccessfullySubmitted: PropTypes.bool.isRequired,
  };

  constructor() {
    super();
    this.state = {
      colorTag: "",
      textTag: "",
      isPopupDisplayed: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isTagSuccessfullySubmitted && this.props.isTagSuccessfullySubmitted) {
      this.setState({
        colorTag: "",
        textTag: "",
      });
    }
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleColorTagChange = (color) => () => {
    this.setState({
      colorTag: color,
    });
  };

  handleTextTagChange = (event) => {
    this.setState({
      textTag: event.target.value,
    });
  };

  displayPopup = () => {
    this.setState({
      isPopupDisplayed: true,
    });
  };

  closePopup = () => {
    this.setState({
      isPopupDisplayed: false,
    });
  };

  handleClick = () => {
    this.props.postTag(this.state.colorTag, this.state.textTag);
  };

  handleClickOutside = (event) => this.popupRef && !this.popupRef.contains(event.target) && this.closePopup();

  renderColorPicker = () =>
    colorList.map((color) => (
      <div
        key={color}
        className="color-choice"
        style={{ backgroundColor: color }}
        onClick={this.handleColorTagChange(color)}
      >
        {color === this.state.colorTag && (
          <span id="checkIcon" aria-hidden="true" className="fal fa-check color-icon" />
        )}
      </div>
    ));

  render() {
    return (
      <div className="post-tag-form-container">
        <div className="add-icon">
          <i className="fa fa-plus-circle ico ico-popup" onClick={this.displayPopup} />
          <p onClick={this.displayPopup}> Ajouter une étiquette </p>
        </div>
        {this.state.isPopupDisplayed ? (
          <div
            className="popup-form"
            ref={(node) => {
              this.popupRef = node;
            }}
          >
            <div className="row">
              <div className="col-8">
                <div className="label-form">Couleurs :</div>
              </div>
              <div className="col-4">
                <span aria-hidden="true" className="close-icon" onClick={this.closePopup}>
                  {" "}
                  &times;{" "}
                </span>
              </div>
            </div>
            <div>{this.renderColorPicker()}</div>
            <div className="post-form-container">
              <div className="label-form">Nom :</div>
              <input
                key="texte"
                className="text-field"
                value={this.state.textTag}
                onChange={this.handleTextTagChange}
              />
              <button className="button-form-container" onClick={this.handleClick}>
                Créer
              </button>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
