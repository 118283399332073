import React, { useContext } from "react";

import { Employee } from "types/employees";

import { useEmployees } from "utils/api";

type EmployeesContextType = {
  employees: Employee[];
};

const EmployeesContext = React.createContext<EmployeesContextType>({} as EmployeesContextType);

type EmployeesProviderProps = {
  children: React.ReactNode;
};

export const EmployeesProvider: React.FunctionComponent<EmployeesProviderProps> = ({
  children,
}: EmployeesProviderProps) => {
  const employees = useEmployees();

  return <EmployeesContext.Provider value={{ employees: employees }}>{children}</EmployeesContext.Provider>;
};

export const useEmployeesContext: () => EmployeesContextType = () => useContext(EmployeesContext);
