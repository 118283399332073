import React from "react";

import { FileField, FileInput } from "react-admin";

import { withStyles } from "@material-ui/core";

const StyledFileInput = withStyles({
  root: {
    "& > div > div:first-child": {
      backgroundColor: "transparent",
      borderRadius: 16,
      width: "fit-content",
      textDecoration: "underline",
      fontSize: 12,
      fontWeight: 600,
      fontFamily: "Poppins, Roboto, Helvetica, Arial, sans-serif",
    },
    "& label": { display: "none" },
  },
})(FileInput);

const CommentAttachment = () => {
  return (
    <StyledFileInput
      source="attachments"
      label="Fichiers"
      placeholder="+ ajouter un document"
      accept="image/*,.pdf"
      multiple
    >
      <FileField source="attachments" title="title" />
    </StyledFileInput>
  );
};

export default CommentAttachment;
