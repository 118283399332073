import React, { Component } from "react";

import PropTypes from "prop-types";

import "./style.css";

export default class InputField extends Component {
  static propTypes = {
    isPassword: PropTypes.bool,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    maxLength: PropTypes.number,
  };

  render() {
    return (
      <div className="form-group">
        <label htmlFor={this.props.label} className="fieldLabel">
          {this.props.label} :
        </label>
        <input
          type={this.props.isPassword ? "password" : "text"}
          className="form-control"
          id={this.props.label}
          onChange={this.props.onChange}
          placeholder={this.props.placeholder}
          value={this.props.value}
          maxLength={this.props.maxLength}
        />
      </div>
    );
  }
}
