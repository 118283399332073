import { Button, Checkbox, TableCell, TableRow, Typography, styled } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import Modal from "components/Modal";

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "rgba(135, 149, 179, 0.1)",
    color: theme.palette.secondary.contrastText,
  },
  body: {
    fontSize: 13,
    color: theme.palette.secondary.contrastText,
  },
}))(TableCell);

export const StyledTableCellBold = withStyles((theme) => ({
  body: {
    fontSize: 13,
    fontWeight: 700,
    color: theme.palette.secondary.contrastText,
  },
}))(StyledTableCell);

export const StyledTableRow = styled(TableRow)({
  "&.Mui-selected": {
    background: "rgba(74, 85, 254, 0.05)",
  },
});

export const StyledCheckbox = styled(Checkbox)({
  "&.Mui-checked": {
    color: "#3C46DC",
  },
});

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  color: theme.palette.secondary.contrastText,
  fontWeight: 600,
  marginBottom: theme.spacing(2),
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(2),
  color: "#1B1E1C",
  fontWeight: 400,
  marginBottom: theme.spacing(3),
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  padding: "14px 24px",
}));

export const StyledModal = styled(Modal)({
  "& .modal-content-padding-container": {
    padding: 0,
    top: "50%",
    left: "50%",
    transform: "translateX(-50%)translateY(-50%)",
  },
});
