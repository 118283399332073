import React, { Component } from "react";

import { Datagrid, DateField, List, Pagination, TextField } from "react-admin";

import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import SkuDemandStatusField from "components/SkuDemandStatusField";
import { DesignationField } from "components/atoms/DesignationField.js";

import DelayField from "../DelayField/DelayField";
import DemandItemList from "../DemandItemList";
import { EditSkuDemand } from "../EditSkuDemand/index.js";
import { SkuDemandListFilter } from "../SkuDemandListFilter";
import { SkuDemandListStyles } from "./styles.js";

export class SkuDemandList extends Component {
  static propTypes = {
    classes: PropTypes.any,
    employees: PropTypes.array,
    getAccount: PropTypes.any,
    getEmployees: PropTypes.func.isRequired,
    idEmployee: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      isEditionModalOpen: false,
      selectedDemand: {},
    };
  }

  componentDidMount() {
    this.props.getAccount();
    this.props.getEmployees();
  }

  openEditionModal = (id, basePath, record) => {
    this.setState({ isEditionModalOpen: true, selectedDemand: record });
  };

  handleCloseEditionModal = () => {
    this.setState({ isEditionModalOpen: false });
  };

  render() {
    const { classes, idEmployee } = this.props;
    const planners = this.props.employees.filter((employee) => employee.role === "planner");

    return (
      <>
        <List
          basePath=""
          resource="sku-demand"
          exporter={false}
          className={classes.container}
          bulkActionButtons={false}
          filters={<SkuDemandListFilter idEmployee={idEmployee} planners={planners} />}
          pagination={<Pagination rowsPerPageOptions={[25, 100, 250]} />}
          perPage={25}
          {...this.props}
        >
          <Datagrid expand={<DemandItemList assigned_employee={idEmployee} />} rowClick={this.openEditionModal}>
            <DesignationField label="Demande pour client" classes={classes} />
            <SkuDemandStatusField label="Statut" />
            <DateField source="updated_at" label="Mise à jour" className={classes.field} showTime locales="fr-FR" />
            <TextField source="employee_name" label="Technicien" className={classes.field} />
            <TextField source="workshop_name" label="Atelier" className={classes.field} />
            <DelayField label="Retard" />
          </Datagrid>
        </List>
        <EditSkuDemand
          isModalOpen={this.state.isEditionModalOpen}
          onCloseModal={this.handleCloseEditionModal}
          id={this.state.selectedDemand.id}
          record={this.state.selectedDemand}
        />
      </>
    );
  }
}

export default withStyles(SkuDemandListStyles)(SkuDemandList);
