import React, { useState } from "react";

import { useNotify } from "react-admin";

import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

import CancellationFeesModal from "pages/customerFiles/components/CancellationFeesModal";
import CustomerFileTargetStyles from "pages/customerFiles/components/CustomerFileTargetStatesInput/CustomerFileTargetStyles";

import { cancellationVisitWithFees } from "utils/api";

const useStyles = makeStyles(CustomerFileTargetStyles);

const CancellationVisitWithFees = ({ customerFile, getCustomerFileDetail }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const notify = useNotify();
  const classes = useStyles();

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmitCancellation = async (values) => {
    const { status } = await cancellationVisitWithFees(customerFile.uuid, {
      payload: { cancellation_fee: values.cancellation_fee_id },
    });
    if (status < 200 || status >= 300) {
      closeModal();
      notify("Une erreur est survenue lors de l'annulation", "warning");
    } else {
      getCustomerFileDetail(customerFile.id);
      closeModal();
      notify("Annulé avec succès");
    }
  };

  return (
    <>
      <Box
        width="fit-content"
        className={classes.actionButton}
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        <CloseIcon className="ico ico-small" />
        <Typography className={classes.body2} variant="body2">
          Annuler avec facturation
        </Typography>
      </Box>
      {isModalOpen && (
        <CancellationFeesModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          handleSubmitCancellation={handleSubmitCancellation}
          customerFileUuid={customerFile.uuid}
        />
      )}
    </>
  );
};

export default CancellationVisitWithFees;
