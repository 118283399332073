import { useQuery } from "@tanstack/react-query";
import { get } from "lib/http";
import { VisitPaymentIssue } from "types/payment-issue";

export const usePaymentIssue = (visitId: number) => {
  const fetchPaymentIssues = async (visitId: number): Promise<VisitPaymentIssue[]> => {
    return await get(
      `operations-data-provider/cobble-flow-read-api-proxy/payment_visitpaymentissue?select=id,reason,visit:murfy_erp_visit(id)&visit_id=eq.${visitId}`
    );
  };

  const { data, isLoading, isError } = useQuery<VisitPaymentIssue[]>(["visit-payment-issues", visitId], async () =>
    fetchPaymentIssues(visitId)
  );
  return { issue: data && data[0], isLoading, isError };
};
