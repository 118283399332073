/**
 * Return the sales price of a spare part, taxes included, for a given purchase cost, quantity and items in cart
 * The returned value is an integer as it is rounded up.
 * @param {integer} basePrice - the base selling price of the spare part
 * @param {integer} newQuantity - the quantity of spare parts to price
 * @param {integer} sparePartsAlreadyInCart - the number of spare parts that already belong to the cart
 */
export function getSparePartPriceWithTax(basePrice, newQuantity = 1, sparePartsAlreadyInCart = 0) {
  const quantityTotal = newQuantity + sparePartsAlreadyInCart;
  let sparePartPrice = basePrice;
  /*
   * Disable declining pricing when basePrice is less than (29.25 * 1.2)
   * to avoid negative prices.
   * This seems to only happen for second hand spare parts
   */
  if (basePrice >= 29.25 * 1.2 || quantityTotal <= 1) {
    sparePartPrice -= ((quantityTotal - 1) * 29.25 * 1.2) / quantityTotal;
  }
  return Math.ceil(sparePartPrice);
}

export function getSparePartBasePriceFromSparePartCost(sparePartCost) {
  let basePricePretax = 0;

  if (sparePartCost <= 3.54) {
    basePricePretax = 30.71 + sparePartCost * 1.05;
  } else if (3.54 < sparePartCost && sparePartCost < 15) {
    basePricePretax = (1.75 * sparePartCost) / 0.9 + 26.25;
  } else {
    basePricePretax = (1.08 * sparePartCost) / 0.9 + 38.12;
  }

  return basePricePretax * 1.2;
}
