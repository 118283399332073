import React from "react";

import { Box, styled } from "@material-ui/core";

import { useFormikContext } from "formik";
import { CustomerFileDetailed } from "types/customer-files";
import { Timeslot } from "types/timeslot";

import { Body } from "components/atoms/Typography";
import { CalendarContainer } from "components/organisms/Calendar";

const Container = styled(Box)(({ theme }) => ({
  borderRadius: 8,
  boxShadow: "0 0 3px 0 rgba(0, 0, 0, 0.08), 0 1px 40px 0 rgba(0, 0, 0, 0.08)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(2),
  minHeight: 272,
}));

type SuggestCalendarProps = {
  minimumCapacity: number;
  onSelect: (timeslot: Timeslot) => void;
  isSelected: (timeslot: Timeslot) => boolean;
  customerFile: CustomerFileDetailed;
};
type FormValues = {
  visit_type: string;
  employee: string | undefined;
};

const SuggestCalendar: React.FunctionComponent<SuggestCalendarProps> = ({
  customerFile,
  onSelect,
  minimumCapacity,
  isSelected,
}: SuggestCalendarProps) => {
  const { values } = useFormikContext<FormValues>();
  return (
    <Container>
      <>
        {values.visit_type ? (
          <>
            <CalendarContainer
              minimumCapacity={minimumCapacity}
              selectedRepairmanId={values.employee}
              onSelect={(timeslot: Timeslot) => onSelect(timeslot)}
              selectedType={values.visit_type}
              isSelected={(timeslot: Timeslot) => isSelected(timeslot)}
              customerFile={customerFile}
            />
          </>
        ) : (
          <>
            <Body> Veuillez renseigner les informations ci-dessus afin d'accéder</Body>
            <Body> aux créneaux disponibles </Body>
          </>
        )}
      </>
    </Container>
  );
};

export default SuggestCalendar;
