import React from "react";

import { styled } from "@material-ui/core";
import Box from "@material-ui/core/Box";

import { BodyBold } from "components/atoms/Typography";

const COLOR_WARNING = "#FFA500";
const COLOR_INFO = "#007BFF";
const COLOR_ERROR = "#FF0000";
const COLOR_DEFAULT = "#333333";
const COLOR_SUCCESS = "#548052";
const COLOR_WHITE = "#FFFFFF";
const COLOR_BLACK = "#000000";

type Warning = "warning";
type Info = "info";
type Default = "default";
type Error = "error";
type White = "white";
type Success = "success";

export type Variant = Warning | Info | Default | Error | White | Success;

type InformativeBlockProps = {
  title: string;
  content: React.ReactNode;
  icon?: React.ReactNode;
  variant?: Variant;
};

const colorMap: Record<Variant, string> = {
  warning: COLOR_WARNING,
  info: COLOR_INFO,
  error: COLOR_ERROR,
  default: COLOR_DEFAULT,
  success: COLOR_SUCCESS,
  white: COLOR_WHITE,
};

const getMappedColor = (variant: Variant) => `${colorMap[variant]}`;

const InformativeBlockContainer = styled(Box)<any, { variant: Variant }>(({ variant }) => ({
  display: "flex",
  alignItems: "center",
  padding: "8px 16px",
  borderRadius: "8px",
  backgroundColor: `${getMappedColor(variant)}35`,
  color: variant === "white" ? COLOR_BLACK : getMappedColor(variant),
}));

const IconContainer = styled(Box)({
  marginRight: "8px",
});

const TextContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

const InformativeBlock: React.FC<InformativeBlockProps> = ({ title, content, icon, variant = "default" }) => {
  return (
    <InformativeBlockContainer variant={variant}>
      {icon && <IconContainer>{icon}</IconContainer>}
      <TextContainer>
        <BodyBold>{title}</BodyBold>
        {content}
      </TextContainer>
    </InformativeBlockContainer>
  );
};

export default InformativeBlock;
