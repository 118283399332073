/**
 * Following the duck pattern, actions, constants and reducers are in the same file called module.js
 *
 * See: https://github.com/erikras/ducks-modular-redux
 *
 */
import { saveWidget } from "modules/generators/widgetGenerators";
import { call, put, takeLatest } from "redux-saga/effects";

import { ADMIN_CMS_GENERAL_INFORMATION_ROUTE, ADMIN_CMS_PRODUCT_ROUTE } from "constants/routes";

import request, { getTokenFromLocalStorage } from "utils/networking";

export const ADD_ARTICLE_TO_CATALOG = "CATALOG_EDITION/ADD_ARTICLE_TO_CATALOG";
export const REMOVE_ARTICLE_FROM_CATALOG = "CATALOG_EDITION/REMOVE_ARTICLE_FROM_CATALOG";

export const GET_CATALOG_REQUEST = "CATALOG_EDITION/GET_CATALOG_REQUEST";
export const GET_CATALOG_SUCCESS = "CATALOG_EDITION/GET_CATALOG_SUCCESS";
export const GET_CATALOG_ERROR = "CATALOG_EDITION/GET_CATALOG_ERROR";

export const SAVE_CATALOG_REQUEST = "CATALOG_EDITION/SAVE_CATALOG_REQUEST";
export const SAVE_CATALOG_SUCCESS = "CATALOG_EDITION/SAVE_CATALOG_SUCCESS";
export const SAVE_CATALOG_ERROR = "CATALOG_EDITION/SAVE_CATALOG_ERROR";

export const SET_CATALOG_FIELD = "CATALOG_EDITION/SET_CATALOG_FIELD";

export const INITIALIZE_NEW_CATALOG = "CATALOG_EDITION/INITIALIZE_NEW_CATALOG";

export function addArticleToCatalog(id, name) {
  return {
    type: ADD_ARTICLE_TO_CATALOG,
    payload: { id, name },
  };
}

export function removeArticleFromCatalog(id) {
  return {
    type: REMOVE_ARTICLE_FROM_CATALOG,
    payload: { id },
  };
}

export function getCatalogRequest(id) {
  return {
    type: GET_CATALOG_REQUEST,
    payload: { id },
  };
}

export function getCatalogSuccess(catalog) {
  return {
    type: GET_CATALOG_SUCCESS,
    payload: { catalog },
  };
}

export function getCatalogError(error) {
  return {
    type: GET_CATALOG_ERROR,
    payload: { error },
  };
}

export function saveCatalogRequest() {
  return {
    type: SAVE_CATALOG_REQUEST,
  };
}

export function saveCatalogSuccess(catalog) {
  return {
    type: SAVE_CATALOG_SUCCESS,
    payload: { catalog },
  };
}

export function saveCatalogError(error) {
  return {
    type: SAVE_CATALOG_ERROR,
    payload: { error },
  };
}

export function setCatalogField(fieldName, fieldValue) {
  return {
    type: SET_CATALOG_FIELD,
    payload: { fieldName, fieldValue },
  };
}

export function initializeNewCatalog() {
  return {
    type: INITIALIZE_NEW_CATALOG,
  };
}

export const getCatalogSelector = (state) => state.catalogEdition.catalog;

export const getCMSRedirectionRoute = (hasProduct) => {
  if (hasProduct) {
    return ADMIN_CMS_PRODUCT_ROUTE;
  } else {
    return ADMIN_CMS_GENERAL_INFORMATION_ROUTE;
  }
};

export function* getCatalog(action) {
  const token = yield call(getTokenFromLocalStorage);
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/widget/catalog/${action.payload.id}/`,
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  try {
    const catalog = yield call(request, config);
    yield put(getCatalogSuccess(catalog));
  } catch (error) {
    yield put(getCatalogError(error));
  }
}

export function* getAdminCatalogSaga() {
  yield takeLatest(GET_CATALOG_REQUEST, getCatalog);
}

export function* saveCatalogSaga() {
  const requestRoute = "catalog";
  const isMultiPartRequest = false;
  yield takeLatest(
    SAVE_CATALOG_REQUEST,
    saveWidget,
    requestRoute,
    isMultiPartRequest,
    getCatalogSelector,
    saveCatalogSuccess,
    saveCatalogError,
    null
  );
}

const initialState = {
  catalog: null,
  error: null,
  isEditingDirty: false,
};

/**
 * Following the duck pattern, the module.js file should export a reducer as a default function
 */
export function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_ARTICLE_TO_CATALOG:
      return {
        ...state,
        catalog: {
          ...state.catalog,
          articles: [...state.catalog.articles, { id: action.payload.id, name: action.payload.name }],
        },
      };
    case REMOVE_ARTICLE_FROM_CATALOG:
      return {
        ...state,
        catalog: {
          ...state.catalog,
          articles: state.catalog.articles.filter((article) => article.id !== action.payload.id),
        },
      };
    case GET_CATALOG_REQUEST:
      return {
        ...state,
        catalog: null,
        isEditingDirty: false,
      };
    case GET_CATALOG_SUCCESS:
      return {
        ...state,
        catalog: action.payload.catalog,
      };
    case GET_CATALOG_ERROR:
      return {
        ...state,
        error: action.payload.error,
      };
    case SAVE_CATALOG_REQUEST:
      return {
        ...state,
      };
    case SAVE_CATALOG_SUCCESS:
      return {
        ...state,
        isEditingDirty: false,
        catalog: action.payload.catalog,
      };
    case SAVE_CATALOG_ERROR:
      return {
        ...state,
        error: action.payload.error,
      };
    case SET_CATALOG_FIELD:
      return {
        ...state,
        catalog: {
          ...state.catalog,
          [action.payload.fieldName]: action.payload.fieldValue,
        },
        isEditingDirty: true,
      };
    case INITIALIZE_NEW_CATALOG:
      return {
        ...state,
        catalog: {
          articles: [],
        },
      };

    default:
      return state;
  }
}

export default reducer;
