/**
 * Following the duck pattern, actions, constants and reducers are in the same file called module.js
 *
 * See: https://github.com/erikras/ducks-modular-redux
 *
 */
import { getCompanyEmployeeSelector } from "admin/pages/Company/module";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import get from "lodash/get";
import random from "lodash/random";
import sortBy from "lodash/sortBy";
import { getEmployees } from "modules/generators/getEmployeesGenerator";
import { getForm } from "modules/generators/getFormGenerator";
import { getStates } from "modules/generators/getTicketsStatesGenerator";
import { saveWidget } from "modules/generators/widgetGenerators";
import { put, select, takeEvery, takeLatest } from "redux-saga/effects";

import GENERIC_FORM_FIELDS from "constants/genericFormFields";

export const GET_FORM_REQUEST = "FORM_EDITION/GET_FORM_REQUEST";
export const GET_FORM_SUCCESS = "FORM_EDITION/GET_FORM_SUCCESS";
export const GET_FORM_ERROR = "FORM_EDITION/GET_FORM_ERROR";

export const SAVE_FORM_REQUEST = "FORM_EDITION/SAVE_FORM_REQUEST";
export const SAVE_FORM_SUCCESS = "FORM_EDITION/SAVE_FORM_SUCCESS";
export const SAVE_FORM_ERROR = "FORM_EDITION/SAVE_FORM_ERROR";

export const INITIALIZE_NEW_FORM = "FORM_EDITION/INITIALIZE_NEW_FORM";
export const INITIALIZE_NEW_TICKET_CREATION_FORM = "FORM_EDITION/INITIALIZE_NEW_TICKET_CREATION_FORM";
export const INITIALIZE_NEW_MAIL_SENDER_FORM = "FORM_EDITION/INITIALIZE_NEW_MAIL_SENDER_FORM";

export const SET_FORM_FIELD = "FORM_EDITION/SET_FORM_FIELD";
export const SET_FORM_FIELD_FIELD = "FORM_EDITION/SET_FORM_FIELD_FIELD";

export const INCREASE_FORM_FIELD_ORDER = "FORM_EDITION/INCREASE_FORM_FIELD_ORDER";
export const DECREASE_FORM_FIELD_ORDER = "FORM_EDITION/DECREASE_FORM_FIELD_ORDER";

export const INITIALIZE_NEW_FORM_FIELD = "FORM_EDITION/INITIALIZE_NEW_FORM_FIELD";
export const REMOVE_FORM_FIELD = "FORM_EDITION/REMOVE_FORM_FIELD";

export const GET_STATES_REQUEST = "FORM_EDITION/GET_STATES_REQUEST";
export const GET_STATES_SUCCESS = "FORM_EDITION/GET_STATES_SUCCESS";
export const GET_STATES_ERROR = "FORM_EDITION/GET_STATES_ERROR";

export const GET_EMPLOYEES_REQUEST = "FORM_EDITION/GET_EMPLOYEES_REQUEST";
export const GET_EMPLOYEES_SUCCESS = "FORM_EDITION/GET_EMPLOYEES_SUCCESS";
export const GET_EMPLOYEES_ERROR = "FORM_EDITION/GET_EMPLOYEES_ERROR";

export function getFormRequest(id) {
  return {
    type: GET_FORM_REQUEST,
    payload: id,
  };
}

export function getFormSuccess(form) {
  return {
    type: GET_FORM_SUCCESS,
    payload: form,
  };
}

export function getFormError(error) {
  return {
    type: GET_FORM_ERROR,
    payload: error,
  };
}

export function setFormField(fieldName, fieldValue) {
  return {
    type: SET_FORM_FIELD,
    payload: { fieldName, fieldValue },
  };
}

export function initializeNewForm() {
  return {
    type: INITIALIZE_NEW_FORM,
  };
}

export function initializeNewTicketCreationForm() {
  return {
    type: INITIALIZE_NEW_TICKET_CREATION_FORM,
  };
}

export function initializeNewMailSenderForm() {
  return {
    type: INITIALIZE_NEW_MAIL_SENDER_FORM,
  };
}

export function setFormFieldField(formFieldId, fieldName, fieldValue) {
  return {
    type: SET_FORM_FIELD_FIELD,
    payload: { formFieldId, fieldName, fieldValue },
  };
}

export function increaseFormFieldOrder(formFieldId) {
  return {
    type: INCREASE_FORM_FIELD_ORDER,
    payload: formFieldId,
  };
}

export function decreaseFormFieldOrder(formFieldId) {
  return {
    type: DECREASE_FORM_FIELD_ORDER,
    payload: formFieldId,
  };
}

export function initializeNewFormField() {
  return {
    type: INITIALIZE_NEW_FORM_FIELD,
  };
}

export function removeFormField(formFieldId) {
  return {
    type: REMOVE_FORM_FIELD,
    payload: { formFieldId },
  };
}

export function saveFormRequest() {
  return {
    type: SAVE_FORM_REQUEST,
  };
}

export function saveFormSuccess(form) {
  return {
    type: SAVE_FORM_SUCCESS,
    payload: { form },
  };
}

export function saveFormError(error) {
  return {
    type: SAVE_FORM_ERROR,
    payload: { error },
  };
}

export function getStatesRequest() {
  return {
    type: GET_STATES_REQUEST,
  };
}

export function getStatesSuccess(states) {
  return {
    type: GET_STATES_SUCCESS,
    payload: states,
  };
}

export function getStatesError(error) {
  return {
    type: GET_STATES_ERROR,
    payload: error,
  };
}

export function getEmployeesRequest() {
  return {
    type: GET_EMPLOYEES_REQUEST,
  };
}

export function getEmployeesSuccess(employees) {
  return {
    type: GET_EMPLOYEES_SUCCESS,
    payload: employees,
  };
}

export function getEmployeesError(error) {
  return {
    type: GET_EMPLOYEES_ERROR,
    payload: error,
  };
}

export function getFormSelector(state) {
  return sanitizeFormFields(state.formEdition.form);
}

export function sanitizeFormFields(form) {
  form = {
    ...form,
    form_fields: form.form_fields.map((formField) => {
      if (formField.field_type === "file") {
        return { ...formField, name: "attachment" };
      } else {
        return formField;
      }
    }),
  };
  return form;
}

export function getFormFieldsSelector(state) {
  return state.formEdition.form && [...GENERIC_FORM_FIELDS, ...sortBy(state.formEdition.form.form_fields, "order")];
}

export function getStatesSelector(state) {
  return (
    state.formEdition.states &&
    sortBy(state.formEdition.states, "type.name").map((ticketState) => {
      return {
        value: ticketState.id,
        label: ticketState.type.name + " - " + ticketState.name,
      };
    })
  );
}

export function getEmployeesSelector(state) {
  return (
    state.formEdition.employees &&
    state.formEdition.employees.map((employee) => {
      return { value: employee.id, label: employee.user };
    })
  );
}

export function* initializeNewFormWithType(action) {
  const company = yield select(getCompanyEmployeeSelector);
  company.is_ticket_management_displayed
    ? yield put(initializeNewTicketCreationForm())
    : yield put(initializeNewMailSenderForm());
}

export function* getAdminFormSaga() {
  yield takeLatest(GET_FORM_REQUEST, getForm, getFormSuccess, getFormError);
}

export function* getFormEditionStatesSaga() {
  yield takeEvery(GET_STATES_REQUEST, getStates, getStatesSuccess, getStatesError);
}

export function* getFormEditionEmployeesSaga() {
  yield takeEvery(GET_EMPLOYEES_REQUEST, getEmployees, getEmployeesSuccess, getEmployeesError);
}

export function* initializeNewFormSaga() {
  yield takeEvery(INITIALIZE_NEW_FORM, initializeNewFormWithType);
}

export function* saveFormSaga() {
  const requestRoute = "form";
  const isMultiPartRequest = false;
  yield takeLatest(
    SAVE_FORM_REQUEST,
    saveWidget,
    requestRoute,
    isMultiPartRequest,
    getFormSelector,
    saveFormSuccess,
    saveFormError,
    null
  );
}

const initialState = {
  error: null,
  employees: null,
  form: null,
  isEditingDirty: false,
  states: null,
};

/**
 * Following the duck pattern, the module.js file should export a reducer as a default function
 */
export function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_FORM_REQUEST:
      return {
        ...state,
        form: null,
        isEditingDirty: false,
      };
    case GET_FORM_SUCCESS:
      return {
        ...initialState,
        form: action.payload,
      };
    case GET_FORM_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SAVE_FORM_SUCCESS:
      return {
        ...state,
        isEditingDirty: false,
        form: action.payload.form,
      };
    case SAVE_FORM_ERROR:
      return {
        ...state,
        error: action.payload.error,
      };
    case SET_FORM_FIELD:
      return {
        ...state,
        isEditingDirty: true,
        form: {
          ...state.form,
          [action.payload.fieldName]: action.payload.fieldValue,
        },
      };
    case INITIALIZE_NEW_TICKET_CREATION_FORM:
      return {
        ...state,
        editedTutorialSteps: [],
        isEditingDirty: true,
        form: {
          form_fields: [],
          form_type: "ticket_creation_form",
          responsible_employees: [],
          ticket_state: null,
        },
      };
    case INITIALIZE_NEW_MAIL_SENDER_FORM:
      return {
        ...state,
        editedTutorialSteps: [],
        isEditingDirty: true,
        form: {
          form_fields: [],
          form_type: "mail_sender_form",
        },
      };
    case SET_FORM_FIELD_FIELD:
      return {
        ...state,
        isEditingDirty: true,
        form: {
          ...state.form,
          form_fields: state.form.form_fields.map((formField) =>
            formField.id === action.payload.formFieldId
              ? { ...formField, [action.payload.fieldName]: action.payload.fieldValue }
              : formField
          ),
        },
      };
    case INITIALIZE_NEW_FORM_FIELD:
      const newFormFieldId = random(-100, 0);
      const order =
        state.form.form_fields.length > 0
          ? Math.max.apply(
              Math,
              state.form.form_fields.map((formField) => formField.order)
            ) + 1
          : 1;
      return {
        ...state,
        isEditingDirty: true,
        form: {
          ...state.form,
          form_fields: [
            ...state.form.form_fields,
            {
              id: newFormFieldId,
              order: order,
            },
          ],
        },
      };
    case REMOVE_FORM_FIELD:
      return {
        isEditingDirty: true,
        form: {
          ...state.form,
          form_fields: state.form.form_fields.filter((formField) => formField.id !== action.payload.formFieldId),
        },
      };
    case INCREASE_FORM_FIELD_ORDER:
      const formFieldIndex = findIndex(state.form.form_fields, { id: action.payload });
      const formFieldOrder = get(find(state.form.form_fields, { id: action.payload }), "order");
      const nextFormFieldOrder =
        get(state.form.form_fields[formFieldIndex + 1], "order") ||
        Math.max.apply(
          Math,
          state.tutoriel.formFields.map((formField) => formField.order)
        );
      return {
        ...state,
        form: {
          ...state.form,
          form_fields: sortBy(
            state.form.form_fields.map((formField, index) => {
              if (index === formFieldIndex + 1) {
                return { ...formField, order: formFieldOrder };
              } else if (index === formFieldIndex) {
                return { ...formField, order: nextFormFieldOrder };
              } else {
                return formField;
              }
            }),
            "order"
          ),
        },
      };
    case DECREASE_FORM_FIELD_ORDER:
      const _formFieldIndex = findIndex(state.form.form_fields, { id: action.payload });
      const _formFieldOrder = get(find(state.form.form_fields, { id: action.payload }), "order");
      const _previousFormFieldOrder = get(state.form.form_fields[_formFieldIndex - 1], "order") || 1;
      return {
        ...state,
        form: {
          ...state.form,
          form_fields: sortBy(
            state.form.form_fields.map((formField, index) => {
              if (index === _formFieldIndex - 1) {
                return { ...formField, order: _formFieldOrder };
              } else if (index === _formFieldIndex) {
                return { ...formField, order: _previousFormFieldOrder };
              } else {
                return formField;
              }
            }),
            "order"
          ),
        },
      };
    case GET_STATES_REQUEST:
      return {
        ...state,
        error: null,
      };
    case GET_STATES_SUCCESS:
      return {
        ...state,
        states: action.payload,
        error: null,
      };
    case GET_STATES_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case GET_EMPLOYEES_REQUEST:
      return {
        ...state,
        error: null,
      };
    case GET_EMPLOYEES_SUCCESS:
      return {
        ...state,
        employees: action.payload,
        error: null,
      };
    case GET_EMPLOYEES_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default reducer;
