export default class CustomerFileState {
  static get REPORT_TO_PROCESS() {
    return "CR à traiter";
  }
  static get CR_TO_CHECK() {
    return "CR à vérifier";
  }
  static get AWAITING_AFFECTATION() {
    return "En attente d'affectation";
  }
  static get AWAITING_SPARE_PART_ORDER() {
    return "En attente de commande de pièce(s)";
  }
  static get AWAITING_TIMESLOT_REOPENING() {
    return "En attente de creneau - réouverture";
  }
  static get PREQUAL_REPORT_TO_PROCESS() {
    return "CR de prequal à traiter";
  }
  static get REQUEST_FOR_PLANNING_AFTER_SALES() {
    return "Demande de planification-SAV";
  }
}
