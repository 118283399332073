import * as React from "react";

import {
  ArrayInput,
  BooleanInput,
  DateTimeInput,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  Toolbar,
  useNotify,
  useRedirect,
} from "react-admin";

import { Box, Typography, makeStyles } from "@material-ui/core";

import dataProvider from "dataProvider";

import { DELIVERY_ITEM_TYPES, DELIVERY_TYPE_OPTIONS } from "components/OrderShow/utils";
import InternationalPhoneInput from "components/atoms/PhoneInput/InternationalPhoneInput";

import { COLLECTION, RECYCLING } from "constants/shipping";

import { useDeliveryOptions, useProductBrands, useProductTypes } from "utils/api";

const isOrderAddressEditable = (order) => {
  return !["DELIVERED"].includes(order.state);
};

const OrderEditLayout = ({ record }) => {
  const redirect = useRedirect();
  const notify = useNotify();

  const classes = useStyle();
  const productTypes = useProductTypes();
  const productBrands = useProductBrands();
  const [zipcode, setZipcode] = React.useState(record.zipcode);
  const [deliveryType, setDeliveryType] = React.useState(record.type);
  const deliveryOptions = useDeliveryOptions(zipcode, record.store);

  const getDeliveryOptionFromType = (type) => {
    return deliveryOptions.find((option) => option.type === type);
  };

  const getDisplayableError = (errorObject) => {
    let errorArray = [];
    for (const property in errorObject) {
      errorArray.push(`${property}: ${errorObject[property]}`);
    }
    return errorArray.join(" - ");
  };

  const save = (data) => {
    const items = data.items
      .filter((item) => !!item)
      .map((item) => {
        return {
          id: item.id,
          delivery_type: item.delivery_type || "collection",
          product_type: item.product_type,
          product_brand: item.product_brand,
          product: item.product && item.product.id,
        };
      });
    const dataWithOption = getDeliveryOptionFromType(data.type)
      ? { ...data, delivery_option_id: getDeliveryOptionFromType(data.type).id }
      : data;

    dataProvider
      .update("ecom/orders", { id: data.id, data: { ...dataWithOption, items } })
      .then((response) => {
        if (response.status > 201) {
          notify(`Une erreur est survenue: ${getDisplayableError(response.data)}`, "error");
        } else {
          redirect("show", "/ecom/orders", data.id);
          notify("Commande mise à jour");
        }
      })
      .catch((e) => notify(`Une erreur est survenue ${e.message}`));
  };
  const isNotEditable = (scopedFormData) => {
    return scopedFormData && ![COLLECTION, RECYCLING].includes(scopedFormData.delivery_type);
  };

  const deliveryOptionNotAvailable =
    !deliveryOptions || !deliveryOptions.map((option) => option.type).includes(deliveryType);

  const validationSchema = (values) => {
    const errors = {};
    if (
      (values.phone && values.phone.replace(/ /g, "").includes("+33") && values.phone.replace(/ /g, "").length < 12) ||
      values.phone === "+"
    ) {
      errors.phone = "Numéro de téléphone invalide";
    }
    return errors;
  };

  return (
    <SimpleForm
      validate={validationSchema}
      record={record}
      className={classes.editForm}
      toolbar={<SaveToolbar />}
      save={save}
    >
      <Typography variant="h2">Commande {record.reference}</Typography>
      <TextInput source="note" label="Note" multiline rows={6} />
      <Typography variant="h2">Livraison</Typography>
      {isOrderAddressEditable(record) ? (
        <>
          <SelectInput
            source="type"
            optionText="label"
            label="Mode de livraison"
            choices={DELIVERY_TYPE_OPTIONS}
            onChange={(event) => setDeliveryType(event.target.value)}
          />
          {deliveryOptionNotAvailable && (
            <Typography className={classes.error}>
              l'option de livraison choisie n'est pas disponible dans cette zone
            </Typography>
          )}
          <Box display="flex" alignItems="end">
            <Box width="25%">
              <BooleanInput label="Ascenseur" source="elevator" alwaysOn helperText={null} />
            </Box>
            <NumberInput label="Étage" source="floor" alwaysOn />
          </Box>
          <Box display="flex" width="100%">
            <Box width="25%">
              <TextInput source="street_number" label="Numéro de rue" fullWidth />
            </Box>
            <Box width="75%" marginLeft="12px">
              <TextInput source="street" label="Nom de rue" fullWidth />
            </Box>
          </Box>
          <Box display="flex" width="100%">
            <Box width="25%">
              <TextInput
                source="zipcode"
                label="Code postal"
                fullWidth
                onChange={(event) => setZipcode(event.target.value)}
              />
            </Box>
            <Box width="75%" marginLeft="12px">
              <TextInput source="city" label="Ville" fullWidth />
            </Box>
          </Box>
          <Box mb={2}>
            <InternationalPhoneInput label={"Téléphone du client"} source="customer_phone" />
          </Box>
          <Box mb={2}>
            <InternationalPhoneInput label={"Téléphone à joindre à la livraison"} source="phone" />
          </Box>
          <Box display="flex" width="100%" paddingLeft="12px">
            <Box width="320px">
              <DateTimeInput
                source="time_span_beginning"
                label="Début du créneau"
                InputProps={{ disableUnderline: true }}
                fullWidth
              />
            </Box>
            <Box width="320px" marginLeft="24px">
              <DateTimeInput
                source="time_span_end"
                label="Fin du créneau"
                InputProps={{ disableUnderline: true }}
                fullWidth
              />
            </Box>
          </Box>
          <TextInput source="informations" label="Informations" multiline rows={3} />
          <TextInput source="difficulties" label="Difficultés" multiline rows={3} />

          <ReferenceInput
            label="Transporteur dernier km"
            reference="shipping-providers"
            source="last_mile_shipping_provider.id"
            alwaysOn
            fullWidth
          >
            <SelectInput optionText="name" />
          </ReferenceInput>

          <ReferenceInput
            label="Transporteur interrégional"
            reference="shipping-providers"
            source="interregional_shipping_provider.id"
            alwaysOn
            fullWidth
          >
            <SelectInput optionText="name" optionValue="id" />
          </ReferenceInput>

          <Typography variant="h2">Produits</Typography>
          <ArrayInput label="" source="items">
            <SimpleFormIterator>
              <FormDataConsumer>
                {({ scopedFormData, getSource }) => (
                  <>
                    <Box width={250}>
                      <SelectInput
                        label="Prestation"
                        source={getSource("delivery_type")}
                        choices={DELIVERY_ITEM_TYPES}
                        disabled={scopedFormData && isNotEditable(scopedFormData)}
                        fullWidth
                      />
                    </Box>
                    <Box width={250}>
                      <SelectInput
                        label="Type de produit"
                        source={getSource("product_type")}
                        choices={productTypes}
                        optionValue="name"
                        disabled={scopedFormData && isNotEditable(scopedFormData)}
                        fullWidth
                      />
                    </Box>
                    <Box width={250}>
                      <SelectInput
                        label="Marque"
                        source={getSource("product_brand")}
                        choices={productBrands}
                        optionValue="name"
                        disabled={scopedFormData && isNotEditable(scopedFormData)}
                        fullWidth
                      />
                    </Box>
                  </>
                )}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </>
      ) : (
        <div>Commande livrée : l'adresse de livraison ne peut plus être modifiée.</div>
      )}

      <Typography variant="h2">Facturation</Typography>
      <TextInput source="billing_name" label="Nom facturation" />
      <TextInput source="billing_street" label="Adresse facturation" />
      <TextInput source="billing_zipcode" label="Code postal facturation" />
      <TextInput source="billing_city" label="Ville facturation" />
    </SimpleForm>
  );
};

const SaveToolbar = (props) => {
  const classes = useStyle();
  return (
    <Toolbar {...props} className={classes.toolbar}>
      <SaveButton label="Modifier" />
    </Toolbar>
  );
};

const useStyle = makeStyles((theme) => ({
  editForm: {
    margin: theme.spacing(3),
    "& > div": {
      margin: 0,
      width: "auto",
    },
    "& > div > div > *": {
      width: "100%",
    },
  },
  error: {
    color: "red",
    marginBottom: 12,
  },
}));

export default OrderEditLayout;
