import { useQuery } from "@tanstack/react-query";
import { get } from "lib/http";

interface VisitPreviewUpdateQueryParams {
  beginning: string;
  end: string;
  employee: number | null;
  visitType: string;
  visitStatus: string;
  timeNeeded: boolean;
}

interface VisitPreviewDiffableAttributeType {
  diff: string;
  name: string;
  previous_value: string;
  new_value: string;
}
export const useVisitUpdatePreview = (visitId: number, queryParams: VisitPreviewUpdateQueryParams | null) => {
  const fetchVisitUpdatePreview = async (queryParams: VisitPreviewUpdateQueryParams) => {
    const baseUrl = `/murfy-erp/visit-update-preview/${visitId}/`;
    const searchParams = new URLSearchParams();

    for (const [key, value] of Object.entries(queryParams)) {
      if (value !== undefined && value !== null) {
        searchParams.append(key, value);
      }
    }

    const url = `${baseUrl}?${searchParams.toString()}`;
    const response: VisitPreviewDiffableAttributeType[] = await get(url);
    return response;
  };

  const { data, isLoading, error } = useQuery(
    ["visit-update-preview", queryParams],
    async ({ queryKey }) => {
      const params = queryKey[1];
      if (typeof params === "string" || params === null) {
        throw new Error("Invalid Query Key");
      }
      return fetchVisitUpdatePreview(params);
    },
    {
      enabled: !!queryParams,
    }
  );
  return { data, isLoading, error };
};
