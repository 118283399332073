import React from "react";

import { Box, Typography } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

import { ProductType } from "types/product_type";

import Modal from "components/Modal";
import Button from "components/atoms/Button";

import { useProductTypes } from "utils/api";
import { usePackagePrices } from "utils/hooks/useRepairPackagePrices";

import { ADDITIONAL_PACKAGE_PRICE_NAME, BASIC_PACKAGE, BASIC_PACKAGE_PRICE_NAME } from "../../services/constants";
import { getPretaxPrice } from "../../services/utils";

type ProductSelectionModalProps = {
  postalCode: string;
  package_name: string;
  package_id: string;
  package_type_id: number;
  onClose: () => void;
  submit: (element: any[]) => void;
  productId: number;
};

export const ProductSelectionModal = ({
  onClose,
  submit,
  postalCode,
  package_name,
  package_id,
  package_type_id,
  productId,
}: ProductSelectionModalProps) => {
  const productTypes = useProductTypes();
  const [productSelected, setProductSelected] = React.useState<string>("");
  const { data: repairPrices, loading } = usePackagePrices(postalCode as string, encodeURIComponent(productSelected));
  const packagePrice =
    repairPrices &&
    repairPrices[package_id === BASIC_PACKAGE ? BASIC_PACKAGE_PRICE_NAME : ADDITIONAL_PACKAGE_PRICE_NAME];

  const handleChangeProduct = (event: React.ChangeEvent<{ value: string }>) => {
    setProductSelected(event.target.value);
  };

  const handleValidate = () => {
    submit([
      {
        home_repair_product: productId,
        element_type: package_id,
        element_name: package_name,
        amount_withtax: packagePrice,
        amount_pretax: getPretaxPrice(packagePrice),
        product: productSelected,
        package_type: package_type_id,
      },
    ]);
    onClose();
  };

  return (
    <Modal id="new-product-modal" modalClassName={`modal-small-content`} isOpen handleCloseModal={onClose}>
      <Box p={3}>
        <Typography variant="h2">Choisir le type d'appareil</Typography>
        <Box p={2}>
          <RadioGroup onChange={handleChangeProduct} value={productSelected}>
            {productTypes &&
              productTypes.map((productType: ProductType) => (
                <FormControlLabel
                  key={productType.id}
                  value={productType.name}
                  control={<Radio />}
                  label={productType.name}
                  color="secondary"
                />
              ))}
          </RadioGroup>
        </Box>
        <Box justifyContent="space-between" display="flex">
          <Button version="invisible" onClick={onClose}>
            Annuler
          </Button>
          <Button disabled={loading || !productSelected} version="fancy" color="secondary" onClick={handleValidate}>
            Valider
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ProductSelectionModal;
