import React from "react";

import { DateInput, NumberInput, SelectInput, TextInput } from "react-admin";

import { Box } from "@material-ui/core";

const taxChoices = [
  { id: "standard_tax", name: "TVA 20%" },
  { id: "on_margin_tax", name: "TVA sur marge" },
];

const ProductReceptionInputs = (props) => {
  const getDeliveryNumber = () => {
    const dateOfTheDay = new Date().toISOString().slice(0, 10).split("-").join("");
    return `${dateOfTheDay.slice(2)}01`;
  };
  const productPublished = props.record && props.record.is_online;
  return (
    <>
      <Box display="flex" width="100%">
        <Box width="320px">
          <TextInput
            source="delivery_number"
            defaultValue={getDeliveryNumber()}
            label="N° de livraison"
            InputProps={{ disableUnderline: true, fullWidth: true }}
            disabled={productPublished}
            fullWidth
          />
        </Box>
        <Box width="320px" marginLeft="24px">
          <DateInput
            source="delivery_date"
            label="Date de réception"
            defaultValue={new Date().toISOString().slice(0, 10)}
            InputProps={{ disableUnderline: true }}
            disabled={productPublished}
            fullWidth
          />
        </Box>
      </Box>
      <Box display="flex" width="100%">
        <Box width="320px">
          <TextInput
            source="supplier"
            label="Fournisseur"
            InputProps={{ disableUnderline: true, fullWidth: true }}
            disabled={productPublished}
            fullWidth
          />
        </Box>
        <Box width="320px" marginLeft="24px">
          <TextInput
            source="client_name"
            label="Complément nom client"
            InputProps={{ disableUnderline: true }}
            disabled={productPublished}
            fullWidth
          />
        </Box>
      </Box>
      <Box display="flex" width="100%">
        <Box width="203px">
          <NumberInput
            source="buying_price_pretax"
            label="Prix d'achat"
            format={(v) => parseFloat(v).toFixed(2) / 100}
            parse={(v) => parseInt(v * 100)}
            InputProps={{ disableUnderline: true }}
            disabled={productPublished}
            fullWidth
          />
        </Box>
        <Box width="203px" marginLeft="24px">
          <NumberInput
            source="delivery_fee"
            label="Frais de livraison"
            format={(v) => parseFloat(v).toFixed(2) / 100}
            parse={(v) => parseInt(v * 100)}
            InputProps={{ disableUnderline: true }}
            disabled={productPublished}
            fullWidth
          />
        </Box>
        <Box width="203px" marginLeft="24px">
          <SelectInput
            source="supplier_tax"
            label="Taux de TVA"
            choices={taxChoices}
            disabled={productPublished}
            fullWidth
          />
        </Box>
      </Box>

      <Box display="flex" width="100%">
        <Box width="658px">
          <TextInput
            multiline
            source="delivery_comment"
            label="Commentaire"
            InputProps={{ disableUnderline: true }}
            disabled={productPublished}
            fullWidth
          />
        </Box>
      </Box>
    </>
  );
};

export default ProductReceptionInputs;
