const QuoteElementStyles = {
  detailDisplayField: {
    display: "flex",
    minHeight: 64,
    justifyContent: "space-between",
    padding: "8px 16px",
    "&:not(:last-child)": {
      borderBottom: "1px solid #DBDFE8",
    },
  },
  requiredActionField: {
    background: "#F4F3F8",
  },
  packageField: {
    color: "#8795B3",
    margin: "auto 0px",
  },
  actionIcon: {
    height: "auto",
    color: "#8795B3",
    marginLeft: 3,
  },
  actionsContainer: {
    display: "inline-flex",
    marginTop: "auto",
    marginBottom: "auto",
  },
  sparePartPriceInput: {
    width: 120,
  },
  externalLinkIcon: {
    marginLeft: 10,
    width: 16,
    height: 16,
    color: "#8795B3",
    cursor: "pointer",
  },
  avaibilityText: {
    display: "inline",
    fontSize: 11,
    lineHeight: "140%",
  },
};

export default QuoteElementStyles;
