import { getCompanyEmployeeSelector } from "admin/pages/Company/module";
import { sendAuthenticatedGetRequestToAPI } from "modules/generators/requestsToAPI";
import { all, call, select, takeEvery } from "redux-saga/effects";

export const GET_CALENDAR_TIMESLOTS_REQUEST = "Calendar/GET_CALENDAR_TIMESLOTS_REQUEST";
export const GET_CALENDAR_TIMESLOTS_3BY3_ERROR = "Calendar/GET_CALENDAR_TIMESLOTS_3BY3_ERROR";
export const GET_CALENDAR_TIMESLOTS_3BY3_SUCCESS = "Calendar/GET_CALENDAR_TIMESLOTS_3BY3_SUCCESS";
export const SET_FIRST_DISPLAYED_DAY = "Calendar/SET_FIRST_DISPLAYED_DAY";
export const SET_TIMESLOT = "Calendar/SET_TIMESLOT";
export const RESET_TIMESLOTS = "Calendar/RESET_TIMESLOTS";

export function getCalendarTimeslotsRequest(
  postalCode = null,
  beginningTimestamp = null,
  endTimestamp = null,
  city = null,
  street = null,
  streetNumber = null
) {
  return {
    type: GET_CALENDAR_TIMESLOTS_REQUEST,
    payload: { postalCode, beginningTimestamp, endTimestamp, city, street, streetNumber },
  };
}

export function getCalendarTimeslots3by3Success(timeslots) {
  return {
    type: GET_CALENDAR_TIMESLOTS_3BY3_SUCCESS,
    payload: timeslots,
  };
}

export function getCalendarTimeslots3by3Error(error) {
  return {
    type: GET_CALENDAR_TIMESLOTS_3BY3_ERROR,
    payload: error,
  };
}

export function setFirstDisplayedDay(timestamp) {
  return {
    type: SET_FIRST_DISPLAYED_DAY,
    payload: timestamp,
  };
}

/**
 * Authenticated request to fetch list of timeslots
 * @param {*} action
 */
export function* getCalendarTimeslotsAdmin(action) {
  const { postalCode, beginningTimestamp, endTimestamp, city, street, streetNumber } = action.payload;
  const company = yield select(getCompanyEmployeeSelector);
  var route = `/route-management/timeslots/?company=${company.id}&postal_code=${postalCode}&city=${city}&street=${street}&street_number=${streetNumber}`;
  if (beginningTimestamp) {
    route += `&beginning=${beginningTimestamp}`;
  }
  if (endTimestamp) {
    route += `&end=${endTimestamp}`;
  }

  yield call(sendAuthenticatedGetRequestToAPI, route, getCalendarTimeslots3by3Success, getCalendarTimeslots3by3Error);
}

export function setSelectedTimeslot(timeslot) {
  return {
    type: SET_TIMESLOT,
    payload: timeslot.id,
  };
}

export function resetTimeslots() {
  return {
    type: RESET_TIMESLOTS,
  };
}

export function* getCalendarTimeslotsSaga() {
  yield takeEvery(GET_CALENDAR_TIMESLOTS_REQUEST, getCalendarTimeslotsAdmin);
}

const initialState = {
  timeslots: [],
  firstDisplayedDay: 0,
  isCalendarLoading: false,
  nextTimeslot: null,
  selectedTimeslotId: null,
};

export function* calendarSagas() {
  yield all([getCalendarTimeslotsSaga()]);
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_CALENDAR_TIMESLOTS_REQUEST:
      return {
        ...state,
        error: null,
        isCalendarLoading: true,
      };
    case GET_CALENDAR_TIMESLOTS_3BY3_SUCCESS:
      return {
        ...state,
        timeslots: action.payload.timeslots,
        nextTimeslot: action.payload.next_timeslot,
        getTimeslotsSuccess: true,
        isCalendarLoading: false,
      };
    case GET_CALENDAR_TIMESLOTS_3BY3_ERROR:
      return {
        ...state,
        timeslots: [],
        error: action.payload,
        getTimeslotsSuccess: false,
        isCalendarLoading: false,
        nextTimeslot: null,
      };
    case SET_FIRST_DISPLAYED_DAY:
      return {
        ...state,
        firstDisplayedDay: action.payload,
      };
    case SET_TIMESLOT:
      return {
        ...state,
        selectedTimeslotId: action.payload,
      };
    case RESET_TIMESLOTS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

export default reducer;
