import React, { Component } from "react";

import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import QuoteDetails from "./QuoteDetails";
import QuoteHeader from "./QuoteHeader.component.js";

const newQuoteStyle = {
  newQuoteFormWrapper: {
    marginLeft: "54px",
    maxWidth: "672px",
    background: "#ffffff",
    padding: 32,
    borderRadius: 16,
  },
  headerNewQuoteForm: {
    marginBottom: 24,
  },
};

export class NewQuoteForm extends Component {
  static propTypes = {
    quote: PropTypes.object,
    classesQuoteList: PropTypes.object,
    handleDisplayNewQuoteForm: PropTypes.func,
    handleQuoteCreation: PropTypes.func,
    employees: PropTypes.array,
    visits: PropTypes.array,
    saveCurrentEditionSession: PropTypes.func,
    resetCurrentEditionSession: PropTypes.func,
    currentEditionSession: PropTypes.object,
    sparePartsOrders: PropTypes.array,
    hermeticUnitsOrders: PropTypes.array,
    customerFile: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      quote: this.props.quote,
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.newQuoteFormWrapper}>
        <div className={classes.headerNewQuoteForm}>
          <QuoteHeader record={this.props.quote} isDraft />
        </div>
        <QuoteDetails
          saveCurrentEditionSession={this.props.saveCurrentEditionSession}
          resetCurrentEditionSession={this.props.resetCurrentEditionSession}
          currentEditionSession={this.props.currentEditionSession}
          employees={this.props.employees}
          visits={this.props.visits}
          record={this.props.quote}
          isCreationQuote
          handleQuoteCreation={this.props.handleQuoteCreation}
          handleDisplayNewQuoteForm={this.props.handleDisplayNewQuoteForm}
          sparePartsOrders={this.props.sparePartsOrders}
          hermeticUnitsOrders={this.props.hermeticUnitsOrders}
          customerFile={this.props.customerFile}
        />
      </div>
    );
  }
}

export default withStyles(newQuoteStyle)(NewQuoteForm);
