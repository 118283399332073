import React from "react";

import WhatshotIcon from "@material-ui/icons/Whatshot";

export default () => (
  <WhatshotIcon
    style={{
      color: "#E95945",
      width: 24,
      height: 24,
    }}
  />
);
