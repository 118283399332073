import * as React from "react";

const CURRENCY_SYMBOL = "€";

const FormatPrice = ({ cents }) => {
  const price = parseInt(cents) / 100;
  if (isNaN(price)) {
    return <span>–&nbsp;{CURRENCY_SYMBOL}</span>;
  }
  return (
    <span>
      {price.toFixed(2).replace(".", ",")}&nbsp;{CURRENCY_SYMBOL}
    </span>
  );
};

export default FormatPrice;
