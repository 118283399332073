import { useQuery } from "@tanstack/react-query";
import { get } from "lib/http";
import { Diagnostic } from "types/diagnostic";

export const useProductDiagnosticHistory = (productId: number, visitId: number) => {
  const fetchDiagnosticHistory = async (productId: number, visitId: number): Promise<Diagnostic[]> => {
    return await get(
      `${process.env.REACT_APP_REPAIR_ASSISTANT_READ_API_URL}/diagnostic?select=*%2Cappliance_repair!diagnostic_appliance_repair_fkey!inner(home_repair_id%2Cproduct_id)&appliance_repair.product_id=eq.${productId}&context->>type=eq.visit&context->>id=eq.${visitId}&order=created_at`
    );
  };

  return useQuery<Diagnostic[]>(["diagnostic-history", productId, visitId], async () =>
    fetchDiagnosticHistory(productId, visitId)
  );
};
