import { connect } from "react-redux";

import CustomerFileQuoteBilling from "./CustomerFileBillingQuote.component";
import { resetCurrentEditionSession, saveCurrentEditionSession } from "./modules/module";

function mapStateToProps(state) {
  return {
    currentEditionSession: state.customerFileBillingQuote.editionSession,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveCurrentEditionSession(editionSession) {
      dispatch(saveCurrentEditionSession(editionSession));
    },
    resetCurrentEditionSession() {
      dispatch(resetCurrentEditionSession());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerFileQuoteBilling);
