import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const FancyButton = withStyles((theme: any) => ({
  root: {
    background: "linear-gradient(90deg, #F64F6E 0%, #FF8787 100%)",
    color: "white",
    boxShadow: "none",
  },
  disabled: {
    background: theme.palette.secondary.lightGrey,
    color: "white",
  },
}))(Button);

export default FancyButton;
