export const getResourceRoute = (resource: string): string =>
  ({
    account: "common/account",
    "account-balance": "payment/account-balance",
    "aswo-article": "sku/aswo/article",
    "base-attribute-values": "product/base-attribute-values",
    "billing-quotes": "payment/quotebilling",
    "billing-elements": "payment/quoteelement",
    "billing-detail": "payment/quotebilling",
    "cancellation-fees": "murfy-erp/customer-file",
    "charge-setup-card": "payment/paymentbilling/process",
    "closure-mail-template": "email/closure-mail-template",
    "collection-files": "murfy-erp/collection-files",
    comments: "common/comments",
    "comment-attachments": "common/comment-attachments",
    "customer-files": "murfy-erp/customer-files",
    "customer-file": "murfy-erp/customer-file",
    "customer-files-slim": "murfy-erp/customer-files/slim",
    "customer-file-states": "murfy-erp/customer-file-states",
    "customer-file-states-unpaginated": "murfy-erp/customer-file-states",
    "customer-files-to-process": "murfy-erp/customer-files",
    deliveries: "shipping/deliveries",
    "deliveries-csv": "shipping/deliveries/csv",
    "delivery-CCV": "ecom/delivery-orders",
    "delivery-notes": "shipping/delivery-notes",
    "delivery-options": "shipping/delivery-options",
    "demand-item": "murfy-erp/demand-item",
    "deprecated-spare-parts": "murfy-erp/spare-part",
    "email-actions": "email/mail-sending-task",
    "employee-availability": "route-management/employee-availability",
    employees: "common/employee",
    "fault-forecast": "murfy-erp/fault-forecast",
    "fault-forecast-report": "repairman-report/fault-forecast-report",
    "installation-types": "product/installation-types",
    location: "murfy-erp/location",
    "marketplace-export": "ecom/export",
    package: "murfy-erp/package",
    "package-types": "murfy-erp/package-types",
    "paymentbilling-history": "payment/paymentbilling-history",
    "payment-details": "payment/paymentdetail",
    "payment-intent-last": "payment/payment-intent-last",
    "product-brands": "product/brands",
    "product-models": "sku/product-models",
    "product-types": "product/product-types",
    "product-type-attributes": "product/product-type-attributes",
    products: "murfy-erp/products",
    "recondition-files": "murfy-erp/customer-files",
    refunds: "ecom/refunds",
    "repair-package-prices": "payment/murfy-repair-package-price",
    "repair-report-checks": "product/repair-report-checks",
    "send-next-intervention-planning-email": "email/send_next_intervention_planning_email",
    "send-temporisation-email": "email/send-temporisation-email",
    "shipping-providers": "shipping/shipping-providers",
    "shipping-timeslots": "timeslot/shipping-timeslots",
    skus: "sku/skus",
    "sku-demand": "murfy-erp/sku-demand",
    "sku-location": "murfy-erp/sku-location",
    "sku-log": "murfy-erp/sku-log",
    "sku-model": "murfy-erp/sku-model", // Deprecated SkuModel
    "sku-model-references": "sku/sku-model-references",
    "sku-models": "sku/sku-models",
    "sku-price-history": "sku/sku-price-history",
    "sku-selling-price": "sku/sku-selling-price",
    "sku-suppliers": "sku/suppliers",
    "sku-supplying": "sku/skus/supplying",
    "spare-parts": "murfy-erp/spare-parts",
    tickets: "tool/ticket",
    "ticket-states": "tool/ticket_state",
    "ticket-tags": "tool/ticket_tag",
    timeslot: "widget/timeslot/generic",
    "timeslot-booking": "widget/timeslot/timeslot-booking",
    "timeslot-details": "widget/timeslot/generic-details",
    "visit-preparations": "murfy-erp/visit-preparations",
    "package-price": "payment/package-price",
    visits: "murfy-erp/visit",
    workshop: "common/workshops",
    "workshop-tasks": "murfy-erp/workshop-tasks",
    "workshop-file-tags": "murfy-erp/workshop-file-tags",
    "workshop-files": "murfy-erp/workshop-files",
    "workshop-files-count": "murfy-erp/workshop-files/count",
    warranty: "ecom/warranty",
    "seven-opteam-deployed": "route-management/zone/seven-opteam-deployed",
    zone: "route-management/zone",
  }[resource] || resource);
