import { call, put } from "redux-saga/effects";

import { getAPIUrl, request } from "utils/networking";

export function* getForm(onSuccessAction, onErrorAction, action) {
  const config = {
    method: "get",
    url: `${getAPIUrl()}/widget/form/${action.payload}/`,
  };
  try {
    const form = yield call(request, config);
    yield put(onSuccessAction(form));
  } catch (error) {
    yield put(onErrorAction(error));
  }
}
