import React, { useState } from "react";

import { Box, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { Form, Formik } from "formik";
import { CustomerFileDetailed } from "types/customer-files";
import { Employee } from "types/employees";
import { Timeslot } from "types/timeslot";
import * as yup from "yup";

import Button from "components/atoms/Button";
import Loading from "components/atoms/Loading";
import { Body, H3 } from "components/atoms/Typography";
import BaseModal from "components/molecules/BaseModal";
import CalendarField from "components/molecules/CalendarField";
import EmployeeField from "components/molecules/EmployeeField";
import VisitTypeField from "components/molecules/VisitTypeField";

import { useRepairmen } from "utils/api";

import TimeNeededField from "./components/TimeNeededField";
import VisitStatusField from "./components/VisitStatusField";

type FormValues = {
  visit_type: string;
  visit_status: string;
  employee: string | undefined;
  time_needed: boolean;
  timeslot: Timeslot | undefined;
};

type AddVisitProps = {
  handleCreateVisit: (values: FormValues) => void;
  defaultRepairmanId: number | null;
  customerFile: CustomerFileDetailed;
};

const AddVisit: React.FunctionComponent<AddVisitProps> = ({
  handleCreateVisit,
  defaultRepairmanId = null,
  customerFile,
}: AddVisitProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const repairmen = useRepairmen();

  const getLastAssignedRepairmanId = (repairmanId: number | undefined) => {
    if (!repairmanId) return;
    const repairman =
      repairmen &&
      repairmen.find((repairman: Employee) => repairman.id === repairmanId && repairman.role === "repairman");
    if (!repairman) return;
    return repairman.id.toString();
  };
  const initialValues = {
    visit_type: "",
    visit_status: "",
    employee: (defaultRepairmanId && getLastAssignedRepairmanId(defaultRepairmanId)) || undefined,
    time_needed: false,
    timeslot: undefined,
  };

  const validationSchema = yup.object().shape({
    visit_type: yup.string().required("Le type de visite est obligatoire"),
    visit_status: yup.string().required("Le statut est obligatoire"),
    employee: yup.string().test({
      name: "employee",
      test: (value) => {
        return true;
      },
      message: "Le technicien est obligatoire",
    }),
    timeslot: yup.object().required("Un créneau doit être sélectionné"),
  });

  const handleSubmit = (values: FormValues) => {
    handleCreateVisit(values);
    setIsModalOpen(false);
  };

  return (
    <>
      <Button version="ghost" fullWidth onClick={() => setIsModalOpen(true)}>
        Créer un nouveau passage &nbsp; +
      </Button>
      <Box m={1} textAlign="end"></Box>
      <BaseModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          validateOnMount={true}
        >
          {({ isSubmitting, isValid }) => (
            <Form translate={null}>
              <Box display="flex" justifyContent="space-between">
                <H3>Informations</H3>
                <Box>
                  <IconButton onClick={() => setIsModalOpen(false)}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
              <Box m={2}>
                <VisitTypeField />
              </Box>
              <Box m={2}>
                <VisitStatusField />
              </Box>
              <Box m={2}>{repairmen ? <EmployeeField repairmen={repairmen} /> : <Loading />}</Box>
              <Box m={2}>
                <TimeNeededField />
              </Box>
              <H3>Choix du créneau</H3>
              <Box m={2}>
                <CalendarField customerFile={customerFile} />
              </Box>

              <Box m={2}>
                <Body color="red">
                  nb. Si le champ technicien n’est pas renseigné, alors 7opteam allouera l’intervention au technicien
                  qui lui semble être le plus pertinent
                </Body>
              </Box>

              <Button disabled={isSubmitting || !isValid} dimension="small" version="fancy" type="submit">
                Créer un nouveau passage
              </Button>
            </Form>
          )}
        </Formik>
      </BaseModal>
    </>
  );
};

export default AddVisit;
