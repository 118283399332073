import React from "react";

import { TextInput, useNotify } from "react-admin";

import { Box, Button } from "@material-ui/core";

import { useFormState } from "react-final-form";

import { updateProductModelGtin } from "utils/api";

const ProductModelGtinEditionField = () => {
  const notify = useNotify();
  const { values } = useFormState();

  const handleUpdateGtin = async () => {
    const response: any = await updateProductModelGtin(values);
    if (response.status > 200) {
      notify(`Une erreur est survenue: l'EAN n'a pas été mis à jour.`, "error");
    } else {
      notify("EAN mis à jour");
    }
  };
  return (
    <Box ml={1}>
      <TextInput source="gtin" label="EAN" />
      <Button style={{ marginLeft: 8, marginTop: 16, height: "fit-content" }} onClick={() => handleUpdateGtin()}>
        mettre à jour
      </Button>
    </Box>
  );
};
export default ProductModelGtinEditionField;
