import React, { useState } from "react";

import { Box, styled } from "@material-ui/core";

import { Attachment } from "types/diagnostic";

import BaseModal from "components/molecules/BaseModal";

const GalleryContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: theme.spacing(1),
}));

const ImageContainer = styled(Box)({
  cursor: "pointer",
  width: 200,
  height: 200,
  overflow: "hidden",
  marginRight: 8,
  marginBottom: 8,
});

const ThumbnailImage = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
});

const ModalImage = styled("img")({
  maxWidth: "100%",
  maxHeight: "100%",
  objectFit: "contain",
});

interface DiagnosticPicturesProps {
  pictures: Attachment[];
}

export const DiagnosticPictures: React.FC<DiagnosticPicturesProps> = ({ pictures }) => {
  const [selectedImage, setSelectedImage] = useState<Attachment | null>(null);

  const handleImageClick = (picture: Attachment) => {
    setSelectedImage(picture);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };
  const getUrl = (url: string) => {
    return `https://${process.env.REACT_APP_REPAIR_ASSISTANT_ATTACHMENTS_BASE_URL}/${url.split("/").pop()}`;
  };

  return (
    <Box>
      <GalleryContainer>
        {pictures.map((item, index) => (
          <ImageContainer key={index} onClick={() => handleImageClick(item)}>
            <ThumbnailImage src={getUrl(item.attachment.url)} alt={item.attachment.title} />
          </ImageContainer>
        ))}
      </GalleryContainer>
      <BaseModal isOpen={!!selectedImage} onClose={handleCloseModal}>
        <ModalImage
          src={(!!selectedImage && getUrl(selectedImage.attachment.url)) || ""}
          alt={(selectedImage && selectedImage.attachment.title) || ""}
        />
      </BaseModal>
    </Box>
  );
};

export default DiagnosticPictures;
