/**
 * Following the duck pattern, actions, constants and reducers are in the same file called module.js
 *
 * See: https://github.com/erikras/ducks-modular-redux
 *
 */
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import get from "lodash/get";
import random from "lodash/random";
import sortBy from "lodash/sortBy";
import { getTutorial } from "modules/generators/getTutorialGenerator";
import { saveWidget } from "modules/generators/widgetGenerators";
import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import { POST_TUTORIEL_STEP_IMAGE_ORDER_SUCCESS } from "pages/Cms/ContentManagement/components/ImagesManagement/module";

import { addOrReplaceById, addOrReplaceMultiPropertyById } from "utils/addOrReplace";
import convertIntoFormData from "utils/formData";
import request from "utils/networking";

export const GET_TUTORIEL_ADMIN_REQUEST = "TUTORIAL_EDITION/GET_TUTORIEL_REQUEST";
export const GET_TUTORIEL_ADMIN_SUCCESS = "TUTORIAL_EDITION/GET_TUTORIEL_SUCCESS";
export const GET_TUTORIEL_ADMIN_ERROR = "TUTORIAL_EDITION/GET_TUTORIEL_ERROR";

export const SAVE_TUTORIAL_REQUEST = "TUTORIAL_EDITION/SAVE_TUTORIAL_REQUEST";
export const SAVE_TUTORIAL_SUCCESS = "TUTORIAL_EDITION/SAVE_TUTORIAL_SUCCESS";
export const SAVE_TUTORIAL_ERROR = "TUTORIAL_EDITION/SAVE_TUTORIAL_ERROR";

export const SET_TUTORIAL_FIELD = "TUTORIAL_EDITION/SET_TUTORIAL_FIELD";
export const SET_TUTORIAL_STEP_FIELD = "TUTORIAL_EDITION/SET_TUTORIAL_STEP_FIELD";
export const SET_TUTORIAL_STEP_HAS_NO_MEDIA = "TUTORIAL/SET_TUTORIAL_STEP_HAS_NO_MEDIA";
export const SET_TUTORIAL_STEP_EXTERNAL_URL = "TUTORIAL/SET_TUTORIAL_STEP_EXTERNAL_URL";
export const SET_TUTORIAL_STEP_S3_URL = "TUTORIAL/SET_TUTORIAL_STEP_S3_URL";

export const INITIALIZE_NEW_TUTORIAL = "TUTORIAL_EDITION/INITIALIZE_NEW_TUTORIAL";
export const INITIALIZE_NEW_TUTORIAL_STEP = "TUTORIAL_EDITION/INITIALIZE_NEW_TUTORIAL_STEP";

export const SET_TUTORIAL_STEP_ORDER = "TUTORIAL_EDITION/SET_TUTORIAL_STEP_ORDER";
export const INCREASE_TUTORIAL_STEP_ORDER = "TUTORIAL_EDITION/INCREASE_TUTORIAL_STEP_ORDER";
export const DECREASE_TUTORIAL_STEP_ORDER = "TUTORIAL_EDITION/DECREASE_TUTORIAL_STEP_ORDER";

export const REMOVE_TUTORIAL_STEP = "TUTORIAL_EDITION/REMOVE_TUTORIAL_STEP";

export function getTutorielAdminRequest(id) {
  return {
    type: GET_TUTORIEL_ADMIN_REQUEST,
    payload: id,
  };
}

export function getTutorielAdminSuccess(tutoriel) {
  return {
    type: GET_TUTORIEL_ADMIN_SUCCESS,
    payload: tutoriel,
  };
}

export function getTutorielAdminError(error) {
  return {
    type: GET_TUTORIEL_ADMIN_ERROR,
    payload: error,
  };
}

export function saveTutorialRequest() {
  return {
    type: SAVE_TUTORIAL_REQUEST,
  };
}

export function saveTutorialSuccess(tutorial) {
  return {
    type: SAVE_TUTORIAL_SUCCESS,
    payload: tutorial,
  };
}

export function saveTutorialError(error) {
  return {
    type: SAVE_TUTORIAL_ERROR,
    payload: error,
  };
}

export function setTutorialField(fieldName, fieldValue) {
  return {
    type: SET_TUTORIAL_FIELD,
    payload: { fieldName, fieldValue },
  };
}

export function setTutorialStepField(tutorialStepId, fieldName, fieldValue) {
  return {
    type: SET_TUTORIAL_STEP_FIELD,
    payload: { tutorialStepId, fieldName, fieldValue },
  };
}

export function initializeNewTutorial() {
  return {
    type: INITIALIZE_NEW_TUTORIAL,
  };
}

export function initializeNewTutorialStep() {
  return {
    type: INITIALIZE_NEW_TUTORIAL_STEP,
  };
}

export function setTutorialStepHasNoMedia(tutorialStepId) {
  return {
    type: SET_TUTORIAL_STEP_HAS_NO_MEDIA,
    payload: { tutorialStepId },
  };
}

export function setTutorialStepExternalURL(tutorialStepId, externalURL, mediaType) {
  return {
    type: SET_TUTORIAL_STEP_EXTERNAL_URL,
    payload: { tutorialStepId, externalURL, mediaType },
  };
}

export function setTutorialStepS3URL(tutorialStepId, fileToUpload, previewURL, mediaType) {
  return {
    type: SET_TUTORIAL_STEP_S3_URL,
    payload: { tutorialStepId, fileToUpload, previewURL, mediaType },
  };
}

export function setTutorialStepOrder(tutorialStepId, isIncreased) {
  return {
    type: SET_TUTORIAL_STEP_ORDER,
    payload: { tutorialStepId, isIncreased },
  };
}

export function increaseTutorialStepOrder(tutorialStepId) {
  return {
    type: INCREASE_TUTORIAL_STEP_ORDER,
    payload: tutorialStepId,
  };
}

export function decreaseTutorialStepOrder(tutorialStepId) {
  return {
    type: DECREASE_TUTORIAL_STEP_ORDER,
    payload: tutorialStepId,
  };
}

export function removeTutorialStep(tutorialStepId) {
  return {
    type: REMOVE_TUTORIAL_STEP,
    payload: { tutorialStepId },
  };
}

export const getSaveTutorialSelector = (state) => {
  let { steps, ...tutorialWithoutSteps } = state.tutorielEdition.tutoriel;

  return tutorialWithoutSteps;
};

export const getStepHasMediaSelector = (state, tutorialStepId) => {
  if (!state.tutorielEdition.tutoriel) return false;
  let tutorialStep = find(state.tutorielEdition.tutoriel.steps, { id: tutorialStepId });
  return (
    !!tutorialStep.external_url || !!tutorialStep.s3_url || (tutorialStep.images && tutorialStep.images.length > 0)
  );
};

export const getTutorialStepsSelector = (state) => state.tutorielEdition.tutoriel.steps;

export const getEditedTutorielSteps = (state) => state.tutorielEdition.editedTutorialSteps;

export const getSaveTutorialStepConfig = (token, tutorialStep) => {
  let { id, isDeleted, isCreated } = tutorialStep;
  let baseConfig = {
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  let baseUrl = `${process.env.REACT_APP_API_URL}/widget/tutoriel/tutoriel-step/`;
  if (isDeleted && !isCreated) {
    return { ...baseConfig, method: "delete", url: `${baseUrl}${id}/` };
  }
  if (isCreated && !isDeleted) {
    return { ...baseConfig, method: "post", url: baseUrl, data: convertIntoFormData(tutorialStep) };
  } else {
    return {
      ...baseConfig,
      method: "patch",
      url: `${baseUrl}${id}/`,
      data: convertIntoFormData(tutorialStep),
    };
  }
};

export function* setEditedTutorialStepOrder(action) {
  const { isIncreased, tutorialStepId } = action.payload;
  isIncreased
    ? yield put(increaseTutorialStepOrder(tutorialStepId))
    : yield put(decreaseTutorialStepOrder(tutorialStepId));

  const steps = yield select(getTutorialStepsSelector);

  yield all(steps.map((step) => put(setTutorialStepField(step.id, "order", step.order))));
}

export function* getTutorielAdminSaga() {
  yield takeEvery(GET_TUTORIEL_ADMIN_REQUEST, getTutorial, getTutorielAdminSuccess, getTutorielAdminError);
}

export function* saveTutorialSteps(token) {
  let editedTutorialSteps = yield select(getEditedTutorielSteps);
  let tutorialStepsToSave = editedTutorialSteps.filter((step) => {
    // Filter out steps that have been created and deleted before save
    return !(step.isCreated && step.isDeleted);
  });
  yield all(tutorialStepsToSave.map((step) => call(request, getSaveTutorialStepConfig(token, step))));
}

export function* saveTutorialSaga() {
  const requestRoute = "tutoriel";
  const isMultiPartRequest = true;
  yield takeLatest(
    SAVE_TUTORIAL_REQUEST,
    saveWidget,
    requestRoute,
    isMultiPartRequest,
    getSaveTutorialSelector,
    saveTutorialSuccess,
    saveTutorialError,
    saveTutorialSteps
  );
}

export function* setTutorialStepOrderSaga() {
  yield takeLatest(SET_TUTORIAL_STEP_ORDER, setEditedTutorialStepOrder);
}

const initialState = {
  error: null,
  editedTutorialSteps: [],
  isEditingDirty: false,
  tutoriel: null,
};

/**
 * Following the duck pattern, the module.js file should export a reducer as a default function
 */
export function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_TUTORIEL_ADMIN_REQUEST:
      return {
        ...state,
        editedTutorialSteps: [],
        error: null,
        isEditingDirty: false,
        tutoriel: null,
      };
    case GET_TUTORIEL_ADMIN_SUCCESS:
      return {
        ...state,
        tutoriel: action.payload,
      };
    case GET_TUTORIEL_ADMIN_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SAVE_TUTORIAL_REQUEST:
      return {
        ...state,
      };
    case SAVE_TUTORIAL_SUCCESS:
      return {
        ...state,
        editedTutorialSteps: [],
        isEditingDirty: false,
        tutoriel: action.payload,
      };
    case SAVE_TUTORIAL_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_TUTORIAL_FIELD:
      return {
        ...state,
        isEditingDirty: true,
        tutoriel: {
          ...state.tutoriel,
          [action.payload.fieldName]: action.payload.fieldValue,
        },
      };
    case SET_TUTORIAL_STEP_FIELD:
      return {
        ...state,
        isEditingDirty: true,
        tutoriel: {
          ...state.tutoriel,
          steps: state.tutoriel.steps.map((step) =>
            step.id === action.payload.tutorialStepId
              ? { ...step, [action.payload.fieldName]: action.payload.fieldValue }
              : step
          ),
        },
        editedTutorialSteps: addOrReplaceById(
          state.editedTutorialSteps,
          action.payload.tutorialStepId,
          action.payload.fieldName,
          action.payload.fieldValue
        ),
      };
    case INITIALIZE_NEW_TUTORIAL:
      return {
        ...state,
        editedTutorialSteps: [],
        isEditingDirty: false,
        tutoriel: {},
      };
    case INITIALIZE_NEW_TUTORIAL_STEP:
      const newTutorialStepId = random(-100, 0);
      const order =
        state.tutoriel.steps.length > 0
          ? Math.max.apply(
              Math,
              state.tutoriel.steps.map((step) => step.order)
            ) + 1
          : 1;
      return {
        ...state,
        isEditingDirty: true,
        tutoriel: {
          ...state.tutoriel,
          steps: [
            ...state.tutoriel.steps,
            {
              id: newTutorialStepId,
              order: order,
            },
          ],
        },
        editedTutorialSteps: [
          ...state.editedTutorialSteps,
          {
            isCreated: true,
            id: newTutorialStepId,
            order: order,
            tutoriel: state.tutoriel.id,
          },
        ],
      };
    case SET_TUTORIAL_STEP_HAS_NO_MEDIA:
      return {
        ...state,
        isEditingDirty: true,
        tutoriel: {
          ...state.tutoriel,
          steps: state.tutoriel.steps.map((step) =>
            step.id === action.payload.tutorialStepId ? { ...step, external_url: "", media_type: "", s3_url: "" } : step
          ),
        },
        editedTutorialSteps: addOrReplaceMultiPropertyById(state.editedTutorialSteps, action.payload.tutorialStepId, [
          { key: "external_url", value: "" },
          { key: "media_type", value: "" },
          { key: "s3_url", value: "" },
        ]),
      };
    case SET_TUTORIAL_STEP_EXTERNAL_URL:
      return {
        ...state,
        isEditingDirty: true,
        tutoriel: {
          ...state.tutoriel,
          steps: state.tutoriel.steps.map((step) =>
            step.id === action.payload.tutorialStepId
              ? {
                  ...step,
                  external_url: action.payload.externalURL,
                  media_type: action.payload.mediaType,
                  s3_url: "",
                }
              : step
          ),
        },
        editedTutorialSteps: addOrReplaceMultiPropertyById(state.editedTutorialSteps, action.payload.tutorialStepId, [
          { key: "external_url", value: action.payload.externalURL },
          { key: "media_type", value: action.payload.mediaType },
          { key: "s3_url", value: "" },
        ]),
      };
    case SET_TUTORIAL_STEP_S3_URL:
      return {
        ...state,
        isEditingDirty: true,
        tutoriel: {
          ...state.tutoriel,
          steps: state.tutoriel.steps.map((step) =>
            step.id === action.payload.tutorialStepId
              ? {
                  ...step,
                  external_url: "",
                  media_type: action.payload.mediaType,
                  s3_url: action.payload.previewURL,
                }
              : step
          ),
        },
        editedTutorialSteps: addOrReplaceMultiPropertyById(state.editedTutorialSteps, action.payload.tutorialStepId, [
          { key: "external_url", value: "" },
          { key: "media_type", value: action.payload.mediaType },
          { key: "s3_url", value: action.payload.fileToUpload },
        ]),
      };
    case INCREASE_TUTORIAL_STEP_ORDER:
      const tutorialStepIndex = findIndex(state.tutoriel.steps, { id: action.payload });
      const tutorialStepOrder = get(find(state.tutoriel.steps, { id: action.payload }), "order");
      const nextTutorialStepOrder =
        get(state.tutoriel.steps[tutorialStepIndex + 1], "order") ||
        Math.max.apply(
          Math,
          state.tutoriel.steps.map((step) => step.order)
        );
      return {
        ...state,
        tutoriel: {
          ...state.tutoriel,
          steps: sortBy(
            state.tutoriel.steps.map((step, index) => {
              if (index === tutorialStepIndex + 1) {
                return { ...step, order: tutorialStepOrder };
              } else if (index === tutorialStepIndex) {
                return { ...step, order: nextTutorialStepOrder };
              } else {
                return step;
              }
            }),
            "order"
          ),
        },
      };
    case DECREASE_TUTORIAL_STEP_ORDER:
      const _tutorialStepIndex = findIndex(state.tutoriel.steps, { id: action.payload });
      const _tutorialStepOrder = get(find(state.tutoriel.steps, { id: action.payload }), "order");
      const _previousTutorialStepOrder = get(state.tutoriel.steps[_tutorialStepIndex - 1], "order") || 1;
      return {
        ...state,
        tutoriel: {
          ...state.tutoriel,
          steps: sortBy(
            state.tutoriel.steps.map((step, index) => {
              if (index === _tutorialStepIndex - 1) {
                return { ...step, order: _tutorialStepOrder };
              } else if (index === _tutorialStepIndex) {
                return { ...step, order: _previousTutorialStepOrder };
              } else {
                return step;
              }
            }),
            "order"
          ),
        },
      };
    case REMOVE_TUTORIAL_STEP:
      return {
        ...state,
        isEditingDirty: true,
        tutoriel: {
          ...state.tutoriel,
          steps: state.tutoriel.steps.filter((step) => step.id !== action.payload.tutorialStepId),
        },
        editedTutorialSteps: addOrReplaceById(
          state.editedTutorialSteps,
          action.payload.tutorialStepId,
          "isDeleted",
          true
        ),
      };
    case POST_TUTORIEL_STEP_IMAGE_ORDER_SUCCESS:
      return {
        ...state,
        tutoriel: {
          ...state.tutoriel,
          steps: state.tutoriel.steps.map((step) =>
            step.id === action.payload.tutoriel_step
              ? {
                  ...step,
                  images: [...step.images, action.payload],
                  media_type: "image",
                }
              : step
          ),
        },
      };
    default:
      return state;
  }
}

export default reducer;
