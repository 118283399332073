import React from "react";

import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import sortBy from "lodash/sortBy";

const statusStyle = {
  root: {
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    background: "#FFFFFF",
    borderBottom: "1px solid #DBDFE8",
    paddingBottom: "16px",
    paddingTop: "16px",
  },
  selectState: {
    display: "block",
    padding: ".375rem .75rem",
    color: "#2B3956",
    "background-color": "#F4F3F8",
    "background-image": "none",
    "background-clip": "padding-box",
    border: "none",
    "border-radius": ".25rem",
    height: "31px",
    transition: "border-color ease-in-out .15s,box-shadow ease-in-out .15s",
    "&:hover": {
      cursor: "pointer",
    },
    "font-family": "Poppins",
    "font-size": "16px",
    "font-style": "normal",
    "font-weight": "normal",
    "line-height": "140%",
  },
  text: {
    "font-family": "Poppins",
    "font-style": "normal",
    "font-weight": "normal",
    "font-size": "16px",
    "line-height": "140%",
    color: "#8795B3",
    height: "31px",
  },
  textVisit: {
    "font-family": "Poppins",
    "font-style": "normal",
    "line-height": "140%",
    color: "#2B3956",
    "font-weight": 600,
    "font-size": "16px",
    height: "31px",
  },
};

const StatusVisitInput = ({ classes, choices, value, editable, handleUpdate, label }) => {
  const handleChange = (event) => {
    handleUpdate(event.target.value);
  };

  return (
    <div className={classes.root}>
      <span className={classes.text}>{label}</span>
      {editable ? (
        <select className={classes.selectState} value={value} onChange={handleChange}>
          {sortBy(choices, "value").map((choice) => {
            return (
              <option value={choice.value} key={choice.value}>
                {choice.label}
              </option>
            );
          })}
        </select>
      ) : (
        <div className={classes.textVisit}>
          {choices.find((visitStatus) => value === visitStatus.value) &&
            choices.find((visitStatus) => value === visitStatus.value).label}
        </div>
      )}
    </div>
  );
};

StatusVisitInput.propTypes = {
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  editable: PropTypes.bool,
};

export default withStyles(statusStyle)(StatusVisitInput);
