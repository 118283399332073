import React from "react";

import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import sortBy from "lodash/sortBy";

import TicketTargetStatesInput from "pages/customerFiles/components/CustomerFileRepairReports/components/TicketTargetStatesInput";

import groupElementsByKeys from "utils/groupElementsByKeys";

const stateStyle = {
  root: {
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    paddingTop: "20px",
    paddingBottom: "150px",
  },
  selectState: {
    display: "block",
    padding: ".375rem .75rem",
    "font-size": "1rem",
    "line-height": "1.5",
    color: "#495057",
    "background-color": "#fff",
    "background-image": "none",
    "background-clip": "padding-box",
    border: "1px solid #ced4da",
    "border-radius": ".25rem",
    height: "40px",
    transition: "border-color ease-in-out .15s,box-shadow ease-in-out .15s",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "bold",
      userSelect: "none",
      border: "1px solid #beb4ba",
    },
  },
};

const TicketStatesInput = ({ canEdit = true, classes, choices, displaystyle = "", record, updateTicketDetail }) => {
  const patchTicketStates = (TicketId, state) => updateTicketDetail(TicketId, { ticket_state: state });
  const getStatesGroupedByTypeSelector = (states) => {
    const keys = [
      { keyName: "counter", pathToKey: "type.counter", isNumber: true },
      { keyName: "id", pathToKey: "type.id", isNumber: true },
      { keyName: "name", pathToKey: "type.name" },
      { keyName: "order", pathToKey: "type.order" },
    ];
    return [
      {
        id: null,
        name: "Tous les tickets",
        order: 0,
        elements: [],
      },
      ...groupElementsByKeys(states, keys),
    ];
  };
  const groupedStates = getStatesGroupedByTypeSelector(choices);
  return (
    <div className={classes.root}>
      <select
        className={classes.selectState}
        defaultValue={record.ticket_state}
        value={record.ticket_state}
        onChange={(event) => patchTicketStates(record.id, event.target.value)}
      >
        {sortBy(groupedStates, "type").map((type) => {
          return (
            <optgroup label={type.name} key={type.id}>
              {sortBy(type.elements, "order").map((state) => {
                return (
                  <option value={state.id} key={state.name}>
                    {state.name}
                  </option>
                );
              })}
            </optgroup>
          );
        })}
      </select>
      <TicketTargetStatesInput
        ticketId={record.id}
        ticketStateId={record.ticket_state}
        ticketStates={choices}
        updateTicketDetail={updateTicketDetail}
      />
    </div>
  );
};

TicketStatesInput.propTypes = {
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      type: PropTypes.string,
      order: PropTypes.string,
      counter: PropTypes.number,
      transitions: PropTypes.array,
    })
  ),
  record: PropTypes.any,
};

export default withStyles(stateStyle)(TicketStatesInput);
