/**
 * Following the duck pattern, actions, constants and reducers are in the same file called module.js
 *
 * See: https://github.com/erikras/ducks-modular-redux
 *
 */
import { saveWidget } from "modules/generators/widgetGenerators";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import request, { getTokenFromLocalStorage } from "utils/networking";

export const GET_ARTICLE_REQUEST = "ARTICLE_EDITION/GET_ARTICLE_REQUEST";
export const GET_ARTICLE_SUCCESS = "ARTICLE_EDITION/GET_ARTICLE_SUCCESS";
export const GET_ARTICLE_ERROR = "ARTICLE_EDITION/GET_ARTICLE_ERROR";

export const SAVE_ARTICLE_REQUEST = "ARTICLE_EDITION/SAVE_ARTICLE_REQUEST";
export const SAVE_ARTICLE_SUCCESS = "ARTICLE_EDITION/SAVE_ARTICLE_SUCCESS";
export const SAVE_ARTICLE_ERROR = "ARTICLE_EDITION/SAVE_ARTICLE_ERROR";

export const SET_ARTICLE_FIELD = "ARTICLE_EDITION/SET_ARTICLE_FIELD";

export const INITIALIZE_NEW_ARTICLE = "ARTICLE_EDITION/INITIALIZE_NEW_ARTICLE";

export function getArticleRequest(id) {
  return {
    type: GET_ARTICLE_REQUEST,
    payload: id,
  };
}

export function getArticleSuccess(article) {
  return {
    type: GET_ARTICLE_SUCCESS,
    payload: article,
  };
}

export function getArticleError(error) {
  return {
    type: GET_ARTICLE_ERROR,
    payload: error,
  };
}

export function saveArticleRequest() {
  return {
    type: SAVE_ARTICLE_REQUEST,
  };
}

export function saveArticleSuccess(article) {
  return {
    type: SAVE_ARTICLE_SUCCESS,
    payload: article,
  };
}

export function saveArticleError(error) {
  return {
    type: SAVE_ARTICLE_ERROR,
    payload: error,
  };
}

export function setArticleField(fieldName, fieldValue) {
  return {
    type: SET_ARTICLE_FIELD,
    payload: { fieldName, fieldValue },
  };
}

export function initializeNewArticle() {
  return {
    type: INITIALIZE_NEW_ARTICLE,
  };
}

export const getArticleSelector = (state) => state.articleEdition.article;

export function* getArticle(action) {
  const token = yield call(getTokenFromLocalStorage);
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/widget/article/${action.payload}/`,
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  try {
    const article = yield call(request, config);
    yield put(getArticleSuccess(article));
  } catch (error) {
    yield put(getArticleError(error));
  }
}

export function* getAdminArticleSaga() {
  yield takeEvery(GET_ARTICLE_REQUEST, getArticle);
}

export function* saveArticleSaga() {
  const requestRoute = "article";
  const isMultiPartRequest = true;
  yield takeLatest(
    SAVE_ARTICLE_REQUEST,
    saveWidget,
    requestRoute,
    isMultiPartRequest,
    getArticleSelector,
    saveArticleSuccess,
    saveArticleError,
    null
  );
}

const initialState = {
  error: null,
  article: null,
  isEditingDirty: false,
};

/**
 * Following the duck pattern, the module.js file should export a reducer as a default function
 */
export function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ARTICLE_REQUEST:
      return {
        ...state,
        article: null,
      };
    case GET_ARTICLE_SUCCESS:
      return {
        ...state,
        isEditingDirty: false,
        article: action.payload,
      };
    case GET_ARTICLE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SAVE_ARTICLE_REQUEST:
      return {
        ...state,
      };
    case SAVE_ARTICLE_SUCCESS:
      return {
        ...state,
        isEditingDirty: false,
        article: action.payload,
      };
    case SAVE_ARTICLE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_ARTICLE_FIELD:
      return {
        ...state,
        isEditingDirty: true,
        article: {
          ...state.article,
          [action.payload.fieldName]: action.payload.fieldValue,
        },
      };
    case INITIALIZE_NEW_ARTICLE:
      return {
        ...state,
        isEditingDirty: false,
        article: {},
      };
    default:
      return state;
  }
}

export default reducer;
