import React from "react";

import { Box } from "@material-ui/core";

export default ({ color, ...props }) => {
  const getBgColor = () => {
    switch (color) {
      case "red":
        return "#F85B5B";
      case "yellow":
        return "#F7B349";
      case "green":
        return "#81D89F";
      case "grey":
        return "#555555";
      default:
        throw new Error("Invalid color");
    }
  };

  return (
    <Box {...props} mr={1} display="inline-block" width="7px" height="7px" borderRadius={3} bgcolor={getBgColor()} />
  );
};
