import findIndex from "lodash/findIndex";

export function addOrReplaceById(array, id, fieldName, fieldValue) {
  let editedArray = array.slice();
  let editedObject = {
    id: id,
    [fieldName]: fieldValue,
  };
  let index = findIndex(editedArray, { id: id });
  if (index === -1) {
    return [...editedArray, editedObject];
  } else {
    let object = { ...editedArray[index], ...editedObject };
    editedArray[index] = object;
    return editedArray;
  }
}

export function addOrReplaceMultiPropertyById(array, id, propertiesArray) {
  const reducer = (accumulator, currentValue) =>
    addOrReplaceById(accumulator, id, currentValue.key, currentValue.value);
  return propertiesArray.reduce(reducer, array);
}

export function addOrReplaceByKey(array, lookupKey, data) {
  let editedArray = array.slice();
  let editedObject = data;
  let index = findIndex(editedArray, { [lookupKey]: data[`${lookupKey}`] });

  if (index === -1) {
    return [...editedArray, editedObject];
  } else {
    let object = { ...editedArray[index], ...editedObject };
    editedArray[index] = object;
    return editedArray;
  }
}
