import { getCompanyEmployeeSelector } from "admin/pages/Company/module";
import { sendAuthenticatedGetRequestToAPI, sendAuthenticatedRequestToAPI } from "modules/generators/requestsToAPI";
import { call, select, takeLatest } from "redux-saga/effects";

import convertIntoFormData from "utils/formData";

export const GET_IMAGES_FOLDERS_LIST_REQUEST = "CMS/GET_IMAGES_FOLDERS_LIST_REQUEST";
export const GET_IMAGES_FOLDERS_LIST_SUCCESS = "CMS/GET_IMAGES_FOLDERS_LIST_SUCCESS";
export const GET_IMAGES_FOLDERS_LIST_ERROR = "CMS/GET_IMAGES_FOLDERS_LIST_ERROR";

export const GET_IMAGES_FOLDER_REQUEST = "CMS/GET_IMAGES_FOLDER_REQUEST";
export const GET_IMAGES_FOLDER_SUCCESS = "CMS/GET_IMAGES_FOLDER_SUCCESS";
export const GET_IMAGES_FOLDER_ERROR = "CMS/GET_IMAGES_FOLDER_ERROR";

export const GET_IMAGES_REQUEST = "CMS/GET_IMAGES_REQUEST";
export const GET_IMAGES_SUCCESS = "CMS/GET_IMAGES_SUCCESS";
export const GET_IMAGES_ERROR = "CMS/GET_IMAGES_ERROR";

export const POST_IMAGES_FOLDER_REQUEST = "CMS/POST_IMAGES_FOLDER_REQUEST";
export const POST_IMAGES_FOLDER_SUCCESS = "CMS/POST_IMAGES_FOLDER_SUCCESS";
export const POST_IMAGES_FOLDER_ERROR = "CMS/POST_IMAGES_FOLDER_ERROR";

export const POST_IMAGE_REQUEST = "CMS/POST_IMAGE_REQUEST";
export const POST_IMAGE_SUCCESS = "CMS/POST_IMAGE_SUCCESS";
export const POST_IMAGE_ERROR = "CMS/POST_IMAGE_ERROR";

export const DELETE_IMAGE_REQUEST = "CMS/DELETE_IMAGE_REQUEST";
export const DELETE_IMAGE_SUCCESS = "CMS/DELETE_IMAGE_SUCCESS";
export const DELETE_IMAGE_ERROR = "CMS/DELETE_IMAGE_ERROR";

export const POST_STEP_IMAGE_ORDER_REQUEST = "CMS/POST_STEP_IMAGE_ORDER_REQUEST";
export const POST_STEP_IMAGE_ORDER_SUCCESS = "CMS/POST_STEP_IMAGE_ORDER_SUCCESS";
export const POST_TUTORIEL_STEP_IMAGE_ORDER_SUCCESS = "CMS/POST_TUTORIEL_STEP_IMAGE_ORDER_SUCCESS";
export const POST_STEP_IMAGE_ORDER_ERROR = "CMS/POST_STEP_IMAGE_ORDER_ERROR";

export const SET_ACTIVE_FOLDER = "CMS/SET_ACTIVE_FOLDER";

export function getImagesFoldersListRequest() {
  return {
    type: GET_IMAGES_FOLDERS_LIST_REQUEST,
  };
}

export function getImagesFoldersListSuccess(folders) {
  return {
    type: GET_IMAGES_FOLDERS_LIST_SUCCESS,
    payload: folders,
  };
}

export function getImagesFoldersListError(error) {
  return {
    type: GET_IMAGES_FOLDERS_LIST_ERROR,
    payload: error,
  };
}

export function getImagesFolderRequest(folderId) {
  return {
    type: GET_IMAGES_FOLDER_REQUEST,
    payload: folderId,
  };
}

export function getImagesFolderSuccess(folder) {
  return {
    type: GET_IMAGES_FOLDER_SUCCESS,
    payload: folder,
  };
}

export function getImagesFolderError(error) {
  return {
    type: GET_IMAGES_FOLDER_ERROR,
    payload: error,
  };
}

export function postImagesFolderRequest(folderTitle) {
  return {
    type: POST_IMAGES_FOLDER_REQUEST,
    payload: folderTitle,
  };
}

export function postImagesFolderSuccess(folder) {
  return {
    type: POST_IMAGES_FOLDER_SUCCESS,
    payload: folder,
  };
}

export function postImagesFolderError(error) {
  return {
    type: POST_IMAGES_FOLDER_ERROR,
    payload: error,
  };
}

export function postImageRequest(imageTitle, imageFile, imageAlt) {
  return {
    type: POST_IMAGE_REQUEST,
    payload: { imageTitle, imageFile, imageAlt },
  };
}

export function postImageSuccess(image) {
  return {
    type: POST_IMAGE_SUCCESS,
    payload: image,
  };
}

export function getImagesRequest() {
  return {
    type: GET_IMAGES_REQUEST,
  };
}

export function getImagesSuccess(images) {
  return {
    type: GET_IMAGES_SUCCESS,
    payload: images,
  };
}

export function getImagesError(error) {
  return {
    type: GET_IMAGES_ERROR,
    payload: error,
  };
}

export function deleteImageRequest(imageId) {
  return {
    type: DELETE_IMAGE_REQUEST,
    payload: imageId,
  };
}

export function deleteImageSuccess(apiResponse, imageDeleted) {
  return {
    type: DELETE_IMAGE_SUCCESS,
    payload: imageDeleted,
  };
}

export function deleteImageError(error) {
  return {
    type: DELETE_IMAGE_ERROR,
    payload: error,
  };
}

export function setActiveFolder(folderId) {
  return {
    type: SET_ACTIVE_FOLDER,
    payload: folderId,
  };
}

export function postImageError(error) {
  return {
    type: POST_IMAGE_ERROR,
    payload: error,
  };
}

export function postStepImageOrderRequest(stepType, stepId, imageId) {
  return {
    type: POST_STEP_IMAGE_ORDER_REQUEST,
    payload: { stepType, stepId, imageId },
  };
}

export function postStepImageOrderSuccess(stepimageorder) {
  return {
    type: POST_STEP_IMAGE_ORDER_SUCCESS,
    payload: stepimageorder,
  };
}

export function postTutorielStepImageOrderSuccess(tutorielstepimageorder) {
  return {
    type: POST_TUTORIEL_STEP_IMAGE_ORDER_SUCCESS,
    payload: tutorielstepimageorder,
  };
}

export function postStepImageOrderError(error) {
  return {
    type: POST_STEP_IMAGE_ORDER_ERROR,
    payload: error,
  };
}

export const getActiveFolderSelector = (state) => state.imagesManagement.activeFolder;

export function* getImagesFoldersList(action) {
  let route = "/librarian/folders/";
  const currentFolder = yield select(getActiveFolderSelector);
  if (currentFolder) route = `${route}?parent-folder=${currentFolder.id}`;

  yield call(sendAuthenticatedGetRequestToAPI, route, getImagesFoldersListSuccess, getImagesFoldersListError);
}

export function* getImagesFoldersListSaga() {
  yield takeLatest(GET_IMAGES_FOLDERS_LIST_REQUEST, getImagesFoldersList);
}

export function* getImagesFolder(action) {
  let route = `/librarian/folders/${action.payload}/`;
  yield call(sendAuthenticatedGetRequestToAPI, route, getImagesFolderSuccess, getImagesFolderError);
}

export function* getImagesFolderSaga() {
  yield takeLatest(GET_IMAGES_FOLDER_REQUEST, getImagesFolder);
}

export function* getImages(action) {
  let route = `/librarian/image/`;
  const currentFolder = yield select(getActiveFolderSelector);
  if (currentFolder) route = `${route}?folder=${currentFolder.id}`;

  yield call(sendAuthenticatedGetRequestToAPI, route, getImagesSuccess, getImagesError);
}

export function* getImagesSaga() {
  yield takeLatest(GET_IMAGES_REQUEST, getImages);
}

export function* postImagesFolder(action) {
  const currentFolder = yield select(getActiveFolderSelector);
  const company = yield select(getCompanyEmployeeSelector);

  let data = {
    company: company.id,
    title: action.payload,
  };

  data = currentFolder ? { ...data, parent_folder: currentFolder.id } : data;

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/librarian/folders/`,
  };

  const toastrConfig = {
    error: { title: "Erreur", message: "Le dossier n'a pas été créé" },
  };

  yield call(sendAuthenticatedRequestToAPI, config, postImagesFolderSuccess, postImagesFolderError, toastrConfig);
}

export function* postImagesFolderSaga() {
  yield takeLatest(POST_IMAGES_FOLDER_REQUEST, postImagesFolder);
}

export function* postImage(action) {
  const currentFolder = yield select(getActiveFolderSelector);
  const { imageTitle, imageFile, imageAlt } = action.payload;

  const data = {
    alt: imageAlt,
    folder: currentFolder.id,
    image: imageFile,
    title: imageTitle,
  };

  const formData = convertIntoFormData(data);

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/librarian/image/`,
  };

  const toastrConfig = {
    error: { title: "Erreur", message: "L'image n'a pas été uploadée" },
  };

  yield call(sendAuthenticatedRequestToAPI, config, postImageSuccess, postImageError, toastrConfig);
}

export function* postImageSaga() {
  yield takeLatest(POST_IMAGE_REQUEST, postImage);
}

export function* deleteImage(action) {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "delete",
    url: `${process.env.REACT_APP_API_URL}/librarian/image/${action.payload}/`,
  };

  const toastrConfig = {
    success: { title: "Succès", message: "L'image a bien été supprimée" },
    error: { title: "Erreur", message: "L'image n'a pas pu être supprimée" },
  };

  yield call(sendAuthenticatedRequestToAPI, config, deleteImageSuccess, deleteImageError, toastrConfig, [
    action.payload,
  ]);
}

export function* deleteImageSaga() {
  yield takeLatest(DELETE_IMAGE_REQUEST, deleteImage);
}

export function* postStepImageOrder(action) {
  /**
   * This generator can link an image to a step or a tutoriel step,
   * given the stepType contained in the action's payload.
   * Given this stepType, we have to adapt the data shape and
   * the requested route.
   */
  const { stepType, stepId, imageId } = action.payload;

  let data = {
    image: imageId,
  };

  data = stepType === "step" ? { ...data, step: stepId } : { ...data, tutoriel_step: stepId };

  const url = `${process.env.REACT_APP_API_URL}/librarian/${
    stepType === "step" ? "stepimageorders/" : "tutorielstepimageorders/"
  }`;

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
    method: "post",
    url: url,
  };

  const toastrConfig = {
    success: { title: "Succès", message: "L'image a bien été ajoutée" },
    error: { title: "Erreur", message: "L'image n'a pas pu être ajoutée" },
  };

  yield call(
    sendAuthenticatedRequestToAPI,
    config,
    stepType === "step" ? postStepImageOrderSuccess : postTutorielStepImageOrderSuccess,
    postStepImageOrderError,
    toastrConfig
  );
}

export function* postStepImageOrderSaga() {
  yield takeLatest(POST_STEP_IMAGE_ORDER_REQUEST, postStepImageOrder);
}

const initialState = {
  activeFolder: null,
  error: null,
  folders: [],
  images: [],
};

/**
 * Following the duck pattern, the module.js file should export a reducer as a default function
 */
export function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_IMAGES_FOLDERS_LIST_REQUEST:
      return {
        ...state,
        error: null,
      };
    case GET_IMAGES_FOLDERS_LIST_SUCCESS:
      return {
        ...state,
        folders: action.payload,
      };
    case GET_IMAGES_FOLDERS_LIST_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case GET_IMAGES_FOLDER_REQUEST:
      return {
        ...state,
        error: null,
      };
    case GET_IMAGES_FOLDER_SUCCESS:
      return {
        ...state,
        activeFolder: action.payload,
      };
    case GET_IMAGES_FOLDER_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case GET_IMAGES_REQUEST:
      return {
        ...state,
        error: null,
      };
    case GET_IMAGES_SUCCESS:
      return {
        ...state,
        images: action.payload,
      };
    case GET_IMAGES_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case POST_IMAGES_FOLDER_REQUEST:
      return {
        ...state,
        error: null,
      };
    case POST_IMAGES_FOLDER_SUCCESS:
      return {
        ...state,
        folders: [...state.folders, action.payload],
      };
    case POST_IMAGES_FOLDER_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case POST_IMAGE_REQUEST:
      return {
        ...state,
        error: null,
      };
    case POST_IMAGE_SUCCESS:
      return {
        ...state,
        images: [...state.images, action.payload],
      };
    case POST_IMAGE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_IMAGE_REQUEST:
      return {
        ...state,
        error: null,
      };
    case DELETE_IMAGE_SUCCESS:
      return {
        ...state,
        images: state.images.filter((image) => image.id !== action.payload),
      };
    case DELETE_IMAGE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_ACTIVE_FOLDER:
      return {
        ...state,
        activeFolder: state.folders.find((folder) => folder.id === action.payload),
      };
    case POST_STEP_IMAGE_ORDER_REQUEST:
      return {
        ...state,
        error: null,
      };
    case POST_STEP_IMAGE_ORDER_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default reducer;
