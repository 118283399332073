import { push } from "connected-react-router";
import filter from "lodash/filter";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import indexOf from "lodash/indexOf";
import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";

import { getAPIUrl, request } from "utils/networking";

export const GET_COMPANY_BASED_ON_HOST_REQUEST = "Company/GET_COMPANY_BASED_ON_HOST_REQUEST";
export const GET_COMPANY_REQUEST = "Company/GET_COMPANY_REQUEST";
export const GET_COMPANY_SUCCESS = "Company/GET_COMPANY_SUCCESS";
export const GET_COMPANY_ERROR = "Company/GET_COMPANY_ERROR";
export const SET_SELECTED_PRODUCT = "Company/SET_SELECTED_PRODUCT";
export const SET_SELECTED_APP_FAMILY = "Company/SET_SELECTED_APP_FAMILY";

export function getCompanyRequest(companyId) {
  return {
    type: GET_COMPANY_REQUEST,
    payload: companyId,
  };
}

export function getCompanyBasedOnHostRequest() {
  return {
    type: GET_COMPANY_BASED_ON_HOST_REQUEST,
  };
}

export function getCompanySuccess(company, applications, applicationFamilies, products, widgets, cities) {
  return {
    type: GET_COMPANY_SUCCESS,
    payload: { company, applications, applicationFamilies, products, widgets, cities },
    meta: {
      analytics: {
        type: "SEND_EVENT",
        payload: {
          action: "ACCESS_COMPANY",
          category: "COMPANY",
          label: company.id,
          trackerName: "user",
          nonInteraction: true,
        },
      },
    },
  };
}

export function getCompanyError(error) {
  return {
    type: GET_COMPANY_ERROR,
    payload: error,
  };
}

export function setSelectedProduct(productId) {
  return {
    type: SET_SELECTED_PRODUCT,
    payload: { productId },
  };
}

export const getCompanySelector = (state) => state.company.company;

export const getApplicationByWidgetSelector = (state, widgetId) => {
  const application = find(state.company.applications, { widget: widgetId });
  return application || null;
};

export const getApplicationsSelector = (state, productId, applicationFamilyId) => {
  let filteredApps = state.company.applications;
  if (productId) {
    filteredApps = filter(filteredApps, (app) => {
      return indexOf(app.products, parseInt(productId, 10)) >= 0;
    });
  } else {
    filteredApps = getGeneralInformationSelector(state);
  }
  if (applicationFamilyId)
    filteredApps = filter(filteredApps, { application_family: parseInt(applicationFamilyId, 10) });
  return filteredApps;
};

export const getGeneralInformationSelector = (state) => {
  let applications = state.company.applications;
  return filter(applications, (app) => app.products.length === 0);
};

export const getProductNameSelector = (state, productId) => {
  try {
    var productIdAsInt = parseInt(productId, 10);
  } catch (error) {
    return;
  }
  const relatedProduct = state.company.products.find((product) => product.id === productIdAsInt);
  return relatedProduct && relatedProduct.name;
};

export const getProductsSelector = (state, applicationFamilyId) => {
  const { applications, products } = state.company;
  if (applicationFamilyId) {
    let apps = filter(applications, { application_family: parseInt(applicationFamilyId, 10) });
    return filter(
      products,
      (product) =>
        findIndex(apps, (app) => {
          return indexOf(app.products, product.id) >= 0;
        }) >= 0
    );
  } else {
    return products;
  }
};

export const getProductsWithApplicationSelector = (state, applicationFamilyId) => {
  const { applications, products } = state.company;
  if (applicationFamilyId) {
    let apps = filter(applications, { application_family: parseInt(applicationFamilyId, 10) });
    return filter(
      products,
      (product) =>
        findIndex(apps, (app) => {
          return indexOf(app.products, product.id) >= 0;
        }) >= 0
    );
  } else {
    return filter(
      products,
      (product) =>
        findIndex(applications, (app) => {
          return indexOf(app.products, product.id) >= 0;
        }) >= 0
    );
  }
};

export const getWidgetSelector = (state, widgetId) => {
  return find(state.company.widgets, { id: widgetId });
};

export const getSelectedProductSelector = (state) =>
  find(state.company.products, { id: state.company.selectedProduct });

export const getQueryStringsSelector = (state) => state.router.location.search;

export const getProductFromSlugSelector = (state, slug) => {
  const product = state.company.products.find(function (element) {
    return element.slug === slug;
  });
  return product || null;
};

export const getCityFromSlugSelector = (state, slug) => {
  const city = state.company.cities.find(function (element) {
    return element.slug === slug;
  });
  return city || null;
};

export const getAuthenticatedSelector = (state) => state.user.isUserAuthenticated;

export function* getCompany(action) {
  let companyId = action.payload;
  const companyRequestConfig = {
    method: "get",
    url: `${getAPIUrl()}/common/company/${companyId}/`,
  };
  const applicationRequestConfig = {
    method: "get",
    url: `${getAPIUrl()}/common/application/?company=${companyId}`,
  };
  const applicationFamilyRequestConfig = {
    method: "get",
    url: `${getAPIUrl()}/common/application-family/?company=${action.payload}`,
  };
  const productRequestConfig = {
    method: "get",
    url: `${getAPIUrl()}/common/product/?company=${companyId}`,
  };
  const widgetRequestConfig = {
    method: "get",
    url: `${getAPIUrl()}/widget/?company=${companyId}`,
  };
  const cityRequestConfig = {
    method: "get",
    url: `${getAPIUrl()}/common/city/?company=${companyId}`,
  };
  try {
    const [company, applications, applicationFamilies, products, widgets, cities] = yield all([
      call(request, companyRequestConfig),
      call(request, applicationRequestConfig),
      call(request, applicationFamilyRequestConfig),
      call(request, productRequestConfig),
      call(request, widgetRequestConfig),
      call(request, cityRequestConfig),
    ]);
    yield put(getCompanySuccess(company, applications, applicationFamilies, products, widgets, cities));
    yield put(push("/"));
  } catch (error) {
    yield put(getCompanyError(error));
  }
}

export function* getCompanyBasedOnHost() {
  const companyRequestConfig = {
    method: "get",
    url: `${getAPIUrl()}/common/company/`,
  };
  try {
    const company = yield call(request, companyRequestConfig);
    const companyId = company.id;
    const applicationRequestConfig = {
      method: "get",
      url: `${getAPIUrl()}/common/application/?company=${companyId}`,
    };
    const applicationFamilyRequestConfig = {
      method: "get",
      url: `${getAPIUrl()}/common/application-family/?company=${companyId}`,
    };
    const productRequestConfig = {
      method: "get",
      url: `${getAPIUrl()}/common/product/?company=${companyId}`,
    };
    const widgetRequestConfig = {
      method: "get",
      url: `${getAPIUrl()}/widget/?company=${companyId}`,
    };
    const cityRequestConfig = {
      method: "get",
      url: `${getAPIUrl()}/common/city/?company=${companyId}`,
    };
    const [applications, applicationFamilies, products, widgets, cities] = yield all([
      call(request, applicationRequestConfig),
      call(request, applicationFamilyRequestConfig),
      call(request, productRequestConfig),
      call(request, widgetRequestConfig),
      call(request, cityRequestConfig),
    ]);
    yield put(getCompanySuccess(company, applications, applicationFamilies, products, widgets, cities));
  } catch (error) {
    yield put(getCompanyError(error));
  }
}

// worker Saga: will be fired on GET_COMPANY_REQUEST actions
export function* getCompanySaga() {
  yield takeEvery(GET_COMPANY_REQUEST, getCompany);
}

export function* getCompanyBasedOnHostSaga() {
  yield takeLatest(GET_COMPANY_BASED_ON_HOST_REQUEST, getCompanyBasedOnHost);
}

const initialState = {
  applications: [],
  applicationFamilies: [],
  company: null,
  error: null,
  products: [],
  selectedProduct: null,
  widgets: [],
  cities: [],
};

/**
 * Following the duck pattern, the module.js file should export a reducer as a default function
 */
export function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_COMPANY_REQUEST:
      return {
        ...state,
        error: null,
      };
    case GET_COMPANY_SUCCESS:
      return {
        ...state,
        applications: action.payload.applications,
        applicationFamilies: action.payload.applicationFamilies,
        company: action.payload.company,
        error: null,
        products: action.payload.products,
        widgets: action.payload.widgets,
        cities: action.payload.cities,
      };
    case GET_COMPANY_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_SELECTED_PRODUCT: {
      return {
        ...state,
        selectedProduct: action.payload.productId,
      };
    }
    default:
      return state;
  }
}

export default reducer;
