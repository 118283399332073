import * as React from "react";

import get from "lodash/get";

import FormatPrice from "./FormatPrice";

const CurrencyField = ({ source, record = {} }) => {
  return <FormatPrice cents={get(record, source)} />;
};

export default CurrencyField;

CurrencyField.defaultProps = {
  addLabel: true,
};
