import React from "react";

import { NavLink } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";

const styles = {
  subMenuItemWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: "3px",
    marginBottom: 8,
    fontSize: "14px",
    "& :hover": {
      backgroundColor: "#404C67",
      cursor: "pointer",
      color: "white",
    },
  },
  subMenuIcon: {
    marginRight: "10px",
    marginLeft: "10px",
    height: "13px",
    width: "13px",
  },
  subMenuNumber: {
    margin: "0 14px 0 auto",
  },
  unactiveLink: {
    color: "#9FA5B3",
    "& :hover": {
      color: "#9FA5B3",
      backgroundColor: "#404C67",
      "& svg": {
        color: "#F85B5B",
      },
    },
  },
  activeLink: {
    color: "white",
    "& :hover": {
      color: "white",
      "& svg": {
        color: "white",
      },
    },
    "& svg": {
      color: "white",
    },
  },
};

const isLinkActive = (checkQueryParams, link) => (match, location) => {
  if (match && !checkQueryParams) {
    return match.url + location.search === link.pathname;
  } else if (checkQueryParams && match) {
    const toMatch = match.url + location.search;
    const formattedLink = link.pathname + "?" + link.search;
    return toMatch === formattedLink;
  }
  return false;
};

/*
 ** Component to use as a child to MurfySubMenu (cf MurfyMenu)
 ** Needed props:
 **   - itemName (name of the submenu displayed)
 **   - link (link of the page to reach, will default to '/')
 ** Optional props:
 **   - icon (icon to be displayed at the left of the name. If none, no icon is displayed)
 **   - matchWithQueryParams (tells if the isActive check must be done with query-params)
 **   - numberDisplayed (number to be displayed at the end of the item. If none, no number is displayed)
 */
const MurfySubMenuItem = ({
  classes,
  icon,
  itemName,
  link = "/",
  matchWithQueryParams = false,
  numberDisplayed = null,
}) => {
  return (
    <NavLink
      to={link}
      className={classes.unactiveLink}
      activeClassName={classes.activeLink}
      isActive={isLinkActive(matchWithQueryParams, link)}
    >
      <div className={classes.subMenuItemWrapper}>
        {icon ? React.cloneElement(icon, { className: classes.subMenuIcon }) : null}
        {itemName}
        <div className={classes.subMenuNumber}>{numberDisplayed}</div>
      </div>
    </NavLink>
  );
};

export default withStyles(styles)(MurfySubMenuItem);
