import React, { useEffect } from "react";

import { Box } from "@material-ui/core";

import { Product } from "types/products";

import FileComments from "components/FileComments";

import FileProduct from "../components/FileProduct";
import AddNewProduct from "../components/FileProduct/AddNewProduct";
import InterventionPreparation from "../components/InterventionPreparation";

const products = require(`${process.env.REACT_APP_PRODUCT_CONF_FILE}.json`);

type ProductInformationTabProps = {
  details: any; // to be changed: can be a customer file or a workshop file, not right
  getCustomerFileDetail: (id: number) => void;
  getWorkshopFile: (id: number) => void;
  idEmployee: number;
  isWorkshopFile: boolean;
};

const ProductInformationTab: React.FunctionComponent<ProductInformationTabProps> = ({
  details,
  getCustomerFileDetail,
  getWorkshopFile,
  idEmployee,
  isWorkshopFile,
}) => {
  useEffect(() => {
    if (!isWorkshopFile) {
      getCustomerFileDetail(details.id);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {details.category !== "workshop_repair" && !isWorkshopFile && (
        <Box maxWidth={768}>
          <FileComments
            title="Commentaire"
            filter={{ recipient_role: null, customer_file: details.id, ordering: "-id" }}
            customerFile={details}
            recipientRole={undefined}
            maxNumberOfComments={undefined}
            updateEnabled={undefined}
          />

          <FileComments
            filter={{ recipient_role: "repairman", customer_file: details.id, ordering: "-id" }}
            customerFile={details}
            attachmentsEnabled
            updateEnabled
            recipientRole="repairman"
            title="Message au réparateur"
            maxNumberOfComments={1}
          />
        </Box>
      )}
      {isWorkshopFile ? (
        <FileProduct
          isWorkshopFile
          record={details.product}
          key={details.product.id}
          products={products}
          murfyFile={details}
          idEmployee={idEmployee}
          getWorkshopFile={getWorkshopFile}
          getCustomerFileDetail={undefined}
        />
      ) : (
        <>
          {details.products.map((product: Product) => (
            <FileProduct
              record={product}
              key={product.id}
              products={products}
              murfyFile={details}
              idEmployee={idEmployee}
              getCustomerFileDetail={getCustomerFileDetail}
              getWorkshopFile={getWorkshopFile}
              isWorkshopFile={isWorkshopFile}
            />
          ))}
          <Box mt={2} maxWidth={800}>
            <InterventionPreparation customerFile={details} />
          </Box>
          <AddNewProduct customerFile={details.id} products={products} getCustomerFileDetail={getCustomerFileDetail} />
        </>
      )}
    </>
  );
};

export default ProductInformationTab;
