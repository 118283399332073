import React from "react";

import { Box } from "@material-ui/core";

import moment from "moment";
import { Diagnostic } from "types/diagnostic";

import ColorChip from "components/atoms/ColorChip";
import { Body, BodyBold } from "components/atoms/Typography";

import { useProductDetails } from "utils/hooks/useProductDetails";

interface ProductDetailProps {
  diagnostic: Diagnostic;
}

const ProductTitle = ({ diagnostic }: ProductDetailProps) => {
  const { productDetails, isLoading, isError } = useProductDetails(diagnostic.appliance_repair.product_id);
  if (isLoading && !productDetails) {
    return <div>Récupération du produit</div>;
  }

  if (isError || !productDetails) {
    return <div>Echec de la récupération du produit</div>;
  }

  const productModelData = productDetails.product_model;

  const productType =
    productModelData && productModelData.product_type
      ? productModelData.product_type.name
      : productDetails.product_type || "Produit non défini";

  const brand = productModelData && productModelData.brand ? productModelData.brand.name : productDetails.brand || "";

  const reference =
    productModelData && productModelData.modelReference
      ? productModelData.modelReference
      : productDetails.model_reference || "";

  const productString = `${productType} ${brand} ${reference} `;

  const dateInfoString = ` le ${moment(diagnostic.finalized_at).format("DD/MM/YYYY à HH:mm")}`;

  const productCorrectlyIdentified =
    productModelData &&
    productModelData.modelReference &&
    productModelData.brand &&
    productModelData.product_type &&
    productDetails.identification_plate;

  return (
    <Box width="100%">
      <Box display="flex" justifyContent="space-between">
        <Box my="auto" display="flex">
          <BodyBold>{productString}</BodyBold> &nbsp; <Body>{dateInfoString}</Body> &nbsp;
        </Box>
        <Box>
          {productDetails.identification_plate && (
            <a
              href={`${process.env.REACT_APP_IDENTIFICATION_PLATE_BUCKET}${productDetails.identification_plate}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ColorChip label="Plaque signalétique" color="turquoise" />
            </a>
          )}
        </Box>
      </Box>
      {productCorrectlyIdentified ? (
        <ColorChip label="Identification OK" color="green" />
      ) : (
        <ColorChip label="Identification incomplète" color="orange" />
      )}
    </Box>
  );
};

export default ProductTitle;
