import React, { Component } from "react";

import PropTypes from "prop-types";

import Tag from "admin/components/Tag";
import find from "lodash/find";
import sortBy from "lodash/sortBy";

import Modal from "components/Modal";

import "./style.css";

export default class TicketTagsList extends Component {
  static propTypes = {
    canEdit: PropTypes.bool,
    ticketId: PropTypes.number.isRequired,
    ticketTags: PropTypes.arrayOf(PropTypes.number).isRequired,
    patchTicketTags: PropTypes.func.isRequired,
    tagsList: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        text: PropTypes.string,
        color: PropTypes.string,
      })
    ).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }

  onClickTagHandler = (tagId) => {
    find(this.props.ticketTags, (tag) => tag === tagId)
      ? this.props.patchTicketTags(this.props.ticketId, [...this.props.ticketTags.filter((tag) => tag !== tagId)])
      : this.props.patchTicketTags(this.props.ticketId, [...this.props.ticketTags, tagId]);
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  openModal = () => {
    this.setState({
      isModalOpen: true,
    });
  };

  render() {
    return (
      <div>
        <p className="cf-tags-list-open-modal-button" onClick={this.openModal}>
          Editer les tags
        </p>
        <Modal isOpen={this.state.isModalOpen} handleCloseModal={this.closeModal} modalClassName="modal-small-content">
          <div className="cf-tags-list-container">
            <p className="cf-tags-list-container-title">Ajouter ou enlever un tag</p>
            <div>
              {sortBy(sortBy(this.props.tagsList, "text"), "color").map((tag) => (
                <div key={tag.id}>
                  <Tag
                    color={tag.color}
                    handleClickTag={this.onClickTagHandler}
                    handleClickIcon={this.props.deleteTicketTag}
                    isDeleteDisplayed={false}
                    isSelected={!!find(this.props.ticketTags, (ticketTagId) => tag.id === ticketTagId)}
                    tagId={tag.id}
                    text={tag.text}
                  />
                </div>
              ))}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
