import React from "react";

import { Box, Card, CardContent, CardHeader, IconButton, ListItem, Tooltip, Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AssignmentIcon from "@material-ui/icons/Assignment";

import Moment from "moment";
import "moment/locale/fr";
import { CustomerFileSlim } from "types/customer-files";

import CustomerFileTitleField from "components/CustomerFileTitleField";
import AfterSalesIndicator from "components/atoms/AfterSalesIndicator";
import Avatar from "components/atoms/Avatar";
import ColorChip from "components/atoms/ColorChip";
import HotStatusIcon from "components/atoms/HotStatusIcon";
import Location from "components/atoms/Location";

import CustomerFileStateField from "pages/customerFiles/CustomerFileStateField";

import TasksToDoFileCard from "../../components/TasksToDoFileCard";

const useStyles = makeStyles({
  cardStyle: {
    padding: "16px",
    cursor: "pointer",
    width: "100%",
  },
  yellowBorder: {
    border: "1px solid #F7B349",
  },
  titleStyle: {
    minWidth: 298,
  },
});

const getIndicator = (customerFile: CustomerFileSlim) => {
  if (customerFile.is_after_sales) {
    return (
      <Box position="absolute" bottom={14} right={16} width={24} height={24}>
        <AfterSalesIndicator size={20} display="flex" />
      </Box>
    );
  }
  if (customerFile.hot_status) {
    return (
      <Box position="absolute" bottom={14} right={16} width={24} height={24}>
        <HotStatusIcon />
      </Box>
    );
  }
};

const StyledListItem = withStyles({ root: { padding: "4px 8px" } })(ListItem);
const StyledCardHeader = withStyles({ root: { padding: 0 } })(CardHeader);
const StyledCardContent = withStyles({
  root: {
    padding: 0,
    margin: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
})(CardContent);

type CustomerFileCardProps = {
  customerFile: CustomerFileSlim;
  customerFilesTags: any;
  selected: boolean;
  onClick: (arg0: string) => void;
  isReportCheckingInterface: boolean;
};

export default ({
  customerFile,
  customerFilesTags,
  selected,
  onClick,
  isReportCheckingInterface,
}: CustomerFileCardProps) => {
  const classes = useStyles();

  const copyFileNumber = (event: any) => {
    event.stopPropagation();
    const span = document.createElement("span");
    span.textContent = customerFile.file_number;
    document.body.appendChild(span);

    const selection = document.getSelection();
    const range = document.createRange();
    range.selectNode(span);
    selection!.removeAllRanges();
    selection!.addRange(range);

    document.execCommand("copy");
    selection!.removeAllRanges();

    document.body.removeChild(span);
  };

  return (
    <StyledListItem button onClick={() => onClick(customerFile.file_number)} key={customerFile.id}>
      <Card key={customerFile.id} className={classes.cardStyle + (selected ? ` ${classes.yellowBorder}` : "")}>
        <StyledCardHeader
          title={<CustomerFileTitleField variant="subtitle1" record={customerFile} className={classes.titleStyle} />}
        />
        {isReportCheckingInterface && (
          <StyledCardContent>
            <TasksToDoFileCard customerFile={customerFile} />
          </StyledCardContent>
        )}
        {!isReportCheckingInterface && (
          <StyledCardContent>
            <Box my={1}>
              <Location city={customerFile.city} />
            </Box>
            <Box mb={2}>
              <CustomerFileStateField id={customerFile.state_id} text={customerFile.state_name} />
            </Box>
            <Box display="flex">
              {customerFile.assigned_employees.map((employee) => (
                <Avatar id={employee.id} name={`${employee.first_name} ${employee.last_name}`} mr={0.5} />
              ))}
            </Box>
            <Box display="flex" flexWrap="wrap">
              {customerFile.tags.map((tag) => (
                <Box my="3px">
                  <ColorChip label={tag.text} hexColor={tag.color} />
                </Box>
              ))}
              {customerFile.has_repair_report_check_done && <ColorChip label="Avis du CET" hexColor="#40c040" />}
            </Box>
            <Box display="flex" flexDirection="column">
              {Moment(customerFile.updated_at).diff(Moment(customerFile.created_at), "seconds") > 1 && (
                <Typography variant="caption">
                  Modifié <b> {Moment(customerFile.updated_at).fromNow()} </b>
                </Typography>
              )}
            </Box>
            {getIndicator(customerFile)}
          </StyledCardContent>
        )}
        <Tooltip title="Copier la référence dossier">
          <Typography variant="caption">
            N° {customerFile.file_number}{" "}
            <IconButton aria-label="copy" onClick={copyFileNumber}>
              <AssignmentIcon />
            </IconButton>
          </Typography>
        </Tooltip>
      </Card>
    </StyledListItem>
  );
};
