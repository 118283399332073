import React, { Component } from "react";

import PropTypes from "prop-types";

import ReactQuill from "react-quill";

import "./style.css";

export default class TextEditionField extends Component {
  static propTypes = {
    label: PropTypes.string,
    isMainContainer: PropTypes.bool,
    isRichText: PropTypes.bool,
    nbRows: PropTypes.number,
    onChange: PropTypes.func,
    value: PropTypes.string.isRequired,
    maxLength: PropTypes.number,
    additionalEditionOptions: PropTypes.array,
  };

  static defaultProps = {
    enableImage: false,
    isMainContainer: false,
    isRichText: false,
    additionalEditionOptions: [],
    nbRows: 1,
    value: "",
  };

  getRichTextOptions = () => {
    let options = ["bold", "italic", "underline", { list: "ordered" }, { list: "bullet" }, "link"];
    if (this.props.enableImage) {
      options.push("image");
    }
    if (this.props.additionalEditionOptions.length !== 0) {
      options = options.concat(this.props.additionalEditionOptions);
    }
    return options;
  };

  onValueChange = (event) => this.props.onChange(event.target.value);

  onRichTextChange = (content) => this.props.onChange(content);

  render() {
    return (
      <div
        className={this.props.isMainContainer ? "text-edition-field-container" : "text-edition-field-subcontainer"}
        id="text-edition-field"
      >
        {this.props.label && (
          <div
            className={
              this.props.isMainContainer
                ? "text-edition-field-title-container"
                : "text-edition-field-title-left-container"
            }
          >
            <span className={this.props.isMainContainer ? "text-edition-field-title" : ""}>
              {this.props.label}&nbsp;
            </span>
            {this.props.maxLength && (
              <span className="text-edition-field-length-info">{`(${this.props.maxLength} car. max)`}</span>
            )}
          </div>
        )}
        {this.props.isRichText ? (
          <ReactQuill
            bounds={".edition-box-container"}
            modules={{ toolbar: [this.getRichTextOptions()] }}
            onChange={this.onRichTextChange}
            value={this.props.value}
          />
        ) : (
          <textarea
            className="text-edition-field-textarea"
            disabled={!this.props.onChange}
            onChange={this.onValueChange}
            rows={this.props.nbRows}
            value={this.props.value}
            maxLength={this.props.maxLength}
          />
        )}
      </div>
    );
  }
}
