// React
import React from "react";

// Icons
import LinkIcon from "@material-ui/icons/Link";

import CopyClipboard from "components/atoms/CopyClipboard";
import CustomerFileLink from "components/atoms/CustomerFileLink";

export const DesignationField = ({ record = {}, classes, callbackNotify, onClickOnTooltip }) => {
  return (
    <div className={classes.designationField}>
      <span>{record.customer_name}</span>
      {record.customer_file && (
        <>
          <CopyClipboard
            info={record.customer_file_number}
            successMessage="Référence de dossier copiée"
            title={"Copier la référence dossier"}
            Icon={LinkIcon}
          />
          <CustomerFileLink
            fileNumber={record.customer_file_number}
            isWorkshopFile={record && !!record.workshop_name}
          />
        </>
      )}
    </div>
  );
};
