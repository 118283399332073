// React imports
import React from "react";

// MUI
import { Box, IconButton, Typography, useTheme } from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";

import { quoteElementList } from "pages/customerFiles/components/CustomerFileBillingQuotes/services/QuoteHelper.js";
// Services
import { getQuoteElementProperty } from "pages/customerFiles/components/CustomerFileBillingQuotes/services/getQuoteElementProperty.js";

// Utils
import { formatPriceCompact } from "utils/formatting";

const BasicPackage = ({
  isModifiable = false,
  productId,
  element,
  handleOpenPackageModificationModal,
  index,
  packageTypeList,
}) => {
  const theme = useTheme();

  return (
    <Box display="flex" justifyContent="space-between" p={1}>
      <Box my="auto" mx={0} color={theme.palette.secondary.grey900}>
        <Typography variant="body1" color="inherit">
          {getQuoteElementProperty(element.element_type, quoteElementList, "name")} &nbsp;-&nbsp;
          {getQuoteElementProperty(element.package_type, packageTypeList, "package_name")}
          {element.product && <>&nbsp;-&nbsp;{element.product}</>}
        </Typography>
      </Box>
      <Box display="inline-flex" my="auto">
        <Typography variant="body1">{formatPriceCompact(element.amount_withtax)}</Typography>
        {isModifiable && (
          <Box color={theme.palette.secondary.grey900} ml="3px">
            <IconButton
              id="edit-package"
              color="inherit"
              onClick={() => handleOpenPackageModificationModal(index !== -1 ? index : element.id, productId)}
            >
              <Edit />
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default BasicPackage;
