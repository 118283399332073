import * as React from "react";

import { Typography } from "@material-ui/core";

import { FormatPrice } from "components/CurrencyField";
import { DELIVERY_TYPE_OPTIONS } from "components/OrderShow/utils";

const DeliveryTypeField = ({ order }) => {
  const possible_values = DELIVERY_TYPE_OPTIONS.filter((o) => o.id === order.type);
  if (possible_values.length === 0) return null;
  const deliveryTypeLabel = possible_values[0].label;
  return (
    <Typography variant="body2">
      {deliveryTypeLabel} (<FormatPrice cents={order.method_price} />)
    </Typography>
  );
};

DeliveryTypeField.defaultProps = {
  addLabel: true,
};

export default DeliveryTypeField;
