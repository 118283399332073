import { CRUD_UPDATE_SUCCESS, FETCH_END, UPDATE } from "react-admin";

import findIndex from "lodash/findIndex";
import unionBy from "lodash/unionBy";

export const storeProductAction = (customerFile, newProduct, difference) => {
  let newCustomerFile = customerFile;
  const index = findIndex(customerFile.products, { id: newProduct.id });
  const product = { ...customerFile.products[index], ...difference };
  newCustomerFile = { ...customerFile, products: unionBy([product], customerFile.products, "id") };

  return {
    type: CRUD_UPDATE_SUCCESS,
    payload: { id: customerFile.id, data: { ...newCustomerFile } },
    meta: {
      resource: "customer-file",
      fetchResponse: UPDATE,
      fetchStatus: FETCH_END,
      redirectTo: false,
    },
  };
};
