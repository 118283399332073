import React from "react";

import { Box, Button } from "@material-ui/core";

export default function AddSparePartNextStepButtonGroup({ handleValidate, handleClose, disabled }) {
  return (
    <Box textAlign="center" py={2} justifyContent="space-evenly" display="flex">
      <Box>
        <Button
          id="validate-spare-part"
          variant="contained"
          color="secondary"
          disabled={disabled}
          onClick={handleValidate}
        >
          Ajouter la pièce
        </Button>
      </Box>
      <Box>
        <Button id="cancel-spare-part" variant="string" onClick={handleClose}>
          Annuler
        </Button>
      </Box>
    </Box>
  );
}
