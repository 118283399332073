import React, { useEffect, useState } from "react";

import {
  ArrayField,
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  ImageField,
  List,
  SimpleShowLayout,
  TextField,
} from "react-admin";

import { Box, Button, Link, Typography, makeStyles } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";

import classNames from "classnames";
import { toastr } from "react-redux-toastr";

import dataProvider from "dataProvider";

import CurrencyField from "components/CurrencyField";
import CreateOrderLine from "components/OrderShow/components/OrderLine/CreateOrderLine/CreateOrderLine";
import EditOrderLine from "components/OrderShow/components/OrderLine/EditOrderLine/EditOrderLine";
import OrderLineActions from "components/OrderShow/components/OrderLine/OrderLineActions";
import RefundAdd from "components/OrderShow/components/OrderShowLayout/Refund/RefundAdd";
import RefundList from "components/OrderShow/components/OrderShowLayout/Refund/RefundList";
import { hasCollectionItems, isOrderCancelled, shouldOrderLineBeDisabled } from "components/OrderShow/utils";
import OrderStateField from "components/OrderStateField";
import { AppearancesChoices } from "components/ProductCaracteristicsInputs/ProductCaracteristicsInputs";

import { copyToClipboard } from "utils/CopyToClipboard";

import CustomerInformation from "./CustomerInformation.tsx";
import EditState from "./EditState";
import BillingAddressField from "./fieldComponents/BillingAddressField";
import CollectionField from "./fieldComponents/CollectionField";
import DeliveryAddressField from "./fieldComponents/DeliveryAddressField";
import DeliveryTypeField from "./fieldComponents/DeliveryTypeField";

const getAppearance = (orderline) => {
  const appearance = AppearancesChoices.find((a) => a.id === orderline.product.appearance);
  if (appearance) {
    return appearance.name;
  }
  return orderline.product.appearance;
};

const getDimensions = (orderline) => {
  const characteristics = orderline.product.functional_characteristics;
  if (
    characteristics &&
    characteristics.Dimensions &&
    characteristics.Dimensions.L &&
    characteristics.Dimensions.H &&
    characteristics.Dimensions.P
  ) {
    return `${characteristics.Dimensions.L}x${characteristics.Dimensions.H}x${characteristics.Dimensions.P}cm`;
  }
  return "–";
};

const getWeight = (orderline) => {
  const characteristics = orderline.product.functional_characteristics;
  if (characteristics && characteristics["Poids (en kg)"]) {
    return characteristics["Poids (en kg)"];
  }
  return "–";
};

// Split layout out to capture props of <Edit> children
const OrderShowLayout = (props) => {
  const [isCCV, setIsCCV] = useState(false);
  const classes = useStyle();

  useEffect(() => {
    if (props.record.last_mile_shipping_provider && props.record.last_mile_shipping_provider.name === "CCV") {
      setIsCCV(true);
    }
  }, [props.record]);
  const getProductName = (orderline) => {
    const name = `${orderline.product.brand} ${orderline.product.model_reference} ${orderline.product.product_type} ${
      orderline.product.subcategory1
    } ${orderline.product.installation_type ? orderline.product.installation_type.name : ""}`;
    if (shouldOrderLineBeDisabled(orderline)) {
      return <span className={classes.striked}>{name}</span>;
    }
    return name;
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString();
  };

  const getDeliveryDateRange = (record) =>
    `${formatDate(record.time_span_beginning)} - ${formatDate(record.time_span_end)}`;

  const handleDeliveryOrderDownload = () => {
    if (props.record && props.record.last_delivery) {
      dataProvider.getOne("delivery-CCV", { id: props.record.last_delivery }).then((response) => {
        if (response) {
          let a = document.createElement("a");
          a.href = "data:application/pdf;base64, " + response.data;
          a.download = `CCV ${props.record.id}.pdf`;
          a.click();
        }
      });
    }
  };
  const formattedEvent = () => {
    /*
      Formatting some values into a text needed for XPO or GEFCO deliveries.
      This solution works fine for now since no particular infos/formatting is needed. If it gets too much of a strain to maintain,
      then we'll adapt the serializer to format the different texts when getting the delivery 
    */
    let formattedEventObject = "";
    let orderlines = props.record.intent && props.record.intent.transactions;
    if (orderlines) {
      formattedEventObject += orderlines.map(
        (orderline) =>
          (formattedEventObject += `${orderline.product.product_type} ${orderline.product.functional_characteristics["Poids (en kg)"]} kg`)
      );
    }
    return `Objet: ${formattedEventObject}
    ${props.record.customer_lastname} ${props.record.customer_firstname}
    ${props.record.billing_street}, ${props.record.billing_zipcode}, ${props.record.billing_city}
    Étage: ${props.record.floor}
    Ascenseur: ${props.record.elevator ? "Oui" : "Non"}
    Reprise: ${hasCollectionItems(props.record) ? "Oui" : "Non"}
    Installation: ${props.record.type === "installation" ? "Oui" : "Non"}
    ${props.record.phone || props.record.customer_phone}\n
    Difficultés à prévoir: ${props.record.difficulties}\n
    nb: ${props.record.note}`;
  };
  return (
    <Box margin={3}>
      <Typography variant="h2">
        Commande {props.record.reference} <EditButton {...props} label="Modifier" />{" "}
      </Typography>
      {props.record && (
        <Button
          onClick={(event) =>
            copyToClipboard(formattedEvent(), event, toastr.success, "Texte de l'évènement google calendar copié")
          }
        >
          Copier le texte de l'évènement
        </Button>
      )}

      <SimpleShowLayout {...props} className={classNames(classes.layout, classes.inline)}>
        <CustomerInformation />
        <TextField source="reference" label="Référence" />
        <OrderStateField source="state" label="Statut" />
        <CurrencyField source="intent.total" label="Total" />
        <TextField source="note" label="Note" emptyText="-" className={classes.textField} />
        <TextField source="store" label="Revendeur" />
      </SimpleShowLayout>
      {isOrderCancelled(props.record) && (
        <Box marginLeft="auto" fontSize="10pt">
          Une commande annulée ne peut plus être modifiée.
        </Box>
      )}
      {!isOrderCancelled(props.record) && <EditState {...props} />}
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <SimpleShowLayout {...props} className={classNames(classes.layout, classes.column)}>
          <Typography variant="h2">Informations</Typography>
          <BillingAddressField label="Adresse de facturation" order={props.record} />
          <Button
            href={"data:application/pdf;base64," + props.record.invoice}
            download={"FACTURE_" + props.record.invoice_nb}
            color="primary"
          >
            <GetAppIcon />
            Télécharger la facture
          </Button>
          <Button onClick={handleDeliveryOrderDownload} color="primary">
            <GetAppIcon />
            Bon de commande CCV
          </Button>
          <Box mt={2} width="50%">
            <Typography variant="subtitle1">Historique des livraisons</Typography>
            <List
              {...props}
              resource="shipping/deliveries"
              basePath=""
              label=""
              exporter={false}
              actions={null}
              bulkActionButtons={false}
              filterDefaultValues={{ order: props.record.id }}
              pagination={<></>}
            >
              <Datagrid>
                {!isCCV ? (
                  <DateField source="time_span_beginning" label="Date de livraison" locales="fr-FR" />
                ) : (
                  <FunctionField label="Date de livraison" render={getDeliveryDateRange} />
                )}
                <TextField source="slot" label="Horaire" />
              </Datagrid>
            </List>
          </Box>
          {isCCV && (
            <Box mt={2} width="50%">
              <Typography className={classes.deliveryRangeLabel}>Lien de tracking</Typography>
              <Link
                target="_blank"
                href={`https://www.cchezvous.fr/suivi-colis/${props.record.reference}`}
                className={classes.deliveryRangeValue}
              >
                {`https://www.cchezvous.fr/suivi-colis/${props.record.reference}`}
              </Link>
            </Box>
          )}
        </SimpleShowLayout>
        <SimpleShowLayout {...props} className={classNames(classes.layout, classes.column)}>
          <Typography variant="h2">Livraison</Typography>
          {!isCCV && <DateField source="date" label="Date de livraison" locales="fr-FR" />}
          {!isCCV && <TextField source="slot" label="Créneau de livraison" />}
          {isCCV && <FunctionField label="Date de livraison" render={getDeliveryDateRange} />}
          <DeliveryTypeField label="Option de livraison" order={props.record} />
          <DeliveryAddressField label="Adresse de livraison" order={props.record} />
          <CollectionField label="Reprise" order={props.record} />
          <TextField source="last_mile_shipping_provider.name" label="Prestataire (dernier km)" />
          <TextField source="interregional_shipping_provider.name" label="Prestataire interrégional" />
        </SimpleShowLayout>
        <SimpleShowLayout {...props} className={classNames(classes.layout, classes.column)}>
          <Typography variant="h2">Achat</Typography>
          <DateField source="created_at" label="Date" showTime />
          <CurrencyField source="intent.total_without_delivery" label="Montant total (hors livraison)" />
          <CurrencyField source="intent.total" label="Montant total" />
          <CurrencyField source="intent.amount" label="Montant payé" />
          <TextField source="payment_solution" label="Prestataire de paiement" emptyText="–" />
          <TextField source="payment_solution_reference" label="Référence de paiement" emptyText="–" />
          <TextField source="intent.coupon" label="Code promo" emptyText="–" />
          <CurrencyField source="intent.coupon_amount" label="Montant code promo" />
        </SimpleShowLayout>
      </Box>
      <SimpleShowLayout {...props} className={classes.layout}>
        <ArrayField source="intent.transactions" label="">
          <Datagrid>
            <ImageField label="Visuel" source="product.selling_picture_1" className={classes.imageField} />
            <FunctionField label="Produit" render={getProductName} />
            <CurrencyField source="product.selling_price_withtax" label="Prix" />
            <CurrencyField source="shipping_price" label="Livraison" />
            <CurrencyField source="warranty_price" label="Garantie" />
            <TextField source="product.barcode" label="Code-barres" />
            <FunctionField label="Dimensions" render={getDimensions} />
            <FunctionField label="Poids" render={getWeight} />
            <FunctionField label="Apparence" render={getAppearance} />
            <TextField source="product.location" label="Emplacement" />
            <TextField source="external_package_number" label="Numéro de colis" />
            <OrderLineActions />
            <EditOrderLine orderCreationDate={props.record.created_at} />
          </Datagrid>
        </ArrayField>
        <CreateOrderLine />
      </SimpleShowLayout>
      {props.record.store === "murfy" && (
        <>
          <Typography variant="h2">Remboursements</Typography>
          <CurrencyField source="intent.refunds_amount" label="Montant total" />
          <RefundList order_id={props.record.intent.id} />
          <RefundAdd style={classes} {...props} />
        </>
      )}
    </Box>
  );
};

const useStyle = makeStyles((theme) => ({
  layout: {
    width: "100%",
    margin: 0,
  },
  column: {
    flex: 1,
    paddingTop: theme.spacing(2),
  },
  inline: {
    display: "flex",
    flexDirection: "row",
    paddingTop: theme.spacing(2),
    "& > .ra-field": {
      marginRight: theme.spacing(5),
    },
  },
  textField: {
    whiteSpace: "pre-wrap",
    maxWidth: 350,
  },
  imageField: {
    "& > img": {
      maxHeight: "5em",
    },
  },
  striked: {
    textDecoration: "line-through",
  },
  refundForm: {
    display: "flex",
    marginLeft: "auto",
    "& > div": {
      margin: 0,
      flex: 1,
      display: "flex",
    },
  },
  noteForm: {},
  deliveryRangeLabel: {
    fontSize: "13px",
    fontWeight: "400",
    color: "rgba(0, 0, 0, 0.54)",
    margingTop: "8px",
    marginBottom: "4px",
  },
  deliveryRangeValue: {
    color: "#2B3B5C",
    fontSize: "13px",
    fontWeight: "400",
    paddingTop: "8px",
    paddingBottom: "4px",
  },
}));

export default OrderShowLayout;
