import React, { useState } from "react";

import { List } from "react-admin";

import { Box, withStyles } from "@material-ui/core";

import CommentsIterator from "./components/CommentsIterator/CommentsIterator";

const FileComments = ({
  filter,
  customerFile,
  recipientRole,
  attachmentsEnabled = false,
  title,
  maxNumberOfComments,
  updateEnabled,
}) => {
  const [checked, setChecked] = useState(false);

  const StyledList = withStyles({
    root: {
      // toolbar
      "& > div:first-child": {
        display: "none",
      },
      // list content
      "& > div > div:first-child": {
        backgroundColor: "transparent",
        boxShadow: "none",
      },
    },
  })(List);

  return (
    <Box mt={2}>
      <StyledList
        record={customerFile}
        resource="comments"
        target="id"
        filter={filter}
        basePath=""
        hasCreate={false}
        hasEdit={false}
        hasList={false}
        hasShow={false}
        pagination={false}
        exporter={false}
        perPage={100}
        bulkActionButtons={false}
        // sort={{ field: "-id", order: "DESC" }} => Broken in this RA Version, workaround is putting it in the filter object
      >
        <CommentsIterator
          attachmentsEnabled={attachmentsEnabled}
          recipientRole={recipientRole}
          displayMore={checked}
          setDisplayMore={setChecked}
          customerFile={customerFile}
          title={title}
          maxNumberOfComments={maxNumberOfComments}
          updateEnabled={updateEnabled}
        />
      </StyledList>
    </Box>
  );
};

export default FileComments;
