import { CustomerFileDetailed } from "types/customer-files";
import { Employee } from "types/employees";
import { Visit } from "types/visits";

const getEmployeeIdFromVisit = (lastIntervention?: Visit) => {
  if (!lastIntervention) {
    return null;
  }
  if (!lastIntervention.timeslot) {
    return null;
  }
  return lastIntervention.timeslot.employee || null;
};

const getAssociatedEmployeeIdFromVisit = (lastIntervention?: Visit) => {
  if (!lastIntervention) {
    return null;
  }
  return lastIntervention.assigned_repairman ? parseInt(lastIntervention.assigned_repairman) : null;
};

export const getLastRepairmanOfFile = (customerFile: CustomerFileDetailed, employees: Employee[]) => {
  const employeeId = customerFile && customerFile.last_repairman && customerFile.last_repairman.id;

  if (!employeeId) {
    return;
  }
  return employees && employees.find((employee: Employee) => employee.id === employeeId);
};

export const getEmployeeOfLastVisit = (customerFile: CustomerFileDetailed, employees: Employee[]) => {
  const orderedVisits = customerFile.visits.sort(
    (a, b) => new Date(a.timeslot.beginning).valueOf() - new Date(b.timeslot.beginning).valueOf()
  );
  const lastVidit = orderedVisits.at(-1);
  let employeeId = getAssociatedEmployeeIdFromVisit(lastVidit);
  if (!employeeId) {
    employeeId = getEmployeeIdFromVisit(lastVidit);
  }
  if (!employeeId) {
    return;
  }
  return employees && employees.find((employee: Employee) => employee.id === employeeId);
};

export const getFileAssociatedRepairman = (customerFile: CustomerFileDetailed, employees: Employee[]) => {
  const orderedVisits = customerFile.visits.sort(
    (a, b) => new Date(a.timeslot.beginning).valueOf() - new Date(b.timeslot.beginning).valueOf()
  );
  const lastVidit = orderedVisits.at(-1);
  const employeeId = getEmployeeIdFromVisit(lastVidit);

  if (!employeeId) {
    return;
  }
  return employees && employees.find((employee: Employee) => employee.id === employeeId);
};
