import React from "react";

import Tooltip from "@material-ui/core/Tooltip";

import { useHomeRepairCovered } from "utils/hooks/useHomeRepairCovered";

import CoveredTag from "./CoveredTag";

type HomeRepairCoveredProps = {
  postalCode: string;
};

const getDisplayMessage = (isCovered: boolean) => {
  if (isCovered) {
    return {
      title: "Adresse desservie",
      label: "RD possible",
    };
  }
  return { title: "Adresse non desservie", label: "RD impossible" };
};

const HomeRepairCovered: React.FC<HomeRepairCoveredProps> = ({ postalCode }) => {
  const isCovered = useHomeRepairCovered(postalCode);
  if (isCovered === null) {
    return null;
  }

  const { title, label } = getDisplayMessage(isCovered);
  return (
    <Tooltip title={title}>
      <CoveredTag isCovered={isCovered} label={label} />
    </Tooltip>
  );
};

export default HomeRepairCovered;
