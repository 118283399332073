import React from "react";

import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { formatPrice } from "utils/price.js";

const useStyles = makeStyles((theme) => ({
  price: {
    fontWeight: 400,
    fontSize: 15,
  },
}));

export default ({ price, unavailabilityText = "Prix indisponible" }) => {
  const classes = useStyles();

  return (
    <Typography variant="subtitle2" className={classes.price}>
      {price ? formatPrice(price) : unavailabilityText}
    </Typography>
  );
};
