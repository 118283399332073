import moment from "moment";

export const getDate = (date) => {
  return moment(date).locale("fr").format("dddd DD MMMM");
};

export const dateToHour = (date) => {
  return moment(date).locale("fr-FR").format("LT");
};

export const getSlot = (beginning, end, separator = "et") => {
  return `${dateToHour(beginning)} ${separator} ${dateToHour(end)}`;
};

export const generateTimeslotsList = (start, end) => {
  if (end < start) {
    throw new Error("The end should be after the start!");
  }
  const timeslots = [];

  while (start <= end) {
    timeslots.push({ id: `${start}:00 et ${start + 2}:00`, name: `${start}h` });
    start += 1;
  }
  return timeslots;
};

export const formatDate = (date) => {
  if (!date) {
    return moment().locale("fr").format("dddd DD MMM");
  }
  return moment(date).locale("fr").format("dddd DD MMM");
};
