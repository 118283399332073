import React from "react";

import { Filter, ReferenceInput, SelectInput } from "react-admin";

import { Box } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";

import CustomFiltersTheme from "shared/themes/customFiltersTheme.js";

export default (props) => (
  <ThemeProvider theme={CustomFiltersTheme}>
    <Box mt={3}>
      <Filter {...props}>
        <ReferenceInput label="Zone" reference="zone" source="home_repair_zone" alwaysOn>
          <SelectInput optionText="name" optionValue="id" allowEmpty emptyText="Toutes" />
        </ReferenceInput>
        <ReferenceInput label="Type de produit" reference="product-types" source="product_type" alwaysOn>
          <SelectInput optionText="name" optionValue="id" allowEmpty emptyText="Tous" />
        </ReferenceInput>
      </Filter>
    </Box>
  </ThemeProvider>
);
