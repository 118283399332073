import React from "react";

import { Button } from "react-admin";

import GetApp from "@material-ui/icons/GetApp";

import dataProvider from "dataProvider";

const BulkExportButton = (props) => {
  const handleClick = () => {
    dataProvider.getMany("spare-parts", { ids: props.selectedIds }).then(({ data }) => {
      props.exporter(data);
    });
  };

  return (
    <Button onClick={() => handleClick()} label="Exporter CSV">
      <GetApp />
    </Button>
  );
};

export default BulkExportButton;
