import React, { Component } from "react";

import PropTypes from "prop-types";

import { DeleteOrReorderElements } from "admin/components";

import "./style.css";

export default class ElementaryBlock extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    isSelected: PropTypes.bool,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
      })
    ),
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    onDeleteElement: PropTypes.func,
    onDecreaseOrder: PropTypes.func,
    onIncreaseOrder: PropTypes.func,
  };

  static defaultProps = {
    isSelected: false,
  };

  render() {
    return (
      <div
        className={this.props.isSelected ? "elementary-block element-selected" : "elementary-block"}
        style={{ border: this.props.isSelected && "3px solid #ce9071" }}
        onClick={this.props.onClick}
      >
        <div className="row no-gutters full-width">
          <div className="col-8 elementary-block-text">
            <span>{this.props.text}</span>
          </div>
          <div className="col-4 elementary-block-icons-tags-container">
            {this.props.isSelected && (
              <div>
                <i className="fas fa-check elementary-block-icon" />
              </div>
            )}
            {this.props.tags &&
              this.props.tags.map((tag) => (
                <div key={`tag-${tag.id}`} className="elementary-block-tag">
                  <span>{tag.title}</span>
                </div>
              ))}

            <DeleteOrReorderElements
              handleDelete={this.props.onDeleteElement}
              handleOrderDecrease={this.props.onIncreaseOrder}
              handleOrderIncrease={this.props.onDecreaseOrder}
              id={this.props.id}
            />
          </div>
        </div>
      </div>
    );
  }
}
