import React, { Component } from "react";

import PropTypes from "prop-types";

import CircularProgress from "@material-ui/core/CircularProgress";

import sortBy from "lodash/sortBy";
import Moment from "moment";
import "moment/locale/fr";

import "./style.css";

const NoTimeslot = ({ postalCode }) => {
  return postalCode ? (
    <div className="custom-title">Pas de créneau disponible pour le code postal {String(postalCode)}</div>
  ) : (
    <div className="custom-title">Veuillez rentrer une adresse valide</div>
  );
};

export default class MurfyCalendar3by3 extends Component {
  constructor() {
    super();
    this.state = {
      isLeftChevronDisplayed: false,
      isInitialTimeslotsDisplay: true,
    };
  }

  static propTypes = {
    firstDisplayedDay: PropTypes.number.isRequired,
    getCalendarTimeslots: PropTypes.func.isRequired,
    handleClick: PropTypes.func,
    isAdmin: PropTypes.bool.isRequired,
    isCalendarLoading: PropTypes.bool.isRequired,
    nextTimeslot: PropTypes.object,
    postalCode: PropTypes.string,
    selectedTimeslotId: PropTypes.number,
    setFirstDisplayedDay: PropTypes.func.isRequired,
    resetTimeslots: PropTypes.func.isRequired,
    timeslots: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        beginning: PropTypes.string.isRequired,
        end: PropTypes.string.isRequired,
        nb_bookings: PropTypes.number.isRequired,
        priority: PropTypes.number,
        total_capacity: PropTypes.number.isRequired,
      })
    ),
    city: PropTypes.string,
    streetNumber: PropTypes.string,
    street: PropTypes.string,
  };

  static defaultProps = {
    timeslots: [],
  };

  componentDidMount() {
    // set firstDisplayedDay at today's date, time should be set at 0
    const currentDate = new Date(Date.now()).setUTCHours(0, 0, 0);
    this.fetchTimeslotsWithin3DaysFromDatetime(currentDate);
  }

  componentWillUnmount() {
    this.props.resetTimeslots();
  }

  componentDidUpdate(prevProps) {
    if (this.state.isInitialTimeslotsDisplay && this.props.timeslots) {
      this.setState({ isInitialTimeslotsDisplay: false });
      if (!this.props.timeslots.length && this.props.nextTimeslot) {
        this.goToNextTimeslot();
      }
    }
    if (prevProps.firstDisplayedDay !== this.props.firstDisplayedDay) {
      const currentDate = Date.now();
      if (this.props.firstDisplayedDay <= currentDate) {
        this.setState({ isLeftChevronDisplayed: false });
      } else {
        this.setState({ isLeftChevronDisplayed: true });
      }
    }
    if (prevProps.postalCode !== this.props.postalCode) {
      this.fetchTimeslotsWithin3DaysFromDatetime(this.props.firstDisplayedDay);
    }
  }

  handleTimeslotClick = (timeslot) => {
    this.props.setSelectedTimeslot(timeslot);
    this.props.handleClick(timeslot);
  };

  fetchTimeslotsWithin3DaysFromDatetime = (beginningTimestamp) => {
    const endTimestamp = this.addDaysToTimestamp(beginningTimestamp, 3);
    this.props.getCalendarTimeslots(
      this.props.postalCode || null,
      this.getPythonTimestamp(beginningTimestamp),
      this.getPythonTimestamp(endTimestamp),
      this.props.city,
      this.props.street,
      this.props.streetNumber
    );
    this.props.setFirstDisplayedDay(beginningTimestamp);
  };

  displayNextTimeslots = () => {
    if (!this.props.isCalendarLoading) {
      const beginningTimestamp = this.addDaysToTimestamp(this.props.firstDisplayedDay, 3);
      this.fetchTimeslotsWithin3DaysFromDatetime(beginningTimestamp);
    }
  };

  displayPreviousTimeslots = () => {
    if (!this.props.isCalendarLoading) {
      const currentDate = new Date(Date.now()).setUTCHours(0, 0, 0);
      const beginningTimestamp = Math.max(this.addDaysToTimestamp(this.props.firstDisplayedDay, -3), currentDate);
      this.fetchTimeslotsWithin3DaysFromDatetime(beginningTimestamp);
    }
  };

  getPythonTimestamp = (timestamp) => Math.floor(timestamp / 1000);

  addDaysToTimestamp = (datetime, nbDays) => datetime + 1000 * 3600 * 24 * nbDays;

  getDateFromDatetime = (datetime) => Moment(datetime).locale("fr").format("dddd DD MMMM");

  getTimeFromDatetime = (datetime) => Moment(datetime).locale("fr").format("HH:mm");

  getWeekdayFromDatetime = (datetime) => Moment(datetime).locale("fr").format("dddd");

  getDayAndMonthFromDatetime = (datetime) => Moment(datetime).locale("fr").format("DD MMM");

  getTimeslotsDates = () => {
    const dates = [];
    for (var i = 0; i < 3; i++) {
      dates[i] = this.addDaysToTimestamp(this.props.firstDisplayedDay, i);
    }
    return dates;
  };

  getCSSClassByTimeslotId = (timeslot) =>
    this.props.selectedTimeslotId && timeslot.id === this.props.selectedTimeslotId
      ? "murfy-calendar-timeslot-hours-container-selected"
      : "";

  getCSSClassByPriority = (timeslot) =>
    timeslot.priority && timeslot.priority === 100
      ? "murfy-calendart-timeslot-high-priority-container"
      : "murfy-calendar-timeslot-hours-container";

  getNextTimeslotMessageCSSClass = () =>
    !this.props.isCalendarLoading && this.props.timeslots.length === 0 && this.props.nextTimeslot
      ? "next-timeslot-message"
      : "no-next-timeslot-message";

  goToNextTimeslot = () => {
    const beginningDatetime = Moment(this.props.nextTimeslot.beginning);
    beginningDatetime.utc().set({ hour: 0, m: 0, s: 0, ms: 0 });
    const beginningTimestamp = beginningDatetime.unix() * 1000;
    this.props.setFirstDisplayedDay(beginningTimestamp);
    this.props.getCalendarTimeslots(this.props.postalCode || null, this.getPythonTimestamp(beginningTimestamp), null);
  };

  renderNextTimeslotMessage = () => {
    return (
      <div className="murfy-calendar-next-timeslot-container">
        <button onClick={this.goToNextTimeslot} className="quick-booking-transition-button">
          Prochain rdv le {this.getDayAndMonthFromDatetime(this.props.nextTimeslot.beginning)} {">"}
        </button>
      </div>
    );
  };

  renderLoadingCalendar = () => {
    return (
      <div className="murfy-calendar-loader-container">
        <CircularProgress color="inherit" />
      </div>
    );
  };

  renderCalendarContent = () => {
    if (this.props.isCalendarLoading) {
      return this.renderLoadingCalendar();
    } else if (this.props.timeslots.length > 0) {
      return this.renderLoadedCalendar();
    } else if (this.props.nextTimeslot) {
      return this.renderNextTimeslotMessage();
    } else {
      return <NoTimeslot postalCode={this.props.postalCode} />;
    }
  };

  renderLoadedCalendar = () => {
    return this.getTimeslotsDates().map((datetime) => this.renderDayCalendar(datetime));
  };

  renderDayCalendar = (datetime) => {
    const timeslots = this.props.timeslots.filter(
      (timeslot) => this.getDateFromDatetime(timeslot.beginning) === this.getDateFromDatetime(datetime)
    );

    return timeslots.length > 0 ? (
      <div
        key={this.getDateFromDatetime(datetime)}
        className="murfy-calendar-day-timeslots-container"
        style={{ width: "33%" }}
      >
        {this.renderDayTimeslotsCalendar(timeslots)}
      </div>
    ) : (
      <div key={this.getDateFromDatetime(datetime)} className="murfy-calendar-day-timeslots-container complete">
        <div className="murfy-calendar-complete-day" />
      </div>
    );
  };

  renderDayTimeslotsCalendar = (timeslots) => {
    return sortBy(timeslots, "beginning").map((timeslot) => (
      <div
        key={timeslot.id}
        className={`${this.getCSSClassByPriority(timeslot)} ${this.getCSSClassByTimeslotId(timeslot)}`}
        onClick={() => this.handleTimeslotClick(timeslot)}
      >
        <p>
          {this.getTimeFromDatetime(timeslot.beginning)} - {this.getTimeFromDatetime(timeslot.end)}
        </p>
        {timeslot.priority && <p>Priorité : {timeslot.priority}</p>}
      </div>
    ));
  };

  render() {
    const { isLeftChevronDisplayed } = this.state;
    return (
      <div className="murfy-calendar-container">
        <div className="row murfy-calendar-top-bar-container">
          <div
            id="chevron-left-button"
            className="murfy-calendar-chevron-container"
            onClick={this.displayPreviousTimeslots}
          >
            {isLeftChevronDisplayed && <i className="fas fa-chevron-left" />}
          </div>
          <div className="murfy-calendar-days-container">
            {this.getTimeslotsDates().map((datetime) => (
              <div
                key={this.getDateFromDatetime(datetime)}
                className="murfy-calendar-day-container"
                style={{ width: "33%" }}
              >
                <p className="murfy-calendar-day-weekday">{`${this.getWeekdayFromDatetime(
                  datetime
                )} ${this.getDayAndMonthFromDatetime(datetime)}`}</p>
              </div>
            ))}
          </div>
          <div
            id="chevron-right-button"
            className="murfy-calendar-chevron-container"
            onClick={this.displayNextTimeslots}
          >
            {this.props.nextTimeslot && <i className="fas fa-chevron-right" />}
          </div>
        </div>
        <div className={`row murfy-calendar-timeslots-container ${this.getNextTimeslotMessageCSSClass()}`}>
          {this.renderCalendarContent()}
        </div>
      </div>
    );
  }
}
