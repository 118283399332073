import React, { useState } from "react";

import moment from "moment";
import { CustomerFileDetailed } from "types/customer-files";
import { Timeslot } from "types/timeslot";

import Calendar from "components/organisms/Calendar";

import { useTimeslots } from "utils/hooks/useTimeslots";

type CalendarContainerProps = {
  selectedRepairmanId: string | undefined;
  selectedType: string | undefined;
  minimumCapacity: number;
  onSelect: (timeslot: Timeslot) => void;
  isSelected: (timeslot: Timeslot) => boolean;
  customerFile: CustomerFileDetailed;
};

const CalendarContainer: React.FC<CalendarContainerProps> = ({
  selectedRepairmanId,
  selectedType = "first_visit",
  minimumCapacity,
  onSelect,
  isSelected,
  customerFile,
}) => {
  const [selectedDate, setSelectedDate] = useState<moment.Moment>(moment().set({ hour: 0, minute: 0, second: 0 }));
  const queryParams = {
    employee_id: selectedRepairmanId || "",
    start_date: selectedDate.format("YYYY-MM-DD"),
    end_date: moment(selectedDate).add(3, "days").format("YYYY-MM-DD"),
    type: selectedType,
    minimum_capacity: minimumCapacity,
    postal_code: customerFile.postal_code,
    city: customerFile.city,
    street: customerFile.street,
    street_number: customerFile.street_number,
    has_cold_appliance: customerFile.has_cold_appliance,
    beginning: selectedDate.format(),
    end: moment(selectedDate).add(3, "days").format(),
    employee: selectedRepairmanId || "",
  };

  const { data: timeslots, isLoading: loadingTimeslots, isError, error } = useTimeslots(queryParams);
  return (
    <Calendar
      onSelect={onSelect}
      isSelected={isSelected}
      timeslots={timeslots || []}
      selectedDate={selectedDate}
      setSelectedDate={setSelectedDate}
      loadingTimeslots={loadingTimeslots}
      isError={isError}
      error={error}
    />
  );
};

export default CalendarContainer;
