// React imports
import React from "react";

// MUI imports
import { Box, Button, useTheme } from "@material-ui/core";
// MUI Icons imports
import CreateIcon from "@material-ui/icons/Create";

// Components
import {
  ButtonsPendingAnswerQuote,
  ButtonsQuoteAccepted,
} from "pages/customerFiles/components/CustomerFileBillingQuotes/components/button";

import QuoteBalanceSummary from "./components/QuoteBalanceSummary";

const QuoteDetailsFooter = ({
  isCreationQuote,
  isNewQuoteFormOpen,
  record,
  isReadyToDisplay,
  handleDisplayNewQuoteForm,
  openAddPaymentModal,
  handleClosePaymentModal,
  handleValidatePayment,
  isAddPaymentModalOpen,
  employees,
  customerFile,
  visits,
  amountPaymentIntent,
  totalAmountPayment,
  totalAmountQuote,
  classes,
  quoteTotalPaymentAmountCents,
  handleClickhandleUpdateQuote,
  leftToPayQuoteCents,
  handleChangeSendConfirmationEmail,
  sendConfirmationEmail,
  leftToPayEditSessionAmountCents,
  isValidateButtonDisabled,
  cancelQuoteEdition,
  handleQuoteValidation,
}) => {
  const theme = useTheme();
  return (
    <>
      {isCreationQuote && (
        <>
          <QuoteBalanceSummary
            customerFile={record}
            leftToPay={leftToPayEditSessionAmountCents}
            alreadyPaid={quoteTotalPaymentAmountCents}
            editionSession
          />
          <Box mt={3} ml={3} display="flex" justifyContent="end">
            <Box display="flex" mr={2} color={theme.palette.secondary.grey900}>
              <Button color="inherit" variant="text" onClick={cancelQuoteEdition}>
                Annuler
              </Button>
            </Box>
            <Button
              id="create-new-quote-button"
              variant="contained"
              color="primary"
              className={classes.validateNewQuoteButton}
              onClick={handleQuoteValidation}
              disabled={isValidateButtonDisabled}
            >
              Valider le devis
            </Button>
          </Box>
        </>
      )}
      {!isCreationQuote && !isNewQuoteFormOpen && (
        <Box>
          <QuoteBalanceSummary
            customerFile={record}
            leftToPay={leftToPayQuoteCents}
            alreadyPaid={quoteTotalPaymentAmountCents}
            editionSession
          />
          {isReadyToDisplay && (
            <>
              {record.quote_accepted && (
                <ButtonsQuoteAccepted
                  handleDisplayNewQuoteForm={handleDisplayNewQuoteForm}
                  openAddPaymentModal={openAddPaymentModal}
                  handleClosePaymentModal={handleClosePaymentModal}
                  handleValidatePayment={handleValidatePayment}
                  isAddPaymentModalOpen={isAddPaymentModalOpen}
                  employees={employees}
                  customerFile={customerFile}
                  visits={visits}
                  amountPaymentIntent={amountPaymentIntent}
                  totalAmountQuote={totalAmountQuote}
                  totalAmountPayment={totalAmountPayment}
                  elements={record.quote_elements}
                />
              )}
              {record.quote_refused && (
                <Box pt={3}>
                  <Button
                    id="create-new-quote-button"
                    variant="contained"
                    color="secondary"
                    className={classes.addNewQuoteButton}
                    onClick={handleDisplayNewQuoteForm}
                  >
                    Modifier le devis
                    {
                      <Box ml={2}>
                        <CreateIcon />
                      </Box>
                    }
                  </Button>
                </Box>
              )}
              {!record.quote_refused && !record.quote_accepted && (
                <ButtonsPendingAnswerQuote
                  classes={classes}
                  handleDisplayNewQuoteForm={handleDisplayNewQuoteForm}
                  handleUpdateQuote={handleClickhandleUpdateQuote}
                  handleChangeSendConfirmationEmail={handleChangeSendConfirmationEmail}
                  sendConfirmationEmail={sendConfirmationEmail}
                />
              )}
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default QuoteDetailsFooter;
