import React from "react";

import { TextInput } from "react-admin";

import { withStyles } from "@material-ui/core/styles";

const StyledTextInput = withStyles({
  height: 56,
  "& p": {
    display: "none",
  },
})(TextInput);

export default function RefInput({
  variant = "outlined",
  margin = "none",
  label = "Référence",
  value,
  onChange,
  name = "Ref",
  fullWidth = false,
  helperText = "",
}) {
  return (
    <StyledTextInput
      fullWidth={fullWidth}
      variant={variant}
      margin={margin}
      label={label}
      value={value}
      onChange={(event) => onChange(event.target.value.trim())}
      name={name}
      helperText={helperText}
    />
  );
}
