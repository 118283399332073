const DelaySelectionModalStyles = {
  DelaySelectionModalContainer: {
    borderBottom: "2px solid #F4F3F8",
    textAlign: "center",
    padding: "32px 32px",
  },
  modalTitle: {
    textAlign: "center",
    padding: "37px 0px",
  },
  simpleForm: {
    textAlign: "center",
    padding: "16px 0px",
    marginLeft: "100px",
    marginRight: "100px",
  },

  inputValue: {
    textAlign: "center",
    height: 56,
    width: 360,
  },

  buttonContainer: {
    textAlign: "center",
    marginBottom: "48px",
  },
  button: {
    fontWeight: 600,
    lineHeight: "150%",
    backgroundColor: "#4A55FE",
    "&:hover": {
      backgroundColor: "#3b44cb",
    },
    color: "#FFF",
  },
  cancelButton: {
    color: "#8795B3",
    background: "none",
    padding: "16px 32px 16px 32px",
  },
};

export default DelaySelectionModalStyles;
