import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#01132d",
      main: "#2b3956",
      dark: "#566383",
      contrastText: "#ffffff",
      white: "#FFFFFF",
    },
    secondary: {
      light: "#ffff91",
      main: "#fcd660",
      dark: "#c6a52f",
      contrastText: "#2B3B5C",
      grey700: "#787878",
      grey900: "#8795B3",
      greyHome: "#D7D7D7",
      lightGrey: "#f2f2f2",
      honey: "#F7B349",
      purple: "#CB77FE",
      green: "#81D89F",
      red: "#F85B5B",
      contrastBackground: "#F4F3F8",
    },
  },
  typography: {
    h1: {
      // title 1
      fontSize: "28px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "120%",
      fontFamily: "Poppins, Roboto, Helvetica, Arial, sans-serif",
    },
    h2: {
      // title 2
      color: "#2B3956",
      fontSize: 26,
      lineHeight: 1.4,
      fontWeight: 600,
      paragraphSpacing: 16.5,
      fontFamily: "Poppins, Roboto, Helvetica, Arial, sans-serif",
    },
    h5: {
      fontSize: 14,
      fontStyle: "Book",
      lineHeight: 1.4,
      fontWeight: 600,
      color: "#555c66",
      fontFamily: "Poppins, Roboto, Helvetica, Arial, sans-serif",
    },
    subtitle1: {
      // subtitle
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "19px",
      lineHeight: "140%",
      color: "#2B3956",
      fontFamily: "Poppins, Roboto, Helvetica, Arial, sans-serif",
    },
    subtitle2: {
      color: "#2B3B5C",
      fontSize: 16,
      lineHeight: 1.4,
      fontWeight: 600,
      fontFamily: "Poppins, Roboto, Helvetica, Arial, sans-serif",
    },
    caption: {
      fontSize: 13,
      fontStyle: "normal",
      lineHeight: 1.4,
      paragraphSpacing: 16.5,
      color: "#555c66",
      fontFamily: "Poppins, Roboto, Helvetica, Arial, sans-serif",
    },
    body1: {
      fontSize: 16,
      fontStyle: "Book",
      lineHeight: 1.4,
      paragraphSpacing: 16.5,
      fontFamily: "Poppins, Roboto, Helvetica, Arial, sans-serif",
    },
    body2: {
      // small
      fontSize: 13,
      fontStyle: "normal",
      lineHeight: 1.5,
      paragraphSpacing: 16.5,
      fontFamily: "Poppins, Roboto, Helvetica, Arial, sans-serif",
      color: "#2B3B5C",
    },
    button: {
      fontSize: 14,
      textTransform: "uppercase",
    },
    textPrimary: {
      color: "#000000",
    },
    textSecondary: {
      color: "#8795B3",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: "4px",
        color: "#2B3B5C",
        padding: "16px",
        fontFamily: "Poppins, Roboto, Helvetica, Arial, sans-serif",
        fontSize: "12px",
        fontWeight: 600,
      },
    },
    MuiDialog: {
      root: {
        maxWidth: "100vw",
      },
      paperWidthSm: {
        maxWidth: "100vw",
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        "&$checked": {
          color: "#F7B349",
        },
      },
      colorSecondary: {
        "&$checked": {
          color: "#8795B3",
        },
      },
    },
    MuiRadio: {
      colorSecondary: {
        "&$checked": {
          color: "#8795B3",
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
      },
    },
    MuiInput: {
      root: {
        color: "#2B3B5C",
      },
    },
    MuiSvgIcon: {
      colorSecondary: {
        fontSize: "19px",
        position: "absolute",
        marginLeft: "5px",
      },
    },
    MuiNativeSelect: {
      select: {
        fontFamily: "Poppins",
      },
    },
    MuiTableRow: {
      root: {
        height: 48,
        color: "#2B3B5C",
      },
    },
    MuiTableCell: {
      root: {
        color: "#2B3B5C",
        borderBottom: "1px solid #DBDFE8",
      },
      head: {
        color: "#2B3B5C",
        fontSize: 13,
        fontWeight: 600,
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: "#F4F3F8",
        border: "1px solid rgba(219, 223, 232, 0.4)",
        borderRadius: "12px",
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px",
        color: "#2B3B5C",
        width: "100%",
      },
      underline: {
        "&:before": {
          borderBottom: "none",
        },
        "&:hover:not($disabled):before": {
          display: "none",
        },
        "&:after": {
          borderBottom: "none",
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        width: "100%",
        height: "100%",
      },
    },
    MuiCardContent: {
      root: {
        paddingLeft: 0,
        paddingRight: 0,
        width: "fit-content",
        margin: "auto",
      },
    },
    RaEdit: {
      card: {
        boxShadow: "none",
      },
    },
    RaCreate: {
      card: {
        boxShadow: "none",
      },
    },
    RaSaveButton: {
      button: {
        color: "#2B3B5C",
        backgroundColor: "#FCD660",
        borderRadius: 12,
      },
      leftIcon: {
        display: "none",
      },
    },
    RaButton: {
      smallIcon: {
        fontSize: 24,
      },
    },
    RaFilterFormInput: {
      spacer: {
        display: "none",
      },
    },
    MuiStepIcon: {
      root: {
        color: "#DBDFE8",
        width: 32,
        height: 32,
      },
    },
    MuiStepper: {
      root: {
        paddingBottom: 0,
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        '&[class*="MuiOutlinedInput-root"]': {
          paddingRight: 8,
          padding: 8,
        },
      },
    },
    RaListToolbar: {
      actions: {
        paddingTop: 8,
      },
    },
    RaToolbar: {
      toolbar: {
        backgroundColor: "transparent",
      },
    },
    RaBulkActionsToolbar: {
      toolbar: {
        backgroundColor: "white",
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        boxSizing: "border-box",
      },
      title: {
        display: "none",
      },
    },
    RaList: {
      bulkActionsDisplayed: {
        marginTop: 0,
      },
    },
    MuiSelect: {
      filled: {
        textAlign: "left",
      },
      select: {
        "&:focus": {
          backgroundColor: "unset",
        },
      },
    },
    MuiExpansionPanelSummary: {
      expandIcon: {
        color: "#8795B3",
        "&.Mui-expanded": {
          transform: "rotate(90deg)",
        },
      },
    },
  },
});

export default theme;
