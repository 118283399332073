/**
 * Following the duck pattern, actions, constants and reducers are in the same file called module.js
 *
 * See: https://github.com/erikras/ducks-modular-redux
 *
 */
import { getCompanyEmployeeSelector } from "admin/pages/Company/module";
import find from "lodash/find";
import get from "lodash/get";
import includes from "lodash/includes";
import unionBy from "lodash/unionBy";
import { getEmployees } from "modules/generators/getEmployeesGenerator";
import { getStates } from "modules/generators/getTicketsStatesGenerator";
import { toastr } from "react-redux-toastr";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import groupElementsByKeys from "utils/groupElementsByKeys";
import { getTokenFromLocalStorage, request } from "utils/networking";

export const INCREASE_TICKETS_PAGE_NUMBER = "Tickets/INCREASE_TICKETS_PAGE";
export const DECREASE_TICKETS_PAGE_NUMBER = "Tickets/DECREASE_TICKETS_PAGE";
export const RESET_TICKETS_PAGE_NUMBER = "Tickets/RESET_TICKETS_PAGE_NUMBER";

export const GET_TICKETS_REQUEST = "Tickets/GET_TICKETS_REQUEST";
export const GET_TICKETS_SUCCESS = "Tickets/GET_TICKETS_SUCCESS";
export const GET_TICKETS_ERROR = "Tickets/GET_TICKETS_ERROR";
export const PATCH_TICKET_EMAIL_REQUEST = "Tickets/PATCH_TICKET_EMAIL_REQUEST";
export const PATCH_TICKET_TAG_REQUEST = "Tickets/PATCH_TICKET_TAG_REQUEST";
export const PATCH_TICKET_STATE_REQUEST = "Tickets/PATCH_TICKET_STATE_REQUEST";
export const PATCH_TICKET_SUCCESS = "Tickets/PATCH_TICKET_SUCCESS";
export const PATCH_TICKET_ERROR = "Tickets/PATCH_TICKET_ERROR";
export const PATCH_TICKET_ADMIN_COMMENTS_REQUEST = "Tickets/PATCH_TICKET_ADMIN_COMMENTS_REQUEST";
export const PATCH_IS_PAYMENT_DONE_REQUEST = "Tickets/PATCH_IS_PAYMENT_DONE_REQUEST";

export const GET_STATES_REQUEST = "Tickets/GET_STATES_REQUEST";
export const GET_STATES_SUCCESS = "Tickets/GET_STATES_SUCCESS";
export const GET_STATES_ERROR = "Tickets/GET_STATES_ERROR";

export const GET_TAGS_REQUEST = "Tickets/GET_TAGS_REQUEST";
export const GET_TAGS_SUCCESS = "Tickets/GET_TAGS_SUCCESS";
export const GET_TAGS_ERROR = "Tickets/GET_TAGS_ERROR";
export const POST_TAG_REQUEST = "Tickets/POST_TAG_REQUEST";
export const POST_TAG_SUCCESS = "Tickets/POST_TAG_SUCCESS";
export const POST_TAG_ERROR = "Tickets/POST_TAG_ERROR";
export const DELETE_TAG_REQUEST = "Tickets/DELETE_TAG_REQUEST";
export const DELETE_TAG_SUCCESS = "Tickets/DELETE_TAG_SUCCESS";
export const DELETE_TAG_ERROR = "Tickets/DELETE_TAG_ERROR";

export const GET_EMAIL_TEMPLATES_REQUEST = "Tickets/GET_EMAIL_TEMPLATES_REQUEST";
export const GET_EMAIL_TEMPLATES_SUCCESS = "Tickets/GET_EMAIL_TEMPLATES_SUCCESS";
export const GET_EMAIL_TEMPLATES_ERROR = "Tickets/GET_EMAIL_TEMPLATES_ERROR";

export const GET_EMPLOYEES_REQUEST = "Ticket/GET_EMPLOYEES_REQUEST";
export const GET_EMPLOYEES_SUCCESS = "Ticket/GET_EMPLOYEES_SUCCESS";
export const GET_EMPLOYEES_ERROR = "Ticket/GET_EMPLOYEES_ERROR";

export const POST_EMAIL_REQUEST = "Tickets/POST_EMAIL_REQUEST";
export const POST_EMAIL_SUCCESS = "Tickets/POST_EMAIL_SUCCESS";
export const POST_EMAIL_ERROR = "Tickets/POST_EMAIL_ERROR";
export const SHOW_NO_TOASTR = "Tickets/SHOW_NO_TOASTR";

export const SET_SELECTED_TICKET = "Tickets/SET_SELECTED_TICKET";
export const SET_SELECTED_EMAIL_TEMPLATE = "Tickets/SET_SELECTED_EMAIL_TEMPLATE";
export const SET_LASTNAME_FILTER = "Tickets/SET_LASTNAME_FILTER";
export const RESET_LASTNAME_FILTER = "Tickets/RESET_LASTNAME_FILTER";
export const SET_TICKET_NUMBER_FILTER = "Tickets/SET_TICKET_NUMBER_FILTER";
export const RESET_TICKET_NUMBER_FILTER = "Tickets/RESET_TICKET_NUMBER_FILTER";
export const SET_EMPLOYEE_FILTER = "Tickets/SET_EMPLOYEE_FILTER";
export const RESET_EMPLOYEE_FILTER = "Tickets/RESET_EMPLOYEE_FILTER";
export const SET_TYPE_FILTER = "Tickets/SET_TYPE_FILTER";
export const SET_STATE_FILTER = "Tickets/SET_STATE_FILTER";
export const SET_DESCRIPTION_FILTER = "Tickets/SET_DESCRIPTION_FILTER";
export const RESET_DESCRIPTION_FILTER = "Tickets/RESET_DESCRIPTION_FILTER";
export const SET_TAG_FILTER = "Tickets/SET_TAG_FILTER";
export const SET_EMAIL_FILTER = "Tickets/SET_EMAIL_FILTER";
export const RESET_EMAIL_FILTER = "Tickets/RESET_EMAIL_FILTER";

export function increaseTicketsPageNumber(pageNumber) {
  return {
    type: INCREASE_TICKETS_PAGE_NUMBER,
    payload: pageNumber,
  };
}

export function decreaseTicketsPageNumber(pageNumber) {
  return {
    type: DECREASE_TICKETS_PAGE_NUMBER,
    payload: pageNumber,
  };
}

export function resetTicketsPageNumber() {
  return {
    type: RESET_TICKETS_PAGE_NUMBER,
  };
}

export function getTicketsRequest() {
  return {
    type: GET_TICKETS_REQUEST,
  };
}

export function getTicketsSuccess(tickets) {
  return {
    type: GET_TICKETS_SUCCESS,
    payload: tickets,
  };
}

export function getTicketsError(error) {
  return {
    type: GET_TICKETS_ERROR,
    payload: error,
  };
}

export function setSelectedTicketId(ticketId) {
  return {
    type: SET_SELECTED_TICKET,
    payload: ticketId,
  };
}

export function setLastnameFilter(lastnameFilter) {
  return {
    type: SET_LASTNAME_FILTER,
    payload: lastnameFilter,
  };
}

export function resetLastnameFilter() {
  return {
    type: RESET_LASTNAME_FILTER,
  };
}

export function setTicketNumberFilter(ticketNumberFilter) {
  return {
    type: SET_TICKET_NUMBER_FILTER,
    payload: ticketNumberFilter,
  };
}

export function resetTicketNumberFilter() {
  return {
    type: RESET_TICKET_NUMBER_FILTER,
  };
}

export function setTypeFilter(typeId) {
  return {
    type: SET_TYPE_FILTER,
    payload: typeId,
  };
}

export function setStateFilter(stateId) {
  return {
    type: SET_STATE_FILTER,
    payload: stateId,
  };
}

export function setDescriptionFilter(descriptionFilter) {
  return {
    type: SET_DESCRIPTION_FILTER,
    payload: { descriptionFilter },
  };
}

export function resetDescriptionFilter() {
  return {
    type: RESET_DESCRIPTION_FILTER,
  };
}

export function setEmployeeFilter(employeeFilter) {
  return {
    type: SET_EMPLOYEE_FILTER,
    payload: employeeFilter,
  };
}

export function resetEmployeeFilter() {
  return {
    type: RESET_EMPLOYEE_FILTER,
  };
}

export function setEmailFilter(emailFilter) {
  return {
    type: SET_EMAIL_FILTER,
    payload: emailFilter,
  };
}

export function resetEmailFilter() {
  return {
    type: RESET_EMAIL_FILTER,
  };
}

export function setTagFilter(tagId) {
  return {
    type: SET_TAG_FILTER,
    payload: { tagId },
  };
}

export function getTagsRequest() {
  return {
    type: GET_TAGS_REQUEST,
  };
}

export function getTagsSuccess(tags) {
  return {
    type: GET_TAGS_SUCCESS,
    payload: tags,
  };
}

export function getTagsError(error) {
  return {
    type: GET_TAGS_ERROR,
    payload: error,
  };
}

export function getStatesRequest() {
  return {
    type: GET_STATES_REQUEST,
  };
}

export function getStatesSuccess(states) {
  return {
    type: GET_STATES_SUCCESS,
    payload: states,
  };
}

export function getStatesError(error) {
  return {
    type: GET_STATES_ERROR,
    payload: error,
  };
}

export function getEmailTemplatesRequest() {
  return {
    type: GET_EMAIL_TEMPLATES_REQUEST,
  };
}

export function getEmailTemplatesSuccess(emailTemplates) {
  return {
    type: GET_EMAIL_TEMPLATES_SUCCESS,
    payload: emailTemplates,
  };
}

export function getEmailTemplatesError(error) {
  return {
    type: GET_EMAIL_TEMPLATES_ERROR,
    payload: error,
  };
}

export function setSelectedEmailTemplateId(emailTemplateId) {
  return {
    type: SET_SELECTED_EMAIL_TEMPLATE,
    payload: emailTemplateId,
  };
}

export function postTagRequest(color, text) {
  return {
    type: POST_TAG_REQUEST,
    payload: { color, text },
  };
}

export function postTagSuccess(tag) {
  return {
    type: POST_TAG_SUCCESS,
    payload: tag,
  };
}

export function postTagError(error) {
  return {
    type: POST_TAG_ERROR,
    payload: error,
  };
}

export function deleteTagRequest(tagId) {
  return {
    type: DELETE_TAG_REQUEST,
    payload: tagId,
  };
}

export function deleteTagSuccess(tagId) {
  return {
    type: DELETE_TAG_SUCCESS,
    payload: tagId,
  };
}

export function deleteTagError(error) {
  return {
    type: DELETE_TAG_ERROR,
    payload: error,
  };
}

export function patchTicketEmailRequest(ticketId, email) {
  return {
    type: PATCH_TICKET_EMAIL_REQUEST,
    payload: { ticketId, email },
  };
}

export function patchTicketTagRequest(ticketId, tags) {
  return {
    type: PATCH_TICKET_TAG_REQUEST,
    payload: { ticketId, tags },
  };
}

export function patchTicketStateRequest(ticketId, ticketState) {
  return {
    type: PATCH_TICKET_STATE_REQUEST,
    payload: { ticketId, ticketState },
  };
}

export function patchTicketSuccess(ticket) {
  return {
    type: PATCH_TICKET_SUCCESS,
    payload: ticket,
  };
}

export function patchTicketError(error) {
  return {
    type: PATCH_TICKET_ERROR,
    payload: error,
  };
}

export function patchTicketAdminCommentsRequest(ticketId, adminComments) {
  return {
    type: PATCH_TICKET_ADMIN_COMMENTS_REQUEST,
    payload: { ticketId, adminComments },
  };
}

export function showNoToastr() {
  return {
    type: SHOW_NO_TOASTR,
  };
}

export function patchIsPaymentDoneRequest(ticketId, isPaymentDone) {
  return {
    type: PATCH_IS_PAYMENT_DONE_REQUEST,
    payload: { ticketId, isPaymentDone },
  };
}

export function getEmployeesRequest() {
  return {
    type: GET_EMPLOYEES_REQUEST,
  };
}

export function getEmployeesSuccess(employees) {
  return {
    type: GET_EMPLOYEES_SUCCESS,
    payload: employees,
  };
}

export function getEmployeesError(error) {
  return {
    type: GET_EMPLOYEES_ERROR,
    payload: error,
  };
}

export const getLastnameFilterSelector = (state) => state.tickets.lastnameFilter;

export const getTicketNumberFilterSelector = (state) => state.tickets.ticketNumberFilter;

export const getTicketsPageNumberFilterSelector = (state) => state.tickets.ticketsPageNumberFilter;

export const getStateFilterSelector = (state) => state.tickets.stateFilter;

export const getTypeFilterSelector = (state) => state.tickets.typeFilter;

export const getDescriptionFilterSelector = (state) => state.tickets.descriptionFilter;

export const getEmployeeFilterIdSelector = (state) =>
  get(find(state.tickets.employees, { user: state.tickets.employeeFilter }), "id", null);

export const getStateTransitionsSelector = (state, stateId) =>
  get(find(state.tickets.states, { id: stateId }), "transitions");

export const getTicketStatesForActiveTypeSelector = (state) =>
  state.tickets.states && state.tickets.states.filter((ticketState) => ticketState.type === state.tickets.typeFilter);

export const getFilteredTicketsSelector = (state) => {
  const { tickets, stateFilter, typeFilter } = state.tickets;

  if (stateFilter) return tickets && tickets.filter((ticket) => ticket.ticket_state === stateFilter);
  if (typeFilter) {
    const typeStatesIds = getTicketStatesForActiveTypeSelector(state).map((states) => states.id);
    return tickets && tickets.filter((ticket) => includes(typeStatesIds, ticket.ticket_state));
  }
  return tickets;
};

export const getTagFilterSelector = (state) => state.tickets.tagFilter;

export const getSelectedEmailTemplateContent = (state, templateId) => {
  const { emailTemplates, selectedEmailTemplateId } = state.tickets;
  return get(find(emailTemplates, { id: selectedEmailTemplateId }), "content");
};

export function getTypesSelector(state) {
  return (
    state.tickets.types && [
      ...state.tickets.types,
      {
        id: -100,
        name: "TOUS LES TICKETS",
        order: 0,
      },
    ]
  );
}

export function getStatesGroupedByTypeSelector(state) {
  const keys = [
    { keyName: "counter", pathToKey: "type.counter", isNumber: true },
    { keyName: "id", pathToKey: "type.id", isNumber: true },
    { keyName: "name", pathToKey: "type.name" },
    { keyName: "order", pathToKey: "type.order" },
  ];
  return [
    {
      id: null,
      name: "Tous les tickets",
      order: 0,
      elements: [],
    },
    ...groupElementsByKeys(state.tickets.states, keys),
  ];
}

export const getEmailFilterSelector = (state) => state.tickets.emailFilter;

export const getFormatedPaymentMethod = (paymentMethod) => {
  switch (paymentMethod) {
    case "credit_card":
      return "Carte bancaire";
    case "check":
      return "Chèque";
    case "cash":
      return "Espèces";
    case "prepayed_online":
      return "Prépayé en ligne";
    case "payed_online":
      return "Payé en ligne";
    default:
      return "";
  }
};

export function* getTickets(action) {
  const token = yield call(getTokenFromLocalStorage);
  const lastnameFilter = yield select(getLastnameFilterSelector);
  const ticketsPageNumberFilter = yield select(getTicketsPageNumberFilterSelector);
  const ticketNumberFilter = yield select(getTicketNumberFilterSelector);
  const stateFilter = yield select(getStateFilterSelector);
  const typeFilter = yield select(getTypeFilterSelector);
  const descriptionFilter = yield select(getDescriptionFilterSelector);
  const employeeFilterId = yield select(getEmployeeFilterIdSelector);
  const tagFilter = yield select(getTagFilterSelector);
  const emailFilter = yield select(getEmailFilterSelector);
  const config = {
    headers: {
      Authorization: "Token " + token,
    },
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/tool/ticket/?page=${ticketsPageNumberFilter}`,
    params: {
      email: emailFilter,
      employee: employeeFilterId,
      lastname: lastnameFilter,
      tickets_page_number: ticketsPageNumberFilter,
      ticket_number: ticketNumberFilter,
      ticket_state: stateFilter,
      ticket_state__type: typeFilter,
      ticket_description: descriptionFilter,
      tag: tagFilter,
    },
  };

  try {
    const tickets = yield call(request, config);
    yield put(getTicketsSuccess(tickets));
  } catch (error) {
    yield put(getTicketsError(error));
  }
}

export function* getTags(action) {
  const token = yield call(getTokenFromLocalStorage);
  const config = {
    headers: {
      Authorization: "Token " + token,
    },
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/tool/ticket_tag/`,
  };
  try {
    const tags = yield call(request, config);
    yield put(getTagsSuccess(tags));
  } catch (error) {
    yield put(getTagsError(error));
  }
}

export function* getEmailTemplates(action) {
  const token = yield call(getTokenFromLocalStorage);
  const config = {
    headers: {
      Authorization: "Token " + token,
    },
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/tool/ticket_email_template/`,
  };
  try {
    const emailTemplates = yield call(request, config);
    yield put(getEmailTemplatesSuccess(emailTemplates));
  } catch (error) {
    yield put(getEmailTemplatesError(error));
  }
}

export function* postTag(action) {
  const token = yield call(getTokenFromLocalStorage);
  const company = yield select(getCompanyEmployeeSelector);
  const config = {
    headers: {
      Authorization: "Token " + token,
    },
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/tool/ticket_tag/`,
    data: {
      company: company.id,
      color: action.payload.color,
      text: action.payload.text,
    },
  };
  try {
    const tag = yield call(request, config);
    yield put(postTagSuccess(tag));
  } catch (error) {
    yield put(postTagError(error));
    yield call(toastr.error, "Erreur", "L'étiquette n'a pas été ajoutée");
  }
}

export function* deleteTag(action) {
  const token = yield call(getTokenFromLocalStorage);
  const tagId = action.payload;
  const config = {
    headers: {
      Authorization: "Token " + token,
    },
    method: "delete",
    url: `${process.env.REACT_APP_API_URL}/tool/ticket_tag/${tagId}/`,
  };
  try {
    yield call(request, config);
    yield put(deleteTagSuccess(tagId));
  } catch (error) {
    yield put(deleteTagError(error));
  }
}

export function setTicketPatchedData(action) {
  switch (action.type) {
    case PATCH_TICKET_EMAIL_REQUEST:
      return {
        id: action.payload.ticketId,
        email: action.payload.email,
      };
    case PATCH_TICKET_TAG_REQUEST:
      return {
        id: action.payload.ticketId,
        ticket_tags: action.payload.tags,
      };
    case PATCH_TICKET_STATE_REQUEST:
      return {
        id: action.payload.ticketId,
        ticket_state: action.payload.ticketState,
      };
    case PATCH_TICKET_ADMIN_COMMENTS_REQUEST:
      return {
        id: action.payload.ticketId,
        admin_comments: action.payload.adminComments,
      };
    case PATCH_IS_PAYMENT_DONE_REQUEST:
      return {
        id: action.payload.ticketId,
        is_payment_done: action.payload.isPaymentDone,
      };
    default:
      return null;
  }
}

export function* patchTicket(action) {
  const token = yield call(getTokenFromLocalStorage);
  const data = setTicketPatchedData(action);
  const config = {
    headers: {
      Authorization: "Token " + token,
    },
    method: "patch",
    url: `${process.env.REACT_APP_API_URL}/tool/ticket/${action.payload.ticketId}/`,
    data: data,
  };
  try {
    const ticket = yield call(request, config);
    yield put(patchTicketSuccess(ticket));
    switch (action.type) {
      case PATCH_TICKET_EMAIL_REQUEST:
        yield call(toastr.success, "Succès", "Le mail a bien été modifié");
        break;
      case PATCH_TICKET_STATE_REQUEST:
        yield call(toastr.success, "Succès", "Votre action a bien été prise en compte");
        break;
      case PATCH_TICKET_ADMIN_COMMENTS_REQUEST:
        yield call(toastr.success, "Succès", "Votre commentaire est bien enregistré");
        break;
      case PATCH_IS_PAYMENT_DONE_REQUEST:
        yield call(toastr.success, "Succès", "Votre action a bien été prise en compte");
        break;
      default:
        return yield put(showNoToastr());
    }
  } catch (error) {
    yield put(patchTicketError(error));
    switch (action.type) {
      case PATCH_TICKET_EMAIL_REQUEST:
        yield call(toastr.error, "Erreur", "Le mail n'a pas été modifié");
        break;
      case PATCH_TICKET_STATE_REQUEST:
        yield call(toastr.error, "Erreur", "Votre action n'a pas été prise en compte");
        break;
      case PATCH_TICKET_TAG_REQUEST:
        yield call(toastr.error, "Erreur", "L'étiquette n'a pas été ajoutée au ticket");
        break;
      case PATCH_TICKET_ADMIN_COMMENTS_REQUEST:
        yield call(toastr.error, "Erreur", "Votre commentaire n'a pas pu être enregistré");
        break;
      case PATCH_IS_PAYMENT_DONE_REQUEST:
        yield call(toastr.error, "Erreur", "Votre action n'a pas été prise en compte");
        break;
      default:
        return yield put(showNoToastr());
    }
  }
}

export function* getTagsSaga() {
  yield takeEvery(GET_TAGS_REQUEST, getTags);
}

export function* getStatesSaga() {
  yield takeEvery(GET_STATES_REQUEST, getStates, getStatesSuccess, getStatesError);
}

export function* getEmailTemplatesSaga() {
  yield takeEvery(GET_EMAIL_TEMPLATES_REQUEST, getEmailTemplates);
}

export function* getEmployeesSaga() {
  yield takeEvery(GET_EMPLOYEES_REQUEST, getEmployees, getEmployeesSuccess, getEmployeesError);
}

export function* postTagSaga() {
  yield takeEvery(POST_TAG_REQUEST, postTag);
}

export function* deleteTagSaga() {
  yield takeEvery(DELETE_TAG_REQUEST, deleteTag);
}

// worker Saga: will be fired on GET_TICKETS_REQUEST or SET_LASTNAME_FILTER actions
export function* getTicketsSaga() {
  yield takeLatest(
    [
      INCREASE_TICKETS_PAGE_NUMBER,
      DECREASE_TICKETS_PAGE_NUMBER,
      RESET_TICKETS_PAGE_NUMBER,
      GET_TICKETS_REQUEST,
      SET_LASTNAME_FILTER,
      RESET_LASTNAME_FILTER,
      SET_TICKET_NUMBER_FILTER,
      RESET_TICKET_NUMBER_FILTER,
      SET_STATE_FILTER,
      SET_TYPE_FILTER,
      SET_DESCRIPTION_FILTER,
      RESET_DESCRIPTION_FILTER,
      SET_EMPLOYEE_FILTER,
      RESET_EMPLOYEE_FILTER,
      SET_TAG_FILTER,
      SET_EMAIL_FILTER,
      RESET_EMAIL_FILTER,
    ],
    getTickets
  );
}

export function* patchTicketSaga() {
  yield takeEvery(
    [
      PATCH_TICKET_EMAIL_REQUEST,
      PATCH_TICKET_TAG_REQUEST,
      PATCH_TICKET_STATE_REQUEST,
      PATCH_TICKET_ADMIN_COMMENTS_REQUEST,
      PATCH_IS_PAYMENT_DONE_REQUEST,
    ],
    patchTicket
  );
}

const initialState = {
  counter: null,
  descriptionFilter: null,
  emailFilter: null,
  email: null,
  emailTemplates: null,
  employees: null,
  employeeFilter: null,
  error: null,
  isSendingAllowed: true,
  lastnameFilter: null,
  selectedEmailTemplateId: -1,
  selectedTicketId: null,
  stateFilter: null,
  states: null,
  tagFilter: null,
  tags: null,
  ticketsPageNumberFilter: 1,
  ticketNumberFilter: null,
  tickets: null,
  ticketSuccessfullySubmitted: false,
  typeFilter: null,
  types: null,
  isTagSuccessfullySubmitted: false,
};

/**
 * Following the duck pattern, the module.js file should export a reducer as a default function
 */
export function reducer(state = initialState, action) {
  switch (action.type) {
    case INCREASE_TICKETS_PAGE_NUMBER:
      return {
        ...state,
        ticketsPageNumberFilter: action.payload + 1,
      };
    case DECREASE_TICKETS_PAGE_NUMBER:
      return {
        ...state,
        ticketsPageNumberFilter: action.payload - 1,
      };
    case RESET_TICKETS_PAGE_NUMBER:
      return {
        ...state,
        ticketsPageNumberFilter: 1,
      };
    case GET_TICKETS_REQUEST:
      return {
        ...state,
        error: null,
      };
    case GET_TICKETS_SUCCESS:
      return {
        ...state,
        tickets: action.payload.results,
        counter: action.payload.count,
        error: null,
      };
    case GET_TICKETS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case GET_EMPLOYEES_REQUEST:
      return {
        ...state,
        error: null,
      };
    case GET_EMPLOYEES_SUCCESS:
      return {
        ...state,
        employees: action.payload,
      };
    case GET_EMPLOYEES_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_SELECTED_TICKET:
      return {
        ...state,
        selectedTicketId: action.payload,
        selectedEmailTemplateId: -1,
      };
    case SET_LASTNAME_FILTER:
      return {
        ...state,
        lastnameFilter: action.payload,
      };
    case RESET_LASTNAME_FILTER:
      return {
        ...state,
        lastnameFilter: null,
      };
    case SET_TICKET_NUMBER_FILTER:
      return {
        ...state,
        ticketNumberFilter: action.payload,
      };
    case RESET_TICKET_NUMBER_FILTER:
      return {
        ...state,
        ticketNumberFilter: null,
      };
    case SET_EMPLOYEE_FILTER:
      return {
        ...state,
        employeeFilter: action.payload,
      };
    case RESET_EMPLOYEE_FILTER:
      return {
        ...state,
        employeeFilter: null,
      };
    case SET_TYPE_FILTER:
      return {
        ...state,
        stateFilter: null,
        typeFilter: action.payload,
      };
    case SET_STATE_FILTER:
      return {
        ...state,
        stateFilter: action.payload,
        typeFilter: get(find(state.states, { id: action.payload }), "type.id"),
      };
    case SET_DESCRIPTION_FILTER:
      return {
        ...state,
        descriptionFilter: action.payload.descriptionFilter,
      };
    case RESET_DESCRIPTION_FILTER:
      return {
        ...state,
        descriptionFilter: null,
      };
    case SET_EMAIL_FILTER:
      return {
        ...state,
        emailFilter: action.payload,
      };
    case RESET_EMAIL_FILTER:
      return {
        ...state,
        emailFilter: null,
      };
    case SET_TAG_FILTER:
      return {
        ...state,
        tagFilter: action.payload.tagId,
      };
    case GET_TAGS_REQUEST:
      return {
        ...state,
        error: null,
      };
    case GET_TAGS_SUCCESS:
      return {
        ...state,
        tags: action.payload,
        error: null,
      };
    case GET_TAGS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case GET_STATES_REQUEST:
      return {
        ...state,
        error: null,
      };
    case GET_STATES_SUCCESS:
      return {
        ...state,
        states: action.payload,
        error: null,
      };
    case GET_STATES_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case GET_EMAIL_TEMPLATES_REQUEST:
      return {
        ...state,
        error: null,
      };
    case GET_EMAIL_TEMPLATES_SUCCESS:
      return {
        ...state,
        emailTemplates: action.payload,
        error: null,
      };
    case GET_EMAIL_TEMPLATES_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case POST_EMAIL_REQUEST:
      return {
        ...state,
        isSendingAllowed: false,
        ticketSuccessfullySubmitted: false,
        error: null,
      };

    case POST_EMAIL_SUCCESS:
      return {
        ...state,
        isSendingAllowed: true,
        tickets: unionBy([action.payload], [...state.tickets], "id"),
        ticketSuccessfullySubmitted: true,
        selectedEmailTemplateId: -1,
        error: null,
      };

    case POST_EMAIL_ERROR:
      return {
        ...state,
        isSendingAllowed: true,
        error: action.payload,
      };

    case SET_SELECTED_EMAIL_TEMPLATE:
      return {
        ...state,
        selectedEmailTemplateId: action.payload,
      };
    case POST_TAG_REQUEST:
      return {
        ...state,
        error: null,
        isTagSuccessfullySubmitted: false,
      };
    case POST_TAG_SUCCESS:
      return {
        ...state,
        tags: [...state.tags, action.payload],
        error: null,
        isTagSuccessfullySubmitted: true,
      };
    case POST_TAG_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_TAG_REQUEST:
      return {
        ...state,
        error: null,
      };
    case DELETE_TAG_SUCCESS:
      return {
        ...state,
        tags: [...state.tags.filter((tag) => tag.id !== action.payload)],
        error: null,
      };
    case DELETE_TAG_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case PATCH_TICKET_EMAIL_REQUEST:
      return {
        ...state,
        error: null,
      };
    case PATCH_TICKET_TAG_REQUEST:
      return {
        ...state,
        error: null,
      };
    case PATCH_TICKET_STATE_REQUEST:
      return {
        ...state,
        error: null,
      };
    case PATCH_TICKET_SUCCESS:
      return {
        ...state,
        tickets: unionBy([action.payload], [...state.tickets], "id"),
        error: null,
      };
    case PATCH_TICKET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default reducer;
