import { adminSaga } from "react-admin";

import adminRootSaga from "modules/adminSagas";
import {
  getCouponSaga,
  getQuickBookingSettingsSaga,
  getQuickBookingTimeslotsSaga,
  postQuickBookingFormSaga,
} from "murfy/pages/QuickBooking/module";
import { all } from "redux-saga/effects";
import { pushApplicationSaga } from "shared/components/Menu/module";
import { getArticleSaga } from "shared/pages/Article/module";
import { getCatalogSaga, redirectToArticleSaga } from "shared/pages/Catalog/module";
import { getCompanyBasedOnHostSaga, getCompanySaga } from "shared/pages/Company/module";
import {
  decrementStepsCounterSaga,
  getDiagnosticSaga,
  getStepSaga,
  getSymtomsFaultsCheckingProcessesFromSymptomSaga,
  incrementStepsCounterSaga,
  pushStepIdToURLSaga,
  setActiveStepSaga,
  updateStepAnswerSaga,
} from "shared/pages/Diagnostic/module";

import { calendarSagas } from "components/MurfyCalendar3by3/module";

// single entry point to start all Sagas at once
export default function* rootSaga(dataProvider, authProvider) {
  yield all([
    adminSaga(dataProvider, authProvider)(),
    adminRootSaga(),
    calendarSagas(),
    decrementStepsCounterSaga(),
    getArticleSaga(),
    getCatalogSaga(),
    getCompanySaga(),
    getCompanyBasedOnHostSaga(),
    getDiagnosticSaga(),
    getQuickBookingSettingsSaga(),
    getSymtomsFaultsCheckingProcessesFromSymptomSaga(),
    getStepSaga(),
    incrementStepsCounterSaga(),
    postQuickBookingFormSaga(),
    pushApplicationSaga(),
    pushStepIdToURLSaga(),
    redirectToArticleSaga(),
    getQuickBookingTimeslotsSaga(),
    getCouponSaga(),
    setActiveStepSaga(),
    updateStepAnswerSaga(),
  ]);
}
