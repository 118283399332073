import React, { Component } from "react";

import PropTypes from "prop-types";

import includes from "lodash/includes";
import Tooltip from "rc-tooltip";

import "./style.css";

export default class MenuButton extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
    activeRoute: PropTypes.string.isRequired,
    navigateTo: PropTypes.func.isRequired,
  };

  navigateTo = () => this.props.navigateTo(this.props.route);

  isRouteActive = () => includes(this.props.activeRoute, this.props.route);

  render() {
    return (
      <Tooltip placement="right" overlay={<p className="menu-button-tooltip">{this.props.text}</p>}>
        <button
          className={`menu-button-container ${this.isRouteActive() && "menu-button-container-active"}`}
          onClick={this.navigateTo}
        >
          <i className={`${this.props.icon} menu-button-container-ico`} />
        </button>
      </Tooltip>
    );
  }
}
