import React from "react";

import { Box, Checkbox } from "@material-ui/core";

import { Field, FieldProps } from "formik";

import { Body } from "components/atoms/Typography";

const TimeNeededField: React.FunctionComponent = () => (
  <Field name="time_needed">
    {({ form }: FieldProps) => (
      <Box display="flex" alignItems="center">
        <Body> Passage long: </Body>
        <Checkbox
          checked={form.values.time_needed}
          onChange={() => form.setFieldValue("time_needed", !form.values.time_needed)}
          name="Intervention longue"
        />
      </Box>
    )}
  </Field>
);

export default TimeNeededField;
