import * as React from "react";

import { useDataProvider, useNotify, useRefresh } from "react-admin";

import { Button } from "@material-ui/core";

import { CANCELLED } from "components/OrderShow/utils";

const getProductName = (orderLine) => `${orderLine.product.brand} ${orderLine.product.model_reference}`;

const OrderLineCancelButton = ({ orderLines, children, ...rest }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const cancelorderLines = async () => {
    if (window.confirm("En êtes-vous sûr(e)?")) {
      for (const orderLine of orderLines) {
        await dataProvider
          .update("ecom/orderlines", {
            id: orderLine.id,
            data: {
              state: CANCELLED,
              warranty_name: orderLine.warranty_name,
              warranty_price: orderLine.warranty_price,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              notify(`Le produit ${getProductName(orderLine)} a été annulé`);
            } else {
              notify(`Le produit ${getProductName(orderLine)} ne peut pas être annulé`, "warning");
            }
            refresh();
          });
      }
    }
  };

  return (
    <Button color="primary" onClick={cancelorderLines} {...rest}>
      {children}
    </Button>
  );
};

export default OrderLineCancelButton;
