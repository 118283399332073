import React, { useState } from "react";

import { SelectInput, SimpleForm, useNotify } from "react-admin";

import { Box, Divider } from "@material-ui/core";

import Moment from "moment";
import { CustomerFileDetailed } from "types/customer-files";
import { Product } from "types/products";

import FileComments from "components/FileComments";
import FancyTabs from "components/atoms/FancyTabs";
import { Body, H3, Small } from "components/atoms/Typography";
import BodyBold from "components/atoms/Typography/BodyBold";
import CollapsibleBlock from "components/molecules/CollapsibleBlock";

import {
  COMPLETE_TASK_STATES,
  TASK_STATE_DEPRECATED,
  TASK_STATE_EXPIRED,
  UPDATABLE_TASK_STATE,
} from "constants/tasksStates";

import { updateVisitPreparation, useLastVisitPreparation } from "utils/api";
import { useEmployeesContext } from "utils/contexts/employeesContext";
import { getFileAssociatedRepairman } from "utils/getLastRepairmanOfFile";
import { getEmployeeFullName } from "utils/repairmenSortedList";

type InterventionPreparationProps = { customerFile: CustomerFileDetailed };

const InterventionPreparation: React.FunctionComponent<InterventionPreparationProps> = ({ customerFile }) => {
  const notify = useNotify();
  const [activeTab, setActiveTab] = useState<number>(0);
  const { employees } = useEmployeesContext();
  const tabs = customerFile.products.map((_product: Product, index: number) => {
    return { label: `Produit ${index + 1}` };
  });

  let lastVisitPreparation = useLastVisitPreparation(customerFile.id);

  const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
  };

  const associatedRepairman = employees && getFileAssociatedRepairman(customerFile, employees);

  const handleUpdateTaskState = async (
    event: React.ChangeEvent<{ value: "done" | "todo" | "on hold"; name: string }>
  ) => {
    const response: any = await updateVisitPreparation(lastVisitPreparation.id, { state: event.target.value });
    if (response.status > 200) {
      notify(`Une erreur est survenue:le statut n'a pas été mis à jour.`, "error");
    } else {
      notify("Statut de la préparation mis à jour");
    }
  };

  return (
    <CollapsibleBlock title={<BodyBold>Préparation première intervention</BodyBold>}>
      <Box>
        <Box mt={3}>
          <FancyTabs tabs={tabs} handleChange={handleChange} activeTab={activeTab} />
        </Box>
        <Box fontStyle="italic" mt={5}>
          <Body>Informations transmises par le client</Body>
        </Box>
        <Box mt={2}>
          <Body>Symptôme</Body>
          <Box mt={1} p={2} borderRadius={12} border="1px solid #DBDFE899">
            {customerFile.products[activeTab].fault_details || "Non renseigné"}
          </Box>
        </Box>
        <Box mt={3}>
          <Divider />
        </Box>
        <Box fontStyle="italic" mt={3}>
          <Body>Diagnostic du CET </Body>
        </Box>
        <Box mt={lastVisitPreparation ? 0 : 3} display="flex" justifyContent="space-between">
          <H3 marginY="auto ">Statut de la préparation</H3>
          {lastVisitPreparation && !lastVisitPreparation.error ? (
            <SimpleForm toolbar={null} record={lastVisitPreparation}>
              {[TASK_STATE_DEPRECATED, TASK_STATE_EXPIRED].includes(lastVisitPreparation.state) ? (
                <SelectInput
                  source="state"
                  optionText="name"
                  label="Statut"
                  choices={COMPLETE_TASK_STATES}
                  onChange={handleUpdateTaskState}
                  disabled
                />
              ) : (
                <SelectInput
                  source="state"
                  optionText="name"
                  label="Statut"
                  choices={UPDATABLE_TASK_STATE}
                  onChange={handleUpdateTaskState}
                />
              )}
            </SimpleForm>
          ) : (
            <Small>Aucune intervention à préparer</Small>
          )}
        </Box>
        <Box mt={lastVisitPreparation ? 3 : 0} display="flex">
          <Box width="50%">
            <BodyBold>Technicien affecté</BodyBold>
            <Body>{associatedRepairman ? getEmployeeFullName(associatedRepairman) : "Aucun technicien affecté"}</Body>
          </Box>
          <Box width="50%">
            <BodyBold>Date du passage</BodyBold>
            <Body>
              {lastVisitPreparation && lastVisitPreparation.timeslot_beginning
                ? Moment(lastVisitPreparation.timeslot_beginning).locale("fr").format("DD/MM/YYYY à HH:mm")
                : "Aucune intervention programmée"}
            </Body>
          </Box>
        </Box>
        <Box mt={3}>
          <FileComments
            filter={{ recipient_role: "repairman", customer_file: customerFile.id, ordering: "-id" }}
            customerFile={customerFile}
            attachmentsEnabled
            updateEnabled
            recipientRole="repairman"
            title="Message au technicien"
            maxNumberOfComments={1}
          />
        </Box>
      </Box>
    </CollapsibleBlock>
  );
};

export default InterventionPreparation;
