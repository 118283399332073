import React from "react";

import { SelectInput, TextInput } from "react-admin";

import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useSmallBodyStyles = makeStyles((theme) => ({
  root: {
    fontWeight: "600",
    color: theme.palette.secondary.contrastText,
  },
}));

const GradesChoices = [{ id: "A" }, { id: "B" }, { id: "C" }, { id: "D" }, { id: "E" }];

const ProductGeneralInputs = (props) => {
  const classes = useSmallBodyStyles();
  const productPublished = props.record && props.record.is_online;
  return (
    <>
      <Typography variant="body2" color="secondary.contrastText" className={classes.root}>
        Générales
      </Typography>
      <Box display="flex" width="100%">
        <Box width="320px">
          <TextInput
            source="barcode"
            label="Code-barres"
            InputProps={{ disableUnderline: true, fullWidth: true }}
            disabled={productPublished}
            fullWidth
          />
        </Box>
        <Box width="320px" marginLeft="24px">
          <SelectInput
            label="Grade"
            source="grade"
            choices={GradesChoices}
            optionText="id"
            disabled={productPublished}
            fullWidth
          />
        </Box>
      </Box>
    </>
  );
};

export default ProductGeneralInputs;
