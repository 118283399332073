import React from "react";

import { FunctionField } from "react-admin";

import { listRepairReportLabel } from "./ListRepairReportLabel";

const LabelRepairReportField = ({ record, visits }) => {
  const visit = (visits && visits.find((visit) => record.visit === visit.id)) || {};
  const visitLabels = visit.label;
  return visitLabels &&
    visitLabels.includes('"id":') &&
    visitLabels.includes('"label":') &&
    JSON.parse(visitLabels) &&
    JSON.parse(visitLabels)[0] ? (
    JSON.parse(visitLabels).map((label, index) => (
      <div key={index}>
        <FunctionField
          key={index}
          render={() =>
            listRepairReportLabel.find((repairLabel) => label.label === repairLabel.id) &&
            listRepairReportLabel.find((repairLabel) => label.label === repairLabel.id).name
          }
        />
      </div>
    ))
  ) : (
    <div>
      <FunctionField
        render={() =>
          listRepairReportLabel.find((repairLabel) => visitLabels === repairLabel.id) &&
          listRepairReportLabel.find((repairLabel) => visitLabels === repairLabel.id).name
        }
      />
    </div>
  );
};
export default LabelRepairReportField;
