import React from "react";

import { ArrayField, Datagrid, FunctionField, SimpleShowLayout, TextField } from "react-admin";

import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Moment from "moment";

import ListingPublishButton from "pages/customerFiles/components/ListingList/components/ListingPublishButton";
import ListingStatusField from "pages/customerFiles/components/ListingList/components/ListingStatusField.js";

const ListingList = ({ disabled, refreshWorkshopFileData, ...props }) => {
  const classes = useSmallBodyStyles();

  const getLastUpdate = (listing) => {
    const updatelist = [];
    if (listing.published_at !== null) {
      updatelist.push(new Date(listing.published_at));
    }
    if (listing.sold_at !== null) {
      updatelist.push(new Date(listing.sold_at));
    }
    if (updatelist.length > 0) {
      const lastUpdate = new Date(Math.max.apply(null, updatelist));
      return Moment(lastUpdate).local("fr").format("ddd DD MMM - HH:mm");
    }
    return "-";
  };

  return (
    <Box>
      <Typography variant="body2" color="secondary.contrastText" className={classes.sectionTitle}>
        Annonces
      </Typography>
      <SimpleShowLayout {...props} className={classes.fullWidth}>
        <ArrayField source="listings" label="">
          <Datagrid>
            <TextField label="Plateforme" source="store" />
            <TextField label="Catalogue" source="catalog.name" />
            <FunctionField label="Mise à jour" render={getLastUpdate} />
            <ListingStatusField label="Statut" />
            <ListingPublishButton disabled={disabled} refreshWorkshopFileData={refreshWorkshopFileData} />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Box>
  );
};
const useSmallBodyStyles = makeStyles((theme) => ({
  sectionTitle: {
    fontSize: "24px",
    fontWeight: "600",
    color: theme.palette.secondary.contrastText,
  },
  fullWidth: {
    width: "100%",
    marginRight: "0px",
    marginLeft: "0px",
  },
}));

export default ListingList;
