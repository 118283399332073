import React from "react";

import { SaveButton, SimpleForm, TextInput, Toolbar, useNotify, useRefresh } from "react-admin";

import { Box, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

import { Order } from "types/order";

import dataProvider from "dataProvider";

import Modal from "components/Modal";
import Button from "components/atoms/Button";
import InternationalPhoneInput from "components/atoms/PhoneInput/InternationalPhoneInput";

type CustomerInformationProps = {
  record: Order;
};
type FormValues = {
  customer_phone: string;
  customer_email: string;
  customer_firstname: string;
  customer_lastname: string;
};

const CustomerInformation: React.FunctionComponent<CustomerInformationProps> = ({ record }) => {
  const refresh = useRefresh();
  const notify = useNotify();

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const customerName = `${record.customer_firstname} ${record.customer_lastname}`;

  const save = (data: any) => {
    data = {
      id: data.id,
      customer_phone: data.customer_phone,
      customer_email: data.customer_email,
      customer_firstname: data.customer_firstname,
      customer_lastname: data.customer_lastname,
    };
    dataProvider
      .update("ecom/orders", { id: data.id, data: data })
      .then((response) => {
        if (response.status > 200) {
          notify("Une erreur est survenue", "error");
          setIsModalOpen(false);
        } else {
          refresh();
          setIsModalOpen(false);
          notify("Commande mise à jour");
        }
      })
      .catch((e) => notify(`Une erreur est survenue ${e.message}`));
  };

  const validationSchema = (values: FormValues) => {
    const errors: FormValues = {} as FormValues;
    if (
      (values.customer_phone &&
        values.customer_phone.replace(/ /g, "").includes("+33") &&
        values.customer_phone.replace(/ /g, "").length < 12) ||
      values.customer_phone === "+"
    ) {
      errors.customer_phone = "Numéro de téléphone invalide";
    }
    return errors;
  };

  return (
    <>
      {isModalOpen ? (
        <Modal
          modalClassName={`modal-small-content`}
          isOpen={isModalOpen}
          handleCloseModal={() => setIsModalOpen(false)}
        >
          <SimpleForm validate={validationSchema} record={record} toolbar={<SaveToolbar />} save={save}>
            <Box width="100% !important">
              <Box display="flex" width="100%" pl={1}>
                <Box width="320px">
                  <TextInput source="customer_lastname" label="Nom" InputProps={{ disableUnderline: true }} fullWidth />
                </Box>
                <Box width="320px" ml={3}>
                  <TextInput
                    source="customer_firstname"
                    label="Prénom"
                    InputProps={{ disableUnderline: true }}
                    fullWidth
                  />
                </Box>
              </Box>
              <Box display="flex" width="100%" pl={1}>
                <Box width="320px">
                  <TextInput
                    source="customer_email"
                    label="Adresse email"
                    InputProps={{ disableUnderline: true }}
                    fullWidth
                  />
                </Box>
                <Box width="320px" ml={3} mt={1}>
                  <InternationalPhoneInput label={"N° de Téléphone"} source="customer_phone" />
                </Box>
              </Box>
            </Box>
          </SimpleForm>
        </Modal>
      ) : (
        <Box mx={1}>
          <Box display="flex" alignItems="center">
            <Typography variant="body2">Client:</Typography>
            <Button
              style={{
                padding: 0,
                height: 24,
              }}
              dimension="small"
              version="invisible"
              onClick={() => setIsModalOpen(true)}
            >
              <EditIcon />
            </Button>
          </Box>
          <Typography
            variant="body2"
            style={{
              textTransform: "capitalize",
            }}
          >
            {customerName}
          </Typography>
          <Typography variant="body2">{record.customer_email}</Typography>
          <Typography variant="body2">{record.customer_phone}</Typography>
        </Box>
      )}
    </>
  );
};

const SaveToolbar = (props: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Modifier" />
    </Toolbar>
  );
};

export default CustomerInformation;
