import React, { useState } from "react";

import { useDelete, useNotify, useUpdate } from "react-admin";

import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";

import moment from "moment";

import Modal from "components/Modal";

import { getEmployeeFullName, repairmenSortedList } from "utils/repairmenSortedList";

const editPaymentModeWrapper = {
  editPaymentModeWrapper: {
    height: "64px",
    borderBottom: "2px solid #F4F3F8",
    textAlign: "left",
    marginBottom: "24px",
    paddingTop: "18px",
    display: "contents",
  },
  modalTitle: {
    textAlign: "center",
    padding: "37px 0px",
  },
  buttonGroup: {
    textAlign: "center",
    padding: "40px 0 8px 0",
  },
  cancelButton: {
    color: "#8795B3",
    boxShadow: "unset",
    marginLeft: 16,
    backgroundColor: "unset",
    position: "relative",
  },
  cancelUpdateButton: {
    left: 112,
    color: "#8795B3",
    boxShadow: "unset",
    marginLeft: 16,
    backgroundColor: "unset",
    position: "relative",
  },
  radioButtonGroup: {
    width: 420,
    border: "1px solid #DBDFE8",
    borderRadius: 8,
    padding: 24,
  },
  inputField: {
    padding: "0px 88px 16px 88px",
    margin: "auto",
  },
  textFieldContent: {
    width: "100%",
  },
  deletePaymentButton: {
    background: "#F85B5B",
    color: "#FFFFFF",
    border: "none",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#b04343",
    },
    position: "fixed",
    left: 24,
  },
  updatePaymentButton: {
    background: "#4A55FE",
    color: "#fff",
    boxShadow: "none",
    marginLeft: 16,
    "&:hover": {
      backgroundColor: "#3b44cb",
    },
    position: "fixed",
    right: 24,
  },
  updatePaymentModal: {
    width: 569,
  },
};

const useStyles = makeStyles(editPaymentModeWrapper);

const paymentNatureList = [
  { id: "payment", name: "Paiement" },
  { id: "refund", name: "Remboursement" },
];

const paymentTypeList = [
  { id: "credit_card", name: "Carte bancaire" },
  { id: "check", name: "Chèque" },
  { id: "cash", name: "Espèces" },
  { id: "prepayed_online", name: "Prépayé en ligne" },
  { id: "payed_online", name: "Payé en ligne" },
  { id: "bank_transfer", name: "Virement bancaire" },
];
export default function PaymentEditionModal(props) {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState(props.record.payment_date);
  const [selectedPaymentMode, setSelectedPaymentMode] = useState(props.record.payment_method);
  const [selectedTech, setSelectedTech] = useState(props.record.employee);
  const [selectedTransactionType, setSelectedTransactionType] = useState(props.record.payment_nature);
  const [selectedAmount, setSelectedAmount] = useState(Math.abs(parseFloat(props.record.amount / 100)));
  const [selectedPartner, setSelectedPartner] = useState(props.record.payment_solution);
  const [selectedPartnerReference, setSelectedPartnerRefence] = useState(props.record.solution_reference);
  const [update] = useUpdate("payment-details");
  const [deletePayment] = useDelete("payment-details");
  const notify = useNotify();

  const handleSubmitEditPayment = () => {
    const id = props.record.id;
    update(
      {
        payload: {
          id,
          data: {
            payment_method: selectedPaymentMode,
            payment_nature: selectedTransactionType,
            amount:
              selectedTransactionType === "payment" ? parseInt(selectedAmount * 100) : -parseInt(selectedAmount * 100),
            solution_reference: selectedPartnerReference || "",
            payment_solution: selectedPartner || "",
            payment_date: moment(selectedDate).locale("fr").toISOString(),
            employee: selectedTech,
          },
        },
      },
      {
        onSuccess: () => {
          notify("Paiement mis à jour");
          props.handleClosePaymentModificationModal();
          props.getRelatedPayments(props.record.customer_file);
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };

  const handleSubmitDeletePayment = () => {
    const id = props.record.id;
    deletePayment(
      {
        payload: {
          id,
        },
      },
      {
        onSuccess: () => {
          notify("Paiement Supprimé");
          props.handleClosePaymentModificationModal();
          props.getRelatedPayments(props.record.customer_file);
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };

  return (
    <div className={classes.editPaymentModeWrapper}>
      <form>
        <Modal
          id="payment-edition-modal"
          modalClassName={`modal-small-content ${classes.updatePaymentModal}`}
          isOpen={props.isOpen}
          handleCloseModal={props.handleClosePaymentModificationModal}
        >
          <Typography className={classes.modalTitle} variant="h2">
            Modification du paiement / remboursement
          </Typography>
          <div className={classes.inputField}>
            <TextField
              className={classes.textFieldContent}
              id="payment-transaction-select"
              select
              label="Mode de paiement"
              value={selectedTransactionType}
              onChange={(e) => setSelectedTransactionType(e.target.value)}
              variant="outlined"
            >
              {paymentNatureList &&
                paymentNatureList.map((paymentNature) => (
                  <MenuItem key={paymentNature.id} value={paymentNature.id}>
                    {paymentNature.name}
                  </MenuItem>
                ))}
            </TextField>
          </div>
          <div className={classes.inputField}>
            <TextField
              className={classes.textFieldContent}
              id="payment-mode-select"
              select
              label="Mode de paiement"
              value={selectedPaymentMode}
              onChange={(e) => setSelectedPaymentMode(e.target.value)}
              variant="outlined"
            >
              {paymentTypeList &&
                paymentTypeList.map((paymentType) => (
                  <MenuItem key={paymentType.id} value={paymentType.id}>
                    {paymentType.name}
                  </MenuItem>
                ))}
            </TextField>
          </div>
          <div className={classes.inputField}>
            <TextField
              className={classes.textFieldContent}
              id="date-selected"
              type="number"
              label="Montant payé/remboursé"
              value={selectedAmount}
              onChange={(e) => setSelectedAmount(e.target.value)}
              variant="outlined"
              InputProps={{
                endAdornment: <EuroSymbolIcon />,
              }}
            />
          </div>
          <div className={classes.inputField}>
            <TextField
              className={classes.textFieldContent}
              id="selected-date"
              type="date"
              label="Date du paiement"
              defaultValue={moment(selectedDate).format("YYYY-MM-DD")}
              onChange={(e) => setSelectedDate(e.target.value)}
              variant="outlined"
            />
          </div>
          <div className={classes.inputField}>
            <TextField
              className={classes.textFieldContent}
              id="employee-select"
              select
              label="Technicien"
              value={selectedTech}
              onChange={(e) => setSelectedTech(e.target.value)}
              variant="outlined"
            >
              {props.employees &&
                props.employees &&
                repairmenSortedList(props.employees).map((employee) => (
                  <MenuItem key={employee.id} value={employee.id}>
                    {getEmployeeFullName(employee)}
                  </MenuItem>
                ))}
            </TextField>
          </div>
          <div className={classes.inputField}>
            <TextField
              className={classes.textFieldContent}
              id="partner-selected"
              label="Prestataire de paiement"
              value={selectedPartner}
              onChange={(e) => setSelectedPartner(e.target.value)}
              variant="outlined"
            />
          </div>
          <div className={classes.inputField}>
            <TextField
              className={classes.textFieldContent}
              id="partner-reference-selected"
              label="Référence prestataire"
              value={selectedPartnerReference}
              onChange={(e) => setSelectedPartnerRefence(e.target.value)}
              variant="outlined"
            />
          </div>

          <div className={classes.buttonGroup}>
            <Button
              id="delete-update"
              variant="contained"
              color="secondary"
              className={classes.deletePaymentButton}
              onClick={handleSubmitDeletePayment}
            >
              Supprimer le paiement
            </Button>
            <Button
              id="cancel-update"
              variant="contained"
              className={classes.cancelUpdateButton}
              onClick={props.handleClosePaymentModificationModal}
            >
              Annuler
            </Button>
            <Button
              className={classes.updatePaymentButton}
              id="validate-update"
              variant="contained"
              onClick={handleSubmitEditPayment}
            >
              Valider
            </Button>
          </div>
        </Modal>
      </form>
    </div>
  );
}
