import React, { useState } from "react";

import { Box } from "@material-ui/core";
import { makeStyles, withTheme } from "@material-ui/core/styles";

import CreateFileButton from "pages/customerFiles/FileCreate/CreateFileButton";

import CustomerFileCard from "./components/CustomerFileCard";

const useStyles = makeStyles({
  numberOfCustomerFileStyle: {
    color: "#102D69",
    marginLeft: 20,
    marginRight: 20,
  },
});

const CustomerGrid = ({
  ids,
  resource,
  data,
  customerFilesTags,
  total,
  handleItemClick,
  isReportCheckingInterface,
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState(null);

  const updateSelected = (id) => {
    setSelected(id);
    handleItemClick(id);
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <h6 className={classes.numberOfCustomerFileStyle}>Nombre de dossiers : {total}</h6>
        <CreateFileButton resource={resource} />
      </Box>
      {ids
        .filter((id) => data[id])
        .map((id) => (
          <CustomerFileCard
            customerFile={data[id]}
            customerFilesTags={customerFilesTags}
            selected={data[id].file_number === selected}
            onClick={updateSelected}
            isReportCheckingInterface={isReportCheckingInterface}
          />
        ))}
    </Box>
  );
};

const CustomerGridWithTheme = withTheme(CustomerGrid);

export default CustomerGridWithTheme;
