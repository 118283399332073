import React from "react";

import { useField, useFormState } from "react-final-form";

import MurfyCalendar from "components/MurfyCalendar3by3";

/**
 * Displays a Calendar of Murfy's available timeslot in a given locality (postal code).
 * Requires a reference to the postal code to be queried.
 * On select, send the selected timeslot id to the underlying form field.
 * Should be used inside a react-admin or react-final-form form.
 * @param {*} props
 *   - postalCodeFieldName : string, a reference to a postal code in the underlying form
 *   - source or name : string, field to identify underlying form field.
 */
const CalendarInput = (props) => {
  const {
    input: { onChange: onTimeslotChange },
  } = useField("timeslot");

  const {
    input: { onChange: onTimeslotDataChange },
  } = useField("timeslot_data");
  var {
    values: { [props.postalCodeFieldName]: postalCode },
  } = useFormState(props.postalCodeFieldName, {
    subscription: { [props.postalCodeFieldName]: true },
  });
  const {
    values: { city, street_number, street },
  } = useFormState();

  const handleTimeslotChange = (timeslot) => {
    onTimeslotChange(timeslot.id);
    onTimeslotDataChange({
      id: timeslot.id,
      beginning: timeslot.beginning,
      end: timeslot.end,
    });
  };
  return (
    <MurfyCalendar
      handleClick={handleTimeslotChange}
      postalCode={postalCode}
      city={city}
      streetNumber={street_number}
      street={street}
    />
  );
};

export default CalendarInput;
