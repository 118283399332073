import React from "react";

import { FunctionField } from "react-admin";

import { withStyles } from "@material-ui/core/styles";

import StatusFieldStyles from "shared/styles/StatusFieldStyles";

import { SparePartStatus } from "constants/sparePartStatus";

export default withStyles(StatusFieldStyles)(({ record, classes }) => {
  const getClass = () => {
    switch (record.status) {
      case SparePartStatus.INSTALLED:
        return classes.grey;
      case SparePartStatus.ORDERED:
      case SparePartStatus.ORDERED_ROTATING_STOCK:
        return classes.purple;
      case SparePartStatus.INSTOCK:
      case SparePartStatus.RESERVED:
        return classes.dark_blue;
      case SparePartStatus.PACKED:
        return classes.yellow;
      case SparePartStatus.RECEIVED_TECH:
        return classes.green;
      case SparePartStatus.LOST:
        return classes.dark;
      case SparePartStatus.RECEIVED_WRONG_TECH:
        return classes.red;
      case SparePartStatus.RETURN_TO_WAREHOUSE:
        return classes.brown;
      default:
        return classes.grey;
    }
  };

  return <FunctionField render={() => record.status_display} className={`${classes.label} ${getClass()}`} />;
});
