import React from "react";

import { Box } from "@material-ui/core";

import { Product } from "types/products";

import { postIncentive } from "utils/api";

import RequalificationSelect from "./components/RequalificationSelect";

type IncentiveRequalificationProps = {
  visitId: number;
  product: Product;
};

const IncentiveRequalification = ({ visitId, product }: IncentiveRequalificationProps) => {
  const handleQualificationUpdate = async (incentive: {
    visit: number;
    product_id: number;
    label: string;
    repairman?: string;
    point_type: string;
  }) => {
    await postIncentive(incentive);
  };

  return (
    <Box width={400}>
      <RequalificationSelect
        visitId={visitId}
        product={product}
        handleQualificationUpdate={handleQualificationUpdate}
      />
    </Box>
  );
};

export default IncentiveRequalification;
