// React imports
import React from "react";

// MUI
import { Box, Typography, useTheme } from "@material-ui/core";

import { quoteElementList } from "pages/customerFiles/components/CustomerFileBillingQuotes/services/QuoteHelper.js";
// Services
import { getQuoteElementProperty } from "pages/customerFiles/components/CustomerFileBillingQuotes/services/getQuoteElementProperty.js";

const LegacyQuote = ({ element }) => {
  const theme = useTheme();

  return (
    <Box display="flex" justifyContent="space-between" p={1}>
      <Box width="80%" alignItems="center" my="auto" mx={0} color={theme.palette.secondary.contrastText}>
        <Typography variant="body1" color="inherit">
          {getQuoteElementProperty(element.element_type, quoteElementList, "name")}
        </Typography>
      </Box>
    </Box>
  );
};

export default LegacyQuote;
