import React, { useContext, useState } from "react";

import { SimpleForm, TextInput, useNotify, useRefresh } from "react-admin";

import { Box, withStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import { SimpleValidateToolbar } from "components/FormToolbars";

import { UserContext } from "pages/customerFiles/CustomerFilesList/CustomerFilesList";

import { createComment, createCommentAttachments } from "utils/api";

import CommentAttachment from "../Comment/CommentAttachment";

const StyledTextInput = withStyles({
  root: {
    "& > div": {
      background: "#ffffff",
      borderRadius: 10,
    },
    "& p": {
      display: "none",
    },
  },
})(TextInput);

const NewComment = ({ customerFile, attachmentsEnabled, recipientRole }) => {
  const [isLoading, setIsLoading] = useState(false);
  const refresh = useRefresh();
  const classes = useStyle();
  const notify = useNotify();
  const connectedEmployee = useContext(UserContext);
  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      const { data } = await createComment(values);
      if (values.attachments.length > 0) {
        values.attachments.map(async (attachment) => {
          await createCommentAttachments({ file: attachment.rawFile, comment: data.id });
        });
      }
    } catch (e) {
      notify(`Une erreur est survenue ${e.status} ${e.message}`, "warning");
    }
    setIsLoading(false);
    notify("Commentaire enregistré");
    refresh();
  };

  return (
    <SimpleForm
      toolbar={
        <SimpleValidateToolbar
          size="small"
          variant="text"
          version="right"
          label={`Valider le commentaire`}
          onSave={handleSubmit}
          handleClose={() => {}}
          disabled={!connectedEmployee || isLoading}
        />
      }
      redirect={false}
      classes={classes.createForm}
      initialValues={{
        customer_file: customerFile.id,
        text: "",
        author: connectedEmployee && connectedEmployee.id,
        attachments: [],
        recipient_role: recipientRole,
      }}
    >
      <Box width="736px !important">
        <StyledTextInput label="Ajouter un commentaire" source="text" fullWidth multiline rows={3} />
        {attachmentsEnabled && <CommentAttachment />}
      </Box>
    </SimpleForm>
  );
};

const useStyle = makeStyles((theme) => ({
  margin: theme.spacing(8),
  width: "fit-content",
}));
export default NewComment;
