import React from "react";

import { SaveButton, Toolbar } from "react-admin";

import { Button, makeStyles } from "@material-ui/core";

export default function BinaryValidateToolbar(props) {
  const getToolbarClass = (version) => {
    switch (version) {
      case "right":
        return classes.rightAlignedToolbar;
      default:
        return classes.centeredToolbar;
    }
  };
  const classes = makeStyles({
    centeredToolbar: {
      backgroundColor: "#FFFFFF",
      justifyContent: "space-evenly",
    },
    rightAlignedToolbar: {
      backgroundColor: "transparent",
      justifyContent: "end",
    },
  })();
  return (
    <Toolbar classes={{ toolbar: getToolbarClass(props.version) }} variant="dense" disableGutters {...props}>
      <Button size={props.size || "small"} variant="text" onClick={props.handleClose}>
        Annuler
      </Button>
      <SaveButton
        size={props.size || "small"}
        variant={props.variant || "text"}
        label={props.label}
        onSave={props.onSave}
        disabled={props.disabled}
      />
    </Toolbar>
  );
}
