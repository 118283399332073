/**
 * Converts the data given into the formData format
 * If a key is found into the arraysToConvert, it adds all the keys following the PHP convention (key[]) to the formData
 * @param {Object} data Data to convert
 * @param {Array} arraysToConvert Array of keys that need treatment - values related to keys listed in arraysToConvert should be iterators
 */
export default function convertIntoFormData(data, arraysToConvert) {
  var formData = new FormData();

  Object.keys(data).forEach(function (key) {
    if (arraysToConvert && arraysToConvert.includes(key)) {
      for (let arrayItem of data[key] || []) {
        // iterate over array to convert or empty array if null
        formData.append(key + "[]", arrayItem);
      }
    } else {
      formData.append(key, data[key]);
    }
  });
  return formData;
}
