import React, { useEffect, useState } from "react";

import { BooleanInput, Edit, NumberInput, SaveButton, SelectInput, SimpleForm, Toolbar } from "react-admin";

import { Box, Button, Typography } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";

import { diff } from "deep-object-diff";
import { useForm, useFormState } from "react-final-form";
import { toastr } from "react-redux-toastr";

import dataProvider from "dataProvider";

import Modal from "components/Modal";
import PictureForm from "components/PictureForm";
import {
  AppearancesChoices,
  GOOD_CONDITION,
  LIKE_NEW,
  PROPER_CONDITION,
  WEARY,
} from "components/ProductCaracteristicsInputs/ProductCaracteristicsInputs";
import ProductImageInput from "components/ProductImageInput";
import ProductToggleGroup from "components/ProductToggleGroup";
import { FancyButton } from "components/atoms/Button";

import ListingList from "pages/customerFiles/components/ListingList";

const MANDATORY_FIELDS = [
  { id: "subcategory1", name: "Sous catégorie 1" },
  { id: "functional_characteristics.Dimensions", name: "Dimensions de l'appareil" },
  { id: "functional_characteristics.Dimensions.H", name: "Hauteur de l'appareil" },
  { id: "functional_characteristics.Dimensions.L", name: "Largeur de l'appareil" },
  { id: "functional_characteristics.Dimensions.P", name: "Profondeur de l'appareil" },
  { id: "appearance", name: "État esthétique (Apparence)" },
  { id: "product_model", name: "Modèle de l'appareil" },
  { id: "installation_type_id", name: "Type d'installation" },
  { id: "functional_characteristics.Poids (en kg)", name: "Poids" },
];

const useSmallBodyStyles = makeStyles((theme) => ({
  warning: {
    color: theme.palette.secondary.red,
    fontWeight: "600",
  },
  root: {
    fontWeight: "600",
    color: theme.palette.secondary.contrastText,
  },
  removeInputSubText: {
    "& p": {
      display: "none",
    },
    marginBottom: theme.spacing(1),
  },
  toolbar: {
    backgroundColor: "white",
  },
  saveButton: {
    fontSize: 14,
    fontWeight: "bold",
    height: 48,
    justifyContent: "center",
    padding: "18px 14px",
    boxShadow: "none",
  },
  validModalButton: {
    color: "#2B3B5C",
    backgroundColor: "#FCD660",
    borderRadius: "12px",
    fontSize: 14,
    fontWeight: "bold",
    height: 48,
    justifyContent: "center",
    padding: "18px 14px",
    boxShadow: "none",
  },
  modalButton: {
    borderRadius: "12px",
    fontSize: 14,
    fontWeight: "bold",
    height: 48,
    justifyContent: "center",
    padding: "18px 14px",
    boxShadow: "none",
  },
  pictureModal: {
    color: "red",
  },
  editForm: {
    paddingBottom: "2rem",
    marginBottom: "4rem",
    border: "1px solid rgba(135, 149, 179, 0.3)",
    borderRadius: "8px",
  },
}));

const formatPrice = (val) => parseFloat(val).toFixed(2) / 100;
const parsePrice = (v) => parseInt(v * 100);

const discountByAppearance = {};
discountByAppearance[LIKE_NEW] = 0.2;
discountByAppearance[GOOD_CONDITION] = 0.24;
discountByAppearance[PROPER_CONDITION] = 0.29;
discountByAppearance[WEARY] = 0.34;

const discountCoef = (appearance) => {
  return discountByAppearance[appearance] || 0;
};

const VAT_RATE = 0.2;

const SavePictureModal = ({ isOpen, handleCloseModal, savePicture, record, classes }) => {
  const { values } = useFormState();
  const currentPictures = (({ picture_1, picture_2, picture_3, picture_4, picture_5 }) => ({
    picture_1,
    picture_2,
    picture_3,
    picture_4,
    picture_5,
  }))(record);

  const modifiedPictures = (({ picture_1, picture_2, picture_3, picture_4, picture_5 }) => ({
    picture_1,
    picture_2,
    picture_3,
    picture_4,
    picture_5,
  }))(values);

  const difference = diff(currentPictures, modifiedPictures);
  return (
    <Modal isOpen={isOpen} handleCloseModal={handleCloseModal} modalClassName="modal-medium-content">
      <Box m={2}>
        <Box mb={2}>
          <Typography variant="body2" color="secondary.contrastText" className={classes.warning}>
            Attention, cette modification sera appliquée à toutes les machines appartenant au modèle suivant:
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography variant="body2" color="secondary.contrastText" className={classes.root}>
            {values.product_type.name} - {values.brand.name} - {values.reference}
          </Typography>
        </Box>

        <Button onClick={() => savePicture(difference)} className={classes.validModalButton}>
          Oui
        </Button>
        <Button onClick={handleCloseModal} className={classes.modalButton}>
          Non
        </Button>
      </Box>
    </Modal>
  );
};

const PriceInput = ({ source, label, disabled }) => (
  <NumberInput
    source={source}
    label={label}
    fullWidth
    format={formatPrice}
    parse={parsePrice}
    disabled={disabled}
    InputProps={{
      endAdornment: <InputAdornment position="end">€</InputAdornment>,
    }}
  />
);

const ProductSellingInputs = (props) => {
  const classes = useSmallBodyStyles();
  const productPublished = props.record && props.record.is_online;
  const [isOpenSavePictureModal, setIsOpenSavePictureModal] = useState(false);
  const { values, dirty } = useFormState();
  const { change } = useForm();

  const getUnfilledFields = () => {
    // subcategory1 is not mandatory for dishwashers and microwaves
    const fields = ["Lave-vaisselle", "Four micro-ondes"].includes(props.record.product_type)
      ? MANDATORY_FIELDS.filter((item) => item.id !== "subcategory1")
      : MANDATORY_FIELDS;

    let list = [];
    for (let i = 0, len = fields.length; i < len; i++) {
      const fieldId = fields[i].id;
      const fieldName = fields[i].name;
      if (fieldId.includes(".")) {
        let objectField = props.record;
        let nestedField = fieldId;
        while (nestedField.includes(".")) {
          objectField = objectField && objectField[nestedField.split(".")[0]];
          nestedField = nestedField.substring(nestedField.indexOf(".") + 1);
        }
        !(objectField && [nestedField] && objectField[nestedField]) && list.push(fieldName);
      } else {
        !props.record[fieldId] && list.push(fieldName);
      }
    }
    if (list.length > 0) {
      return list;
    }
    return null;
  };

  const savePicture = (values) => {
    dataProvider
      .update("product-models", {
        id: props.record.product_model,
        data: (({ picture_1, picture_2, picture_3, picture_4, picture_5 }) => ({
          picture_1,
          picture_2,
          picture_3,
          picture_4,
          picture_5,
        }))(values),
      })
      .then((response) => {
        if (response.status === 200) {
          setIsOpenSavePictureModal(false);
          toastr.success("Succès", "Modifications enregistrées");
          props.save();
        } else {
          toastr.error("Erreur", "Une erreur est survenue");
        }
      });
  };
  // Calculate drop in value and selling price if appearance, MSP or discount changed
  useEffect(() => {
    const dropInValue = Math.round(values.market_selling_price * discountCoef(values.appearance));
    change("drop_in_value", dropInValue);
    change(
      "selling_price_withtax",
      Math.max(Math.round((values.market_selling_price - dropInValue) / 100) * 100 - values.murfy_discount, 0)
    );
  }, [change, values.appearance, values.market_selling_price, values.murfy_discount]);

  // Calculate price excluding VAT if price with VAT changed
  useEffect(() => {
    change("selling_price_pretax", Math.round(values.selling_price_withtax / (1 + VAT_RATE)));
  }, [change, values.selling_price_withtax]);

  const useMarketPrice = () => {
    change("market_selling_price", props.record.market_price);
  };

  return (
    <>
      {props.isWorkshopFile ? (
        <ProductToggleGroup
          record={props.record}
          tasks={props.tasks}
          refreshWorkshopFileData={props.refreshWorkshopFileData}
        />
      ) : (
        <BooleanInput source="is_online" label="Machine en ligne ?" fullWidth />
      )}

      <Typography variant="body2" color="secondary.contrastText" className={classes.root}>
        Apparence
      </Typography>
      <Box display="flex" width="100%">
        <Box width="320px" className={classes.removeInputSubText}>
          <SelectInput
            label="Apparence"
            source="appearance"
            choices={AppearancesChoices}
            disabled={productPublished}
            fullWidth
          />
        </Box>
      </Box>
      <Typography variant="body2" color="secondary.contrastText" className={classes.root}>
        Prix
      </Typography>
      <Box width="100%" mb={1} display="flex" alignItems="center">
        <Box width="320px" className={classes.removeInputSubText}>
          <PriceInput source="market_selling_price" label="Prix du marché (TTC)" disabled={productPublished} />
        </Box>
        {props.record.market_price !== values.market_selling_price && !dirty && (
          <Box alignItems="center" style={{ columnGap: 16 }} ml={2} mt={1}>
            <FancyButton disabled={productPublished} onClick={useMarketPrice}>{`Utiliser le dernier prix du marché: ${
              props.record.market_price / 100
            }€`}</FancyButton>
          </Box>
        )}
      </Box>
      <Box display="flex" width="100%">
        <Box width="320px" className={classes.removeInputSubText}>
          <PriceInput
            source="selling_price_pretax"
            label="Prix de vente HT"
            disabled={props.record.supplier_tax === null || productPublished}
          />
        </Box>
        <Box width="320px" marginLeft="24px" className={classes.removeInputSubText}>
          <PriceInput
            source="selling_price_withtax"
            label="Prix de vente TTC"
            disabled={props.record.supplier_tax === null || productPublished}
          />
        </Box>
      </Box>
      <Typography variant="body2" color="secondary.contrastText" className={classes.root}>
        Photos de la machine
      </Typography>
      <PictureForm
        pictures={props.record.pictures}
        disabled={productPublished}
        save={props.save}
        product={props.record}
      />
      <Edit resource="product-models" id={props.record.product_model} basePath="">
        <SimpleForm
          toolbar={
            <CustomToolbar
              classes={classes}
              openModal={() => {
                setIsOpenSavePictureModal(true);
              }}
            />
          }
          className={classes.editForm}
        >
          <>
            <Typography variant="body2" color="secondary.contrastText" className={classes.root}>
              Photos du modèle neuf
            </Typography>

            <Box display="flex" width="100%">
              <Box width="180px" margin="20px">
                <ProductImageInput source="picture_1" label="Photo 1" />
              </Box>
              <Box width="180px" margin="20px">
                <ProductImageInput source="picture_2" label="Photo 2" />
              </Box>
              <Box width="180px" margin="20px">
                <ProductImageInput source="picture_3" label="Photo 3" />
              </Box>
            </Box>
            <Box display="flex" width="100%">
              <Box width="200px" margin="20px">
                <ProductImageInput source="picture_4" label="Photo 4" />
              </Box>
              <Box width="200px" margin="20px">
                <ProductImageInput source="picture_5" label="Photo 5" />
              </Box>
            </Box>
          </>
          <SavePictureModal
            isOpen={isOpenSavePictureModal}
            handleCloseModal={() => setIsOpenSavePictureModal(false)}
            savePicture={savePicture}
            classes={classes}
          />
        </SimpleForm>
      </Edit>

      {getUnfilledFields() && (
        <Box mb={2}>
          <Typography variant="body1" className={classes.pictureModal}>
            Attention champ(s) manquant(s):
            {getUnfilledFields().map((field) => (
              <div>{field} </div>
            ))}
          </Typography>
        </Box>
      )}
      <ListingList
        disabled={!!getUnfilledFields()}
        props={props}
        record={props.record}
        source={props.source}
        refreshWorkshopFileData={props.refreshWorkshopFileData}
      />
      {props.isWorkshopFile ? (
        <ProductToggleGroup tasks={props.tasks} record={props.record} />
      ) : (
        <BooleanInput source="is_online" label="Machine en ligne ?" fullWidth />
      )}
    </>
  );
};

const CustomToolbar = (props) => {
  return (
    <Toolbar {...props} classes={{ toolbar: props.classes.toolbar }}>
      <SaveButton label="Enregistrer les modifications" onSave={props.openModal} className={props.classes.saveButton} />
    </Toolbar>
  );
};

export default ProductSellingInputs;
