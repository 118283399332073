import React, { useEffect, useState } from "react";

import { AutocompleteInput, SelectInput, required } from "react-admin";

import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import dataProvider from "dataProvider";

import ProductReceptionInputs from "components/ProductReceptionInputs";
import ProductToggleGroup from "components/ProductToggleGroup";

const useSmallBodyStyles = makeStyles((theme) => ({
  root: {
    fontWeight: "600",
    color: theme.palette.secondary.contrastText,
  },
}));

export function getFilteredLocationList(warehouse, callback, isWorkshopEditable, productId) {
  let filter = { ancestor_path: warehouse };
  if (!isWorkshopEditable) {
    filter = { product: productId };
  }
  dataProvider
    .getList("location", {
      sort: { field: "id", order: "DESC" },
      filter: filter,
    })
    .then(({ data }) => {
      callback(data);
    });
}

const ProductLogisticInputs = (props) => {
  const classes = useSmallBodyStyles();

  const [warehouse, setWarehouse] = useState();
  const [warehouseData, setWarehouseData] = useState();

  useEffect(() => {
    getFilteredLocationList(warehouse, setWarehouseData, props.isWorkshopEditable, props.productId);
  }, [warehouse, props.isWorkshopEditable, props.productId]);

  function getWarehouseData() {
    return warehouseData;
  }

  const productPublished = props.record && props.record.is_online;
  return (
    <>
      {props.isWorkshopFile && (
        <ProductToggleGroup
          tasks={props.tasks}
          record={props.record}
          refreshWorkshopFileData={props.refreshWorkshopFileData}
        />
      )}

      <Box display="flex" width="100%">
        {props.isWorkshopEditable && (
          <Box width="320px" marginRight="24px">
            <Typography variant="body2" color="secondary.contrastText" className={classes.root}>
              Atelier
            </Typography>
            <SelectInput
              label="Atelier"
              source="warehouse"
              choices={[
                { id: "WHBOB", name: "Bobigny" },
                { id: "WHLIL", name: "Lille" },
                { id: "WHLYO", name: "Lyon" },
                { id: "WHNAN", name: "Nantes" },
              ]}
              alwaysOn
              emptyText="Atelier"
              fullWidth
              InputProps={{ disableUnderline: true }}
              onChange={(event) => setWarehouse(event.target.value)}
              disabled={productPublished}
            />
          </Box>
        )}

        <Box width="320px">
          <Typography variant="body2" color="secondary.contrastText" className={classes.root}>
            Emplacement
          </Typography>
          <AutocompleteInput
            optionValue="id"
            optionText="name"
            fullWidth
            choices={getWarehouseData()}
            options={{ disabled: (!warehouse && props.isWorkshopEditable) || productPublished }}
            source="location"
            reference="location"
            label="Emplacement"
            validate={[required()]}
            name="location"
            helperText={props.locationHelperText}
          />
        </Box>
      </Box>
      <Typography variant="body2" color="secondary.contrastText" className={classes.root}>
        Réception
      </Typography>
      <ProductReceptionInputs record={props.record} />
    </>
  );
};

export default ProductLogisticInputs;
