import React, { useState } from "react";

import { useNotify } from "react-admin";

import { Box, styled } from "@material-ui/core";

import { CustomerFile } from "types/customer-files";

import Caption from "components/atoms/Typography/Caption";

import { reopeningCustomerFile } from "utils/api";

import ReopeningCustomerFileModal from "./ReopeningCustomerFileModal";

const StyledBox = styled(Box)({
  boxShadow: "0px 1px 15px rgba(0, 0, 0, 0.08), 0px 0px 3px rgba(0, 0, 0, 0.08)",
  borderRadius: 4,
  backgroundColor: "white",
  color: "#8795B3",
  cursor: "pointer",
  justifyContent: "space-around",
  padding: "4px 8px",
  whiteSpace: "nowrap",
  "&:hover": {
    background: "#f1f1f1",
  },
  margin: "0 4px 4px",
});

const StyledCaption = styled(Caption)({
  display: "inline",
  fontWeight: 500,
  color: "#8795B3",
});

type ReopeningCustomerFileProps = {
  customerFile: CustomerFile;
  getCustomerFileDetail: (id: number) => void;
};

const ReopeningCustomerFile: React.FC<ReopeningCustomerFileProps> = ({ customerFile, getCustomerFileDetail }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const notify = useNotify();

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmitReopening = async () => {
    const { status } = await reopeningCustomerFile(customerFile.uuid);
    if (status < 200 || status >= 300) {
      closeModal();
      notify("Une erreur est survenue", "warning");
    } else {
      getCustomerFileDetail(customerFile.id);
      closeModal();
      notify("Succès");
    }
  };

  return (
    <>
      <StyledBox
        width="fit-content"
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        <StyledCaption>Réouverture du dossier</StyledCaption>
      </StyledBox>
      {isModalOpen && (
        <ReopeningCustomerFileModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          handleSubmitReopening={handleSubmitReopening}
        />
      )}
    </>
  );
};

export default ReopeningCustomerFile;
