import { Chip, ChipProps as CoreChipProps, Theme, styled } from "@material-ui/core";

interface ChipProps extends CoreChipProps {
  isCovered?: boolean;
}

export default styled(Chip)<Theme, ChipProps>(({ theme, isCovered }) => {
  let variantProps;
  switch (isCovered) {
    //the theme is not properly configured to work with typescript
    case true:
      variantProps = {
        backgroundColor: "#E0F5E7",
        color: "#35AB5E",
        border: "1px solid #81D89F",
      };
      break;
    case false:
      variantProps = {
        backgroundColor: "#FEEBEB",
        border: "1px solid #F85B5B",
        color: "#F85B5B",
      };
      break;
    default: {
      variantProps = {};
      break;
    }
  }
  return {
    fontSize: theme.spacing(2),
    borderRadius: theme.spacing(10),
    padding: "6px 12px",
    fontWeight: 700,
    ...variantProps,
  };
});
