import { downloadCSV } from "react-admin";

import axios from "axios";
import { toastr } from "react-redux-toastr";

import dataProvider from "dataProvider";

export const postS3File = async (uploadType, file, url, filename) => {
  /*
   * Upload a file to AWS S3 and POST the file S3 key to the given API url.
   *
   * `uploadType`: The object type to import (ex: "skumodelreferences")
   * `file`: The file containing the data to import
   * `url`: The target API url to POST the import (ex: "sku/sku-models/bulk")
   */

  // Get AWS S3 credentials and upload key from the backend API
  const {
    data: { upload_key, form_data },
  } = await dataProvider.create("common/uploads", { data: { type: uploadType, filename: filename } });

  // Upload the CSV file on AWS S3
  const formData = new FormData();
  Object.entries(form_data.fields).forEach(([key, value]) => formData.append(key, value));
  formData.append("file", file);

  axios
    .post(form_data.url, formData, { headers: { "Content-Type": "multipart/form-data" } })
    .then(() =>
      // Send a bulk request to the backend API with the AWS S3 file path to import
      dataProvider
        .create(url, { data: { upload_key: upload_key } })
        .then(({ data, status, headers }) => {
          // The data provider returns undefined status on success for json responses. Does anyone know why ?
          // However CSV responses return a 200 status code as expected.
          if (data.success || status === 200) {
            if (headers.ContentType === "text/csv") {
              downloadCSV(data, "spare-parts-order");
            } else {
              toastr.success(
                "Import réussi !",
                Number.isInteger(data.created) &&
                  Number.isInteger(data.updated) &&
                  `${data.created} lignes créées, ${data.updated} lignes mises à jour`
              );
            }
          } else if (status === 400) {
            toastr.error("Erreur", data.detail);
          } else {
            toastr.error("Erreur", "Echec de l'import, merci de contacter l'équipe produit pour plus d'informations");
          }
        })
        .catch((err) => console.err(err))
    )
    .catch((err) =>
      toastr.error(
        "Erreur",
        "Echec de l'import du fichier sur S3, merci de contacter l'équipe produit pour plus d'informations"
      )
    );
};
