import React, { useState } from "react";

import { useDelete, useNotify } from "react-admin";

import { Box, IconButton, Link } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";

const CommentAttachmentLink = ({ comment, deletable }) => {
  const [deleteComment] = useDelete("comment-attachments");
  const [deletedAttachmentIds, setDeletedAttachmentIds] = useState([]);
  const notify = useNotify();

  const handleDelete = (attachmentId) => {
    deleteComment(
      {
        payload: {
          id: attachmentId,
        },
      },
      {
        onSuccess: ({ status }) => {
          if (status > 204) {
            notify("Une erreur est survenue, le document n'a pas été supprimé", "warning");
          } else {
            notify("Document supprimé");
            setDeletedAttachmentIds([...deletedAttachmentIds, attachmentId]);
          }
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };

  return (
    <Box>
      {comment.attachments && comment.attachments.length > 0 && (
        <Box display="inline-grid">
          {comment.attachments.map((attachment, index) => {
            return (
              <>
                {!deletedAttachmentIds.some((deletedId) => deletedId === attachment.id) && (
                  <Box display="flex">
                    <Box my="auto">
                      <Link target="_blank" href={attachment.file} variant="caption">
                        • Document {index + 1}
                      </Link>
                    </Box>
                    {deletable && (
                      <IconButton ml={1} onClick={() => handleDelete(attachment.id)}>
                        <ClearIcon />
                      </IconButton>
                    )}
                  </Box>
                )}
              </>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default CommentAttachmentLink;
