import find from "lodash/find";
import get from "lodash/get";
import unionBy from "lodash/unionBy";
import { getEmployees } from "modules/generators/getEmployeesGenerator";
import { sendAuthenticatedGetRequestToAPI, sendAuthenticatedRequestToAPI } from "modules/generators/requestsToAPI";
import { call, select, takeEvery, takeLatest } from "redux-saga/effects";

import { addOrReplaceByKey } from "utils/addOrReplace";
import { getAPIUrl } from "utils/networking";

export const GET_CUSTOMER_FILES_REQUEST = "CustomerFiles/GET_CUSTOMER_FILES_REQUEST";
export const GET_CUSTOMER_FILES_SUCCESS = "CustomerFiles/GET_CUSTOMER_FILES_SUCCESS";
export const GET_CUSTOMER_FILES_ERROR = "CustomerFiles/GET_CUSTOMER_FILES_ERROR";

export const GET_EMPLOYEES_REQUEST = "CustomerFiles/GET_EMPLOYEES_REQUEST";
export const GET_EMPLOYEES_SUCCESS = "CustomerFiles/GET_EMPLOYEES_SUCCESS";
export const GET_EMPLOYEES_ERROR = "CustomerFiles/GET_EMPLOYEES_ERROR";

export const GET_CUSTOMER_FILES_STATES_REQUEST = "CustomerFiles/GET_CUSTOMER_FILES_STATES_REQUEST";
export const GET_CUSTOMER_FILES_STATES_SUCCESS = "CustomerFiles/GET_CUSTOMER_FILES_STATES_SUCCESS";
export const GET_CUSTOMER_FILES_STATES_ERROR = "CustomerFiles/GET_CUSTOMER_FILES_STATES_ERROR";

export const GET_CUSTOMER_FILES_TAGS_REQUEST = "CustomerFiles/GET_CUSTOMER_FILES_TAGS_REQUEST";
export const GET_CUSTOMER_FILES_TAGS_SUCCESS = "CustomerFiles/GET_CUSTOMER_FILES_TAGS_SUCCESS";
export const GET_CUSTOMER_FILES_TAGS_ERROR = "CustomerFiles/GET_CUSTOMER_FILES_TAGS_ERROR";
export const POST_CUSTOMER_FILES_TAG_REQUEST = "CustomerFiles/POST_CUSTOMER_FILES_TAG_REQUEST";
export const POST_CUSTOMER_FILES_TAG_SUCCESS = "CustomerFiles/POST_CUSTOMER_FILES_TAG_SUCCESS";
export const POST_CUSTOMER_FILES_TAG_ERROR = "CustomerFiles/POST_CUSTOMER_FILES_TAG_ERROR";
export const DELETE_CUSTOMER_FILES_TAG_REQUEST = "CustomerFiles/DELETE_CUSTOMER_FILES_TAG_REQUEST";
export const DELETE_CUSTOMER_FILES_TAG_SUCCESS = "CustomerFiles/DELETE_CUSTOMER_FILES_TAG_SUCCESS";
export const DELETE_CUSTOMER_FILES_TAG_ERROR = "CustomerFiles/DELETE_CUSTOMER_FILES_TAG_ERROR";

export const SET_CUSTOMER_FILE_STATE_FILTER = "CustomerFiles/SET_CUSTOMER_FILE_STATE_FILTER";
export const SET_CUSTOMER_FILES_FILTER = "CustomerFiles/SET_CUSTOMER_FILES_FILTER";
export const RESET_CUSTOMER_FILES_FILTER = "CustomerFiles/RESET_CUSTOMER_FILES_FILTER";

export const INCREASE_CUSTOMER_FILES_PAGE_NUMBER = "CustomerFiles/INCREASE_CUSTOMER_FILES_PAGE_NUMBER";
export const DECREASE_CUSTOMER_FILES_PAGE_NUMBER = "CustomerFiles/DECREASE_CUSTOMER_FILES_PAGE_NUMBER";
export const RESET_CUSTOMER_FILES_PAGE_NUMBER = "CustomerFiles/RESET_CUSTOMER_FILES_PAGE_NUMBER";

export const PATCH_CUSTOMER_FILES_SUCCESS = "CustomerFiles/PATCH_CUSTOMER_FILES_SUCCESS";

export function getCustomerFilesRequest() {
  return {
    type: GET_CUSTOMER_FILES_REQUEST,
  };
}

export function getCustomerFilesSuccess(customerFiles) {
  return {
    type: GET_CUSTOMER_FILES_SUCCESS,
    payload: customerFiles,
  };
}

export function getCustomerFilesError(error) {
  return {
    type: GET_CUSTOMER_FILES_ERROR,
    payload: error,
  };
}

export function getEmployeesRequest() {
  return {
    type: GET_EMPLOYEES_REQUEST,
  };
}

export function getEmployeesSuccess(employees) {
  return {
    type: GET_EMPLOYEES_SUCCESS,
    payload: employees,
  };
}

export function getEmployeesError(error) {
  return {
    type: GET_EMPLOYEES_ERROR,
    payload: error,
  };
}

export function getCustomerFilesStatesRequest() {
  return {
    type: GET_CUSTOMER_FILES_STATES_REQUEST,
  };
}

export function getCustomerFilesStatesSuccess(customerFilesStates) {
  return {
    type: GET_CUSTOMER_FILES_STATES_SUCCESS,
    payload: customerFilesStates,
  };
}

export function getCustomerFilesStatesError(error) {
  return {
    type: GET_CUSTOMER_FILES_STATES_ERROR,
    payload: error,
  };
}

export function setCustomerFileStateFilter(stateId) {
  return {
    type: SET_CUSTOMER_FILE_STATE_FILTER,
    payload: stateId,
  };
}

export function getCustomerFilesTagsRequest() {
  return {
    type: GET_CUSTOMER_FILES_TAGS_REQUEST,
  };
}

export function getCustomerFilesTagsSuccess(customerFilesTags) {
  return {
    type: GET_CUSTOMER_FILES_TAGS_SUCCESS,
    payload: customerFilesTags,
  };
}

export function getCustomerFilesTagsError(error) {
  return {
    type: GET_CUSTOMER_FILES_TAGS_ERROR,
    payload: error,
  };
}

export function postCustomerFilesTagRequest(color, text) {
  return {
    type: POST_CUSTOMER_FILES_TAG_REQUEST,
    payload: { color, text },
  };
}

export function postCustomerFilesTagSuccess(customerFilesTag) {
  return {
    type: POST_CUSTOMER_FILES_TAG_SUCCESS,
    payload: customerFilesTag,
  };
}

export function postCustomerFilesTagError(error) {
  return {
    type: POST_CUSTOMER_FILES_TAG_ERROR,
    payload: error,
  };
}

export function deleteCustomerFilesTagRequest(tagId) {
  return {
    type: DELETE_CUSTOMER_FILES_TAG_REQUEST,
    payload: tagId,
  };
}

export function deleteCustomerFilesTagSuccess(response, tagId) {
  return {
    type: DELETE_CUSTOMER_FILES_TAG_SUCCESS,
    payload: tagId,
  };
}

export function deleteCustomerFilesTagError(error) {
  return {
    type: DELETE_CUSTOMER_FILES_TAG_ERROR,
    payload: error,
  };
}

export function increaseCustomerFilesPageNumber(pageNumber) {
  return {
    type: INCREASE_CUSTOMER_FILES_PAGE_NUMBER,
    payload: pageNumber,
  };
}

export function decreaseCustomerFilesPageNumber(pageNumber) {
  return {
    type: DECREASE_CUSTOMER_FILES_PAGE_NUMBER,
    payload: pageNumber,
  };
}

export function resetCustomerFilesPageNumber() {
  return {
    type: RESET_CUSTOMER_FILES_PAGE_NUMBER,
  };
}

export function setCustomerFilesFilters(filterName, filterValue) {
  return {
    type: SET_CUSTOMER_FILES_FILTER,
    payload: {
      filterName: filterName,
      filterValue: filterValue,
    },
  };
}

export function resetCustomerFilesFilters(filterName) {
  return {
    type: RESET_CUSTOMER_FILES_FILTER,
    payload: filterName,
  };
}

export function patchCustomerFilesSuccess(customerFile) {
  return {
    type: PATCH_CUSTOMER_FILES_SUCCESS,
    payload: { customerFile: customerFile },
  };
}

// selectors

export const getStateFilterSelector = (state) => state.customerFiles.stateFilter;

export const getCustomerFilesStatesGroupedByTypeSelector = (state) => {
  var typeId = 1;
  const customerFilesStatesGroupedByTypes = state.customerFiles.customerFilesStates.reduce(
    (statesGroupedByTypesAcc, customerFileState) => {
      if (!statesGroupedByTypesAcc["Tous les dossiers clients"]) {
        statesGroupedByTypesAcc["Tous les dossiers clients"] = {
          counter: 0,
          id: 0,
          name: "Tous les dossiers clients",
          order: 0,
          elements: [],
        };
      }
      var type = customerFileState.type;
      if (!statesGroupedByTypesAcc[type]) {
        statesGroupedByTypesAcc[type] = {
          counter: 0,
          id: typeId,
          name: type,
          order: typeId,
          elements: [],
        };
        typeId += 1;
      }
      statesGroupedByTypesAcc[type].elements.push(customerFileState);
      statesGroupedByTypesAcc[type].counter += customerFileState.counter; // sum states counters to get type counter
      statesGroupedByTypesAcc["Tous les dossiers clients"].counter += customerFileState.counter; // sum all counters to get the total counter
      return statesGroupedByTypesAcc;
    },
    {}
  );
  return Object.keys(customerFilesStatesGroupedByTypes).map((typeName) => customerFilesStatesGroupedByTypes[typeName]);
};

export const getCustomerFilesPageNumberFilterSelector = (state) => state.customerFiles.customerFilesPageNumberFilter;

export const getCustomerFilesFilterSelector = (state, filterName) =>
  get(
    find(state.customerFiles.customerFilesFilters, (filter) => filter.filterName === filterName),
    "filterValue"
  );

export const getCustomerFilesFilteredByStateSelector = (state) => {
  return state.customerFiles.stateFilter
    ? state.customerFiles.customerFiles &&
        state.customerFiles.customerFiles.filter(
          (customerFile) => customerFile.state === state.customerFiles.stateFilter
        )
    : state.customerFiles.customerFiles;
};

// generators
export function* getCustomerFiles(action) {
  const emailFilter = yield select(getCustomerFilesFilterSelector, "email");
  const lastnameFilter = yield select(getCustomerFilesFilterSelector, "lastname");
  const tagFilter = yield select(getCustomerFilesFilterSelector, "tag");
  const stateFilter = yield select(getStateFilterSelector);
  const customerFilesPageNumberFilter = yield select(getCustomerFilesPageNumberFilterSelector);
  const config = {
    method: "get",
    url: `${getAPIUrl()}/murfy-erp/customer-files/?page=${customerFilesPageNumberFilter}`,
    params: {
      email: emailFilter,
      lastname: lastnameFilter,
      state: stateFilter,
      tag: tagFilter,
    },
  };
  yield call(sendAuthenticatedRequestToAPI, config, getCustomerFilesSuccess, getCustomerFilesError);
}

export function* getCustomerFilesStates(action) {
  yield call(
    sendAuthenticatedGetRequestToAPI,
    `/murfy-erp/customer-file-states/`,
    getCustomerFilesStatesSuccess,
    getCustomerFilesStatesError
  );
}

export function* getCustomerFilesTags(action) {
  yield call(
    sendAuthenticatedGetRequestToAPI,
    `/murfy-erp/customer-file-tags/`,
    getCustomerFilesTagsSuccess,
    getCustomerFilesTagsError
  );
}

export function* postCustomerFilesTag(action) {
  const config = {
    method: "post",
    url: `${getAPIUrl()}/murfy-erp/customer-file-tags/`,
    data: {
      color: action.payload.color,
      text: action.payload.text,
    },
  };
  yield call(sendAuthenticatedRequestToAPI, config, postCustomerFilesTagSuccess, postCustomerFilesTagError);
}

export function* deleteCustomerFilesTag(action) {
  const config = {
    method: "delete",
    url: `${getAPIUrl()}/murfy-erp/customer-file-tag/${action.payload}/`,
  };
  yield call(
    sendAuthenticatedRequestToAPI,
    config,
    deleteCustomerFilesTagSuccess,
    deleteCustomerFilesTagError,
    undefined,
    [action.payload]
  );
}

// sagas
export function* getCustomerFilesSaga() {
  yield takeLatest(
    [
      DECREASE_CUSTOMER_FILES_PAGE_NUMBER,
      GET_CUSTOMER_FILES_REQUEST,
      INCREASE_CUSTOMER_FILES_PAGE_NUMBER,
      POST_CUSTOMER_FILES_TAG_SUCCESS,
      RESET_CUSTOMER_FILES_FILTER,
      SET_CUSTOMER_FILE_STATE_FILTER,
      SET_CUSTOMER_FILES_FILTER,
    ],
    getCustomerFiles
  );
}

export function* getCustomerFilesStatesSaga() {
  yield takeEvery([GET_CUSTOMER_FILES_STATES_REQUEST, PATCH_CUSTOMER_FILES_SUCCESS], getCustomerFilesStates);
}

export function* getCustomerFilesTagsSaga() {
  yield takeEvery(GET_CUSTOMER_FILES_TAGS_REQUEST, getCustomerFilesTags);
}

export function* getEmployeesSaga() {
  yield takeEvery(GET_EMPLOYEES_REQUEST, getEmployees, getEmployeesSuccess, getEmployeesError);
}

export function* postCustomerFilesTagSaga() {
  yield takeEvery(POST_CUSTOMER_FILES_TAG_REQUEST, postCustomerFilesTag);
}

export function* deleteCustomerFilesTagSaga() {
  yield takeEvery(DELETE_CUSTOMER_FILES_TAG_REQUEST, deleteCustomerFilesTag);
}

// initial state
const initialState = {
  customerFiles: null,
  customerFilesCounter: null,
  customerFilesFilters: [],
  customerFilesPageNumberFilter: 1,
  customerFilesStates: [],
  customerFilesTags: [],
  isTagSuccessfullySubmitted: false,
  stateFilter: null,
};

// reducer
export function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_CUSTOMER_FILES_REQUEST:
      return {
        ...state,
        error: null,
      };
    case GET_CUSTOMER_FILES_SUCCESS:
      return {
        ...state,
        customerFiles: action.payload.results,
        customerFilesCounter: action.payload.count,
        error: null,
      };
    case GET_CUSTOMER_FILES_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case GET_EMPLOYEES_REQUEST:
      return {
        ...state,
        error: null,
      };
    case GET_EMPLOYEES_SUCCESS:
      return {
        ...state,
        employees: action.payload,
      };
    case GET_EMPLOYEES_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case GET_CUSTOMER_FILES_STATES_REQUEST:
      return {
        ...state,
        error: null,
      };
    case GET_CUSTOMER_FILES_STATES_SUCCESS:
      return {
        ...state,
        customerFilesStates: action.payload,
      };
    case GET_CUSTOMER_FILES_STATES_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case GET_CUSTOMER_FILES_TAGS_REQUEST:
      return {
        ...state,
        error: null,
      };
    case GET_CUSTOMER_FILES_TAGS_SUCCESS:
      return {
        ...state,
        customerFilesTags: action.payload,
      };
    case GET_CUSTOMER_FILES_TAGS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case POST_CUSTOMER_FILES_TAG_REQUEST:
      return {
        ...state,
        error: null,
        isTagSuccessfullySubmitted: false,
      };
    case POST_CUSTOMER_FILES_TAG_SUCCESS:
      return {
        ...state,
        customerFilesTags: [...state.customerFilesTags, action.payload],
        isTagSuccessfullySubmitted: true,
      };
    case POST_CUSTOMER_FILES_TAG_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_CUSTOMER_FILES_TAG_REQUEST:
      return {
        ...state,
        error: null,
      };
    case DELETE_CUSTOMER_FILES_TAG_SUCCESS:
      return {
        ...state,
        customerFilesTags: [...state.customerFilesTags.filter((tag) => tag.id !== action.payload)],
      };
    case DELETE_CUSTOMER_FILES_TAG_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_CUSTOMER_FILE_STATE_FILTER:
      return {
        ...state,
        stateFilter: action.payload,
      };
    case INCREASE_CUSTOMER_FILES_PAGE_NUMBER:
      return {
        ...state,
        customerFilesPageNumberFilter: action.payload + 1,
      };
    case DECREASE_CUSTOMER_FILES_PAGE_NUMBER:
      return {
        ...state,
        customerFilesPageNumberFilter: action.payload - 1,
      };
    case RESET_CUSTOMER_FILES_PAGE_NUMBER:
      return {
        ...state,
        customerFilesPageNumberFilter: 1,
      };
    case SET_CUSTOMER_FILES_FILTER:
      return {
        ...state,
        customerFilesFilters: addOrReplaceByKey(state.customerFilesFilters, "filterName", action.payload),
      };
    case RESET_CUSTOMER_FILES_FILTER:
      return {
        ...state,
        customerFilesFilters: state.customerFilesFilters.filter((filter) => filter.filterName !== action.payload),
      };
    case PATCH_CUSTOMER_FILES_SUCCESS:
      return {
        ...state,
        customerFiles: unionBy([action.payload.customerFile], [...state.customerFiles], "id"),
      };
    default:
      return state;
  }
}

export default reducer;
