import React from "react";

import Tooltip from "@material-ui/core/Tooltip";

const truncate = (text, length = 15) => (text && text.length > length ? text.slice(0, length) + "..." : text);

export default ({ record }) =>
  record && (
    <Tooltip title={record.designation}>
      <span>{truncate(record.designation, 15)}</span>
    </Tooltip>
  );
