import { toastr } from "react-redux-toastr";
import { call, put, select } from "redux-saga/effects";

import convertIntoFormData from "utils/formData";
import { getAPIUrl, getTokenFromLocalStorage, request } from "utils/networking";

export function* saveWidget(
  requestRoute,
  isMultiPartRequest,
  widgetSelector,
  onSuccessAction,
  onErrorAction,
  saveSubElementsGenerator,
  action
) {
  const widget = yield select(widgetSelector);
  let data = widget;
  if (isMultiPartRequest) data = convertIntoFormData(widget);
  const token = yield call(getTokenFromLocalStorage);
  const isNewWidget = !widget.id;

  const baseConfig = {
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": `${isMultiPartRequest ? "multipart/form-data" : "application/json"}`,
    },
    data: data,
  };

  const getConfig = (isNewWidget) => {
    if (isNewWidget) {
      return {
        ...baseConfig,
        method: "post",
        url: `${getAPIUrl()}/widget/${requestRoute}/`,
      };
    } else {
      return {
        ...baseConfig,
        method: "patch",
        url: `${getAPIUrl()}/widget/${requestRoute}/${widget.id}/`,
      };
    }
  };

  try {
    if (saveSubElementsGenerator) yield call(saveSubElementsGenerator, token);
    const response = yield call(request, getConfig(isNewWidget));
    yield put(onSuccessAction(response));
    yield call(toastr.success, "Succès", "Modifications enregistrées");
  } catch (error) {
    yield put(onErrorAction(error));
    yield call(toastr.error, "Erreur", "Vos modififications n'ont pas été enregistrées");
  }
}
