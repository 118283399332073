import React, { Component } from "react";

import PropTypes from "prop-types";

import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import { withStyles } from "@material-ui/core/styles";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";

import Modal from "components/Modal";

import { getPricePretax, toCents } from "utils/price";

const addGestureWrapper = {
  addGestureWrapper: {
    height: "64px",
    borderBottom: "2px solid #F4F3F8",
    textAlign: "left",
    marginBottom: "24px",
    paddingTop: "18px",
  },
  AddElementInQuote: {
    color: "#F7B349",
    cursor: "pointer",
  },
  addSparePartModal: {
    width: 570,
    height: 480,
  },
  modalTitle: {
    textAlign: "center",
    padding: "37px 0px",
  },
  inputGesture: {
    width: 328,
    margin: 12,
    marginLeft: 96,
  },
  buttonGroup: {
    textAlign: "center",
    paddingTop: 40,
  },
  gestureButtonGroup: {
    textAlign: "center",
    paddingTop: 20,
  },
  cancelButton: {
    color: "#8795B3",
    backgroundColor: "unset",
    boxShadow: "unset",
    marginLeft: 16,
  },
  inputLabel: {
    fontSize: 12,
    display: "grid",
    color: "#2B3B5C",
    marginLeft: 16,
  },
  inputNumberValue: {
    width: "100%",
    border: "1px solid #DBDFE8",
    borderRadius: 8,
    padding: "11px 57px 11px 16px",
  },
  inputCommentValue: {
    width: "100%",
    border: "1px solid #DBDFE8",
    borderRadius: 8,
    padding: "16px",
    height: 96,
  },
  euroSymbol: {
    position: "absolute",
    top: 172,
    right: 130,
  },
  errorMessage: {
    color: "#ff0000",
    fontSize: 12,
    position: "absolute",
    left: 137,
    top: 195,
  },
};

export class AddCommercialGesture extends Component {
  static propTypes = {
    handleCloseGesture: PropTypes.func,
    newElements: PropTypes.any,
    addCommercialGesture: PropTypes.func,
    productId: PropTypes.number,
  };
  constructor(props) {
    super(props);
    this.state = {
      commercialGestureAmount: 0,
      commercialGestureComment: "",
    };
  }

  onInputChange = (inputName) => (event) => {
    this.setState({ [inputName]: event.target.value });
  };

  handleValidateGesture = () => {
    this.props.addCommercialGesture([
      {
        home_repair_product: this.props.productId,
        element_type: "commercial_gesture",
        amount_pretax: -getPricePretax(toCents(this.state.commercialGestureAmount)),
        amount_withtax: -toCents(this.state.commercialGestureAmount),
        customer_file: this.props.customer_file,
        quote_billing: null,
        admin_comments: this.state.commercialGestureComment,
      },
    ]);
    this.props.handleCloseGesture();
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.addGestureWrapper}>
        <Modal
          id="commercial-gesture-modal"
          modalClassName={`modal-small-content ${classes.addSparePartModal}`}
          isOpen
          handleCloseModal={this.props.handleCloseGesture}
        >
          <Typography className={classes.modalTitle} variant="h2">
            Ajouter un élément au devis
          </Typography>
          <div className={classes.inputGesture}>
            <span className={classes.inputLabel}>Montant du geste</span>
            <Input
              className={classes.inputNumberValue}
              name="Montant du geste"
              value={this.state.commercialGestureAmount}
              onChange={this.onInputChange("commercialGestureAmount")}
              disableUnderline
            />
            <EuroSymbolIcon className={classes.euroSymbol} />
            {isNaN(this.state.commercialGestureAmount) && (
              <span id="is-not-a-number-error" className={classes.errorMessage}>
                Valeur incorrecte
              </span>
            )}
          </div>
          <div className={classes.inputGesture}>
            <span className={classes.inputLabel}>Commentaire</span>
            <Input
              className={classes.inputCommentValue}
              name="Commentaire du geste"
              multiline
              rowsMax="4"
              value={this.state.commercialGestureComment}
              onChange={this.onInputChange("commercialGestureComment")}
              disableUnderline
            />
          </div>
          <div className={classes.gestureButtonGroup}>
            <Button
              id="validate-commercial-gesture"
              variant="contained"
              color="secondary"
              onClick={this.handleValidateGesture}
            >
              Valider
            </Button>
            <Button
              id="cancel-commercial-gesture"
              variant="contained"
              className={classes.cancelButton}
              onClick={this.props.handleCloseGesture}
            >
              Annuler
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withStyles(addGestureWrapper)(AddCommercialGesture);
