import React, { Component } from "react";

import PropTypes from "prop-types";

import "./style.css";

export default class EditionButtons extends Component {
  static propTypes = {
    cancelAction: PropTypes.func.isRequired,
    saveAction: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div className="edition-buttons-container">
        <button className="edition-button cancel-button" onClick={this.props.cancelAction}>
          Annuler
        </button>
        <button className="edition-button confirm-button" onClick={this.props.saveAction}>
          Enregistrer
        </button>
      </div>
    );
  }
}
