import React from "react";

import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Color, getHexColor } from "utils/color";

type StylesProps = { hexColor: string };
type ColorChipPropsWithColor = { hexColor?: never; color: Color };
type ColorChipPropsWithHexColor = { hexColor: string; color?: never };
type ColorChipProps = (ColorChipPropsWithColor | ColorChipPropsWithHexColor) & { label: string };

const useStyles = makeStyles((theme) => ({
  tag: {
    color: (props: StylesProps) => (props.hexColor ? `${props.hexColor}` : "black"),
    // 26 = 15% transparency
    // hexa color code follows the following rule: RRGGBBAA where AA is the added transparency
    // more infos at: https://hashnode.com/post/understanding-rrggbbaa-color-notation-cisvdr52x088fwt53h1drf6m2
    background: (props: StylesProps) => (props.hexColor ? `${props.hexColor}26` : "white"),
  },
}));

/**
 * This component is a wrapper around material-ui Chip component adding a hexColor prop
 * A predefined color can also be passed to the color prop instead of a raw hexColor
 */
const ColorChip = ({ hexColor, color, ...props }: ColorChipProps) => {
  const classes = useStyles({ hexColor: hexColor || getHexColor(color as Color) });

  return <Chip className={classes.tag} {...props} />;
};

export default ColorChip;

// Convevient predefined tags
export const CorrespondanceTag = () => <ColorChip label="Correspondance" color="light_blue" />;
export const NewTag = () => <ColorChip label="Neuve" color="dark_blue" />;
export const SecondHandTag = () => <ColorChip label="Reconditionnée" color="turquoise" />;
export const InstockTag = () => <ColorChip label="En stock" color="green" />;
export const OutOfStockTag = () => <ColorChip label="À commander" color="red" />;
