import React from "react";

import { Edit } from "react-admin";

import { Box } from "@material-ui/core";

import OrderEditLayout from "./OrderEditLayout";

const OrderEdit = (props) => {
  return (
    <Box margin={5}>
      <Edit {...props} undoable={false} actions={false}>
        <OrderEditLayout />
      </Edit>
    </Box>
  );
};

export default OrderEdit;
