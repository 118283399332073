export default class DemandItemStatus {
  static get EMITTED() {
    return "emitted";
  }
  static get WAITING_FOR_SKU() {
    return "waiting_for_sku";
  }
  static get LOSS_DECLARED() {
    return "loss_declared";
  }
  static get RESERVED() {
    return "reserved";
  }
  static get PACKED() {
    return "packed";
  }
  static get CANCELED() {
    return "canceled";
  }

  // Unsupported status
  static get CLOSED() {
    return "closed";
  }
}
