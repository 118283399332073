import React from "react";

import { Box, BoxProps, styled } from "@material-ui/core";
import LocalLaundryServiceIcon from "@material-ui/icons/LocalLaundryService";

const Icon = styled(LocalLaundryServiceIcon)(({ theme }: any) => ({
  color: theme.palette.secondary.red,
}));

const StyledBox = styled(Box)(({ theme }: any) => ({
  color: theme.palette.secondary.red,
}));

type AfterSalesIndicatorProps = {
  size?: number;
} & BoxProps;

const AfterSalesIndicator: React.FC<AfterSalesIndicatorProps> = ({ size = 20, ...boxProps }) => {
  return (
    <StyledBox {...boxProps}>
      <Icon style={{ fontSize: size }} />!
    </StyledBox>
  );
};

export default AfterSalesIndicator;
