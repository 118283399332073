import React, { createContext, useEffect } from "react";

import { List } from "react-admin";

import { Route, Switch, useHistory, useLocation } from "react-router-dom";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import { REPAIR_REPORT_CHECK_STATE } from "constants/customerFileAssociatedTasks";

import CustomerFileDetail from "pages/customerFiles/CustomerFileDetail/CustomerFileDetail";
import CustomerFilesFilter from "pages/customerFiles/CustomerFilesList/components/CustomerFilesFilter";
import CustomerFilesListPagination from "pages/customerFiles/CustomerFilesList/components/CustomerFilesListPagination";
import CustomersGrid from "pages/customerFiles/CustomerFilesList/components/CustomersGrid";

import { useConnectedEmployee } from "utils/api";
import { CustomerFileStatesProvider } from "utils/contexts/customerFileStatesContext";
import { useContainsURL } from "utils/hooks/useContainsURL";

const HOME_REPAIR = "home_repair";

export const UserContext = createContext();

export default function CustomerFilesList({
  customerFilesStates,
  customerFilesTags,
  getCustomerFilesStates,
  getAccount,
  getCompanyEmployee,
  getCustomerFilesTags,
  activeCustomerFile,
  idEmployee,
  employees,
  getEmployees,
  updateCustomerFileDetail,
  updateTicketDetail,
  createIncentiveSystem,
  updateCustomerFileAfterActionStatusSparePart,
  changeCustomerFileStatusSparePart,
  deleteCustomerFilesTag,
  postCustomerFilesTag,
  isTagSuccessfullySubmitted,
  CustomActions,
  resetCurrentEditionSession,
  resource,
  ...listProps
}) {
  const classes = useStyles();
  let location = useLocation();
  let history = useHistory();
  const isReportCheckingInterface = useContainsURL(REPAIR_REPORT_CHECK_STATE);

  const connectedEmployee = useConnectedEmployee();
  useEffect(() => {
    getCompanyEmployee();
    getCustomerFilesStates();
    getCustomerFilesTags();
    getEmployees();
    getAccount();
    // eslint-disable-next-line
  }, []);

  const updateStatusSparePartInCustomerFile = (idSparePartAfterPost, customerFile) => {
    updateCustomerFileAfterActionStatusSparePart(idSparePartAfterPost, customerFile);
  };

  const handleChangeStatusSparePart = (idSparePare, newStatus) => {
    changeCustomerFileStatusSparePart(
      idSparePare,
      newStatus,
      activeCustomerFile && activeCustomerFile[idSparePare],
      updateStatusSparePartInCustomerFile
    );
  };

  const setActiveCustomerFile = (file_number) => {
    history.push({
      pathname: `/${resource}/${file_number}`,
      search: location.search,
    });
    resetCurrentEditionSession();
  };

  const getDefaultFilter = () => {
    let defaultFilter = {
      category: HOME_REPAIR,
    };
    return defaultFilter;
  };
  return (
    <div className={classes.wrapperCustomerFilesList}>
      <StyledList
        hasShow={false}
        hasList={true}
        hasEdit={false}
        hasCreate={false}
        basePath="/customer-files"
        resource="customer-files-slim"
        className={classes.customerFilesListStyle}
        pagination={<CustomerFilesListPagination />}
        exporter={false}
        filter={getDefaultFilter()}
        filters={
          <CustomerFilesFilter
            customerFilesStates={customerFilesStates}
            employees={employees}
            customerFilesTags={customerFilesTags}
            idEmployee={idEmployee}
            connectedEmployee={connectedEmployee}
          />
        }
        actions={CustomActions && React.cloneElement(CustomActions, { classes: classes })}
        perPage={20}
      >
        <CustomersGrid
          customerFilesTags={customerFilesTags}
          handleItemClick={setActiveCustomerFile}
          updateCustomerFileDetail={updateCustomerFileDetail}
          isReportCheckingInterface={isReportCheckingInterface}
        />
      </StyledList>
      <Switch>
        <Route
          path={`/${resource}/:fileNumber`}
          render={(props) => (
            <UserContext.Provider value={connectedEmployee}>
              <CustomerFileStatesProvider>
                <CustomerFileDetail
                  {...props}
                  customerFile={activeCustomerFile}
                  fileNumber={props.match.params.fileNumber}
                  idEmployee={idEmployee}
                  customerFilesStates={customerFilesStates}
                  customerFilesTags={customerFilesTags}
                  createIncentiveSystem={createIncentiveSystem}
                  getEmployees={getEmployees}
                  employees={employees}
                  updateTicketDetail={updateTicketDetail}
                  changeCustomerFileStatusSparePart={handleChangeStatusSparePart}
                  deleteCustomerFilesTag={deleteCustomerFilesTag}
                  postCustomerFilesTag={postCustomerFilesTag}
                  isTagSuccessfullySubmitted={isTagSuccessfullySubmitted}
                  resource={resource}
                  defaultTab={isReportCheckingInterface ? "reports" : "infos"}
                />
              </CustomerFileStatesProvider>
            </UserContext.Provider>
          )}
        />
      </Switch>
    </div>
  );
}

const customerFilesListStyles = {
  customerFilesListStyle: {
    backgroundColor: "#FFFFFF",
    marginRight: 10,
    overflow: "auto",
    width: 330,
  },
  wrapperCustomerFilesList: {
    display: "flex",
    height: "100%",
  },
  toolbar: {
    backgroundColor: "white",
    display: "block",
    padding: 0,
    marginTop: 8,
  },
};

const customerFilesStyledList = {
  root: {
    boxShadow: "none",
    borderRadius: 0,
  },
  content: {
    width: "100%",
  },
};

const StyledList = withStyles(customerFilesStyledList)(List);
const useStyles = makeStyles(customerFilesListStyles);
