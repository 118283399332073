import { CustomerFileState } from "types/customer-file-states";

import { normalizeCase } from "utils/caseUtils";

const getState = (customerFileStates: CustomerFileState[], label: string) =>
  customerFileStates
    .map((state) => {
      return { id: state.id, name: state.name, normalized_name: normalizeCase(state.name.toLowerCase()) };
    })
    .find((state) => state.normalized_name === normalizeCase(label.toLowerCase()));

export function getCustomerFileStateFromLabel(customerFileStates: CustomerFileState[], label: string) {
  if (!label || !customerFileStates) return;
  return getState(customerFileStates, label);
}
