import React, { Component } from "react";

import PropTypes from "prop-types";

import "./style.css";

export default class AddElementButton extends Component {
  static propTypes = {
    buttonText: PropTypes.string.isRequired,
    disabledButtonText: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
    isButtonClickable: PropTypes.bool.isRequired,
  };

  render() {
    return this.props.isButtonClickable ? (
      <div className="add-element-button-container" onClick={this.props.handleClick}>
        <span>{this.props.buttonText}</span>
      </div>
    ) : (
      <div className="add-element-button-container">
        <span className="add-element-button-disabled">{this.props.disabledButtonText}</span>
      </div>
    );
  }
}
