import React from "react";

import { DateField } from "react-admin";

import { Box, Typography } from "@material-ui/core";

import { VISIT_STATUSES, VISIT_TYPES } from "pages/customerFiles/components/CustomerFileVisitsNew/constants";

export default function VisitSnapshotField({ record }) {
  return (
    <Box alignItems="center" justifyContent="space-between" display="inline-flex" width="100%">
      <DateField
        locales="fr-FR"
        variant="subtitle1"
        options={{ weekday: "long", year: "numeric", month: "short", day: "numeric" }}
        record={record}
        source="timeslot.beginning"
      />

      <Typography variant="caption">
        P{record.visit_number} {record.time_needed && "Long"}
      </Typography>

      <Typography variant="caption">
        {record.visit_type && VISIT_TYPES.find((type) => type.value === record.visit_type).label}
      </Typography>

      <Typography variant="caption">
        {record.visit_status && VISIT_STATUSES.find((status) => status.value === record.visit_status).label}
      </Typography>
    </Box>
  );
}
