import React from "react";

import PropTypes from "prop-types";

import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";

import sortBy from "lodash/sortBy";
import Moment from "moment";
import moment from "moment-timezone";

import { getEmployeeFullName, repairmenSortedList } from "utils/repairmenSortedList";

moment.tz.setDefault("fr");

const techStyle = {
  root: {
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    background: "#FFFFFF",
    borderBottom: "1px solid #DBDFE8",
    paddingBottom: "16px",
    paddingTop: "16px",
  },
  selectTech: {
    display: "block",
    marginTop: "0px",
    marginBottom: "0px",
    alignItems: "center",
    "line-height": "1.5",
    "background-color": "#F4F3F8",
    "background-image": "none",
    "background-clip": "padding-box",
    border: "none",
    "border-radius": ".25rem",
    height: "31px",
    transition: "border-color ease-in-out .15s,box-shadow ease-in-out .15s",
    "&:hover": {
      cursor: "pointer",
    },
  },
  datePicker: {
    marginTop: "0px",
    marginBottom: "0px",
    "background-color": "#F4F3F8",
    "&:hover": {
      cursor: "pointer",
    },
    "font-style": "normal",
    "font-weight": "normal",
    "line-height": "140%",
  },
  selectTechInModal: {
    "padding-left": "10px",
    "background-color": "#F4F3F8",
    border: "none",
    height: "31px",
    "&:hover": {
      cursor: "pointer",
    },
    "font-family": "Poppins",
    "font-size": "15px",
    color: "#2B3956",
    "font-style": "normal",
    "font-weight": "normal",
    "line-height": "140%",
  },
  text: {
    "font-family": "Poppins",
    "font-style": "normal",
    "font-weight": "normal",
    "font-size": "16px",
    "line-height": "140%",
    color: "#8795B3",
    height: "31px",
  },
  textVisit: {
    "font-family": "Poppins",
    "font-style": "normal",
    "line-height": "140%",
    color: "#2B3956",
    "text-transform": "capitalize",
    "font-weight": 600,
    "font-size": "16px",
    height: "31px",
  },
};

const tableTimeslots = [
  { value: 6, text: "6h" },
  { value: 7, text: "7h" },
  { value: 8, text: "8h" },
  { value: 9, text: "9h" },
  { value: 10, text: "10h" },
  { value: 11, text: "11h" },
  { value: 12, text: "12h" },
  { value: 13, text: "13h" },
  { value: 14, text: "14h" },
  { value: 15, text: "15h" },
  { value: 16, text: "16h" },
  { value: 17, text: "17h" },
  { value: 18, text: "18h" },
  { value: 19, text: "19h" },
  { value: 20, text: "20h" },
  { value: 21, text: "21h" },
];

const visitTimeNeededArray = [
  { value: false, text: "Standard" },
  { value: true, text: "Long" },
];

class EmployeeTimeslotVisitInput extends React.Component {
  static propTypes = {
    record: PropTypes.any,
    employees: PropTypes.any,
    editable: PropTypes.bool,
    visitEmployee: PropTypes.any,
    handleSaveEmployee: PropTypes.func,
    visitBeginningUTCISOString: PropTypes.any,
    handleSaveTimeNeeded: PropTypes.func,
    handleSaveDateHeureVisit: PropTypes.func,
    visitTimeNeeded: PropTypes.bool,
  };

  handleChangeEmployee = (event) => {
    this.props.handleSaveEmployee(event.target.value);
  };

  handleChangeHour = (event) => {
    const visitBeginningLocalizedDatetime = Moment(this.props.visitBeginningUTCISOString).locale("fr");
    const visitBeginningLocalizedDatetimeNewHour = visitBeginningLocalizedDatetime.hours(event.target.value);
    this.props.handleSaveDateHeureVisit(visitBeginningLocalizedDatetimeNewHour.toISOString().split(".")[0] + "Z");
  };

  handleChangeDate = (event) => {
    const value = event.target.value;
    // on reçoit la réponse sous forme de 10 caractères '2', '0', '1', '9', etc..
    const year = value["0"] + value["1"] + value["2"] + value["3"];
    const month = value["5"] + value["6"];
    const day = value["8"] + value["9"];
    const visitBeginningLocalizedDatetime = moment(this.props.visitBeginningUTCISOString).locale("fr");

    visitBeginningLocalizedDatetime.set({
      year: year,
      month: parseInt(month) - 1,
      date: day,
      second: 0,
      minute: 0,
    });
    this.props.handleSaveDateHeureVisit(visitBeginningLocalizedDatetime.utc().format("YYYY-MM-DDTHH:mm:ss") + "Z");
  };

  handleSaveTimeNeeded = (event) => {
    this.props.handleSaveTimeNeeded(event.target.value === "true");
  };

  getEmployeeFullNameFromId = (employeeId) => {
    const employee =
      this.props.employees && this.props.employees.find((employee) => parseInt(employee.id) === employeeId);
    return employee && getEmployeeFullName(employee);
  };

  render() {
    const visitBeginningLocalizedDatetime = Moment(this.props.visitBeginningUTCISOString).locale("fr");
    const localizedVisitHour = visitBeginningLocalizedDatetime.format("HH");
    const visitTimeNeeded =
      visitTimeNeededArray &&
      visitTimeNeededArray.find((visitDuration) => this.props.visitTimeNeeded === visitDuration.value).text;
    const { classes, editable, visitEmployee, visitBeginningUTCISOString } = this.props;
    return (
      <div>
        <div className={classes.root}>
          <span className={classes.text}>Technicien</span>
          {editable ? (
            <span className={classes.selectTech}>
              <select
                id="select-employee"
                className={classes.selectTechInModal}
                value={visitEmployee}
                onChange={this.handleChangeEmployee}
              >
                {this.props.employees &&
                  repairmenSortedList(this.props.employees).map((employeeDisplayed) => {
                    return (
                      <option value={employeeDisplayed.id} key={employeeDisplayed.id}>
                        {this.getEmployeeFullNameFromId(employeeDisplayed.id)}
                      </option>
                    );
                  })}
              </select>
            </span>
          ) : (
            <div className={classes.textVisit}>{this.getEmployeeFullNameFromId(parseInt(visitEmployee))}</div>
          )}
        </div>
        <div className={classes.root}>
          <span className={classes.text}>Date</span>
          {editable ? (
            <span className={classes.datePicker}>
              <TextField
                id="select-date"
                type="date"
                className={classes.datePicker}
                onChange={this.handleChangeDate}
                value={visitBeginningUTCISOString && visitBeginningUTCISOString.substring(0, 10)}
              />
            </span>
          ) : (
            <div className={classes.textVisit}> {Moment(visitBeginningUTCISOString).format("DD/MM/YYYY")}</div>
          )}
        </div>
        <div className={classes.root}>
          <span className={classes.text}>Heure</span>
          {editable ? (
            <span className={classes.selectTech}>
              <select
                id="select-hour"
                className={classes.selectTechInModal}
                value={parseInt(localizedVisitHour)}
                onChange={this.handleChangeHour}
              >
                {sortBy(tableTimeslots, "value").map((timeslot) => {
                  return (
                    <option value={timeslot.value} key={timeslot.value}>
                      {timeslot.text}
                    </option>
                  );
                })}
              </select>
            </span>
          ) : (
            <div className={classes.textVisit}>{localizedVisitHour}h</div>
          )}
        </div>
        <div className={classes.root}>
          <span className={classes.text}>Durée du passage</span>
          {editable ? (
            <span className={classes.selectTech}>
              <select
                id="select-intervention_length"
                className={classes.selectTechInModal}
                value={this.props.visitTimeNeeded}
                onChange={this.handleSaveTimeNeeded}
              >
                {sortBy(visitTimeNeededArray, "value").map((duration) => {
                  return (
                    <option value={duration.value} key={duration.value}>
                      {duration.text}
                    </option>
                  );
                })}
              </select>
            </span>
          ) : (
            <div className={classes.textVisit}>{visitTimeNeeded}</div>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(techStyle)(EmployeeTimeslotVisitInput);
