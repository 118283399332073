import React from "react";

import { Box, IconButton, Typography, useTheme } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import { quoteElementList } from "pages/customerFiles/components/CustomerFileBillingQuotes/services/QuoteHelper.js";
import { getQuoteElementProperty } from "pages/customerFiles/components/CustomerFileBillingQuotes/services/getQuoteElementProperty.js";

import { formatPriceCompact } from "utils/formatting";

const AdditionalPackage = ({ isModifiable = false, removeElementInQuote = null, isDeletable = false, element }) => {
  const theme = useTheme();

  return (
    <Box display="flex" justifyContent="space-between" p={1}>
      <Box width="80%" alignItems="center" my="auto" mx={0} color={theme.palette.secondary.contrastText}>
        <Box display="flex">
          <Box>
            <Typography variant="body1" color="inherit">
              {getQuoteElementProperty(element.element_type, quoteElementList, "name")}
            </Typography>
          </Box>
          <Box color={theme.palette.secondary.grey900}>
            <Typography variant="body1" color="inherit">
              &nbsp;- {element.product}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box display="inline-flex" my="auto">
        <Typography variant="body1">{formatPriceCompact(element.amount_withtax)}</Typography>
        {isDeletable && isModifiable && (
          <Box color={theme.palette.secondary.grey900} ml="3px">
            <IconButton id="delete-icon" color="inherit" onClick={() => removeElementInQuote(element.id)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AdditionalPackage;
