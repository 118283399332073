/* global ga */
import { dimensions, init } from "./base";

/**
 * Trackers are defined in a separate file to make them easier to test.
 * That way, the init function can be spied on.
 *
 * =====
 * Google Analytics trackers
 * =====
 * For both evenTracker and pageviewTracker, if the tracker to be used is not defined
 * then the google analytics initialization is triggered and it would fire the tracker
 * as a callback.
 * In order to prevent infinite callstack, initialization call is blocked by the 'firstCall argument.
 */
export const eventTracker = (
  category,
  action,
  label,
  trackerName,
  value = undefined,
  nonInteraction,
  firstCall = true
) => {
  if (firstCall && !(window.ga && window.ga.getByName && window.ga.getByName(trackerName))) {
    init(trackerName, () => eventTracker(category, action, label, trackerName, value, nonInteraction, false));
  } else {
    const time = new Date().toISOString();
    ga(`${trackerName}.set`, dimensions[trackerName].TIME_STAMP, time);
    ga(`${trackerName}.send`, "event", {
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      eventValue: value,
      nonInteraction: nonInteraction,
    });
  }
};

export const pageviewTracker = (pathname, trackerName, firstCall = true) => {
  if (firstCall && !(window.ga && window.ga.getByName && window.ga.getByName(trackerName))) {
    init(trackerName, () => pageviewTracker(pathname, trackerName, false));
  } else {
    const time = new Date().toISOString();
    ga(`${trackerName}.set`, dimensions[trackerName].TIME_STAMP, time);
    ga(`${trackerName}.set`, "page", pathname);
    ga(`${trackerName}.send`, "pageview");
  }
};

/**
 * ===
 * Facebook Pixel tracker
 * ===
 * The tracker is only active in production and on murfy.fr
 * cf https://developers.facebook.com/docs/facebook-pixel/api-reference
 * @param {*} eventName a string, should be one of facebook 9 standard events
 * @param {*} customData an object, the parameters expected by facebook for the given event
 */
export const facebookTracker = (eventName, customData) => {
  if (process.env.REACT_APP_GA_PLATFORM_ENVIRONMENT === "MASTER" && window.location.hostname === "murfy.fr") {
    window.fbq && window.fbq("track", eventName, customData);
  }
};
