import React, { useState } from "react";

import { NumberInput, TextInput, required } from "react-admin";

import { Box, Typography } from "@material-ui/core";
import { IconButton, Input } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Create from "@material-ui/icons/Create";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";

import Modal from "components/Modal";
import SkuChoice from "components/SkuChoice";

import { SKU_TYPE_NEW } from "constants/sku";

import {
  getSparePartBasePriceFromSparePartCost,
  getSparePartPriceWithTax,
} from "pages/customerFiles/components/CustomerFileBillingQuotes/services/sparePartPriceCalculator.js";

import { formatPrice } from "utils/price.js";

import AddSparePartNextStepButtonGroup from "../AddSparePartNextStepButtonGroup";

const MAX_QUANTITY = 20;

export default ({
  skus,
  selectedSku,
  setSku,
  handleCloseSparePart,
  sparePartsAlreadyInCart,
  quantity,
  sellingPrice,
  isSparePartModalOpen,
  handleValidateSparePart,
  onClickChangeEditabilityOfSparePartPrice,
  setDesignation,
  setForcedSparePartPriceWithTax,
  setBuyingPriceInCents,
  setQuantity,
  forcedSparePartPriceWithTax,
  isSparePartPriceEditable,
  spareAswoRef,
  spareName,
  supplier,
  buyingPriceInCents,
}) => {
  const classes = useStyles();
  const [isEditingCost, setIsEditingCost] = useState(false);

  const getSellingPrice = () => {
    const basePrice = sellingPrice
      ? sellingPrice
      : getSparePartBasePriceFromSparePartCost(parseFloat(buyingPriceInCents / 100));
    return getSparePartPriceWithTax(basePrice, quantity, sparePartsAlreadyInCart) * quantity;
  };

  return (
    <Modal
      id="commercial-gesture-modal"
      modalClassName={`modal-small-content`}
      isOpen={isSparePartModalOpen}
      handleCloseModal={handleCloseSparePart}
    >
      <Box py={4}>
        <Typography align="center" variant="h2">
          Ajouter une pièce au devis
        </Typography>
      </Box>
      <form noValidate autoComplete="off">
        <Box width={500}>
          {skus
            .filter((sku) => sku.type === SKU_TYPE_NEW || sku.stock_available >= quantity)
            .map((sku) => (
              <SkuChoice
                sku={sku}
                quantity={quantity}
                isSelected={sku.id === selectedSku.id}
                onClick={() => setSku(sku)}
              />
            ))}
          <Box margin="auto" display="grid" pt={4}>
            <TextInput
              variant="outlined"
              margin="none"
              name="spareName"
              onChange={(event) => setDesignation(event.target.value)}
              disableUnderline
              label="Nom de la pièce"
              fullWidth
              value={spareName}
            />
          </Box>
          <Box py={1}>
            <NumberInput
              label="Quantité"
              variant="outlined"
              // Min and max props does not seem to work
              min={1}
              max={MAX_QUANTITY}
              fullWidth
              onChange={(event) => {
                const quantity = event.target.value;
                // Manual range validation
                setQuantity(Math.min(MAX_QUANTITY, Math.max(1, quantity)));
              }}
              value={quantity}
              name="Quantity"
            />
          </Box>
          {!sellingPrice &&
            (!isEditingCost ? (
              <Box margin="auto" display="flex" justifyContent="space-between" borderBottom="2px solid #F4F3F8" py={2}>
                <Typography variant="body1" className={classes.nameField}>
                  Coût de la pièce
                </Typography>
                <Box display="flex">
                  <Typography variant="body1" className={classes.valueField}>
                    {buyingPriceInCents ? formatPrice(buyingPriceInCents) : "Prix d'achat inconnu"}
                  </Typography>
                  <IconButton
                    id="edit-cost"
                    className={classes.editSparePartPriceIcon}
                    onClick={() => setIsEditingCost(true)}
                  >
                    <Create />
                  </IconButton>
                </Box>
              </Box>
            ) : (
              <Box margin="auto" display="flex" justifyContent="space-between" borderBottom="2px solid #F4F3F8" py={2}>
                <NumberInput
                  label="Coût de la pièce"
                  variant="outlined"
                  margin="none"
                  name="buyingPriceInCents"
                  onChange={(event) => setBuyingPriceInCents(parseInt(event.target.value) * 100)}
                  disableUnderline
                  validate={required()}
                  fullWidth
                  value={parseInt(buyingPriceInCents / 100)}
                />
              </Box>
            ))}
          <Box display="flex" justifyContent="space-between" py={2} borderBottom="2px solid #F4F3F8">
            <Typography variant="body1" className={classes.nameField}>
              À encaisser
            </Typography>
            <Box>
              {isSparePartPriceEditable ? (
                <Box width={100} border="1px solid #DBDFE8" borderRadius={8} py={1} px={2}>
                  <Input
                    id="spare-part-input-override"
                    value={forcedSparePartPriceWithTax}
                    onChange={(event) => setForcedSparePartPriceWithTax(event.target.value)}
                    disableUnderline
                    endAdornment={<EuroSymbolIcon />}
                  />
                </Box>
              ) : (
                <Typography
                  id="spare-part-price-computed-from-quantity"
                  variant="body1"
                  className={classes.toBeCashedvalueField}
                >
                  {getSellingPrice()}
                  €&nbsp;
                  <IconButton
                    id="trigger-edit-spare-part-price"
                    className={classes.editSparePartPriceIcon}
                    onClick={onClickChangeEditabilityOfSparePartPrice}
                  >
                    <Create />
                  </IconButton>
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </form>
      <AddSparePartNextStepButtonGroup handleValidate={handleValidateSparePart} handleClose={handleCloseSparePart} />
    </Modal>
  );
};

const useStyles = makeStyles({
  nameField: {
    color: "#8795B3",
    display: "table-cell",
    alignSelf: "center",
  },
  valueField: {
    color: "#2B3B5C",
  },
  toBeCashedvalueField: {
    color: "#2B3B5C",
    marginLeft: 198,
  },
  editSparePartPriceIcon: {
    width: 35,
    height: 35,
  },
  customSelect: {
    margin: 0,
    minWidth: 56,
    "& p": {
      display: "none",
    },
    ".filled": {
      paddingTop: 12,
    },
  },
});
