import React from "react";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles({
  root: {
    alignItems: "center",
    background: "#FFFFFF",
    paddingBottom: "10px",
    paddingTop: "10px",
  },
  visitCommentButton: {
    backgroundColor: "#FCD660",
    border: "none",
    borderRadius: "4px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    textAlign: "center",
    textTransform: "uppercase",
    width: "fill-content",
    display: "inline-block",
    borderBottom: "1px solid #c9d3e2",
    padding: "16px",
    color: "#102d69",
    lineHeight: "150%",
    cursor: "pointer",
  },
  cancelButton: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "150%",
    textAlign: "center",
    textTransform: "uppercase",
    color: "#8795B3",
    padding: "15px",
    cursor: "pointer",
  },
  edition: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
});

export function ButtonVisit({ editable, toggleEditSaveMode, handleSubmit, status, handleCancel }) {
  const classes = useStyle();

  return (
    <div className={classes.root}>
      <div className={classes.edition}>
        {editable ? (
          <div>
            <span className={classes.cancelButton} onClick={handleCancel}>
              <span>Annuler</span>{" "}
            </span>
            <span className={classes.visitCommentButton} onClick={handleSubmit}>
              {" "}
              <span>Valider les Changements</span>
            </span>
          </div>
        ) : status === "tbm" || status === "canceled" ? (
          ""
        ) : (
          <span className={classes.visitCommentButton} onClick={toggleEditSaveMode}>
            {" "}
            <span>Modifier le passage</span>
          </span>
        )}
      </div>
    </div>
  );
}

ButtonVisit.propTypes = {
  handleChange: PropTypes.func,
  editable: PropTypes.bool,
  handleSubmit: PropTypes.func,
};

export default ButtonVisit;
