import React, { Component } from "react";

import { TextInput } from "react-admin";

import PropTypes from "prop-types";

/* global google */

export default class GooglePlacesController extends Component {
  static propTypes = {
    autocompleteElementId: PropTypes.string,
    onPlaceChange: PropTypes.func.isRequired,
    inputProps: PropTypes.object,
  };

  static defaultProps = {
    autocompleteElementId: "input_autocomplete_input_google_places",
  };

  componentDidMount() {
    if (!window.google) {
      window.initGooglePlaces = this.init;
    } else {
      this.init();
    }
  }

  componentWillUnmount() {
    if (this.googleMapsListener) {
      google.maps.event.removeListener(this.googleMapsListener);
    }
  }

  /**
   * Initialize the autocomplete of the adress field and the state
   */
  init = () => {
    this.initializeAutocomplete(this.props.autocompleteElementId);
  };

  initializeAutocomplete = (id) => {
    const element = document.getElementById(id);
    if (element) {
      var autocomplete = new google.maps.places.Autocomplete(element, {
        types: ["address"],
        componentRestrictions: { country: "fr" },
      });
      this.googleMapsListener = google.maps.event.addListener(
        autocomplete,
        "place_changed",
        this.handlePlaceChange(autocomplete)
      );
    }
  };

  handlePlaceChange = (autocomplete) => () => {
    const place = autocomplete.getPlace();
    const address = place.address_components;
    const formattedAddress = place.formatted_address;
    this.props.onPlaceChange(address, formattedAddress);
  };

  render() {
    return <TextInput variant="filled" id={this.props.autocompleteElementId} {...this.props.inputProps} />;
  }
}
