import React, { Component } from "react";

import PropTypes from "prop-types";

import Button from "admin/components/Button";
import { Image, Transformation } from "cloudinary-react";
import get from "lodash/get";
import sortBy from "lodash/sortBy";

import Modal from "components/Modal";

import "./style.css";

export default class ImagesManagement extends Component {
  static propTypes = {
    activeFolder: PropTypes.shape({
      id: PropTypes.number.isRequired,
      parent_folder: PropTypes.number,
      title: PropTypes.string.isRequired,
    }),
    deleteImage: PropTypes.func.isRequired,
    getImagesFolder: PropTypes.func.isRequired,
    getImagesFoldersList: PropTypes.func.isRequired,
    getImages: PropTypes.func.isRequired,
    imagesFolders: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        parent_folder: PropTypes.number,
        title: PropTypes.string.isRequired,
      })
    ),
    onImageClick: PropTypes.func.isRequired,
    postImage: PropTypes.func.isRequired,
    postImagesFolder: PropTypes.func.isRequired,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.isRequired,
        public_id: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
        folder: PropTypes.number.isRequired,
      })
    ),
    setActiveFolder: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isNewFolderModalOpen: false,
      isImageUploadModalOpen: false,
      folderTitle: "",
      imageAlt: "",
      imageFile: null,
      imagePreview: null,
      imageTitle: "",
    };
  }

  componentDidMount() {
    this.props.getImagesFoldersList();
    this.props.getImages();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeFolder !== this.props.activeFolder) {
      this.props.getImagesFoldersList();
      this.props.getImages();
    }
  }

  setActiveFolder = (folderId) => () => this.props.setActiveFolder(folderId);

  backToParentFolder = () =>
    this.props.activeFolder.parent_folder
      ? this.props.getImagesFolder(this.props.activeFolder.parent_folder)
      : this.props.setActiveFolder(null);

  toggleNewFolderModalOpen = () =>
    this.setState((prevState) => ({ isNewFolderModalOpen: !prevState.isNewFolderModalOpen }));

  toggleImageUploadModalOpen = () =>
    this.setState((prevState) => ({ isImageUploadModalOpen: !prevState.isImageUploadModalOpen }));

  handleFolderTitleChange = (event) => {
    this.setState({ folderTitle: event.target.value });
  };

  handleImageTitleChange = (event) => {
    this.setState({ imageTitle: event.target.value });
  };

  handleImageAltChange = (event) => {
    this.setState({ imageAlt: event.target.value });
  };

  handleImageFileChange = (event) => {
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (e) => {
      this.setState({
        imageFile: file,
        imagePreview: reader.result,
      });
    };
  };

  saveImagesFolder = () => {
    this.props.postImagesFolder(this.state.folderTitle);
    this.setState({ folderTitle: "" });
    this.toggleNewFolderModalOpen();
  };

  saveImage = () => {
    this.props.postImage(this.state.imageTitle, this.state.imageFile, this.state.imageAlt);
    this.setState({
      imageAlt: "",
      imageFile: null,
      imagePreview: null,
      imageTitle: "",
    });
    this.toggleImageUploadModalOpen();
  };

  deleteImage = (imageId) => {
    this.props.deleteImage(imageId);
  };

  render() {
    const { activeFolder, imagesFolders, images } = this.props;
    return (
      <div className="cms-page-content-container">
        <div className="cms-page-content-list-container full-height">
          <div className="pc-group-container full-height">
            <div className="pc-group-title">Gestion des images</div>
            <div className="images-management-main-container">
              <div className="images-management-folder-tree-container">
                <div className="images-management-current-folder-container">
                  <i className="ico fas fa-folder-open" />
                  {activeFolder && (
                    <span className="images-management-back-button" onClick={this.backToParentFolder}>
                      ... /
                    </span>
                  )}
                  <span className="images-management-folder-title">{activeFolder ? activeFolder.title : "/"}</span>
                </div>
                <div className="images-management-all-subfolders-container">
                  {sortBy(imagesFolders, "title").map((imageFolder) => (
                    <div
                      key={`images-management-subfolder-${imageFolder.id}`}
                      id={`images-management-subfolder-${imageFolder.id}`}
                      className="images-management-subfolder-container"
                    >
                      <i className="ico fas fa-folder" />
                      <span className="images-management-folder-title" onClick={this.setActiveFolder(imageFolder.id)}>
                        {imageFolder.title}
                      </span>
                    </div>
                  ))}
                  <div
                    className="images-management-add-button"
                    id="new-image-folder-button"
                    onClick={this.toggleNewFolderModalOpen}
                  >
                    + Ajouter un nouveau dossier
                  </div>
                </div>
              </div>
              <div className="images-management-images-container">
                <div className="row images-management-images-subcontainer">
                  {images.map((image) => (
                    <div className="col-3 images-management-image-preview-container" key={image.id}>
                      <div
                        className="images-management-image-preview-image-container"
                        onClick={this.props.onImageClick && this.props.onImageClick(image.id)}
                      >
                        <Image className="cloudinary-image" cloudName="cobble" publicId={image.public_id}>
                          <Transformation crop="fit" height="120" width="120" />
                        </Image>
                      </div>
                      <div>
                        {image.title}
                        <i
                          className="images-management-image-delete far fa-trash-alt"
                          onClick={() => this.deleteImage(image.id)}
                        />
                      </div>
                    </div>
                  ))}
                  {this.props.activeFolder && (
                    <div
                      className="images-management-add-button"
                      id="upload-image-button"
                      onClick={this.toggleImageUploadModalOpen}
                    >
                      + Ajouter une nouvelle image
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          id="new-images-folder-creation-modal"
          key="new-images-folder-creation-modal"
          isOpen={this.state.isNewFolderModalOpen}
          handleCloseModal={this.toggleNewFolderModalOpen}
          modalClassName="modal-small-content"
        >
          <div className="add-target-modal-container">
            <span className="title">Créer un nouveau dossier</span>
            <div className="images-management-input-field">
              <span>Nom du dossier</span>
              <input className="input-field" value={this.state.folderTitle} onChange={this.handleFolderTitleChange} />
            </div>
            <div className="button">
              <Button clickHandler={this.saveImagesFolder} text="Créer" />
            </div>
          </div>
        </Modal>
        <Modal
          id="new-image-upload-modal"
          key="new-image-upload-modal"
          isOpen={this.state.isImageUploadModalOpen}
          handleCloseModal={this.toggleImageUploadModalOpen}
          modalClassName="modal-small-content"
        >
          <div className="add-target-modal-container">
            <span className="title">Uploader une nouvelle image</span>
            <div className="images-management-input-field">
              <span>Nom de l'image (obligatoire)</span>
              <input
                className="input-field"
                id="new-image-title-input"
                value={this.state.imageTitle || `${get(activeFolder, "title")}-${images.length + 1}`}
                onChange={this.handleImageTitleChange}
              />
            </div>
            <div className="images-management-input-field">
              <span>Alt de l'image</span>
              <input
                className="input-field"
                id="new-image-alt-input"
                alt="preview"
                value={this.state.imageAlt}
                onChange={this.handleImageAltChange}
              />
            </div>
            {this.state.imagePreview && [
              <span key="preview-title">Prévisualisation</span>,
              <img
                key="preview-image"
                alt="preview"
                className="images-management-upload-image-preview"
                src={this.state.imagePreview}
              />,
            ]}
            <div className="images-management-input-field">
              <input
                className="file-upload-input"
                id="new-image-file-input"
                type="file"
                onChange={this.handleImageFileChange}
              />
            </div>
            <div className="button">
              <Button clickHandler={this.saveImage} text="Créer" />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
