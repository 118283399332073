import { useEffect, useState } from "react";

import { getHeaders } from "../headers";

type ApiResponse = {
  data: { is_covered: boolean } | null;
  status: number;
};
export const checkHomeRepairCovered = async (postalCode: string): Promise<ApiResponse> => {
  const url = `${process.env.REACT_APP_API_URL}/route-management/zone/covered/?postal_code=${postalCode}`;
  const token = localStorage.getItem("token");

  const headers = getHeaders(token);
  const res = await fetch(url, { headers });
  try {
    const data = await res.json();
    return { data: data, status: res.status };
  } catch {
    return { data: null, status: res.status };
  }
};

export const useHomeRepairCovered = (postalCode: string) => {
  const [isCovered, setIsCovered] = useState<boolean | null>(null);

  useEffect(() => {
    const checkCovered = async () => {
      const { data, status } = await checkHomeRepairCovered(postalCode);
      if (status === 200 && !!data) {
        setIsCovered(data.is_covered);
      }
    };
    checkCovered();
  }, [postalCode]);

  return isCovered;
};
