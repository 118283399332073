import React from "react";

import { AutocompleteInput, TextInput } from "react-admin";

import { Box } from "@material-ui/core";

const paymentTypes = [
  { id: "credit_card", name: "Carte de crédit" },
  { id: "check", name: "Chèque" },
  { id: "cash", name: "Espèces" },
  { id: "prepayed_online", name: "Prepayé en ligne" },
];

const AdminInputs = (props) => {
  return (
    <>
      <Box display="flex" width="100%">
        <Box width="320px">
          <AutocompleteInput
            label="Moyen de paiement"
            source="payment_method"
            fullWidth
            choices={paymentTypes}
            optionValue="id"
            optionText="name"
          />
        </Box>
      </Box>
      <Box display="flex" width="100%">
        <Box width="658px">
          <TextInput
            multiline
            source="admin_comments"
            label="Commentaires admin"
            InputProps={{ disableUnderline: true }}
            fullWidth
          />
        </Box>
      </Box>
    </>
  );
};

export default AdminInputs;
