/**
 * Following the duck pattern, actions, constants and reducers are in the same file called module.js
 *
 * See: https://github.com/erikras/ducks-modular-redux
 *
 */
import { push } from "connected-react-router";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import get from "lodash/get";
import sortBy from "lodash/sortBy";
import { sendUnauthenticatedGetRequestToAPI } from "modules/generators/requestsToAPI";
import { getMurfyFormRequest } from "murfy/pages/MurfyBookingForm/module";
import queryString from "query-string";
import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { getArticleRequest } from "shared/pages/Article/module";
import { getQueryStringsSelector } from "shared/pages/Company/module";

import { getAPIUrl, request } from "utils/networking";

export const GET_DIAGNOSTIC_REQUEST = "Diagnostic/GET_DIAGNOSTIC_REQUEST";
export const GET_DIAGNOSTIC_SUCCESS = "Diagnostic/GET_DIAGNOSTIC_SUCCESS";
export const GET_DIAGNOSTIC_ERROR = "Diagnostic/GET_DIAGNOSTIC_ERROR";

export const GET_STEP_REQUEST = "Diagnostic/GET_STEP_REQUEST";
export const GET_STEP_SUCCESS = "Diagnostic/GET_STEP_SUCCESS";
export const GET_STEP_ERROR = "Diagnostic/GET_STEP_ERROR";

export const GET_SYMPTOMS_FAULTS_CHECKING_PROCESSES_FROM_SYMPTOM_REQUEST =
  "CMS/GET_SYMPTOMS_FAULTS_CHECKING_PROCESSES_FROM_SYMPTOM_REQUEST";
export const GET_SYMPTOMS_FAULTS_CHECKING_PROCESSES_FROM_SYMPTOM_SUCCESS =
  "CMS/GET_SYMPTOMS_FAULTS_CHECKING_PROCESSES_FROM_SYMPTOM_SUCCESS";
export const GET_SYMPTOMS_FAULTS_CHECKING_PROCESSES_FROM_SYMPTOM_ERROR =
  "CMS/GET_SYMPTOMS_FAULTS_CHECKING_PROCESSES_FROM_SYMPTOM_ERROR";

export const RESET_OUTDATED_STEP_ANSWERS = "Diagnostic/RESET_OUTDATED_STEP_ANSWERS";

export const SET_ACTIVE_STEP = "Diagnostic/SET_ACTIVE_STEP";
export const SET_STEP_ANSWER = "Diagnostic/SET_STEP_ANSWER";
export const SET_CURRENT_SYMPTOM = "Diagnostic/SET_CURRENT_SYMPTOM";
export const SET_PRIMARY_SYMPTOM = "Diagnostic/SET_PRIMARY_SYMPTOM";

export const UPDATE_STEP_ANSWER = "Diagnostic/UPDATE_STEP_ANSWER";

export const DECREMENT_STEPS_COUNTER = "Diagnostic/DECREMENT_STEPS_COUNTER";
export const INCREMENT_STEPS_COUNTER = "Diagnostic/INCREMENT_STEPS_COUNTER";

export const UPDATE_STEPS_COUNTER = "Diagnostic/UPDATE_STEPS_COUNTER";

export const PUSH_STEP_ID_TO_URL = "Diagnostic/PUSH_STEP_ID_TO_URL";

export function getDiagnosticRequest(id) {
  return {
    type: GET_DIAGNOSTIC_REQUEST,
    payload: id,
  };
}

export function getDiagnosticSuccess(diagnostic) {
  return {
    type: GET_DIAGNOSTIC_SUCCESS,
    payload: diagnostic,
  };
}

export function getDiagnosticError(error) {
  return {
    type: GET_DIAGNOSTIC_ERROR,
    payload: error,
  };
}

export function getStepRequest(targetId, targetType = "step", stepRequestArgs) {
  return {
    type: GET_STEP_REQUEST,
    payload: { targetId, targetType, stepRequestArgs },
  };
}

export function getStepSuccess(step) {
  return {
    type: GET_STEP_SUCCESS,
    payload: step,
    meta: step
      ? {
          analytics: {
            type: "SEND_EVENT",
            payload: {
              action: "ACCESS_STEP",
              category: `DIAGNOSTIC_${step.diagnostic}_STEP_${step.id}`,
              label: step.title,
              trackerName: "user",
              nonInteraction: true,
            },
          },
        }
      : undefined,
  };
}

export function getStepError(error) {
  return {
    type: GET_STEP_ERROR,
    payload: error,
  };
}

export function getSymptomsFaultsCheckingProcessesFromSymptomRequest(symptomId) {
  return {
    payload: symptomId,
    type: GET_SYMPTOMS_FAULTS_CHECKING_PROCESSES_FROM_SYMPTOM_REQUEST,
  };
}

export function getSymptomsFaultsCheckingProcessesFromSymptomSuccess(symptomsCheckingProcesses) {
  return {
    type: GET_SYMPTOMS_FAULTS_CHECKING_PROCESSES_FROM_SYMPTOM_SUCCESS,
    payload: symptomsCheckingProcesses,
  };
}

export function getSymptomsFaultsCheckingProcessesFromSymptomError(error) {
  return {
    type: GET_SYMPTOMS_FAULTS_CHECKING_PROCESSES_FROM_SYMPTOM_ERROR,
    payload: error,
  };
}

export function setActiveStepAction(stepId, prevStepId) {
  return {
    type: SET_ACTIVE_STEP,
    payload: {
      stepId,
      prevStepId,
    },
  };
}

export function setCurrentSymptom(symptomId) {
  return {
    type: SET_CURRENT_SYMPTOM,
    payload: symptomId,
  };
}

export function setPrimarySymptom(symptomId) {
  return {
    type: SET_PRIMARY_SYMPTOM,
    payload: symptomId,
  };
}

export function setStepAnswer(stepOrder, answer, currentStepId, diagnosticId, answerHeader) {
  return {
    type: SET_STEP_ANSWER,
    payload: {
      stepOrder,
      answer,
      currentStepId,
      answerHeader,
    },
    meta: {
      analytics: {
        type: "SEND_EVENT",
        payload: {
          action: "SELECT_STEP_ANSWER",
          category: `DIAGNOSTIC_${diagnosticId}_STEP_${currentStepId}`,
          label: answer,
          trackerName: "user",
        },
      },
    },
  };
}

export function updateStepAnswerAction(
  stepOrder,
  answer,
  targetId,
  currentStepId,
  diagnosticId,
  targetType,
  onEmbeddedDiagnosticExit
) {
  return {
    type: UPDATE_STEP_ANSWER,
    payload: {
      answer,
      targetId,
      stepOrder,
      currentStepId,
      diagnosticId,
      targetType,
      onEmbeddedDiagnosticExit,
    },
  };
}

export function resetOutdatedStepAnswers(stepId) {
  return {
    type: RESET_OUTDATED_STEP_ANSWERS,
    payload: stepId,
  };
}

export function decrementStepsCounterAction() {
  return {
    type: DECREMENT_STEPS_COUNTER,
  };
}

export function incrementStepsCounterAction() {
  return {
    type: INCREMENT_STEPS_COUNTER,
  };
}

export function updateStepsCounterAction(stepsCounter) {
  return {
    type: UPDATE_STEPS_COUNTER,
    payload: stepsCounter,
  };
}

export function pushStepIdToURLAction(stepId) {
  return {
    type: PUSH_STEP_ID_TO_URL,
    payload: stepId,
  };
}

export const getCurrentSymptomSelector = (state) => state.diagnostic.currentSymptom;

export const getPrimarySymptomSelector = (state) => state.diagnostic.primarySymptom;

export const getStepSelector = (state, stepId) =>
  find(state.diagnostic.steps, (stepIterator) => stepIterator.step.id === stepId);

export const getActiveStepSelector = (state) =>
  find(state.diagnostic.steps, (stepIterator) => stepIterator.step.id === state.diagnostic.activeStep);

export const isActiveStepSelector = (state, stepId) => state.diagnostic.activeStep === stepId;

export const getActiveStepIdSelector = (state) => state.diagnostic.activeStep;

export const getActiveStepIndexSelector = (state) =>
  state.diagnostic.steps.findIndex((stepIterator) => stepIterator.step.id === state.diagnostic.activeStep);

export const getStepIndexSelector = (state, stepId) =>
  state.diagnostic.steps.findIndex((stepIterator) => stepIterator.step.id === stepId);

export const getStepByIndexSelector = (state, index) =>
  find(state.diagnostic.steps, (stepIterator, stepIndex) => stepIndex === index);

export const isStepStoredSelector = (state, targetId, targetType) => {
  return !!find(state.diagnostic.steps, (step) => step.step.id === targetId) && targetType !== "symptom";
};

/**
 * Before returning the symptomCheckingProcesses, it should order them :
 *  - first we sort by order the symptomsCheckingProcesses which belong to the primary symptom
 *  - for the symptomsCheckingProcesses to the secondary symptoms, we have to group them by syptom
 *  before sorting them.
 * So we build two sorted list and then we concatenate them.
 */
export const getSymptomTechnicalFaultsCheckingProcessesSelector = (state) => {
  const primarySymptomId = getPrimarySymptomSelector(state);

  const currentSymptomTechnicalFaultsCheckingProcessesOrdered = sortBy(
    state.diagnostic.symptomsFaultsCheckingProcesses.filter(
      (symptomFaultCheckingProcess) =>
        symptomFaultCheckingProcess.checking_process.process_type === "technical_fault" &&
        symptomFaultCheckingProcess.symptom === primarySymptomId
    ),
    "order"
  );

  const otherSymptomsTechnicalFaultsCheckingProcessesOrdered = sortBy(
    sortBy(
      state.diagnostic.symptomsFaultsCheckingProcesses.filter(
        (symptomFaultCheckingProcess) =>
          symptomFaultCheckingProcess.checking_process.process_type === "technical_fault" &&
          symptomFaultCheckingProcess.symptom !== primarySymptomId
      ),
      "order"
    ),
    "symptom"
  );

  return [
    ...currentSymptomTechnicalFaultsCheckingProcessesOrdered,
    ...otherSymptomsTechnicalFaultsCheckingProcessesOrdered,
  ];
};

export const getStepsCounterSelector = (state) => state.diagnostic.stepsCounter;

export const getUserDiagnosticSelector = (state) => state.diagnostic.userDiagnostic;

export function* getDiagnostic(action) {
  const config = {
    method: "get",
    url: `${getAPIUrl()}/widget/diagnostic/${action.payload}/`,
  };
  try {
    const diagnostic = yield call(request, config);
    yield put(getDiagnosticSuccess(diagnostic));
    yield put(getStepRequest(diagnostic.step.id));
  } catch (error) {
    yield put(getDiagnosticError(error));
  }
}

/**
 * Get a step based on the information provided by a step transition.
 * @param {Object} action The action that triggers the retrieval of the step,
 * its stepRequestArgs may carry values that are specific to the targetType.
 * A step transition can be of different types: towards a step, a symptom or a fault,
 * thus the url to retrieve the next step changes.
 * When a step is successfully retrieved, we push its id as a query string.
 * Special case: when the target is a symptom and no primary symptom is already set then
 * it is set and the matching symptomCheckingProcesses are retrieved.
 */
export function* getStep(action) {
  const { targetType, stepRequestArgs } = action.payload;
  var targetId = action.payload.targetId;

  const primarySymptomId = yield select(getPrimarySymptomSelector);
  const currentSymptomId = yield select(getCurrentSymptomSelector);

  if (targetType === "symptom" && !targetId) {
    targetId = currentSymptomId;
  }

  switch (targetType) {
    case "step":
      var url = `${getAPIUrl()}/widget/diagnostic/step/${targetId}/`;
      break;
    case "symptom":
      url = `${getAPIUrl()}/smart-diagnostic/symptom/${targetId}/?checking-process-id=${
        stepRequestArgs.checkingProcessId
      }`;
      break;
    case "fault":
      url = `${getAPIUrl()}/smart-diagnostic/fault/solution/${targetId}/`;
      break;
    default:
      url = `${getAPIUrl()}/widget/diagnostic/step/${targetId}/`;
  }
  const config = {
    method: "get",
    url: url,
  };
  try {
    const step = yield call(request, config);
    yield put(getStepSuccess(step));

    // Push the targetId into the route
    yield put(pushStepIdToURLAction(step.id));

    // Special case when the target is of type symptom
    if (targetType === "symptom" && targetId) {
      yield put(setCurrentSymptom(targetId));
      if (!primarySymptomId) {
        yield all([
          put(getSymptomsFaultsCheckingProcessesFromSymptomRequest(targetId)),
          put(setPrimarySymptom(targetId)),
        ]);
      }
    }

    // Get the associated widget
    yield call(getStepWidget, step.widget);
  } catch (error) {
    yield put(getStepError(error));
  }
}

export function* getStepWidget(widget) {
  switch (get(widget, "widget_type")) {
    case "article":
      yield put(getArticleRequest(widget.id));
      break;
    case "murfy_form":
      yield put(getMurfyFormRequest(widget.id));
      break;
    default:
      break;
  }
}

export function* getSymptomsFaultsCheckingProcessesFromSymptom(action) {
  const symptomId = action.payload;
  const route = `/smart-diagnostic/symptoms-checking-processes/?symptom_id=${symptomId}`;
  yield call(
    sendUnauthenticatedGetRequestToAPI,
    route,
    getSymptomsFaultsCheckingProcessesFromSymptomSuccess,
    getSymptomsFaultsCheckingProcessesFromSymptomError
  );
}

export function* setActiveStep(action) {
  const { stepId, prevStepId } = action.payload;
  const stepIndex = yield select(getStepIndexSelector, stepId);
  const prevStepIndex = yield select(getStepIndexSelector, prevStepId);

  if (stepIndex && prevStepIndex) {
    stepIndex > prevStepIndex ? yield put(incrementStepsCounterAction()) : yield put(decrementStepsCounterAction());
  }
}

/**
 * @param {Object} action
 * payload fields:
 *   - stepOrder {number} the order of the current step in the current diagnostic process
 *   - answer {string} the step_bar_content of the transition
 *   - targetId {number} the id of the target of the transition (may be null)
 *   - currentStepId {number} the id of the current step
 *   - diagnosticId {number} the id of the diagnostic to which the current belongs
 *   - targetType {string} the type of the transition's target (step, symptom, fault)
 *   - onEmbeddedDiagnosticExit {function} called when there is no more step (targetType === null)
 *
 *    we retrieve answerHeader from previousStep.step_bar_header to build the user's diagnostic history
 */
export function* updateStepAnswer(action) {
  const { stepOrder, answer, targetId, currentStepId, diagnosticId, targetType, onEmbeddedDiagnosticExit } =
    action.payload;
  const previousStep = yield select(getStepSelector, currentStepId);
  const answerHeader = previousStep.step.step_bar_content;
  var isTargetStepStored = yield select(isStepStoredSelector, targetId, targetType);
  let stepRequestArgs = null;

  // Update the answer for the given step
  yield put(setStepAnswer(stepOrder, answer, currentStepId, diagnosticId, answerHeader));

  if (targetType === null && onEmbeddedDiagnosticExit) {
    const userDiagnostic = yield select(getUserDiagnosticSelector);
    yield call(onEmbeddedDiagnosticExit, userDiagnostic);
  } else {
    // If the answer has been changed, remove the next steps from the steps list
    if (get(previousStep, "answer") !== answer) {
      yield put(resetOutdatedStepAnswers(currentStepId));
      // after reset of outdated steps, isTargetStepStored should be false to force getStepRequest, even if it was true before (in the case where answer changes but not target step)
      isTargetStepStored = false;
    }

    // If the targetStep is not already stored, we request it
    if (!isTargetStepStored) {
      if (targetType === "symptom") {
        stepRequestArgs = { checkingProcessId: diagnosticId };
      }
      yield put(getStepRequest(targetId, targetType, stepRequestArgs));
    } else {
      // Push the targetId into the route
      yield put(pushStepIdToURLAction(targetId));
    }
  }
}

/**
 * First case : the active and previous steps belong to a checking process
 * - if the active step and the previous step belong to a same diagnostic of type checking process
 * then the checking process steps counter is descreased by one
 * - if they belong to two different diagnostics of type checking process
 * then we set the counter to the number of steps of the previous diagnostic
 *
 * Second case : the active step belong to a checking process and the previous step
 * belong to a smart diagnostic : we set the symptom to null and the steps counter to 1
 * because the user may identfify a new symptom and the begin a new cheking process
 *
 * Third case : the active step belong to a solution tree and the previous step
 * belong to a checking process : we set the counter to the number of steps of this
 * checking process because we are now at its last step
 */
export function* decrementStepsCounter(action) {
  const activeStepIndex = yield select(getActiveStepIndexSelector);
  const activeStep = yield select(getActiveStepSelector);
  const stepsCounter = yield select(getStepsCounterSelector);
  const previousStep = yield select(getStepByIndexSelector, activeStepIndex + 1);

  if (previousStep) {
    if (
      activeStep.step.diagnostic_type === "checking_process" &&
      previousStep.step.diagnostic_type === "checking_process"
    ) {
      activeStep.step.diagnostic === previousStep.step.diagnostic
        ? yield put(updateStepsCounterAction(stepsCounter - 1))
        : yield put(updateStepsCounterAction(activeStep.step.diagnostic_nb_steps));
    }
  }

  // Going back to a smart_diagnostic entry diagnostic so the primary symptom is reset
  if (previousStep) {
    if (
      activeStep.step.diagnostic_type === "checking_process" &&
      previousStep.step.diagnostic_type === "smart_diagnostic"
    ) {
      yield put(setPrimarySymptom(null));
      yield put(updateStepsCounterAction(1));
    }
  }

  // When going back from a solution tree towards a cheking process, the steps counter
  // is set to the number of steps of the checking process
  if (previousStep) {
    if (
      activeStep.step.diagnostic_type === "solution_tree" &&
      previousStep.step.diagnostic_type === "checking_process"
    ) {
      yield put(updateStepsCounterAction(activeStep.step.diagnostic_nb_steps));
    }
  }
}

/**
 * When the current diagnostic is a checking process, a steps counter is set to
 * display the progression.
 * So when a step whom diagnostic is of type checking_process is retrieved from the
 * API then this methods tests whether the previous step is part of the same diagnostic and
 * if yes, it increments the counter. If no, and if the step is a part of a different diagnostic
 * of type checking_process, it resets the counter.
 */
export function* incrementStepsCounter(action) {
  const activeStepIndex = yield select(getActiveStepIndexSelector);
  const activeStep = yield select(getActiveStepSelector);
  const stepsCounter = yield select(getStepsCounterSelector);
  const previousStep = yield select(getStepByIndexSelector, activeStepIndex - 1);

  if (activeStep && previousStep) {
    if (
      activeStep.step.diagnostic_type === "checking_process" &&
      previousStep.step.diagnostic_type === "checking_process"
    ) {
      activeStep.step.diagnostic === previousStep.step.diagnostic
        ? yield put(updateStepsCounterAction(stepsCounter + 1))
        : yield put(updateStepsCounterAction(1));
    }
  }
}

/**
 * Push the stepId given by the action payload as a query filter in the current url
 */
export function* pushStepIdToURL(action) {
  const stepId = action.payload;
  const searchParams = yield select(getQueryStringsSelector);
  const queryStrings = {
    stepId: stepId,
    id: queryString.parse(searchParams).id,
  };
  yield put(push(`?${queryString.stringify(queryStrings)}`));
}

export function* getDiagnosticSaga() {
  yield takeLatest(GET_DIAGNOSTIC_REQUEST, getDiagnostic);
}

export function* getStepSaga() {
  yield takeEvery(GET_STEP_REQUEST, getStep);
}

export function* getSymtomsFaultsCheckingProcessesFromSymptomSaga() {
  yield takeEvery(
    GET_SYMPTOMS_FAULTS_CHECKING_PROCESSES_FROM_SYMPTOM_REQUEST,
    getSymptomsFaultsCheckingProcessesFromSymptom
  );
}

export function* setActiveStepSaga() {
  yield takeEvery(SET_ACTIVE_STEP, setActiveStep);
}

export function* updateStepAnswerSaga() {
  yield takeEvery(UPDATE_STEP_ANSWER, updateStepAnswer);
}

export function* decrementStepsCounterSaga() {
  yield takeEvery(DECREMENT_STEPS_COUNTER, decrementStepsCounter);
}

export function* incrementStepsCounterSaga() {
  yield takeEvery(INCREMENT_STEPS_COUNTER, incrementStepsCounter);
}

export function* pushStepIdToURLSaga() {
  yield takeEvery(PUSH_STEP_ID_TO_URL, pushStepIdToURL);
}

const initialState = {
  activeStep: null,
  stepsCounter: 1,
  currentSymptom: null,
  diagnostic: {
    step: {
      id: null,
    },
    title: null,
  },
  error: null,
  primarySymptom: null,
  symptomsFaultsCheckingProcesses: [],
  steps: [],
  userDiagnostic: "",
};

/**
 * Following the duck pattern, the module.js file should export a reducer as a default function
 */
export function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_DIAGNOSTIC_REQUEST:
      return {
        ...state,
        steps: [],
        error: null,
        userDiagnostic: "",
      };
    case GET_DIAGNOSTIC_SUCCESS:
      return {
        ...state,
        diagnostic: action.payload,
        userDiagnostic: `diagnostic: ${action.payload.name}`,
      };
    case GET_DIAGNOSTIC_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case GET_STEP_SUCCESS:
      return {
        ...state,
        activeStep: action.payload.id,
        steps: [
          ...state.steps,
          {
            step: action.payload,
            answer: null,
          },
        ],
      };
    case GET_STEP_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case GET_SYMPTOMS_FAULTS_CHECKING_PROCESSES_FROM_SYMPTOM_REQUEST:
      return {
        ...state,
        error: null,
      };
    case GET_SYMPTOMS_FAULTS_CHECKING_PROCESSES_FROM_SYMPTOM_SUCCESS:
      return {
        ...state,
        symptomsFaultsCheckingProcesses: action.payload,
      };
    case GET_SYMPTOMS_FAULTS_CHECKING_PROCESSES_FROM_SYMPTOM_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.payload.stepId,
      };
    case SET_CURRENT_SYMPTOM:
      return {
        ...state,
        currentSymptom: action.payload,
      };
    case SET_PRIMARY_SYMPTOM:
      return {
        ...state,
        primarySymptom: action.payload,
      };
    case SET_STEP_ANSWER:
      return {
        ...state,
        steps: state.steps.map((stepIterator) =>
          stepIterator.step.id === action.payload.currentStepId
            ? { ...stepIterator, answer: action.payload.answer }
            : stepIterator
        ),
        userDiagnostic:
          state.userDiagnostic +
          `%-%Step${action.payload.stepOrder}: ${action.payload.answerHeader} = ${action.payload.answer}`,
      };
    case RESET_OUTDATED_STEP_ANSWERS:
      const activeStepIndex = findIndex(state.steps, (stepIterator) => stepIterator.step.id === action.payload);
      return {
        ...state,
        steps: state.steps.filter((stepIterator, index) => index <= activeStepIndex),
      };
    case UPDATE_STEPS_COUNTER:
      return {
        ...state,
        stepsCounter: action.payload,
      };
    default:
      return state;
  }
}

export default reducer;
