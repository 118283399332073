import React, { Component } from "react";

import PropTypes from "prop-types";

import "./style.css";

export default class QuickAccessElementaryBlock extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    isSelected: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isSelected: false,
  };

  render() {
    return (
      <div
        className={this.props.isSelected ? "qa-elementary-block element-selected" : "qa-elementary-block"}
        style={{ backgroundColor: this.props.index % 2 === 0 ? "#fff" : "#f9f9f9" }}
        onClick={this.props.onClick}
      >
        <div className="qa-elementary-block-text">
          <span>{this.props.text}</span>
        </div>
      </div>
    );
  }
}
