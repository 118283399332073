import React, { Component } from "react";

import { Datagrid, DateField, List, Pagination, TextField } from "react-admin";

import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import { CustomerField } from "components/atoms/CustomerField.js";
import { ReferenceField } from "components/atoms/ReferenceField.js";
import SparePartStatusField from "components/atoms/SparePartStatusField";

import DelayField from "../DelayField/DelayField";
import { EditSparePart } from "../EditSparePart/index.js";
import { SkuHistory } from "../SkuHistory/index.js";
import { SparePartListFilter } from "../SparePartListFilter";
import CustomBulkButtons from "./BulkBottomBar/CustomBulkButtons";
import DefectField from "./DefectField";
import DesignationField from "./DesignationField";
import HistoryField from "./HistoryField";
import { exporter } from "./SparePartExporterUtils";
import { SparePartListStyles } from "./styles.js";

const CustomBulkToolbar = (props) => <CustomBulkButtons label="Custom Toolbar" {...props} />;

export class SparePartList extends Component {
  static propTypes = {
    idEmployee: PropTypes.any,
    classes: PropTypes.any,
    employees: PropTypes.array,
    getEmployees: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      isEditionModalOpen: false,
      isHistoryModalOpen: false,
      selectedSku: {},
      isUpdating: false,
    };
    this.child = React.createRef();
  }
  componentDidMount() {
    this.props.getEmployees();
  }

  openEditionModal = (id, basePath, record) => {
    this.setState({ isEditionModalOpen: true, selectedSku: record });
  };

  handleCloseEditionModal = () => {
    this.setState({ isEditionModalOpen: false });
  };

  openHistoryModal = (record) => (event) => {
    this.refs.sku_history.getSkuLog(record.id);
    this.setState({ isHistoryModalOpen: true, selectedSku: record });
    event.stopPropagation();
  };

  handleCloseHistoryModal = () => {
    this.setState({ isHistoryModalOpen: false });
  };

  toggleUpdate = (bool) => {
    this.setState({ isUpdating: bool });
  };

  render() {
    const { classes, idEmployee, employees } = this.props;
    return (
      <>
        <List
          basePath=""
          resource="spare-parts"
          className={classes.container}
          exporter={false}
          bulkActionButtons={<CustomBulkToolbar exporter={exporter} />}
          filters={<SparePartListFilter idEmployee={idEmployee} />}
          filterDefaultValues={{ assigned_employees: idEmployee }}
          pagination={<Pagination rowsPerPageOptions={[25, 100, 250]} />}
          perPage={25}
          {...this.props}
        >
          <Datagrid rowClick={this.openEditionModal}>
            <DelayField label="Retard" source="aswo_order_due_date" Sku />
            <DesignationField label="Désignation" />
            <TextField source="supplier_name" label="Fournisseur" />
            <ReferenceField label="Réference" />
            <SparePartStatusField label="Statut" />
            <DefectField label="Défaut" />
            <DateField source="updated_at" label="Mise à jour" className={classes.field} showTime locales="fr-FR" />
            <TextField source="location_name" label="Emplacement" className={classes.field} />
            <CustomerField label="Client" classes={classes} />
            <TextField source="demand.employee_name" label="Technicien" className={classes.field} />
            <HistoryField label="Historique" openHistoryModal={this.openHistoryModal} />
          </Datagrid>
        </List>
        <EditSparePart
          ref={this.child}
          isModalOpen={this.state.isEditionModalOpen}
          onCloseModal={this.handleCloseEditionModal}
          id={this.state.selectedSku.id}
          record={this.state.selectedSku}
          toggleUpdate={this.toggleUpdate}
        />
        <SkuHistory
          ref="sku_history"
          isModalOpen={this.state.isHistoryModalOpen}
          onCloseModal={this.handleCloseHistoryModal}
          id={this.state.selectedSku.id}
          record={this.state.selectedSku}
          employees={employees}
        />
      </>
    );
  }
}

export default withStyles(SparePartListStyles)(SparePartList);
