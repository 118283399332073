import React from "react";

import { Record } from "react-admin";

import { Box } from "@material-ui/core";

import { Body, BodyBold } from "components/atoms/Typography";

interface HighlightedReferenceFieldProps {
  record?: Record<any, any>;
  source: string;
  correspondingValue: string;
}

const HighlightedReferenceField: React.FC<HighlightedReferenceFieldProps> = ({
  record,
  source,
  correspondingValue,
}) => {
  if (!record) return <></>;

  const reference = record[source];

  const isHighlighted = correspondingValue === reference;

  if (isHighlighted) {
    return (
      <Box display="flex" justifyContent="flex-start">
        <BodyBold mr={2}>{record["supplier_name"]}</BodyBold>
        <BodyBold mr={2}>{reference}</BodyBold>
      </Box>
    );
  } else {
    return (
      <Box display="flex" justifyContent="flex-start">
        <Body mr={2}>{record["supplier_name"]}</Body>
        <Body ml={2}>{reference}</Body>
      </Box>
    );
  }
};

export default HighlightedReferenceField;
