export default class SkuDemandStatus {
  static get EMITTED() {
    return "emitted";
  }
  static get WAITING_FOR_SKU() {
    return "waiting_for_sku";
  }
  static get LOSS_DECLARED() {
    return "loss_declared";
  }
  static get WAITING_FOR_AFFECTATION() {
    return "waiting_for_affectation";
  }
  static get WAITING_FOR_PACKING() {
    return "waiting_for_packing";
  }
  static get BEING_PREPARED() {
    return "being_prepared";
  }
  static get SENT() {
    return "sent";
  }
  static get AVAILABLE() {
    return "available";
  }
  static get CANCELED() {
    return "canceled";
  }

  // Unsupported status
  static get AWAITING_PLANNING() {
    return "awaiting_planning";
  }
  static get CLOSED() {
    return "closed";
  }
}
