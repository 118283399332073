// specific employees
export const HORS_ZONE = "Hors zone";
export const BRICOLIX = "Bicolix";
export const BRICOLOS = "Bricolos";
export const TECH_FICTIF = "Tech Fictif";
export const NON_LIMITED_FILLING_REPAIRMEN = [HORS_ZONE, BRICOLIX, BRICOLOS, TECH_FICTIF];

// employee roles
export const REPAIRMAN = "repairman";

// visit types
export const ADMINISTRATIVE = "administrative";
export const REOPENING_SP_INSTALLATION = "reopening_sp_installation";
export const SP_INSTALLATION = "sp_installation";
export const PREVI = "previ";

// timeslots types
export const DISPO = "dispo";
export const FIRST_INTERVENTION = "first_intervention";
export const NEXT_INTERVENTION = "next_intervention";
export const PASSAGE = "passage";
export const P1 = "P1";
export const P2Plus = "P2+";

// visit status
export const TBC = "tbc";
export const TBM = "tbm";
export const DONE = "done";
export const INTERVENTION = "intervention";
export const CANCELED = "canceled";

// time unit
export const DAY = "day";
export const WEEK = "week";

//murfy url
export const MURFY_URL_SUB_DOMAIN_TINY = "c.murfy.fr";

export const TIMESLOT_PROPOSAL_IN_PROGRESS = "Proposition de créneau en cours";
