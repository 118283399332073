import { call, put } from "redux-saga/effects";

import { getAPIUrl, request } from "utils/networking";

export function* getTutorial(onSuccessAction, onErrorAction, action) {
  const config = {
    method: "get",
    url: `${getAPIUrl()}/widget/tutoriel/${action.payload}/`,
  };
  try {
    const tutoriel = yield call(request, config);
    yield put(onSuccessAction(tutoriel));
  } catch (error) {
    yield put(onErrorAction(error));
  }
}
