import React from "react";

import { Box } from "@material-ui/core";

import { SmallBold } from "components/atoms/Typography";
import Caption from "components/atoms/Typography/Caption";

import { useSparePart } from "utils/hooks/useSparePart";

interface SparePartFittingProps {
  sparePartId: number;
}

const SparePartDetail: React.FC<SparePartFittingProps> = ({ sparePartId }: SparePartFittingProps) => {
  const { data, isLoading, isError } = useSparePart(sparePartId);

  if (isLoading && !data) {
    return <div>Récupération de la pièce</div>;
  }

  if (isError || !data) {
    return <div>Echec de la récupération de la pièce</div>;
  }
  return (
    <Box pl={2}>
      <SmallBold>{data.unique_id}</SmallBold>
      <Caption>Référence: {data.sku_model.sku_reference}</Caption>
      <Caption>Désignation: {data.sku_model.designation}</Caption>
      <Caption>Id Murfy: {sparePartId}</Caption>
    </Box>
  );
};

export default SparePartDetail;
