import React from "react";

import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Circle from "components/atoms/Circle";
import { CorrespondanceTag, NewTag, SecondHandTag } from "components/atoms/ColorChip";
import Price from "components/atoms/Price";
import { ReferenceField } from "components/atoms/ReferenceField";

import { SKU_SUBSTITUTION_TYPE_SUBSTITUTE, SKU_TYPE_NEW } from "constants/sku";

import { useAswoPrice } from "utils/api";
import { getAswoReference } from "utils/sku";

// Price sticks to the right
const useStyles = makeStyles((theme) => ({
  container: {
    cursor: "pointer",
    background: "#F7F8FC",
    borderRadius: 12,
  },
  selected: {
    border: "1px solid #2B3B5C",
  },
  reference: {
    color: "#ABABAB",
  },
  avaibility: {
    fontWeight: 600,
  },
}));

export default ({ sku, quantity, isSelected, onClick }) => {
  const classes = useStyles();
  const aswoReference = getAswoReference(sku);
  const buyingPrice = useAswoPrice(aswoReference);
  const designation = sku.designation ? sku.designation.substring(0, 30) : "";
  const isInStock = sku.stock_available >= quantity;
  const isNew = sku.type === SKU_TYPE_NEW;
  const isSubstitute = sku.substitution_type === SKU_SUBSTITUTION_TYPE_SUBSTITUTE;

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      width={"100%"}
      p={3}
      mb={1}
      className={[classes.container, isSelected ? classes.selected : null]}
      onClick={onClick}
    >
      <Box width={"70%"}>
        <Typography variant="subtitle2">{designation}</Typography>
        {sku.references.map((reference) => (
          <>
            <Typography variant="caption" className={classes.reference}>
              {reference.supplier_name} <ReferenceField record={reference} variant="caption" />
            </Typography>
            <br />
          </>
        ))}
        <Box mt={1} display="flex">
          <Price price={buyingPrice} unavailabilityText={"Prix d'achat indisponible"} />
          <Circle color={isInStock ? "green" : "red"} mt={"6px"} ml={3} />
          <Typography variant="body2" className={classes.avaibility}>
            {isInStock ? "En stock" : "À commander"}
          </Typography>
        </Box>
      </Box>
      <Box float="right" textAlign="right">
        <Box mb={1}>{isNew ? <NewTag /> : <SecondHandTag />}</Box>
        {isSubstitute && <CorrespondanceTag />}{" "}
      </Box>
    </Box>
  );
};
