import React from "react";

import { FunctionField } from "react-admin";

import { withStyles } from "@material-ui/core/styles";

import StatusFieldStyles from "shared/styles/StatusFieldStyles";

import DemandItemStatus from "constants/demandItemStatus";

export default withStyles(StatusFieldStyles)(({ record, classes }) => {
  const render = () => {
    switch (record.status) {
      case DemandItemStatus.EMITTED:
        return "Émise";
      case DemandItemStatus.WAITING_FOR_SKU:
        return "En attente de pièces";
      case DemandItemStatus.PACKED:
        return "Colisé";
      case DemandItemStatus.RESERVED:
        return "Réservé";
      case DemandItemStatus.LOSS_DECLARED:
        return "Perte déclarée";
      case DemandItemStatus.CANCELED:
        return "Annulé";
      default:
        return "";
    }
  };

  const getClass = () => {
    switch (record.status) {
      case DemandItemStatus.EMITTED:
        return classes.grey;
      case DemandItemStatus.WAITING_FOR_SKU:
        return classes.purple;
      case DemandItemStatus.RESERVED:
        return classes.dark_blue;
      case DemandItemStatus.PACKED:
        return classes.yellow;
      case DemandItemStatus.LOSS_DECLARED:
      case DemandItemStatus.CANCELED:
        return classes.red;
      default:
        return classes.grey;
    }
  };

  return <FunctionField render={render} className={`${classes.label} ${getClass()}`} />;
});
