import React from "react";

import { useQuery } from "react-admin";

import { Box, Link } from "@material-ui/core";

const PrequalRedirectionLink = ({ customerFile }) => {
  const url = `${process.env.REACT_APP_MURFY_TECH_URL || ""}/prequalification`;

  const { data: prequals } = useQuery({
    type: "getMany",
    resource: "fault-forecast",
    payload: {
      ids: customerFile.products.map((product) => {
        return product.id;
      }),
    },
  });
  return (
    <Box color="blue" display={"inline-grid"}>
      {prequals &&
        prequals.map((prequal) => {
          const product = customerFile.products.find((product) => product.id === prequal.product);
          return (
            <Link
              component="button"
              variant="body1"
              color="inherit"
              onClick={() => {
                window.open(`${url}/${prequal.id}/`, "_blank");
              }}
            >
              {`+ Faire une préqualification ${product.product_type} ${product.brand} ${
                product.model_reference || ""
              } `}
            </Link>
          );
        })}
    </Box>
  );
};

export default PrequalRedirectionLink;
