import React from "react";

import { useNotify, usePermissions, useRefresh } from "react-admin";

import { Box } from "@material-ui/core";

import { CustomerFileDetailed } from "types/customer-files";
import { RepairReportCheck } from "types/repair-report-checks";

import Loading from "components/atoms/Loading";

import { REPORT_CHECK_REASONS_CHOICES } from "constants/reportCheckReasons";
import { TASK_STATE_TODO } from "constants/task";

import {
  postComment,
  reportCheckedByLead,
  updateRepairReportCheck,
  useConnectedEmployee,
  useRepairReportChecks,
} from "utils/api";
import { getVerboseFromListOfElements } from "utils/getVerboseFromListOfElements";
import { DEVELOPER_GROUP, TEAM_LEADER, hasPerm } from "utils/permissions";

import OpinionForm from "./components/OpinionForm";

type LeadOpinionProps = {
  productId: number;
  customerFile: CustomerFileDetailed;
  getCustomerFileDetail: (fileId: number) => void;
  shouldRefreshInterface: () => void;
};

type FormValues = {
  task: number | null;
  comment: string;
};

const LeadOpinion: React.FunctionComponent<LeadOpinionProps> = ({
  customerFile,
  productId,
  getCustomerFileDetail,
  shouldRefreshInterface,
}: LeadOpinionProps) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const { data: repairReportChecks } = useRepairReportChecks(customerFile.id, TASK_STATE_TODO);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { permissions } = usePermissions();
  const relatedTask =
    repairReportChecks &&
    repairReportChecks
      .filter((check: RepairReportCheck) => check.product === productId)
      .slice(-1)
      .pop();

  const connectedUser = useConnectedEmployee();

  const formatComment = (relatedTask: RepairReportCheck, comment: string) => {
    return `Demande de vérification par demandeur: ${relatedTask.created_by_name}, ${relatedTask.reasons.map(
      (reason: string) => {
        return `${getVerboseFromListOfElements(REPORT_CHECK_REASONS_CHOICES, reason)},`;
      }
    )} 
    \n${relatedTask.comment} 
    \nAvis du CE : 
    \n${comment}`;
  };

  const handlePostTeamLeaderCheck = async (values: FormValues) => {
    // if there are checks left to do on another product, then individually create update objects
    setIsSubmitting(true);
    if (
      repairReportChecks.find(
        (check: RepairReportCheck) => check.state === TASK_STATE_TODO && check.product !== productId
      )
    ) {
      try {
        await postComment({
          customer_file: customerFile.id,
          text: formatComment(relatedTask, values.comment),
          author: connectedUser.id,
        });
        await updateRepairReportCheck(values.task as number, { state: "done" });
        shouldRefreshInterface();
      } catch (e) {
        notify("La soumission d'avis n'a pas fonctionné", "error");
      }
    } else {
      values.comment = formatComment(relatedTask, values.comment);
      const { status } = await reportCheckedByLead(customerFile.uuid, { payload: { ...values } });
      if (status < 200 || status >= 300) {
        notify("Une erreur est survenue", "warning");
      } else {
        getCustomerFileDetail(customerFile.id); // refreshes the file detail, ugh
        notify("Avis créé");
        shouldRefreshInterface();
      }
    }

    refresh(); // refreshes the list
    setIsSubmitting(false);
  };

  if (isSubmitting)
    return (
      <Box mt={2} mb={2} textAlign="center">
        Soumission de l'avis en cours
        <Loading />
      </Box>
    );
  return hasPerm(permissions, [DEVELOPER_GROUP, TEAM_LEADER]) ? (
    <Box width="100%">
      <OpinionForm submitOpinion={handlePostTeamLeaderCheck} customerFile={customerFile} productId={productId} />
    </Box>
  ) : null;
};

export default LeadOpinion;
