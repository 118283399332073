import { TVA_RATE } from "constants/payment";

export const formatPrice = (priceInCents) => {
  const price = priceInCents / 100;
  return Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(price);
};

export const getPricePretax = (price) => {
  return parseInt(price / (1 + TVA_RATE));
};

export function toCents(amount) {
  const cents = Math.round(parseFloat(amount) * 100);
  if (isNaN(cents)) {
    throw new Error("Montant invalide");
  }
  return cents;
}
