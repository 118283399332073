import React, { useState } from "react";

import { Box, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import Modal from "components/Modal";

const GenericConfirmationModal = ({
  isValidationModalOpen,
  handleCloseValidationModal,
  handleValidateAction,
  dataValidation,
  confirmationModalTitle,
  confirmationModalSubtitle,
  children,
}) => {
  const [isValidateButtonDisabled, setIsValidationModalOpen] = useState(false);

  const onClickValidateAction = () => {
    setIsValidationModalOpen(true);
    handleValidateAction(dataValidation);
  };

  return (
    <>
      <Modal
        modalClassName={`modal-small-content`}
        isOpen={isValidationModalOpen}
        handleCloseModal={handleCloseValidationModal}
      >
        <Box textAlign="center" mt={5}>
          <Box pb={2}>
            <Typography id="confirmation-modal-title" variant="h2">
              {confirmationModalTitle}
            </Typography>
          </Box>
          <Box pb={7}>
            <Typography id="confirmation-modal-subtitle" variant="body1">
              {confirmationModalSubtitle}
            </Typography>
          </Box>
        </Box>
        {children}

        <Box pb={4} display="flex" justifyContent="space-evenly">
          <Button
            id="confirm-validation"
            variant="contained"
            color="secondary"
            onClick={onClickValidateAction}
            disabled={isValidateButtonDisabled}
          >
            Confirmer
          </Button>

          <Button id="cancel-validation" variant="text" onClick={handleCloseValidationModal}>
            Annuler
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default GenericConfirmationModal;
