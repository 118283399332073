import React, { Component } from "react";

import PropTypes from "prop-types";

import "./style.css";

export default class FileUploadField extends Component {
  static propTypes = {
    isMainContainer: PropTypes.bool,
    onFileChange: PropTypes.func.isRequired,
    title: PropTypes.string,
    displayTitle: PropTypes.bool,
  };

  static defaultProps = {
    isMainContainer: false,
    title: "Image",
    displayTitle: true,
  };

  onFileChange = (event) => {
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (e) => {
      this.props.onFileChange(file, reader.result);
    };
  };

  render() {
    return (
      <div className={this.props.isMainContainer ? "file-upload-container" : ""}>
        {this.props.displayTitle && <p className="file-upload-title">{this.props.title}</p>}
        <input className="file-upload-input" type="file" onChange={this.onFileChange} />
      </div>
    );
  }
}
