import React from "react";

const listReportType = [
  { id: "need-spare-part", name: "Commande de pièce détachée" },
  { id: "without-spare-part", name: "Réparable sans besoin de pièce" },
  { id: "not_reparable", name: "Non-réparable" },
];
const ReportTypeRepairReportField = ({ record }) => {
  return (
    <div>
      {record &&
        listReportType.find((reportType) => reportType.id === record.report_type) &&
        listReportType.find((reportType) => reportType.id === record.report_type).name}
    </div>
  );
};

export default ReportTypeRepairReportField;
