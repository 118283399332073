/**
 * Following the duck pattern, actions, constants and reducers are in the same file called module.js
 *
 * See: https://github.com/erikras/ducks-modular-redux
 *
 */
import { push } from "connected-react-router";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import get from "lodash/get";
import random from "lodash/random";
import sortBy from "lodash/sortBy";
import { toastr } from "react-redux-toastr";
import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { getStepWidget } from "shared/pages/Diagnostic/module";

import { POST_STEP_IMAGE_ORDER_SUCCESS } from "pages/Cms/ContentManagement/components/ImagesManagement/module";

import { addOrReplaceById } from "utils/addOrReplace";
import convertIntoFormData from "utils/formData";
import { getTokenFromLocalStorage, request } from "utils/networking";

export const GET_STEP_REQUEST = "STEP_EDITION/GET_STEP_REQUEST";
export const GET_STEP_SUCCESS = "STEP_EDITION/GET_STEP_SUCCESS";
export const GET_STEP_ERROR = "STEP_EDITION/GET_STEP_ERROR";
export const GET_STEP_WIDGET_ADMIN_REQUEST = "STEP_EDITION/GET_STEP_WIDGET_ADMIN_REQUEST";

export const SAVE_STEP_REQUEST = "STEP_EDITION/SAVE_STEP_REQUEST";
export const SAVE_STEP_SUCCESS = "STEP_EDITION/SAVE_STEP_SUCCESS";
export const SAVE_STEP_ERROR = "STEP_EDITION/SAVE_STEP_ERROR";

export const DELETE_STEP_REQUEST = "STEP_EDITION/DELETE_STEP_REQUEST";
export const DELETE_STEP_SUCCESS = "STEP_EDITION/DELETE_STEP_SUCCESS";
export const DELETE_STEP_ERROR = "STEP_EDITION/DELETE_STEP_ERROR";

export const REMOVE_STEP_WIDGET = "STEP_EDITION/REMOVE_STEP_WIDGET";
export const REMOVE_STEP_TRANSITION = "STEP_EDITION/REMOVE_STEP_TRANSITION";

export const SET_STEP_TITLE = "STEP_EDITION/SET_STEP_TITLE";
export const SET_STEP_CONTEXT = "STEP_EDITION/SET_STEP_CONTEXT";
export const SET_STEP_BAR_CONTENT = "STEP_EDITION/SET_STEP_BAR_CONTENT";
export const SET_STEP_EXTERNAL_URL = "STEP_EDITION/SET_STEP_EXTERNAL_URL";
export const SET_STEP_S3_URL = "STEP_EDITION/SET_STEP_S3_URL";
export const SET_STEP_HAS_NO_MEDIA = "STEP_EDITION/SET_STEP_HAS_NO_MEDIA";
export const SET_STEP_TRANSITION_FIELD = "STEP_EDITION/SET_STEP_TRANSITION_FIELD";
export const SET_STEP_TRANSITION_MEDIA = "STEP_EDITION/SET_STEP_TRANSITION_MEDIA";
export const SET_STEP_TRANSITIONS_TYPE_TO_IMAGE = "STEP_EDITION/SET_STEP_TRANSITIONS_TYPE_TO_IMAGE";
export const SET_STEP_TRANSITIONS_TYPE_TO_TEXT = "STEP_EDITION/SET_STEP_TRANSITIONS_TYPE_TO_TEXT";
export const SET_STEP_TRANSITIONS_ORDER = "STEP_EDITION/SET_STEP_TRANSITIONS_ORDER";
export const SET_STEP_WIDGET = "STEP_EDITION/SET_STEP_WIDGET";

export const INITIALIZE_NEW_STEP = "STEP_EDITION/INITIALIZE_NEW_STEP";
export const INITIALIZE_NEW_TRANSITION = "STEP_EDITION/INITIALIZE_NEW_TRANSITION";

export const OPEN_TRANSITION_MODAL = "STEP_EDITION/OPEN_TRANSITION_MODAL";
export const CLOSE_TRANSITION_MODAL = "STEP_EDITION/CLOSE_TRANSITION_MODAL";

export const INCREASE_STEP_TRANSITIONS_ORDER = "STEP_EDITION/INCREASE_STEP_TRANSITIONS_ORDER";
export const DECREASE_STEP_TRANSITIONS_ORDER = "STEP_EDITION/DECREASE_STEP_TRANSITIONS_ORDER";

export function getStepRequest(id) {
  return {
    type: GET_STEP_REQUEST,
    payload: id,
  };
}

export function getStepSuccess(step) {
  return {
    type: GET_STEP_SUCCESS,
    payload: step,
  };
}

export function getStepError(error) {
  return {
    type: GET_STEP_ERROR,
    payload: error,
  };
}

export function getStepWidgetAdminRequest(id, widgetType) {
  return {
    type: GET_STEP_WIDGET_ADMIN_REQUEST,
    payload: {
      widget: {
        widget_type: widgetType,
        id: id,
      },
    },
  };
}

export function saveStepRequest() {
  return {
    type: SAVE_STEP_REQUEST,
  };
}

export function saveStepSuccess(step) {
  return {
    type: SAVE_STEP_SUCCESS,
    payload: step,
  };
}

export function saveStepError(error) {
  return {
    type: SAVE_STEP_ERROR,
    payload: error,
  };
}

export function initializeNewStep(diagnosticId) {
  return {
    type: INITIALIZE_NEW_STEP,
    payload: diagnosticId,
  };
}

export function initializeNewTransition(buttonType, order) {
  return {
    type: INITIALIZE_NEW_TRANSITION,
    payload: { buttonType, order },
  };
}

export function deleteStepRequest(id) {
  return {
    type: DELETE_STEP_REQUEST,
    payload: id,
  };
}

export function deleteStepSuccess(id) {
  return {
    type: DELETE_STEP_SUCCESS,
    payload: id,
  };
}

export function deleteStepError(error) {
  return {
    type: DELETE_STEP_ERROR,
    payload: error,
  };
}

export function removeStepTransition(transitionId) {
  return {
    type: REMOVE_STEP_TRANSITION,
    payload: transitionId,
  };
}

export function removeStepWidget() {
  return {
    type: REMOVE_STEP_WIDGET,
  };
}

export function setStepTitle(title) {
  return {
    type: SET_STEP_TITLE,
    payload: title,
  };
}

export function setStepContext(context) {
  return {
    type: SET_STEP_CONTEXT,
    payload: context,
  };
}

export function setStepBarContent(barContent) {
  return {
    type: SET_STEP_BAR_CONTENT,
    payload: barContent,
  };
}

export function setStepExternalURL(externalURL, mediaType, mediaName) {
  return {
    type: SET_STEP_EXTERNAL_URL,
    payload: { externalURL, mediaType, mediaName },
  };
}

export function setStepS3URL(fileToUpload, previewURL, mediaType, mediaName) {
  return {
    type: SET_STEP_S3_URL,
    payload: { fileToUpload, previewURL, mediaType, mediaName },
  };
}

export function setStepHasNoMedia() {
  return {
    type: SET_STEP_HAS_NO_MEDIA,
  };
}

export function setStepTransitionField(transitionId, fieldName, fieldValue) {
  return {
    type: SET_STEP_TRANSITION_FIELD,
    payload: { transitionId, fieldName, fieldValue },
  };
}

export function setStepTransitionMedia(transitionId, file, previewUrl) {
  return {
    type: SET_STEP_TRANSITION_MEDIA,
    payload: { transitionId, file, previewUrl },
  };
}

export function setStepTransitionsTypeToImage() {
  return {
    type: SET_STEP_TRANSITIONS_TYPE_TO_IMAGE,
  };
}

export function setStepTransitionsTypeToText() {
  return {
    type: SET_STEP_TRANSITIONS_TYPE_TO_TEXT,
  };
}

export function setStepTransitionsOrder(transitionId, isIncreased) {
  return {
    type: SET_STEP_TRANSITIONS_ORDER,
    payload: { transitionId, isIncreased },
  };
}

export function increaseStepTransitionsOrder(transitionId) {
  return {
    type: INCREASE_STEP_TRANSITIONS_ORDER,
    payload: transitionId,
  };
}

export function decreaseStepTransitionsOrder(transitionId) {
  return {
    type: DECREASE_STEP_TRANSITIONS_ORDER,
    payload: transitionId,
  };
}

export function setStepWidget(id, name, widgetType, company) {
  return {
    type: SET_STEP_WIDGET,
    payload: { id, name, widgetType, company },
  };
}

export function openTransitionModal(openedTransitionModalId) {
  return {
    type: OPEN_TRANSITION_MODAL,
    payload: openedTransitionModalId,
  };
}

export function closeTransitionModal() {
  return {
    type: CLOSE_TRANSITION_MODAL,
  };
}

export const getStepDiagnosticId = (state) => state.stepEdition.step && state.stepEdition.step.diagnostic;

export const getEditedTransitions = (state) => state.stepEdition.editedTransitions;

export const getTransitionsSelector = (state) => state.stepEdition.step.transitions;

/**
 * the use of the spread operator (...) allow to add keys only if values are defined
 */
export const getSaveStepDataSelector = (state) => ({
  diagnostic: state.stepEdition.step.diagnostic,
  step_bar_content: state.stepEdition.step.step_bar_content,
  title: state.stepEdition.step.title,
  widget: get(state.stepEdition.step.widget, "id", ""),
  ...(state.stepEdition.step.context && { context: state.stepEdition.step.context }),
  ...(state.stepEdition.step.id && { id: state.stepEdition.step.id }),
});

export const getStepTransitionsTypeSelector = (state) => {
  let transitions = get(state.stepEdition, "step.transitions");
  if (!(transitions && state.stepEdition.step.transitions.length > 0)) {
    return "text";
  }
  return transitions.reduce(
    (areAllTransitionsImages, transition) => transition.button_type === "image" && areAllTransitionsImages,
    true
  )
    ? "image"
    : "text";
};

export const getFileToUploadSelector = (state) => state.stepEdition.fileToUpload;

export function getContentMediaSelector(state) {
  const { fileToUpload, step } = state.stepEdition;

  let isMediaSet = step.s3_url || step.external_url || (step.images && step.images.length > 0);
  let contentData = {
    media_name: isMediaSet ? step.media_name : "",
    media_type: isMediaSet ? step.media_type : "",
    external_url: step.external_url || "",
  };
  if (fileToUpload) {
    contentData = {
      ...contentData,
      s3_url: fileToUpload,
    };
  } else {
    if (!step.s3_url) {
      contentData = {
        ...contentData,
        s3_url: "",
      };
    }
  }
  return contentData;
}

export const getSaveStepTransitionConfig = (token, transition) => {
  let { id, isDeleted, isCreated } = transition;
  let baseConfig = {
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  let baseUrl = `${process.env.REACT_APP_API_URL}/widget/diagnostic/step-transition/`;
  if (isDeleted && !isCreated) {
    return { ...baseConfig, method: "delete", url: `${baseUrl}${id}/` };
  }
  if (isCreated && !isDeleted) {
    return { ...baseConfig, method: "post", url: baseUrl, data: convertIntoFormData(transition) };
  } else {
    return {
      ...baseConfig,
      method: "patch",
      url: `${baseUrl}${id}/`,
      data: convertIntoFormData(transition),
    };
  }
};

export function* getStep(action) {
  const token = yield call(getTokenFromLocalStorage);
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/widget/diagnostic/step/${action.payload}/`,
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  try {
    const step = yield call(request, config);
    yield put(getStepSuccess(step));
    yield call(getStepWidget, step.widget);
  } catch (error) {
    yield put(getStepError(error));
  }
}

export function* saveStepTransitions(token) {
  let editedTransitions = yield select(getEditedTransitions);
  let transitionsToSave = editedTransitions.filter((transition) => {
    // Filter out steps that have been created and deleted before save
    return !(transition.isCreated && transition.isDeleted);
  });
  yield all(transitionsToSave.map((transition) => call(request, getSaveStepTransitionConfig(token, transition))));
}

export function* saveStep(action) {
  const token = yield call(getTokenFromLocalStorage);
  const stepData = yield select(getSaveStepDataSelector);
  const contentData = yield select(getContentMediaSelector);
  const formData = convertIntoFormData({ ...stepData, ...contentData });
  const isNewStep = !stepData.id;

  let baseConfig = {
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  };

  const getConfig = (isNewStep) => {
    if (isNewStep) {
      return {
        ...baseConfig,
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/widget/diagnostic/step/`,
      };
    } else {
      return {
        ...baseConfig,
        method: "patch",
        url: `${process.env.REACT_APP_API_URL}/widget/diagnostic/step/${stepData.id}/`,
      };
    }
  };

  try {
    yield call(saveStepTransitions, token);
    const response = yield call(request, getConfig(isNewStep));
    yield put(saveStepSuccess(response));
    yield call(toastr.success, "Succès", "Étape enregistrée");
    // Fire the push action after the toastr to give time to React rerender the
    // StepEdition component so the isEditingDirty variable switches to false
    // and the Prompt is not shown
    if (isNewStep) yield put(push(`/admin/cms/diagnostic/step/${response.id}/`));
  } catch (error) {
    yield put(saveStepError(error));
    yield call(toastr.error, "Erreur", "L'étape n'a pas été enregistrée");
  }
}

export function* deleteStep(action) {
  const token = yield call(getTokenFromLocalStorage);
  const config = {
    method: "delete",
    url: `${process.env.REACT_APP_API_URL}/widget/diagnostic/step/${action.payload}/`,
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  try {
    yield call(request, config);
    yield put(deleteStepSuccess(action.payload));
    yield call(toastr.success, "Succès", "L'élément a bien été supprimé");
  } catch (error) {
    yield put(deleteStepError(error));
    yield call(toastr.error, "Erreur", "L'élément n'a pas été supprimé");
  }
}

export function* getStepWidgetAdmin(action) {
  yield call(getStepWidget, action.payload.widget);
}

export function* setEditedStepTransitionsOrder(action) {
  const { isIncreased, transitionId } = action.payload;
  isIncreased
    ? yield put(increaseStepTransitionsOrder(transitionId))
    : yield put(decreaseStepTransitionsOrder(transitionId));

  const transitions = yield select(getTransitionsSelector);

  yield all(transitions.map((transition, index) => put(setStepTransitionField(transition.id, "order", index))));
}

export function* saveStepSaga() {
  yield takeEvery(SAVE_STEP_REQUEST, saveStep);
}

export function* getAdminStepSaga() {
  yield takeEvery(GET_STEP_REQUEST, getStep);
}

export function* getStepWidgetAdminSaga() {
  yield takeEvery(GET_STEP_WIDGET_ADMIN_REQUEST, getStepWidgetAdmin);
}

export function* deleteStepSaga() {
  yield takeLatest(DELETE_STEP_REQUEST, deleteStep);
}

export function* setStepTransitionsOrderSaga() {
  yield takeLatest(SET_STEP_TRANSITIONS_ORDER, setEditedStepTransitionsOrder);
}

const initialState = {
  error: null,
  editedTransitions: [],
  fileToUpload: null,
  isEditingDirty: false,
  openedTransitionModalId: null,
  step: null,
};

/**
 * Following the duck pattern, the module.js file should export a reducer as a default function
 */
export function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_STEP_REQUEST:
      return {
        ...state,
        step: null,
      };
    case GET_STEP_SUCCESS:
      return {
        ...initialState,
        step: action.payload,
      };
    case GET_STEP_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case REMOVE_STEP_WIDGET:
      return {
        ...state,
        isEditingDirty: true,
        step: {
          ...state.step,
          widget: null,
        },
      };
    case REMOVE_STEP_TRANSITION:
      let transitionToRemove = action.payload;
      let checkTransitionToRemove = (transition) => transition.id !== transitionToRemove;
      return {
        ...state,
        isEditingDirty: true,
        editedTransitions: addOrReplaceById(state.editedTransitions, transitionToRemove, "isDeleted", true),
        step: {
          ...state.step,
          transitions: state.step.transitions.filter(checkTransitionToRemove),
        },
      };
    case SAVE_STEP_REQUEST:
      return {
        ...state,
      };
    case SAVE_STEP_SUCCESS:
      return {
        ...state,
        isEditingDirty: false,
        editedTransitions: [],
        fileToUpload: null,
        step: action.payload,
      };
    case SAVE_STEP_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case INITIALIZE_NEW_STEP:
      return {
        ...state,
        step: {
          diagnostic: action.payload,
        },
      };
    case INITIALIZE_NEW_TRANSITION:
      const newTransitionId = random(-100, 0);
      return {
        ...state,
        step: {
          ...state.step,
          transitions: [
            ...state.step.transitions,
            {
              button_type: action.payload.buttonType,
              id: newTransitionId,
              order: action.payload.order,
            },
          ],
        },
        editedTransitions: [
          ...state.editedTransitions,
          {
            button_type: action.payload.buttonType,
            current_step: state.step.id,
            id: newTransitionId,
            isCreated: true,
            order: action.payload.order,
          },
        ],
        isEditingDirty: true,
      };
    case DELETE_STEP_REQUEST:
      return {
        ...state,
      };
    case DELETE_STEP_SUCCESS:
      return {
        ...state,
      };
    case DELETE_STEP_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_STEP_TITLE:
      return {
        ...state,
        isEditingDirty: true,
        step: {
          ...state.step,
          title: action.payload,
        },
      };
    case SET_STEP_CONTEXT:
      return {
        ...state,
        isEditingDirty: true,
        step: {
          ...state.step,
          context: action.payload,
        },
      };
    case SET_STEP_BAR_CONTENT:
      return {
        ...state,
        isEditingDirty: true,
        step: {
          ...state.step,
          step_bar_content: action.payload,
        },
      };
    case SET_STEP_EXTERNAL_URL:
      return {
        ...state,
        isEditingDirty: true,
        fileToUpload: null,
        step: {
          ...state.step,
          media_type: action.payload.mediaType,
          media_name: action.payload.mediaName,
          external_url: action.payload.externalURL,
          s3_url: null,
        },
      };
    case SET_STEP_S3_URL:
      return {
        ...state,
        isEditingDirty: true,
        fileToUpload: action.payload.fileToUpload,
        step: {
          ...state.step,
          media_type: action.payload.mediaType,
          media_name: action.payload.mediaName,
          external_url: null,
          s3_url: action.payload.previewURL,
        },
      };
    case SET_STEP_HAS_NO_MEDIA:
      return {
        ...state,
        isEditingDirty: true,
        step: {
          ...state.step,
          external_url: "",
          media_name: "",
          media_type: "",
          s3_url: "",
        },
      };
    case SET_STEP_TRANSITION_FIELD:
      return {
        ...state,
        isEditingDirty: true,
        step: {
          ...state.step,
          transitions: state.step.transitions.map((transition) =>
            transition.id === action.payload.transitionId
              ? { ...transition, [action.payload.fieldName]: action.payload.fieldValue }
              : transition
          ),
        },
        editedTransitions: addOrReplaceById(
          state.editedTransitions,
          action.payload.transitionId,
          action.payload.fieldName,
          action.payload.fieldValue
        ),
      };
    case SET_STEP_TRANSITION_MEDIA:
      return {
        ...state,
        step: {
          ...state.step,
          transitions: state.step.transitions.map((transition) =>
            transition.id === action.payload.transitionId
              ? { ...transition, media: action.payload.previewUrl }
              : transition
          ),
        },
        editedTransitions: addOrReplaceById(
          state.editedTransitions,
          action.payload.transitionId,
          "media",
          action.payload.file
        ),
      };
    case SET_STEP_TRANSITIONS_TYPE_TO_IMAGE:
      return {
        ...state,
        step: {
          ...state.step,
          transitions: state.step.transitions.map((transition) => ({
            ...transition,
            button_type: "image",
          })),
        },
        editedTransitions: state.step.transitions.reduce(
          (accumulator, transition) => addOrReplaceById(accumulator, transition.id, "button_type", "image"),
          state.editedTransitions
        ),
      };
    case SET_STEP_TRANSITIONS_TYPE_TO_TEXT:
      return {
        ...state,
        isEditingDirty: true,
        step: {
          ...state.step,
          transitions: state.step.transitions.map((transition) => ({
            ...transition,
            media: undefined,
            button_type: "text",
          })),
        },
        editedTransitions: state.step.transitions.reduce((accumulator, transition) => {
          accumulator = addOrReplaceById(accumulator, transition.id, "media", "");
          accumulator = addOrReplaceById(accumulator, transition.id, "button_type", "text");
          return accumulator;
        }, state.editedTransitions),
      };
    case DECREASE_STEP_TRANSITIONS_ORDER:
      const transitionIndex = findIndex(state.step.transitions, { id: action.payload });
      const transitionOrder = get(find(state.step.transitions, { id: action.payload }), "order");
      return {
        ...state,
        /**
         * We aim at switching the transition passed by the action with the previous one :
         *  - by allowing the transition order to the previous one and decreasing the transition order
         *  - in order to keep the order of the others steps, we also descrease the order of the previous ones
         */
        step: {
          ...state.step,
          transitions: sortBy(
            state.step.transitions.map((transition, index) => {
              if (index === transitionIndex - 1) {
                return { ...transition, order: transitionOrder };
              } else if (index === transitionIndex || index < transitionIndex - 1) {
                return { ...transition, order: transition.order - 1 };
              } else {
                return transition;
              }
            }),
            "order"
          ),
        },
      };
    case INCREASE_STEP_TRANSITIONS_ORDER:
      const _transitionIndex = findIndex(state.step.transitions, { id: action.payload });
      const _transitionOrder = get(find(state.step.transitions, { id: action.payload }), "order");
      return {
        ...state,
        /**
         * We aim at switching the transition passed by the action with the next one :
         *  - by allowing the transition order to the next one and increasing the transition order
         *  - in order to keep the order of the others steps, we also increase the order of the next ones
         */
        step: {
          ...state.step,
          transitions: sortBy(
            state.step.transitions.map((transition, index) => {
              if (index === _transitionIndex + 1) {
                return { ...transition, order: _transitionOrder };
              } else if (index === _transitionIndex || index > _transitionIndex + 1) {
                return { ...transition, order: transition.order + 1 };
              } else {
                return transition;
              }
            }),
            "order"
          ),
        },
      };
    case SET_STEP_WIDGET:
      return {
        ...state,
        isEditingDirty: true,
        step: {
          ...state.step,
          widget: {
            id: action.payload.id,
            name: action.payload.name,
            company: action.payload.company,
            widget_type: action.payload.widgetType,
          },
        },
      };
    case OPEN_TRANSITION_MODAL:
      return {
        ...state,
        openedTransitionModalId: action.payload,
      };
    case CLOSE_TRANSITION_MODAL:
      return {
        ...state,
        openedTransitionModalId: null,
      };
    case POST_STEP_IMAGE_ORDER_SUCCESS:
      return {
        ...state,
        step: {
          ...state.step,
          images: [...state.step.images, action.payload],
          media_type: "image",
        },
      };
    default:
      return state;
  }
}

export default reducer;
