import axios from "axios";
import { eventTracker } from "shared/middleware/analytics/trackers";

export function checkError(error) {
  // if error.response sizes more than 500 bytes (210 char), it must be sliced because analytics doesn't support more
  eventTracker(
    "ERROR_BACK",
    error.response.status,
    JSON.stringify(error.response).slice(0, 250),
    "user",
    undefined,
    true
  );
  throw error.response;
}

/**
 * Return the url to be used to request the api.
 * The url is built from the hostname if it is a valid host (i.e ending with cobble-tech.com or murfy.fr),
 * otherwise the default api url is returned.
 */
export function getAPIUrl() {
  return process.env.REACT_APP_API_URL;
}

export function request(config) {
  return axios
    .request(config)
    .then((response) => response.data)
    .catch(checkError);
}

export function getTokenFromLocalStorage() {
  return localStorage.getItem("token");
}

export default request;
