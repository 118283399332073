import React from "react";

import { Box } from "@material-ui/core";

import Button from "components/atoms/Button/Button";
import { Body, H3 } from "components/atoms/Typography";
import BaseModal from "components/molecules/BaseModal/BaseModal";

import { useVisitUpdatePreview } from "utils/hooks/useVisitPreviewUpdate";

type VisitPreviewChangesProps = {
  visitId: number;
  beginning: string;
  end: string;
  employee: number;
  visitType: string;
  visitStatus: string;
  timeNeeded: boolean;
  handleCancel: () => void;
  handleSubmit: () => void;
};

const VisitPreviewChanges: React.FunctionComponent<VisitPreviewChangesProps> = ({
  visitId,
  beginning,
  end,
  employee,
  visitType,
  visitStatus,
  timeNeeded,
  handleCancel,
  handleSubmit,
}: VisitPreviewChangesProps) => {
  const queryParams = {
    beginning: beginning,
    end: end,
    employee: employee,
    visitType: visitType,
    visitStatus: visitStatus,
    timeNeeded: timeNeeded,
  };

  const { data: preview, isLoading: isPreviewLoading, error } = useVisitUpdatePreview(visitId, queryParams);
  return (
    <BaseModal isOpen={true} onClose={handleCancel}>
      <Box>
        <H3 textAlign="center">Aperçu des modifications dans 7opteam</H3>
        <Box m={2}>
          {isPreviewLoading && <p>Chargement...</p>}
          {error && (
            <>
              <p>Le chargement de l'aperçu des modifications à échoué. </p>
              <p>Vous pouvez tout de même valider vos modifications, la mise à jour est indépendante de cet aperçu.</p>
            </>
          )}
          {preview && (
            <Box m={2}>
              {preview.map(
                (diff: { diff: string; name: string; previous_value: string; new_value: string }, index: number) => (
                  <Body>
                    <li key={index}>
                      {diff.name} va changer de <b>{diff.previous_value}</b> à <b>{diff.new_value}</b>.
                    </li>
                  </Body>
                )
              )}
            </Box>
          )}
          {preview && preview.length === 0 && (
            <>
              <p>Les aperçus de ces modifications ne sont pas encore implémentées.</p>
              <p>Vous pouvez tout de même valider vos modifications, la mise à jour est indépendante de cet aperçu.</p>
            </>
          )}
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Button onClick={handleCancel} version="invisible">
            Annuler
          </Button>
          <Button onClick={handleSubmit} version="fancy">
            Valider
          </Button>
        </Box>
      </Box>
    </BaseModal>
  );
};

export default VisitPreviewChanges;
