import { useCallback, useEffect, useState } from "react";

import { Invoice } from "types/invoice";

import { getHeaders } from "utils/headers";

type InvoiceQuery = {
  isSuccess: boolean;
  data: Invoice | undefined;
  error: string | undefined;
};

export const useInvoiceByCreditNoteId = (
  creditNoteId: number,
  enabled: boolean,
  callBack: (items: number[]) => void
) => {
  const [query, setQuery] = useState<InvoiceQuery>({
    isSuccess: false,
    data: undefined,
    error: undefined,
  });
  const [salt, setSalt] = useState(false);
  const refetch = useCallback(() => {
    setSalt((salt) => !salt);
  }, [setSalt]);

  useEffect(() => {
    let abort = false;
    const getInvoiceByCreditNoteId = async () => {
      const token = localStorage.getItem("token");

      const headers = getHeaders(token);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/payment/credit-notes/${creditNoteId}/invoice/`, {
        headers,
      });
      const data: Invoice = await response.json();
      if (!abort) {
        setQuery({
          isSuccess: true,
          data: data,
          error: undefined,
        });
        callBack(data.items.filter((item) => item.price_tax_included > 0).map((item) => item.id));
      }
    };
    if (enabled) {
      getInvoiceByCreditNoteId();
      return () => {
        abort = true;
      };
    }
  }, [refetch, salt, creditNoteId, enabled, callBack]);
  return { ...query, refetch };
};
