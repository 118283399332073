import React from "react";

import { Button, makeStyles } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";

import HomeRepairQuoteElementAssociationModal from "components/molecules/HomeRepairQuoteElementAssociationModal";

import AddPaymentModal from "../../AddPaymentModal";
import ButtonsQuoteAcceptedStyles from "./ButtonsQuoteAcceptedStyles";

const useStyles = makeStyles(ButtonsQuoteAcceptedStyles);

export default function ButtonsQuoteAccepted({
  handleDisplayNewQuoteForm,
  openAddPaymentModal,
  isAddPaymentModalOpen,
  handleClosePaymentModal,
  handleValidatePayment,
  employees,
  visits,
  customerFile,
  amountPaymentIntent,
  totalAmountQuote,
  totalAmountPayment,
  elements,
}) {
  const classes = useStyles();
  const balance = totalAmountQuote - totalAmountPayment;
  const isPayment = balance > 0;
  return (
    <div className={classes.acceptedQuoteButtonWrapper}>
      <HomeRepairQuoteElementAssociationModal elements={elements} customerFile={customerFile} />
      <Button
        id="button-edit-accepted-quote-button"
        variant="contained"
        color={balance === 0 ? "secondary" : ""}
        className={balance === 0 ? classes.addNewQuoteButton : classes.editQuoteButton}
        onClick={handleDisplayNewQuoteForm}
      >
        Modifier le devis {<CreateIcon className={classes.createIcon} />}
      </Button>
      {balance !== 0 ? (
        <Button
          id="payment-quote-button"
          variant="contained"
          color="primary"
          className={classes.buttonPaiementReceived}
          onClick={openAddPaymentModal}
        >
          <AddPaymentModal
            customerFile={customerFile}
            isAddPaymentModalOpen={isAddPaymentModalOpen}
            handleClosePaymentModal={handleClosePaymentModal}
            handleValidatePayment={handleValidatePayment}
            employees={employees}
            visits={visits}
            amountPaymentIntent={Math.abs(balance / 100)}
            isPayment={isPayment}
          />
          {isPayment ? "Paiement encaissé" : "Remboursement effectué"}
        </Button>
      ) : null}
    </div>
  );
}
