const tagsToIgnoreClosure = ["SOGEDEP", "!! RECO !!", "!! SPB !!"];
const statesToIgnoreClosure = ["Annulation", "Abandon"];

/**
 * Returns a boolean that follow a few rules: if the balance account is not 0, then the returned boolean is true
 * except if the customer file has a status or tag that will override this rule.
 * The tags and states that have the power to ignore this rule are declared above.
 * @param {array} customerFilesStates - the list of available customer states
 * @param {object} newCustomerFileStatusId - the id of the selected customerFIleState: {state: id}
 * @param {integer} accountBalance - the account balance of the customer file
 * @param {object} customerFile - the customer file object
 * @param {array} customerFilesTags - the list of available tags

 */
export function closureModalValidation(
  customerFilesStates,
  newCustomerFileStatusId,
  accountBalance,
  customerFile,
  customerFilesTags
) {
  let isClosureModalValidationButtonDisabled = false;
  if (accountBalance && accountBalance !== 0) {
    isClosureModalValidationButtonDisabled = true;
  }
  const actualCustomerFileStateName =
    customerFilesStates &&
    newCustomerFileStatusId &&
    customerFilesStates.find((state) => state.id === parseInt(newCustomerFileStatusId.state)).name;
  if (statesToIgnoreClosure.includes(actualCustomerFileStateName)) {
    isClosureModalValidationButtonDisabled = false;
  }
  let currentTag = "";
  customerFile &&
    customerFilesTags &&
    customerFile.customer_file_tags &&
    customerFile.customer_file_tags.map((associatedTag) => {
      currentTag = customerFilesTags.find((availableTag) => availableTag.id === associatedTag);
      if (currentTag && currentTag.text && tagsToIgnoreClosure.includes(currentTag.text)) {
        isClosureModalValidationButtonDisabled = false;
      }
      return isClosureModalValidationButtonDisabled;
    });
  return isClosureModalValidationButtonDisabled;
}
