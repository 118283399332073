import React from "react";

import CustomerFilesList from "pages/customerFiles/CustomerFilesList";

import { CustomerFileStatesProvider } from "utils/contexts/customerFileStatesContext";
import { EmployeesProvider } from "utils/contexts/employeesContext";

const FilesList = (props: JSX.IntrinsicAttributes) => {
  return (
    <EmployeesProvider>
      <CustomerFileStatesProvider>
        <CustomerFilesList {...props} />
      </CustomerFileStatesProvider>
    </EmployeesProvider>
  );
};

export default FilesList;
