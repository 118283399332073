import * as React from "react";

import { Order } from "types/order";

import ColorChip from "components/atoms/ColorChip";

type OrderStateFieldProps = {
  source: "state" | "file_state";
  record: Order & { file_state?: string };
  label: string;
};
type OrderState = {
  id: string;
  label: string;
  color: string;
};

export const ORDER_STATES: OrderState[] = [
  { id: "CREATED", label: "Commande en cours", color: "#e0e0e0" },
  { id: "PAID", label: "Paiement accepté", color: "#32cd32" },
  { id: "PLANNED", label: "Planifié", color: "#ff8c00" },
  { id: "PREPARING", label: "En cours de préparation", color: "#000000" },
  { id: "PRE_SHIPPING_ISSUE", label: "Annulation avant expé - Problème", color: "#fc3d3a" },
  { id: "SENT", label: "Expédié", color: "#8a2be2" },
  { id: "DELIVERED", label: "Livré", color: "#108510" },
  { id: "EXCHANGE", label: "Echange", color: "#919191" },
  { id: "AFTER_SALES_SERVICE_PRODUCT", label: "Retour - Appareil ou livraison non conforme", color: "#808080" },
  { id: "AFTER_SALES_SERVICE_SHIPPING", label: "SAV transport", color: "#808080" },
  { id: "CANCELLED_ANOMALY", label: "Retour - Panne", color: "#fc3d3a" },
  { id: "CANCELLED_FREE_WITHDRAWAL", label: "Annulation avant expé - Rétractation gratuite", color: "#919191" },
  { id: "CANCELLED_PAYING_WITHDRAWAL", label: "Retour - Rétractation payante", color: "#919191" },
];

export const EXCLUDED_STATES = ["CREATED", "AFTER_SALES_SERVICE_SHIPPING", "EXCHANGE"];

const OrderStateField = ({ source, record, label }: OrderStateFieldProps) => {
  const state = record[source];

  const getColor = (): string => (ORDER_STATES.find((s) => s.id === state) || { color: "#ccc" }).color;
  const getLabel = (): string => (ORDER_STATES.find((s) => s.id === state) || { label: state || "" }).label;

  return <ColorChip label={getLabel()} hexColor={getColor()} />;
};

export default OrderStateField;

OrderStateField.defaultProps = {
  addLabel: true,
};
