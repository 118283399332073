import { toastr } from "react-redux-toastr";

import dataProvider from "dataProvider";

export const mailSendingTaskRequest = (customerFileId, customerFileStatusId, shouldSendClosureEmail, couponAmount) => {
  return dataProvider
    .create("email-actions", {
      data: {
        id: customerFileId,
        customer_file_status_id: customerFileStatusId,
        closure_email: shouldSendClosureEmail,
        coupon_amount: couponAmount,
      },
    })
    .then((response) => {
      let successNotification = "";

      if (response.data.closure_email) {
        successNotification += "Mail envoyé avec succès! ";
      }
      toastr.success("Succès", successNotification);
    })
    .catch((response) => {
      toastr.error("Erreur", `Une erreur s'est produite: ${response.message}`);
    });
};
