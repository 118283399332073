import React from "react";

import { NumberInput, SelectInput, TextInput, required } from "react-admin";

import { Box, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import { makeStyles } from "@material-ui/core/styles";
import Create from "@material-ui/icons/Create";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";

import Modal from "components/Modal";

import AddSparePartNextStepButtonGroup from "pages/customerFiles/components/CustomerFileBillingQuotes/components/elementCreation/AddSparePart/components/AddSparePartNextStepButtonGroup";
import {
  getSparePartBasePriceFromSparePartCost,
  getSparePartPriceWithTax,
} from "pages/customerFiles/components/CustomerFileBillingQuotes/services/sparePartPriceCalculator.js";

import { useSkuSuppliers } from "utils/api";

export default function AddSparePartNoRef({
  handleCloseSparePart,
  sparePartsAlreadyInCart,
  quantity,
  sparePrice,
  isSparePartModalOpen,
  handleValidateSparePart,
  onClickChangeEditabilityOfSparePartPrice,
  setReference,
  setDesignation,
  setBuyingPriceInCents,
  setQuantity,
  setSupplier,
  setForcedSparePartPriceWithTax,
  forcedSparePartPriceWithTax,
  isSparePartPriceEditable,
  spareAswoRef,
  supplier,
  buyingPriceInCents,
}) {
  const classes = useStyles();
  const supplierChoices = useSkuSuppliers();

  const getSellingPrice = () => {
    const basePrice = sparePrice
      ? sparePrice
      : getSparePartBasePriceFromSparePartCost(parseFloat(buyingPriceInCents / 100));
    return getSparePartPriceWithTax(basePrice, quantity, sparePartsAlreadyInCart) * quantity;
  };

  return (
    <Modal
      id="commercial-gesture-modal"
      modalClassName={`modal-small-content`}
      isOpen={isSparePartModalOpen}
      handleCloseModal={handleCloseSparePart}
    >
      <Box py={4}>
        <Typography align="center" variant="h2">
          Demande de pièce
        </Typography>
      </Box>
      <form noValidate autoComplete="off">
        <Box margin="auto" width={360} display="grid">
          {supplierChoices && (
            <SelectInput
              label="Fournisseur"
              variant="outlined"
              margin="none"
              value={supplier.id}
              source={supplier.name}
              choices={supplierChoices}
              onChange={(event) => setSupplier(supplierChoices.find((supplier) => supplier.id === event.target.value))}
            />
          )}
        </Box>

        <Box margin="auto" width={360} display="grid">
          <TextInput
            variant="outlined"
            margin="none"
            name="spare part reference Piece"
            label="Reference de la pièce"
            value={spareAswoRef}
            onChange={(event) => setReference(event.target.value)}
            fullWidth
          />
        </Box>

        <Box margin="auto" width={360} display="grid">
          <TextInput
            variant="outlined"
            margin="none"
            name="spareName"
            onChange={(event) => setDesignation(event.target.value)}
            disableUnderline
            label="Nom de la pièce"
            fullWidth
          />
        </Box>
        {!sparePrice && (
          <Box margin="auto" width={360} display="grid">
            <NumberInput
              label="Coût de la pièce"
              variant="outlined"
              margin="none"
              name="sparePrice"
              onChange={(event) => setBuyingPriceInCents(parseInt(event.target.value) * 100)}
              disableUnderline
              validate={required()}
              value={parseInt(buyingPriceInCents / 100)}
              fullWidth
            />
          </Box>
        )}
        <Box
          margin="auto"
          width={360}
          display="flex"
          justifyContent="space-between"
          py={2}
          borderBottom="2px solid #F4F3F8"
        >
          <Typography variant="body1" className={classes.nameField}>
            Quantité
          </Typography>
          <SelectInput
            label=""
            className={classes.customSelect}
            choices={[
              { id: 1, name: 1 },
              { id: 2, name: 2 },
              { id: 3, name: 3 },
              { id: 4, name: 4 },
              { id: 5, name: 5 },
            ]}
            onChange={(event) => setQuantity(event.target.value)}
            InputProps={{ disableUnderline: true }}
            value={quantity}
            name="Quantity"
          />
        </Box>

        <Box display="flex" justifyContent="space-between" py={2} borderBottom="2px solid #F4F3F8">
          <Typography variant="body1" className={classes.nameField}>
            À encaisser
          </Typography>
          {isSparePartPriceEditable ? (
            <Box width={100} border="1px solid #DBDFE8" borderRadius={8} py={1} px={2}>
              <Input
                id="spare-part-input-override"
                value={forcedSparePartPriceWithTax}
                onChange={(event) => setForcedSparePartPriceWithTax(event.target.value)}
                disableUnderline
                endAdornment={<EuroSymbolIcon />}
              />
            </Box>
          ) : (
            <Typography
              id="spare-part-price-computed-from-quantity"
              variant="body1"
              className={classes.toBeCashedvalueField}
            >
              {getSellingPrice()}
              €&nbsp;
              <IconButton
                id="trigger-edit-spare-part-price"
                className={classes.editSparePartPriceIcon}
                onClick={onClickChangeEditabilityOfSparePartPrice}
              >
                <Create />
              </IconButton>
            </Typography>
          )}
        </Box>
      </form>

      <AddSparePartNextStepButtonGroup
        handleValidate={handleValidateSparePart}
        handleClose={handleCloseSparePart}
        disabled={!sparePrice && !buyingPriceInCents}
      />
    </Modal>
  );
}

const useStyles = makeStyles({
  nameField: {
    color: "#8795B3",
    display: "table-cell",
    alignSelf: "center",
  },
  valueField: {
    color: "#2B3B5C",
  },
  toBeCashedvalueField: {
    color: "#2B3B5C",
    marginLeft: 198,
  },
  editSparePartPriceIcon: {
    width: 35,
    height: 35,
  },
  customSelect: {
    margin: 0,
    minWidth: 56,
    "& p": {
      display: "none",
    },
    ".filled": {
      paddingTop: 12,
    },
  },
});
