export function getEmployeeFullName(employee) {
  return employee ? `${employee.first_name} ${employee.last_name}` : "Tech inconnu";
}

export function repairmenSortedList(employees) {
  return (
    employees &&
    employees
      .filter((employee) => employee.role === "repairman")
      .sort((employee1, employee2) => {
        const name1 = getEmployeeFullName(employee1);
        const name2 = getEmployeeFullName(employee2);
        return name1 > name2 ? 1 : name1 < name2 ? -1 : 0;
      })
  );
}
