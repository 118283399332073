import React from "react";

import { Product } from "types/products";

import Select from "components/atoms/Select";

import { qualificationLabelAsOptions } from "pages/customerFiles/components/CustomerFileRepairReports/components/ListRepairReportLabel.js";

import { useVisit } from "utils/hooks/useVisit";

type RequalificationSelectrops = {
  visitId: number;
  product: Product;
  handleQualificationUpdate(incentive: {
    visit: number;
    product_id: number;
    label: string;
    repairman?: string;
    point_type: string;
  }): void;
};

const RequalificationSelect = ({ visitId, product, handleQualificationUpdate }: RequalificationSelectrops) => {
  const { data: visit, isLoading: visitIsLoading, isError: visitIsError } = useVisit(visitId);

  let productLabelisation;
  try {
    const label = visit && JSON.parse(visit.label);
    productLabelisation = label.filter((element: { id: number; label: string }) => element.id === product.id)[0].label;
  } catch (error) {
    productLabelisation = (visit && visit.label) || ""; // can be a plain string because of the legacy data
  }
  const [selectedValue, setSelectedValue] = React.useState((productLabelisation && productLabelisation.label) || "");
  if (visitIsLoading) {
    return <div>Chargement...</div>;
  }
  if (visitIsError || !visit) {
    return <div>Erreur lors du chargement de la qualification</div>;
  }

  if (!visit.label) {
    return <div> Passage non qualifié </div>;
  }

  const onChangeQualification = (event: React.ChangeEvent<{ value: string | unknown }>) => {
    setSelectedValue(event.target.value as string);
    handleQualificationUpdate({
      visit: visitId,
      product_id: product.id,
      label: event.target.value as string,
      repairman: visit.assigned_repairman,
      point_type: "label",
    });
  };

  return (
    <Select
      defaultValue={selectedValue || productLabelisation}
      options={qualificationLabelAsOptions}
      label="Qualification du passage / produit"
      onChange={onChangeQualification}
    />
  );
};

export default RequalificationSelect;
