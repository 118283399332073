import React, { Component } from "react";

import PropTypes from "prop-types";

import "./style.css";

export default class Page extends Component {
  static propTypes = {
    hasBlueBackground: PropTypes.bool,
  };

  static defaultProps = {
    hasBlueBackground: true,
  };

  render() {
    return (
      <div className={"page-container" + (this.props.hasBlueBackground ? " admin-blue-background" : "")}>
        {this.props.children}
      </div>
    );
  }
}
