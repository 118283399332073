import React from "react";

import { Toolbar } from "react-admin";

import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    backgroundColor: "white",
    position: "absolute",
    bottom: 12,
    left: "50%",
    transform: "translateX(-50%)",
  },
}));

export default ({ handleSaveAndClose, ...props }) => {
  const classes = useStyles();

  return (
    <Toolbar {...props} classes={{ toolbar: classes.toolbar }}>
      <Button variant="contained" color="primary" onClick={handleSaveAndClose}>
        Confirmer
      </Button>
    </Toolbar>
  );
};
