import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { get } from "lib/http";
// Mettez à jour le chemin d'importation pour correspondre à l'emplacement de votre fichier datalayer
import { Timeslot } from "types/timeslots";

interface TimeslotsQueryParams {
  start_date: string;
  end_date: string;
  employee_id?: string;
  type: string;
  minimum_capacity: number;
  postal_code?: string;
  city?: string;
  street?: string;
  street_number?: string;
  has_cold_appliance?: boolean;
  beginning: string;
  end: string;
  employee?: string;
}

interface TimeslotsSevenOpteamResponseType {
  timeslots: Timeslot[];
  next_timeslot: Timeslot | null;
}
export const useTimeslots = (queryParams: TimeslotsQueryParams | null) => {
  const fetchSevenOpteamTimeslots = async (queryParams: TimeslotsQueryParams) => {
    const baseUrl = "/route-management/generic-appointment-timeslots/";
    const url = `${baseUrl}?${new URLSearchParams(queryParams as Record<any, any>).toString()}`;
    const response: TimeslotsSevenOpteamResponseType = await get(url);
    return response.timeslots;
  };

  const { data, isLoading, isError, error } = useQuery<
    Timeslot[],
    AxiosError | null,
    Timeslot[],
    (string | TimeslotsQueryParams | null)[]
  >(
    ["widget-timeslots-generic", queryParams],
    async ({ queryKey }) => {
      const params = queryKey[1];
      if (typeof params === "string" || params === null) {
        throw new Error("Invalid Query Key");
      }
      return fetchSevenOpteamTimeslots(params);
    },
    {
      enabled: !!queryParams,
    }
  );
  return { data, isLoading, isError, error };
};
