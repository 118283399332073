import React, { Component } from "react";

import { PropTypes } from "prop-types";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import "./style.css";

export default class Tag extends Component {
  static propTypes = {
    tagId: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    isDeleteDisplayed: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool.isRequired,
    handleClickIcon: PropTypes.func,
    handleClickTag: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      isAlertOpen: false,
    };
  }

  clickHandlerTag = (event) => {
    this.props.handleClickTag(this.props.tagId);
  };

  clickHandlerIcon = (event) => {
    this.setState({ isAlertOpen: true });
    event.stopPropagation();
  };

  handleAlertCancel = () => {
    this.setState({ isAlertOpen: false });
  };

  handleAlertValidation = (event) => {
    this.props.handleClickIcon(this.props.tagId);
    this.setState({ isAlertOpen: false });
    event.stopPropagation();
  };

  render() {
    return (
      <div className="tag-container">
        <span
          className="tag"
          style={{
            backgroundColor: this.props.color,
          }}
          onClick={this.props.handleClickTag ? this.clickHandlerTag : null}
        >
          {this.props.text}
          {this.props.isSelected && (
            <span id="checkIcon" aria-hidden="true" className="fas fa-check selected-tag-icon mt-1" />
          )}
        </span>
        {this.props.isDeleteDisplayed && (
          <i id="deleteIcon" className="fal fa-trash-alt ico delete-tag-icon" onClick={this.clickHandlerIcon} />
        )}
        <Dialog
          open={this.state.isAlertOpen}
          onClose={this.handleAlertCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Suppression de tag</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Voulez-vous réellement supprimer le tag <span className="tag-alert-name">{`${this.props.text}`}</span> ?
              <br />
              <span className="tag-alert-subtext">
                Cela entrainera la supression de ce tag dans tous les dossiers sur lesquels il est attribué.
              </span>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleAlertCancel} color="primary">
              Annuler
            </Button>
            <Button id="alert-confirmation-button" onClick={this.handleAlertValidation} color="primary" autoFocus>
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
