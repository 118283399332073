import React from "react";

import { Box, Checkbox, Divider, IconButton, styled } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { Form, Formik } from "formik";
import { CustomerFileDetailed } from "types/customer-files";
import { Employee } from "types/employees";
import { Timeslot } from "types/timeslot";
import * as yup from "yup";

import Button from "components/atoms/Button";
import Loading from "components/atoms/Loading";
import { Body, H2 } from "components/atoms/Typography";
import BaseModal from "components/molecules/BaseModal";
import EmployeeField from "components/molecules/EmployeeField";
import VisitTypeField from "components/molecules/VisitTypeField";

import { useRepairmen } from "utils/api";
import { useMultipleTimeslotSelector } from "utils/hooks/useMultipleTimeslotSelector";

import SuggestCalendar from "./components/SuggestCalendar";

const ModalHeader = styled(Box)({
  boxShadow: "inset 0px -1px 0px #D7D7D7",
});
const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  "&.Mui-checked": {
    color: theme.palette.primary.main,
  },
}));

type FormValues = {
  visit_type: string;
  employee: string | undefined;
};
const compareDate = (current: string, prev: string) => {
  if (current > prev) return -1;
  if (prev > current) return 1;
  return 0;
};

type SuggestTimeslotProps = {
  closeModal: () => void;
  isDialogOpen: boolean;
  handleSubmit: (timeslots: Timeslot[], employeeId: string, visitType: string) => Promise<void>;
  minimumCapacity: number;
  handleKeepTimeslot: () => void;
  keepTimeslot: boolean;
  displayKeepTimeslot?: boolean;
  customerFile: CustomerFileDetailed;
  visitType: string;
  assignedRepairman: string | undefined;
};

const SuggestTimeslot: React.FC<SuggestTimeslotProps> = ({
  closeModal,
  isDialogOpen,
  handleSubmit,
  minimumCapacity,
  handleKeepTimeslot,
  keepTimeslot,
  displayKeepTimeslot = true,
  customerFile,
  visitType,
  assignedRepairman,
}) => {
  const { selectedTimeslots, handleTimesotChange } = useMultipleTimeslotSelector();

  const repairmen = useRepairmen();

  const lastVisit = customerFile.visits.sort((slot1, slot2) =>
    compareDate(slot1.timeslot.beginning, slot2.timeslot.beginning)
  )[0];

  const getLastAssignedRepairmanId = (repairmanId: string | undefined) => {
    if (!repairmanId) return;
    const repairman =
      repairmen &&
      repairmen.find((repairman: Employee) => repairman.id === parseInt(repairmanId) && repairman.role === "repairman");
    if (!repairman) return;
    return repairman.id.toString();
  };
  const lastVisitType = lastVisit ? lastVisit.visit_type : "";
  const lastAssignedRepairman = lastVisit ? getLastAssignedRepairmanId(lastVisit.assigned_repairman) : "";

  const initialValues: FormValues = {
    visit_type: visitType || lastVisitType,
    employee: assignedRepairman || lastAssignedRepairman || "",
  };

  const validationSchema = yup.object().shape({
    visit_type: yup.string().required("Le type de visite est obligatoire"),
    employee: yup.string().test({
      name: "employee",
      test: () => {
        return true;
      },
      message: "Le technicien est obligatoire",
    }),
  });

  const sendProposalTimeslot = async (values: FormValues) => {
    await handleSubmit(selectedTimeslots, values.employee!, values.visit_type);
  };
  const onSelect = (timeslot: Timeslot) => handleTimesotChange(timeslot);
  return (
    <BaseModal isOpen={isDialogOpen} onClose={closeModal}>
      <Box>
        <Box display="flex" justifyContent="end">
          <IconButton onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </Box>
        <ModalHeader width="100%" justifyContent="center" textAlign="center" paddingY={3}>
          <H2 color="primary.main">Proposition de rendez-vous</H2>
        </ModalHeader>
        <Divider />

        <Formik
          initialValues={initialValues}
          onSubmit={sendProposalTimeslot}
          validationSchema={validationSchema}
          validateOnMount={true}
        >
          {({ isSubmitting, isValid }) => (
            <Form translate={null}>
              <Box m={2}>{repairmen ? <EmployeeField repairmen={repairmen} /> : <Loading />}</Box>
              <Box m={2}>
                <VisitTypeField />
              </Box>
              <Box m={2}>
                <Box mt={1} mb={1}>
                  <Body color="primary.main"> Sélectionnez un ou plusieurs créneaux à proposer au client :</Body>
                </Box>
                <SuggestCalendar
                  minimumCapacity={minimumCapacity}
                  onSelect={onSelect}
                  isSelected={(timeslot: Timeslot) => selectedTimeslots.map((item) => item.id).includes(timeslot.id)}
                  customerFile={customerFile}
                />
              </Box>
              {displayKeepTimeslot && (
                <Box display="flex" width="100% !important" justifyContent="center" alignItems="center">
                  <StyledCheckbox checked={keepTimeslot} onChange={handleKeepTimeslot} />
                  <Body color="main">
                    En cas d’acceptation du nouveau créneau par le client, le créneau initial sera supprimé
                  </Body>
                </Box>
              )}
              <Box m={2}>
                <Body color="red">
                  nb. Si le champ technicien n’est pas renseigné, alors 7opteam allouera l’intervention au technicien
                  qui lui semble être le plus pertinent
                </Body>
              </Box>

              <Box display="flex" justifyContent="end">
                <Button
                  disabled={isSubmitting || !isValid || !selectedTimeslots.length}
                  dimension="small"
                  version="fancy"
                  type="submit"
                >
                  ENVOYER LA PROPOSITION
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </BaseModal>
  );
};

export default SuggestTimeslot;
