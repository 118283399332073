import React, { useState } from "react";

import { Box, TextField, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { makeStyles } from "@material-ui/core/styles";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";

import Modal from "components/Modal";

import { getPricePretax } from "utils/price";

const editPackageWrapper = {
  editPackageWrapper: {
    height: "64px",
    borderBottom: "2px solid #F4F3F8",
    textAlign: "left",
    marginBottom: "24px",
    paddingTop: "18px",
    display: "contents",
  },
  modalTitle: {
    textAlign: "center",
    padding: "37px 0px",
  },
  buttonGroup: {
    textAlign: "center",
    paddingTop: 40,
  },
  cancelButton: {
    color: "#8795B3",
    backgroundColor: "unset",
    boxShadow: "unset",
    marginLeft: 16,
  },
  radioButtonGroup: {
    border: "1px solid #DBDFE8",
    borderRadius: 8,
    padding: 24,
  },
  customInputWrapper: {
    display: "flex",
  },
  customInputAmount: {
    position: "absolute",
    left: 80,
    paddingTop: 8,
  },
  inputCustomAmount: {
    width: 100,
    position: "absolute",
    top: 0,
  },
  euroIcon: {
    color: "#8795B3",
    height: 20,
  },
  inputPackageName: {
    paddingRight: 3,
  },
  label: { width: "100%", "& > span:last-child": { width: "100%" }, "&:hover": { backgroundColor: "#F4F3F8" } },
};

const useStyles = makeStyles(editPackageWrapper);

export default function PackageEditionModal(props) {
  const classes = useStyles();
  const [selectedPackageType, setSelectedPackageType] = useState("");
  const [customizedAmount, setCustomizedAmount] = useState(0);

  const getPackagePrices = (packageType) => {
    let amount_pretax = packageType.amount_pretax;
    let amount_withtax = packageType.amount_withtax;
    if (selectedPackageType.package_name === "Forfait B2B") {
      amount_pretax = getPricePretax(customizedAmount * 100);
      amount_withtax = Math.ceil(customizedAmount * 100);
    }
    return { pretax: amount_pretax, withtax: amount_withtax };
  };

  const getPackagePriceDisplay = (packageType) => {
    return (getPackagePrices(packageType).withtax / 100).toFixed(2);
  };

  const getPackageDisplay = (type) => {
    const packageDisplay = (
      <Box display="flex" justifyContent="space-between" width="100%">
        <span>{type.package_name}</span>
        <span>{getPackagePriceDisplay(type)} €</span>
      </Box>
    );
    return type === selectedPackageType ? <b>{packageDisplay}</b> : packageDisplay;
  };

  const handleSubmitNewPackage = () => {
    // Delete the package to replace it by a new one
    // Must delete first in case of a new package modification to avoid the deletion of the one created
    props.removeElementInQuote(props.packageId);
    props.addNewElementsInQuote([
      {
        element_type: "basic_package",
        element_name: selectedPackageType.package_name,
        amount_pretax: getPackagePrices(selectedPackageType).pretax,
        amount_withtax: getPackagePrices(selectedPackageType).withtax,
        customer_file: props.customer_file,
        quote_billing: null,
        package_type: selectedPackageType.id,
        home_repair_product: props.productId,
      },
    ]);
    props.handleCloseModal();
  };
  return (
    <div className={classes.editPackageWrapper}>
      <Modal
        id="package-edition-modal"
        modalClassName="modal-medium-content"
        isOpen={props.isOpen}
        handleCloseModal={props.handleCloseModal}
      >
        <Typography className={classes.modalTitle} variant="h2">
          Type de forfait
        </Typography>
        <div className={classes.radioButtonGroup}>
          <RadioGroup>
            {props.packageTypeList &&
              props.packageTypeList.map((type) => (
                <div key={type.id}>
                  {type.package_name === "Forfait B2B" && type === selectedPackageType ? (
                    <div className={classes.customInputWrapper}>
                      <FormControlLabel
                        onClick={() => setSelectedPackageType(type)}
                        checked={type === selectedPackageType}
                        value={type}
                        key={type.id}
                        control={<Radio />}
                      />
                      <div className={classes.customInputAmount}>
                        <span className={classes.inputPackageName}>{`${type.package_name} : `}</span>
                        <TextField
                          className={classes.inputCustomAmount}
                          id="amount-input"
                          value={customizedAmount}
                          onChange={(e) => setCustomizedAmount(e.target.value)}
                          variant="outlined"
                          InputProps={{
                            style: { height: 40, color: "#2B3B5C" },
                            endAdornment: <EuroSymbolIcon className={classes.euroIcon} />,
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <FormControlLabel
                      className={classes.label}
                      onClick={() => setSelectedPackageType(type)}
                      checked={type === selectedPackageType}
                      value={type}
                      key={type.id}
                      control={<Radio />}
                      label={getPackageDisplay(type)}
                    />
                  )}
                </div>
              ))}
          </RadioGroup>
        </div>
        <div className={classes.buttonGroup}>
          <Button
            id="validate"
            variant="contained"
            color="secondary"
            onClick={handleSubmitNewPackage}
            disabled={isNaN(customizedAmount)}
          >
            Valider le forfait
          </Button>
          <Button id="cancel" variant="contained" className={classes.cancelButton} onClick={props.handleCloseModal}>
            Annuler
          </Button>
        </div>
      </Modal>
    </div>
  );
}
