import * as React from "react";

import { Box } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";

import OrderLineCancelButton from "components/OrderShow/components/OrderLine/OrderLineCancelButton";
import { shouldOrderLineBeDisabled } from "components/OrderShow/utils";

const OrderLineActions = ({ record: orderLine }) => {
  const disabled = shouldOrderLineBeDisabled(orderLine);

  return (
    <Box>
      <OrderLineCancelButton orderLines={[orderLine]} startIcon={<CancelIcon />} disabled={disabled}>
        Annuler
      </OrderLineCancelButton>
    </Box>
  );
};

export default OrderLineActions;
