import { call, put } from "redux-saga/effects";

import { getAPIUrl, getTokenFromLocalStorage, request } from "utils/networking";

export function* getEmployees(onSuccessAction, onErrorAction, action) {
  const token = yield call(getTokenFromLocalStorage);
  const config = {
    headers: {
      Authorization: "Token " + token,
    },
    method: "get",
    url: `${getAPIUrl()}/common/employee/`,
  };
  try {
    const employees = yield call(request, config);
    yield put(onSuccessAction(employees));
  } catch (error) {
    yield put(onErrorAction(error));
  }
}
