export const SAVE_QUOTE_EDITION_SESSION = "NewQuoteForm/SAVE_QUOTE_EDITION_SESSION";
export const RESET_QUOTE_EDITION_SESSION = "NewQuoteForm/RESET_QUOTE_EDITION_SESSION";

export function saveCurrentEditionSession(editionSessionData) {
  return {
    type: SAVE_QUOTE_EDITION_SESSION,
    payload: {
      editionSession: editionSessionData,
    },
  };
}

export function resetCurrentEditionSession() {
  return {
    type: RESET_QUOTE_EDITION_SESSION,
  };
}
// initial state
const initialState = {
  editionSession: null,
};
// reducer
export function reducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_QUOTE_EDITION_SESSION:
      return {
        ...state,
        editionSession: action.payload.editionSession,
      };
    case RESET_QUOTE_EDITION_SESSION:
      return {
        ...state,
        editionSession: null,
      };
    default:
      return state;
  }
}

export default reducer;
