import React from "react";

import { IconButton } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

import { toastr } from "react-redux-toastr";

const CopyClipboard = ({ info, successMessage, title, Icon }) => {
  const clipboard = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(info);
    toastr.success("Succès", successMessage);
  };
  const classes = useStyle();
  return (
    <IconButton onClick={(e) => clipboard(e)} className={classes.iconButton}>
      <Tooltip title={title}>
        <Icon className={classes.icon} />
      </Tooltip>
    </IconButton>
  );
};

export default CopyClipboard;

const useStyle = makeStyles((theme) => ({
  iconButton: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    marginLeft: 8,
  },
  icon: {
    fontSize: 20,
  },
}));
