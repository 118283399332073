import React, { Component } from "react";

import { Admin, Resource } from "react-admin";

import { ThemeProvider } from "@material-ui/core/styles";
import AssignmentIcon from "@material-ui/icons/Assignment";
import HomeIcon from "@material-ui/icons/Home";
import ListAlt from "@material-ui/icons/ListAlt";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

import { QueryClient } from "@tanstack/react-query";
import { QueryClientProvider } from "@tanstack/react-query";
import polyglotI18nProvider from "ra-i18n-polyglot";
import defaultMessages from "ra-language-english";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import { PersistGate } from "redux-persist/integration/react";

import OrderEdit from "components/OrderEdit";
import OrderList from "components/OrderList";
import OrderShow from "components/OrderShow";

import PackageList from "pages/PackagePrice/PackageList";
import SkuDemandList from "pages/SkuStore/Components/SkuDemandList";
import SparePartList from "pages/SkuStore/Components/SparePartList";
import CustomerFileCreationForm from "pages/customerFiles/FileCreate/CustomerFileCreate/CustomerFileCreateForm";
import FilesList from "pages/customerFiles/FilesList";

import customAdminRoutes from "./CustomAdminRoutes";
import theme from "./customTheme";
import MurfyLayout from "./layout/MurfyLayout";
import MurfyMenu from "./layout/MurfyMenu";

polyglotI18nProvider((locale) => {
  return defaultMessages;
});

class App extends Component {
  componentDidMount() {
    /* Set the scrollRestoration to manual to avoid browsers to restore the previous scroll
     * position when navigating through the app
     */
    if ("scrollRestoration" in window.history) {
      // Back off, browser, I got this...
      window.history.scrollRestoration = "manual";
    }
  }

  render() {
    const queryClient = new QueryClient();
    return (
      <Provider store={this.props.store}>
        <QueryClientProvider client={queryClient}>
          <PersistGate persistor={this.props.persistor}>
            <Helmet defaultTitle="Admin Murfy" />
            <ThemeProvider theme={theme}>
              <Admin
                layout={MurfyLayout}
                customRoutes={customAdminRoutes}
                menu={MurfyMenu}
                theme={theme}
                authProvider={this.props.authProvider}
                dataProvider={this.props.dataProvider}
                history={this.props.history}
              >
                <Resource name="account" />
                <Resource name="account-balance" />
                <Resource name="billing-quotes" />
                <Resource name="billing-elements" />
                <Resource name="closure-mail-template" />
                <Resource name="collection-files" />
                <Resource name="comments" />
                <Resource name="customer-file" />
                <Resource
                  name="customer-files"
                  create={() => (
                    <CustomerFileCreationForm
                      onSuccess={(file_number) => window.open(`/customer-files/${file_number}`)}
                    />
                  )}
                  list={FilesList}
                  options={{ label: "Dossiers Clients" }}
                  icon={HomeIcon}
                />
                <Resource name="customer-files-slim" />
                <Resource
                  name="customer-files-to-process"
                  list={FilesList}
                  options={{ label: "Mes actions de CET" }}
                  icon={AssignmentIcon}
                />
                <Resource name="deliveries" />
                <Resource name="deliveries-csv" />
                <Resource name="delivery-CCV" />
                <Resource name="demand-item" />
                <Resource
                  name="ecom/orders"
                  list={OrderList}
                  edit={OrderEdit}
                  show={OrderShow}
                  options={{ label: "Commandes reco" }}
                  icon={ListAlt}
                />
                <Resource name="package-price" list={PackageList} options={{ label: "Édition forfaits RD" }} />
                <Resource name="deprecated-spare-parts" />
                <Resource name="ecom/refunds" />
                <Resource name="email-actions" />
                <Resource name="employees" />
                <Resource name="installation-types" />
                <Resource name="fault-forecast" />
                <Resource name="fault-forecast-report" />
                <Resource name="location" />
                <Resource name="package" />
                <Resource name="paymentbilling-history" />
                <Resource name="products" />
                <Resource name="product-brands" />
                <Resource name="product-models" />
                <Resource name="product-types" />
                <Resource name="zone" />
                <Resource name="recondition-files" />
                <Resource name="send-next-intervention-planning-email" />
                <Resource name="send-temporisation-email" />
                <Resource name="shipping-providers" />
                <Resource name="shipping/deliveries" />
                <Resource name="skus" />
                <Resource name="sku-demand" list={SkuDemandList} />
                <Resource name="sku-location" />
                <Resource name="sku-log" />
                <Resource name="sku-models" />
                <Resource name="sku-model-references" />
                <Resource name="sku-suppliers" />
                <Resource name="sku-supplying" />
                <Resource name="spare-parts" list={SparePartList} />
                <Resource name="tickets" />
                <Resource name="ticket-tags" />
                <Resource name="ticket-states" />
                <Resource name="timeslot-booking" />
                <Resource name="visits" />
                <Resource name="visit-preparations" />
                <Resource name="workshop" />
                <Resource name="workshop-files" options={{ label: "Reconditionné" }} icon={ShoppingCartIcon} />
                <Resource name="workshop-file-tags" />
                <Resource name="workshop-tasks" />
              </Admin>
            </ThemeProvider>
            <ReduxToastr
              timeOut={4000}
              newestOnTop
              preventDuplicates
              position="top-right"
              transitionIn="fadeIn"
              transitionOut="fadeOut"
            />
          </PersistGate>
        </QueryClientProvider>
      </Provider>
    );
  }
}

export default App;
