import React, { Component } from "react";

import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    display: "flex",
    justifyContent: "flex-end",
  },
  actionButton: {
    color: "#384760",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    fontSize: "14px",
    height: "100%",
    justifyContent: "space-around",
    padding: "10px",
    textAlign: "center",
    width: "100px",
    "&:hover": {
      background: "#f1f1f1",
    },
  },
};

export class TicketTargetStatesInput extends Component {
  static propTypes = {
    classes: PropTypes.object,
    ticketId: PropTypes.number,
    ticketStateId: PropTypes.number,
    ticketStates: PropTypes.array,
    updateTicketDetail: PropTypes.func,
  };

  static defaultProps = {
    classes: {},
  };

  getTicketTargetStates(currentStateId, states = []) {
    const currentState = states.find((state) => state.id === currentStateId) || {};
    return currentState.transitions || [];
  }

  handleClick = (targetStateId) => () => {
    this.props.updateTicketDetail(this.props.ticketId, { ticket_state: targetStateId });
  };

  render() {
    const { classes } = this.props;
    const targetStates = this.getTicketTargetStates(this.props.ticketStateId, this.props.ticketStates);
    return (
      <div className={`col4 ${classes.root}`}>
        {targetStates
          .sort((state1, state2) => state1.order > state2.order)
          .map((targetState) => (
            <div
              key={targetState.id}
              className={classes.actionButton}
              onClick={this.handleClick(targetState.next_state)}
            >
              <i className={`${targetState.icon} ico ico-large`} />
              <span>{targetState.text}</span>
            </div>
          ))}
      </div>
    );
  }
}

export default withStyles(styles)(TicketTargetStatesInput);
