import * as React from "react";
import { useState } from "react";

import { SaveButton, SelectInput, TextInput, Toolbar, required } from "react-admin";
import { SimpleForm } from "react-admin";

import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import dataProvider from "dataProvider";

const formStyles = {
  toolbar: {
    backgroundColor: "#FFFFFF",
    justifyContent: "center",
  },
};

const useStyles = makeStyles(formStyles);

const InnerForm = ({ create, setBarcode, errorMessage, foundProduct, workshopFileId, warrantyOptions }) => {
  const [workshopFile, setWorkshopFile] = useState();
  const classes = useStyles();

  React.useEffect(() => {
    if (workshopFileId) {
      dataProvider.getOne("workshop-files", { id: workshopFileId }).then((response) => {
        if (response) {
          setWorkshopFile(response.data);
        }
      });
    } else {
      setWorkshopFile();
    }
  }, [workshopFileId]);

  const isAvailable = (state) => {
    return ["in_stock", "online"].includes(state);
  };
  const getProductName = (product) => {
    return (
      <>
        {product.brand} {product.model_reference} <br /> {product.product_type} <br /> {product.subcategory1}{" "}
        {product.installation_type ? product.installation_type.name : ""} <br /> prix:{" "}
        {product.selling_price_withtax ? parseFloat(product.selling_price_withtax).toFixed(2) / 100 : ""} €
      </>
    );
  };

  const missingData = (product) => {
    let missingValues = "";
    if (!product.selling_price_withtax) {
      missingValues += "Prix de vente ";
    }
    if (!product.product_type) {
      missingValues += "Type de produit ";
    }
    return missingValues;
  };
  return (
    <>
      <SimpleForm
        width="100%"
        redirect={false}
        toolbar={
          <Toolbar classes={{ toolbar: classes.toolbar }}>
            <SaveButton
              label="Ajouter le produit"
              onSave={create}
              size="medium"
              disabled={
                !foundProduct ||
                (workshopFile && missingData(workshopFile.product) !== "") ||
                !(workshopFile && workshopFile.state && isAvailable(workshopFile.state))
              }
            />
          </Toolbar>
        }
        save={create}
      >
        <Box width="100%" display="flex">
          <Box width="300px" mr={1}>
            <TextInput
              fullWidth
              validate={[required()]}
              label="Référence produit"
              source="barcode"
              onChange={(e) => setBarcode(e.target.value)}
            />
          </Box>
          <Box width="300px">
            <SelectInput
              fullWidth
              validate={[required()]}
              source="warranty_name"
              optionText="name"
              optionValue="name"
              label="Garantie"
              choices={warrantyOptions}
            />
          </Box>
        </Box>
        {workshopFile && workshopFile.product && (
          <Box width="100%">
            <Box display="flex" justifyContent="space-around">
              {workshopFile.product.selling_picture_1 && workshopFile.product.selling_picture_1 !== "" && (
                <Box maxWidth="200px" maxHeight="200px">
                  <img
                    width="200px"
                    height="200px"
                    alt="preview"
                    title="Preview"
                    src={workshopFile.product.selling_picture_1}
                  />
                </Box>
              )}
              <Box height="100%" margin="auto">
                <Typography variant="subtitle1">Informations produit:</Typography>
                <Typography>{getProductName(workshopFile.product)}</Typography>
              </Box>
            </Box>
            {!isAvailable(workshopFile.state) && (
              <Box my={2}>
                <Typography color="error">
                  Ce produit n'est pas disponible (dossier {workshopFile.file_number}), statut {workshopFile.state_name}
                </Typography>
              </Box>
            )}
            {missingData(workshopFile.product) !== "" && (
              <Box my={2}>
                <Typography color="error">
                  Ce produit n'est pas disponible, il manque: {missingData(workshopFile.product)}
                </Typography>
              </Box>
            )}
          </Box>
        )}

        <Typography textAlign="center" color="error">
          {errorMessage}
        </Typography>
      </SimpleForm>
    </>
  );
};

const CreateTransactionForm = ({ create, setBarcode, errorMessage, foundProduct, warrantyOptions }) => {
  return (
    <>
      <Box width={700} px={3} my={2}>
        <Typography variant="h2">
          <Box textAlign="center">Ajout de produit</Box>
        </Typography>
        <InnerForm
          create={create}
          setBarcode={setBarcode}
          errorMessage={errorMessage}
          foundProduct={foundProduct}
          workshopFileId={(foundProduct && foundProduct.workshop_file) || null}
          warrantyOptions={warrantyOptions}
        />
      </Box>
    </>
  );
};

export default CreateTransactionForm;
