import React from "react";

import { stringify } from "query-string";

import { useWorkshopFilesCount } from "utils/api";

import MurfySubMenuItem from "./MurfySubMenuItem";

export const getWorkshopFileMenuLink = (resource, filter) => {
  return {
    pathname: `/${resource}`,
    search: stringify({
      filter: JSON.stringify(filter),
      order: "DESC",
      page: 1,
      perPage: 20,
      sort: "id",
    }),
  };
};

export default ({ resource = "workshop-files", filter, ...props }) => {
  const count = useWorkshopFilesCount(filter);

  return <MurfySubMenuItem numberDisplayed={count} link={getWorkshopFileMenuLink(resource, filter)} {...props} />;
};
