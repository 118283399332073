export class SparePartStatus {
  static get ORDERED() {
    return "ordered";
  }
  static get ORDERED_ROTATING_STOCK() {
    return "ordered_rotating_stock";
  }
  static get INSTOCK() {
    return "instock";
  }
  static get RESERVED() {
    return "reserved";
  }
  static get PACKED() {
    return "packed";
  }
  static get RECEIVED_TECH() {
    return "received_tech";
  }
  static get RECEIVED_WRONG_TECH() {
    return "received_wrong_tech";
  }
  static get INSTALLED() {
    return "installed";
  }
  static get LOST() {
    return "lost";
  }
  static get RETURN_TO_WAREHOUSE() {
    return "return_to_warehouse";
  }
}

export const SPARE_PART_STATUS_CHOICES = [
  { id: SparePartStatus.ORDERED, name: "Commandé", disabled: true },
  { id: SparePartStatus.RESERVED, name: "Réservé", disabled: true },
  { id: SparePartStatus.INSTOCK, name: "En stock", disabled: true },
  { id: SparePartStatus.PACKED, name: "Colisé" },
  { id: SparePartStatus.RECEIVED_TECH, name: "Reçu Technicien" },
  { id: SparePartStatus.RECEIVED_WRONG_TECH, name: "Reçu Mauvais Technicien" },
  { id: SparePartStatus.INSTALLED, name: "Posé" },
  { id: SparePartStatus.LOST, name: "Perdu" },
  { id: SparePartStatus.RETURN_TO_WAREHOUSE, name: "A retourner à l'atelier", disabled: true },
];
