import React, { useEffect, useState } from "react";

import { useNotify, useRefresh, useUpdate } from "react-admin";

import { BrowserRouter as Router } from "react-router-dom";

import { Box, CircularProgress, withStyles } from "@material-ui/core";
import WhatshotIcon from "@material-ui/icons/Whatshot";

import dataProvider from "dataProvider";

import CustomerFileStateSelectInput from "components/CustomerFileStateSelectInput";
import CustomerFileTitleField from "components/CustomerFileTitleField";
import EmployeesAssignmentModal from "components/EmployeesAssignmentModal";
import AfterSalesIndicator from "components/atoms/AfterSalesIndicator";
import EmployeesAvatarsField from "components/employeesAvatarsField";
import CustomerFileDetailsCard from "components/molecules/CustomerFileDetailsCard";

import CustomerFileTabs from "pages/customerFiles/CustomerFileTabs";

import CustomerFileTagsField from "../CustomerFilesTags/CustomerFileTagsField";
import CustomerFileClosureModal from "../components/CustomerFileClosureModal/CustomerFileClosureModal";
import RepairReportChecks from "../components/RepairReportChecks";

const styles = () => ({
  titleStyle: {
    color: "#102D69",
    display: "inline",
    margin: "0px 24px",
  },
  employeesAssignmentWrapper: {
    display: "flex",
    alignItems: "center",
  },
  customerFileDetailWrapper: {
    background: "#F4F3F8",
    flexGrow: 1,
    overflow: "auto",
    maxWidth: "calc(100% - 340px)",
  },
  customerFileHeaderWrapper: {
    alignItems: "center",
    background: "#F4F3F8",
    display: "flex",
    justifyContent: "space-between",
    padding: "24px 0 12px",
    width: "100%",
  },
  customerFileHeaderTitleWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  customerFileHotStatus: {
    marginLeft: "8px",
    marginTop: "8px",
    marginRight: 16,
    "& :hover": {
      color: "#2B3956",
    },
  },
  whatshotIconStyleActivated: {
    color: "#E95945",
    width: 32,
    height: 32,
  },
  whatshotIconStyleDesactivated: {
    color: "#E6E6E6",
    width: 32,
    height: 32,
  },
  employeesAvatarsWrapper: {
    marginLeft: 11,
    display: "flex",
    alignItems: "center",
  },
  bottomLineWrapper: {
    background: "#F4F3F8",
    display: "flex",
    justifyContent: "space-between",
    padding: 12,
  },
  loaderContainer: {
    width: "100%",
    height: "100%",
    paddingTop: "45%",
    boxSizing: "border-box",
    textAlign: "center",
  },
});

export const CustomerFileDetail = ({
  fileNumber,
  customerFilesStates,
  customerFilesTags,
  employees,
  getEmployees,
  idEmployee,
  classes,
  updateTicketDetail,
  createIncentiveSystem,
  changeCustomerFileStatusSparePart,
  deleteCustomerFilesTag,
  postCustomerFilesTag,
  isTagSuccessfullySubmitted,
  resource,
  defaultTab,
}) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const [customerFile, setCustomerFile] = useState(null);
  const [isCustomerFileClosureModalOpen, setIsCustomerFileClosureModalOpen] = useState(false);
  const [newCustomerFileStateId, setNewCustomerFileStateId] = useState(null);
  const [shoudlMailSelectorsBeDisabled, setShoudlMailSelectorsBeDisabled] = useState(false);
  const [isCancellingStatus, setIsCancellingStatus] = useState(false);
  const [updateFile] = useUpdate("customer-files");

  const getCustomerFile = (file_number) => {
    dataProvider.getOne("customer-file", { id: file_number }).then((response) => {
      if (response) {
        setCustomerFile(response.data);
      }
    });
  };
  const updateCustomerFile = (id, data) => {
    updateFile(
      {
        payload: {
          id: id,
          data: data,
        },
      },
      {
        onSuccess: () => {
          notify("Dossier mis à jour");
          getCustomerFile(id);
          refresh();
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };

  useEffect(() => {
    getCustomerFile(fileNumber);
    if (!employees) {
      getEmployees();
    }
    // eslint-disable-next-line
  }, [fileNumber]);

  const toggleHotStatus = (hotStatus) => (event) => {
    event.stopPropagation();
    updateCustomerFile(customerFile.id, { hot_status: !hotStatus });
  };

  const resetClosureForm = () => {
    setShoudlMailSelectorsBeDisabled(false);
    setIsCustomerFileClosureModalOpen(false);
    setNewCustomerFileStateId(null);
    setIsCancellingStatus(false);
  };

  const getIndicator = () => {
    if (customerFile.is_after_sales) {
      return <AfterSalesIndicator size={20} display="flex" />;
    }
    return (
      <div className={classes.customerFileHotStatus} onClick={toggleHotStatus(customerFile.hot_status)}>
        <WhatshotIcon
          className={
            customerFile.hot_status ? classes.whatshotIconStyleActivated : classes.whatshotIconStyleDesactivated
          }
        />
      </div>
    );
  };

  const handleCustomerFileStatusChange = (newCustomerFileStatusId) => {
    dataProvider.getOne("closure-mail-template", { id: parseInt(newCustomerFileStatusId) }).then((response) => {
      if (response && response.data && response.data.length > 0 && customerFile.category === "home_repair") {
        setNewCustomerFileStateId({ state: newCustomerFileStatusId });
        setIsCancellingStatus(response.data[0].mail_template === "closure_mail_cancellation");
        setIsCustomerFileClosureModalOpen(true);
        setShoudlMailSelectorsBeDisabled(false);
      } else if (isCustomerFileClosureModalOpen) {
        setIsCancellingStatus(false);
        setNewCustomerFileStateId({ state: newCustomerFileStatusId });
        setShoudlMailSelectorsBeDisabled(true);
      } else {
        updateCustomerFile(customerFile.id, { state: newCustomerFileStatusId });
      }
    });
  };

  const handleCloseCustomerFileClosureModal = () => {
    setIsCustomerFileClosureModalOpen(false);
  };

  return fileNumber ? (
    customerFile ? (
      <div className={classes.customerFileDetailWrapper}>
        <div className={classes.customerFileHeaderWrapper}>
          <div className={classes.customerFileHeaderTitleWrapper}>
            <CustomerFileTitleField variant="h1" className={classes.titleStyle} record={customerFile} />
            <CustomerFileTagsField
              canEdit
              customerFilesTags={customerFilesTags}
              deleteCustomerFilesTag={deleteCustomerFilesTag}
              isTagSuccessfullySubmitted={isTagSuccessfullySubmitted}
              postCustomerFilesTag={postCustomerFilesTag}
              record={customerFile}
              updateCustomerFileDetail={updateCustomerFile}
            />
          </div>
          <div className={classes.employeesAssignmentWrapper}>
            {getIndicator()}

            <div className={classes.employeesAvatarsWrapper}>
              <EmployeesAvatarsField employees={employees} record={customerFile} />
              <EmployeesAssignmentModal
                employees={employees}
                record={customerFile}
                updateCustomerFileDetail={updateCustomerFile}
              />
            </div>
          </div>
        </div>
        <Box ml={4} display="flex" justifyContent="space-between">
          <Box minWidth="70%" mr={1}>
            <RepairReportChecks customerFileId={customerFile.id} />
          </Box>
          <CustomerFileStateSelectInput
            currentStateId={customerFile.state}
            customerFilesStates={customerFilesStates}
            handleCustomerFileStatusChange={handleCustomerFileStatusChange}
          />
        </Box>
        <Box m={2}>
          <CustomerFileDetailsCard customerFile={customerFile} updateCustomerFileDetail={updateCustomerFile} />
        </Box>
        <Router basename={`/customer-files/`}>
          <CustomerFileTabs
            details={customerFile}
            idEmployee={idEmployee}
            getCustomerFileDetail={getCustomerFile}
            updateCustomerFileDetail={updateCustomerFile}
            updateTicketDetail={updateTicketDetail}
            createIncentiveSystem={createIncentiveSystem}
            changeCustomerFileStatusSparePart={changeCustomerFileStatusSparePart}
            employees={employees}
            getEmployees={getEmployees}
            customerFilesStates={customerFilesStates}
            customerFilesTags={customerFilesTags}
            defaultTab={defaultTab}
          />
        </Router>
        {isCustomerFileClosureModalOpen && (
          <CustomerFileClosureModal
            updateCustomerFileDetail={updateCustomerFile}
            getCustomerFileDetail={getCustomerFile}
            isOpen={isCustomerFileClosureModalOpen}
            newCustomerFileStatusId={newCustomerFileStateId}
            id={customerFile.id}
            handleCloseCustomerFileClosureModal={handleCloseCustomerFileClosureModal}
            customerFilesStates={customerFilesStates}
            handleCustomerFileStatusChange={handleCustomerFileStatusChange}
            shoudlMailSelectorsBeDisabled={shoudlMailSelectorsBeDisabled}
            customerFilesTags={customerFilesTags}
            customerFile={customerFile}
            resetClosureForm={resetClosureForm}
            isCancellingStatus={isCancellingStatus}
          />
        )}
      </div>
    ) : (
      <div className={classes.loaderContainer}>
        <CircularProgress color="inherit" />
      </div>
    )
  ) : null;
};

export default withStyles(styles)(CustomerFileDetail);
