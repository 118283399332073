import React, { Component } from "react";

import PropTypes from "prop-types";

import QuickAccessElementaryBlock from "admin/components/QuickAccessElementaryBlock";

import "./style.css";

export default class QuickAccessElementsGroup extends Component {
  static propTypes = {
    groupTitle: PropTypes.string.isRequired,
    elements: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
    isSelected: PropTypes.bool.isRequired,
    redirectionRoute: PropTypes.string,
    redirectToEditionPage: PropTypes.func.isRequired,
    toggleSelectedElementsGroup: PropTypes.func.isRequired,
  };

  handleClick = (element) => () => {
    this.props.redirectToEditionPage(element.id);
  };

  toggleSelectedElementsGroup = () => this.props.toggleSelectedElementsGroup(this.props.groupTitle);

  render() {
    return (
      this.props.elements && (
        <div className="qa-elements-groups-container">
          <div id="qaElementsGroupTitle" onClick={this.toggleSelectedElementsGroup}>
            <i className={`qa-elements-group-icon fas ${this.props.isSelected ? "fa-folder-open" : "fa-folder"}`} />
            <span className="quick-access-subtitle">{this.props.groupTitle}</span>
          </div>
          {this.props.isSelected &&
            this.props.elements.map((element, index) => (
              <QuickAccessElementaryBlock
                key={element.id}
                index={index}
                id={element.id}
                text={element.title}
                onClick={this.handleClick(element)}
              />
            ))}
        </div>
      )
    );
  }
}
