import React from "react";

import { AutocompleteInput, ReferenceInput, SaveButton, SelectInput, SimpleForm, Toolbar } from "react-admin";

import { Box, Button, Dialog, DialogContent, DialogTitle, Typography, makeStyles } from "@material-ui/core";

import { SPARE_PART_STATUS_CHOICES } from "constants/sparePartStatus";

const useStyles = makeStyles({
  container: {
    zIndex: 4000,
  },
  toolbar: {
    backgroundColor: "#FFFFFF",
    borderTop: "1px solid #DBDFE8",
    padding: 8,
    width: "100%",
    justifyContent: "space-evenly",
  },
});

const isAtLeastOneFieldFilled = (value, allValues) => {
  if (allValues && Object.keys(allValues).length === 0) {
    return "Au moins un champ doit être rempli";
  }
  return undefined;
};
const BulkSkuUpdateDialog = ({ open, handleClose, handleConfirm }) => {
  const classes = useStyles();
  const validateField = [isAtLeastOneFieldFilled];

  const CustomToolbar = (props) => {
    // nb: it does not seem possible to use mui styled-component
    // equivalent in this version.
    return (
      <Toolbar classes={{ toolbar: classes.toolbar }} {...props}>
        <SaveButton label="Valider" />
        <Button onClick={props.handleClose} color="primary">
          Annuler
        </Button>
      </Toolbar>
    );
  };
  return (
    <Dialog fullWidth maxWidth="xs" open={open}>
      <DialogTitle disableTypography>
        <Box textAlign="center">
          <Typography variant="subtitle1" color="primary">
            Modification de skus{" "}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <SimpleForm
          save={handleConfirm}
          toolbar={<CustomToolbar handleClose={handleClose} handleConfirm={handleConfirm} />}
        >
          <Box>
            <ReferenceInput label="Emplacement" reference="sku-location" source="location_id" alwaysOn fullWidth>
              <AutocompleteInput
                validate={validateField}
                optionValue="id"
                optionText="name"
                emptyText="Tout"
                options={{
                  // since MUI v4/RA is dumb, passing props through classes
                  suggestionsContainerProps: {
                    className: classes.container,
                  },
                }}
              />
            </ReferenceInput>
            <SelectInput
              validate={validateField}
              fullWidth
              source="status"
              choices={SPARE_PART_STATUS_CHOICES}
              label="Statut"
            />
          </Box>
        </SimpleForm>
      </DialogContent>
    </Dialog>
  );
};

export default BulkSkuUpdateDialog;
