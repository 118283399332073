import { crudCreate, crudGetOne, crudUpdate } from "react-admin";

import { changeStatusSparePart, storeStatusAction } from "actions/changeStatusSparePart";
import { getCompanyEmployeeRequest } from "admin/pages/Company/module";
import {
  deleteCustomerFilesTagRequest,
  getCustomerFilesStatesRequest,
  getCustomerFilesTagsRequest,
  getEmployeesRequest,
  postCustomerFilesTagRequest,
} from "admin/pages/CustomerFiles/module";
import { connect } from "react-redux";

import { getAccountRequest } from "pages/Account/module";
import { resetCurrentEditionSession } from "pages/customerFiles/components/CustomerFileBillingQuotes/modules/module";

import CustomerFilesList from "./CustomerFilesList";

function mapStateToProps(state, props) {
  return {
    customerFilesStates: state.customerFiles.customerFilesStates,
    customerFilesTags: state.customerFiles.customerFilesTags,
    employees: state.customerFiles.employees,
    isTagSuccessfullySubmitted: state.customerFiles && state.customerFiles.isTagSuccessfullySubmitted,
    activeCustomerFile: state.admin.resources["customer-file"] && state.admin.resources["customer-file"].data,
    idEmployee: state.account.account && state.account.account.id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCustomerFilesStates() {
      dispatch(getCustomerFilesStatesRequest());
    },
    getAccount() {
      dispatch(getAccountRequest());
    },
    getCustomerFilesTags() {
      dispatch(getCustomerFilesTagsRequest());
    },
    getEmployees() {
      dispatch(getEmployeesRequest());
    },
    getCompanyEmployee() {
      dispatch(getCompanyEmployeeRequest());
    },
    getCustomerFileDetail(id) {
      dispatch(crudGetOne("customer-file", id));
    },
    updateCustomerFileDetail(id, updateData) {
      dispatch(crudUpdate("customer-file", id, updateData, undefined, undefined, false));
    },
    updateTicketDetail(id, updateData) {
      dispatch(crudUpdate("tickets", id, updateData, undefined, undefined, false));
    },
    createIncentiveSystem(productId, repairman, visit, labelType, pointType = "label") {
      dispatch(
        crudCreate(
          "murfy-erp/incentives",
          {
            product_id: productId,
            repairman,
            visit,
            label: labelType || "",
            point_type: pointType,
          },
          undefined,
          false
        )
      );
    },
    changeCustomerFileStatusSparePart(idSparePareAfterPost, newStatus, customerFile, callback) {
      dispatch(changeStatusSparePart(idSparePareAfterPost, newStatus, customerFile, callback));
    },
    updateCustomerFileAfterActionStatusSparePart(idSparePart, customerFile) {
      dispatch(storeStatusAction(idSparePart, customerFile));
    },
    deleteCustomerFilesTag(tagId) {
      dispatch(deleteCustomerFilesTagRequest(tagId));
    },
    postCustomerFilesTag(tagColor, tagText) {
      dispatch(postCustomerFilesTagRequest(tagColor, tagText));
    },
    resetCurrentEditionSession() {
      dispatch(resetCurrentEditionSession());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerFilesList);
