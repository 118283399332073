import { patchCustomerFilesSuccess } from "admin/pages/CustomerFiles/module";
import findIndex from "lodash/findIndex";
import unionBy from "lodash/unionBy";
import { sendAuthenticatedGetRequestToAPI, sendAuthenticatedRequestToAPI } from "modules/generators/requestsToAPI";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import { addOrReplaceById } from "utils/addOrReplace";

export const GET_CUSTOMER_FILE_ADMIN_REQUEST = "CustomerFileAdmin/GET_CUSTOMER_FILE_ADMIN_REQUEST";
export const GET_CUSTOMER_FILE_ADMIN_SUCCESS = "CustomerFileAdmin/GET_CUSTOMER_FILE_ADMIN_SUCCESS";
export const GET_CUSTOMER_FILE_ADMIN_ERROR = "CustomerFileAdmin/GET_CUSTOMER_FILE_ADMIN_ERROR";

export const PATCH_CUSTOMER_FILE_ADMIN_COMMENTS_REQUEST =
  "CustomerFileAdmin/PATCH_CUSTOMER_FILE_ADMIN_COMMENTS_REQUEST";
export const PATCH_CUSTOMER_FILE_EMAIL_REQUEST = "CustomerFileAdmin/PATCH_CUSTOMER_FILE_EMAIL_REQUEST";
export const PATCH_CUSTOMER_FILE_PHONE_REQUEST = "CustomerFileAdmin/PATCH_CUSTOMER_FILE_PHONE_REQUEST";
export const PATCH_CUSTOMER_FILE_STATE_REQUEST = "CustomerFileAdmin/PATCH_CUSTOMER_FILE_STATE_REQUEST";
export const PATCH_IS_PAYED_REQUEST = "CustomerFileAdmin/PATCH_IS_PAYED_REQUEST";
export const PATCH_CUSTOMER_FILE_TAGS_REQUEST = "CustomerFileAdmin/PATCH_CUSTOMER_FILE_TAGS_REQUEST";
export const PATCH_QUOTE_REBATE_REQUEST = "CustomerFileAdmin/PATCH_QUOTE_REBATE_REQUEST";
export const PATCH_QUOTE_ACCEPTED_REQUEST = "CustomerFileAdmin/PATCH_QUOTE_ACCEPTED_REQUEST";
export const PATCH_SPARE_PART_STATE_REQUEST = "CustomerFileAdmin/PATCH_SPARE_PART_STATE_REQUEST";

export const PATCH_CUSTOMER_FILE_SUCCESS = "CustomerFileAdmin/PATCH_CUSTOMER_FILE_SUCCESS";
export const PATCH_IS_PAYED_SUCCESS = "CustomerFileAdmin/PATCH_IS_PAYED_SUCCESS";
export const PATCH_QUOTE_REBATE_SUCCESS = "CustomerFileAdmin/PATCH_QUOTE_REBATE_SUCCESS";
export const PATCH_QUOTE_ACCEPTED_SUCCESS = "CustomerFileAdmin/PATCH_QUOTE_ACCEPTED_SUCCESS";
export const PATCH_SPARE_PART_STATE_SUCCESS = "CustomerFileAdmin/PATCH_SPARE_PART_STATE_SUCCESS";

export const GENERIC_PATCH_ERROR = "CustomerFileAdmin/GENERIC_PATCH_ERROR";

export const POST_CUSTOMER_FILE_PAYMENT_REQUEST = "CustomerFileAdmin/POST_CUSTOMER_FILE_PAYMENT_REQUEST";
export const POST_CUSTOMER_FILE_PAYMENT_SUCCESS = "CustomerFileAdmin/POST_CUSTOMER_FILE_PAYMENT_SUCCESS";
export const POST_CUSTOMER_FILE_PAYMENT_ERROR = "CustomerFileAdmin/POST_CUSTOMER_FILE_PAYMENT_ERROR";

export const DELETE_CUSTOMER_FILE_PAYMENT_REQUEST = "CustomerFileAdmin/DELETE_CUSTOMER_FILE_PAYMENT_REQUEST";
export const DELETE_CUSTOMER_FILE_PAYMENT_SUCCESS = "CustomerFileAdmin/DELETE_CUSTOMER_FILE_PAYMENT_SUCCESS";
export const DELETE_CUSTOMER_FILE_PAYMENT_ERROR = "CustomerFileAdmin/DELETE_CUSTOMER_FILE_PAYMENT_ERROR";

export const POST_CUSTOMER_FILE_QUOTE_REQUEST = "CustomerFileAdmin/POST_CUSTOMER_FILE_QUOTE_REQUEST";
export const POST_CUSTOMER_FILE_QUOTE_SUCCESS = "CustomerFileAdmin/POST_CUSTOMER_FILE_QUOTE_SUCCESS";
export const POST_CUSTOMER_FILE_QUOTE_ERROR = "CustomerFileAdmin/POST_CUSTOMER_FILE_QUOTE_ERROR";
export const POST_EMAIL_ON_QUOTE_SUBMISSION_SUCCESS = "CustomerFileAdmin/POST_EMAIL_ON_QUOTE_SUBMISSION_SUCCESS";

export const POST_CUSTOMER_FILE_VISIT_REQUEST = "CustomerFileAdmin/POST_CUSTOMER_FILE_VISIT_REQUEST";
export const POST_CUSTOMER_FILE_VISIT_SUCCESS = "CustomerFileAdmin/POST_CUSTOMER_FILE_VISIT_SUCCESS";
export const POST_CUSTOMER_FILE_VISIT_ERROR = "CustomerFileAdmin/POST_CUSTOMER_FILE_VISIT_ERROR";

export const DELETE_CUSTOMER_FILE_VISIT_REQUEST = "CustomerFileAdmin/DELETE_CUSTOMER_FILE_VISIT_REQUEST";
export const DELETE_CUSTOMER_FILE_VISIT_SUCCESS = "CustomerFileAdmin/DELETE_CUSTOMER_FILE_VISIT_SUCCESS";
export const DELETE_CUSTOMER_FILE_VISIT_ERROR = "CustomerFileAdmin/DELETE_CUSTOMER_FILE_VISIT_ERROR";

export const POST_EMAIL_QUOTE_SUBMISSION_SUCCESS = "CustomerFileAdmin/POST_EMAIL_QUOTE_SUBMISSION_SUCCESS";
export const POST_EMAIL_QUOTE_SUBMISSION_ERROR = "CustomerFileAdmin/POST_EMAIL_QUOTE_SUBMISSION_ERROR";

export function getCustomerFileAdminRequest(customerFileAdminId) {
  return {
    type: GET_CUSTOMER_FILE_ADMIN_REQUEST,
    payload: {
      customerFileId: customerFileAdminId,
    },
  };
}

export function getCustomerFileAdminSuccess(customerFileAdmin) {
  return {
    type: GET_CUSTOMER_FILE_ADMIN_SUCCESS,
    payload: customerFileAdmin,
  };
}

export function getCustomerFileAdminError(error) {
  return {
    type: GET_CUSTOMER_FILE_ADMIN_ERROR,
    payload: error,
  };
}

export function patchCustomerFileAdminCommentsRequest(customerFileId, adminComments) {
  return {
    type: PATCH_CUSTOMER_FILE_ADMIN_COMMENTS_REQUEST,
    payload: {
      customerFileId: customerFileId,
      adminComments: adminComments,
    },
  };
}

export function patchCustomerFileEmailRequest(customerFileId, email) {
  return {
    type: PATCH_CUSTOMER_FILE_EMAIL_REQUEST,
    payload: {
      customerFileId: customerFileId,
      email: email,
    },
  };
}

export function patchCustomerFilePhoneRequest(customerFileId, phone) {
  return {
    type: PATCH_CUSTOMER_FILE_PHONE_REQUEST,
    payload: {
      customerFileId: customerFileId,
      phone: phone,
    },
  };
}

export function patchCustomerFileStateRequest(customerFileId, stateId) {
  return {
    type: PATCH_CUSTOMER_FILE_STATE_REQUEST,
    payload: {
      customerFileId: customerFileId,
      stateId: stateId,
    },
  };
}

export function patchCustomerFileTagsRequest(customerFileId, tags) {
  return {
    type: PATCH_CUSTOMER_FILE_TAGS_REQUEST,
    payload: {
      customerFileId: customerFileId,
      tags: tags,
    },
  };
}

export function patchIsPayedRequest(paymentId, isPayed, customerFileId) {
  return {
    type: PATCH_IS_PAYED_REQUEST,
    payload: {
      customerFileId: customerFileId,
      paymentId: paymentId,
      isPayed: isPayed,
    },
  };
}

export function patchQuoteRebateRequest(quoteId, rebate) {
  return {
    type: PATCH_QUOTE_REBATE_REQUEST,
    payload: {
      quoteId: quoteId,
      rebate: rebate,
    },
  };
}

export function patchQuoteAcceptedRequest(quoteId, accepted) {
  return {
    type: PATCH_QUOTE_ACCEPTED_REQUEST,
    payload: {
      quoteId: quoteId,
      quoteAccepted: accepted,
    },
  };
}

export function patchSparePartStateRequest(sparePartId, state) {
  return {
    type: PATCH_SPARE_PART_STATE_REQUEST,
    payload: {
      sparePartId: sparePartId,
      sparePartState: state,
    },
  };
}

export function patchCustomerFileSuccess(customerFile) {
  return {
    type: PATCH_CUSTOMER_FILE_SUCCESS,
    payload: customerFile,
  };
}

export function patchIsPayedSuccess(payment, customerFileId) {
  return {
    type: PATCH_IS_PAYED_SUCCESS,
    payload: {
      payment: payment,
      customerFileId: customerFileId,
    },
  };
}

export function patchQuoteRebateSuccess(rebate) {
  return {
    type: PATCH_QUOTE_REBATE_SUCCESS,
    payload: rebate,
  };
}

export function patchQuoteAcceptedSuccess(quote, quoteId) {
  return {
    type: PATCH_QUOTE_ACCEPTED_SUCCESS,
    payload: {
      quote,
      quoteId,
    },
  };
}

export function patchSparePartStateSuccess(sparePart) {
  return {
    type: PATCH_SPARE_PART_STATE_SUCCESS,
    payload: {
      sparePart,
    },
  };
}

export function genericPatchError(error) {
  return {
    type: GENERIC_PATCH_ERROR,
    payload: error,
  };
}

export function postCustomerFilePaymentRequest(
  amount,
  comments,
  customerFileId,
  email,
  firstname,
  lastname,
  paymentType
) {
  return {
    type: POST_CUSTOMER_FILE_PAYMENT_REQUEST,
    payload: {
      amount,
      comments,
      customerFileId,
      email,
      firstname,
      lastname,
      paymentType,
    },
  };
}

export function postCustomerFilePaymentSuccess(payement) {
  return {
    type: POST_CUSTOMER_FILE_PAYMENT_SUCCESS,
    payload: payement,
  };
}

export function postCustomerFilePaymentError(error) {
  return {
    type: POST_CUSTOMER_FILE_PAYMENT_ERROR,
    payload: error,
  };
}

export function deleteCustomerFilePaymentRequest(paymentId) {
  return {
    type: DELETE_CUSTOMER_FILE_PAYMENT_REQUEST,
    payload: paymentId,
  };
}

export function deleteCustomerFilePaymentSuccess(apiResponse, paymentId) {
  return {
    type: DELETE_CUSTOMER_FILE_PAYMENT_SUCCESS,
    payload: paymentId,
  };
}

export function deleteCustomerFilePaymentError(error) {
  return {
    type: DELETE_CUSTOMER_FILE_PAYMENT_ERROR,
    payload: error,
  };
}

export function postCustomerFileQuoteRequest(
  amountPretax,
  amountWithtax,
  delay,
  rebate,
  customerFileId,
  emailTemplate,
  spareParts,
  includeRepairPackage
) {
  return {
    type: POST_CUSTOMER_FILE_QUOTE_REQUEST,
    payload: {
      amountPretax,
      amountWithtax,
      delay,
      rebate,
      customerFileId,
      emailTemplate,
      spareParts,
      includeRepairPackage,
    },
  };
}

export function postCustomerFileQuoteSuccess(quote, emailTemplate) {
  return {
    type: POST_CUSTOMER_FILE_QUOTE_SUCCESS,
    payload: {
      quote,
      emailTemplate,
    },
  };
}

export function postEmailOnQuoteSubmissionSuccess(quote, emailTemplate) {
  return {
    type: POST_EMAIL_ON_QUOTE_SUBMISSION_SUCCESS,
    payload: {
      quote,
      emailTemplate,
    },
  };
}

export function postCustomerFileQuoteError(error) {
  return {
    type: POST_CUSTOMER_FILE_QUOTE_ERROR,
    payload: error,
  };
}

export function postCustomerFileVisitRequest(customerFileId, timeNeeded, timeslot, visitComments, visitType) {
  return {
    type: POST_CUSTOMER_FILE_VISIT_REQUEST,
    payload: {
      customerFileId,
      timeNeeded,
      timeslot,
      visitComments,
      visitType,
    },
  };
}

export function postCustomerFileVisitSuccess(visit) {
  return {
    type: POST_CUSTOMER_FILE_VISIT_SUCCESS,
    payload: visit,
  };
}

export function postCustomerFileVisitError(error) {
  return {
    type: POST_CUSTOMER_FILE_VISIT_ERROR,
    payload: error,
  };
}

export function deleteCustomerFileVisitRequest(visitId) {
  return {
    type: DELETE_CUSTOMER_FILE_VISIT_REQUEST,
    payload: visitId,
  };
}

export function deleteCustomerFileVisitSuccess(apiResponse, visitId) {
  return {
    type: DELETE_CUSTOMER_FILE_VISIT_SUCCESS,
    payload: visitId,
  };
}

export function deleteCustomerFileVisitError(error) {
  return {
    type: DELETE_CUSTOMER_FILE_VISIT_ERROR,
    payload: error,
  };
}

export function postEmailQuoteSubmissionSuccess() {
  return {
    type: POST_EMAIL_QUOTE_SUBMISSION_SUCCESS,
  };
}

export function postEmailQuoteSubmissionError(error) {
  return {
    type: POST_EMAIL_QUOTE_SUBMISSION_ERROR,
    payload: error,
  };
}

// selectors
export function setCustomerFilePatchedData(action) {
  switch (action.type) {
    case PATCH_CUSTOMER_FILE_ADMIN_COMMENTS_REQUEST:
      return {
        admin_comments: action.payload.adminComments,
      };
    case PATCH_CUSTOMER_FILE_EMAIL_REQUEST:
      return {
        email: action.payload.email,
      };
    case PATCH_CUSTOMER_FILE_PHONE_REQUEST:
      return {
        phone: action.payload.phone,
      };
    case PATCH_CUSTOMER_FILE_STATE_REQUEST:
      return {
        state: action.payload.stateId,
      };
    case PATCH_CUSTOMER_FILE_TAGS_REQUEST:
      return {
        customer_file_tags: action.payload.tags,
      };
    default:
      return {};
  }
}

// generators
export function* getCustomerFileAdmin(action) {
  yield call(
    sendAuthenticatedGetRequestToAPI,
    `/murfy-erp/customer-file/${action.payload.customerFileId}/`,
    getCustomerFileAdminSuccess,
    getCustomerFileAdminError
  );
}

export function* patchCustomerFile(action) {
  const data = setCustomerFilePatchedData(action);
  const config = {
    method: "patch",
    url: `${process.env.REACT_APP_API_URL}/murfy-erp/customer-file/${action.payload.customerFileId}/`,
    data: data,
  };
  const toastrConfig = {
    success: { title: "Succès", message: "Votre modification est bien enregistrée" },
    error: { title: "Erreur", useApiResponse: true },
  };
  yield call(sendAuthenticatedRequestToAPI, config, patchCustomerFileSuccess, genericPatchError, toastrConfig);
}

export function* patchIsPayed(action) {
  const config = {
    method: "patch",
    url: `${process.env.REACT_APP_API_URL}/murfy-erp/payment/${action.payload.paymentId}/`,
    data: { is_payment_done: action.payload.isPayed },
  };
  const toastrConfig = {
    success: { title: "Succès", message: "Votre action est bien enregistrée" },
    error: { title: "Erreur", useApiResponse: true },
  };
  yield call(sendAuthenticatedRequestToAPI, config, patchIsPayedSuccess, genericPatchError, toastrConfig, [
    action.payload.customerFileId,
  ]);
}

export function* patchQuoteRebate(action) {
  const config = {
    method: "patch",
    url: `${process.env.REACT_APP_API_URL}/murfy-erp/quote/${action.payload.quoteId}/`,
    data: { rebate: action.payload.rebate },
  };
  const toastrConfig = {
    success: { title: "Succès", message: "Votre action est bien enregistrée" },
    error: { title: "Erreur", useApiResponse: true },
  };
  yield call(sendAuthenticatedRequestToAPI, config, patchQuoteRebateSuccess, genericPatchError, toastrConfig);
}

export function* patchQuoteAccepted(action) {
  const config = {
    method: "patch",
    url: `${process.env.REACT_APP_API_URL}/murfy-erp/quote/${action.payload.quoteId}/`,
    data: { quote_accepted: action.payload.quoteAccepted },
  };
  const toastrConfig = {
    success: { title: "Succès", message: "Votre action est bien enregistrée" },
    error: { title: "Erreur", useApiResponse: true },
  };
  yield call(sendAuthenticatedRequestToAPI, config, patchQuoteAcceptedSuccess, genericPatchError, toastrConfig, [
    action.payload.quoteId,
  ]);
}

export function* patchSparePartState(action) {
  const config = {
    method: "patch",
    url: `${process.env.REACT_APP_API_URL}/murfy-erp/spare-part/${action.payload.sparePartId}/`,
    data: { state: action.payload.sparePartState },
  };
  const toastrConfig = {
    success: { title: "Succès", message: "Votre action est bien enregistrée" },
    error: { title: "Erreur", useApiResponse: true },
  };
  yield call(sendAuthenticatedRequestToAPI, config, patchSparePartStateSuccess, genericPatchError, toastrConfig);
}

export function* patchCustomerFiles(action) {
  yield put(patchCustomerFilesSuccess(action.payload));
}

export function* postCustomerFilePayment(action) {
  const data = {
    amount: action.payload.amount,
    comments: action.payload.comments,
    customer_file: action.payload.customerFileId,
    email: action.payload.email,
    firstname: action.payload.firstname,
    lastname: action.payload.lastname,
    payment_type: action.payload.paymentType,
  };
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/murfy-erp/payment/`,
    data: data,
  };
  const toastrConfig = {
    success: { title: "Succès", message: "Votre action est bien enregistrée" },
    error: { title: "Erreur", useApiResponse: true },
  };
  yield call(
    sendAuthenticatedRequestToAPI,
    config,
    postCustomerFilePaymentSuccess,
    postCustomerFilePaymentError,
    toastrConfig
  );
}

export function* deleteCustomerFilePayment(action) {
  const config = {
    method: "delete",
    url: `${process.env.REACT_APP_API_URL}/murfy-erp/payment/${action.payload}/`,
  };
  const toastrConfig = {
    success: { title: "Succès", message: "Votre action est bien enregistrée" },
    error: { title: "Erreur", useApiResponse: true },
  };
  yield call(
    sendAuthenticatedRequestToAPI,
    config,
    deleteCustomerFilePaymentSuccess,
    deleteCustomerFilePaymentError,
    toastrConfig,
    [action.payload]
  );
}

export function* postCustomerFileQuote(action) {
  const data = {
    amount_pretax: action.payload.amountPretax,
    amount_withtax: action.payload.amountWithtax,
    delay: action.payload.delay,
    rebate: action.payload.rebate,
    customer_file: action.payload.customerFileId,
    spare_parts: action.payload.spareParts,
    include_repair_package: action.payload.includeRepairPackage,
  };
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/murfy-erp/quote/`,
    data: data,
  };
  const toastrConfig = {
    success: { title: "Succès", message: "Votre action est bien enregistrée" },
    error: { title: "Erreur", useApiResponse: true },
  };
  yield call(
    sendAuthenticatedRequestToAPI,
    config,
    postCustomerFileQuoteSuccess,
    postCustomerFileQuoteError,
    toastrConfig,
    [action.payload.emailTemplate]
  );
}

export function* postCustomerFileVisit(action) {
  const data = {
    customer_file: action.payload.customerFileId,
    time_needed: action.payload.timeNeeded,
    timeslot: action.payload.timeslot,
    visit_comments: action.payload.visitComments,
    visit_type: action.payload.visitType,
  };
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/murfy-erp/visit/`,
    data: data,
  };
  const toastrConfig = {
    success: { title: "Succès", message: "Votre action est bien enregistrée" },
    error: { title: "Erreur", useApiResponse: true },
  };
  yield call(
    sendAuthenticatedRequestToAPI,
    config,
    postCustomerFileVisitSuccess,
    postCustomerFileVisitError,
    toastrConfig
  );
}

export function* deleteCustomerFileVisit(action) {
  const config = {
    method: "delete",
    url: `${process.env.REACT_APP_API_URL}/murfy-erp/visit/${action.payload}/`,
  };
  const toastrConfig = {
    success: { title: "Succès", message: "Votre action est bien enregistrée" },
    error: { title: "Erreur", useApiResponse: true },
  };
  yield call(
    sendAuthenticatedRequestToAPI,
    config,
    deleteCustomerFileVisitSuccess,
    deleteCustomerFileVisitError,
    toastrConfig,
    [action.payload]
  );
}

export function* postEmailQuoteSubmission(action) {
  const toastrConfig = {
    success: {
      title: "Succès",
      message:
        action.payload.emailTemplate !== "no_template"
          ? "Votre email a été envoyé"
          : "Suivant le template, aucun email envoyé",
    },
    error: { title: "Erreur", message: "Votre email n'a pas été envoyé", useApiResponse: true },
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/email/send_quote_submission_email/`,
    data: {
      quoteId: action.payload.quote.id,
      customerFileId: action.payload.quote.customer_file,
      template: action.payload.emailTemplate,
    },
  };

  yield call(
    sendAuthenticatedRequestToAPI,
    config,
    postEmailQuoteSubmissionSuccess,
    postEmailQuoteSubmissionError,
    toastrConfig
  );
}

// sagas
export function* getCustomerFileAdminSaga() {
  yield takeEvery([GET_CUSTOMER_FILE_ADMIN_REQUEST, PATCH_IS_PAYED_SUCCESS], getCustomerFileAdmin);
}

export function* patchCustomerFileSaga() {
  yield takeLatest(
    [
      PATCH_CUSTOMER_FILE_ADMIN_COMMENTS_REQUEST,
      PATCH_CUSTOMER_FILE_EMAIL_REQUEST,
      PATCH_CUSTOMER_FILE_PHONE_REQUEST,
      PATCH_CUSTOMER_FILE_STATE_REQUEST,
      PATCH_CUSTOMER_FILE_TAGS_REQUEST,
    ],
    patchCustomerFile
  );
}

export function* patchIsPayedSaga() {
  yield takeEvery(PATCH_IS_PAYED_REQUEST, patchIsPayed);
}

export function* patchQuoteRebateSaga() {
  yield takeEvery(PATCH_QUOTE_REBATE_REQUEST, patchQuoteRebate);
}

export function* patchQuoteAcceptedSaga() {
  yield takeEvery(PATCH_QUOTE_ACCEPTED_REQUEST, patchQuoteAccepted);
}

export function* patchSparePartStateSaga() {
  yield takeEvery(PATCH_SPARE_PART_STATE_REQUEST, patchSparePartState);
}

export function* patchCustomerFileSuccessSaga() {
  yield takeEvery(PATCH_CUSTOMER_FILE_SUCCESS, patchCustomerFiles);
}

export function* postCustomerFilePaymentSaga() {
  yield takeEvery(POST_CUSTOMER_FILE_PAYMENT_REQUEST, postCustomerFilePayment);
}

export function* postCustomerFileQuoteSaga() {
  yield takeEvery(POST_CUSTOMER_FILE_QUOTE_REQUEST, postCustomerFileQuote);
}

export function* deleteCustomerFilePaymentSaga() {
  yield takeEvery(DELETE_CUSTOMER_FILE_PAYMENT_REQUEST, deleteCustomerFilePayment);
}

export function* postCustomerFileVisitSaga() {
  yield takeEvery(POST_CUSTOMER_FILE_VISIT_REQUEST, postCustomerFileVisit);
}

export function* deleteCustomerFileVisitSaga() {
  yield takeEvery(DELETE_CUSTOMER_FILE_VISIT_REQUEST, deleteCustomerFileVisit);
}

export function* postEmailQuoteSubmissionSaga() {
  yield takeEvery([POST_CUSTOMER_FILE_QUOTE_SUCCESS, POST_EMAIL_ON_QUOTE_SUBMISSION_SUCCESS], postEmailQuoteSubmission);
}

// initial state
const initialState = {
  customerFileAdmin: null,
};

// reducer
export function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_CUSTOMER_FILE_ADMIN_REQUEST:
      return {
        ...state,
        error: null,
      };
    case GET_CUSTOMER_FILE_ADMIN_SUCCESS:
      return {
        ...state,
        customerFileAdmin: action.payload,
        error: null,
      };
    case GET_CUSTOMER_FILE_ADMIN_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case PATCH_CUSTOMER_FILE_ADMIN_COMMENTS_REQUEST:
      return {
        ...state,
        error: null,
      };
    case PATCH_CUSTOMER_FILE_EMAIL_REQUEST:
      return {
        ...state,
        error: null,
      };
    case PATCH_CUSTOMER_FILE_PHONE_REQUEST:
      return {
        ...state,
        error: null,
      };
    case PATCH_CUSTOMER_FILE_STATE_REQUEST:
      return {
        ...state,
        error: null,
      };
    case PATCH_CUSTOMER_FILE_TAGS_REQUEST:
      return {
        ...state,
        error: null,
      };
    case PATCH_IS_PAYED_REQUEST:
      return {
        ...state,
        error: null,
      };
    case PATCH_QUOTE_REBATE_REQUEST:
      return {
        ...state,
        error: null,
      };
    case PATCH_QUOTE_ACCEPTED_REQUEST:
      return {
        ...state,
        error: null,
      };
    case PATCH_SPARE_PART_STATE_REQUEST:
      return {
        ...state,
        error: null,
      };
    case PATCH_CUSTOMER_FILE_SUCCESS:
      return {
        ...state,
        customerFileAdmin: action.payload,
      };
    case PATCH_IS_PAYED_SUCCESS:
      return {
        ...state,
        customerFileAdmin: {
          ...state.customerFileAdmin,
          payments: unionBy([action.payload.payment], state.customerFileAdmin.payments, "id"),
        },
      };
    case PATCH_QUOTE_REBATE_SUCCESS:
      let newCustomerFile;
      if (action.payload.payment) {
        newCustomerFile = {
          ...state.customerFileAdmin,
          quotes: unionBy([action.payload], state.customerFileAdmin.quotes, "id"),
          payments: unionBy([action.payload.payment], state.customerFileAdmin.payments, "id"),
        };
      } else {
        newCustomerFile = {
          ...state.customerFileAdmin,
          quotes: unionBy([action.payload], state.customerFileAdmin.quotes, "id"),
        };
      }
      return {
        ...state,
        customerFileAdmin: newCustomerFile,
      };
    case PATCH_QUOTE_ACCEPTED_SUCCESS:
      return {
        ...state,
        customerFileAdmin: {
          ...state.customerFileAdmin,
          payments: unionBy([action.payload.quote.payment], state.customerFileAdmin.payments, "id"),
          quotes: unionBy([action.payload.quote], state.customerFileAdmin.quotes, "id"),
        },
      };
    case PATCH_SPARE_PART_STATE_SUCCESS: // get the right quote, then modify the right spare_part and finally patch the quote
      let index = findIndex(state.customerFileAdmin.quotes, { id: action.payload.sparePart.quote });
      let quote = { ...state.customerFileAdmin.quotes[index] };
      quote.spare_parts = addOrReplaceById(
        state.customerFileAdmin.quotes[index].spare_parts,
        action.payload.sparePart.id,
        "state",
        action.payload.sparePart.state
      );
      return {
        ...state,
        customerFileAdmin: {
          ...state.customerFileAdmin,
          quotes: unionBy([quote], state.customerFileAdmin.quotes, "id"),
        },
      };
    case GENERIC_PATCH_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case POST_CUSTOMER_FILE_PAYMENT_REQUEST:
      return {
        ...state,
        error: null,
      };
    case POST_CUSTOMER_FILE_PAYMENT_SUCCESS:
      return {
        ...state,
        customerFileAdmin: {
          ...state.customerFileAdmin,
          payments: [...state.customerFileAdmin.payments, action.payload],
        },
      };
    case POST_CUSTOMER_FILE_PAYMENT_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_CUSTOMER_FILE_PAYMENT_REQUEST:
      return {
        ...state,
        error: null,
      };
    case DELETE_CUSTOMER_FILE_PAYMENT_SUCCESS:
      return {
        ...state,
        customerFileAdmin: {
          ...state.customerFileAdmin,
          payments: state.customerFileAdmin.payments.filter((payment) => payment.id !== action.payload),
        },
      };
    case DELETE_CUSTOMER_FILE_PAYMENT_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case POST_CUSTOMER_FILE_QUOTE_REQUEST:
      return {
        ...state,
        error: null,
      };
    case POST_CUSTOMER_FILE_QUOTE_SUCCESS:
      return {
        ...state,
        customerFileAdmin: {
          ...state.customerFileAdmin,
          quotes: [...state.customerFileAdmin.quotes, action.payload.quote],
        },
      };
    case POST_CUSTOMER_FILE_QUOTE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case POST_CUSTOMER_FILE_VISIT_REQUEST:
      return {
        ...state,
        error: null,
      };
    case POST_CUSTOMER_FILE_VISIT_SUCCESS:
      return {
        ...state,
        customerFileAdmin: {
          ...state.customerFileAdmin,
          visits: [...state.customerFileAdmin.visits, action.payload],
        },
      };
    case POST_CUSTOMER_FILE_VISIT_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_CUSTOMER_FILE_VISIT_REQUEST:
      return {
        ...state,
        error: null,
      };
    case DELETE_CUSTOMER_FILE_VISIT_SUCCESS:
      return {
        ...state,
        customerFileAdmin: {
          ...state.customerFileAdmin,
          visits: state.customerFileAdmin.visits.filter((visit) => visit.id !== action.payload),
        },
      };
    case DELETE_CUSTOMER_FILE_VISIT_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case POST_EMAIL_QUOTE_SUBMISSION_SUCCESS:
      return {
        ...state,
      };
    case POST_EMAIL_QUOTE_SUBMISSION_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default reducer;
