import React from "react";

import NativeSelect from "@material-ui/core/NativeSelect";

import sortBy from "lodash/sortBy";

import groupElementsByKeys from "utils/groupElementsByKeys";

const BACKGROUND_COLORS = [
  "#f39c12",
  "#8e44ad",
  "#7f8c8d",
  "#95a5a6",
  "#e74c3c",
  "#27ae60",
  "#1abc9c",
  "#3498db",
  "#2c3e50",
  "#95a5a6",
];

const stateStyle = {
  border: 3,
  borderRadius: 5,
  color: "#fff",
  textAlignLast: "center",
  maxWidth: 270,
  float: "right",
  padding: "0px 8px",
  fontSize: 13,
  marginRight: 20,
};

const dropdownStyle = {
  color: "#000000",
};

export default ({ currentStateId, customerFilesStates, handleCustomerFileStatusChange }) => (
  <div style={{ width: "100%", display: "inline-block" }}>
    <NativeSelect
      className="inputStatus"
      value={currentStateId}
      onChange={(event) => handleCustomerFileStatusChange(event.target.value)}
      disableUnderline
      style={{ ...stateStyle, backgroundColor: BACKGROUND_COLORS[currentStateId % 10] }}
    >
      {sortBy(groupElementsByKeys(customerFilesStates, [{ keyName: "type", pathToKey: "type" }]), "type").map(
        (type) => (
          <optgroup label={type.type} key={type.type} style={dropdownStyle}>
            {sortBy(type.elements, "order").map((state) => (
              <option value={state.id} key={state.id}>
                {state.name}
              </option>
            ))}
          </optgroup>
        )
      )}
    </NativeSelect>
  </div>
);
