import React, { ReactNode, useState } from "react";

import { Box, Collapse, ListItem, ListItemText, List as MUIList } from "@material-ui/core";
import { styled } from "@material-ui/core";
import { ChevronRight, ExpandMore } from "@material-ui/icons";

interface ListProps {
  items: ReactNode[];
  titleBlock: ReactNode;
  defaultOpen?: boolean;
  subtitle?: ReactNode;
  backgroundColor?: string;
  itemBackgroundColor?: string;
}

type StylesProps = {
  backgroundColor: string;
  itemBackgroundColor: string;
};

const StyledMUIList = styled(MUIList)<any, StylesProps>(({ theme, backgroundColor }) => ({
  backgroundColor: backgroundColor,
  borderBottom: `2px solid ${theme.palette.secondary.grey700}`,
}));

const StyledListItem = styled(ListItem)<any, StylesProps>(({ theme, itemBackgroundColor }) => ({
  backgroundColor: itemBackgroundColor,
  marginTop: 1,
}));

const List: React.FC<ListProps> = ({
  items,
  titleBlock,
  backgroundColor = "#fff",
  itemBackgroundColor = "#fff",
  subtitle = null,
  defaultOpen = false,
}) => {
  const [open, setOpen] = useState(defaultOpen);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <StyledMUIList backgroundColor={backgroundColor} itemBackgroundColor={itemBackgroundColor}>
      <StyledListItem
        button
        onClick={handleToggle}
        backgroundColor={backgroundColor}
        itemBackgroundColor={itemBackgroundColor}
      >
        <Box mt={1} width="100%" display="flex" justifyContent="space-between">
          <Box flexGrow={1}>{titleBlock}</Box>
          <Box>{open ? <ExpandMore /> : <ChevronRight />}</Box>
        </Box>
      </StyledListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box mx={2}>{subtitle}</Box>
        {items.map((item, index) => (
          <Box>
            <ListItem key={index}>
              <ListItemText primary={item} />
            </ListItem>
          </Box>
        ))}
      </Collapse>
    </StyledMUIList>
  );
};

export default List;
