import React from "react";

import { TextInput, email, required } from "react-admin";

import { Box } from "@material-ui/core";

import GooglePlacesInput from "components/GooglePlacesComponents/GooglePlacesInput";
import InternationalPhoneInput from "components/atoms/PhoneInput/InternationalPhoneInput";

const ClientInformationInputs = () => {
  return (
    <>
      <Box display="flex" width="100%">
        <Box width="320px">
          <TextInput source="firstname" label="Prénom" InputProps={{ disableUnderline: true }} fullWidth />
        </Box>
        <Box width="320px" marginLeft="24px">
          <TextInput source="lastname" label="Nom" InputProps={{ disableUnderline: true }} fullWidth />
        </Box>
      </Box>
      <Box display="flex" width="100%">
        <Box width="320px">
          <TextInput
            source="email"
            label="Adresse email"
            validate={[required("Email du client manquant"), email("Email non valide")]}
            InputProps={{ disableUnderline: true }}
            fullWidth
          />
        </Box>
        <Box width="320px" marginLeft="24px" mb={2}>
          <InternationalPhoneInput label={"N° de Téléphone"} source="phone" />
        </Box>
      </Box>
      <Box display="flex" width="100%">
        <Box width="658px">
          <GooglePlacesInput
            source="address"
            inputProps={{
              name: "address",
              label: "Adresse",
              disableUnderline: true,
              fullWidth: true,
            }}
          />
        </Box>
      </Box>
      <Box display="flex" width="100%">
        <Box width="658px">
          <TextInput
            source="extra_information"
            label="Digicode, étage, appartement"
            InputProps={{ disableUnderline: true }}
            fullWidth
          />
        </Box>
      </Box>
    </>
  );
};

export default ClientInformationInputs;
