import { toastr } from "react-redux-toastr";
import { call, put } from "redux-saga/effects";

import { getAPIUrl, getTokenFromLocalStorage, request } from "utils/networking";

export function getErrorFormatted(toastrConfig, data) {
  let message = "";

  if (toastrConfig.error.message) {
    message += toastrConfig.error.message;
  }

  if (toastrConfig.error.useApiResponse) {
    if (toastrConfig.error.message) {
      message += " - ";
    }
    if (!Array.isArray(data) && typeof data === "object") {
      let keys = Object.keys(data);
      keys.forEach(function (key, keyIndex) {
        if (keyIndex !== 0) {
          message += ", ";
        }
        message += key + ": ";
        data[key].forEach(function (error, dataIndex) {
          if (dataIndex !== 0) {
            message += " ";
          }
          message += error;
        });
      });
    } else {
      message += data;
    }
  }

  return message;
}

/**
 * Send an authenticated GET request to Cobble API
 * @param {String} APIRoute The API path to the requested route, should not start either with the servername nor '/api'
 * @param {Function} onSuccessAction The action creator called on GET request success with the response as argument
 * @param {Function} onErrorAction The action creator called on GET request error with the error as argument
 */
export function* sendAuthenticatedGetRequestToAPI(APIRoute, onSuccessAction, onErrorAction) {
  const token = yield call(getTokenFromLocalStorage);
  const config = {
    method: "get",
    url: `${getAPIUrl()}${APIRoute}`,
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  try {
    const response = yield call(request, config);
    yield put(onSuccessAction(response));
  } catch (error) {
    yield put(onErrorAction(error));
  }
}

/**
 * Send an authenticated request to Cobble API.
 * It only adds an authentication token to the provided config and displays toastr if asked to.
 * @param {Object} config The config to be used to send the request
 * @param {Function} onSuccessAction The action creator called on request success with the response as argument (and successArgs)
 * @param {Function} onErrorAction The action creator called on request error with the error as argument (and errorArgs)
 * @param {Object} toastrConfig The optional config used for success and error toastr {success: {title, message}, error: {title, message}}
 * @param {List} successArgs The optional list of arguments to pass to the onSuccessAction, after the response
 * @param {List} errorArgs The optional list of arguments to pass to the onErrorAction, after the error
 */
export function* sendAuthenticatedRequestToAPI(
  config,
  onSuccessAction,
  onErrorAction,
  toastrConfig,
  successArgs = [],
  errorArgs = []
) {
  const token = yield call(getTokenFromLocalStorage);
  const configWithToken = {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Token ${token}`,
    },
  };
  try {
    const response = yield call(request, configWithToken);
    yield put(onSuccessAction(response, ...successArgs));
    if (toastrConfig && toastrConfig.success) {
      yield call(toastr.success, toastrConfig.success.title, toastrConfig.success.message);
    }
  } catch (error) {
    yield put(onErrorAction(error, ...errorArgs));
    if (toastrConfig && toastrConfig.error) {
      yield call(toastr.error, toastrConfig.error.title, getErrorFormatted(toastrConfig, error.data));
    }
  }
}

/**
 * Send an unauthenticated request to Cobble API.
 * @param {Object} config The config to be used to send the request
 * @param {Function} onSuccessAction The action creator called on request success with the response as argument (and successArgs)
 * @param {Function} onErrorAction The action creator called on request error with the error as argument (and errorArgs)
 * @param {Object} toastrConfig The optional config used for success and error toastr {success: {title, message}, error: {title, message}}
 * @param {List} successArgs The optional list of arguments to pass to the onSuccessAction, after the response
 * @param {List} errorArgs The optional list of arguments to pass to the onErrorAction, after the error
 */
export function* sendUnauthenticatedRequestToAPI(
  config,
  onSuccessAction,
  onErrorAction,
  toastrConfig,
  successArgs = [],
  errorArgs = []
) {
  try {
    const response = yield call(request, config);
    yield put(onSuccessAction(response, ...successArgs));
    if (toastrConfig && toastrConfig.success) {
      yield call(toastr.success, toastrConfig.success.title, toastrConfig.success.message);
    }
  } catch (error) {
    yield put(onErrorAction(error, ...errorArgs));
    if (toastrConfig && toastrConfig.error) {
      yield call(toastr.error, toastrConfig.error.title, getErrorFormatted(toastrConfig, error.data));
    }
  }
}

/**
 * Send an unauthenticated GET request to Cobble API
 * @param {String} APIRoute The API path to the requested route, should not start either with the servername nor '/api'
 * @param {Function} onSuccessAction The action creator called on GET request success with the response as argument
 * @param {Function} onErrorAction The action creator called on GET request error with the error as argument
 */
export function* sendUnauthenticatedGetRequestToAPI(APIRoute, onSuccessAction, onErrorAction) {
  const config = {
    method: "get",
    url: `${getAPIUrl()}${APIRoute}`,
  };
  try {
    const response = yield call(request, config);
    yield put(onSuccessAction(response));
  } catch (error) {
    yield put(onErrorAction(error));
  }
}
