import { useState } from "react";

import { Timeslot } from "types/timeslot";

type TimeslotSelectorHandler = {
  selectedTimeslots: Timeslot[];
  handleTimesotChange: (timeslot: Timeslot) => void;
};
export const useMultipleTimeslotSelector = (): TimeslotSelectorHandler => {
  const [selectedTimeslots, setSelectedTimeslots] = useState<Timeslot[]>([]);

  const handleTimesotChange = (timeslot: Timeslot) => {
    const selectedIndex = selectedTimeslots.findIndex((item) => item.id === timeslot.id);
    let newSelected: Timeslot[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedTimeslots, timeslot);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedTimeslots.slice(1));
    } else if (selectedIndex === selectedTimeslots.length - 1) {
      newSelected = newSelected.concat(selectedTimeslots.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedTimeslots.slice(0, selectedIndex),
        selectedTimeslots.slice(selectedIndex + 1)
      );
    }

    setSelectedTimeslots(newSelected);
  };

  return {
    selectedTimeslots: selectedTimeslots,
    handleTimesotChange: handleTimesotChange,
  };
};
