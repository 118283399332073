import { getCompanyEmployeeSaga, getProductsSaga } from "admin/pages/Company/module";
import {
  deleteCustomerFilePaymentSaga,
  deleteCustomerFileVisitSaga,
  getCustomerFileAdminSaga,
  patchCustomerFileSaga,
  patchCustomerFileSuccessSaga,
  patchIsPayedSaga,
  patchQuoteAcceptedSaga,
  patchQuoteRebateSaga,
  patchSparePartStateSaga,
  postCustomerFilePaymentSaga,
  postCustomerFileQuoteSaga,
  postCustomerFileVisitSaga,
  postEmailQuoteSubmissionSaga,
} from "admin/pages/CustomerFiles/components/CustomerFilesList/module";
import {
  deleteCustomerFilesTagSaga,
  getCustomerFilesSaga,
  getCustomerFilesStatesSaga,
  getCustomerFilesTagsSaga,
  getEmployeesSaga as getEmployeesInCustomerFilesModuleSaga,
  postCustomerFilesTagSaga,
} from "admin/pages/CustomerFiles/module";
import { all } from "redux-saga/effects";

import { getAccountSaga, saveAccountSaga } from "pages/Account/module";
import { getAdminArticleSaga, saveArticleSaga } from "pages/Cms/ArticleEdition/module";
import { getAdminCatalogSaga, saveCatalogSaga } from "pages/Cms/CatalogEdition/module";
import {
  deleteImageSaga,
  getImagesFolderSaga,
  getImagesFoldersListSaga,
  getImagesSaga,
  postImageSaga,
  postImagesFolderSaga,
  postStepImageOrderSaga,
} from "pages/Cms/ContentManagement/components/ImagesManagement/module";
import {
  createTemplateWidgetSaga,
  deleteApplicationFamilySaga,
  deleteApplicationSaga,
  deleteProductSaga,
  redirectToApplicationWidgetSaga,
  saveApplicationFamilySaga,
  saveApplicationSaga,
  saveProductSaga,
} from "pages/Cms/ContentManagement/components/ProductsList/module";
import {
  deleteSymptomCheckingProcessSaga,
  getCheckingProcessesSaga,
  getFaultsSaga,
  getSymptomsAndFaultsFromCheckingProcessSaga,
  getSymptomsSaga,
  patchSymptomCheckingProcessOrderSaga,
  postCheckingProcessSaga,
  postFaultSaga,
  postSymptomCheckingProcessSaga,
  postSymptomSaga,
  reloadSymptomsAfterSuccessSaga,
  reloadSymptomsAndCheckingProcessesListSaga,
} from "pages/Cms/ContentManagement/components/SmartDiagnosticEdition/module";
import {
  deleteWidgetSaga,
  getApplicationFamiliesSaga,
  getApplicationsSaga,
  getStepsSaga,
  getWidgetsSaga,
  saveEmptyStepSaga,
} from "pages/Cms/ContentManagement/module";
import {
  getAdminFormSaga,
  getFormEditionEmployeesSaga,
  getFormEditionStatesSaga,
  initializeNewFormSaga,
  saveFormSaga,
} from "pages/Cms/FormEdition/module";
import {
  deleteStepSaga,
  getAdminStepSaga,
  getStepWidgetAdminSaga,
  saveStepSaga,
  setStepTransitionsOrderSaga,
} from "pages/Cms/StepEdition/module";
import { getTutorielAdminSaga, saveTutorialSaga, setTutorialStepOrderSaga } from "pages/Cms/TutorialEdition/module";
import {
  deleteTagSaga,
  getEmailTemplatesSaga,
  getEmployeesSaga,
  getStatesSaga,
  getTagsSaga,
  getTicketsSaga,
  patchTicketSaga,
  postTagSaga,
} from "pages/Tickets/module";

export default function* adminRootSaga() {
  yield all([
    createTemplateWidgetSaga(),
    deleteApplicationFamilySaga(),
    deleteApplicationSaga(),
    deleteCustomerFilePaymentSaga(),
    deleteCustomerFilesTagSaga(),
    deleteCustomerFileVisitSaga(),
    deleteImageSaga(),
    deleteProductSaga(),
    deleteStepSaga(),
    deleteSymptomCheckingProcessSaga(),
    deleteTagSaga(),
    deleteWidgetSaga(),
    getAccountSaga(),
    getAdminArticleSaga(),
    getAdminCatalogSaga(),
    getAdminFormSaga(),
    getAdminStepSaga(),
    getApplicationFamiliesSaga(),
    getApplicationsSaga(),
    getCheckingProcessesSaga(),
    getCompanyEmployeeSaga(),
    getCustomerFileAdminSaga(),
    getCustomerFilesSaga(),
    getCustomerFilesStatesSaga(),
    getCustomerFilesTagsSaga(),
    getEmailTemplatesSaga(),
    getEmployeesInCustomerFilesModuleSaga(),
    getEmployeesSaga(),
    getFaultsSaga(),
    getFormEditionEmployeesSaga(),
    getFormEditionStatesSaga(),
    getImagesFolderSaga(),
    getImagesFoldersListSaga(),
    getImagesSaga(),
    getProductsSaga(),
    getStatesSaga(),
    getStepsSaga(),
    getStepWidgetAdminSaga(),
    getSymptomsAndFaultsFromCheckingProcessSaga(),
    getSymptomsSaga(),
    getTagsSaga(),
    getTicketsSaga(),
    getTutorielAdminSaga(),
    getWidgetsSaga(),
    initializeNewFormSaga(),
    patchCustomerFileSaga(),
    patchCustomerFileSuccessSaga(),
    patchIsPayedSaga(),
    patchQuoteRebateSaga(),
    patchQuoteAcceptedSaga(),
    patchSparePartStateSaga(),
    patchSymptomCheckingProcessOrderSaga(),
    patchTicketSaga(),
    postCheckingProcessSaga(),
    postCustomerFilePaymentSaga(),
    postCustomerFileQuoteSaga(),
    postCustomerFilesTagSaga(),
    postCustomerFileVisitSaga(),
    postEmailQuoteSubmissionSaga(),
    postFaultSaga(),
    postImageSaga(),
    postImagesFolderSaga(),
    postStepImageOrderSaga(),
    postSymptomCheckingProcessSaga(),
    postSymptomSaga(),
    postTagSaga(),
    redirectToApplicationWidgetSaga(),
    reloadSymptomsAfterSuccessSaga(),
    reloadSymptomsAndCheckingProcessesListSaga(),
    saveAccountSaga(),
    saveApplicationFamilySaga(),
    saveApplicationSaga(),
    saveArticleSaga(),
    saveCatalogSaga(),
    saveEmptyStepSaga(),
    saveFormSaga(),
    saveProductSaga(),
    saveStepSaga(),
    saveTutorialSaga(),
    setStepTransitionsOrderSaga(),
    setTutorialStepOrderSaga(),
  ]);
}
