export default {
  login: ({ username, password }) => {
    const request = new Request(process.env.REACT_APP_API_URL + "/authentication/", {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    const oidcForm = new FormData();
    oidcForm.append("username", username);
    oidcForm.append("password", password);
    oidcForm.append("grant_type", "password");

    return fetch(process.env.REACT_APP_API_URL + "/account/o/token/", {
      method: "POST",
      headers: {
        Authorization: `Basic ${process.env.REACT_APP_OIDC_CLIENT_CREDENTIALS}`,
      },
      body: oidcForm,
    })
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ access_token, refresh_token }) => {
        localStorage.setItem("accessToken", access_token);
        localStorage.setItem("refreshToken", refresh_token);
      })
      .then(() => {
        return fetch(request);
      })
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ token, role, groups }) => {
        localStorage.setItem("token", token);
        localStorage.setItem("groups", JSON.stringify(groups));
      });
  },
  logout: () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("token");
    localStorage.removeItem("groups");
    return Promise.resolve();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("token");
      localStorage.removeItem("groups");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    return localStorage.getItem("token") ? Promise.resolve() : Promise.reject();
  },
  getPermissions: () => {
    const groups = localStorage.getItem("groups");
    return groups ? Promise.resolve(JSON.parse(groups)) : Promise.reject();
  },
};
