import React from "react";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { Field, FieldProps } from "formik";
import { Repairman } from "types/employees";

import { Body } from "components/atoms/Typography";

import { getEmployeeFullName } from "utils/repairmenSortedList";

type EmployeeFieldProps = {
  repairmen: Repairman[];
};

const EmployeeField: React.FunctionComponent<EmployeeFieldProps> = ({ repairmen }: EmployeeFieldProps) => {
  return (
    <Field name="employee">
      {({ form, field }: FieldProps) => {
        const currentEmployeeId = Number(field.value);
        const currentEmployee = repairmen.find((repairman) => repairman.id === currentEmployeeId);
        return (
          <>
            <Autocomplete
              {...field}
              options={repairmen}
              getOptionLabel={(repairman: Repairman) => getEmployeeFullName(repairman)}
              value={currentEmployee || null}
              onChange={(event: React.ChangeEvent<{}>, newValue: Repairman | null) => {
                form.setFieldValue("employee", newValue ? newValue.id.toString() : "");
              }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  fullWidth
                  label={"Technicien(optionnel)"}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
            {form.touched.employee && form.errors.employee && <Body color="red">{form.errors.employee}</Body>}
          </>
        );
      }}
    </Field>
  );
};

export default EmployeeField;
