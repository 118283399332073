export const interventionImpossibleReasonArray = [
  { id: "customers-absence", name: "Absence du client" },
  { id: "product-column", name: "Colonne de produit" },
  { id: "lack-of-space-risk-of-damage", name: "Manque de place - risque de dégâts" },
  { id: "health-risk", name: "Risque sanitaire" },
  { id: "dispute-with-the-customer", name: "Litige avec le client" },
  { id: "spare-part-not-found-on-board", name: "Pièce introuvable à bord" },
];

export const notRepairedReasonArray = [
  { id: "bearing-porthole-washing-machine", name: "Roulement Lave-linge hublot" },
  { id: "clothes-dryer-heat-pump", name: "Sèche-linge Pompe à chaleur" },
  { id: "drilled-dishwasher-tub", name: "Cuve lave-vaisselle percée" },
  { id: "cold-gas-other-thanR600", name: "Froid - gaz autre que R600" },
  { id: "cold-leak-not-identified", name: "Froid - fuite non-identifiée" },
];

export const renderExplanation = (reasonId, listReason) =>
  listReason.find((reason) => reason.id === reasonId) && listReason.find((reason) => reason.id === reasonId).name;
