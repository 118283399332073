import * as React from "react";

import { Edit } from "react-admin";

import { Box } from "@material-ui/core";

import OrderShowActions from "./components/OrderShowActions/OrderShowActions";
import OrderShowLayout from "./components/OrderShowLayout/OrderShowLayout";

const OrderShow = (props) => {
  return (
    <Box margin={5}>
      <Edit {...props} actions={<OrderShowActions />} undoable={false}>
        <OrderShowLayout />
      </Edit>
    </Box>
  );
};

export default OrderShow;
