/**
 * Return the associated property value stored in a given array and an element value.
 * The returned value is an integer as it is rounded up.
 * @param {string} elementValue - the given value on which we'll search for an equivalence in the given array
 * @param {array} associatedArray - the array in where to find the wanted element
 * @param {string} property - the wanted object property of the element found in the given array
 */

export function getQuoteElementProperty(elementValue, associatedArray, property) {
  return (
    (associatedArray.find((label) => label.id === elementValue) &&
      associatedArray.find((label) => label.id === elementValue)[property]) ||
    elementValue
  );
}
