import * as React from "react";
import { useState } from "react";

import { useCreate, useNotify, useRedirect, useRefresh } from "react-admin";

import { Button, Dialog } from "@material-ui/core";

import Moment from "moment";

import dataProvider from "dataProvider";

import { useWarranties } from "utils/api";

import CreateOrderLineForm from "./CreateOrderLineForm";

export default ({ record }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [createOrderLine] = useCreate("ecom/orderlines");

  const [product, setProduct] = useState(null);
  const [barcode, setBarcode] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const redirect = useRedirect();
  const warrantyOptions = useWarranties((product && product.selling_price_withtax) || undefined);

  const create = async (values) => {
    const warranty_price = warrantyOptions.find((option) => option.name === values.warranty_name).price;
    const warranty_years = warrantyOptions.find((option) => option.name === values.warranty_name).years;
    const warranty_end_date = Moment().add(warranty_years, "years").format("YYYY-MM-DD");
    setErrorMessage(null);
    await createOrderLine(
      {
        payload: {
          data: {
            warranty_name: values.warranty_name,
            product: product && product.id,
            warranty_price: warranty_price,
            warranty_years: warranty_years,
            warranty_end_date: warranty_end_date,
            order: record.intent.id,
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          notify("Produit ajouté");
          setIsOpen(false);
          setProduct(null);
          refresh();
          redirect("show", "/ecom/orders", record.id);
        },
        onFailure: (e) => {
          notify("Une erreur est survenue" + e, "warning");
        },
      }
    );
  };
  React.useEffect(() => {
    if (barcode && barcode !== "") {
      dataProvider
        .getList("products", {
          sort: {},
          filter: { barcode: barcode },
        })
        .then((response) => {
          setProduct(response.data && response.data.length === 1 ? response.data[0] : null);
        });
    }
  }, [barcode]);

  return (
    <>
      <Button onClick={() => setIsOpen(true)}> Ajouter un produit à la commande </Button>
      <Dialog
        disableEnforceFocus
        open={isOpen}
        onClose={() => {
          setProduct(null);
          setIsOpen(false);
        }}
      >
        <CreateOrderLineForm
          barcode={barcode}
          setBarcode={setBarcode}
          create={create}
          setProduct={setProduct}
          errorMessage={errorMessage}
          foundProduct={product}
          warrantyOptions={warrantyOptions}
        />
      </Dialog>
    </>
  );
};
