import React from "react";

import { useNotify } from "react-admin";

import Tooltip from "@material-ui/core/Tooltip";
import LinkIcon from "@material-ui/icons/Link";

import CustomerFileLink from "components/atoms/CustomerFileLink";

import { copyToClipboard } from "utils/CopyToClipboard.js";

const Truncate = (text) => {
  return text && text.length > 15 ? text.slice(0, 15) + "..." : text;
};

export const CustomerField = ({ record = {}, classes, callbackNotify }) => {
  const notify = useNotify();
  let customerName = record && record.demand && record.demand.customer_name;
  customerName = /\d/.test(customerName) ? customerName.match(/\d/g) : customerName;
  const customerFileNumber = record && record.demand && record.demand.customer_file_number;
  return record ? (
    <div className={classes.CustomerField}>
      <Tooltip title={customerName}>
        <span>{Truncate(customerName)}</span>
      </Tooltip>
      {customerFileNumber && (
        <>
          <Tooltip title="Copier la référence dossier">
            <LinkIcon
              className={classes.linkIcon}
              onClick={(event) => copyToClipboard(customerFileNumber, event, notify)}
            />
          </Tooltip>
          <CustomerFileLink
            fileNumber={customerFileNumber}
            isWorkshopFile={record.demand && record.demand.workshop_name}
          />
        </>
      )}
    </div>
  ) : null;
};
