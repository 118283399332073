const CustomerFileClosureModalStyles = {
  closureModalContainer: {
    margin: "auto",
    width: 588,
  },
  titleContainer: {
    textAlign: "center",
    paddingTop: 24,
    marginBottom: 56,
  },
  validateButton: {
    borderRadius: 8,
    display: "block",
    margin: "56px auto 40px",
  },
  checkboxFieldContainer: {
    margin: "0 75px",
    height: 80,
    borderTop: "1px solid #DBDFE8",
    display: "flex",
    alignItems: "center",
  },
  checkboxFieldContainerCancellation: {
    margin: "0 75px",
    height: 80,
    display: "flex",
    alignItems: "center",
  },
  accountBalanceInfoContainer: {
    justifyContent: "space-between",
    color: "#8795B3",
    height: 80,
    margin: "0 75px",
    display: "flex",
    alignItems: "center",
  },
  checkboxField: {
    color: "#8795B3",
  },
  checkboxInput: {
    color: "#F7B349",
    marginRight: 11,
  },
  fieldSelection: {
    color: "#8795B3",
    background: "#F4F3F8",
    borderRadius: "12px",
  },
  couponAmount: {
    textAlignLast: "right",
  },
};

export default CustomerFileClosureModalStyles;
