// React imports
import React from "react";

import { useGetOne } from "react-admin";

import { CircularProgress, Typography } from "@material-ui/core";
// MUI imports
import Button from "@material-ui/core/Button";
// Style imports
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

// Component Imports
import Modal from "components/Modal";

import ChargeSetupCardModalStyles from "./ChargeSetupCardModalStyles";

// Module imports

const useStyles = makeStyles(ChargeSetupCardModalStyles);

export default function ChargeSetupCardModal({
  isOpen,
  handleCloseChargeSetupCardModal,
  customerFileId,
  chargeCard,
  disableButton,
}) {
  const classes = useStyles();

  const handleCloseModal = () => {
    handleCloseChargeSetupCardModal();
  };

  const { data, loading, error } = useGetOne("customer-file", customerFileId);
  return (
    <Modal
      isOpen={isOpen}
      handleCloseModal={handleCloseModal}
      modalClassName={`modal-small-content ${classes.closureModalContainer}`}
    >
      <Typography className={classes.titleContainer} variant="h2">
        Débiter le client
      </Typography>
      <div className={classes.subtitleContainer}>
        <Typography variant="body1" className={classes.subtitle}>
          Option “Payer plus tard”
          <CheckCircleIcon className={classes.icon} />
        </Typography>
      </div>

      <div className={classes.amountContainer}>
        <Typography variant="body1" className={classes.amount}>
          Net à payer
        </Typography>
        {data && data.balance && !loading && (
          <Typography variant="body1" className={classes.amount}>
            {data.balance / 100} €
          </Typography>
        )}
        {loading && <CircularProgress />}
        {error && (
          <Typography variant="body1" className={classes.amount}>
            Erreur lors de la récupération de la balance
          </Typography>
        )}
      </div>
      <div className={classes.buttonContainer}>
        <Button
          id="Validate"
          variant="contained"
          color="secondary"
          className={classes.validateButton}
          onClick={() => chargeCard(customerFileId, data.balance)}
          disabled={disableButton || !data || !data.balance}
        >
          Débiter
        </Button>
        <Button
          id="Cancel"
          variant="contained"
          color="primary"
          className={classes.cancelButton}
          onClick={handleCloseChargeSetupCardModal}
          disabled={disableButton}
        >
          Annuler
        </Button>
      </div>
    </Modal>
  );
}
