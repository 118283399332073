import React from "react";

import { SelectInput } from "react-admin";

import { Box, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";

import Modal from "components/Modal";

import { useSkuSuppliers } from "utils/api";

import RefInput from "../RefInput";

export default function AddSparePartASWORefSelection({
  isOpen,
  handleCloseSparePart,
  handleReferenceChange,
  goToAswoModalNoReference,
  spareAswoRef,
  onSearchClick,
  setSupplier,
  supplier,
}) {
  const supplierChoices = useSkuSuppliers();

  return (
    <Modal
      id="aswo-part-modal"
      modalClassName={`modal-small-content`}
      isOpen={isOpen}
      handleCloseModal={handleCloseSparePart}
    >
      <Box pt={3}>
        <Typography align="center" variant="h2">
          Détail de la pièce
        </Typography>
      </Box>
      <Box mt={7} mx={3} mb={2} display="flex" height={56}>
        <Box display="flex">
          {supplierChoices && (
            <SelectInput
              id="supplier-select"
              label="Fournisseur"
              variant="outlined"
              margin="none"
              value={supplier.id}
              source={supplier.name}
              choices={supplierChoices}
              onChange={(event) => setSupplier(supplierChoices.find((supplier) => supplier.id === event.target.value))}
            />
          )}
          <Box ml={1} display="block">
            <RefInput
              variant="outlined"
              margin="none"
              label="Reference de la pièce"
              value={spareAswoRef}
              onChange={handleReferenceChange}
              name="Ref"
            />
          </Box>
        </Box>
        <Box ml={1}>
          <Button id="aswo-search-button" variant="contained" color="secondary" onClick={onSearchClick}>
            <SearchIcon />
          </Button>
        </Box>
      </Box>
      <Box textAlign="center" py={2}>
        <Button id="no-reference-aswo" variant="sting" onClick={goToAswoModalNoReference}>
          Pas de référence aswo ?
        </Button>
      </Box>
    </Modal>
  );
}
