import React, { useState } from "react";

import { Box, Button, IconButton, Typography, makeStyles, useTheme } from "@material-ui/core";
import ControlPointDuplicateIcon from "@material-ui/icons/ControlPointDuplicate";
import DeleteIcon from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";

import Circle from "components/atoms/Circle";
import ColorChip, { InstockTag, SecondHandTag } from "components/atoms/ColorChip";
import SkuModelReferencesModal from "components/organisms/SkuModelReferencesModal";

import { SPARE_PART } from "pages/customerFiles/components/CustomerFileBillingQuotes/services/constants";
import { getAvailabilityText } from "pages/customerFiles/components/CustomerFileBillingQuotes/services/formatSparePartData";
import { getSparePartPriceWithTax } from "pages/customerFiles/components/CustomerFileBillingQuotes/services/sparePartPriceCalculator.js";

import { formatPriceCompact } from "utils/formatting";
import { getPricePretax } from "utils/price";

import QuoteElementStyles from "../QuoteElementStyles";

const SparePartQuoteElement = ({
  classes,
  element,
  customerFile,
  numberOfSparePartsInQuote,
  isModifiable,
  isAswoDelayUnknown,
  isAswoUnavailable,
  isReservedInMurfyStock,
}) => {
  const getAvailabilityColor = (demandItem) => {
    if (!demandItem) return "grey";
    if (isReservedInMurfyStock(demandItem.status) || demandItem.is_in_murfy_stock) {
      return "green";
    }
    if (isAswoUnavailable(demandItem.aswo_announced_delay) || isAswoDelayUnknown(demandItem.aswo_verbose_delay)) {
      return "red";
    }
    if (demandItem.aswo_announced_delay >= 14) {
      return "yellow";
    }
    return "green";
  };

  return (
    <Box>
      <Box mr={2}>
        <Typography variant="body1" color="textPrimary">
          {element.name}
        </Typography>
        <Typography component="span" variant="subtitle2">
          {element.spare_part && element.spare_part.aswo_ref}
        </Typography>
      </Box>
      {isModifiable && (
        <>
          <Circle color={getAvailabilityColor(element.demand_item)} />
          <Typography id="avaibility-text" variant="body2" className={classes.avaibilityText}>
            {getAvailabilityText(element.demand_item)}
          </Typography>
        </>
      )}
      {element.spare_part && element.spare_part.second_hand_available && (
        <ColorChip label="Occasion disponible en stock" color="turquoise" />
      )}
    </Box>
  );
};

const SparePartQuoteElementDetail = ({
  classes,
  element,
  index,
  handleSparePartModification,
  handleDuplicateSparePartElement,
}) => (
  <>
    <IconButton
      id="edit-spare-part"
      className={classes.actionIcon}
      onClick={() => handleSparePartModification(element, element.id || index)}
    >
      <Edit />
    </IconButton>
    <IconButton
      id="duplicate-icon"
      onClick={() => handleDuplicateSparePartElement(element)}
      className={classes.actionIcon}
    >
      <ControlPointDuplicateIcon />
    </IconButton>
  </>
);

const SparePartReferences = ({ skuId, designation }) => {
  const [isReferencesModalOpen, setIsReferencesModalOpen] = useState(false);

  return (
    <>
      <Box mr={1}>
        <Button variant="outlined" size="small" onClick={() => setIsReferencesModalOpen(true)}>
          <small>Voir les références</small>
        </Button>
      </Box>
      <SkuModelReferencesModal
        isOpen={isReferencesModalOpen}
        skuId={skuId}
        designation={designation}
        onModalClose={() => setIsReferencesModalOpen(false)}
        title="Références valides"
        btnText="Ok"
      />
    </>
  );
};

const SparePart = ({
  element,
  customerFile,
  isModifiable = false,
  isDeletable,
  removeElementInQuote,
  billingElementsList,
  addNewElementsInQuote,
  index,
  handleSparePartModification,
  productId,
}) => {
  const handleDuplicateSparePartElement = (quoteElement) => {
    const sparePartsAlreadyInCart =
      (billingElementsList &&
        billingElementsList.filter((quoteElement) => quoteElement.element_type === SPARE_PART).length) ||
      0;
    const amountWithtaxInCents =
      getSparePartPriceWithTax(
        parseFloat((quoteElement.base_price || quoteElement.amount_withtax) / 100),
        1,
        sparePartsAlreadyInCart
      ) * 100;

    const amountPretaxInCents = getPricePretax(amountWithtaxInCents);

    addNewElementsInQuote([
      {
        home_repair_product: productId,
        admin_comments: null,
        amount_pretax: amountPretaxInCents || 0,
        amount_withtax: amountWithtaxInCents || 0,
        base_price: quoteElement.base_price || quoteElement.amount_withtax,
        customer_file: customerFile || null,
        demand_item: {
          aswo_announced_delay: quoteElement.demand_item && quoteElement.demand_item.aswo_announced_delay,
          aswo_verbose_delay: quoteElement.demand_item && quoteElement.demand_item.aswo_verbose_delay,
          is_in_murfy_stock: quoteElement.demand_item && quoteElement.demand_item.is_in_murfy_stock,
          price_pretax: (quoteElement.demand_item && quoteElement.demand_item.price_pretax) || 0,
          quantity: 1,
          sku: quoteElement.demand_item && quoteElement.demand_item.sku,
        },
        element_type: SPARE_PART,
        name: quoteElement.name,
        quote_billing: null,
        spare_part: {
          aswo_ref: (quoteElement.spare_part && quoteElement.spare_part.aswo_ref) || "",
          availability: (quoteElement.spare_part && quoteElement.spare_part.delay) || "",
          piece_name: quoteElement.name,
          price_pretax: (quoteElement.spare_part && quoteElement.spare_part.price_pretax) || 0,
          quantity: 1,
          state: "unaccepted_quote",
        },
        sku_model: {
          designation: (quoteElement.sku_model && quoteElement.sku_model.designation) || "",
          sku_reference: (quoteElement.sku_model && quoteElement.sku_model.sku_reference) || "",
          supplier: (quoteElement.sku_model && quoteElement.sku_model.supplier) || "",
          supplier_reference: (quoteElement.sku_model && quoteElement.sku_model.supplier_reference) || "",
        },
      },
    ]);
  };

  const isAswoUnavailable = (aswoAnnouncedDelay) => {
    /**
     * null: ASWO request failed (wrong reference)
     * "-1": returned by ASWO if stock data is unavailable
     */
    return [null, "-1"].includes(aswoAnnouncedDelay);
  };

  const isAswoDelayUnknown = (aswoVerboseDelay) => {
    return aswoVerboseDelay === " Le délai de livraison est inconnu";
  };

  const isReservedInMurfyStock = (status) => {
    return ["reserved", "affected", "packed"].includes(status);
  };

  const isAvailable = (demandItem) => {
    if (!demandItem) return null;
    return (
      isReservedInMurfyStock(demandItem.status) ||
      demandItem.is_in_murfy_stock ||
      !isAswoUnavailable(demandItem.aswo_announced_delay)
    );
  };

  const doesRequireAction = (element) => {
    // Unavailable spare part, no name or no reference
    return (
      !isAvailable(element.demand_item) ||
      !element.name ||
      (element.spare_part && !element.spare_part.aswo_ref) ||
      isAswoDelayUnknown(element.demand_item.aswo_verbose_delay)
    );
  };

  /**
   * returns the number of elements with the right type in the current quote
   */
  const getNumberOfSparePartsInQuote = () => {
    return (
      (billingElementsList &&
        billingElementsList.filter((quoteElement) => quoteElement.element_type === SPARE_PART).length) ||
      0
    );
  };

  const onSkuLinkClick = (reference) => {
    window.open(`https://aswoshop.aswo.com/showArticleDetails/?fromprofile=nsearch&articleNo=${reference}`, "_blank");
  };

  const theme = useTheme();

  const classes = makeStyles(QuoteElementStyles)();

  return (
    <Box
      p={1}
      justifyContent="space-between"
      bgcolor={
        isModifiable && doesRequireAction(element)
          ? theme.palette.secondary.contrastBackground
          : theme.palette.primary.white
      }
      display="flex"
    >
      <Box margin="auto 0px" minWidth="50%">
        <SparePartQuoteElement
          classes={classes}
          element={element}
          customerFile={customerFile}
          numberOfSparePartsInQuote={getNumberOfSparePartsInQuote()}
          isModifiable={isModifiable}
          onSkuLinkClick={onSkuLinkClick}
          isAswoDelayUnknown={isAswoDelayUnknown}
          isAswoUnavailable={isAswoUnavailable}
          isReservedInMurfyStock={isReservedInMurfyStock}
        />
        <Box display="flex">
          {element.demand_item && element.demand_item.is_second_hand && (
            <Box mt="4px" mr={1}>
              <SecondHandTag />
            </Box>
          )}
          {element.demand_item && element.demand_item.status === "reserved" && (
            <Box mt="4px" mr={1}>
              <InstockTag />
            </Box>
          )}
        </Box>
      </Box>
      <Box display="flex">
        {element.demand_item && element.demand_item.sku && (
          <Box margin="auto">
            <SparePartReferences skuId={element.demand_item.sku} designation={element.demand_item.designation} />
          </Box>
        )}
        <Box margin="auto">
          <Typography variant="body1">{formatPriceCompact(element.amount_withtax)}</Typography>
        </Box>
        {isModifiable && (
          <>
            {isAvailable(element.demand_item) && (
              <SparePartQuoteElementDetail
                handleSparePartModification={handleSparePartModification}
                handleDuplicateSparePartElement={handleDuplicateSparePartElement}
                classes={classes}
                element={element}
                index={index}
              />
            )}
            {isDeletable && (
              // Element deletion
              <IconButton
                id="delete-icon"
                className={classes.actionIcon}
                onClick={() => removeElementInQuote(element.id)}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default SparePart;
