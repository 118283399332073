import React from "react";

import { Box, Button, styled } from "@material-ui/core";

import Modal from "components/Modal";
import H3 from "components/atoms/Typography/H3";

const StyledButton = styled(Button)({
  fontSize: 14,
  fontWeight: "bold",
  height: 50,
  justifyContent: "center",
  padding: "18px 14px",
  boxShadow: "none",
  width: 220,
  color: "#2B3B5C",
  backgroundColor: "#FCD660",
  borderRadius: 12,
});

type ReopeningCustomerFileModalProps = {
  isModalOpen: boolean;
  closeModal: () => void;
  handleSubmitReopening: () => void;
};
const ReopeningCustomerFileModal: React.FC<ReopeningCustomerFileModalProps> = ({
  isModalOpen,
  closeModal,
  handleSubmitReopening,
}) => {
  return (
    <Modal isOpen={isModalOpen} handleCloseModal={closeModal} modalClassName={"modal-small-content"}>
      <Box alignItems="center" display="flex" flexDirection="column" p={3}>
        <H3 color="primary.main">Confirmez-vous la réouverture?</H3>
        <Box mt={3}>
          <StyledButton onClick={handleSubmitReopening}>Confirmer</StyledButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default ReopeningCustomerFileModal;
