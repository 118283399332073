import React from "react";

import { AutocompleteInput, ReferenceInput, TextInput, maxLength } from "react-admin";

import { Box, Button, Typography } from "@material-ui/core";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import ProductToggleGroup from "components/ProductToggleGroup";

import { useProductBrands } from "utils/api";
import { useProductTypes } from "utils/api";

import UserDiagnostic from "../../pages/Tickets/components/UserDiagnostic";

/**
 * Display inputs for handling Product description edition or submission.
 * Should be used inside a react-admin or react-final-form Form.
 * @param {*} props
 */

const ProductDescriptionInputs = (props) => {
  const { isWorkshopFile, record, productType, productsNomenclature } = props;
  // function to get list of subcategories from json config for a given category
  const getSubcategory = (number) => {
    if (productType) {
      return productType["subCategory" + number];
    } else {
      return [];
    }
  };
  const productPublished = props.record && props.record.is_online;
  const classes = useSmallBodyStyles();
  const productBrands = useProductBrands();
  const productTypes = useProductTypes();

  const renderImage = (photoUrl) => {
    return <img src={photoUrl} alt="preview" style={{ maxWidth: "100%" }} />;
  };

  return (
    <>
      {isWorkshopFile && (
        <ProductToggleGroup
          tasks={props.tasks}
          record={props.record}
          refreshWorkshopFileData={props.refreshWorkshopFileData}
        />
      )}
      <Typography variant="body2" color="secondary.contrastText" className={classes.root}>
        Identification
      </Typography>
      {!isWorkshopFile && (!record || !record.product_model) && props.isProductCreation && (
        <>
          <Box display="flex" width="100%">
            <Box width="320px">
              <AutocompleteInput
                label="Catégorie"
                source="product_type"
                fullWidth
                choices={productsNomenclature}
                optionValue="name"
                optionText="name"
                options={{ disabled: productPublished }}
              />
            </Box>
            <Box width="320px" marginLeft="24px">
              <AutocompleteInput
                source="brand"
                label="Marque"
                choices={productBrands}
                fullWidth
                optionValue="name"
                optionText="name"
                options={{ disabled: productPublished }}
              />
            </Box>
          </Box>
          <Box display="flex" width="100%">
            <Box width="320px">
              <TextInput
                source="model_reference"
                label="Modèle"
                InputProps={{ disableUnderline: true, fullWidth: true }}
                disabled={productPublished}
                fullWidth
              />
            </Box>
          </Box>
        </>
      )}
      {isWorkshopFile && (
        <Box display="flex" width="100%">
          {!["Lave-vaisselle", "Four micro-ondes"].includes(record.product_type) && (
            <Box width="320px">
              <AutocompleteInput
                label="Sous-catégorie 1"
                source="subcategory1"
                optionValue="name"
                optionText="name"
                fullWidth
                options={{ disabled: productPublished }}
                choices={getSubcategory(1)}
              />
            </Box>
          )}
          <Box width="320px" marginLeft="24px">
            {productTypes && (
              <ReferenceInput
                label="Installation"
                source="installation_type_id"
                reference="installation-types"
                filter={{ product_type: productTypes.find((type) => type.name === record.product_type).id }}
              >
                <AutocompleteInput optionText="name" options={{ disabled: productPublished }} />
              </ReferenceInput>
            )}
          </Box>
        </Box>
      )}
      <Box display="flex" width="100%">
        <Box width="658px">
          <TextInput
            multiline
            source="fault_details"
            label="Détails et code erreur"
            InputProps={{ disableUnderline: true }}
            disabled={productPublished}
            fullWidth
          />
        </Box>
      </Box>
      {!isWorkshopFile && (
        <>
          <Box display="flex" width="100%">
            <Box width="658px">
              <TextInput
                multiline
                source="symptom"
                label="Symptôme client"
                InputProps={{ disableUnderline: true }}
                fullWidth
                disabled={!props.isProductCreation}
                validate={maxLength(64, "maximum 64 caractères")}
              />
            </Box>
          </Box>
          {!props.isProductCreation && record && record.user_diagnostic && (
            <Box display="flex" width="100%">
              <Box width="658px">
                <ExpansionPanel className="ticket-detail-history-container">
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <span className="ticket-detail ticket-detail-key">Lire le diagnostic utilisateur</span>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <UserDiagnostic ticketUserDiagnostic={record.user_diagnostic} />
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Box>
            </Box>
          )}
        </>
      )}
      {!props.isProductCreation && (
        <>
          {record.identification_plate && <>{renderImage(record.identification_plate)} </>}
          <Box textAlign="center">
            <input
              id={`file-upload-${record.id}`}
              data-testid="nameplate-input"
              type="file"
              style={{ display: "none" }}
              onChange={(event) => {
                props.save({ identification_plate: event.currentTarget.files[0] }, () => {});
              }}
            />
            <Button variant="transparent" my={2} width="90%" type="button">
              <label htmlFor={`file-upload-${record.id}`}>Modifier la plaque signalétique</label>
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

const useSmallBodyStyles = makeStyles((theme) => ({
  root: {
    fontWeight: "600",
    color: theme.palette.secondary.contrastText,
  },
}));

export default ProductDescriptionInputs;
