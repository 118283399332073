import * as React from "react";

import { useDataProvider, useNotify, useRefresh } from "react-admin";
import { DateInput, SelectInput, SimpleForm } from "react-admin";

import { styled } from "@material-ui/core/styles";

import Moment from "moment";

import { isOrderLineCancelled } from "components/OrderShow/utils";

import { useWarranties } from "utils/api";

import Toolbar from "../../OrderShowLayout/Toolbar";

export default (props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const warrantyOptions = useWarranties(props.record.product.selling_price_withtax);

  if (isOrderLineCancelled(props.record)) return null;

  const update = (record, redirect) => {
    // we make sure warranty_price is consistent with warranty_name
    const warranty_price = warrantyOptions.find((option) => option.name === record.warranty_name).price;
    const warranty_years = warrantyOptions.find((option) => option.name === record.warranty_name).years;

    // if warranty years has changed we calculate the new warranty end date
    const warranty_end_date =
      warranty_years !== record.warranty_years
        ? Moment(props.orderCreationDate).add(warranty_years, "years").format("YYYY-MM-DD")
        : record.warranty_end_date;

    dataProvider
      .update("ecom/orderlines", {
        id: record.id,
        data: {
          ...record,
          warranty_price: warranty_price,
          warranty_years: warranty_years,
          warranty_end_date: warranty_end_date,
        },
      })
      .then((response) => {
        notify("Modification effectuée");
        refresh();
      })
      .catch((error) => {
        alert(error.message);
        notify(error.message, "warning");
      });
  };

  const StyledDateInput = styled(DateInput)({
    root: {
      "& p": {
        display: "none",
      },
    },
  });

  return (
    <SimpleForm
      record={props.record}
      resource="ecom/orderlines"
      redirect={false}
      toolbar={<Toolbar label="Modifier" />}
      save={update}
    >
      <SelectInput
        source="warranty_name"
        optionText="name"
        optionValue="name"
        label="Nom de la garantie"
        choices={warrantyOptions}
      />
      <StyledDateInput source="warranty_end_date" optionText="name" optionValue="name" label="Fin de la garantie" />
    </SimpleForm>
  );
};
