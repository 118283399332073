import React, { useState } from "react";

import { Box, Typography } from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";

import Button from "components/atoms/Button";

import EditPackagePriceModal from "./EditPackagePriceModal";

const PackagePriceToolbar = (props) => {
  const [isMassEditModalOpen, setIsMassEditModalOpen] = useState(false);

  return (
    <Box display="flex" mb={2} alignItems={"center"}>
      <Typography variant="subtitle1">Nombre de forfaits selectionnés: {props.selectedIds.length} </Typography>
      <Box mx={3}>
        <Button version="fancy" onClick={() => setIsMassEditModalOpen(true)} color="primary" startIcon={<Edit />}>
          Modifier le prix
        </Button>
      </Box>
      <EditPackagePriceModal
        isModalOpen={isMassEditModalOpen}
        onCloseModal={() => setIsMassEditModalOpen(false)}
        {...props}
      />
    </Box>
  );
};

export default PackagePriceToolbar;
