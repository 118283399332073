import * as React from "react";

import { Product } from "types/products";

type EcommerceProductLinkProps = {
  product: Product;
};

const EcommerceProductLink = ({ product }: EcommerceProductLinkProps) => {
  const url = `https://murfy.fr/reconditionne${product.ecommerce_url + (product.is_online ? "" : "/hors-ligne")}`;
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      Fiche produit
    </a>
  );
};

export default EcommerceProductLink;
