import { Box, BoxProps, Theme, styled } from "@material-ui/core";

interface ChoiceButtonProps extends BoxProps {
  isActive?: boolean;
}

export default styled(Box)<Theme, ChoiceButtonProps>(({ theme, isActive = false }) => {
  let variantProps = { background: "#FFFFFF", border: "1px dashed #ABABAB", fontWeight: 400 };
  if (isActive) {
    variantProps = {
      background: "#F7F8FC",
      border: "1px solid #ABABAB",
      fontWeight: 700,
    };
  }

  return {
    fontSize: theme.spacing(2),
    color: "#2B3B5C",
    padding: theme.spacing(1),
    borderRadius: 10,
    cursor: "pointer",
    ...variantProps,
  };
});
