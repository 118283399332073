import React from "react";

import { Labeled } from "react-admin";

import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import PictureInput from "./components/PictureInput";

const useStyles = makeStyles(() => ({
  img: {
    maxWidth: "180px",
  },
}));
const PictureForm = ({ pictures, disabled, save, product }) => {
  const classes = useStyles();
  return (
    <>
      {disabled ? (
        <Grid container direction="row" justifyContent="space-evenly" alignItems="flex-start" spacing={4}>
          {pictures.map((picture, index) => (
            <Grid item>
              <Labeled label={`Photo ${index + 1}`}>
                <img src={picture.url} alt="preview" className={classes.img} />
              </Labeled>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid container direction="row" justifyContent="space-evenly" alignItems="flex-start" spacing={4}>
          {pictures.map((picture, index) => (
            <Grid item>
              <PictureInput picture={picture} index={index + 1} save={save} />
            </Grid>
          ))}
          <Grid item>
            <PictureInput index={pictures.length + 1} save={save} product={product} />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default PictureForm;
