import React from "react";

import { Box, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import Button from "components/atoms/Button/Button";
import { H3 } from "components/atoms/Typography";
import Caption from "components/atoms/Typography/Caption";
import BaseModal from "components/molecules/BaseModal";

const EMAIL_TEMPLATE_OPTIONS = [
  { value: "no_template", label: "Pas de mail" },
  { value: "template1", label: "Diagnostic P1/P2" },
  { value: "template2", label: "Mauvais diagnostic" },
  { value: "template3", label: "Mauvaise commande" },
  { value: "template4", label: "Commande incomplète" },
  { value: "template5", label: "Réouverture" },
  { value: "template7", label: "Préqualification" },
  { value: "template8", label: "Réparation de froid" },
];

const validationSchema = Yup.object().shape({
  radioChoice: Yup.string().required("Vous devez sélectionner une option"),
});

interface EmailSelectorModalProps {
  isEmailSelectorModalOpen: boolean;
  handleCloseEmailSelectorModal: () => void;
  handleValidateEmailTemplate: (template: string) => void;
  hasSpareParts: () => boolean;
}

const EmailSelectorModal: React.FC<EmailSelectorModalProps> = ({
  isEmailSelectorModalOpen,
  handleCloseEmailSelectorModal,
  handleValidateEmailTemplate,
  hasSpareParts,
}) => {
  return (
    <BaseModal width="xs" isOpen={isEmailSelectorModalOpen} onClose={handleCloseEmailSelectorModal}>
      <Box>
        <H3>Valider un devis</H3>
        <Formik
          initialValues={{ radioChoice: "no_template" }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleValidateEmailTemplate(values.radioChoice);
          }}
        >
          {({ values, errors, touched }) => {
            const hasNextStep = values.radioChoice !== "no_template" && hasSpareParts();
            return (
              <Form translate={null}>
                <Caption mt={1}>Envoyer un email</Caption>
                <Box mt={2} py={1} px={2} border="1px solid #DBDFE8" borderRadius={8}>
                  <Field name="radioChoice">
                    {({ field }: { field: any }) => (
                      <RadioGroup {...field}>
                        {EMAIL_TEMPLATE_OPTIONS.map((templateOption) => (
                          <FormControlLabel
                            key={templateOption.value}
                            value={templateOption.value}
                            control={<Radio />}
                            label={templateOption.label}
                          />
                        ))}
                      </RadioGroup>
                    )}
                  </Field>
                  {errors.radioChoice && touched.radioChoice ? (
                    <Box mt={1} color="red">
                      {errors.radioChoice}
                    </Box>
                  ) : null}
                </Box>

                <Box mt={2} display="flex" justifyContent="space-between">
                  <Button onClick={handleCloseEmailSelectorModal} version="invisible">
                    Annuler
                  </Button>
                  <Button type="submit" version="fancy">
                    {hasNextStep ? "Valider le template" : "Terminer"}
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </BaseModal>
  );
};

export default EmailSelectorModal;
