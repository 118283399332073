import React from "react";

import { Box, Button } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Modal from "components/Modal";

const useStyles = makeStyles({
  modalContainer: {
    margin: "auto",
    width: 588,
  },
  button: {
    fontSize: 14,
    fontWeight: "bold",
    height: 50,
    justifyContent: "center",
    padding: "18px 14px",
    boxShadow: "none",
    width: 220,
    color: "#2B3B5C",
    backgroundColor: "#FCD660",
    borderRadius: 12,
  },
});

const CancellationVisitWithoutFeesModal = ({ isModalOpen, closeModal, handleSubmitCancellation }) => {
  const classes = useStyles();
  return (
    <Modal
      isOpen={isModalOpen}
      handleCloseModal={closeModal}
      modalClassName={`modal-small-content ${classes.modalContainer}`}
    >
      <Box alignItems="center" display="flex" flexDirection="column">
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h2">Annuler le rendez-vous</Typography>
          <Typography variant="h5">Le client ne sera pas facturé</Typography>
        </Box>
        <Box mt={3}>
          <Button className={classes.button} onClick={handleSubmitCancellation}>
            Confirmer
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CancellationVisitWithoutFeesModal;
