const notRepairableReasonList = [
  {
    name: "LL - Elément du bloc laveur HS, décuvage nécessaire",
    value: "WMBOO",
  },
  {
    name: "SL - Pompe à chaleur HS, démontage intégral nécessaire",
    value: "LDHPOO",
  },
  { name: "Froid - Fuite de gaz", value: "CGLR600" },
  {
    name: "Froid - Panne liée à un autre gaz que le R600",
    value: "CGLO",
  },
];

export const getNotRepairableReason = (value) => {
  if (notRepairableReasonList.find((reason) => reason.value === value)) {
    return notRepairableReasonList.find((reason) => reason.value === value).name;
  }
};
