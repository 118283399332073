import React from "react";

import { ReferenceArrayInput, ReferenceInput, SimpleForm } from "react-admin";

import Details from "pages/Tickets/components/Details";
import { getFormatedPaymentMethod } from "pages/Tickets/module";

import StateRepairReportInput from "./StateRepairReportInput";
import TagRepairReportInput from "./TagRepairReportInput";

const TicketDetails = ({
  record,
  handleTicketUpdate,
  createIncentiveSystem,
  visits,
  getCustomerFileDetail,
  customerFile,
  isWorkshopFile,
}) => (
  <SimpleForm record={record} resource="tickets" toolbar={null}>
    <Details
      isNewAdmin
      checkoutPayment={record.ticket_checkout_payment}
      createIncentiveSystem={createIncentiveSystem}
      getCustomerFileDetail={getCustomerFileDetail}
      repairman={record.employee}
      ticketId={record.id}
      ticketFields={record.ticket_fields}
      ticketEmail={record.email}
      ticketAttachments={record.ticket_attachments}
      ticketUserDiagnostic={record.user_diagnostic}
      ticketAdminComments={record.admin_comments}
      ticketIsPaymentDone={record.is_payment_done}
      ticketPaymentMethod={getFormatedPaymentMethod(record.payment_method)}
      visit={visits.find((visit) => visit.id === record.visit)}
      ticketPayload={record.ticket_payload}
      visitPayment={record.visit_payment}
      customerFile={customerFile}
      isWorkshopFile={isWorkshopFile}
    />
    <ReferenceArrayInput label="Tags" reference="ticket-tags" source="ticket_tags">
      <TagRepairReportInput record={record} updateTicketDetail={handleTicketUpdate} />
    </ReferenceArrayInput>
    <ReferenceInput label="État du Compte Rendu" reference="ticket-states" source="ticket_state">
      <StateRepairReportInput record={record} updateTicketDetail={handleTicketUpdate} />
    </ReferenceInput>
  </SimpleForm>
);

export default TicketDetails;
