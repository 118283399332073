import React, { Component } from "react";

import { AutocompleteInput, Button, Create, SaveButton, SimpleForm, TextInput, Toolbar } from "react-admin";

import { Box, Typography, withStyles } from "@material-ui/core";

import Modal from "components/Modal";

const stylesTab = {
  container: {
    borderRadius: 16,
    marginTop: 24,
    maxWidth: 754,
  },
  modalContainer: {
    borderRadius: 16,
    padding: 24,
    maxWidth: 754,
  },
  titleContainer: {
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    width: "100%",
  },
  productId: {
    color: "#8795B3",
    marginLeft: "24px",
    fontSize: "15px",
  },
  addProductButton: {
    width: "100%",
    height: 80,
    border: "1px dashed #8795B3",
    borderRadius: 16,
    color: "#8795B3",
    textTransform: "unset",
  },
  modal: {
    margin: "auto",
    width: 768,
  },
  modalTitle: {
    margin: "auto",
  },
};

export class AddNewProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productType: {},
      isModalOpen: false,
    };
  }

  // function to get list of subcategories from json config for a given category
  getSubcategory = (number) => {
    const type = this.state.productType;
    if (type) {
      return type["subCategory" + number];
    } else {
      return [];
    }
  };

  onCloseModal = () => {
    this.setState({ isModalOpen: false });
    this.props.getCustomerFileDetail(this.props.customerFile);
  };

  // function to get the category of product from json config file and thus be able
  // to get corresponding subcategories
  updateProductType = (event) => {
    this.setState({ productType: this.props.products.find((product) => product.name === event) });
  };

  render() {
    const { classes, products } = this.props;
    const ProductDefaultValue = { customer_file: this.props.customerFile };
    return (
      <div className={classes.container}>
        <Button
          className={classes.addProductButton}
          onClick={(event) => this.setState({ isModalOpen: true })}
          label="+ Ajouter un produit"
        />
        <Modal
          modalClassName={`modal-small-content ${classes.modal}`}
          products={products}
          isOpen={this.state.isModalOpen}
          handleCloseModal={(event) => this.setState({ isModalOpen: false })}
        >
          <div className={classes.modalContainer}>
            <div className={classes.titleContainer}>
              <Typography className={classes.modalTitle} variant="h2">
                Ajouter un produit
              </Typography>
            </div>
            <div className={classes.customForm}>
              <Create resource="products" basePath="">
                <SimpleForm
                  submitOnEnter={false}
                  redirect={false}
                  toolbar={<CustomToolbar handleCloseModal={this.onCloseModal} />}
                  initialValues={ProductDefaultValue}
                >
                  <Box display="flex" width="100%">
                    <Box width="320px">
                      <AutocompleteInput
                        label="Catégorie"
                        source="product_type"
                        fullWidth
                        choices={products}
                        optionValue="name"
                        optionText="name"
                        onChange={(event) => this.updateProductType(event)}
                      />
                    </Box>
                    <Box width="320px" marginLeft="24px">
                      <TextInput source="brand" label="Marque" InputProps={{ disableUnderline: true }} fullWidth />
                    </Box>
                  </Box>
                  <Box display="flex" width="100%">
                    <Box width="320px">
                      <TextInput
                        source="model_reference"
                        label="Reférence"
                        InputProps={{ disableUnderline: true, fullWidth: true }}
                        fullWidth
                      />
                    </Box>
                  </Box>
                  <Box display="flex" width="100%">
                    <Box width="658px">
                      <TextInput
                        multiline
                        source="fault_details"
                        label="Détails et code erreur"
                        InputProps={{ disableUnderline: true }}
                        fullWidth
                      />
                    </Box>
                  </Box>
                </SimpleForm>
              </Create>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withStyles(stylesTab)(AddNewProduct);

const CustomToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Valider" onClick={props.handleCloseModal} />
    </Toolbar>
  );
};
