const SparePartEditionModalStyles = {
  detailDisplayField: {
    display: "flex",
    justifyContent: "space-between",
    padding: "16px 0px",
    width: 360,
    marginLeft: "96px",
    borderBottom: "2px solid #F4F3F8",
  },
  cashedAmountField: {
    display: "table",
    justifyContent: "space-between",
    padding: "16px 0px",
    marginLeft: 96,
    borderBottom: "2px solid #F4F3F8",
  },
  modal: {
    width: 570,
  },
  modalTitle: {
    textAlign: "center",
    padding: "37px 0px",
  },
  buttonGroup: {
    textAlign: "center",
    paddingTop: 20,
    paddingBottom: 16,
  },
  cancelButton: {
    color: "#8795B3",
    backgroundColor: "unset",
    boxShadow: "unset",
    marginLeft: 16,
  },
  inputSparePartPrice: {
    marginLeft: 172,
  },
  aswoReference: {
    fontSize: 13,
    color: "#8795B3",
    cursor: "pointer",
    alignSelf: "center",
  },
  inputLabel: {
    fontSize: 12,
    display: "grid",
    color: "#2B3B5C",
    marginLeft: 16,
  },
  nameField: {
    color: "#8795B3",
    display: "table-cell",
    alignSelf: "center",
  },
  valueField: {
    color: "#2B3B5C",
  },
  toBeCashedvalueField: {
    color: "#2B3B5C",
    marginLeft: 198,
  },
  editSparePartPriceIcon: {
    width: 35,
    height: 35,
  },
  nameRefField: {
    marginLeft: "96px",
    width: 360,
    display: "grid",
    padding: "16px 0px",
    borderBottom: "2px solid #F4F3F8",
  },
  sparePartPriceInput: {
    width: 96,
  },
};

export default SparePartEditionModalStyles;
