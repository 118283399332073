import React, { Component } from "react";

import { withStyles } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import CustomerFileCreationModal from "pages/customerFiles/FileCreate/CustomerFileCreate";

const Styles = {
  CreationIcon: {
    cursor: "pointer",
    marginRight: 20,
    color: "#555C66",
  },
};

export class CreateFileButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreationModalOpen: false,
    };
  }

  updateSelected = (id, selected) => () => {
    if (selected !== id) {
      this.setState({ yellow: true });
    }
    this.setState({ selected: id });
    this.props.handleItemClick(id);
  };

  toggleCreationModal = () => {
    this.setState({ isCreationModalOpen: !this.state.isCreationModalOpen });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.CreationIcon}>
        <AddCircleOutlineIcon onClick={this.toggleCreationModal} />
        <CustomerFileCreationModal isOpen={this.state.isCreationModalOpen} closeModal={this.toggleCreationModal} />
      </div>
    );
  }
}

export default withStyles(Styles)(CreateFileButton);
