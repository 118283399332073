import React from "react";

import { NumberInput, SaveButton, SimpleForm, Toolbar, required, useRefresh } from "react-admin";

import { Box, Typography } from "@material-ui/core";

import { toastr } from "react-redux-toastr";

import dataProvider from "dataProvider";

import Modal from "components/Modal";
import Button from "components/atoms/Button";

const CustomToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <Button version="invisible" onClick={props.onCloseModal}>
        Fermer
      </Button>
      <SaveButton label="Confirmer" onSave={props.updateMany} />
    </Toolbar>
  );
};

const EditPackagePriceModal = ({ isModalOpen, onCloseModal, selectedIds }) => {
  // Used here to refresh react-admin list without user actions
  const refresh = useRefresh();

  const updateMany = (payload) => {
    return dataProvider.updateMany("package-price", {
      ids: selectedIds,
      data: payload,
    });
  };

  const updateNotifyAndCloseModal = (payload) => {
    updateMany(payload)
      .then(() => {
        toastr.success(selectedIds.length + " forfaits modifiés");
        refresh();
        onCloseModal();
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  // prices are stored in cents in the database so we use
  // these functions to show prices in euros to the user
  const centToBaseUnit = (val) => parseFloat(val).toFixed(2) / 100;
  const baseUnitToCent = (v) => parseInt(v * 100);

  return (
    <Modal modalClassName={`modal-small-content`} isOpen={isModalOpen} handleCloseModal={onCloseModal}>
      <Box p={5} textAlign={"center"}>
        <Typography variant="h2">Modifier le prix</Typography>
        <SimpleForm
          redirect={false}
          toolbar={<CustomToolbar updateMany={updateNotifyAndCloseModal} handleCloseModal={onCloseModal} />}
        >
          <NumberInput
            label="Prix du forfait"
            name="price"
            format={centToBaseUnit}
            parse={baseUnitToCent}
            disableUnderline
            validate={[required()]}
            fullWidth
          />
        </SimpleForm>
      </Box>
    </Modal>
  );
};

export default EditPackagePriceModal;
