import * as React from "react";

import { SaveButton, Toolbar } from "react-admin";

import { makeStyles } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  toolbar: {
    backgroundColor: "transparent",
    paddingRight: 0,
  },
}));

export default ({ label, ...props }) => {
  const classes = useStyle();
  return (
    <Toolbar {...props} className={classes.toolbar}>
      <SaveButton label={label} />
    </Toolbar>
  );
};
