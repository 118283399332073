import React from "react";

import { Datagrid, List, TextField } from "react-admin";

import { Typography } from "@material-ui/core";

import { ReferenceField } from "components/atoms/ReferenceField";

export default ({ skuId, designation, ...props }) => {
  return (
    skuId && (
      <>
        <Typography variant="body2">
          Références du modèle de pièce : <br />
          <b>{designation}</b>
        </Typography>
        <List
          basePath=""
          resource="sku-model-references"
          actions={null}
          bulkActionButtons={false}
          exporter={false}
          pagination={false}
          hasCreate={false}
          hasEdit={false}
          hasList={false}
          hasShow={false}
          filter={{ sku: skuId }}
          {...props}
        >
          <Datagrid>
            <TextField source="supplier_name" label="Fournisseur" />
            <ReferenceField source="reference" label="Référence" />
          </Datagrid>
        </List>
      </>
    )
  );
};
