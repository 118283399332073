import { useQuery } from "@tanstack/react-query";
import { get } from "lib/http";
import { Product } from "types/products";

export const useProduct = (productId: number) => {
  const fetchProduct = async () => {
    const url = `/murfy-erp/products/${productId}`;
    const product: Product = await get(url);
    return product;
  };
  const { data, isLoading, isError } = useQuery(["product-detail", productId], fetchProduct);
  return { data, isLoading, isError };
};
