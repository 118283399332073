import React from "react";

import { Avatar, Box, Tooltip, Typography, makeStyles } from "@material-ui/core";

const isMissingUserName = (name: string) => {
  return !name || name.trim().length === 0;
};

export const getInitials = (name: string): string => {
  if (isMissingUserName(name)) return "X.X";
  return name
    .trim()
    .split(" ")
    .map((word) => word && word[0].toUpperCase())
    .join(".");
};

const useStyles = makeStyles((theme) => ({
  field: {
    fontSize: "12px",
    color: "white",
    display: "inline",
  },
  avatar: {
    width: "35px",
    height: "35px",
  },
}));

const BACKGROUND_COLOR_ERROR = "#FF0000";
const BACKGROUND_COLORS = [
  "#f39c12",
  "#7B68EE",
  "#FF1493",
  "#95a5a6",
  "#e74c3c",
  "#27ae60",
  "#1abc9c",
  "#3498db",
  "#007bff",
  "#FF8C00",
  "#DC143C",
];

type AvatarProps = { id: number; name: string; mt?: number; mr?: number };

export default ({ id, name, ...props }: AvatarProps) => {
  const classes = useStyles();
  const initials = getInitials(name);
  const backgroundColor = name ? BACKGROUND_COLORS[id % 10] : BACKGROUND_COLOR_ERROR;

  return (
    <Box {...props}>
      <Avatar className={classes.avatar} style={{ backgroundColor: backgroundColor }}>
        <Tooltip title={isMissingUserName(name) ? "Inconnu" : name}>
          <Typography className={classes.field}>{initials}</Typography>
        </Tooltip>
      </Avatar>
    </Box>
  );
};
