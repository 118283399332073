import React, { Component } from "react";

import PropTypes from "prop-types";

import Tag from "admin/components/Tag";
import find from "lodash/find";
import sortBy from "lodash/sortBy";

import Modal from "components/Modal";

import PostTagForm from "pages/customerFiles/CustomerFilesTags/PostTagForm";

import "./style.css";

export default class CustomerFileTagsList extends Component {
  static propTypes = {
    canEdit: PropTypes.bool,
    customerFileId: PropTypes.number.isRequired,
    customerFileTags: PropTypes.arrayOf(PropTypes.number).isRequired,
    deleteCustomerFilesTag: PropTypes.func,
    isTagSuccessfullySubmitted: PropTypes.bool,
    patchCustomerFileTags: PropTypes.func.isRequired,
    postCustomerFilesTag: PropTypes.func,
    tagsList: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        text: PropTypes.string,
        color: PropTypes.string,
      })
    ).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isModalOpened: false,
    };
  }

  onClickTagHandler = (tagId) => {
    find(this.props.customerFileTags, (tag) => tag === tagId)
      ? this.props.patchCustomerFileTags(this.props.customerFileId, [
          ...this.props.customerFileTags.filter((tag) => tag !== tagId),
        ])
      : this.props.patchCustomerFileTags(this.props.customerFileId, [...this.props.customerFileTags, tagId]);
  };

  closeModal = () => {
    this.setState({
      isModalOpened: false,
    });
  };

  openModal = () => {
    this.setState({
      isModalOpened: true,
    });
  };

  render() {
    return (
      <div>
        <p className="cf-tags-list-open-modal-button" onClick={this.openModal}>
          Editer les tags
        </p>
        <Modal
          isOpen={this.state.isModalOpened}
          handleCloseModal={this.closeModal}
          modalClassName="modal-small-content"
        >
          <div className="cf-tags-list-container">
            <p className="cf-tags-list-container-title">Ajouter ou enlever un tag</p>
            {this.props.canEdit ? (
              <div className="cf-tags-list-container-add-button">
                <PostTagForm
                  postTag={this.props.postCustomerFilesTag}
                  isTagSuccessfullySubmitted={this.props.isTagSuccessfullySubmitted}
                />
              </div>
            ) : null}
            <div>
              {sortBy(sortBy(this.props.tagsList, "text"), "color").map((tag) => (
                <div key={tag.id}>
                  <Tag
                    color={tag.color}
                    handleClickTag={this.onClickTagHandler}
                    handleClickIcon={this.props.deleteCustomerFilesTag}
                    isDeleteDisplayed={false}
                    isSelected={
                      !!find(this.props.customerFileTags, (customerFileTagId) => tag.id === customerFileTagId)
                    }
                    tagId={tag.id}
                    text={tag.text}
                  />
                </div>
              ))}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
