import React, { Component } from "react";

import { IconButton, InputAdornment, TextField, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Create from "@material-ui/icons/Create";

import Modal from "components/Modal";

import { createHistorySkuPrice } from "utils/api";

import SparePartEditionModalStyles from "./SparePartEditionModalStyles";

export class SparePartEditionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forcedSparePartPriceWithTax: 0,
    };
  }
  componentDidMount = () => {
    this.setState({
      sparePartName:
        (this.props.sparePartToBeModified &&
          this.getAttribute(this.props.sparePartToBeModified.spare_part, "piece_name")) ||
        "",

      sparePartAmount: (
        this.props.sparePartToBeModified && this.props.sparePartToBeModified.amount_withtax / 100
      ).toFixed(2),
    });
  };
  componentDidUpdate = (prevProps, prevStates) => {
    if (this.state.newQuoteElement && prevStates.newQuoteElement !== this.state.newQuoteElement) {
      this.props.addNewElementInQuote(this.state.newQuoteElement);
    }
  };

  onInputChange = (inputName) => (event) => {
    this.setState({ [inputName]: event.target.value });
  };

  handleValidateSparePart = () => {
    this.props.updateSparePart(parseInt(this.state.sparePartAmount), this.state.sparePartName);
    this.props.handleCloseSparePartModificationModal();
    this.setState({
      isSparePartModalOpen: false,
    });
    let prevSellingPrice = (
      this.props.sparePartToBeModified && this.props.sparePartToBeModified.amount_withtax / 100
    ).toFixed(2);
    let currentSellingPrice = this.state.sparePartAmount;
    if (parseFloat(currentSellingPrice) !== prevSellingPrice) {
      createHistorySkuPrice(this.props.sparePartToBeModified.sku_id, currentSellingPrice);
    }
  };

  onClickChangeEditabilityOfSparePartPrice = () => {
    this.setState({
      isSparePartPriceEditable: true,
    });
  };

  getAttribute = (sparePart, attributeToRetrieve) => {
    return sparePart[attributeToRetrieve];
  };

  render() {
    const { classes } = this.props;

    const spareAswoRef =
      (this.props.sparePartToBeModified &&
        this.getAttribute(this.props.sparePartToBeModified.spare_part, "aswo_ref")) ||
      "";
    const isSparePartNew = this.props.sparePartToBeModified && !this.props.sparePartToBeModified.quote_billing;
    return (
      <div>
        <Modal
          id="spare-part-edition-modal"
          modalClassName={`modal-small-content ${classes.modal}`}
          isOpen={this.props.isSparePartModificationModalOpen}
          handleCloseModal={this.props.handleCloseSparePartModificationModal}
        >
          <Typography className={classes.modalTitle} variant="h2">
            Modification de pièce détachée
          </Typography>
          <form className={classes.root} noValidate autoComplete="off">
            <div variant="body1">
              <div className={classes.nameRefField}>
                {isSparePartNew ? (
                  <TextField
                    id="spare-part-name"
                    label="Nom de la pièce"
                    value={this.state.sparePartName}
                    onChange={this.onInputChange("sparePartName")}
                    variant="outlined"
                  />
                ) : (
                  <>
                    <Typography variant="body1" className={classes.nameField}>
                      Nom de la pièce
                    </Typography>
                    <Typography variant="body1" className={classes.valueField}>
                      {this.state.sparePartName}
                    </Typography>
                  </>
                )}
              </div>

              <div className={classes.detailDisplayField}>
                <span variant="body1" className={classes.aswoReference}>
                  Ref: {spareAswoRef}
                </span>
              </div>

              <div className={classes.cashedAmountField}>
                <Typography variant="body1" className={classes.nameField}>
                  À encaisser
                </Typography>
                {this.state.isSparePartPriceEditable ? (
                  <div className={classes.inputSparePartPrice}>
                    <TextField
                      id="spare-part-input-override"
                      autoFocus
                      defaultValue={this.state.sparePartAmount}
                      variant="outlined"
                      size="small"
                      className={classes.sparePartPriceInput}
                      onChange={this.onInputChange("sparePartAmount")}
                      type="number"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">€</InputAdornment>,
                      }}
                    />
                  </div>
                ) : (
                  <Typography id="spare-part-price" variant="body1" className={classes.toBeCashedvalueField}>
                    {this.state.sparePartAmount}
                    <IconButton
                      id="trigger-edit-spare-part-price"
                      className={classes.editSparePartPriceIcon}
                      onClick={() => this.onClickChangeEditabilityOfSparePartPrice()}
                    >
                      <Create />
                    </IconButton>
                  </Typography>
                )}
              </div>
              <span className={classes.inputLabel}>{this.state.Lieferzeit}</span>
            </div>
          </form>

          <div className={classes.buttonGroup}>
            <Button
              id="validate-spare-part-modification"
              variant="contained"
              color="secondary"
              onClick={this.handleValidateSparePart}
            >
              Modifier la pièce
            </Button>
            <Button
              id="cancel-spare-part-modification"
              variant="contained"
              className={classes.cancelButton}
              onClick={this.props.handleCloseSparePartModificationModal}
            >
              Annuler
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}
export default withStyles(SparePartEditionModalStyles)(SparePartEditionModal);
