import axios, { AxiosInstance } from "axios";

export interface ErrorData {
  code: "missing-refresh-token" | "cannot-refresh-token";
  message: string;
}

export class OIDCHttpClient {
  private _httpClient: AxiosInstance;
  private _handleAuthError: (e: ErrorData) => void;

  constructor(onAuthError: (e: ErrorData) => void = () => {}) {
    this._handleAuthError = onAuthError;
    this._httpClient = axios.create({
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: `Bearer ${this._get_token()}`,
      },
    });
  }

  async get(url: string, retry_count: number = 0): Promise<any> {
    if (retry_count > 2) {
      console.error("Failed after 2 unsuccesfull auth attempts");

      return this._handleAuthError({
        code: "cannot-refresh-token",
        message: "Le rafraichissement de la ssesion à achoué. Veuillez vous reconnecter",
      });
    }
    try {
      const response = await this._httpClient.request({ method: "GET", url });
      return response.data;
    } catch (e) {
      if (!axios.isAxiosError(e)) {
        throw e;
      }
      if (e.response && (e.response.status === 403 || e.response.status === 401)) {
        return await this._retry(url, retry_count + 1);
      }
    }
  }
  private async _retry(url: string, retry_count: number) {
    console.log("Token expired, refreshing ....");
    try {
      await this._refreshTokenPair();
    } catch {
      this._handleAuthError({
        code: "missing-refresh-token",
        message: "Session manquante veuillez vous reconnecter",
      });
    }
    console.log("Token refreshed, retrying....");
    return await this.get(url, retry_count + 1);
  }

  private async _refreshTokenPair() {
    const refreshForm = new FormData();
    refreshForm.append("refresh_token", this._get_refresh_token());
    refreshForm.append("grant_type", "refresh_token");

    // Getting the new access token
    const response = await axios.request({
      method: "POST",
      url: process.env.REACT_APP_API_URL + "/account/o/token/",
      headers: {
        Authorization: `Basic ${process.env.REACT_APP_OIDC_CLIENT_CREDENTIALS}`,
      },
      data: refreshForm,
    });
    localStorage.setItem("accessToken", response.data.access_token);
    localStorage.setItem("refreshToken", response.data.refresh_token);

    // Refreshing the http client to use the new token
    this._httpClient = axios.create({
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",

        Authorization: `Bearer ${response.data.access_token}`,
      },
    });
  }
  private _get_token() {
    return localStorage.getItem("accessToken");
  }

  private _get_refresh_token() {
    const refreshToken = localStorage.getItem("refreshToken");
    if (refreshToken === null) {
      throw new Error("missing-refresh-token");
    }
    return refreshToken;
  }
}
