import React, { useContext, useState } from "react";

import { DateField, SimpleForm, TextField, TextInput, useDelete, useNotify, useRefresh } from "react-admin";

import { Box, IconButton, Tooltip, Typography, withStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import { BinaryValidateToolbar } from "components/FormToolbars";

import { UserContext } from "pages/customerFiles/CustomerFilesList/CustomerFilesList";

import { createCommentAttachments, updateComment } from "utils/api";

import CommentAttachment from "./CommentAttachment";
import CommentAttachmentLink from "./CommentAttachmentLink";

const StyledTextInput = withStyles({
  root: {
    "& > div": {
      background: "#ffffff",
      borderRadius: 10,
    },
    "& p": {
      display: "none",
    },
  },
})(TextInput);

const Comment = ({ comment, attachmentsEnabled = false, maxNumberOfComments, updateEnabled }) => {
  const [hover, setHover] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteComment] = useDelete("comments");
  const notify = useNotify();
  const refresh = useRefresh();
  const connectedEmployee = useContext(UserContext);

  const isAuthorConnectedEmployee = (comment) => {
    return comment && connectedEmployee && comment.author === connectedEmployee.id;
  };
  const handleDelete = () => {
    deleteComment(
      {
        payload: {
          id: comment.id,
        },
      },
      {
        onSuccess: ({ status }) => {
          if (status > 204) {
            notify("Commentaire non supprimé", "error");
          } else {
            notify("Commentaire supprimé");
            refresh();
          }
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      const newAttachments = values.attachments.filter((attachment) => !attachment.id);
      await updateComment(comment.id, values);
      if (newAttachments.length > 0) {
        newAttachments.map(async (attachment) => {
          await createCommentAttachments({ file: attachment.rawFile, comment: comment.id });
        });
        notify("Commentaire mis à jour");
      }
      refresh();
    } catch (e) {
      notify(`Une erreur est survenue ${e.status} ${e.message}`, "warning");
    }

    setIsEditing(false);
    setIsLoading(false);
  };

  return (
    <Box mb={2} onMouseEnter={() => !isEditing && setHover(true)} onMouseLeave={() => !isEditing && setHover(false)}>
      {!maxNumberOfComments && (
        <Box display="flex">
          <TextField source="author_name" record={comment} variant="h5" />
          <Typography variant="body2">&nbsp;-&nbsp; </Typography>
          <DateField
            source="created_at"
            record={comment}
            variant="body2"
            showTime
            locales="fr"
            options={{
              dateStyle: "full",
              timeStyle: "short",
            }}
          />
        </Box>
      )}

      {!isEditing && (
        <>
          <Box bgcolor="#FFFFFF" borderRadius="10px" display={"flex"} mb={1}>
            <Box flex={1} whiteSpace="pre-wrap" alignItems="stretch" p={2}>
              <Typography variant="span">{comment.text}</Typography>
            </Box>

            {(isAuthorConnectedEmployee(comment) || updateEnabled) && hover && (
              <Box margin="auto">
                <Tooltip title="Edit note">
                  <IconButton size="small" onClick={() => setIsEditing(true)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete note">
                  <IconButton size="small" onClick={handleDelete}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Box>
          <CommentAttachmentLink comment={comment} />
        </>
      )}
      {isEditing && (
        <SimpleForm
          toolbar={
            <BinaryValidateToolbar
              size="small"
              variant="text"
              version="right"
              label={`Valider la modification`}
              onSave={handleSubmit}
              handleClose={() => {
                setHover(false);
                setIsEditing(false);
              }}
              disabled={isLoading}
            />
          }
          redirect={false}
          initialValues={{ text: comment.text, attachments: [] }}
        >
          <Box width="736px !important">
            <StyledTextInput
              label="Modification de commentaire"
              variant="filled"
              size="small"
              source="text"
              fullWidth
              multiline
              rows={5}
            />
          </Box>
          {attachmentsEnabled && (
            <>
              <CommentAttachmentLink deletable comment={comment} />
              <CommentAttachment />
            </>
          )}
        </SimpleForm>
      )}
    </Box>
  );
};

export default Comment;
