import React, { Component } from "react";

import { PropTypes } from "prop-types";

import "./style.css";

export default class FilterField extends Component {
  static propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    resetValue: PropTypes.func.isRequired,
    inputsList: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        description: PropTypes.string,
      })
    ),
  };

  renderSelectList() {
    return (
      <div className="main-input-container">
        <input
          list="inputs"
          className="input-field"
          placeholder={this.props.placeholder}
          value={this.props.value}
          onChange={this.props.onChange}
        />
        <datalist id="inputs">
          {this.props.inputsList.map((input) => (
            <option value={input.description} key={input.id} />
          ))}
        </datalist>
        {this.props.value && (
          <div className="empty-field-icon-container">
            <span id="deleteIcon" aria-hidden="true" className="empty-field-icon" onClick={this.props.resetValue}>
              &times;
            </span>
          </div>
        )}
      </div>
    );
  }

  renderInputField() {
    return (
      <div className="main-input-container">
        <input
          className="input-field"
          placeholder={this.props.placeholder}
          type="text"
          value={this.props.value}
          onChange={this.props.onChange}
        />
        {this.props.value && (
          <div className="empty-field-icon-container">
            <span id="deleteIcon" aria-hidden="true" className="empty-field-icon" onClick={this.props.resetValue}>
              &times;
            </span>
          </div>
        )}
      </div>
    );
  }

  render() {
    return (
      <div className="pt-4">
        {this.props.label && <div className="filter-field-label">{this.props.label.toUpperCase()}</div>}
        {this.props.inputsList ? this.renderSelectList() : this.renderInputField()}
      </div>
    );
  }
}
