export const STORES = [
  { id: "murfy", name: "Murfy" },
  { id: "backmarket", name: "BackMarket" },
  { id: "lengow", name: "Lengow" },
  { id: "electrodepot", name: "Electro Dépot" },
];

export const MARKETPLACES = [
  { id: "fnac_darty", name: "Darty" },
  { id: "veepee_pink_fr", name: "Veepee" },
];
