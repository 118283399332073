import React, { Component } from "react";

import PropTypes from "prop-types";

import DropdownEditionFieldOption from "admin/components/DropdownEditionFieldOption";
import Select from "react-select";

import "./style.css";

export default class DropdownEditionField extends Component {
  static propTypes = {
    label: PropTypes.string,
    isMultipleChoice: PropTypes.bool,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
      })
    ).isRequired,
    value: PropTypes.any,
  };

  static defaultProps = {
    isMultipleChoice: false,
  };

  setValue = (option) => {
    this.props.isMultipleChoice
      ? this.props.onChange(option.map((element) => element.value))
      : this.props.onChange(option.value);
  };
  render() {
    return (
      <div className="text-edition-field-container">
        <div className="text-edition-field-title-container">
          <span className="text-edition-field-title">{this.props.label}&nbsp;</span>
        </div>
        <Select
          autoFocus={false}
          clearable={false}
          style={{
            borderRadius: "0px",
            fontSize: "14px",
          }}
          multi={this.props.isMultipleChoice}
          name="form-field-name"
          onChange={this.setValue}
          options={this.props.options}
          optionComponent={DropdownEditionFieldOption}
          removeSelected={false}
          searchable={false}
          value={this.props.options.find((item) => item.value === this.props.value)}
        />
      </div>
    );
  }
}
