import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Moment from "moment";

import EmployeesAvatarsField from "components/employeesAvatarsField";

import EmployeesAvatarsFieldAuto from "./EmployeeAvatarAuto";
import { historyGridStyles } from "./styles.js";

const useStyles = makeStyles(historyGridStyles);

export const RenderLog = ({ log, index, logLength, employees }) => {
  const historyGridStyle = useStyles();
  const isNewLog = () => Moment(log.created_at).isAfter("2021-07-27");

  return (
    <div key={log.id}>
      <div className={historyGridStyle.topContainer}>
        <div className={historyGridStyle.employeesAvatarsFieldContainer}>
          {isNewLog() && !log.created_by ? (
            <EmployeesAvatarsFieldAuto />
          ) : (
            <EmployeesAvatarsField employees={employees} record={{ assigned_employees: [log.created_by] }} />
          )}
        </div>
        <div className={historyGridStyle.descriptionContainer}>
          {log.description.split("\n").map((description, index) => (
            <div className={historyGridStyle.logDescription} key={index}>
              {description}
            </div>
          ))}
        </div>
      </div>
      <div className={historyGridStyle.bottomContainer}>
        {index + 1 !== logLength ? (
          <div className={historyGridStyle.lineNext} />
        ) : (
          <div className={historyGridStyle.emptyNext} />
        )}
        <div className={historyGridStyle.logDate}>{Moment(log.created_at).format("DD/MM/YYYY à HH:mm")}</div>
      </div>
    </div>
  );
};
