export const SkuDemandListStyles = {
  container: {
    padding: "8px 48px",
  },
  designationField: {
    display: "flex",
  },
  linkIcon: {
    marginLeft: 8,
    fontSize: 20,
    color: "#8795B3",
  },
};
