import { connect } from "react-redux";

import {
  deleteImageRequest,
  getImagesFolderRequest,
  getImagesFoldersListRequest,
  getImagesRequest,
  postImageRequest,
  postImagesFolderRequest,
  setActiveFolder,
} from "pages/Cms/ContentManagement/components/ImagesManagement/module";

import ImagesManagement from "./ImagesManagement.component";

function mapStateToProps(state) {
  return {
    activeFolder: state.imagesManagement.activeFolder,
    imagesFolders: state.imagesManagement.folders,
    images: state.imagesManagement.images,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deleteImage(imageId) {
      dispatch(deleteImageRequest(imageId));
    },
    getImagesFoldersList() {
      dispatch(getImagesFoldersListRequest());
    },
    getImagesFolder(folderId) {
      dispatch(getImagesFolderRequest(folderId));
    },
    postImagesFolder(folderTitle) {
      dispatch(postImagesFolderRequest(folderTitle));
    },
    postImage(imageTitle, imageFile, imageAlt) {
      dispatch(postImageRequest(imageTitle, imageFile, imageAlt));
    },
    getImages() {
      dispatch(getImagesRequest());
    },
    setActiveFolder(folderId) {
      dispatch(setActiveFolder(folderId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ImagesManagement);
