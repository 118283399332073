import { call, put } from "redux-saga/effects";

import { getTokenFromLocalStorage, request } from "utils/networking";

export function* getStates(onSuccessAction, onErrorAction, action) {
  const token = yield call(getTokenFromLocalStorage);
  const config = {
    headers: {
      Authorization: "Token " + token,
    },
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/tool/ticket_state/`,
  };
  try {
    const states = yield call(request, config);
    yield put(onSuccessAction(states));
  } catch (error) {
    yield put(onErrorAction(error));
  }
}
