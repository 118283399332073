import React, { Component } from "react";

import { ReferenceManyField, crudGetManyReference } from "react-admin";

import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";

import { connect } from "react-redux";

import { getAccountRequest } from "pages/Account/module";

import AddPaymentOrRefundModal from "./components/AddPaymentOrRefundModal";
import AccountingBalance from "./components/CustomerFileAccountingBalance.component";
import PaymentList from "./components/CustomerFilePaymentDetails.component";
import { createPayment } from "./modules/createPayment.js";

const CustomerFilePaymentStyles = {
  root: {
    width: "675px",
    margin: "0 86px",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.13), 0px 0px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: 8,
  },
  addPaymentRefundManager: {
    margin: "24px 86px 30px 265px",
    color: "#8795B3",
    backgroundColor: "unset",
    boxShadow: "unset",
  },
};

export class CustomerFilePayment extends Component {
  static propTypes = {
    customerFile: PropTypes.object,
    employees: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      isPaymentOrRefundModalOpen: false,
    };
  }

  handleClosePaymentRefundModal = () => {
    this.setState({ isPaymentOrRefundModalOpen: false });
  };

  handleOpenPaymentRefundModal = () => {
    this.setState({ isPaymentOrRefundModalOpen: true });
  };

  handlePaymentCreation = (
    addOrRefundType,
    amount,
    paymentMode,
    selectedDate,
    selectedTech,
    paymentPartner,
    partnerReference
  ) => {
    this.props.createNewPayment(
      {
        payment_nature: addOrRefundType,
        amount: amount,
        payment_method: paymentMode,
        employee: selectedTech,
        payment_date: selectedDate,
        payment_solution: paymentPartner,
        solution_reference: partnerReference,
        customer_file: this.props.customerFile.id,
      },
      this.props.customerFile,
      this.props.getRelatedPayments
    );
    this.handleClosePaymentRefundModal();
  };

  render() {
    const { classes, employees } = this.props;
    return (
      <div>
        <AccountingBalance customerFileId={this.props.customerFile.id} />
        <Paper className={classes.root}>
          <ReferenceManyField
            reference="paymentbilling-history"
            target="customer_file_id"
            record={this.props.customerFile}
            basePath=""
          >
            <PaymentList
              employees={employees}
              getRelatedPayments={this.props.getRelatedPayments}
              employeeRole={this.props.employeeRole}
            />
          </ReferenceManyField>
        </Paper>
        <Button
          id="cancel-new-refund-payement"
          variant="contained"
          className={classes.addPaymentRefundManager}
          onClick={this.handleOpenPaymentRefundModal}
        >
          + ajouter un paiement ou remboursement
        </Button>
        <AddPaymentOrRefundModal
          employees={employees}
          isPaymentOrRefundModalOpen={this.state.isPaymentOrRefundModalOpen}
          onClickClosePaymentRefundModal={this.handleClosePaymentRefundModal}
          onClickValidatePaymentOrRefund={this.handlePaymentCreation}
        />
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    employeeRole: state.account.account && state.account.account.role,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createNewPayment(quoteData, customerFile, callback, getPaymentListAndQuoteList) {
      dispatch(createPayment(quoteData, customerFile, callback, getPaymentListAndQuoteList));
    },
    getRelatedPayments(customerFileId) {
      dispatch(
        crudGetManyReference(
          "paymentbilling-history",
          "customer_file_id",
          customerFileId,
          "undefined_paymentbilling-history@customer_file_id_" + customerFileId,
          { page: 1, perPage: 25 },
          { field: "id", order: "DESC" },
          "id"
        )
      );
    },
    getAccount() {
      dispatch(getAccountRequest());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(CustomerFilePaymentStyles)(CustomerFilePayment));
