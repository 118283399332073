import * as React from "react";

import { Typography } from "@material-ui/core";

const DeliveryAddressField = ({ order }) => (
  <Typography variant="body2">
    {order.customer_firstname} {order.customer_lastname}
    <br />
    {order.street_number} {order.street}
    <br />
    {order.zipcode} {order.city}
    <br />
    {order.phone || order.customer_phone}
    <br />
    <br />
    {order.floor === 0 ? "RDC" : `Etage ${order.floor}`}
    <br />
    {order.informations && (
      <>
        {order.informations}
        <br />
      </>
    )}
    {order.difficulties && (
      <>
        {order.difficulties}
        <br />
      </>
    )}
    Ascenseur : {order.elevator ? "Oui" : "Non"}
  </Typography>
);

DeliveryAddressField.defaultProps = {
  addLabel: true,
};

export default DeliveryAddressField;
