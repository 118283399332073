export const ISAHIT_GROUP = "isahit";
export const DEVELOPER_GROUP = "developer";
export const SKU_STORE_ADMIN_GROUP = "admin magasin";
export const SHIPPING_ADMIN_GROUP = "admin transport";
export const WORKSHOP_ADMIN_GROUP = "admin ateliers";
export const OFFER_ADMIN_GROUP = "admin offre";
export const TEAM_LEADER = "chef d'equipe";
export const TEAM_LEADER_REPORT_CHECK_DEPLOY_GROUP = "verification de cr par le chef d'equipe deploiement";

const toLowerCase = (s) => s.toLowerCase();
const normalizeCase = (s) => s.normalize("NFKD").replace(/[\u0300-\u036F]/g, "");
const lowerCaseOverlap = (array1, array2) =>
  array1
    .map(toLowerCase)
    .map(normalizeCase)
    .some((e1) => array2.map(toLowerCase).map(normalizeCase).includes(e1));

export function hasPerm(permissions, allowedGroups = [], forbiddenGroups = []) {
  /**
   * Returns false if the permissions array contains one of the forbidden groups.
   * Returns true if the allowedGroups contains the wildcard caracter ('*').
   * Returns true if the permissions array contains one of the allowed groups.
   */
  if (!permissions) return false;
  if (lowerCaseOverlap(permissions, forbiddenGroups)) {
    return false;
  }
  if (allowedGroups.includes("*")) {
    return true;
  }
  return lowerCaseOverlap(permissions, allowedGroups);
}
