import React from "react";

import { Box, Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";

import { getProductModel, updateAttributeValue, useProductTypeAttributes } from "utils/api";

const useSmallBodyStyles = makeStyles((theme) => ({
  root: {
    fontWeight: "600",
    color: theme.palette.secondary.contrastText,
  },
  gridBox: {
    display: "inline-flex",
    "flex-wrap": "wrap",
  },
  regularBox: {
    width: "300px",
    marginLeft: "12px",
    marginRight: "12px",
  },
}));

const ProductModelAttributes = ({ productType, record }) => {
  const classes = useSmallBodyStyles();
  const productTypeAttributes = useProductTypeAttributes({ product_type: productType });
  const [shouldFetchModel, refetch] = React.useState(true);
  const [productModel, setProductModel] = React.useState(undefined);

  React.useEffect(() => {
    if (shouldFetchModel && record) {
      refetch(false);
      getProductModel(record.product_model).then((data) => setProductModel(data));
    }
  }, [shouldFetchModel, productModel, record]);

  const getAttributeValue = (attributeName) => {
    return (
      productModel &&
      productModel.attribute_values &&
      productModel.attribute_values.find((value) => value.name === attributeName) &&
      productModel.attribute_values.find((value) => value.name === attributeName).value
    );
  };

  if (!(productModel && productTypeAttributes && productTypeAttributes.length)) {
    return null;
  }

  return (
    <Box py={4}>
      <Typography variant="body2" color="secondary.contrastText" className={classes.root}>
        Attributs du modèle
      </Typography>
      <Box className={classes.gridBox}>
        {productTypeAttributes
          .filter((attribute) => attribute.value_type === "booleanvalue")
          .map((attribute, index) => (
            <Box className={classes.regularBox} key={index}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={!!getAttributeValue(attribute.name)}
                    onChange={() => {
                      updateAttributeValue({
                        attribute_id: attribute.id,
                        model_id: productModel.id,
                        value: (!getAttributeValue(attribute.name)).toString(),
                      });
                      refetch(true);
                    }}
                  />
                }
                label={attribute.name}
              />
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default ProductModelAttributes;
