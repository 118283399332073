import React, { Component } from "react";

import {
  Datagrid,
  DateField,
  ReferenceArrayField,
  ReferenceField,
  ReferenceManyField,
  SingleFieldList,
  TextField,
} from "react-admin";

import PropTypes from "prop-types";

import { Typography, withStyles } from "@material-ui/core";

import DiagnosicsPerVisit from "./components/DiagnosticsPerVisit/DiagnosticsPerVisit";
import EmployeeRepairReportField from "./components/EmployeeRepairReportField";
import LabelRepairReportField from "./components/LabelRepairReportField";
import PrequalRedirectionLink from "./components/PrequalRedirectionLink";
import PrequalificationDetails from "./components/PrequalificationDetails";
import ProductRepairReportField from "./components/ProductRepairReportField";
import ProductTypeRepairReportField from "./components/ProductTypeRepairReportField";
import ReportTypeRepairReportField from "./components/ReportTypeRepairReportField";
import TagRepairReportField from "./components/TagRepairReportField";
import TicketDescriptionReportField from "./components/TicketDescriptionReportField";
import TicketDetails from "./components/TicketDetails";
import VisitNumberRepairReportField from "./components/VisitNumberRepairReportField";

const tagListStyles = {
  root: {
    display: "block",
  },
};

export default class CustomerFileRepairReports extends Component {
  static propTypes = {
    createIncentiveSystem: PropTypes.func.isRequired,
    customerFile: PropTypes.object,
    getCustomerFileDetail: PropTypes.func,
    employees: PropTypes.array,
    visits: PropTypes.array,
    updateTicketDetail: PropTypes.func.isRequired,
    isWorkshopFile: PropTypes.bool,
    getEmployees: PropTypes.func,
  };
  componentDidMount() {
    this.getCustomerFile();
    if (!this.props.employees) {
      this.props.getEmployees();
    }
  }

  getCustomerFile = () => {
    this.props.getCustomerFileDetail(this.props.customerFile.id);
  };

  render() {
    return (
      <>
        <Typography variant="h2">Compte-rendus d'intervention</Typography>
        <DiagnosicsPerVisit customerFile={this.props.customerFile} />
        <ReferenceManyField
          reference="tickets"
          target="file_number"
          filter={this.props.isWorkshopFile ? {} : { include: ["visit_payment"] }}
          record={this.props.customerFile}
          source="file_number"
          basePath=""
        >
          <Datagrid
            rowClick="expand"
            expand={
              <TicketDetails
                handleTicketUpdate={this.props.updateTicketDetail}
                createIncentiveSystem={this.props.createIncentiveSystem}
                getCustomerFileDetail={this.getCustomerFile}
                visits={this.props.visits}
                customerFile={this.props.customerFile}
                isWorkshopFile={this.props.isWorkshopFile}
              />
            }
          >
            <TextField label="ID" source="ticket_number" />
            <DateField label="Date" source="created_at" locales="fr-FR" />
            <VisitNumberRepairReportField label="Passage" record={this.record} visits={this.props.visits} />
            <ProductRepairReportField
              label="Type de produit"
              record={this.record}
              customerFile={this.props.customerFile}
            />
            <LabelRepairReportField label="Qualification label" record={this.record} visits={this.props.visits} />

            <ReferenceField label="Statut" reference="ticket-states" source="ticket_state" linkType="">
              <TextField source="name" />
            </ReferenceField>

            <ReferenceArrayField label="Tag" reference="ticket-tags" source="ticket_tags">
              <SingleFieldListWithStyles linkType="">
                <TagRepairReportField record={this.record} />
              </SingleFieldListWithStyles>
            </ReferenceArrayField>

            <TicketDescriptionReportField label="Type de CR" record={this.record} />
            {this.props.employees && (
              <EmployeeRepairReportField label="Technicien" record={this.record} employees={this.props.employees} />
            )}
          </Datagrid>
        </ReferenceManyField>
        {!this.props.isWorkshopFile && (
          <>
            <Typography variant="h2">Compte-rendus de préqualification</Typography>
            <PrequalRedirectionLink customerFile={this.props.customerFile} />
            <ReferenceManyField
              reference="fault-forecast-report"
              target="customer_file"
              record={this.props.customerFile}
              filter={{ include: "product,sku_need" }}
              basePath=""
              sort={{}}
            >
              <Datagrid rowClick="expand" expand={<PrequalificationDetails record={this.record} />}>
                <TextField label="ID" source="id" />
                <DateField label="Date" source="created_at" locales="fr-FR" />
                <ProductTypeRepairReportField label="Type de produit" record={this.record} />
                <ReportTypeRepairReportField label="Type de CR" record={this.record} />
                {this.props.employees && (
                  <EmployeeRepairReportField label="Technicien" record={this.record} employees={this.props.employees} />
                )}
              </Datagrid>
            </ReferenceManyField>
          </>
        )}
      </>
    );
  }
}

const SingleFieldListWithStyles = withStyles(tagListStyles)(SingleFieldList);
