// React imports
import React, { Component } from "react";

// RA imports
import { Datagrid, ReferenceManyField } from "react-admin";

import PropTypes from "prop-types";

// MUI imports
import { Box, Typography } from "@material-ui/core";
// Styles imports
import { withStyles } from "@material-ui/core/styles";

import HomeRepairTag from "components/HomeRepairTag";

import {
  SKU_SUBSTITUTION_TYPE_ORIGINAL,
  SKU_SUBSTITUTION_TYPE_SUBSTITUTE,
  SKU_TYPE_NEW,
  SKU_TYPE_SECOND_HAND,
} from "constants/sku";
import { SUPPLIER_NAME_ASWO } from "constants/supplier";

import QuoteElement from "pages/customerFiles/components/CustomerFileBillingQuotes/components/QuoteElement";
import SparePartStatusModal from "pages/customerFiles/components/CustomerFileBillingQuotes/components/SparePartStatusModal.js";
import PackageEditionModal from "pages/customerFiles/components/CustomerFileBillingQuotes/components/elementCreation/PackageEditionModal";
// Components
import DelaySelectionModal from "pages/customerFiles/components/CustomerFileBillingQuotes/components/modal/DelaySelectionModal.js";
import SparePartEditionModal from "pages/customerFiles/components/CustomerFileBillingQuotes/components/modal/SparePartEditionModal/SparePartEditionModal.component.js";
// Module imports
import {
  getLastPaymentIntentAmount,
  getPackageTypesList,
  getTotalPaymentAmount,
} from "pages/customerFiles/components/CustomerFileBillingQuotes/modules/apiActions.js";
import { getNumberOfSparePartElements } from "pages/customerFiles/components/CustomerFileBillingQuotes/services/QuoteHelper.js";
import {
  formatAswoRef,
  formatSparePartData,
} from "pages/customerFiles/components/CustomerFileBillingQuotes/services/formatSparePartData.js";
import { getQuoteElementProperty } from "pages/customerFiles/components/CustomerFileBillingQuotes/services/getQuoteElementProperty.js";
import { getSparePartPriceWithTax } from "pages/customerFiles/components/CustomerFileBillingQuotes/services/sparePartPriceCalculator.js";

import { getAswoSparePart, getAswoSupplier, getSellingPrice, getSkuSellingPrice, getSkus } from "utils/api";
import { getPricePretax } from "utils/price";

import { HERMETIC_UNIT, HERMETIC_UNIT_PRICE, NO_TEMPLATE, SPARE_PART } from "../../services/constants";
import { homeRepairPaletteArray } from "../../services/utils";
import AddElementInQuote from "../AddElementInQuote";
import EmailSelectorModal from "../modal/EmailSelectorModal";
import { quoteDetailsStyles, styledDatagrid } from "./QuoteDetailsStyles";
import QuoteDetailsFooter from "./components/QuoteDetailsFooter";
import { refreshSparePartsElementsPrices } from "./services/refreshSparePartPrices";

const QuoteElementStyleDatagrid = withStyles(styledDatagrid)(Datagrid);

function isSparePart(quoteElement) {
  if (quoteElement.element_type && quoteElement.element_type === SPARE_PART) {
    return true;
  } else return false;
}

function sparePartsFromQuoteElements(quoteElements) {
  const spareParts = [];
  if (quoteElements) {
    quoteElements.map((quoteElement) => isSparePart(quoteElement) && spareParts.push(quoteElement));
    if (spareParts.length === 0) {
      return false;
    } else return spareParts;
  }
  return false;
}

export class QuoteDetails extends Component {
  static propTypes = {
    record: PropTypes.object,
    customerFile: PropTypes.object,
    handleDisplayNewQuoteForm: PropTypes.func,
    quoteBillingList: PropTypes.object,
    isNewQuoteFormOpen: PropTypes.bool,
    isLast: PropTypes.bool,
    handleQuoteCreation: PropTypes.func,
    handleUpdateQuote: PropTypes.func,
    handlePaymentCreation: PropTypes.func,
    employees: PropTypes.array,
    visits: PropTypes.array,
    saveCurrentEditionSession: PropTypes.func,
    resetCurrentEditionSession: PropTypes.func,
    hermeticUnitsOrders: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.state = {
      newElements: (this.props.currentEditionSession && this.props.currentEditionSession.newElements) || [],
      isAddPaymentModalOpen: false,
      quote: (this.props.currentEditionSession && this.props.currentEditionSession.quote) || this.props.record,
      lastNonRefusedQuote: this.props.lastNonRefusedQuote || this.props.record,
      elementsLength: this.props.record.quote_elements.length,
      isEmailSelectorModalOpen: false,
      isSparePartStatusModalOpen: false,
      isPackageModificationModalOpen: false,
      sparePartSelected: null,
      sparePartsToRemove:
        (this.props.currentEditionSession && this.props.currentEditionSession.sparePartsToRemove) || [],
      savedIndex: -1,
      radioChoice: NO_TEMPLATE,
      totalAmountPayment: 0,
      amountPaymentIntent: 0,
      isReadyToDisplay: false,
      packageId: null,
      productId: null,
      packageTypeList: [],
      actualPackageSelected: "",
      isNewSparePartAdded: false,
      isSparePartPartModifiable: false,
      sparePartIdToBeModified: null,
      isSparePartModificationModalOpen: false,
      modifiedSpareParts: (this.props.modifiedSpareParts && this.props.currentEditionSession.modifiedSpareParts) || [],
      delay: null,
      emailTemplate: null,
      sendConfirmationEmail: false,
      sellingPriceIsReady: false,
    };
  }

  componentDidMount = () => {
    this.getPackageTypesList();
    this.getTotalPaymentAmount(this.props.record.customer_file);
    this.updateSellingPriceNewElements();

    // When no edition session is open, then we don't have to add the
    // last repair report spare parts.
    if (!this.props.currentEditionSession && this.props.sparePartsOrders) {
      this.handleQuoteElementFromSparePartOrder(this.props.sparePartsOrders);
    }
    if (!this.props.currentEditionSession && this.props.hermeticUnitsOrders) {
      this.handleQuoteElementFromHermeticUnitsOrders(this.props.hermeticUnitsOrders);
    }
  };

  updateSellingPriceNewElements = () => {
    let itemsProcessed = 0;
    let sellingPriceNewElements = [];

    this.state.newElements &&
      this.state.newElements
        .filter((element) => isSparePart(element))
        .filter((element) => element.amount_withtax == null) // null or undefined
        .forEach((element) =>
          getSellingPrice(
            element.sku_model.supplier_reference,
            element.sku_model.supplier,
            element.demand_item.is_second_hand ? SKU_TYPE_SECOND_HAND : SKU_TYPE_NEW,
            element.sku_model.designation
          ).then(({ data }) => {
            sellingPriceNewElements.push({
              reference: element.sku_model.supplier_reference,
              supplier: element.sku_model.supplier,
              selling_price: data["selling_price"],
              id: element.id,
              sku_id: data["sku"],
            });
            itemsProcessed++;
            if (this.state.newElements.filter((element) => isSparePart(element)).length === itemsProcessed) {
              const sparePartsAlreadyInCart = getNumberOfSparePartElements(this.state.newElements);
              let updateSellingPriceInElement = this.state.newElements
                .filter((element) => sellingPriceNewElements.map((el) => el.id).includes(element.id))
                .map((el) => {
                  const amountWithTax =
                    getSparePartPriceWithTax(
                      sellingPriceNewElements.find((quote_element) => quote_element.id === el.id).selling_price / 100,
                      1,
                      sparePartsAlreadyInCart - 1
                    ) * 100;
                  return {
                    ...el,
                    sku_id: el.sku_id,
                    amount_withtax: amountWithTax,
                    amount_pretax: getPricePretax(amountWithTax),
                    base_price: Math.ceil(
                      sellingPriceNewElements.find((quote_element) => quote_element.id === el.id).selling_price
                    ),
                  };
                });
              this.setState({
                newElements: [
                  ...this.state.newElements.filter(
                    (element) => !sellingPriceNewElements.map((el) => el.id).includes(element.id)
                  ),
                  ...updateSellingPriceInElement,
                ],
                sellingPriceIsReady: true,
              });
            }
          })
        );
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (!prevProps.record && this.props.record) {
      this.setState({ quote: this.props.record });
    }
    if (prevProps.record.customer_file !== this.props.record.customer_file) {
      // Reset new elements and initialize the array with orders from the last tickets
      this.setState({ newElements: [] }, () => {
        this.getTotalPaymentAmount(this.props.record.customer_file);
        if (this.props.sparePartsOrders) {
          this.handleQuoteElementFromSparePartOrder(this.props.sparePartsOrders);
        }
        if (this.props.hermeticUnitsOrders) {
          this.handleQuoteElementFromHermeticUnitsOrders(this.props.hermeticUnitsOrders);
        }
      });
      this.updateSellingPriceNewElements();
    }
    if (
      this.state.quote !== prevState.quote ||
      prevState.newElements !== this.state.newElements ||
      this.state.modifiedSpareParts !== prevState.modifiedSpareParts ||
      this.state.sparePartsToRemove !== prevState.sparePartsToRemove
    ) {
      this.props.saveCurrentEditionSession({
        lastQuote: this.props.record,
        isEditionSessionPending: true,
        quote: this.state.quote,
        newElements: this.state.newElements,
        modifiedSpareParts: this.state.modifiedSpareParts,
        sparePartsToRemove: this.state.sparePartsToRemove,
      });
    }
    if (prevState.newElements !== this.state.newElements && !this.state.sellingPriceIsReady) {
      this.updateSellingPriceNewElements();
    }
  };

  getActualPackage = () => {
    const actualPackage = [...this.state.quote.quote_elements, ...this.state.newElements].find(
      (element) => element.package_type !== null
    );
    return (
      actualPackage && getQuoteElementProperty(actualPackage.package_type, this.state.packageTypeList, "package_name")
    );
  };

  /**
   * Gets the spare parts indicate in the last instervention report and checks if there is some stock
   * available in Murfy store. It also checks the availability of the
   * spare part in ASWO store by calling getAswoSparePart.
   * @param {array} sparePartsFromRepairReport
   */
  handleQuoteElementFromSparePartOrder = async (sparePartsFromRepairReport) => {
    if (!sparePartsFromRepairReport) return;

    const aswo = await getAswoSupplier();

    const elements = await sparePartsFromRepairReport.reduce(async (elements, sparePart) => {
      const spareName = Array.isArray(sparePart.name) ? sparePart.name.filter((name) => !!name)[0] : sparePart.name;
      let spareAswoRef = Array.isArray(sparePart.ref) ? sparePart.ref.filter((ref) => !!ref)[0] : sparePart.ref;
      if (!spareAswoRef && !spareName) {
        return await elements;
      }

      // If no reference is provided by the repairman, add an element with designation only
      if (!spareAswoRef) {
        return Promise.resolve([
          ...(await elements),
          formatSparePartData({
            skuId: null,
            supplier: null,
            spareAswoRef: null,
            spareName: spareName,
            customerFile: this.props.record.customer_file,
            isInMurfyStock: false,
            sparePartsAlreadyInCart: getNumberOfSparePartElements(this.state.newElements),
            isCorrespondance: false,
            stockPrice: 0,
            productId: sparePart.productId,
            isSecondHandAvailable: null,
            data: null,
          }),
        ]);
      }

      spareAswoRef = formatAswoRef(spareAswoRef);

      const [skus, { aswoSparePart: data }] = [
        await getSkus({ supplier: aswo.id, reference: spareAswoRef, type: ["new", "second_hand"], similar: true }),
        await getAswoSparePart(spareAswoRef),
      ];

      // Select any available new sku, or the original sku
      const newSkus = skus.filter((sku) => sku.type === SKU_TYPE_NEW);
      const availableNewSku = newSkus.find((sku) => sku.stock_available >= 1);
      const originalNewSku = newSkus.find((sku) => sku.substitution_type === SKU_SUBSTITUTION_TYPE_ORIGINAL);
      const sku = availableNewSku || originalNewSku;

      const isSecondHandAvailable = skus.some((sku) => sku.type === SKU_TYPE_SECOND_HAND);

      // Process aswo payload to return no data if there is an error returned
      const aswoPayload = data && !data.error ? data : null;

      if (sku) {
        const sellingPrice = await getSkuSellingPrice(sku.id);
        return Promise.resolve([
          ...(await elements),
          {
            ...formatSparePartData({
              skuId: sku.id,
              supplier: sku.references[0].supplier_name,
              spareAswoRef: sku.references[0].reference,
              spareName: sku.designation,
              customerFile: null,
              isInMurfyStock: true,
              sparePartsAlreadyInCart: getNumberOfSparePartElements(this.state.newElements),
              isCorrespondance: sku.substitution_type === SKU_SUBSTITUTION_TYPE_SUBSTITUTE,
              stockPrice: sellingPrice / 100,
              productId: sparePart.productId,
              isSecondHandAvailable: isSecondHandAvailable,
              data: aswoPayload,
            }),
            type: sku.substitution_type,
          },
        ]);
      } else {
        const { data } = await getSellingPrice(spareAswoRef, SUPPLIER_NAME_ASWO, SKU_TYPE_NEW, spareName);
        return Promise.resolve([
          ...(await elements),
          formatSparePartData({
            skuId: null,
            supplier: SUPPLIER_NAME_ASWO,
            spareAswoRef: spareAswoRef,
            spareName: spareName,
            customerFile: this.props.record.customer_file,
            isInMurfyStock: false,
            sparePartsAlreadyInCart: getNumberOfSparePartElements(this.state.newElements),
            isCorrespondance: false,
            stockPrice: data.selling_price / 100,
            productId: sparePart.productId,
            isSecondHandAvailable: false,
            data: aswoPayload,
          }),
        ]);
      }
    }, []);

    if (elements.length >= 1) {
      this.addNewElementsInQuote(elements);
    }
  };

  handleQuoteElementFromHermeticUnitsOrders = (hermeticUnitsOrders) => {
    const hermeticUnitsToAdd = (hermeticUnitsOrders || []).map((hermeticUnit) => {
      return {
        element_type: HERMETIC_UNIT,
        amount_pretax: getPricePretax(HERMETIC_UNIT_PRICE),
        amount_withtax: HERMETIC_UNIT_PRICE,
        customer_file: this.props.record.customer_file,
        quote_billing: null,
        admin_comments: null,
        home_repair_product: hermeticUnit.productId,
      };
    });
    if (hermeticUnitsToAdd.length > 0) {
      this.addNewElementsInQuote(hermeticUnitsToAdd);
    }
  };

  // Api calls
  getPackageTypesList = () => {
    getPackageTypesList().then(({ data }) => {
      this.setState({ packageTypeList: data });
    });
  };

  getTotalPaymentAmount = (customerFileId) => {
    getTotalPaymentAmount(customerFileId).then(({ data }) => {
      let total = 0;
      data.forEach((element) => {
        if (element.amount) {
          total += parseInt(element.amount);
        }
      });
      this.setState({ totalAmountPayment: total, isReadyToDisplay: true });
    });
  };

  getLastPaymentIntentAmount = () => {
    getLastPaymentIntentAmount(this.props.customerFile.id).then(({ data }) => {
      let amount = 0;
      if (data) {
        amount = data.amount / 100;
      }
      this.setState({ amountPaymentIntent: amount });
    });
  };

  handleDisplayNewQuoteForm = () => {
    this.props.handleDisplayNewQuoteForm(this.props.record);
  };

  cancelQuoteEdition = () => {
    this.props.resetCurrentEditionSession();
    this.handleDisplayNewQuoteForm();
  };

  handleCreateQuote = () => {
    this.props.handleQuoteCreation(
      this.state.quote,
      this.state.newElements,
      this.state.emailTemplate,
      this.state.delay,
      this.state.sparePartsToRemove
    );
    this.props.resetCurrentEditionSession();
  };

  handleValidatePayment = (
    paymentNature,
    amount,
    paymentType,
    selectedTech,
    selectedDate,
    paymentPartner,
    partnerReference
  ) => {
    this.props.handlePaymentCreation(
      paymentNature,
      amount,
      paymentType,
      selectedTech,
      selectedDate,
      paymentPartner,
      partnerReference,
      this.props.record.id,
      this.getTotalPaymentAmount
    );
    this.handleClosePaymentModal();
  };

  openAddPaymentModal = () => {
    this.getLastPaymentIntentAmount();
    this.setState({ isAddPaymentModalOpen: true });
  };

  handleClosePaymentModal = () => {
    this.setState({ shouldAddPaymentModalBeOpened: false }, () => {
      this.setState({ isAddPaymentModalOpen: this.state.shouldAddPaymentModalBeOpened });
    });
  };

  openEmailSelectorModal = () => {
    this.setState({ isEmailSelectorModalOpen: true });
  };

  handleCloseEmailSelectorModal = () => {
    this.setState({ isEmailSelectorModalOpen: false });
  };

  handleValidateEmailTemplate = (emailTemplate) => {
    this.setState({ isEmailSelectorModalOpen: false, emailTemplate }, () => {
      if (this.hasSpareParts() && emailTemplate !== NO_TEMPLATE) {
        this.handleOpenDelaySelectionModal();
      } else {
        this.handleCreateQuote();
      }
    });
  };

  hasSpareParts = () => {
    const spareParts = sparePartsFromQuoteElements(this.props.record.quote_elements);
    const newSpareParts = sparePartsFromQuoteElements(this.state.newElements);

    return spareParts || newSpareParts;
  };

  openSparePartStatusModal = () => {
    this.setState({ isSparePartStatusModalOpen: true });
  };

  closeSparePartStatusModal = () => {
    this.setState({ isSparePartStatusModalOpen: false });
  };

  handleOpenPackageModificationModal = (packageId, productId) => {
    this.setState({
      isPackageModificationModalOpen: true,
      packageId: packageId,
      productId: productId,
    });
  };

  handleSparePartModification = (sparePart, sparePartId) => {
    this.setState({
      isSparePartModificationModalOpen: true,
      sparePartToBeModified: sparePart,
      sparePartIdToBeModified: sparePartId,
    });
  };

  handleOpenDelaySelectionModal = () => {
    this.setState({ isDelaySelectionModalOpen: true });
  };

  handleCloseDelaySelectionModal = () => {
    this.setState({ isDelaySelectionModalOpen: false });
  };

  handleValidateDelay = (delay) => {
    this.setState({ isDelaySelectionModalOpen: false, delay }, () => this.handleCreateQuote());
  };

  updateSparePart = (amount, name) => {
    const quoteData = this.state.quote;
    const newQuoteElements = this.state.newElements;
    let sparePartIndex = null;
    const actualSparePart = this.state.sparePartToBeModified;

    const sparePartAmountWithtax = parseInt(amount * 100);
    // in the case of an already created spare part
    if (quoteData.quote_elements.find((element) => element.id === this.state.sparePartToBeModified.id)) {
      sparePartIndex = quoteData.quote_elements.findIndex(
        (element) => element.id === this.state.sparePartToBeModified.id
      );
      quoteData.quote_elements[sparePartIndex].amount_withtax = sparePartAmountWithtax;
      quoteData.quote_elements[sparePartIndex].amount_pretax = getPricePretax(sparePartAmountWithtax);
      quoteData.quote_elements[sparePartIndex].name = name;
      this.setState({ quote: quoteData });
    }
    // or if it is a new spare part
    else {
      let elementUpdated = newQuoteElements.find((element) => element.id === this.state.sparePartIdToBeModified);
      if (elementUpdated) {
        this.setState({
          newElements: [
            ...newQuoteElements.filter((element) => element.id !== elementUpdated.id),
            {
              ...elementUpdated,
              amount_withtax: sparePartAmountWithtax,
              amount_pretax: getPricePretax(sparePartAmountWithtax),
              name,
              spare_part: {
                ...elementUpdated.spare_part,
                piece_name: name,
                quantity: 1,
              },
            },
          ],
        });
      } else {
        sparePartIndex = this.state.sparePartIdToBeModified;
        newQuoteElements[sparePartIndex].amount_withtax = sparePartAmountWithtax;
        newQuoteElements[sparePartIndex].amount_pretax = getPricePretax(sparePartAmountWithtax);
        newQuoteElements[sparePartIndex].name = name;
        newQuoteElements[sparePartIndex].spare_part.piece_name = name;
        newQuoteElements[sparePartIndex].spare_part.quantity = 1;
        this.setState({
          newElements: newQuoteElements,
        });
      }
    }
    this.setState({
      modifiedSpareParts: [...this.state.modifiedSpareParts, actualSparePart],
      isSparePartPriceModifiable: false,
      sparePartToBeModified: null,
    });
  };

  handleClosePackageModificationModal = () => {
    this.setState({ isPackageModificationModalOpen: false });
  };

  handleCloseSparePartModificationModal = () => {
    this.setState({ isSparePartModificationModalOpen: false, sparePartToBeModified: null });
  };

  handleValidateSparePartStatus = (id, status) => {
    const quoteTmp = this.state.quote;
    quoteTmp.quote_elements.splice(this.state.savedIndex, 1);
    this.setState({
      sparePartsToRemove: [...this.state.sparePartsToRemove, { id, status }],
      quote: quoteTmp,
    });
    this.closeSparePartStatusModal();
  };

  handleChangeSendConfirmationEmail = () => {
    this.setState((prevState) => {
      return { sendConfirmationEmail: !prevState.sendConfirmationEmail };
    });
  };

  handleClickhandleUpdateQuote = (quoteStatus) => {
    this.props.handleUpdateQuote(this.props.record, quoteStatus, this.state.sendConfirmationEmail);
  };

  removeElementInQuote = (elementId) => {
    const quoteTmp = this.state.quote;
    const elementIndex = quoteTmp.quote_elements.findIndex((element) => element.id === elementId);
    if (elementIndex !== -1) {
      if (quoteTmp.quote_elements[elementIndex].element_type === SPARE_PART) {
        this.setState({
          sparePartSelected: quoteTmp.quote_elements[elementIndex].spare_part,
          savedIndex: elementIndex,
        });
        this.openSparePartStatusModal();
      } else {
        quoteTmp.quote_elements.splice(elementIndex, 1);
        this.setState({ quote: quoteTmp });
      }
    } else {
      this.removeNewElementInQuote(elementId); // new package to change case
    }
  };

  removeNewElementInQuote = (elementId) => {
    const elementIndex = this.state.newElements.findIndex((element) => element.id === elementId);
    const elementToRemove = this.state.newElements[elementIndex];
    let newElementsTmp = this.state.newElements;
    newElementsTmp.splice(elementIndex, 1);
    newElementsTmp = refreshSparePartsElementsPrices(newElementsTmp, 0, elementToRemove.element_type);
    this.setState({ newElements: newElementsTmp });
  };

  isValidateButtonDisabled = () => {
    return (
      this.state.newElements &&
      this.state.newElements.length === 0 &&
      this.state.quote &&
      this.state.elementsLength === this.state.quote.quote_elements.length &&
      this.state.modifiedSpareParts.length === 0
    );
  };

  getQuoteTotalAmount = (quoteElementsList) => {
    return (
      quoteElementsList &&
      quoteElementsList.reduce((accumulator, quoteElement) => accumulator + quoteElement.amount_withtax, 0)
    );
  };

  handleQuoteValidation = () => {
    this.openEmailSelectorModal();
  };

  addNewElementsInQuote = (newElementsToAdd) => {
    /* If 1 or several spare parts are added, we must disable the package type modification modification */
    if (newElementsToAdd[0].element_type === SPARE_PART) {
      this.setState({ isNewSparePartAdded: true });
    }

    this.setState({
      newElements: [
        ...refreshSparePartsElementsPrices(
          this.state.newElements,
          newElementsToAdd.length,
          newElementsToAdd[0].element_type
        ),
        ...newElementsToAdd,
      ],
    });
  };

  render() {
    const postalCode = this.props.customerFile && this.props.customerFile.postal_code;
    const quoteTotalAmountCents =
      this.props.record.quote_refused && this.props.amountLastNonRefusedQuote
        ? this.props.amountLastNonRefusedQuote
        : this.getQuoteTotalAmount(this.state.quote.quote_elements);

    const quoteTotalNewElementsAmountCents = this.getQuoteTotalAmount(this.state.newElements);
    const quoteTotalPaymentAmountCents = this.state.totalAmountPayment;
    const leftToPayEditSessionAmountCents =
      quoteTotalAmountCents + quoteTotalNewElementsAmountCents - quoteTotalPaymentAmountCents;
    const leftToPayQuoteCents = quoteTotalAmountCents - quoteTotalPaymentAmountCents;
    const { classes } = this.props;

    const homeRepairProductIds = this.props.customerFile.products.map((product) => product.id);
    return (
      <div className={classes.detailsWrapper}>
        {/**
         * DISPLAY A LIST OF QUOTE ELEMENTS FOR EACH PRODUCT OF THE CUSTOMER FILE
         */}
        {homeRepairProductIds.map((productId, index) => (
          <Box border={"1px solid"} borderRadius={12} borderColor={homeRepairPaletteArray[index]} p={2} mb={3}>
            <Box textAlign="left" mt={-4} bgcolor="white" width="min-content">
              <HomeRepairTag productId={productId} customerFile={this.props.customerFile} />
            </Box>
            {/**
             * DISPLAY EXISTING ELEMENTS
             */}
            {this.props.isCreationQuote ? (
              <div>
                {this.state.quote &&
                  this.state.quote.quote_elements.filter((element) => element.home_repair_product === productId) &&
                  this.state.quote.quote_elements
                    .filter((element) => element.home_repair_product === productId && !element.auto_generated)
                    .map((element) => (
                      <QuoteElement
                        productId={productId}
                        addNewElementsInQuote={this.addNewElementsInQuote}
                        key={element.id}
                        record={element}
                        customerFile={this.props.record.customer_file}
                        customerFileObject={this.props.customerFile}
                        removeElementInQuote={this.removeElementInQuote}
                        handleOpenPackageModificationModal={this.handleOpenPackageModificationModal}
                        packageTypeList={this.state.packageTypeList}
                        isNewSparePartAdded={this.state.isNewSparePartAdded}
                        handleSparePartModification={this.handleSparePartModification}
                        isSparePartPriceModifiable={this.state.isSparePartPriceModifiable}
                        sparePartIdToBeModified={this.state.sparePartIdToBeModified}
                        isModifiable
                        billingElementsList={this.state.newElements}
                      />
                    ))}
              </div>
            ) : (
              <div>
                <Box>
                  <ReferenceManyField
                    filter={{ home_repair_product: productId }}
                    reference="billing-elements"
                    target="quote_billing_id"
                    record={this.props.record}
                    basePath=""
                  >
                    <QuoteElementStyleDatagrid record={this.record}>
                      <QuoteElement
                        productId={productId}
                        record={this.record}
                        packageTypeList={this.state.packageTypeList}
                        customerFile={this.props.record.customer_file}
                        customerFileObject={this.props.customerFile}
                      />
                    </QuoteElementStyleDatagrid>
                  </ReferenceManyField>
                </Box>
              </div>
            )}
            {/**
             * DISPLAY NEW ELEMENTS AND BUTTON TO CREATE NEW ONES
             */}
            {!this.props.isLast && this.props.isCreationQuote && (
              <div className={classes.newQuoteElementsContainer}>
                <Typography className={classes.newElementsTitle}>Nouveaux éléments</Typography>
                {[
                  ...this.state.newElements,
                  ...this.state.quote.quote_elements.filter((element) => element.auto_generated),
                ]
                  .filter((element) => element.home_repair_product === productId)
                  .map((element, index) => (
                    <QuoteElement
                      key={index}
                      productId={productId}
                      classes={classes}
                      record={element}
                      isModifiable
                      customerFile={this.props.record.customer_file}
                      customerFileObject={this.props.customerFile}
                      removeElementInQuote={this.removeElementInQuote}
                      index={index}
                      handleOpenPackageModificationModal={this.handleOpenPackageModificationModal}
                      isNewSparePartAdded={this.state.isNewSparePartAdded}
                      handleSparePartModification={this.handleSparePartModification}
                      isSparePartPriceModifiable={this.state.isSparePartPriceModifiable}
                      sparePartIdToBeModified={this.state.sparePartIdToBeModified}
                      packageTypeList={this.state.packageTypeList}
                      addNewElementsInQuote={this.addNewElementsInQuote}
                      billingElementsList={this.state.newElements.filter(
                        (element) => element.home_repair_product === productId
                      )}
                    />
                  ))}
                <AddElementInQuote
                  productId={productId}
                  postalCode={postalCode}
                  employees={this.props.employees}
                  visits={this.props.visits}
                  newElements={this.state.newElements.filter((element) => element.home_repair_product === productId)}
                  addNewElementsInQuote={this.addNewElementsInQuote}
                  actualPackageSelected={this.getActualPackage()}
                  isNewSparePartAdded={this.state.isNewSparePartAdded}
                  packageTypeList={this.state.packageTypeList}
                />
              </div>
            )}
          </Box>
        ))}

        {/**
         * TRANSITION :
         * DISPLAY EXISTING ELEMENTS WITH NO ASSOCIATED HOMEREPAIR
         */}
        {this.props.isCreationQuote ? (
          <div>
            {this.state.quote &&
              this.state.quote.quote_elements
                .filter((element) => !element.home_repair_product)
                .map((element) => (
                  <QuoteElement
                    addNewElementsInQuote={this.addNewElementsInQuote}
                    key={element.id}
                    record={element}
                    customerFile={this.props.record.customer_file}
                    customerFileObject={this.props.customerFile}
                    removeElementInQuote={this.removeElementInQuote}
                    handleOpenPackageModificationModal={this.handleOpenPackageModificationModal}
                    packageTypeList={this.state.packageTypeList}
                    isNewSparePartAdded={this.state.isNewSparePartAdded}
                    handleSparePartModification={this.handleSparePartModification}
                    isSparePartPriceModifiable={this.state.isSparePartPriceModifiable}
                    sparePartIdToBeModified={this.state.sparePartIdToBeModified}
                    isModifiable
                    billingElementsList={this.state.newElements}
                  />
                ))}
          </div>
        ) : (
          <div>
            <Box>
              <ReferenceManyField
                filter={{ home_repair_product: "undefined" }}
                reference="billing-elements"
                target="quote_billing_id"
                record={this.props.record}
                basePath=""
              >
                <QuoteElementStyleDatagrid record={this.record}>
                  <QuoteElement
                    record={this.record}
                    packageTypeList={this.state.packageTypeList}
                    customerFile={this.props.record.customer_file}
                    customerFileObject={this.props.customerFile}
                  />
                </QuoteElementStyleDatagrid>
              </ReferenceManyField>
            </Box>
          </div>
        )}

        {!this.props.isLast && this.props.isCreationQuote && (
          <>
            <EmailSelectorModal
              isEmailSelectorModalOpen={this.state.isEmailSelectorModalOpen}
              handleCloseEmailSelectorModal={this.handleCloseEmailSelectorModal}
              handleValidateEmailTemplate={this.handleValidateEmailTemplate}
              hasSpareParts={this.hasSpareParts}
            />
            <SparePartStatusModal
              isSparePartStatusModalOpen={this.state.isSparePartStatusModalOpen}
              handleCloseSparePartStatusModal={this.closeSparePartStatusModal}
              handleValidateSparePartStatus={this.handleValidateSparePartStatus}
              sparePartSelected={this.state.sparePartSelected}
            />
            <DelaySelectionModal
              isDelaySelectionModalOpen={this.state.isDelaySelectionModalOpen}
              handleCloseDelaySelectionModal={this.handleCloseDelaySelectionModal}
              handleValidateDelay={this.handleValidateDelay}
            />
          </>
        )}

        <PackageEditionModal
          isOpen={this.state.isPackageModificationModalOpen}
          handleCloseModal={this.handleClosePackageModificationModal}
          addNewElementsInQuote={this.addNewElementsInQuote}
          removeElementInQuote={this.removeElementInQuote}
          productId={this.state.productId}
          packageId={this.state.packageId}
          packageTypeList={this.state.packageTypeList}
          postalCode={postalCode}
        />
        {this.state.isSparePartModificationModalOpen && (
          <SparePartEditionModal
            isSparePartModificationModalOpen={this.state.isSparePartModificationModalOpen}
            handleCloseSparePartModificationModal={this.handleCloseSparePartModificationModal}
            sparePartToBeModified={this.state.sparePartToBeModified}
            updateSparePart={this.updateSparePart}
          />
        )}

        {!this.props.isLast && (
          <QuoteDetailsFooter
            isCreationQuote={this.props.isCreationQuote}
            isNewQuoteFormOpen={this.props.isNewQuoteFormOpen}
            record={this.props.record}
            isReadyToDisplay={this.state.isReadyToDisplay}
            handleDisplayNewQuoteForm={this.handleDisplayNewQuoteForm}
            openAddPaymentModal={this.openAddPaymentModal}
            handleClosePaymentModal={this.handleClosePaymentModal}
            handleValidatePayment={this.handleValidatePayment}
            isAddPaymentModalOpen={this.state.isAddPaymentModalOpen}
            employees={this.props.employees}
            customerFile={this.props.customerFile}
            visits={this.props.visits}
            amountPaymentIntent={this.state.amountPaymentIntent}
            totalAmountQuote={quoteTotalAmountCents}
            totalAmountPayment={quoteTotalPaymentAmountCents}
            classes={classes}
            quoteTotalPaymentAmountCents={quoteTotalPaymentAmountCents}
            leftToPayQuoteCents={leftToPayQuoteCents}
            handleClickhandleUpdateQuote={this.handleClickhandleUpdateQuote}
            handleChangeSendConfirmationEmail={this.handleChangeSendConfirmationEmail}
            sendConfirmationEmail={this.state.sendConfirmationEmail}
            leftToPayEditSessionAmountCents={leftToPayEditSessionAmountCents}
            isValidateButtonDisabled={this.isValidateButtonDisabled()}
            cancelQuoteEdition={this.cancelQuoteEdition}
            handleQuoteValidation={this.handleQuoteValidation}
          />
        )}
      </div>
    );
  }
}
export default withStyles(quoteDetailsStyles)(QuoteDetails);
