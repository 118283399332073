import React from "react";

import { AutocompleteInput, Filter, ReferenceInput, SearchInput, SelectInput } from "react-admin";

import { ThemeProvider } from "@material-ui/core/styles";

import CustomFiltersTheme from "shared/themes/customFiltersTheme.js";

const StatusChoices = [
  { id: "emitted", name: "Émise" },
  { id: "waiting_for_sku", name: "En attente de pièces" },
  { id: "loss_declared", name: "Perte déclarée" },
  { id: "waiting_for_affectation", name: "En attente d'affectation" },
  { id: "waiting_for_packing", name: "En attente de colisage" },
  { id: "being_prepared", name: "En préparation" },
  { id: "sent", name: "Expédié" },
  { id: "available", name: "Livré" },
];

const CategoryChoices = [
  { id: "home_repair", name: "Réparation à domicile" },
  { id: "workshop_repair", name: "Réparation en atelier" },
];

const renderEmployee = (employee) => (employee.id ? `${employee.first_name} ${employee.last_name}` : "Tout");

class SkuDemandListFilter extends React.Component {
  componentWillUnmount() {
    this.setFilterProps();
  }

  setFilterProps() {
    if (this.props.setFilters) {
      this.props.setFilters({});
    }
  }

  isEmployeePlanner = () => {
    let isEmployeePlanner = this.props.planners.find((x) => x.id === this.props.idEmployee);
    return isEmployeePlanner ? true : false;
  };

  render() {
    return (
      <ThemeProvider theme={CustomFiltersTheme}>
        <Filter {...this.props}>
          <SearchInput
            source="q"
            alwaysOn
            label="Rechercher"
            placeholder=""
            inputProps={{
              style: {
                paddingTop: 20,
              },
            }}
          />
          <ReferenceInput
            label="Technicien"
            reference="employees"
            source="employee_id"
            filter={{ role: "repairman" }}
            alwaysOn
            allowEmpty
          >
            <AutocompleteInput
              InputProps={{ disableUnderline: true }}
              optionValue="id"
              optionText={renderEmployee}
              emptyText="Tout"
            />
          </ReferenceInput>
          <ReferenceInput label="Atelier" reference="workshop" source="workshop" alwaysOn allowEmpty>
            <AutocompleteInput
              InputProps={{ disableUnderline: true }}
              optionValue="id"
              optionText="name"
              emptyText="Tout"
            />
          </ReferenceInput>
          <SelectInput
            source="status"
            choices={StatusChoices}
            alwaysOn
            emptyText="Tout"
            InputProps={{ disableUnderline: true }}
            label="Statut"
          />
          <SelectInput
            source="customer_file_category"
            label="Type de dossier"
            choices={CategoryChoices}
            alwaysOn
            emptyText="Tout"
            InputProps={{ disableUnderline: true }}
          />
          <SelectInput
            source="assigned_employees"
            label="Planeur"
            choices={this.props.planners}
            initialValue={this.isEmployeePlanner() ? this.props.idEmployee : null}
            optionText="first_name"
            alwaysOn
            emptyText="Tout"
            InputProps={{ disableUnderline: true }}
          />
          <SelectInput
            source="delay"
            label="Retard"
            choices={[
              { id: "delay", name: "Retard" },
              { id: "urgent", name: "Urgent" },
              { id: "ok", name: "Ok" },
            ]}
            alwaysOn
            emptyText="Tout"
            InputProps={{ disableUnderline: true }}
          />
        </Filter>
      </ThemeProvider>
    );
  }
}

export default SkuDemandListFilter;
