import React from "react";

import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";
import CloudDoneIcon from "@material-ui/icons/CloudDone";
import WarningIcon from "@material-ui/icons/Warning";

import { Listing } from "types/listing";

import dataProvider from "dataProvider";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: "4px",
  },
  buttonOnline: {
    // @ts-ignore
    color: theme.palette.secondary.green,
  },
  buttonOffline: {
    // @ts-ignore
    color: theme.palette.secondary.red,
  },
}));

type ListingPublishButtonProps = {
  record: Listing;
  disabled: boolean;
  refreshWorkshopFileData: () => null;
};

export default ({ record: listing, disabled, refreshWorkshopFileData }: ListingPublishButtonProps) => {
  const classes = useStyles();

  const setOfflineListing = () => {
    dataProvider.update("ecom/listings", { id: listing.id, data: { unpublish: true } }).then((response) => {
      refreshWorkshopFileData();
    });
  };

  const setOnlineListing = () => {
    dataProvider.update("ecom/listings", { id: listing.id, data: { publish: true } }).then((response) => {
      refreshWorkshopFileData();
    });
  };

  return listing.published_at === null ? (
    <Button disabled={disabled || !listing.is_publishable} onClick={setOnlineListing} className={classes.buttonOnline}>
      <CloudDoneIcon className={classes.icon} />
      Mettre en ligne
    </Button>
  ) : (
    <Button onClick={setOfflineListing} className={classes.buttonOffline}>
      {listing.is_publishable ? <CancelIcon className={classes.icon} /> : <WarningIcon className={classes.icon} />}
      Mettre hors ligne
    </Button>
  );
};
