import { push } from "connected-react-router";
import find from "lodash/find";
import queryString from "query-string";
import { put, select, takeEvery } from "redux-saga/effects";
import { pushBreadcrumbElement } from "shared/components/Breadcrumb/module";

export const REDIRECT_TO_APPLICATION = "Menu/REDIRECT_TO_APPLICATION";

export function redirectToApplication(applicationId, applicationName, isPortal = false) {
  return {
    type: REDIRECT_TO_APPLICATION,
    payload: { applicationId, applicationName, isPortal },
    meta: {
      analytics: {
        type: "SEND_EVENT",
        payload: {
          action: "SELECT_APPLICATION",
          category: "APPLICATION",
          label: applicationName,
          trackerName: "user",
        },
      },
    },
  };
}

export const isActiveRouteSelector = (state, applicationId) =>
  parseInt(queryString.parse(state.router.location.search).id, 10) === applicationId;

/** In case where application is a smart_diagnostic, redirect to diagnostic */
export const applicationRouteSelector = (state, applicationId, isPortal = false) => {
  const application = find(state.company.applications, { id: applicationId });
  const { id, widget } = application;
  const applicationRoute =
    application.application_type === "smart_diagnostic" ? "diagnostic" : application.application_type;
  let baseUrl = `/${applicationRoute}/${widget}/?id=${id}`;
  if (isPortal) baseUrl = "/portal" + baseUrl;
  return baseUrl;
};

export function* pushApplication(action) {
  const { applicationId, applicationName, isPortal = false } = action.payload;
  const route = yield select(applicationRouteSelector, applicationId, isPortal);
  yield put(pushBreadcrumbElement(applicationName, route));
  yield put(push(route));
}

export function* pushApplicationSaga() {
  yield takeEvery(REDIRECT_TO_APPLICATION, pushApplication);
}
