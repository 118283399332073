import React from "react";

import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import GetAppIcon from "@material-ui/icons/GetApp";

const useStyles = makeStyles({
  downloadButton: {
    display: "flex",
    color: "#3E3E3E",
    fontSize: 15,
    cursor: "pointer",
  },
});

export default ({ quote }) => {
  const classes = useStyles();

  const url =
    quote.uuid &&
    `${process.env.REACT_APP_API_URL}/payment/quotebilling/${quote.uuid}/${quote.quote_accepted ? "invoice" : "pdf"}/`;

  const getButtonTitle = () => {
    if (quote.quote_accepted) return "Facture";
    if (quote.quote_refused) return "Devis refusé";
    return "Devis émis";
  };

  const download = () => url && window.open(url, "_blank");

  return (
    <Tooltip title={`Télécharger ${quote.quote_accepted ? "la facture" : "le devis"}`}>
      <div onClick={download} className={classes.downloadButton}>
        {getButtonTitle()} <GetAppIcon />
      </div>
    </Tooltip>
  );
};
