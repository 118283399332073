import React from "react";

import Box from "@material-ui/core/Box";

import { Invoice } from "types/invoice";

import Small from "components/atoms/Typography/Small";

type VoucherItemsProps = {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isOneItemSelected: boolean;
  items: Invoice["items"];
  selectedItemsIds: number[];
};

const VoucherItems: React.FunctionComponent<VoucherItemsProps> = ({
  handleChange,
  isOneItemSelected,
  items,
  selectedItemsIds,
}) => {
  return (
    <Box display="flex" width="100%">
      <fieldset>
        {items.map((item) => (
          <Box display="flex">
            <Box mr={1}>
              <input
                type="checkbox"
                name="voucher-item"
                onChange={handleChange}
                value={item.id}
                id={`voucher-item-${item.id}`}
                checked={selectedItemsIds.includes(item.id)}
              />
            </Box>
            <label htmlFor={`voucher-item-${item.id}`}>
              {`${item.label} ${(item.price_tax_included / 100).toLocaleString("fr-FR", {
                style: "currency",
                currency: "EUR",
              })}`}
            </label>
          </Box>
        ))}
        <Box>
          {!isOneItemSelected && <Small color="secondary.red">Veuillez sélectionner au moins une ligne </Small>}
        </Box>
      </fieldset>
    </Box>
  );
};

export default VoucherItems;
