import React from "react";

import { Datagrid, DateField, List, Pagination, ReferenceField, TextField } from "react-admin";

import { Box, Typography } from "@material-ui/core";

import CurrencyField from "components/CurrencyField";

import PackagePriceFilter from "./PackagePriceFilter";
import PackagePriceToolbar from "./PackagePriceToolbar";

export default () => {
  return (
    <Box p={4}>
      <Typography variant="h2">Édition des Forfaits RD</Typography>
      <List
        basePath="/package-price"
        resource="package-price"
        exporter={false}
        bulkActionButtons={<PackagePriceToolbar />}
        hasCreate={false}
        hasEdit={false}
        hasShow={false}
        hasList={false}
        pagination={<Pagination rowsPerPageOptions={[25, 100]} />}
        perPage={25}
        filters={<PackagePriceFilter />}
      >
        <Datagrid>
          <ReferenceField label="Catégorie" reference="product-types" source="product_type">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="Zone" reference="zone" source="home_repair_zone">
            <TextField source="name" />
          </ReferenceField>
          <CurrencyField source="price" label="Tarif actuel" />
          <DateField source="updated_at" label="Mise à jour" />
        </Datagrid>
      </List>
    </Box>
  );
};
