import React, { Component } from "react";

import PropTypes from "prop-types";

import { Switch, ToggleContent, YoutubeVideoField } from "admin/components/";
import sortBy from "lodash/sortBy";

import Modal from "components/Modal";

import ImagesManagement from "pages/Cms/ContentManagement/components/ImagesManagement";

import "./style.css";

export default class MediaSelectionField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      hasMedia: props.hasMedia,
      mediaType: props.externalURL ? "external_url" : "image",
    };
  }

  static propTypes = {
    externalURL: PropTypes.string,
    hasMedia: PropTypes.bool.isRequired,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        image: PropTypes.number.isRequired,
        image_title: PropTypes.string.isRequired,
        public_id: PropTypes.string.isRequired,
        order: PropTypes.number.isRequired,
      })
    ),
    onExternalURLChange: PropTypes.func.isRequired,
    onHasMediaCheck: PropTypes.func,
    onHasMediaUncheck: PropTypes.func.isRequired,
    onImageClick: PropTypes.func.isRequired,
    onS3URLChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    externalURL: "",
    hasMedia: true,
    onHasMediaCheck: () => {},
  };

  setMediaType = (value) => () => this.setState({ mediaType: value });

  onExternalURLChange = (event) => this.props.onExternalURLChange(event.target.value);

  onCheckboxChange = (event) => {
    this.setState((prevState) => ({ hasMedia: !prevState.hasMedia }));
    event.target.checked ? this.props.onHasMediaCheck() : this.props.onHasMediaUncheck();
  };

  toggleModal = () => this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }));

  renderExternalURLSelector = () => (
    <div className="media-selection-field-subcontainer">
      <p className="media-selection-field-subtitle">Lien externe</p>
      <input
        id="externalURLSelector"
        className="media-selection-input"
        placeholder="URL de la vidéo"
        value={this.props.externalURL}
        onChange={this.onExternalURLChange}
      />
    </div>
  );

  render() {
    return (
      <ToggleContent isChecked={this.state.hasMedia} onCheckboxChange={this.onCheckboxChange} title="Media">
        <div className="media-selection-field-container">
          <Switch
            firstSwitch={{
              text: "Image",
              activeValue: "image",
              onSwitch: this.setMediaType("image"),
            }}
            secondSwitch={{
              text: "Lien externe",
              activeValue: "external_url",
              onSwitch: this.setMediaType("external_url"),
            }}
            value={this.state.mediaType}
          />
          {this.state.mediaType === "external_url" && (
            <YoutubeVideoField
              displayTitle={false}
              onYoutubeVideoURLChange={this.props.onExternalURLChange}
              youtubeVideoURL={this.props.externalURL}
            />
          )}
          {this.state.mediaType === "image" && (
            <div className="media-selection-field-all-images-container">
              {this.props.images &&
                sortBy(this.props.images, "order").map((image) => (
                  <div
                    key={`image-${image.id}`}
                    id={`image-${image.id}`}
                    className="media-selection-field-image-container"
                  >
                    <span>{image.order} - </span>
                    <span>{image.image_title}</span>
                  </div>
                ))}
              <p
                id="new-image-button"
                className="media-selection-field-add-button-container"
                onClick={this.toggleModal}
              >
                + Ajouter une nouvelle image
              </p>
            </div>
          )}
        </div>
        <Modal isOpen={this.state.isModalOpen} handleCloseModal={this.toggleModal}>
          <ImagesManagement onImageClick={this.props.onImageClick} />
        </Modal>
      </ToggleContent>
    );
  }
}
