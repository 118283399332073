import * as React from "react";

import { Typography } from "@material-ui/core";

const BillingAddressField = ({ order }) => (
  <Typography variant="body2">
    {order.billing_name}
    <br />
    {order.billing_street}
    <br />
    {order.billingzipcode} {order.billing_city}
  </Typography>
);

BillingAddressField.defaultProps = {
  addLabel: true,
};

export default BillingAddressField;
