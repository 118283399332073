import { connect } from "react-redux";

import Switch from "./Switch.component";

function mapStateToProps() {
  return {};
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Switch);
