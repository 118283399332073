import React from "react";

import { withStyles } from "@material-ui/core/styles";

import { DelayFieldStyles } from "./DelayFieldStyles";

const classFromDelayAndStatus = (delay, status, classes, isText) => {
  if (!isText) {
    if (status === "sent" || status === "available") {
      return classes.green;
    } else {
      if (!delay) {
        if (delay === 0) {
          return classes.orange;
        }
        return classes.gray;
      } else if (delay >= 1) {
        return classes.green;
      } else {
        return classes.red;
      }
    }
  } else {
    if (status === "sent") {
      return classes.textGreen;
    } else {
      if (!delay) {
        if (delay === 0) {
          return classes.textOrange;
        }
        return classes.textGray;
      } else if (delay >= 1) {
        return classes.textGreen;
      } else {
        return classes.textRed;
      }
    }
  }
};

export const DelayField = ({ record, classes, fromReorder, Sku }) => {
  record = fromReorder ? record.demand_item && record.demand_item.demand : record;
  const delay = Sku ? record.aswo_order_delay : record.delay;
  return (
    <div className={classes.wrapperBadge}>
      <div className={`${classes.delayBadge} ${classFromDelayAndStatus(delay, record.status, classes)}`} />
      {record.status === "sent" || record.status === "available" || (!delay && delay !== 0) ? (
        <></>
      ) : (
        <div className={`${classes.textBadge} ${classFromDelayAndStatus(delay, record.status, classes, true)}`}>
          {-delay} j
        </div>
      )}
    </div>
  );
};

export default withStyles(DelayFieldStyles)(DelayField);
