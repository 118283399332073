import React, { useState } from "react";

import { Loading, SelectInput, SimpleForm, useNotify } from "react-admin";

import { Box, Divider, Typography, styled } from "@material-ui/core";

import { CustomerFileDetailed } from "types/customer-files";
import { Product } from "types/products";
import { QuoteElement as QuoteElementType } from "types/quote-element";

import dataProvider from "dataProvider";

import Modal from "components/Modal";
import Button from "components/atoms/Button";

import { quoteElementList } from "pages/customerFiles/components/CustomerFileBillingQuotes/services/QuoteHelper.js";
import { getQuoteElementProperty } from "pages/customerFiles/components/CustomerFileBillingQuotes/services/getQuoteElementProperty";
import { sortByIdAndHomeRepair } from "pages/customerFiles/components/CustomerFileBillingQuotes/services/utils";

import { formatPriceCompact } from "utils/formatting";

type HomeRepairQuoteElementAssociationModalProps = {
  elements: QuoteElementType[];
  customerFile: CustomerFileDetailed;
};

const StyledSelectInput = styled(SelectInput)({
  "& p": {
    display: "none",
  },
});

const HomeRepairQuoteElementAssociationModal: React.FunctionComponent<HomeRepairQuoteElementAssociationModalProps> = ({
  elements,
  customerFile,
}) => {
  // COMPONENT STATE
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  // REACT ADMIN HOOKS
  const notify = useNotify();

  const productDescription = (product: Product) => `${product.product_type} ${product.brand}`;
  const updateQuoteElementHomeRepair = (element_id: number, product_id: number) => {
    setIsUpdating(true);
    dataProvider
      .update("billing-elements", {
        id: element_id,
        data: {
          home_repair_product: product_id,
        },
      })
      .then(() => {
        setIsUpdating(false);
        notify("Association effectuée");
      })
      .catch((error) => {
        setIsUpdating(false);
        notify(error.message, "warning");
      });
  };

  if (!elements) return null;

  return (
    <>
      {isModalOpen ? (
        <Modal modalClassName="modal-small-content" isOpen={isModalOpen} handleCloseModal={() => setIsModalOpen(false)}>
          {isUpdating && (
            <Box position="absolute" left="20%" top="25%" p={10} zIndex={2} bgcolor="white">
              <Loading loadingPrimary="Chargement" loadingSecondary="Association de l'élément en cours..." />;
            </Box>
          )}
          <Box style={{ opacity: isUpdating ? 0.2 : 1 }}>
            <Box textAlign="center" mb={3}>
              <Typography variant="h2">Associer les éléments du devis</Typography>
            </Box>
            {sortByIdAndHomeRepair(elements).map((element: QuoteElementType, index: number) => (
              <>
                <SimpleForm resource="billing-elements" record={element} toolbar={null}>
                  <Box display="flex" width="100% !important" alignItems="center">
                    <Box width="320px" display="flex" alignItems="center" justifyContent="space-between">
                      <Box>
                        {element.name
                          ? element.name.substring(0, 20)
                          : getQuoteElementProperty(element.element_type, quoteElementList, "name")}
                      </Box>
                      <Box ml={3}>{formatPriceCompact(element.amount_withtax)}</Box>
                    </Box>
                    <Box width="320px" ml={3}>
                      <StyledSelectInput
                        source="home_repair_product"
                        label="Appareil"
                        choices={customerFile.products}
                        optionText={productDescription}
                        fullWidth
                        onChange={(event: React.ChangeEvent<{ value: number }>) =>
                          updateQuoteElementHomeRepair(element.id, event.target.value)
                        }
                      />
                    </Box>
                  </Box>
                </SimpleForm>
                {index !== elements.length - 1 && <Divider />}
              </>
            ))}
            <Box textAlign="right" mt={3}>
              <Button version="fancy" onClick={() => setIsModalOpen(false)}>
                Valider
              </Button>
            </Box>
          </Box>
        </Modal>
      ) : (
        <Button version="invisible" onClick={() => setIsModalOpen(true)}>
          Associer les éléments
        </Button>
      )}
    </>
  );
};
export default HomeRepairQuoteElementAssociationModal;
