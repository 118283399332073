import * as React from "react";

import { Typography } from "@material-ui/core";

import { AFTER_SALES, COLLECTION, RECYCLING } from "constants/shipping";

const CollectionField = ({ order }) => {
  const items = order.items
    ? order.items.filter((item) => [COLLECTION, RECYCLING, AFTER_SALES].includes(item.delivery_type))
    : [];
  return items.length === 0 ? (
    <Typography variant="body">Aucun appareil</Typography>
  ) : (
    items.map((item) => (
      <>
        <Typography variant="body2" style={{ display: "inline-block" }}>
          {item.product_type} {item.product_brand} :
        </Typography>
        <Typography variant="caption"> {item.collection_code}</Typography>
        <br />
      </>
    ))
  );
};

CollectionField.defaultProps = {
  addLabel: true,
};

export default CollectionField;
