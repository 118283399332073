export const TASK_STATE_TODO = "todo";
export const TASK_STATE_DONE = "done";
export const TASK_STATE_ON_HOLD = "on_hold";
export const TASK_STATE_DEPRECATED = "deprecated";
export const TASK_STATE_EXPIRED = "expired";

export const UPDATABLE_TASK_STATE = [
  { id: TASK_STATE_TODO, name: "À faire" },
  { id: TASK_STATE_ON_HOLD, name: "En attente" },
  { id: TASK_STATE_DONE, name: "Terminée" },
];

export const COMPLETE_TASK_STATES = [
  ...UPDATABLE_TASK_STATE,
  { id: TASK_STATE_EXPIRED, name: "Expirée" },
  { id: TASK_STATE_DEPRECATED, name: "Obsolète" },
];
