import { useQuery } from "react-admin";

import { PackagePrices } from "types/package-prices";

export const usePackagePrices = (
  postal_code: string,
  product_type: string
): { data: PackagePrices; loading: boolean } => {
  const { data, loading } = useQuery({
    type: "getOne",
    resource: "repair-package-prices",
    payload: {
      postal_code: postal_code,
      product_type: product_type,
      sort: { field: "id", order: "DESC" },
    },
  }) as { data: PackagePrices; loading: boolean };
  return { data, loading };
};
