import React, { useState } from "react";

import {
  AutocompleteArrayInput,
  CheckboxGroupInput,
  SimpleForm,
  TextInput,
  required,
  useNotify,
  useRefresh,
} from "react-admin";

import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import { styled } from "@material-ui/styles";

import { CustomerFileDetailed } from "types/customer-files";
import { Employee } from "types/employees";
import { Product } from "types/products";

import { SimpleValidateToolbar } from "components/FormToolbars";

import CustomerFileState from "constants/customerFileStates";
import { REPORT_CHECK_REASONS_CHOICES } from "constants/reportCheckReasons";

import { needsTeamLeaderCheck, useTeamLeader } from "utils/api";
import { normalizeCase } from "utils/caseUtils";
import { useCustomerFilesStatesContext } from "utils/contexts/customerFileStatesContext";
import { useEmployeesContext } from "utils/contexts/employeesContext";
import { getEmployeeOfLastVisit } from "utils/getLastRepairmanOfFile";
import { TEAM_LEADER_REPORT_CHECK_DEPLOY_GROUP, hasPerm } from "utils/permissions";
import { getEmployeeFullName } from "utils/repairmenSortedList";

const useStyles = makeStyles({
  suggestionsContainer: {
    zIndex: 2000,
  },
});

const StyledButton = styled(Button)({
  padding: 8,
  textTransform: "none",
  height: 32,
});

type VerificationCheckActionsProps = {
  customerFile: CustomerFileDetailed;
  getCustomerFileDetail: (customerFileId: number) => void;
};

type FormValues = {
  comment: string;
  reasons: string[];
  products: number[];
  assignee: number | null;
};

const VerificationCheckActions: React.FunctionComponent<VerificationCheckActionsProps> = ({
  customerFile,
  getCustomerFileDetail,
}: VerificationCheckActionsProps) => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();

  const { customerFileStates } = useCustomerFilesStatesContext();
  const { employees } = useEmployeesContext();

  const technician = employees && getEmployeeOfLastVisit(customerFile, employees);
  const teamLeader: Employee | null = useTeamLeader(technician);

  const initialValues: FormValues = {
    comment: "",
    reasons: [],
    products: [],
    assignee: teamLeader && teamLeader["id"],
  };

  const getAssociatedRepairmanDisplay = (employee: Employee) => {
    if (!employee) return "Aucun technicien détecté";
    return `Technicien: ${getEmployeeFullName(employee)}`;
  };

  const getTeamLeaderDisplay = (teamLeader: Employee | null) => {
    if (!teamLeader) return "Aucun chef d'équipe associé";
    return `Chef d'équipe: ${getEmployeeFullName(teamLeader)}`;
  };

  const [isVerificationDetailsOpen, setIsVerificationDetailsOpen] = useState(false);
  const handlePostTeamLeaderCheck = async (values: FormValues) => {
    const { status } = await needsTeamLeaderCheck(customerFile.uuid, { payload: { ...values } });
    if (status < 200 || status >= 300) {
      notify("Une erreur est survenue pendant l'affectation au chef d'équipe", "warning");
    } else {
      refresh(); // refreshes the list
      getCustomerFileDetail(customerFile.id); // refreshes the file detail, ugh
      setIsVerificationDetailsOpen(false);
      notify("Dossier affecté avec succès ");
    }
  };

  const productDescription = (product: Product) => `${product.product_type}`;

  const isReportToBeProcessed = (customerFileStateId: number): boolean => {
    const currentState = customerFileStates && customerFileStates.find((state) => state.id === customerFileStateId);
    if (!currentState) return false;
    return (
      normalizeCase(currentState.name.toLowerCase()) ===
      normalizeCase(CustomerFileState.REPORT_TO_PROCESS.toLowerCase())
    );
  };

  return (
    <>
      {teamLeader &&
        hasPerm(teamLeader["permissions"], [TEAM_LEADER_REPORT_CHECK_DEPLOY_GROUP]) &&
        isReportToBeProcessed(customerFile.state) && (
          <StyledButton
            startIcon={<FindInPageIcon />}
            color="primary"
            variant="contained"
            onClick={() => setIsVerificationDetailsOpen(true)}
          >
            Vérification du CR requise
          </StyledButton>
        )}

      {isVerificationDetailsOpen && (
        <Dialog
          disableEnforceFocus
          open={isVerificationDetailsOpen}
          onClose={() => setIsVerificationDetailsOpen(false)}
        >
          <Box margin={2}>
            <SimpleForm
              save={handlePostTeamLeaderCheck}
              initialValues={initialValues}
              toolbar={
                <SimpleValidateToolbar
                  disabled={!teamLeader}
                  onSave={handlePostTeamLeaderCheck}
                  size="large"
                  label="Valider"
                  version="right"
                />
              }
            >
              <Box width="100% !important">
                <DialogTitle disableTypography={false}>
                  <Typography variant="h2">Vérification de CR requise</Typography>
                  <Box position="absolute" right={16} top={16}>
                    <IconButton aria-label="close" onClick={() => setIsVerificationDetailsOpen(false)}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </DialogTitle>

                <DialogContent>
                  <Box m={2} width="fit-content" margin="auto" p={2} bgcolor="#f2f2f2">
                    <Box>
                      <Typography variant="body2">{technician && getAssociatedRepairmanDisplay(technician)}</Typography>
                      <Typography variant="body2"> {getTeamLeaderDisplay(teamLeader)}</Typography>
                    </Box>
                  </Box>
                  <Box mt={2}>
                    <Typography variant="subtitle2">Appareils concernés</Typography>
                    <CheckboxGroupInput
                      source="products"
                      label=""
                      choices={customerFile.products}
                      optionText={productDescription}
                      fullWidth
                      validate={[required()]}
                    />
                  </Box>
                  <Divider />
                  <Box mt={2}>
                    <Typography variant="subtitle2">Raison</Typography>
                    <Box width="658px">
                      <AutocompleteArrayInput
                        label="Sélectionnez la ou les raison(s) de cette vérification"
                        source="reasons"
                        choices={REPORT_CHECK_REASONS_CHOICES}
                        optionValue="id"
                        optionText="name"
                        fullWidth
                        options={{
                          placeholder: "Raisons",
                          suggestionsContainerProps: {
                            className: classes.suggestionsContainer, // fix suggestions display on top of the dialog
                          },
                        }}
                      />
                    </Box>
                  </Box>
                  <Divider />
                  <Box mt={2}>
                    <Typography variant="subtitle2">Informations détaillées</Typography>
                    <Box width="658px">
                      <TextInput
                        multiline
                        source="comment"
                        label=""
                        InputProps={{ disableUnderline: true, fullWidth: true }}
                        fullWidth
                        rows={5}
                      />
                    </Box>
                  </Box>
                </DialogContent>
              </Box>
            </SimpleForm>
          </Box>
        </Dialog>
      )}
    </>
  );
};

export default VerificationCheckActions;
