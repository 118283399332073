import React, { useContext } from "react";

import { CustomerFileState } from "types/customer-file-states";

import { useCustomerFileStates } from "utils/hooks/CustomerFileStatesHooks";

type CustomerFileStatesContextType = {
  customerFileStates: CustomerFileState[];
};

const CustomerFileStatesContext = React.createContext<CustomerFileStatesContextType>(
  {} as CustomerFileStatesContextType
);

type CustomerFileStatesProviderProps = {
  children: React.ReactNode;
};

export const CustomerFileStatesProvider: React.FunctionComponent<CustomerFileStatesProviderProps> = ({
  children,
}: CustomerFileStatesProviderProps) => {
  const customerFileStates = useCustomerFileStates();

  return <CustomerFileStatesContext.Provider value={customerFileStates}>{children}</CustomerFileStatesContext.Provider>;
};

export const useCustomerFilesStatesContext: () => CustomerFileStatesContextType = () =>
  useContext(CustomerFileStatesContext);
