import React from "react";

import { FunctionField } from "react-admin";

const stateStyle = {
  borderRadius: 5,
  color: "#fff",
  textAlign: "center",
  padding: 8,
  width: "max-content",
};
const BACKGROUND_COLORS = [
  "#f39c12",
  "#8e44ad",
  "#7f8c8d",
  "#95a5a6",
  "#e74c3c",
  "#27ae60",
  "#1abc9c",
  "#3498db",
  "#2c3e50",
  "#95a5a6",
];

type CustomerFileStateFieldProps = { id: number; text: string };

export default ({ id, text }: CustomerFileStateFieldProps) => (
  <FunctionField
    variant="body2"
    style={{
      ...stateStyle,
      backgroundColor: BACKGROUND_COLORS[id % 10],
    }}
    render={() => text}
  />
);
