import React, { Component } from "react";

import { Datagrid, ReferenceManyField, TextField } from "react-admin";

import { withStyles } from "@material-ui/core/styles";

import DemandItemStatusField from "components/DemandItemStatusField";
import SkuTypeField from "components/molecules/SkuTypeField";

import ReorderDemandItem from "../ReorderDemandItem";
import HighlightedReferenceField from "./components/HighlightedReferenceField";
import { ItemListStyles } from "./styles.js";

const ReferenceDatagrid = withStyles({
  headerRow: {
    display: "none",
  },
  row: {
    height: "2em",
  },
  rowCell: {
    borderBottom: "none",
  },
})(Datagrid);

class ReferencesField extends Component {
  render() {
    return (
      <ReferenceManyField {...this.props} reference="sku-model-references" target="sku" source="sku" label="Références">
        <ReferenceDatagrid>
          <HighlightedReferenceField
            props={this.props}
            source="reference"
            label="Référence"
            correspondingValue={this.props.record.preferred_reference}
          />
        </ReferenceDatagrid>
      </ReferenceManyField>
    );
  }
}

export class DemandItemList extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <ReferenceManyField {...this.props} reference="demand-item" target="demand_id">
          <Datagrid>
            <TextField source="designation" label="Désignation" className={classes.field} />
            <ReferencesField label="Références" />
            <SkuTypeField label="Occasion" className={classes.field} />
            <DemandItemStatusField label="Statut" />
            <ReorderDemandItem assigned_employee={this.props.assigned_employee} label="Actions" />
          </Datagrid>
        </ReferenceManyField>
      </div>
    );
  }
}

export default withStyles(ItemListStyles)(DemandItemList);
