export default class WorkshopTaskTypes {
  static get PHOTO_POST_PROCESSING() {
    return "photo_post_processing";
  }
  static get PRE_PUBLISHING_CHECK() {
    return "pre_publishing_check";
  }
  static get PRODUCT_DATA_FILLING() {
    return "product_data_filling";
  }
  static get CLEANING() {
    return "cleaning";
  }
  static get ALL_WORKSHOP_TASKS() {
    return [
      { id: WorkshopTaskTypes.CLEANING, name: "Nettoyage" },
      { id: WorkshopTaskTypes.PRODUCT_DATA_FILLING, name: "Remplissage infos produit" },
      { id: WorkshopTaskTypes.PHOTO_POST_PROCESSING, name: "Traitement des photos" },
      { id: WorkshopTaskTypes.PRE_PUBLISHING_CHECK, name: "Vérification pré publication" },
    ];
  }
}
