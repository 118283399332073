import React, { useEffect, useState } from "react";

import Loading from "components/atoms/Loading";

interface LazyLoaderProps {
  show: boolean;
  delay?: number;
}
const LazyLoader = (props: LazyLoaderProps) => {
  const { show = false, delay = 0 } = props;
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (!show) {
      setShowLoader(false);
      return;
    }
    if (delay === 0) {
      setShowLoader(true);
    } else {
      timeout = setTimeout(() => setShowLoader(true), delay);
    }
    return () => {
      clearInterval(timeout);
    };
  }, [show, delay]);
  return showLoader ? <Loading /> : null;
};
export default LazyLoader;
