import React, { useEffect, useState } from "react";

import { SimpleForm } from "react-admin";

import { Box, Button, Link, Typography, makeStyles } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import { toastr } from "react-redux-toastr";

import dataProvider from "dataProvider";

import Modal from "components/Modal";
import FileUpload from "components/organisms/FileUpload";

import AutoSave from "pages/customerFiles/components/FileProduct/autoSave";

import { postS3File } from "utils/fileUpload";

const useStyles = makeStyles((theme) => ({
  root: {
    fontWeight: 600,
    color: theme.palette.secondary.contrastText,
  },
  warning: {
    color: theme.palette.secondary.red,
    fontWeight: 600,
  },
  validModalButton: {
    color: "#2B3B5C",
    backgroundColor: "#FCD660",
    borderRadius: "12px",
    fontSize: 14,
    fontWeight: "bold",
    height: 48,
    justifyContent: "center",
    padding: "18px 14px",
    boxShadow: "none",
  },
  modalButton: {
    borderRadius: "12px",
    fontSize: 14,
    fontWeight: "bold",
    height: 48,
    justifyContent: "center",
    padding: "18px 14px",
    boxShadow: "none",
  },

  userGuideContainer: {
    display: "flex",
    alignItems: "center",
  },
  deleteButton: {
    marginRight: "1vw",
  },
}));

const UserGuideInput = ({ product_model_id }) => {
  const classes = useStyles();
  const [productModel, setProductModel] = useState(null);
  const [filename, setFilename] = useState(null);
  const [, setStatus] = useState("Enregistré");
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (product_model_id) {
      dataProvider.getOne("sku/product-models", { id: product_model_id }).then((response) => {
        if (response && response.data) {
          setProductModel(response.data);
          setFilename(`${response.data.product_type.slug}-${response.data.brand.slug}-${response.data.reference}`);
        }
      });
    }
  }, [product_model_id]);

  const onSave = async (values) => {
    setProductModel({ ...productModel, user_guide: filename });
    return postS3File("user_guide", values.file.rawFile, `sku/product-models/${productModel.id}/user_guide`, filename);
  };

  const onDelete = () => {
    dataProvider.update("sku/product-models", { id: product_model_id, data: { user_guide: null } }).then((response) => {
      setProductModel(response.data);
      setIsModalOpen(false);
      toastr.success("Succès", "Mode d'emploi supprimé");
    });
  };
  return (
    <>
      <Typography variant="body2" color="secondary.contrastText" className={classes.root}>
        Mode d'emploi
      </Typography>
      {productModel && !productModel.user_guide ? (
        <SimpleForm toolbar={<></>} onSubmit={() => true}>
          <AutoSave setStatus={setStatus} debounce={100} save={onSave} />

          <FileUpload onSave={onSave} productModel={productModel} />
        </SimpleForm>
      ) : (
        <Typography variant="body2" className={classes.userGuideContainer}>
          <HighlightOffIcon onClick={() => setIsModalOpen(true)} className={classes.deleteButton} />
          <Link
            target="_blank"
            href={`https://${
              process.env.REACT_APP_AWS_UPLOAD_BUCKET
            }.s3.eu-central-1.amazonaws.com/${process.env.REACT_APP_ENVIRONMENT.toUpperCase()}/${
              productModel && productModel.user_guide
            }`}
            variant="body1"
          >
            {`${productModel && productModel.user_guide}`}
          </Link>
        </Typography>
      )}
      <Modal isOpen={isModalOpen} handleCloseModal={() => setIsModalOpen(false)} modalClassName="modal-medium-content">
        <Box m={2}>
          <Box mb={2}>
            <Typography variant="body2" color="secondary.contrastText" className={classes.warning}>
              Attention, cette modification sera appliquée à toutes les machines appartenant au modèle suivant:
            </Typography>
          </Box>

          <Box mb={2}>
            {productModel && (
              <Typography variant="body2" color="secondary.contrastText" className={classes.root}>
                {productModel.product_type.name} - {productModel.brand.name} - {productModel.reference}
              </Typography>
            )}
          </Box>

          <Button onClick={onDelete} className={classes.validModalButton}>
            Oui
          </Button>
          <Button onClick={() => setIsModalOpen(false)} className={classes.modalButton}>
            Non
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default UserGuideInput;
