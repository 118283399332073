import React, { Component } from "react";

import { AutocompleteInput, Edit, ReferenceInput, SaveButton, SelectInput, SimpleForm, Toolbar } from "react-admin";

import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import Modal from "components/Modal";

const EditDemandWrapper = {
  Modal: {
    width: 570,
    height: 480,
    textAlign: "center",
  },
  toolbar: {
    backgroundColor: "white",
    position: "absolute",
    bottom: 12,
    left: "50%",
    transform: "translateX(-50%)",
  },
  titleContainer: {
    marginBottom: 80,
    marginTop: 24,
  },
  saveButton: {
    fontSize: 14,
    fontWeight: "bold",
    height: 50,
    justifyContent: "center",
    padding: "18px 14px",
    boxShadow: "none",
  },
};

const StatusChoices = [
  { id: "emitted", name: "Émise" },
  { id: "waiting_for_sku", name: "En attente de pièces" },
  { id: "loss_declared", name: "Perte déclarée" },
  { id: "waiting_for_affectation", name: "En attente d'affectation" },
  { id: "waiting_for_packing", name: "En attente de colisage" },
  { id: "being_prepared", name: "En préparation" },
  { id: "sent", name: "Expédié" },
  { id: "available", name: "Livré" },
  { id: "canceled", name: "Annulé" },
];

const optionRenderer = (choice) => (choice.id ? choice.first_name + " " + choice.last_name : "N/A");

export class EditSkuDemand extends Component {
  render() {
    const { classes, isModalOpen, onCloseModal, record } = this.props;
    return (
      <Modal
        id="edit-sku-demand-modal"
        modalClassName={`modal-small-content ${classes.Modal}`}
        isOpen={isModalOpen}
        handleCloseModal={onCloseModal}
      >
        <div className={classes.titleContainer}>
          <Typography className={classes.modalTitle} variant="h2">
            Modifier la demande
          </Typography>
          <Typography className={classes.modalTitle} variant="subtitle1" color="primary">
            {record.customer_name}
          </Typography>
        </div>
        <Edit resource="sku-demand" id={this.props.id} basePath="">
          <SimpleForm redirect={false} toolbar={<CustomToolbar handleCloseModal={onCloseModal} classes={classes} />}>
            <ReferenceInput
              label="Technicien"
              reference="employees"
              source="employee"
              filter={{ role: "repairman" }}
              allowEmpty
            >
              <AutocompleteInput
                disableUnderline
                variant="outlined"
                optionValue="id"
                optionText={optionRenderer}
                emptyText="N/A"
              />
            </ReferenceInput>
            <SelectInput source="status" choices={StatusChoices} label="Statut" />
          </SimpleForm>
        </Edit>
      </Modal>
    );
  }
}

export default withStyles(EditDemandWrapper)(EditSkuDemand);

const CustomToolbar = (props) => {
  return (
    <Toolbar {...props} classes={{ toolbar: props.classes.toolbar }}>
      <SaveButton label="Confirmer" onClick={props.handleCloseModal} className={props.classes.saveButton} />
    </Toolbar>
  );
};
