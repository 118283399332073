export default {
  label: {
    display: "inline-block",
    background: "red",
    padding: "4px 8px",
    borderRadius: 4,
    fontWeight: 500,
    fontSize: 13,
    lineHeight: "160%",
  },
  grey: {
    color: "#858585",
    backgroundColor: "rgba(133, 133, 133, 0.15)",
  },
  purple: {
    color: "#CB77FE",
    backgroundColor: "rgba(203, 119, 254, 0.15)",
  },
  dark_blue: {
    color: "#4A55FE",
    backgroundColor: "rgba(74, 85, 254, 0.15)",
  },
  orange: {
    color: "#FEA877",
    backgroundColor: "rgba(254, 168, 119, 0.15)",
  },
  yellow: {
    color: "#F0BB00",
    backgroundColor: "rgba(240, 187, 0, 0.15)",
  },
  light_blue: {
    color: "#4AC8FE",
    backgroundColor: "rgba(74, 200, 254, 0.15)",
  },
  green: {
    color: "#81D89F",
    backgroundColor: "rgba(129, 216, 159, 0.15)",
  },
  red: {
    color: "#F85B5B",
    backgroundColor: "rgba(248, 91, 91, 0.15)",
  },
  dark: {
    color: "#FFFFFF",
    background: "#2B3B5C",
  },
  brown: {
    color: "#D69F4E",
    backgroundColor: "rgba(214, 159, 78, 0.15)",
  },
  turquoise: {
    color: "#1A8E96",
    backgroundColor: "rgba(26, 142, 150, 0.1)",
  },
};
