import React, { Component } from "react";

import PropTypes from "prop-types";

import { IconButton } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import LinkIcon from "@material-ui/icons/Link";
import ReplayIcon from "@material-ui/icons/Replay";

import dataProvider from "dataProvider";

const AccountingBalanceStyles = {
  root: {
    margin: "0 86px 30px 86px",
    padding: "16px",
    width: "675px",
    height: "83px",
    display: "flex",
    backgroundColor: "#FFF",
    boxShadow: "0px 1px 40px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.08)",
    fontFamily: "Poppins",
    borderRadius: 8,
  },
  elementQuote: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 16,
    width: 158.7,
  },
  elementPayment: {
    display: "flex",
    flexDirection: "column",
    width: 158.7,
  },
  element: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 16,
    width: 121,
  },
  elementTitle: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 13,
    color: "#000000",
    display: "inherit",
  },
  paymentIntentPayed: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 13,
    paddingLeft: 20,
    color: "#81D89F",
  },
  elementAmountPayment: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 22,
    lineHeight: "140%",
    color: "#F85B5B",
  },
  elementDetail: {
    fontStyle: "normal",
    fontSize: 22,
    lineHeight: "140%",
    color: "#8795B3",
  },
  elementIcon: {
    fontSize: 20,
    color: "#8795B3",
  },
  divider: {
    height: "45px",
    borderRight: "1px solid #DBDFE8",
  },
  reloadButton: {
    width: 20,
    height: 20,
    top: 40,
  },
};

export class AccountingBalance extends Component {
  static propTypes = {
    customerFile: PropTypes.object,
    employees: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.state = {
      totalAmountQuote: 0,
      totalAmountPayment: 0,
      lastPaymentIntent: "",
      accountBalance: 0,
    };
  }

  componentDidMount = () => {
    this.onClickResetAccountingBalanceValue();
  };

  onClickResetAccountingBalanceValue = () => {
    this.getLastPaymentIntent();
    this.getPaymentListAndQuoteList(this.props.customerFileId);
    this.getAccountBalance();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.customerFileId !== this.props.customerFileId) {
      this.onClickResetAccountingBalanceValue();
    }
  }

  getPaymentListAndQuoteList = (customerFileId) => {
    dataProvider
      .getList("paymentbilling-history", {
        filter: { customer_file_id: customerFileId },
        sort: {},
        pagination: {},
        noPagination: true,
      })
      .then((response) => {
        let total = 0;
        response.data.forEach((element) => {
          if (element.amount) {
            total += parseInt(element.amount);
          }
        });
        this.setState({ totalAmountPayment: total });
      })
      .catch(console.error);

    dataProvider
      .getList("billing-quotes", {
        filter: { customer_file_id: customerFileId },
        sort: {},
        pagination: {},
        noPagination: true,
      })
      .then((response) => {
        let total = 0;
        Object.values(response.data)
          .filter((quote) => quote.quote_accepted === true)
          .map(
            (quote, index) =>
              this.isLast(
                Object.values(response.data).filter((quote) => quote.quote_accepted === true),
                quote
              ) &&
              quote.quote_elements.forEach((element) => {
                if (element.amount_withtax) {
                  total += parseInt(element.amount_withtax);
                }
              })
          );
        this.setState({ totalAmountQuote: total });
      })
      .catch(console.error);
  };

  isLast(quoteList, quote) {
    return quote === quoteList.reduce((prev, current) => (prev.version > current.version ? prev : current));
  }

  getLastPaymentIntent = () => {
    dataProvider
      .getOne(
        "payment-intent-last",
        { id: this.props.customerFileId },
        {
          filter: {},
          sort: {},
          pagination: {},
          noPagination: true,
        }
      )
      .then((response) => {
        if (response.data) {
          this.setState({ lastPaymentIntent: response.data });
        }
      })
      .catch(console.error);
  };

  getAccountBalance = () => {
    dataProvider
      .getOne(
        "account-balance",
        { id: this.props.customerFileId },
        {
          filter: {},
          sort: {},
          pagination: {},
          noPagination: true,
        }
      )
      .then(({ data }) => {
        this.setState({ accountBalance: data });
      })
      .catch(console.error);
  };

  copyPaymentLink = () => {
    const span = document.createElement("span");
    span.textContent = this.state.lastPaymentIntent.custom_payment;
    document.body.appendChild(span);

    let selection = document.getSelection();
    let range = document.createRange();
    range.selectNode(span);
    selection.removeAllRanges();
    selection.addRange(range);

    document.execCommand("copy");
    selection.removeAllRanges();

    document.body.removeChild(span);
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {this.state.accountBalance >= 0 ? (
          <div className={classes.elementPayment}>
            <span className={classes.elementTitle}>
              {" "}
              <span>Montant dû &nbsp; &nbsp;</span>
              <Tooltip title="Copier le lien de paiement">
                <LinkIcon className={classes.elementIcon} onClick={this.copyPaymentLink} />
              </Tooltip>
            </span>
            <span className={classes.elementAmountPayment}>{this.state.accountBalance / 100} €</span>
          </div>
        ) : (
          <div className={classes.elementPayment}>
            <span className={classes.elementTitle}>À Rembourser </span>
            <span className={classes.elementAmountPayment}>{this.state.accountBalance / 100} €</span>
          </div>
        )}
        <Divider component="hr" orientation="vertical" className={classes.divider} />
        <div className={classes.elementQuote}>
          <span className={classes.elementTitle}>Lien de paiement</span>
          <span className={classes.elementDetail}>
            {this.state.lastPaymentIntent ? this.state.lastPaymentIntent.amount / 100 : 0} €{" "}
            {this.state.lastPaymentIntent.payment ? (
              <span id="span-info-payed" className={classes.paymentIntentPayed}>
                Payée
              </span>
            ) : null}
          </span>
        </div>
        <Divider component="hr" orientation="vertical" className={classes.divider} />
        <div className={classes.elementQuote}>
          <span className={classes.elementTitle}>Facturé</span>
          <span className={classes.elementDetail}>{this.state.totalAmountQuote / 100} €</span>
        </div>
        <Divider component="hr" orientation="vertical" className={classes.divider} />
        <div className={classes.element}>
          <span className={classes.elementTitle}>Encaissé</span>
          <span className={classes.elementDetail}>{this.state.totalAmountPayment / 100} €</span>
        </div>
        <IconButton
          id="refresh-account-balance-data"
          onClick={this.onClickResetAccountingBalanceValue}
          className={classes.reloadButton}
        >
          <ReplayIcon />
        </IconButton>
      </div>
    );
  }
}

export default withStyles(AccountingBalanceStyles)(AccountingBalance);
