import React from "react";

import { Field, FieldProps } from "formik";

import Select from "components/atoms/Select";
import { Body } from "components/atoms/Typography";

import { VISIT_STATUSES } from "../../../constants";

const VisitStatusField: React.FunctionComponent = () => (
  <Field name="visit_status">
    {({ field, form }: FieldProps) => (
      <>
        <Select
          {...field}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
            form.setFieldValue("visit_status", event.target.value)
          }
          options={VISIT_STATUSES}
          label="Statut"
        />
        {form.touched.visit_type && form.errors.visit_type && <Body color="red">{form.errors.visit_type}</Body>}
      </>
    )}
  </Field>
);

export default VisitStatusField;
