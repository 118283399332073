import React from "react";

import Button, { ButtonProps } from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import { CreditNote } from "types/credit-note";

interface SubmitButtonProps extends ButtonProps {
  isSuccess: boolean;
  creditNotes: CreditNote[];
}
const ActionButton: React.FunctionComponent<SubmitButtonProps> = ({ isSuccess, creditNotes, ...buttonProps }) => {
  if (creditNotes === null && isSuccess) {
    return <Button disabled> Générer un bon d'achat</Button>;
  }

  if (!isSuccess) {
    return <Button disabled startIcon={<CircularProgress />} />;
  }

  if (creditNotes.length === 0) {
    return <></>;
  }
  return <Button {...buttonProps}>Générer un bon d'achat</Button>;
};

export default ActionButton;
