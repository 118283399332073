export const quoteStyles = {
  detailDisplayField: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "16px 0px 16px 0px",
    "&:last-child": {
      paddingRight: 0,
    },
  },
  packageField: {
    color: "#8795B3",
  },
  quoteField: {
    color: "#000000",
  },
  rowCell: {
    background: "#FFFFFF",
    borderRadius: "8px",
    height: "62px",
  },
  table: {
    marginTop: "8px",
    marginLeft: "54px",
    maxWidth: "672px",
    background: "#ffffff",
    borderRadius: "8px",
    borderCollapse: "unset",
  },
};
