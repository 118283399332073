/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */
import { adminReducer } from "react-admin";

import companyEmployee from "admin/pages/Company/module";
import customerFile from "admin/pages/CustomerFiles/components/CustomerFilesList/module";
import customerFiles from "admin/pages/CustomerFiles/module";
import { connectRouter } from "connected-react-router";
import murfyBookingForm from "murfy/pages/MurfyBookingForm/module";
import quickBooking from "murfy/pages/QuickBooking/module";
import { reducer as toastrReducer } from "react-redux-toastr";
import { persistCombineReducers } from "redux-persist";
import breadcrumb from "shared/components/Breadcrumb/module";
import article from "shared/pages/Article/module";
import catalog from "shared/pages/Catalog/module";
import company from "shared/pages/Company/module";
import diagnostic from "shared/pages/Diagnostic/module";

import calendar from "components/MurfyCalendar3by3/module";

import account from "pages/Account/module";
import articleEdition from "pages/Cms/ArticleEdition/module";
import catalogEdition from "pages/Cms/CatalogEdition/module";
import imagesManagement from "pages/Cms/ContentManagement/components/ImagesManagement/module";
import product from "pages/Cms/ContentManagement/components/ProductsList/module";
import smartDiagnosticEdition from "pages/Cms/ContentManagement/components/SmartDiagnosticEdition/module";
import cms from "pages/Cms/ContentManagement/module";
import formEdition from "pages/Cms/FormEdition/module";
import stepEdition from "pages/Cms/StepEdition/module";
import tutorielEdition from "pages/Cms/TutorialEdition/module";
import tickets from "pages/Tickets/module";
import customerFileBillingQuote from "pages/customerFiles/components/CustomerFileBillingQuotes/modules/module.js";

/**
 * Creates the main reducer with the asynchronously loaded ones
 */
export default function createReducer(persistConfig, asyncReducers, history) {
  return persistCombineReducers(persistConfig, {
    ...asyncReducers,
    account,
    admin: adminReducer,
    article,
    articleEdition,
    breadcrumb,
    calendar,
    catalog,
    catalogEdition,
    cms,
    company,
    companyEmployee,
    customerFile,
    customerFiles,
    diagnostic,
    formEdition,
    imagesManagement,
    murfyBookingForm,
    product,
    quickBooking,
    router: connectRouter(history),
    smartDiagnosticEdition,
    stepEdition,
    tickets,
    toastr: toastrReducer,
    tutorielEdition,
    customerFileBillingQuote,
  });
}
