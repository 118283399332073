import React from "react";

import { Box, Grid } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";

import { AxiosError } from "axios";
import moment from "moment";
import { Timeslot } from "types/timeslot";

import Button from "components/atoms/Button";
import Loading from "components/atoms/Loading";
import { Body } from "components/atoms/Typography";

import TimeslotsPerDate from "./components/TimeslotDisplayPerDate";

type CalendarProps = {
  onSelect: (timeslot: Timeslot) => void;
  isSelected: (timeslot: Timeslot) => boolean;
  timeslots: Timeslot[];
  selectedDate: moment.Moment;
  setSelectedDate: (date: moment.Moment) => void;
  loadingTimeslots: boolean;
  isError: boolean;
  error: AxiosError | null;
};

const Calendar: React.FunctionComponent<CalendarProps> = ({
  onSelect,
  isSelected,
  setSelectedDate,
  selectedDate,
  loadingTimeslots,
  isError,
  timeslots,
  error,
}: CalendarProps) => {
  if (loadingTimeslots) {
    return <Loading />;
  }
  if (isError && error && error.response && error.response.status === 404) {
    return <Body color="red">Ce code postal n'est pas dans une zone desservie par la RD.</Body>;
  }

  if (isError) {
    return <Body>Une erreur est survenue pendant la récupération de créneaux</Body>;
  }
  return (
    <Box width="100%">
      <Grid container justify="space-between">
        <Grid container item xs={1}>
          <Button
            key="previous"
            onClick={() => setSelectedDate(moment(selectedDate).add(-3, "days"))}
            startIcon={<ChevronLeft />}
            version="invisible"
          >
            J-3
          </Button>
        </Grid>
        <Grid item xs={10}>
          <Grid container spacing={2}>
            <TimeslotsPerDate
              timeslots={timeslots}
              selectedDate={selectedDate.format("YYYY-MM-DD")}
              handleSelectTimeslot={onSelect}
              isSelected={isSelected}
            />
          </Grid>
        </Grid>
        <Grid container item xs={1}>
          <Button
            key="next"
            onClick={() => setSelectedDate(moment(selectedDate).add(3, "days"))}
            endIcon={<ChevronRight />}
            version="invisible"
          >
            J+3
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Calendar;
