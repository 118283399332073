import { getCompanyEmployeeSelector } from "admin/pages/Company/module";
import find from "lodash/find";
import get from "lodash/get";
import { sendAuthenticatedGetRequestToAPI, sendUnauthenticatedGetRequestToAPI } from "modules/generators/requestsToAPI";
import Moment from "moment";
import "moment/locale/fr";
import { toastr } from "react-redux-toastr";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { getCompanySelector } from "shared/pages/Company/module";

import { addOrReplaceByKey } from "utils/addOrReplace";
import { getAPIUrl, getTokenFromLocalStorage, request } from "utils/networking";

export const GET_COUPON_REQUEST = "QuickBooking/GET_COUPON_REQUEST";
export const GET_COUPON_SUCCESS = "QuickBooking/GET_COUPON_SUCCESS";
export const GET_COUPON_ERROR = "QuickBooking/GET_COUPON_ERROR";
export const RESET_COUPON = "QuickBooking/RESET_COUPON";

export const GET_QUICK_BOOKING_TIMESLOTS_REQUEST = "QuickBooking/GET_QUICK_BOOKING_TIMESLOTS_REQUEST";
export const GET_QUICK_BOOKING_TIMESLOTS_REQUEST_WITH_POSTAL_CODE =
  "QuickBooking/GET_QUICK_BOOKING_TIMESLOTS_REQUEST_WITH_POSTAL_CODE";
export const GET_QUICK_BOOKING_TIMESLOTS_SUCCESS = "QuickBooking/GET_QUICK_BOOKING_TIMESLOTS_SUCCESS";
export const GET_QUICK_BOOKING_TIMESLOTS_ERROR = "QuickBooking/GET_QUICK_BOOKING_TIMESLOTS_ERROR";

export const GET_QUICK_BOOKING_SETTINGS_REQUEST = "QuickBooking/GET_QUICK_BOOKING_SETTINGS_REQUEST";
export const GET_QUICK_BOOKING_SETTINGS_SUCCESS = "QuickBooking/GET_QUICK_BOOKING_SETTINGS_SUCCESS";
export const GET_QUICK_BOOKING_SETTINGS_ERROR = "QuickBooking/GET_QUICK_BOOKING_SETTINGS_ERROR";

export const SET_QUICK_BOOKING_USER_DIAGNOSTIC = "QuickBooking/SET_QUICK_BOOKING_USER_DIAGNOSTIC";
export const SET_QUICK_BOOKING_USER_DATA = "QuickBooking/SET_QUICK_BOOKING_USER_DATA";

export const SET_QUICK_BOOKING_ADMIN_DATA = "QuickBooking/SET_QUICK_BOOKING_ADMIN_DATA";

export const SET_QUICK_BOOKING_PRODUCT = "QuickBooking/SET_QUICK_BOOKING_PRODUCT";
export const SET_QUICK_BOOKING_ADDRESS = "QuickBooking/SET_QUICK_BOOKING_ADDRESS";
export const SET_QUICK_BOOKING_TIMESLOT = "QuickBooking/SET_QUICK_BOOKING_TIMESLOT";
export const SET_QUICK_BOOKING_PAYMENT_METHOD = "QuickBooking/SET_QUICK_BOOKING_PAYMENT_METHOD";

export const POST_QUICK_BOOKING_FORM_REQUEST = "QuickBooking/POST_QUICK_BOOKING_FORM_REQUEST";
export const POST_QUICK_BOOKING_FORM_SUCCESS = "QuickBooking/POST_QUICK_BOOKING_FORM_SUCCESS";
export const POST_QUICK_BOOKING_FORM_ERROR = "QuickBooking/POST_QUICK_BOOKING_FORM_ERROR";
export const POST_QUICK_BOOKING_FORM_REQUIRES_ACTION = "QuickBooking/POST_QUICK_BOOKING_FORM_REQUIRES_ACTION";
export const RESET_QUICK_BOOKING_SUBMISSION = "QuickBooking/RESET_QUICK_BOOKING_SUBMISSION";

export const RESET_QUICK_BOOKING_FORM_VALUES = "QuickBooking/RESET_QUICK_BOOKING_FORM_VALUES";

/**
 * Utils
 */
export function formatTimeslotsForTracking(timeslots) {
  if (!timeslots || !(timeslots.constructor === Array)) {
    return "{}";
  }
  const todayDate = Moment().utc().startOf("day");
  const compressedTimeslots = timeslots.reduce((timeslostsAcc, timeslot) => {
    const timeslotDatetime = Moment.utc(timeslot.beginning);
    const timeslotHour = timeslotDatetime.hours(); // save hour as timeslotDatetime is mutated afterwards
    const dayHorizon = timeslotDatetime.startOf("day").diff(todayDate, "days"); // startOf necessary to get day difference with respect to dates
    if (dayHorizon > 10) {
      // do not serialize timeslots further than 10 days
      return timeslostsAcc;
    }
    if (timeslostsAcc[dayHorizon]) {
      timeslostsAcc[dayHorizon].push(timeslotHour);
    } else {
      timeslostsAcc[dayHorizon] = [timeslotHour];
    }
    return timeslostsAcc;
  }, {});
  return JSON.stringify(compressedTimeslots);
}

/**
 * Actions generator
 */
export function getQuickBookingSettingsRequest() {
  return {
    type: GET_QUICK_BOOKING_SETTINGS_REQUEST,
  };
}

export function getQuickBookingSettingsSuccess(quickBookingSettings) {
  return {
    type: GET_QUICK_BOOKING_SETTINGS_SUCCESS,
    payload: quickBookingSettings,
  };
}

export function getQuickBookingSettingsError(error) {
  return {
    type: GET_QUICK_BOOKING_SETTINGS_ERROR,
    payload: error,
  };
}

export function getQuickBookingTimeslotsRequest(isAdmin, postalCode = null) {
  return {
    type: GET_QUICK_BOOKING_TIMESLOTS_REQUEST,
    payload: { isAdmin, postalCode },
  };
}

export function getQuickBookingTimeslotsSuccess(timeslots) {
  return {
    type: GET_QUICK_BOOKING_TIMESLOTS_SUCCESS,
    payload: timeslots,
    meta: {
      analytics: {
        type: "SEND_EVENT",
        payload: {
          action: "DISPLAY_TIMESLOTS",
          category: "QUICK_BOOKING",
          label: formatTimeslotsForTracking(timeslots),
          trackerName: "user",
          nonInteraction: true,
        },
      },
    },
  };
}

export function getQuickBookingTimeslotsError(error) {
  return {
    type: GET_QUICK_BOOKING_TIMESLOTS_ERROR,
    payload: error,
  };
}

export function getCouponRequest(couponCode) {
  return {
    type: GET_COUPON_REQUEST,
    payload: couponCode,
  };
}

export function getCouponSuccess(coupon) {
  return {
    type: GET_COUPON_SUCCESS,
    payload: coupon,
  };
}

export function getCouponError(error) {
  return {
    type: GET_COUPON_ERROR,
    payload: error,
  };
}

export function resetCouponAction() {
  return {
    type: RESET_COUPON,
  };
}

export function setProduct(product) {
  return {
    type: SET_QUICK_BOOKING_PRODUCT,
    payload: product,
  };
}

export function setAddress(address) {
  return {
    type: SET_QUICK_BOOKING_ADDRESS,
    payload: address,
  };
}

export function setUserData(inputName, inputValue, isRequired) {
  return {
    type: SET_QUICK_BOOKING_USER_DATA,
    payload: { inputName, inputValue, isRequired },
  };
}

export function setPaymentMethod(paymentMethod) {
  return {
    type: SET_QUICK_BOOKING_PAYMENT_METHOD,
    payload: paymentMethod,
  };
}

export function setQuickBookingTimeslot(timeslotId) {
  return {
    type: SET_QUICK_BOOKING_TIMESLOT,
    payload: timeslotId,
  };
}

export function setQuickBookingUserDiagnostic(userDiagnostic) {
  return {
    type: SET_QUICK_BOOKING_USER_DIAGNOSTIC,
    payload: userDiagnostic,
  };
}

export function setAdminData(inputName, inputValue) {
  return {
    type: SET_QUICK_BOOKING_ADMIN_DATA,
    payload: { inputName, inputValue },
  };
}

/**
 * Generate an action to post the quick booking form
 * @param {String} paymentToken the token to be used by Stripe to make a charge from the backend
 */
export function postQuickBookingFormRequest(paymentToken, isAdmin, paymentMethod) {
  return {
    type: POST_QUICK_BOOKING_FORM_REQUEST,
    payload: { paymentToken, isAdmin, paymentMethod },
  };
}

export function postQuickBookingFormSuccess(paymentPrice, customerFileNumber) {
  return {
    type: POST_QUICK_BOOKING_FORM_SUCCESS,
    payload: { customerFileNumber },
    meta: {
      analytics: {
        type: "SEND_EVENT",
        payload: {
          action: "PAYMENT_QUICK_BOOKING_SUCCESS",
          category: "QUICK_BOOKING",
          trackerName: "user",
          value: paymentPrice / 100,
        },
      },
      facebookPixel: {
        type: "Purchase",
        payload: {
          value: paymentPrice / 100,
          content_name: "quick booking",
          currency: "EUR",
        },
      },
    },
  };
}

export function postQuickBookingFormError(error) {
  return {
    type: POST_QUICK_BOOKING_FORM_ERROR,
    payload: error,
  };
}

export function postQuickBookingFormRequiresAction(intentId) {
  return {
    type: POST_QUICK_BOOKING_FORM_REQUIRES_ACTION,
    payload: intentId,
  };
}

export function resetPaymentSubmissionAction() {
  return {
    type: RESET_QUICK_BOOKING_SUBMISSION,
  };
}

export function resetQuickBookingFormValuesRequest() {
  return {
    type: RESET_QUICK_BOOKING_FORM_VALUES,
  };
}

export const getCardholderNameSelector = (state) => {
  const firstname = get(
    state.quickBooking.inputs.find((formInput) => formInput.inputName === "firstname"),
    "inputValue"
  );
  const lastname = get(
    state.quickBooking.inputs.find((formInput) => formInput.inputName === "lastname"),
    "inputValue"
  );
  return `${firstname || ""}${firstname && lastname ? " " : ""}${lastname || ""}`;
};

export const getDiagnosticIdFromProductSelector = (state) => {
  if (state.quickBooking.quickBookingSettings && state.quickBooking.product) {
    return get(
      find(state.quickBooking.quickBookingSettings.producttodiagnosticmapping_set, {
        product: parseInt(state.quickBooking.product.id, 10),
      }),
      "diagnostic"
    );
  }
};

export const getStreetNumberValueSelector = (state) => state.quickBooking.address[0].long_name;

export const getStreetValueSelector = (state) => state.quickBooking.address[1].long_name;

export const getCityValueSelector = (state) => state.quickBooking.address[2].long_name;

export const getPostalCodeValueSelector = (state, postalCode) =>
  postalCode ||
  (state.quickBooking.address &&
    state.quickBooking.address.find(
      (addressElement) => addressElement.types && addressElement.types.includes("postal_code")
    ).long_name) ||
  null;

export const getPostalCodeFormFieldIdSelector = (state) =>
  state.quickBooking.quickBookingSettings &&
  get(
    state.quickBooking.quickBookingSettings.form.form_fields.find((field) => field.name === "postal_code"),
    "id"
  );

export const getQuickBookingFormSelector = (state, isAdmin) =>
  isAdmin ? state.quickBooking.quickBookingSettings.admin_form : state.quickBooking.quickBookingSettings.form;

export const getQuickBookingFormPaymentPriceSelector = (state) => {
  var coupon = state.quickBooking.coupon;
  var price = get(state.quickBooking.quickBookingSettings, "form.payment_price");

  if (coupon) {
    if (coupon.discount_type === "fixed") {
      return price - coupon.amount;
    } else {
      return price - (price * coupon.amount) / 100;
    }
  }
  return price;
};

export const getQuickBookingFormInputsSelector = (state) => state.quickBooking.inputs;

export const getQuickBookingTimeslotSelector = (state) => state.quickBooking.selectedTimeslotId;

export const getQuickBookingUserDiagnosticSelector = (state) => state.quickBooking.userDiagnostic;

export const getQuickBookingAdminCommentsSelector = (state) => state.quickBooking.adminComments;

export const getQuickBookingAdminAmountSelector = (state) => state.quickBooking.adminAmount;

export const getQuickBookingCouponSelector = (state) => state.quickBooking.coupon && state.quickBooking.coupon.id;

export const getQuickBookingAcquisitionChannelSelector = (state) => state.quickBooking.acquisitionChannel;

export const getQuickBookingPaymentMethodSelector = (state) => state.quickBooking.paymentMethod;

/**
 * This selector format the stored address to match the stored form fields :
 *  - each form field matched a type of an address field so it returns the id of
 * this form field and the value of the address field
 * @param {array} form
 */
export const getFormattedAddressFormFields = (state, form) => {
  const address = state.quickBooking.address;
  const mapAddressToFormFields = [
    { formFieldName: "street_number", addressFieldType: "street_number" },
    { formFieldName: "street", addressFieldType: "route" },
    { formFieldName: "city", addressFieldType: "locality" },
    { formFieldName: "postal_code", addressFieldType: "postal_code" },
  ];

  const addressFormFields = form.form_fields.reduce((tempAddressFormFields, formField) => {
    const mapAddressToFormFieldsElement = mapAddressToFormFields.find(
      (mapAddressToFormFieldsElement) => mapAddressToFormFieldsElement.formFieldName === formField.name
    );

    const addressField =
      mapAddressToFormFieldsElement &&
      address.find((addressElement) => addressElement.types.includes(mapAddressToFormFieldsElement.addressFieldType));

    addressField &&
      tempAddressFormFields.push({
        form_field: formField.id,
        value: get(addressField, "long_name"),
      });

    return tempAddressFormFields;
  }, []);

  return addressFormFields;
};

/**
 * This selector formats the data to post :
 * - the generic data are mandatory to create a ticket
 * - the other data have to be posted as a stringified map ([formFieldId]: formValue)
 * @param {number} company
 * @param {object} form
 * @param {array} formInputs
 * @param {number} formPrice
 * @param {string} paymentToken
 * @param {number} timeslot
 * @param {string} userDiagnostic
 * @param {string} adminComments
 * @param {number} adminAmount
 */
export const getDataFormInputsSelector = (
  state,
  adminAmount,
  adminComments,
  city,
  company,
  couponId,
  form,
  formInputs,
  formPrice,
  paymentToken,
  paymentMethod,
  postalCode,
  street,
  streetNumber,
  timeslot,
  userDiagnostic,
  acquisitionChannel,
  formattedAddressFormFields
) => {
  const genericData = {
    admin_amount: adminAmount,
    admin_comments: adminComments,
    city: city,
    company: company.id,
    coupon: couponId,
    displayed_payment_price: formPrice,
    email: get(
      formInputs.find((formInput) => formInput.inputName === "mail"),
      "inputValue"
    ),
    extra_information: get(
      formInputs.find((formInput) => formInput.inputName === "complement"),
      "inputValue"
    ),
    firstname: get(
      formInputs.find((formInput) => formInput.inputName === "firstname"),
      "inputValue"
    ),
    form: form.id,
    lastname: get(
      formInputs.find((formInput) => formInput.inputName === "lastname"),
      "inputValue"
    ),
    payment_method: paymentMethod || "credit_card",
    phone: get(
      formInputs.find((formInput) => formInput.inputName === "tel"),
      "inputValue"
    ),
    postal_code: postalCode,
    street: street,
    street_number: streetNumber,
    stripe_token: paymentToken,
    timeslot: timeslot,
    ticket_description: form.ticket_description,
    ticket_state: form.ticket_state || null,
    user_diagnostic: userDiagnostic,
    product_type: state.quickBooking.product && state.quickBooking.product.name,
    brand: get(
      formInputs.find((formInput) => formInput.inputName === "brand"),
      "inputValue"
    ),
    model_reference: get(
      formInputs.find((formInput) => formInput.inputName === "reference"),
      "inputValue"
    ),
    is_spare_part_known: get(
      formInputs.find((formInput) => formInput.inputName === "isSparePartKnown"),
      "inputValue"
    ),
    fault_details: get(
      formInputs.find((formInput) => formInput.inputName === "details"),
      "inputValue"
    ),
    acquisition_channel: acquisitionChannel,
  };

  const genericFieldsNames = ["email", "firstname", "lastname"];

  const formattedInputsData = form.form_fields.reduce((tempFormInputsData, formField) => {
    const formInput = formInputs.find((formInput) => formInput.inputName === formField.name);
    if (!genericFieldsNames.includes(formField.name) && formInput) {
      tempFormInputsData.push({
        form_field: formField.id,
        value: get(formInput, "inputValue"),
      });
    }
    return tempFormInputsData;
  }, []);

  return {
    ...genericData,
    ticket_fields: JSON.stringify([...formattedInputsData, ...formattedAddressFormFields]),
  };
};

export const getSingleTimeslotSelector = (state) => {
  if (state.quickBooking.selectedTimeslotId && state.quickBooking.timeslots) {
    return state.quickBooking.timeslots.find((timeslot) => {
      return timeslot.id === state.quickBooking.selectedTimeslotId;
    });
  }
};

export function* getQuickBookingSettings(action) {
  const route = `/quick-booking/settings/`;

  yield call(sendUnauthenticatedGetRequestToAPI, route, getQuickBookingSettingsSuccess, getQuickBookingSettingsError);
}

export function* getQuickBookingSettingsSaga() {
  yield takeEvery(GET_QUICK_BOOKING_SETTINGS_REQUEST, getQuickBookingSettings);
}

export function* getQuickBookingTimeslots(action) {
  const { isAdmin, postalCode } = action.payload;
  const postalCodeValue = yield select(getPostalCodeValueSelector, postalCode);
  const company = isAdmin ? yield select(getCompanyEmployeeSelector) : yield select(getCompanySelector);
  const getGenerator = isAdmin ? sendAuthenticatedGetRequestToAPI : sendUnauthenticatedGetRequestToAPI;
  const route = `/widget/timeslot/city/?company=${company.id}&postal_code=${postalCodeValue}`;

  yield call(getGenerator, route, getQuickBookingTimeslotsSuccess, getQuickBookingTimeslotsError);
}

export function* getQuickBookingTimeslotsSaga() {
  yield takeEvery(GET_QUICK_BOOKING_TIMESLOTS_REQUEST, getQuickBookingTimeslots);
}

export function* getCoupon(action) {
  const couponCode = action.payload;
  const getGenerator = sendUnauthenticatedGetRequestToAPI;
  const route = `/payment/coupon/${couponCode}/`;

  yield call(getGenerator, route, getCouponSuccess, getCouponError);
}

export function* getCouponSaga() {
  yield takeEvery(GET_COUPON_REQUEST, getCoupon);
}

export function* postQuickBookingForm(action) {
  const adminAmount = yield select(getQuickBookingAdminAmountSelector);
  const adminComments = yield select(getQuickBookingAdminCommentsSelector);
  const city = yield select(getCityValueSelector);
  const company = action.payload.isAdmin ? yield select(getCompanyEmployeeSelector) : yield select(getCompanySelector);
  const couponId = yield select(getQuickBookingCouponSelector);
  const form = yield select(getQuickBookingFormSelector, action.payload.isAdmin);
  const formInputs = yield select(getQuickBookingFormInputsSelector);
  const formPaymentPrice = yield select(getQuickBookingFormPaymentPriceSelector);
  // if payment method is in the payload of the action (like in user part), use it, else select it in the store (admin part)
  const paymentMethod = yield action.payload.paymentMethod || select(getQuickBookingPaymentMethodSelector);
  const postalCode = yield select(getPostalCodeValueSelector);
  const street = yield select(getStreetValueSelector);
  const streetNumber = yield select(getStreetNumberValueSelector);
  const timeslot = yield select(getQuickBookingTimeslotSelector);
  const userDiagnostic = yield select(getQuickBookingUserDiagnosticSelector);
  const acquisitionChannel = yield select(getQuickBookingAcquisitionChannelSelector);
  const formattedAddressFormFields = yield select(getFormattedAddressFormFields, form);
  const formattedInputs = yield select(
    getDataFormInputsSelector,
    adminAmount,
    adminComments,
    city,
    company,
    couponId,
    form,
    formInputs,
    formPaymentPrice,
    action.payload.paymentToken,
    paymentMethod,
    postalCode,
    street,
    streetNumber,
    timeslot,
    userDiagnostic,
    acquisitionChannel,
    formattedAddressFormFields
  );

  let customerFileConfig = {
    method: "post",
    url: `${getAPIUrl()}/murfy-erp/customer-files/`,
    headers: {
      "Content-Type": "application/json",
    },
    data: formattedInputs,
  };

  if (action.payload.isAdmin) {
    const token = yield call(getTokenFromLocalStorage);
    customerFileConfig.headers.Authorization = `Token ${token}`;
  }

  try {
    const response = yield call(request, customerFileConfig);
    if (response.requires_action && response.payment_intent_client_secret) {
      yield put(postQuickBookingFormRequiresAction(response.payment_intent_client_secret));
    } else {
      yield put(postQuickBookingFormSuccess(formPaymentPrice, response.file_number));
      if (action.payload.isAdmin) {
        yield call(toastr.success, "Succès", "Le dossier client a bien été créé");
        yield put(resetQuickBookingFormValuesRequest());
      }
    }
  } catch (error) {
    yield put(postQuickBookingFormError(error));
    yield call(toastr.error, "Erreur", "Le formulaire n'a pas pu être envoyé");
  }
}

export function* postQuickBookingFormSaga() {
  yield takeEvery(POST_QUICK_BOOKING_FORM_REQUEST, postQuickBookingForm);
}

const initialState = {
  address: null,
  adminAmount: null,
  adminComments: "",
  customerFileNumber: null,
  inputs: [],
  isQuickBookingPostSuccessful: false,
  isSubmissionPending: false,
  paymentError: "",
  paymentMethod: null,
  product: null,
  quickBookingSettings: null,
  userDiagnostic: "",
  selectedTimeslotId: null,
  error: null,
  acquisitionChannel: null,
  coupon: null,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_QUICK_BOOKING_SETTINGS_REQUEST:
      return {
        ...state,
        error: null,
      };
    case GET_QUICK_BOOKING_TIMESLOTS_REQUEST:
      return {
        ...state,
        error: null,
      };
    case GET_COUPON_REQUEST:
      return {
        ...state,
        couponError: null,
      };
    case GET_COUPON_SUCCESS:
      return {
        ...state,
        coupon: action.payload,
      };
    case GET_COUPON_ERROR:
      return {
        ...state,
        couponError: action.payload,
      };
    case RESET_COUPON:
      return {
        ...state,
        coupon: null,
      };
    case GET_QUICK_BOOKING_SETTINGS_SUCCESS:
      return {
        ...state,
        quickBookingSettings: action.payload,
      };
    case GET_QUICK_BOOKING_SETTINGS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case GET_QUICK_BOOKING_TIMESLOTS_SUCCESS:
      return {
        ...state,
        timeslots: action.payload,
      };
    case GET_QUICK_BOOKING_TIMESLOTS_ERROR:
      return {
        ...state,
        timeslots: [],
        error: action.payload,
      };
    case SET_QUICK_BOOKING_PRODUCT:
      return {
        ...state,
        product: action.payload,
      };
    case SET_QUICK_BOOKING_ADDRESS:
      return {
        ...state,
        address: action.payload,
      };
    case SET_QUICK_BOOKING_USER_DATA:
      const data = {
        inputName: action.payload.inputName,
        inputValue: action.payload.inputValue,
        inputRequired: action.payload.isRequired,
      };
      return {
        ...state,
        inputs: addOrReplaceByKey(state.inputs, "inputName", data),
      };
    case SET_QUICK_BOOKING_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      };
    case SET_QUICK_BOOKING_TIMESLOT:
      return {
        ...state,
        selectedTimeslotId: action.payload,
      };
    case SET_QUICK_BOOKING_USER_DIAGNOSTIC:
      return {
        ...state,
        userDiagnostic: action.payload,
      };
    case SET_QUICK_BOOKING_ADMIN_DATA:
      return {
        ...state,
        [action.payload.inputName]: action.payload.inputValue,
      };
    case POST_QUICK_BOOKING_FORM_REQUEST:
      return {
        ...state,
        isQuickBookingPostSuccessful: false,
        isSubmissionPending: true,
        error: null,
        paymentError: "",
        intentId: null,
      };
    case POST_QUICK_BOOKING_FORM_SUCCESS:
      return {
        ...state,
        customerFileNumber: action.payload.customerFileNumber,
        isQuickBookingPostSuccessful: true,
        isSubmissionPending: false,
        intentId: null,
      };
    case POST_QUICK_BOOKING_FORM_ERROR:
      if (action.payload.data === undefined) {
        return {
          ...state,
          isSubmissionPending: false,
          error: action.payload,
        };
      } else {
        switch (action.payload.data.code) {
          case "INVALID_CVC_TRY_AGAIN":
          case "payment_failed":
            return {
              ...state,
              isSubmissionPending: false,
              error: action.payload,
              paymentError: action.payload.data.code,
            };
          default:
            return {
              ...state,
              isSubmissionPending: false,
              error: action.payload,
            };
        }
      }
    case POST_QUICK_BOOKING_FORM_REQUIRES_ACTION:
      return {
        ...state,
        intentId: action.payload,
      };
    case RESET_QUICK_BOOKING_SUBMISSION:
      return {
        ...state,
        isSubmissionPending: false,
      };
    case RESET_QUICK_BOOKING_FORM_VALUES:
      return {
        ...state,
        address: null,
        adminAmount: null,
        adminComments: "",
        inputs: [],
        product: null,
        userDiagnostic: "",
        selectedTimeslotId: null,
        acquisitionChannel: null,
        coupon: null,
      };
    default:
      return state;
  }
}

export default reducer;
