/**
 * Following the duck pattern, actions, constants and reducers are in the same file called module.js
 *
 * See: https://github.com/erikras/ducks-modular-redux
 *
 */
import { push } from "connected-react-router";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { getApplicationByWidgetSelector } from "shared/pages/Company/module";

import { ARTICLE_ROUTE } from "constants/routes";

import { getAPIUrl, request } from "utils/networking";
import getRedirectionRoute from "utils/portalRouteRedirector";

export const GET_CATALOG_REQUEST = "CATALOG/GET_CATALOG_REQUEST";
export const GET_CATALOG_SUCCESS = "CATALOG/GET_CATALOG_SUCCESS";
export const GET_CATALOG_ERROR = "CATALOG/GET_CATALOG_ERROR";

export const REDIRECT_TO_ARTICLE = "CATALOG/REDIRECT_TO_ARTICLE";

export function getCatalogRequest(id) {
  return {
    type: GET_CATALOG_REQUEST,
    payload: id,
  };
}

export function getCatalogSuccess(catalog) {
  return {
    type: GET_CATALOG_SUCCESS,
    payload: catalog,
  };
}

export function getCatalogError(error) {
  return {
    type: GET_CATALOG_ERROR,
    payload: error,
  };
}

export function redirectToArticleAction(articleId, catalogId) {
  return {
    type: REDIRECT_TO_ARTICLE,
    payload: { articleId, catalogId },
  };
}

export const getRedirectionRouteSelector = (state, articleId, catalogId) => {
  const application = getApplicationByWidgetSelector(state, catalogId);
  const currentRoute = state.router.location.pathname;
  const redirectionRoute = `${ARTICLE_ROUTE}/${articleId}?id=${application.id}`;
  return getRedirectionRoute(currentRoute, redirectionRoute);
};

export function* getCatalog(action) {
  const config = {
    method: "get",
    url: `${getAPIUrl()}/widget/catalog/${action.payload}/`,
  };
  try {
    const catalog = yield call(request, config);
    yield put(getCatalogSuccess(catalog));
  } catch (error) {
    yield put(getCatalogError(error));
  }
}

export function* redirectToArticle(action) {
  const { articleId, catalogId } = action.payload;
  const redirectionRoute = yield select(getRedirectionRouteSelector, articleId, catalogId);
  yield put(push(redirectionRoute));
}

export function* getCatalogSaga() {
  yield takeEvery(GET_CATALOG_REQUEST, getCatalog);
}

export function* redirectToArticleSaga() {
  yield takeEvery(REDIRECT_TO_ARTICLE, redirectToArticle);
}

const initialState = {
  error: null,
  catalog: null,
};

/**
 * Following the duck pattern, the module.js file should export a reducer as a default function
 */
export function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_CATALOG_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case GET_CATALOG_REQUEST:
      return {
        ...state,
        catalog: null,
        error: null,
      };
    case GET_CATALOG_SUCCESS:
      return {
        ...state,
        catalog: action.payload,
      };
    default:
      return state;
  }
}

export default reducer;
