import React from "react";

import * as Sentry from "@sentry/react";
import "core-js/stable";
import { createBrowserHistory } from "history";
import ReactDOM from "react-dom";
import "react-quill/dist/quill.snow.css";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";

import dataProvider from "dataProvider";

import App from "./App";
import murfyAuthProvider from "./authProvider";
import "./index.css";
import configureStore from "./modules/store";

const initialState = {};

const history = createBrowserHistory();

const { store, persistor } = configureStore(initialState, history, dataProvider, murfyAuthProvider);

const rootEl = document.getElementById("root");

const environment = process.env.REACT_APP_ENVIRONMENT || "dev";

if (["master", "preprod", "integration"].includes(environment)) {
  Sentry.init({
    dsn: "https://379ed92fc0224262bfc2843ccd2f6d52@o464319.ingest.sentry.io/5952189",
    environment: environment,
  });
}

ReactDOM.render(
  <App
    store={store}
    history={history}
    persistor={persistor}
    authProvider={murfyAuthProvider}
    dataProvider={dataProvider}
  />,
  rootEl
);

if (module.hot) {
  module.hot.accept("./App", () => {
    const NextApp = require("./App").default;
    ReactDOM.render(
      <NextApp
        store={store}
        history={history}
        persistor={persistor}
        authProvider={murfyAuthProvider}
        dataProvider={dataProvider}
      />,
      rootEl
    );
  });
}

export { store };
