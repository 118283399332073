export default {
  addSparePartWrapper: {
    height: "64px",
    borderBottom: "2px solid #F4F3F8",
    textAlign: "left",
    marginLeft: "42px",
    marginBottom: "30px",
    paddingTop: "19px",
  },
  toolbar: {
    border: "none",
    width: 0,
  },
};
