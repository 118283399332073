import React from "react";

import IconButton from "@material-ui/core/IconButton";
import { HistoryOutlined } from "@material-ui/icons";

export default ({ record = {}, openHistoryModal }) => {
  return (
    <IconButton onClick={openHistoryModal(record)}>
      <HistoryOutlined />
    </IconButton>
  );
};
