import React from "react";

import { Box, Button, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import DoneIcon from "@material-ui/icons/Done";

import Modal from "components/Modal";
import SkuModelReferences from "components/molecules/SkuModelReferences";

const SkuModelReferencesModalStyles = {
  modal: {
    width: 500,
    textAlign: "center",
  },
};

export default withStyles(SkuModelReferencesModalStyles)(
  ({ classes, isOpen, skuId, designation, onModalClose, title, btnText = "J'ai commandé la pièce" }) => (
    <Modal isOpen={isOpen} handleCloseModal={onModalClose} modalClassName={`modal-small-content ${classes.modal}`}>
      {title && (
        <Box mb={1}>
          <Typography variant="h2">{title}</Typography>
        </Box>
      )}
      <SkuModelReferences skuId={skuId} designation={designation} />
      <Box mt={1}>
        <Button variant="contained" color="primary" onClick={onModalClose}>
          <DoneIcon />
          {btnText}
        </Button>
      </Box>
    </Modal>
  )
);
