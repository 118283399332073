import React from "react";

import { AutocompleteInput, Filter, ReferenceInput, SearchInput, SelectInput } from "react-admin";

import { ThemeProvider } from "@material-ui/core/styles";

import CustomFiltersTheme from "shared/themes/customFiltersTheme.js";

const StatusChoices = [
  { id: "ordered", name: "Commandé" },
  { id: "reserved", name: "Réservé" },
  { id: "instock", name: "En stock" },
  { id: "waiting_for_packing", name: "En attente de colisage" },
  { id: "packed", name: "Colisé" },
  { id: "received_tech", name: "Reçu Technicien" },
  { id: "received_wrong_tech", name: "Reçu Mauvais Technicien" },
  { id: "installed", name: "Posé" },
  { id: "lost", name: "Perdu" },
  { id: "return_to_warehouse", name: "A retourner à l'atelier" },
];

const CategoryChoices = [
  { id: "home_repair", name: "Réparation à domicile" },
  { id: "workshop_repair", name: "Réparation en atelier" },
];

const optionRenderer = (choice) => (choice.id ? choice.first_name + " " + choice.last_name : "Tout");

class SparePartListFilter extends React.Component {
  componentWillUnmount() {
    this.setFilterProps();
  }

  setFilterProps() {
    if (this.props.setFilters) {
      this.props.setFilters({});
    }
  }

  render() {
    return (
      <ThemeProvider theme={CustomFiltersTheme}>
        <Filter {...this.props}>
          <SearchInput
            source="q"
            alwaysOn
            label="Rechercher"
            placeholder=""
            inputProps={{
              style: {
                paddingTop: 20,
              },
            }}
          />
          <ReferenceInput
            label="Techniciens"
            reference="employees"
            source="employee_id"
            filter={{ role: "repairman" }}
            alwaysOn
            allowEmpty
          >
            <AutocompleteInput
              InputProps={{ disableUnderline: true }}
              optionValue="id"
              optionText={optionRenderer}
              emptyText="Tout"
            />
          </ReferenceInput>
          <SelectInput
            source="status"
            choices={StatusChoices}
            alwaysOn
            emptyText="Tout"
            InputProps={{ disableUnderline: true }}
            label="Statut"
          />
          <SelectInput
            source="customer_file_category"
            label="Type de dossier"
            choices={CategoryChoices}
            alwaysOn
            emptyText="Tout"
            InputProps={{ disableUnderline: true }}
          />
          <SelectInput
            source="aswo_order_delay"
            label="Retard"
            choices={[
              { id: "delay", name: "Retard" },
              { id: "urgent", name: "Urgent" },
              { id: "ok", name: "Ok" },
            ]}
            alwaysOn
            emptyText="Tout"
            InputProps={{ disableUnderline: true }}
          />
        </Filter>
      </ThemeProvider>
    );
  }
}

export default SparePartListFilter;
