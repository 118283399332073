import { useCallback, useEffect, useState } from "react";

import { CreditNote } from "types/credit-note";

import { getHeaders } from "utils/headers";

export const useCreditNotesToSettle = (orderId: number) => {
  const [query, setQuery] = useState<any>({
    isSuccess: false,
    data: undefined,
    error: undefined,
  });
  const [salt, setSalt] = useState(false);
  const refetch = useCallback(() => {
    setSalt((salt) => !salt);
  }, [setSalt]);

  useEffect(() => {
    let abort = false;
    const fetchCreditNotes = async () => {
      const token = localStorage.getItem("token");

      const headers = getHeaders(token);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/ecom/order/${orderId}/credit-notes-to-settle/`, {
        headers,
      });
      const data: CreditNote[] = await response.json();
      if (!abort) {
        setQuery({
          isSuccess: true,
          data: data,
          error: undefined,
        });
      }
    };
    fetchCreditNotes();
    return () => {
      abort = true;
    };
  }, [refetch, salt, orderId]);
  return { ...query, refetch };
};
