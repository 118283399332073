import React, { Component } from "react";

import { SelectInput, SimpleForm } from "react-admin";

import PropTypes from "prop-types";

import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

import Modal from "components/Modal";

import DelaySelectionModalStyles from "./DelaySelectionModalStyles.js";

const delayOptions = [
  { id: "three-to-five-days", name: "3 à 5 jours" },
  { id: "four-to-six-days", name: "4 à 6 jours" },
  { id: "one-week", name: "1 semaine" },
  { id: "one-to-two-weeks", name: "1 à 2 semaines" },
  { id: "two-to-three-weeks", name: "2 à 3 semaines" },
  { id: "three-to-four-weeks", name: "3 à 4 semaines" },
  { id: "four-to-five-weeks", name: "4 à 5 semaines" },
  { id: "five-to-six-weeks", name: "5 à 6 semaines" },
];

export class DelaySelectionModal extends Component {
  static propTypes = {
    isDelaySelectionModalOpen: PropTypes.bool,
    handleCloseDelaySelectionModal: PropTypes.func,
    handleValidateDelay: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      delay: null,
    };
  }

  handleValidateDelay = () => {
    this.props.handleValidateDelay(this.state.delay);
  };

  onInputChange = (inputName) => (event) => {
    this.setState({ [inputName]: event.target.value });
  };

  render() {
    const { classes } = this.props;
    return (
      <Modal
        isOpen={this.props.isDelaySelectionModalOpen}
        handleCloseModal={this.props.handleCloseDelaySelectionModal}
        modalClassName="modal-small-content"
        className={classes.DelaySelectionModalContainer}
      >
        <Typography className={classes.modalTitle} variant="h2">
          Délais de livraison global
        </Typography>
        <SimpleForm submitOnEnter={false} redirect={false} className={classes.simpleForm} noValidate toolbar={false}>
          <SelectInput
            label="Délai de livraison"
            name="Délais de livraison"
            fullWidth
            choices={delayOptions}
            className={classes.inputValue}
            InputProps={{ disableUnderline: true }}
            onChange={this.onInputChange("delay")}
          />
        </SimpleForm>
        <div className={classes.buttonContainer}>
          <Button
            id="validation-button"
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={this.handleValidateDelay}
            disabled={!this.state.delay}
          >
            Terminer
          </Button>
          <Button
            id="cancel-button"
            onClick={this.props.handleCloseDelaySelectionModal}
            className={classes.cancelButton}
          >
            Annuler
          </Button>
        </div>
      </Modal>
    );
  }
}

export default withStyles(DelaySelectionModalStyles)(DelaySelectionModal);
