/**
 * Helper to extract a given address component from a GooglePlaces address
 * @param {*} googleAddress address components of a GooglePlaces place from autocomplete getPlace
 * @param {*} fieldName the address component type of the value to be retrieved (e.g 'locality')
 */
export const getFieldFromAddress = (googleAddress, fieldName) => {
  if (!googleAddress || googleAddress.constructor !== Array) {
    return undefined;
  }
  const retrievedAddressElement = googleAddress.find(
    (addressElement) => addressElement.types && addressElement.types.includes(fieldName)
  );
  return retrievedAddressElement && retrievedAddressElement.long_name;
};
