import React from "react";

import { Product } from "types/products";

import { useProductDiagnosticHistory } from "utils/hooks/useProductDiagnosticHistory";

import Diagnostic from "./Diagnostic";

export const ProductDiagnosticHistory = ({ product, visitId }: { product: Product; visitId: number }) => {
  const diagnosticHistoryQuery = useProductDiagnosticHistory(product.id, visitId);
  if (diagnosticHistoryQuery.isLoading) {
    return <div> Chargement ... </div>;
  }
  if (diagnosticHistoryQuery.isError) {
    return <div> Erreur de chargement du diagnostic, Merci de déclarer un incident dans le canal approprié</div>;
  }

  if (diagnosticHistoryQuery.data.length === 0) {
    return <></>;
  }

  return (
    <>
      {diagnosticHistoryQuery.data.map((diagnostic: any) => {
        return <Diagnostic diagnostic={diagnostic} />;
      })}
    </>
  );
};
