import React from "react";

import { ImageInput, Labeled } from "react-admin";

import { makeStyles } from "@material-ui/core/styles";

import ProductImageField from "components/ProductImageField";

const useStyles = makeStyles({
  container: {
    minHeight: "18rem",
  },
});

const ProductImageInput = ({ source, record, label, disabled }) => {
  const classes = useStyles();

  if (disabled) {
    return (
      <div class={classes.container}>
        <Labeled label={label}>
          <ProductImageField record={record[source]} title={label} label={label} />
        </Labeled>
      </div>
    );
  }

  return (
    <div class={classes.container}>
      <ImageInput source={source} label={label} accept="image/*" placeholder={<p>Glissez une image ici</p>}>
        <ProductImageField title={label} />
      </ImageInput>
    </div>
  );
};

export default ProductImageInput;
