import React from "react";

import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import CreateIcon from "@material-ui/icons/Create";

import CustomerFileTagsList from "pages/customerFiles/CustomerFilesTags/CustomerFileTagsList/CustomerFileTagsList";

import CustomerFileTag from "../components/CustomerFileTag";

const tagStyle = {
  root: {
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    paddingTop: "4px",
  },
  editButton: {
    margin: "0 0 0 20px",
    display: "flex",
    flexDirection: "horizontal",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
      userSelect: "none",
    },
  },
};

const CustomerFilesTagsField = ({
  canEdit = false,
  classes,
  customerFilesTags = {},
  deleteCustomerFilesTag,
  displaystyle = "",
  isTagSuccessfullySubmitted,
  postCustomerFilesTag,
  record = {},
  updateCustomerFileDetail,
}) => {
  const patchCustomerFileTags = (customerFileId, tags) =>
    updateCustomerFileDetail(customerFileId, { customer_file_tags: tags });
  return (
    <div className={classes.root}>
      {customerFilesTags.length > 0 &&
        record.customer_file_tags.map((tagId) => (
          <CustomerFileTag tagId={tagId} customerFileTags={customerFilesTags} />
        ))}
      {canEdit ? (
        <div className={classes.editButton}>
          <CreateIcon />
          <CustomerFileTagsList
            canEdit
            customerFileId={record.id}
            customerFileTags={record.customer_file_tags}
            deleteCustomerFilesTag={deleteCustomerFilesTag}
            isTagSuccessfullySubmitted={isTagSuccessfullySubmitted}
            patchCustomerFileTags={patchCustomerFileTags}
            postCustomerFilesTag={postCustomerFilesTag}
            tagsList={customerFilesTags}
          />
        </div>
      ) : null}
    </div>
  );
};

CustomerFilesTagsField.propTypes = {
  customerFilesTags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      text: PropTypes.string,
      color: PropTypes.string,
    })
  ),
};

export default withStyles(tagStyle)(CustomerFilesTagsField);
