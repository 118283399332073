import React from "react";

import { Box } from "@material-ui/core";
import { Divider, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from "@material-ui/core";
import { Theme } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { styled } from "@material-ui/styles";

type CollapsibleBlockProps = {
  children: React.ReactNode;
  title: React.ReactNode;
  size?: string;
  openByDefault?: boolean;
  indent?: number;
};

const StyledCollapsibleBlock = styled(ExpansionPanel)(({ theme }: any) => ({
  backgroundColor: theme.palette.primary.white,
  borderRadius: theme.spacing(1),
  border: "none",
  "&:before": {
    background: "transparent",
  },
}));

const StyledExpensionPanelDetails = styled(ExpansionPanelDetails)({
  display: "block",
});

const StyledExpansionPanelSummary = styled(ExpansionPanelSummary)<Theme, { size: string }>(({ theme, size }) => ({
  paddingY: size === "md" ? theme.spacing(3) : theme.spacing(1),
}));

const CollapsibleBlock: React.FunctionComponent<CollapsibleBlockProps> = ({
  openByDefault = true,
  children,
  title,
  size = "md",
  indent = 0,
}) => {
  return (
    <StyledCollapsibleBlock elevation={0} square defaultExpanded={openByDefault}>
      <StyledExpansionPanelSummary size={size} expandIcon={<ChevronRightIcon />}>
        {title}{" "}
      </StyledExpansionPanelSummary>
      <StyledExpensionPanelDetails>
        <Divider />
        <Box pl={indent}>{children}</Box>
      </StyledExpensionPanelDetails>
    </StyledCollapsibleBlock>
  );
};

export default CollapsibleBlock;
