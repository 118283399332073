import { push } from "connected-react-router";
import { connect } from "react-redux";

import { resetActiveMenuElement } from "pages/Cms/ContentManagement/module";

import MenuButton from "./MenuButton.component";

function mapStateToProps(state) {
  return {
    activeRoute: state.router.location.pathname,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigateTo(path) {
      dispatch(push(path));
      dispatch(resetActiveMenuElement());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuButton);
