import React, { useState } from "react";

import { Edit, SimpleForm, TextInput } from "react-admin";

import { Box, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";

import EditionModal from "components/ProductModelEditionModal/ProductModelEditionModal";

import ProductModelGtinEditionField from "./ProductModelGtinEditionField";

const DisabledInput = ({ source, label, classes }) => {
  return (
    <Box ml={1} mr={1}>
      <TextInput
        className={classes.disabled}
        source={source}
        label={label}
        disabled
        InputProps={{ disableUnderline: true }}
      />
    </Box>
  );
};

const ProductModelForm = ({ id, save, product }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyle();
  return (
    <>
      <Typography variant="body2" color="secondary.contrastText" className={classes.root}>
        Modèle de la machine
      </Typography>
      {id !== undefined ? (
        <Edit resource="product-models" id={id} basePath="">
          <SimpleForm toolbar={null} className={classes.editForm}>
            <Box width="100% !important">
              <Box display="flex" justifyContent="center" width="fit-content !important">
                <DisabledInput classes={classes} source="product_type.name" label="Catégorie" />
                <DisabledInput classes={classes} source="brand.name" label="Marque" />
                <DisabledInput classes={classes} source="reference" label="Référence" />
                <Box className={classes.editIcon}>
                  <EditIcon onClick={() => setOpen(true)} />
                </Box>
              </Box>
              <ProductModelGtinEditionField />
            </Box>
            {open && (
              <EditionModal
                title="Modifier le modèle de la machine"
                save={save}
                open={open}
                handleCloseModal={() => setOpen(false)}
              />
            )}
          </SimpleForm>
        </Edit>
      ) : (
        <Box p={4} textAlign="center">
          <Button className={classes.editButton} onClick={() => setOpen(true)}>
            Associer un modèle à la machine
          </Button>
          <SimpleForm toolbar={null} className={classes.editForm}>
            {open && (
              <EditionModal
                title="Associer un modèle à la machine"
                save={save}
                open={open}
                handleCloseModal={() => setOpen(false)}
                product={product}
              />
            )}
          </SimpleForm>
        </Box>
      )}
    </>
  );
};

const useStyle = makeStyles((theme) => ({
  editForm: {
    display: "flex",
    width: "fit-content",
  },
  editIcon: {
    marginTop: 18,
    marginLeft: theme.spacing(1),
    color: theme.palette.secondary.grey900,
    textAlign: "center",
    cursor: "pointer",
  },
  root: {
    fontWeight: "600",
    color: theme.palette.secondary.contrastText,
  },
  editButton: {
    marginTop: 18,
    marginLeft: theme.spacing(1),
    color: theme.palette.secondary.grey900,
    textAlign: "center",
    cursor: "pointer",
  },
  disabled: {
    "& input": {
      color: theme.palette.secondary.contrastText,
      background: "#F4F3F8",
      border: "none",
      borderRadius: 10,
    },
  },
}));

export default ProductModelForm;
