import { USER_LOGIN_SUCCESS } from "react-admin";

import { call, put, takeEvery } from "redux-saga/effects";

import request, { getTokenFromLocalStorage } from "utils/networking";

export const GET_COMPANY_EMPLOYEE_REQUEST = "Company/GET_COMPANY_EMPLOYEE_REQUEST";
export const GET_COMPANY_EMPLOYEE_SUCCESS = "Company/GET_COMPANY_EMPLOYEE_SUCCESS";
export const GET_COMPANY_EMPLOYEE_ERROR = "Company/GET_COMPANY_EMPLOYEE_ERROR";

export const GET_PRODUCTS_REQUEST = "CMS/GET_PRODUCTS_REQUEST";
export const GET_PRODUCTS_SUCCESS = "CMS/GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_ERROR = "CMS/GET_PRODUCTS_ERROR";

export function getProductsRequest() {
  return {
    type: GET_PRODUCTS_REQUEST,
  };
}

export function getProductsSuccess(products) {
  return {
    type: GET_PRODUCTS_SUCCESS,
    payload: products,
  };
}

export function getProductsError(error) {
  return {
    type: GET_PRODUCTS_ERROR,
    payload: error,
  };
}

export function getCompanyEmployeeRequest() {
  return {
    type: GET_COMPANY_EMPLOYEE_REQUEST,
  };
}

export function getCompanyEmployeeSuccess(company) {
  return {
    type: GET_COMPANY_EMPLOYEE_SUCCESS,
    payload: company,
    meta: {
      analytics: {
        type: "SEND_EVENT",
        payload: {
          action: "ENTER_APPLICATION",
          category: "EMPLOYEE",
          label: company.id,
          trackerName: "admin",
        },
      },
    },
  };
}

export function getCompanyEmployeeError(error) {
  return {
    type: GET_COMPANY_EMPLOYEE_ERROR,
    payload: error,
  };
}

export const getCompanyEmployeeSelector = (state) => state.companyEmployee.company;

export function* getCompanyEmployee(action) {
  const token = yield call(getTokenFromLocalStorage);
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/common/company/employee/`,
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  try {
    const company = yield call(request, config);
    yield put(getCompanyEmployeeSuccess(company));
  } catch (error) {
    yield put(getCompanyEmployeeError(error));
  }
}

// worker Saga: will be fired on GET_COMPANY_EMPLOYEE_REQUEST/USER_LOGIN_SUCCESS actions
export function* getCompanyEmployeeSaga() {
  yield takeEvery([GET_COMPANY_EMPLOYEE_REQUEST, USER_LOGIN_SUCCESS], getCompanyEmployee);
}

export function* getProducts(action) {
  const token = yield call(getTokenFromLocalStorage);
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/common/product/`,
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  try {
    const products = yield call(request, config);
    yield put(getProductsSuccess(products));
  } catch (error) {
    yield put(getProductsError(error));
  }
}

export function* getProductsSaga() {
  yield takeEvery([GET_PRODUCTS_REQUEST, USER_LOGIN_SUCCESS], getProducts);
}

const initialState = {
  company: null,
  error: null,
  products: [],
};

/**
 * Following the duck pattern, the module.js file should export a reducer as a default function
 */
export function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_COMPANY_EMPLOYEE_REQUEST:
      return {
        ...state,
        error: null,
      };
    case GET_COMPANY_EMPLOYEE_SUCCESS:
      return {
        ...state,
        company: action.payload,
        error: null,
      };
    case GET_COMPANY_EMPLOYEE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload,
      };
    case GET_PRODUCTS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default reducer;
