/* global ga */
import { dimensions } from "./base";
import { eventTracker, facebookTracker, pageviewTracker } from "./trackers";

/**
 * Send updates or tracking actions to ga tracker
 * @param {action} analyticsAction
 */
export const googleAnalyticsTracker = (analyticsAction) => {
  // GA tracker updates
  if (
    analyticsAction.type === "SEND_EVENT" &&
    (analyticsAction.payload.action === "ENTER_APPLICATION" || analyticsAction.payload.action === "ACCESS_COMPANY")
  ) {
    const trackerName = analyticsAction.payload.trackerName;
    ga(`${trackerName}.set`, dimensions[trackerName].COMPANY_ID, analyticsAction.payload.label);
  }

  // pageviews
  if (analyticsAction.type === "SEND_PAGEVIEW") {
    pageviewTracker(analyticsAction.payload.fullPath, analyticsAction.payload.trackerName);
    return;
  }

  // events (default case)
  const event = analyticsAction.payload;
  eventTracker(event.category, event.action, event.label, event.trackerName, event.value, event.nonInteraction);
};

export const getTrackerName = (pathname) => {
  const adminApplicationResolver = RegExp("^/admin/");
  if (adminApplicationResolver.test(pathname)) {
    return "admin";
  }
  return "user";
};

export const handleAction = (store, next, action) => {
  // track router location changes for pageviews
  if (action.type === "@@router/LOCATION_CHANGE") {
    const analyticsAction = {
      type: "SEND_PAGEVIEW",
      payload: {
        fullPath: action.payload.pathname + action.payload.search,
        trackerName: getTrackerName(action.payload.pathname),
      },
    };
    googleAnalyticsTracker(analyticsAction);
    return next(action);
  }

  // track standard analytics actions from redux
  if (action.meta && action.meta.analytics) {
    googleAnalyticsTracker(action.meta.analytics);
  }
  if (action.meta && action.meta.facebookPixel) {
    facebookTracker(action.meta.facebookPixel.type, action.meta.facebookPixel.payload);
  }
  return next(action);
};

export function analyticsMiddleware() {
  return (store) => (next) => (action) => handleAction(store, next, action);
}
