import React from "react";

import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

const employeesAvatarsStyles = {
  field: {
    fontSize: "12px",
    color: "white",
    display: "inline",
  },
  avatar: {
    width: "35px",
    height: "35px",
  },
  grid: {
    width: "initial",
    marginTop: "5px",
  },
};

export const EmployeesAvatarsFieldAuto = ({ classes }) => {
  return (
    <Grid container justify="flex-start" className={classes.grid} alignItems="center">
      <Avatar
        className={classes.avatar}
        style={{
          backgroundColor: "#95a5a6",
        }}
      >
        <Tooltip title={"Auto"}>
          <Typography variant="body1" className={classes.field}>
            A
          </Typography>
        </Tooltip>
      </Avatar>
    </Grid>
  );
};
export default withStyles(employeesAvatarsStyles)(EmployeesAvatarsFieldAuto);
