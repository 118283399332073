import React from "react";

import Tooltip from "@material-ui/core/Tooltip";
import ReportIcon from "@material-ui/icons/Report";

export default ({ record }) =>
  record.is_defective && (
    <Tooltip
      title={
        <>
          <span style={{ whiteSpace: "pre-line" }}>
            {record.defects.map((defect) => (
              <>
                {defect.defect_display}
                <br />
              </>
            ))}
          </span>
        </>
      }
    >
      <ReportIcon />
    </Tooltip>
  );
