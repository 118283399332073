import React from "react";

import { FunctionField } from "react-admin";

import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

const employeesAvatarsStyles = {
  field: {
    fontSize: "12px",
    color: "white",
    display: "inline",
  },
  avatar: {
    width: "35px",
    height: "35px",
  },
  grid: {
    width: "initial",
    marginTop: "5px",
  },
};

const paletteBg = [
  "#f39c12",
  "#7B68EE",
  "#FF1493",
  "#95a5a6",
  "#e74c3c",
  "#27ae60",
  "#1abc9c",
  "#3498db",
  "#007bff",
  "#FF8C00",
  "#DC143C",
  "#FF0000",
];

export function getEmployeeNameInitialsFromId(id, employees = []) {
  const employee = employees.find((employees) => id === employees.id);
  if (employee) {
    let firstName, lastName;
    if (employee.first_name) {
      firstName = employee.first_name[0];
    } else {
      firstName = "";
    }
    if (employee.last_name) {
      lastName = employee.last_name[0];
    } else {
      lastName = "";
    }

    return firstName.toUpperCase() + "." + lastName.toUpperCase();
  } else return "X.X";
}
export function getEmployeeNameFromId(id, employees = []) {
  const employee = employees.find((employees) => id === employees.id);
  if (employee) {
    let firstName, lastName;
    if (employee.first_name) {
      firstName = employee.first_name;
    } else {
      firstName = "prénom manquant";
    }
    if (employee.last_name) {
      lastName = employee.last_name;
    } else {
      lastName = "nom manquant";
    }

    return firstName + " - " + lastName;
  } else return "Employé inconnu";
}

export const EmployeesAvatarsField = ({ record = {}, employees, classes }) => {
  return (
    <Grid container justify="flex-start" className={classes.grid} alignItems="center">
      {record.assigned_employees ? (
        record.assigned_employees.map((id) => (
          <Avatar
            key={id}
            className={classes.avatar}
            style={{
              backgroundColor: paletteBg[getEmployeeNameInitialsFromId(id, employees) === "X.X" ? 11 : id % 10],
            }}
          >
            <Tooltip title={getEmployeeNameFromId(id, employees)}>
              <FunctionField
                record={record}
                className={classes.field}
                render={(record) => `${getEmployeeNameInitialsFromId(id, employees)}`}
              />
            </Tooltip>
          </Avatar>
        ))
      ) : (
        <span>Error</span>
      )}
    </Grid>
  );
};
export default withStyles(employeesAvatarsStyles)(EmployeesAvatarsField);
