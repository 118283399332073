import * as React from "react";

import { Typography, makeStyles } from "@material-ui/core";

import { useField, useForm } from "react-final-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

export default ({ source, label }) => {
  const { input, meta } = useField(source);
  const { change } = useForm();
  const classes = useStyles();

  return (
    <>
      <PhoneInput
        {...input}
        onChange={(value, country, e, formattedValue) => {
          change(source, formattedValue.replace(/ /g, ""));
        }}
        isValid={meta.error ? false : true}
        containerClass={classes.container}
        inputClass={classes.input}
        buttonClass={classes.button}
        specialLabel={<Typography variant="caption">{label}</Typography>}
        country="fr"
        placeholder="+33 6 01 02 03 04"
      />
      {meta.error && (
        <Typography variant="caption" color="error">
          {meta.error}
        </Typography>
      )}
    </>
  );
};

const useStyles = makeStyles({
  container: {
    height: 48,
    "& .special-label": {
      backgroundColor: "transparent",
      top: 0,
      left: 53,
    },
    "& .flag-dropdown": {
      height: 48,
      width: 50,
    },
  },
  input: {
    outline: "none",
    background: "#F4F3F8 !important",
    height: "100%",
    width: "100% !important",
    border: "none !important ",
    borderRadius: "12px !important",
    padding: "30px 14px 18.5px 58px !important",
    "&:hover": {
      background: "#E8E8E8 !important",
    },
    "&:focus": {
      background: "#E8E8E8 !important",
      outline: "none !important",
      outlineWidth: "0 !important",
    },
  },
  button: {
    borderRadius: "12px !important",
    border: "1px solid #F4F3F8 !important",
    background: "#F4F3F8 !important",
  },
});
