import React, { useState } from "react";

import { Box, IconButton, Typography } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import ScheduleIcon from "@material-ui/icons/Schedule";

import { toastr } from "react-redux-toastr";
import { CustomerFileDetailed } from "types/customer-files";
import { Timeslot } from "types/timeslot";
import { BaseVisit as Visit } from "types/visits";

import SuggestTimeslot from "components/organisms/SuggestTimeslot";

import { proposalTimeslot } from "utils/api";

interface BaseVisit extends Omit<Visit, "timeslot"> {
  timeslot: Timeslot;
}
type CustomerFileMultiSuggestionProps = {
  customerFile: CustomerFileDetailed;
  currentVisit: BaseVisit | undefined;
  getDaysTimeslots: () => void;
  typeButton?: "TOOLTIP" | "BUTTON";
  getCustomerFileDetail?: (customerId: number) => void;
  route?: "reschedule" | "suggest-timeslot";
  title?: string;
};

const CustomerFileMultiSuggestion: React.FC<CustomerFileMultiSuggestionProps> = ({
  customerFile,
  currentVisit,
  getDaysTimeslots,
  typeButton = "TOOLTIP",
  getCustomerFileDetail,
  route = "suggest-timeslot",
  title = "Proposer des créneaux",
}) => {
  const classes = useStyle();
  const [isOpen, setIsOpen] = useState(false);
  const [keepTimeslot, setKeepTimeslots] = useState(true);
  const closeModal = () => {
    setIsOpen(false);
  };
  const openModal = () => {
    setIsOpen(true);
  };

  const handleKeepTimeslot = () => {
    setKeepTimeslots((keepTimeslot) => !keepTimeslot);
  };

  const sendProposalTimeslot = async (timeslots: Timeslot[], employee: string, VisitType: string) => {
    const { status } = await proposalTimeslot(
      customerFile.uuid,
      {
        payload: {
          timeslots: timeslots.map((item) => {
            return {
              id: 1,
              beginning: item.beginning,
              end: item.end,
            };
          }),
          ...(employee && { employee: employee }),
          keep_timeslot: keepTimeslot,
          visit_type: VisitType,
        },
      },
      route
    );
    if (status < 200 || status >= 300) {
      toastr.error("Erreur", "Un problème est survenu sur la proposition de créneau");
    } else {
      toastr.success("Succès", "Proposition envoyée avec succès.");
    }
    getDaysTimeslots && getDaysTimeslots();
    getCustomerFileDetail && getCustomerFileDetail(customerFile.id);
    closeModal();
  };

  const getTypeButton = () => {
    if (typeButton === "BUTTON")
      return (
        <Box width="fit-content" className={classes.actionButton} onClick={() => openModal()}>
          <Typography className={classes.body2} variant="body2">
            {title}
          </Typography>
        </Box>
      );

    return (
      <IconButton onClick={() => openModal()} className={classes.iconButton}>
        <Tooltip title={"Proposition de RDV"}>
          <ScheduleIcon className={classes.icon} />
        </Tooltip>
      </IconButton>
    );
  };
  return (
    <>
      {getTypeButton()}
      {isOpen && (
        <SuggestTimeslot
          isDialogOpen={isOpen}
          closeModal={closeModal}
          handleSubmit={sendProposalTimeslot}
          minimumCapacity={currentVisit && currentVisit.time_needed ? 2 : 1}
          handleKeepTimeslot={handleKeepTimeslot}
          keepTimeslot={keepTimeslot}
          displayKeepTimeslot={typeButton === "TOOLTIP"}
          customerFile={customerFile}
          visitType={currentVisit ? currentVisit.visit_type : ""}
          assignedRepairman={currentVisit && currentVisit.assigned_repairman}
        />
      )}
    </>
  );
};

export default CustomerFileMultiSuggestion;

const useStyle = makeStyles(() => ({
  iconButton: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    marginLeft: 8,
  },
  icon: {
    fontSize: 20,
  },
  actionButton: {
    boxShadow: "0px 1px 15px rgba(0, 0, 0, 0.08), 0px 0px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: 4,
    backgroundColor: "white",
    color: "#8795B3",
    cursor: "pointer",
    justifyContent: "space-around",
    padding: "4px 8px",
    whiteSpace: "nowrap",
    "&:hover": {
      background: "#f1f1f1",
    },
    margin: "0 4px 4px",
  },
  body2: {
    display: "inline",
    fontWeight: 500,
    color: "#8795B3",
  },
}));
