import React, { Component } from "react";

import PropTypes from "prop-types";

import "./style.css";

export default class ToggleContent extends Component {
  static propTypes = {
    children: PropTypes.node,
    isChecked: PropTypes.bool.isRequired,
    onCheckboxChange: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
  };

  render() {
    return (
      <div className="toggle-content-container">
        <p className="toggle-content-title">
          <span>{this.props.title}</span>
          <input
            defaultChecked={this.props.isChecked}
            className="admin-checkbox"
            onClick={this.props.onCheckboxChange}
            type="checkbox"
          />
        </p>
        {this.props.isChecked && this.props.children}
      </div>
    );
  }
}
