import { FormTab } from "react-admin";

import { withStyles } from "@material-ui/core";

export const ProductTab = withStyles({
  root: {
    color: "#8795B3",
    fontWeight: 600,
    height: 34,
    margin: "2px 0",
    minHeight: 34,
    textTransform: "none",
    fontFamily: "Poppins",
    "& :hover": {
      color: "#F7B349",
    },
  },
  selected: {
    background: "rgba(247, 179, 73, 0.15)",
    borderRadius: 8,
    color: "#F7B349",
  },
})(FormTab);

export default ProductTab;
