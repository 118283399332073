import {
  ADDITIONAL_PACKAGE,
  AFTER_SALES_PACKAGE,
  BASIC_PACKAGE,
  COMMERCIAL_GESTURE,
  CONSUMABLE,
  DISCOUNTED_PACKAGE,
  HERMETIC_UNIT,
  LEGACY_QUOTE,
  REDUCTION_CODE,
  REPAIR_BONUS,
  SPARE_PART,
  SPONSORSHIP_REWARD,
  VISIO_DISCOUNT,
} from "./constants.js";

export const quoteElementList = [
  { id: COMMERCIAL_GESTURE, name: "Geste commercial" },
  { id: SPONSORSHIP_REWARD, name: "Récompense Parrainage" },
  { id: BASIC_PACKAGE, name: "Forfait" },
  { id: ADDITIONAL_PACKAGE, name: "Forfait supplémentaire" },
  { id: SPARE_PART, name: "Pièce Détachée" },
  { id: LEGACY_QUOTE, name: "Ancien Devis" },
  { id: REDUCTION_CODE, name: "Bon de réduction" },
  { id: DISCOUNTED_PACKAGE, name: "Forfait Degressif" },
  {
    id: HERMETIC_UNIT,
    name: "Intervention sur unité hermétique",
    comment: "(déshydrateur, recharge de gaz et lockrings)",
  },
  { id: CONSUMABLE, name: "Produit d'entretien" },
  { id: AFTER_SALES_PACKAGE, name: "Intervention machine sous garantie" },
  { id: REPAIR_BONUS, name: "Bonus réparation" },
  { id: VISIO_DISCOUNT, name: "Réduction RD à distance" },
];
export const consumableList = [
  { id: "citric_acid", name: "Acide citrique" },
  { id: "black_soap", name: "Savon noir" },
  { id: "terrabianca", name: "Terrabianca" },
  { id: "ceramic_hob", name: "Kit pour plaque vitrocéramique" },
];

export function getNumberOfSparePartElements(quoteElements) {
  return quoteElements.filter((quoteElement) => quoteElement.element_type === "spare_part").length;
}

export function getQuoteAmountWithTax(quoteElements) {
  return quoteElements.reduce((amount, element) => amount + element.amount_withtax, 0);
}

export function getLastQuote(quotes) {
  return quotes.reduce((lastQuote, quote) => (lastQuote && lastQuote.version > quote ? lastQuote : quote), null);
}
