import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

import dataProvider from "dataProvider";

import Modal from "components/Modal";

import { RenderLog } from "./RenderLog";

const HistoryWrapper = {
  Modal: {
    width: 570,
    height: 580,
    textAlign: "center",
  },
  titleContainer: {
    marginBottom: 24,
    marginTop: 24,
  },
  historyContainer: {
    margin: 32,
    marginBottom: 0,
  },
};

export class SkuHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skuLogList: null,
    };
  }

  getSkuLog = (skuId) => {
    dataProvider
      .getList("sku-log", {
        sort: { field: "id", order: "DESC" },
        filter: { sku: skuId },
      })
      .then(({ data }) => {
        this.setState({ skuLogList: data });
      });
  };

  render() {
    const { classes, isModalOpen, record, employees } = this.props;

    const handleCloseModal = () => {
      this.props.onCloseModal();
    };

    return (
      <Modal
        id="sku-history-modal"
        modalClassName={`modal-small-content ${classes.Modal}`}
        isOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
      >
        <div className={classes.titleContainer}>
          {record.sku_model ? (
            <div>
              {record.sku_model.designation}
              <br />
              {record.sku_model.sku_reference}
            </div>
          ) : null}
          <div className={classes.historyContainer}>
            {this.state.skuLogList && this.state.skuLogList.length > 0 ? (
              this.state.skuLogList.map((log, index) => (
                <RenderLog log={log} index={index} logLength={this.state.skuLogList.length} employees={employees} />
              ))
            ) : (
              <div>Aucun historique disponible pour cette pièce</div>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

export default withStyles(HistoryWrapper)(SkuHistory);
