import React from "react";

import { FunctionField } from "react-admin";

const TicketDescriptionReportField = ({ record }) => {
  const ticketDescription = record.ticket_description;
  return ticketDescription &&
    ticketDescription.includes('{"id":') &&
    ticketDescription.includes('"description":') &&
    ticketDescription &&
    ticketDescription[0] ? (
    JSON.parse(ticketDescription).map((description, index) => (
      <div key={index}>
        <FunctionField label="Type de CR" key={index} render={() => description.description} />
      </div>
    ))
  ) : (
    <div>
      <FunctionField label="Type de CR" render={(record) => `${ticketDescription}` || record.ticket_description} />
    </div>
  );
};
export default TicketDescriptionReportField;
