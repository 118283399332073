import React from "react";

import { Datagrid, ReferenceManyField } from "react-admin";

import { Typography, withStyles } from "@material-ui/core";

import SkuDemandStatusField from "components/SkuDemandStatusField";

import DemandItemList from "pages/SkuStore/Components/DemandItemList";

const DemandSnapshotField = ({ record, classes }) => (
  <span className={classes.snapshot}>
    <span className={classes.snapshotTitleStatus}>
      <Typography variant="subtitle1">Demande {record.id}</Typography>
      <SkuDemandStatusField record={record} />
    </span>
    <Typography variant="subtitle2" className={classes.totalPretax}>
      {(record.total_pretax / 100).toFixed(2).toString() + "€"}
    </Typography>
  </span>
);

const SkuDemandsStyles = {
  container: {
    padding: 0,
    paddingLeft: 8,
    background: "white",
  },
  snapshot: {
    display: "flex",
    justifyContent: "space-between",
  },
  snapshotTitleStatus: {
    display: "flex",
    "column-gap": 24,
  },
  totalPretax: {
    marginRight: 24,
  },
};

const StyledDatagrid = withStyles({
  headerRow: {
    display: "none",
  },
  expandIconCell: {
    display: "none",
  },
  rowCell: {
    paddingLeft: 40,
    background: "#FFFFFF",
    "&:hover": {
      cursor: "pointer",
      "background-color": "#E4E3E8",
    },
    borderBottom: "none",
    borderRadius: 10,
  },
  row: {
    height: "80px",
    background: "#FFFFFF",
  },
  table: {
    background: "white",
    borderBottom: "none",
    borderRadius: 10,
  },
})(Datagrid);

export const HomeRepairSkuDemands = withStyles(SkuDemandsStyles)(({ customerFile, classes }) => (
  <div>
    <ReferenceManyField reference="sku-demand" target="customer_file_id" record={customerFile} basePath="">
      <StyledDatagrid rowClick="expand" expand={<DemandItemList classes={classes} />}>
        <DemandSnapshotField classes={classes} />
      </StyledDatagrid>
    </ReferenceManyField>
    {/*<CreateHomeRepairSkuDemand customerFileId={customerFile.id} toggleCreation={setIsCreating} />*/}
  </div>
));

export const WorkshopSkuDemands = withStyles(SkuDemandsStyles)(({ workshopFile, classes }) => (
  <div>
    <ReferenceManyField reference="sku-demand" target="workshop_file" record={workshopFile} basePath="">
      <StyledDatagrid rowClick="expand" expand={<DemandItemList classes={classes} />}>
        <DemandSnapshotField classes={classes} />
      </StyledDatagrid>
    </ReferenceManyField>

    {/*<CreateWorkshopSkuDemand workshopFileId={workshopFile.id} toggleCreation={setIsCreating} />*/}
  </div>
));
