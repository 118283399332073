import { crudUpdate } from "react-admin";

import { connect } from "react-redux";

import { patchTicketAdminCommentsRequest, patchTicketEmailRequest } from "pages/Tickets/module";

import Details from "./Details.component";

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    patchTicketEmail(ticketId, email) {
      dispatch(patchTicketEmailRequest(ticketId, email));
    },
    patchTicketAdminComments(ticketId, adminComments) {
      dispatch(patchTicketAdminCommentsRequest(ticketId, adminComments));
    },
    updateTicket(id, updateData) {
      dispatch(crudUpdate("tickets", id, updateData, undefined, undefined, false));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Details);
