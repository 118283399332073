import dropRight from "lodash/dropRight";

export const PUSH_BREADCRUMB_ELEMENT = "Breadcrumb/PUSH_BREADCRUMB_ELEMENT";
export const POP_BREADCRUMB_ELEMENT = "Breadcrumb/POP_BREADCRUMB_ELEMENT";
export const POP_LAST_BREADCRUMB_ELEMENT = "Breadcrumb/POP_LAST_BREADCRUMB_ELEMENT";
export const RESET_BREADCRUMB_ELEMENT = "Breadcrumb/RESET_BREADCRUMB_ELEMENT";

export function pushBreadcrumbElement(text, route) {
  return {
    type: PUSH_BREADCRUMB_ELEMENT,
    payload: { text, route },
  };
}

export function popBreadcrumbElement(index) {
  return {
    type: POP_BREADCRUMB_ELEMENT,
    payload: index,
  };
}

export function popLastBreadcrumbElement() {
  return {
    type: POP_LAST_BREADCRUMB_ELEMENT,
  };
}

export function resetBreadcrumbElements() {
  return {
    type: RESET_BREADCRUMB_ELEMENT,
  };
}

const initialState = {
  breadcrumbElements: [],
};

/**
 * Following the duck pattern, the module.js file should export a reducer as a default function
 */
export function reducer(state = initialState, action) {
  switch (action.type) {
    case PUSH_BREADCRUMB_ELEMENT:
      return {
        ...state,
        breadcrumbElements: [
          ...state.breadcrumbElements,
          {
            text: action.payload.text,
            route: action.payload.route,
          },
        ],
      };
    case POP_BREADCRUMB_ELEMENT:
      return {
        ...state,
        breadcrumbElements:
          action.payload < state.breadcrumbElements.length - 1
            ? state.breadcrumbElements.slice(0, action.payload + 1) // keep the element
            : state.breadcrumbElements,
      };
    case POP_LAST_BREADCRUMB_ELEMENT:
      return {
        ...state,
        breadcrumbElements: dropRight(state.breadcrumbElements, 1),
      };
    case RESET_BREADCRUMB_ELEMENT:
      return {
        ...state,
        breadcrumbElements: [],
      };
    default:
      return state;
  }
}

export default reducer;
