import React, { Component } from "react";

import PropTypes from "prop-types";

import { Select, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";

import moment from "moment";

import Modal from "components/Modal";

import {
  PAYMENT_MODES,
  PAYMENT_MODE_BANK_TRANSFER,
  PAYMENT_NATURES,
  PAYMENT_NATURE_PAYMENT,
  PAYMENT_NATURE_REFUND,
} from "constants/payment";

import { toCents } from "utils/price";
import { getEmployeeFullName, repairmenSortedList } from "utils/repairmenSortedList";

const addPaymentOrRefundWrapper = {
  addPaymentModal: {
    padding: "16px",
  },
  inputAmount: {
    display: "flex",
    position: "relative",
  },
  modalTitle: {
    textAlign: "center",
    padding: "0px 0px 20px 0px",
  },
  fieldModalPayment: {
    width: "100%",
    border: "1px solid #DBDFE8",
    borderRadius: 8,
    padding: 16,
    textAlign: "left",
  },
  inputLabel: {
    fontSize: 12,
    display: "grid",
    color: "#2B3B5C",
    textAlign: "left",
    paddingLeft: 16,
  },
  inputValue: {
    width: "100%",
    border: "1px solid #DBDFE8",
    borderRadius: 8,
    padding: "16px 57px 16px 16px",
    height: 56,
  },
  inputField: {
    padding: "0px 80px 8px 80px",
    margin: "auto",
  },
  euroSymbol: {
    position: "absolute",
    right: "16px",
    top: "16px",
  },
  buttonWrapper: {
    paddingTop: 20,
    textAlign: "center",
  },
  errorMessage: {
    color: "#ff0000",
    fontSize: 12,
    position: "absolute",
    bottom: "0px",
    left: "16px",
  },
  cancelButton: {
    color: "#8795B3",
    backgroundColor: "unset",
    boxShadow: "unset",
    marginLeft: 8,
  },
};

export class AddPaymentOrRefundModal extends Component {
  static propTypes = {
    isPaymentOrRefundModalOpen: PropTypes.bool,
    onClickClosePaymentRefundModal: PropTypes.func,
    onClickValidatePaymentOrRefund: PropTypes.func,
    employees: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.state = {
      paymentNature: PAYMENT_NATURE_PAYMENT,
      paymentMode: PAYMENT_MODE_BANK_TRANSFER,
      amount: 0,
      partnerReference: "",
      paymentPartner: "",
      selectedDate: moment().locale("fr"),
      selectedTech: "",
    };
    this.props.employees && this.props.employees.push({ id: "", first_name: "" });
  }

  onInputChange = (inputName) => (event) => {
    let value = event.target.value;
    if (inputName === "selectedDate") {
      value = moment(value).locale("fr").toISOString();
    }
    this.setState({ [inputName]: value });
  };

  onClickValidatePaymentOrRefund = () => {
    try {
      const amountPayedOrRefund = toCents(this.state.amount) * (this.isRefund() ? -1 : 1);

      const { paymentNature, paymentMode, selectedDate, selectedTech, paymentPartner, partnerReference } = this.state;

      this.props.onClickValidatePaymentOrRefund(
        paymentNature,
        amountPayedOrRefund,
        paymentMode,
        selectedDate,
        selectedTech,
        paymentPartner,
        partnerReference
      );
    } catch (error) {
      console.error("Erreur lors de la validation du paiement ou du remboursement:", error.message);
    }
  };

  isRefund = () => this.state.paymentNature === PAYMENT_NATURE_REFUND;
  isBankTransfer = () => this.state.paymentMode === PAYMENT_MODE_BANK_TRANSFER;
  isValid = () => this.isRefund() || this.isBankTransfer() || !!this.state.selectedTech;

  render() {
    const { classes } = this.props;

    return (
      <Modal
        isOpen={this.props.isPaymentOrRefundModalOpen}
        handleCloseModal={this.props.onClickClosePaymentRefundModal}
        modalClassName={`modal-small-content ${classes.addPaymentModal}`}
      >
        <Typography className={classes.modalTitle} variant="h2">
          Valider le paiement/remboursement
        </Typography>
        <div className={classes.inputField}>
          <span className={classes.inputLabel}>Type de paiement</span>
          <Select
            className={classes.fieldModalPayment}
            id="payment-type-select"
            classes={classes.selectPayment}
            onChange={this.onInputChange("paymentNature")}
            value={this.state.paymentNature}
            disableUnderline
          >
            {PAYMENT_NATURES.map((paymentNature) => (
              <MenuItem key={paymentNature.id} value={paymentNature.id}>
                {paymentNature.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={classes.inputField}>
          <span className={classes.inputLabel}>Mode de paiement</span>
          <Select
            className={classes.fieldModalPayment}
            id="payment-mode-select"
            classes={classes.selectPayment}
            onChange={this.onInputChange("paymentMode")}
            value={this.state.paymentMode}
            disableUnderline
          >
            {PAYMENT_MODES.map((paymentType) => (
              <MenuItem key={paymentType.id} value={paymentType.id}>
                {paymentType.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={classes.inputField}>
          <span className={classes.inputLabel}>Montant payé/remboursé</span>
          <div className={classes.inputAmount}>
            <Input
              className={classes.inputValue}
              name="Montant du paiement"
              value={this.state.amount}
              onChange={this.onInputChange("amount")}
              disableUnderline
            />
            <EuroSymbolIcon className={classes.euroSymbol} />
            {isNaN(this.state.amount) && (
              <span id="is-not-a-number-error" className={classes.errorMessage}>
                Valeur incorrecte
              </span>
            )}
          </div>
        </div>
        <div className={classes.inputField}>
          <span id="payment-date" className={classes.inputLabel}>
            Date du paiement
          </span>
          <TextField
            id="date"
            type="date"
            defaultValue={moment(this.state.selectedDate).format("YYYY-MM-DD")}
            onChange={this.onInputChange("selectedDate")}
            className={classes.fieldModalPayment}
          />
        </div>
        <div className={classes.inputField}>
          <span id="employee-selector" className={classes.inputLabel}>
            Technicien
          </span>
          <Select
            disableUnderline
            className={classes.fieldModalPayment}
            inputProps={{
              id: "select-employee",
            }}
            onChange={this.onInputChange("selectedTech")}
            value={this.state.selectedTech}
          >
            {this.props.employees &&
              repairmenSortedList(this.props.employees).map((employee) => (
                <MenuItem key={employee.id} value={employee.id}>
                  {getEmployeeFullName(employee)}
                </MenuItem>
              ))}
          </Select>
        </div>
        <div className={classes.inputField}>
          <span className={classes.inputLabel}>Prestataire de paiement</span>
          <Input
            className={classes.inputValue}
            name="Prestataire de paiement"
            value={this.state.paymentPartner}
            onChange={this.onInputChange("paymentPartner")}
            disableUnderline
          />
        </div>
        <div className={classes.inputField}>
          <span className={classes.inputLabel}>Référence Prestataire</span>
          <Input
            className={classes.inputValue}
            name="Référence Prestataire"
            value={this.state.partnerReference}
            onChange={this.onInputChange("partnerReference")}
            disableUnderline
          />
        </div>
        <div className={classes.buttonWrapper}>
          <Button
            id="validate-refund-or-payment"
            variant="contained"
            color="secondary"
            disabled={!this.isValid()}
            onClick={this.onClickValidatePaymentOrRefund}
          >
            Valider le paiement/remboursement
          </Button>
          <Button
            id="cancel-refund-or-payment"
            variant="contained"
            className={classes.cancelButton}
            onClick={this.props.onClickClosePaymentRefundModal}
          >
            Annuler
          </Button>
        </div>
      </Modal>
    );
  }
}

export default withStyles(addPaymentOrRefundWrapper)(AddPaymentOrRefundModal);
