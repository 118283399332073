//ts-nocheck
import React from "react";

import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";

import { Theme } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { styled } from "@material-ui/core/styles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

import { LARGE, MEDIUM, SMALL } from "constants/sizeVariables";

// variants
const ACTIVE = "active";
const DEFAULT = "default";

type Version = typeof ACTIVE | typeof DEFAULT;
type Size = typeof LARGE | typeof MEDIUM | typeof SMALL;

interface SubMenuProps {
  version: Version;
  size: Size;
}
interface SubMenuVersionProps {
  version: Version;
}

const listItemVariant = (theme: any, version: Version) => {
  switch (version) {
    case ACTIVE:
      return {
        color: theme.palette.secondary.main,
        minWidth: 0,
        maxWidth: theme.spacing(3),
        marginRight: theme.spacing(2),
      };
    case DEFAULT:
    default:
      return {
        color: theme.palette.primary.white,
        minWidth: theme.spacing(5),
      };
  }
};

const listItemTextVariant = (theme: any, version: Version) => {
  switch (version) {
    case ACTIVE:
      return {
        color: theme.palette.secondary.main,
      };
    case DEFAULT:
    default:
      return {
        color: theme.palette.primary.white,
      };
  }
};

const listItemTextSize = (size: Size) => {
  switch (size) {
    case SMALL:
      return { fontSize: 12 };
    case MEDIUM:
      return { fontSize: 14 };
    case LARGE:
    default:
      return { fontSize: 16 };
  }
};

const StyledListItem = styled(ListItem)<Theme, SubMenuProps>(({ theme, size }) => ({
  padding: size === LARGE ? theme.spacing(2) : 0,
  display: "flex",
}));

const StyledListItemText = styled(ListItemText)<Theme, SubMenuProps>(({ theme, version, size }) => {
  return { ...listItemTextVariant(theme, version), ...listItemTextSize(size) };
});

const StyledListItemIcon = styled(ListItemIcon)<Theme, SubMenuVersionProps>(({ theme, version }) => {
  return { margin: "auto", ...listItemVariant(theme, version) };
});

const StyledList = styled(List)(({ theme }) => ({
  padding: theme.spacing(0, 2, 0, 4),
}));

type MurfySubMenuProps = {
  link: string;
  isOpen: boolean;
  icon: React.ReactElement;
  children: React.ReactNode;
  name: string;
  size: Size;
};

interface ListItemLinkProps {
  icon?: React.ReactElement;
  primary: string;
  to: string;
  isOpen: boolean;
  size: Size;
}

function ListItemLink(props: ListItemLinkProps) {
  const { icon = <FiberManualRecordIcon />, primary, to, isOpen, size = LARGE } = props;

  // Omit "to" to make use of the Link as a memo for reusability purposes
  const renderLink = React.useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, "to">>(function Link(itemProps, ref) {
        return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />;
      }),
    [to]
  );

  return (
    <>
      {
        // ListItem is wrongly typed in MUI V4: component IS a props but is missing here.
        // works, but throws an error
        // TODO: delete the exception below when upgrading to V5
        // @ts-ignore:next-line
        <StyledListItem version={isOpen ? ACTIVE : DEFAULT} button component={renderLink} to={to} size={size}>
          {icon && <StyledListItemIcon version={isOpen ? ACTIVE : DEFAULT}>{icon}</StyledListItemIcon>}
          <StyledListItemText size={size} version={isOpen ? ACTIVE : DEFAULT} disableTypography primary={primary} />
        </StyledListItem>
      }
    </>
  );
}

const MurfySubMenu: React.FunctionComponent<MurfySubMenuProps> = ({
  link = "",
  isOpen,
  icon,
  children,
  name,
  size = "large",
}: MurfySubMenuProps) => {
  return (
    <>
      <ListItemLink to={link} primary={name} icon={icon} isOpen={isOpen} size={size} />
      {children && (
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <StyledList dense>{children}</StyledList>
        </Collapse>
      )}
    </>
  );
};

export default MurfySubMenu;
