import { connect } from "react-redux";

import PageContent from "./PageContent.component";

function mapStateToProps() {
  return {};
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(PageContent);
