export const ADMIN_ROUTE = "/admin";

export const ADMIN_ACCOUNT_ROUTE = `${ADMIN_ROUTE}/account`;

export const ADMIN_CUSTOMER_FILES_ROUTE = `${ADMIN_ROUTE}/customer-files`;

export const ADMIN_CMS_ROUTE = `${ADMIN_ROUTE}/cms`;
export const ADMIN_CMS_CATALOG_ROUTE = `${ADMIN_CMS_ROUTE}/catalog`;
export const ADMIN_CMS_CATALOG_EDITION_ROUTE = `${ADMIN_CMS_CATALOG_ROUTE}/catalog`;
export const ADMIN_CMS_GENERAL_INFORMATION_ROUTE = `${ADMIN_CMS_ROUTE}/general-information`;
export const ADMIN_CMS_IMAGES_MANAGEMENT = `${ADMIN_CMS_ROUTE}/images-management`;
export const ADMIN_CMS_KNOWLEDGE_BASE_ROUTE = `${ADMIN_CMS_ROUTE}/knowledge-base`;
export const ADMIN_CMS_KNOWLEDGE_BASE_ARTICLE_EDITION_ROUTE = `${ADMIN_CMS_KNOWLEDGE_BASE_ROUTE}/article`;
export const ADMIN_CMS_KNOWLEDGE_BASE_FORM_EDITION_ROUTE = `${ADMIN_CMS_KNOWLEDGE_BASE_ROUTE}/form`;
export const ADMIN_CMS_KNOWLEDGE_BASE_TUTORIAL_EDITION_ROUTE = `${ADMIN_CMS_KNOWLEDGE_BASE_ROUTE}/tutorial`;
export const ADMIN_CMS_PRODUCT_ROUTE = `${ADMIN_CMS_ROUTE}/product`;
export const ADMIN_CMS_SMART_DIAGNOSTIC_ROUTE = `${ADMIN_CMS_ROUTE}/smart-diagnostic`;
export const ADMIN_CMS_STEP_ROUTE = `${ADMIN_CMS_ROUTE}/diagnostic`;
export const ADMIN_CMS_STEP_EDITION_ROUTE = `${ADMIN_CMS_STEP_ROUTE}/step`;

export const ADMIN_INTERVENTION_ROUTE = `${ADMIN_ROUTE}/interventions`;

export const ADMIN_SKU_STORE_ROUTE = `${ADMIN_ROUTE}/sku-store`;

export const ARTICLE_ROUTE = "/article";

export const SEARCH_RESULTS_ROUTE = "/search-results";

// customer-files sub item links
export const CUSTOMER_FILES = "/customer-files";
export const ADMIN_AFFECTATION_ROUTE_CUSTOMER_FILES = `${CUSTOMER_FILES}/affectation`;

// workshop-files route
export const WORKSHOP_FILES_ROUTE = "/workshop-files";
export const SKU_DEMANDS = `${WORKSHOP_FILES_ROUTE}/sku-demands`;
export const MARKETPLACE_EXPORT_ROUTE = `${WORKSHOP_FILES_ROUTE}/marketplace-export`;

// shipping route
export const ADMIN_SHIPPING_ROUTE = "/shipping";

export const ADMIN_PRODUCTS_ROUTE = "/products";
export const ADMIN_WORKSHOPS_ROUTE = "/workshops";
export const ADMIN_PRODUCT_MODELS_ROUTE = "/product-models";
export const CUSTOMER_FILES_TO_PROCESS = "customer-files-to-process";
