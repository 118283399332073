import React from "react";

import {
  AutocompleteInput,
  Edit,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
  useRefresh,
} from "react-admin";

import { Box, Button, Typography, makeStyles } from "@material-ui/core";

import dataProvider from "dataProvider";

import Modal from "components/Modal";

import { SKU_LOCATION_TYPE_REPAIRMAN, SKU_LOCATION_TYPE_WORKSHOP } from "constants/skuLocation";
import { SPARE_PART_STATUS_CHOICES, SparePartStatus } from "constants/sparePartStatus";

import { useSkuLocations } from "utils/api";

import EditSparePartToolbar from "./components/EditSparePartToolbar";

const useStyles = makeStyles((theme) => ({
  Modal: {
    width: 570,
    minHeight: 700,
    textAlign: "center",
    overflow: "auto",
  },
  actionButton: {
    margin: 8,
  },
}));

export default ({ record, id, isModalOpen, onCloseModal, toggleUpdate }) => {
  const classes = useStyles();
  const locationList = useSkuLocations();

  let locationId = null;
  let status = null;

  const notify = useNotify();
  const refresh = useRefresh();

  const handleSaveAndClose = () => {
    const data = {};
    if (locationId !== null) {
      data.location_id = locationId;
    }
    if (status !== null) {
      data.status = status;
    }
    dataProvider
      .update("spare-parts", { id, data })
      .then(({ status }) => {
        if (status === 200) {
          handleCloseModal();
          notify("Pièce mise à jour", "success");
          refresh();
        } else {
          notify(`Erreur: impossible de mettre la pièce à jour`, "error");
        }
      })
      .catch((error) => {
        notify(`Erreur: impossible de mettre la pièce à jour (${error.message})`, "error");
      });
  };

  const handleCloseModal = () => {
    onCloseModal();
    toggleUpdate(true);
    toggleUpdate(false);
  };

  const onReturnToWarehouseClick = () => {
    dataProvider.create(`murfy-erp/spare-parts/${record.id}/return-to-warehouse`, { data: {} }).then(({ status }) => {
      if (status === 200) {
        notify("Pièce mise à jour", "success");
        refresh();
      } else {
        notify(`Erreur: impossible de mettre la pièce à jour`, "error");
      }
    });
  };

  const onReturnToVendorClick = () => {
    dataProvider.create(`murfy-erp/spare-parts/${record.id}/return-to-vendor`, { data: {} }).then(({ status }) => {
      if (status === 200) {
        notify("Pièce mise à jour", "success");
        refresh();
      } else {
        notify(`Erreur: impossible de mettre la pièce à jour`, "error");
      }
    });
  };

  const isReturnToVendorDisabled = () =>
    !record ||
    ![SparePartStatus.INSTOCK, SparePartStatus.RETURN_TO_WAREHOUSE, SparePartStatus.LOST].includes(record.status) ||
    !record.location ||
    record.location.code === "RETOUR_FOURNISSEUR";

  const isReturnToWarehouseDisabled = () =>
    !record ||
    !record.location ||
    ![SKU_LOCATION_TYPE_REPAIRMAN, SKU_LOCATION_TYPE_WORKSHOP].includes(record.location.location_type) ||
    record.status === SparePartStatus.RETURN_TO_WAREHOUSE;

  return (
    <Modal
      id="edit-sku-modal"
      modalClassName={`modal-small-content ${classes.Modal}`}
      isOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
    >
      <Box>
        <Typography className={classes.modalTitle} variant="h2">
          Modifier la pièce
        </Typography>
      </Box>
      <Edit resource="spare-parts" id={id} basePath="/sku-store">
        <SimpleForm
          redirect={false}
          toolbar={
            <EditSparePartToolbar
              onReturnToWarehouseClick={onReturnToWarehouseClick}
              onReturnToVendorClick={onReturnToVendorClick}
              handleSaveAndClose={handleSaveAndClose}
            />
          }
        >
          <NumberInput label="Identifiant Murfy" variant="outlined" source="id" disabled />
          <TextInput label="Designation" variant="outlined" source="designation" disabled />
          <TextInput label="Fournisseur" variant="outlined" source="supplier_name" disabled />
          <TextInput label="Référence" variant="outlined" source="reference" disabled />
          <AutocompleteInput
            label="Emplacement"
            variant="outlined"
            source="location.id"
            optionValue="id"
            optionText="name"
            choices={locationList}
            onChange={(event) => (locationId = event)}
            className={classes.locationInput}
          />
          <SelectInput
            label="Statut"
            variant="outlined"
            source="status"
            choices={SPARE_PART_STATUS_CHOICES}
            onChange={(event) => (status = event.target.value)}
          />
          <Button
            className={classes.actionButton}
            variant="outlined"
            color="primary"
            onClick={onReturnToWarehouseClick}
            disabled={isReturnToWarehouseDisabled()}
          >
            Retourner à l'atelier
          </Button>
          <Button
            className={classes.actionButton}
            variant="outlined"
            color="primary"
            onClick={onReturnToVendorClick}
            disabled={isReturnToVendorDisabled()}
          >
            Retourner au fournisseur
          </Button>
        </SimpleForm>
      </Edit>
    </Modal>
  );
};
