import React from "react";

import { Field, FieldProps } from "formik";

import Select from "components/atoms/Select";
import { Body } from "components/atoms/Typography";

import { ADMINISTRATIVE, VISIT_TYPES } from "pages/customerFiles/components/CustomerFileVisitsNew/constants";

const VisitTypeField: React.FunctionComponent = () => (
  <Field name="visit_type">
    {({ field, form }: FieldProps) => (
      <>
        <Select
          {...field}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
            form.setFieldValue("visit_type", event.target.value)
          }
          options={VISIT_TYPES.filter((visitType) => visitType.value !== ADMINISTRATIVE)}
          label="Type de passage"
          defaultValue={field.value}
        />
        {form.touched.visit_type && form.errors.visit_type && <Body color="red">{form.errors.visit_type}</Body>}
      </>
    )}
  </Field>
);

export default VisitTypeField;
