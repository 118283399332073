import React from "react";

import { Box, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import ColorChip from "components/atoms/ColorChip";

import { TASK_STATE_TODO } from "constants/task";

import { useRepairReportChecks } from "utils/api";
import { getReasonDisplay } from "utils/repairReportCheck";

export default ({ customerFileId }) => {
  const { data: repairReportChecks } = useRepairReportChecks(customerFileId, TASK_STATE_TODO);

  return repairReportChecks
    ? repairReportChecks.map((repairReportCheck, index) => (
        <ExpansionPanel defaultExpanded={index === repairReportChecks.length - 1}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Box display="flex">
              <Box mr={4}>
                <Typography variant="subtitle2">Contrôle de CR</Typography>
              </Box>
              <Typography variant="body1">Demandeur : {repairReportCheck.created_by_name}</Typography>
            </Box>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Box>
              <Typography variant="caption">
                <b>
                  {repairReportCheck.product_type_name} {repairReportCheck.product_brand_name}
                </b>
              </Typography>
              <br />
              {repairReportCheck.reasons.map((reason) => (
                <Box mr={1}>
                  <ColorChip label={getReasonDisplay(reason)} color="grey" />
                </Box>
              ))}
              <br />
              <Typography variant="caption">{repairReportCheck.comment}</Typography>
            </Box>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))
    : null;
};
