import React, { Component } from "react";

import { PropTypes } from "prop-types";

import sortBy from "lodash/sortBy";

import "./style.css";

export default class SubMenu extends Component {
  static propTypes = {
    elements: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        order: PropTypes.number,
      })
    ),
    selectedElementId: PropTypes.number,
    handleClick: PropTypes.func.isRequired,
  };

  handleClick = (elementId) => () => {
    this.props.handleClick(elementId);
  };

  render() {
    return (
      this.props.elements && (
        <div className="row no-gutters header-submenu-container">
          {sortBy(this.props.elements, "order").map((element) => (
            <button
              key={element.id}
              className={`submenu-button ${this.props.selectedElementId === element.id && "submenu-button-active"}`}
              onClick={this.handleClick(element.id)}
            >
              <span>{element.name}</span>
            </button>
          ))}
        </div>
      )
    );
  }
}
