/**
 * Create the store with asynchronously loaded reducers
 */
import { routerMiddleware } from "connected-react-router";
import { applyMiddleware, compose, createStore } from "redux";
import { persistStore } from "redux-persist";
import storage from "redux-persist/es/storage";
import createSagaMiddleware from "redux-saga";
import { analyticsMiddleware } from "shared/middleware/analytics/analytics-handler";

import createReducer from "./reducers";
import rootSaga from "./sagas";

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(initialState = {}, history, dataProvider, authProvider) {
  // Create the store with three middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  // 3. analyticsMiddleware: Tracks some redux actions
  const middlewares = [sagaMiddleware, routerMiddleware(history), analyticsMiddleware()];

  const enhancers = [applyMiddleware(...middlewares)];

  const persistConfig = {
    key: "cobbleStore",
    storage,
    whitelist: ["user", "companyEmployee"],
  };

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    process.env.NODE_ENV !== "production" && typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;
  /* eslint-enable */

  const store = createStore(
    createReducer(persistConfig, undefined, history),
    initialState,
    composeEnhancers(...enhancers)
  );

  const persistor = persistStore(store);
  store.persistor = persistor;

  sagaMiddleware.run(rootSaga, dataProvider, authProvider);

  // Extensions
  store.runSaga = sagaMiddleware.run; // used to load dynamic saga https://github.com/redux-saga/redux-saga/releases/tag/v0.7.0
  store.asyncReducers = {}; // async reducers registry
  store.asyncSagas = {}; // async sagas registry

  store.injectReducer = (asyncReducer, key) => {
    if (key) {
      store.asyncReducers[key] = asyncReducer;
    } else {
      store.asyncReducers = { ...asyncReducer, ...store.asyncReducers };
    }
    store.replaceReducer(createReducer(persistConfig, store.asyncReducers, history));
    store.persistor.persist();
  };

  store.injectSagas = (asyncSaga, key) => {
    if (!(key in store.asyncSagas)) {
      // do not reinject saga if already there
      store.asyncSagas[key] = asyncSaga;
      store.runSaga(asyncSaga);
    }
  };

  return { store, persistor };
}
