/**
 * Following the duck pattern, actions, constants and reducers are in the same file called module.js
 *
 * See: https://github.com/erikras/ducks-modular-redux
 *
 */
import { push } from "connected-react-router";
import { sendAuthenticatedRequestToAPI } from "modules/generators/requestsToAPI";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  ADMIN_CMS_CATALOG_EDITION_ROUTE,
  ADMIN_CMS_KNOWLEDGE_BASE_FORM_EDITION_ROUTE,
  ADMIN_CMS_KNOWLEDGE_BASE_TUTORIAL_EDITION_ROUTE,
  ADMIN_CMS_SMART_DIAGNOSTIC_ROUTE,
  ADMIN_CMS_STEP_ROUTE,
} from "constants/routes";

import convertIntoFormData from "utils/formData";
import { getAPIUrl } from "utils/networking";

export const CLOSE_APPLICATION_FAMILY_MODAL = "PRODUCTS_LIST/CLOSE_APPLICATION_FAMILY_MODAL";
export const CLOSE_WIDGET_MODAL = "PRODUCTS_LIST/CLOSE_WIDGET_MODAL";
export const OPEN_APPLICATION_FAMILY_MODAL = "PRODUCTS_LIST/OPEN_APPLICATION_FAMILY_MODAL";
export const OPEN_WIDGET_MODAL = "PRODUCTS_LIST/OPEN_WIDGET_MODAL";

export const INITIALIZE_NEW_APPLICATION = "PRODUCTS_LIST/INITIALIZE_NEW_APPLICATION";
export const INITIALIZE_NEW_APPLICATION_FAMILY = "PRODUCTS_LIST/INITIALIZE_NEW_APPLICATION_FAMILY";
export const INITIALIZE_NEW_PRODUCT = "PRODUCTS_LIST/INITIALIZE_NEW_PRODUCT";

export const CREATE_NEW_WIDGET_REQUEST = "PRODUCTS_LIST/CREATE_NEW_WIDGET_REQUEST";
export const CREATE_NEW_WIDGET_SUCCESS = "PRODUCTS_LIST/CREATE_NEW_WIDGET_SUCCESS";
export const CREATE_NEW_WIDGET_ERROR = "PRODUCTS_LIST/CREATE_NEW_WIDGET_ERROR";

export const DELETE_APPLICATION_REQUEST = "PRODUCTS_LIST/DELETE_APPLICATION_REQUEST";
export const DELETE_APPLICATION_SUCCESS = "PRODUCTS_LIST/DELETE_APPLICATION_SUCCESS";
export const DELETE_APPLICATION_ERROR = "PRODUCTS_LIST/DELETE_APPLICATION_ERROR";

export const DELETE_APPLICATION_FAMILY_REQUEST = "PRODUCTS_LIST/DELETE_APPLICATION_FAMILY_REQUEST";
export const DELETE_APPLICATION_FAMILY_SUCCESS = "PRODUCTS_LIST/DELETE_APPLICATION_FAMILY_SUCCESS";
export const DELETE_APPLICATION_FAMILY_ERROR = "PRODUCTS_LIST/DELETE_APPLICATION_FAMILY_ERROR";

export const DELETE_PRODUCT_REQUEST = "PRODUCTS_LIST/DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_SUCCESS = "PRODUCTS_LIST/DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_ERROR = "PRODUCTS_LIST/DELETE_PRODUCT_ERROR";

export const SAVE_APPLICATION_REQUEST = "PRODUCTS_LIST/SAVE_APPLICATION_REQUEST";
export const SAVE_APPLICATION_SUCCESS = "PRODUCTS_LIST/SAVE_APPLICATION_SUCCESS";
export const SAVE_APPLICATION_ERROR = "PRODUCTS_LIST/SAVE_APPLICATION_ERROR";

export const SAVE_APPLICATION_FAMILY_REQUEST = "PRODUCTS_LIST/SAVE_APPLICATION_FAMILY_REQUEST";
export const SAVE_APPLICATION_FAMILY_SUCCESS = "PRODUCTS_LIST/SAVE_APPLICATION_FAMILY_SUCCESS";
export const SAVE_APPLICATION_FAMILY_ERROR = "PRODUCTS_LIST/SAVE_APPLICATION_FAMILY_ERROR";

export const SAVE_PRODUCT_REQUEST = "PRODUCTS_LIST/SAVE_PRODUCT_REQUEST";
export const SAVE_PRODUCT_SUCCESS = "PRODUCTS_LIST/SAVE_PRODUCT_SUCCESS";
export const SAVE_PRODUCT_ERROR = "PRODUCTS_LIST/SAVE_PRODUCT_ERROR";

export const SET_APPLICATION_TYPE = "PRODUCTS_LIST/SET_APPLICATION_TYPE";
export const SET_APPLICATION_FIELD = "PRODUCTS_LIST/SET_APPLICATION_FIELD";
export const SET_APPLICATION_FAMILY_FIELD = "PRODUCTS_LIST/SET_APPLICATION_FAMILY_FIELD";
export const SET_APPLICATION_PRODUCTS = "PRODUCTS_LIST/SET_APPLICATION_PRODUCTS";

export const SET_PRODUCT_FIELD = "PRODUCTS_LIST/SET_PRODUCT_FIELD";
export const SET_PRODUCT_MEDIA = "PRODUCTS_LIST/SET_PRODUCT_MEDIA";

export const SET_APPLICATION_TO_EDIT = "PRODUCTS_LIST/SET_APPLICATION_TO_EDIT";
export const RESET_APPLICATION_TO_EDIT = "PRODUCTS_LIST/RESET_APPLICATION_TO_EDIT";

export const SET_PRODUCT_TO_EDIT = "PRODUCTS_LIST/SET_PRODUCT_TO_EDIT";
export const RESET_PRODUCT_TO_EDIT = "PRODUCTS_LIST/RESET_PRODUCT_TO_EDIT";

export const REDIRECT_TO_APPLICATION_WIDGET = "PRODUCTS_LIST/REDIRECT_TO_APPLICATION_WIDGET";

export function closeApplicationFamilyModal() {
  return {
    type: CLOSE_APPLICATION_FAMILY_MODAL,
  };
}

export function openApplicationFamilyModal(applicationFamily) {
  return {
    type: OPEN_APPLICATION_FAMILY_MODAL,
    payload: { applicationFamily },
  };
}

export function closeWidgetModal() {
  return {
    type: CLOSE_WIDGET_MODAL,
  };
}

export function openWidgetModal() {
  return {
    type: OPEN_WIDGET_MODAL,
  };
}

export function initializeNewApplication(productId) {
  return {
    type: INITIALIZE_NEW_APPLICATION,
    payload: { productId },
  };
}

export function initializeNewProduct() {
  return {
    type: INITIALIZE_NEW_PRODUCT,
  };
}

export function initializeNewApplicationFamily() {
  return {
    type: INITIALIZE_NEW_APPLICATION_FAMILY,
  };
}

export function createWidgetRequest(widgetType) {
  return {
    type: CREATE_NEW_WIDGET_REQUEST,
    payload: { widgetType },
  };
}

export function createWidgetSuccess(widget) {
  return {
    type: CREATE_NEW_WIDGET_SUCCESS,
    payload: { widget },
  };
}

export function createWidgetError(error) {
  return {
    type: CREATE_NEW_WIDGET_ERROR,
    payload: { error },
  };
}

export function saveApplicationRequest() {
  return {
    type: SAVE_APPLICATION_REQUEST,
  };
}

export function saveApplicationSuccess(application) {
  return {
    type: SAVE_APPLICATION_SUCCESS,
    payload: { application },
  };
}

export function saveApplicationError(error) {
  return {
    type: SAVE_APPLICATION_ERROR,
    payload: { error },
  };
}

export function saveApplicationFamilyRequest() {
  return {
    type: SAVE_APPLICATION_FAMILY_REQUEST,
  };
}

export function saveApplicationFamilySuccess(applicationFamily) {
  return {
    type: SAVE_APPLICATION_FAMILY_SUCCESS,
    payload: { applicationFamily },
  };
}

export function saveApplicationFamilyError(error) {
  return {
    type: SAVE_APPLICATION_FAMILY_ERROR,
    payload: { error },
  };
}

export function deleteApplicationRequest(applicationId) {
  return {
    type: DELETE_APPLICATION_REQUEST,
    payload: { applicationId },
  };
}

export function deleteApplicationSuccess(apiResponse, deletedApplicationId) {
  return {
    type: DELETE_APPLICATION_SUCCESS,
    payload: { deletedApplicationId },
  };
}

export function deleteApplicationError(error) {
  return {
    type: DELETE_APPLICATION_ERROR,
    payload: { error },
  };
}

export function deleteApplicationFamilyRequest(applicationFamilyId) {
  return {
    type: DELETE_APPLICATION_FAMILY_REQUEST,
    payload: { applicationFamilyId },
  };
}

export function deleteApplicationFamilySuccess(apiResponse, deletedApplicationFamilyId) {
  return {
    type: DELETE_APPLICATION_FAMILY_SUCCESS,
    payload: { deletedApplicationFamilyId },
  };
}

export function deleteApplicationFamilyError(error) {
  return {
    type: DELETE_APPLICATION_FAMILY_ERROR,
    payload: { error },
  };
}

export function deleteProductRequest(productId) {
  return {
    type: DELETE_PRODUCT_REQUEST,
    payload: { productId },
  };
}

export function deleteProductSuccess(apiResponse, deletedProductId) {
  return {
    type: DELETE_PRODUCT_SUCCESS,
    payload: { deletedProductId },
  };
}

export function deleteProductError(error) {
  return {
    type: DELETE_PRODUCT_ERROR,
    payload: { error },
  };
}

export function saveProductRequest() {
  return {
    type: SAVE_PRODUCT_REQUEST,
  };
}

export function saveProductSuccess(product) {
  return {
    type: SAVE_PRODUCT_SUCCESS,
    payload: { product },
  };
}

export function saveProductError(error) {
  return {
    type: SAVE_PRODUCT_ERROR,
    payload: { error },
  };
}

export function setApplicationType(type) {
  return {
    type: SET_APPLICATION_TYPE,
    payload: { type },
  };
}

export function setApplicationField(fieldName, fieldValue) {
  return {
    type: SET_APPLICATION_FIELD,
    payload: { fieldName, fieldValue },
  };
}

export function setApplicationFamilyField(fieldName, fieldValue) {
  return {
    type: SET_APPLICATION_FAMILY_FIELD,
    payload: { fieldName, fieldValue },
  };
}

export function setApplicationProducts(products) {
  return {
    type: SET_APPLICATION_PRODUCTS,
    payload: { products },
  };
}

export function setProductField(fieldName, fieldValue) {
  return {
    type: SET_PRODUCT_FIELD,
    payload: { fieldName, fieldValue },
  };
}

export function setProductMedia(file, previewUrl) {
  return {
    type: SET_PRODUCT_MEDIA,
    payload: { file, previewUrl },
  };
}

export function setApplicationToEdit(application) {
  return {
    type: SET_APPLICATION_TO_EDIT,
    payload: { application },
  };
}

export function resetApplicationToEdit() {
  return {
    type: RESET_APPLICATION_TO_EDIT,
  };
}

export function setProductToEdit(product) {
  return {
    type: SET_PRODUCT_TO_EDIT,
    payload: { product },
  };
}

export function resetProductToEdit() {
  return {
    type: RESET_PRODUCT_TO_EDIT,
  };
}

export function redirectToApplicationWidget(appType, widgetId) {
  return {
    type: REDIRECT_TO_APPLICATION_WIDGET,
    payload: { appType, widgetId },
  };
}

export const getApplicationSelector = (state) => state.product.application;
export const getApplicationFamilySelector = (state) => state.product.applicationFamily;
export const getEditedProductSelector = (state) => state.product.editedProduct;

export const getApplicationWidgetRedirectionRoute = (state, appType, widgetId) => {
  switch (appType) {
    case "catalog":
      return `${ADMIN_CMS_CATALOG_EDITION_ROUTE}/${widgetId}`;
    case "diagnostic":
      return `${ADMIN_CMS_STEP_ROUTE}/${widgetId}`;
    case "form":
      return `${ADMIN_CMS_KNOWLEDGE_BASE_FORM_EDITION_ROUTE}/${widgetId}`;
    case "tutoriel":
      return `${ADMIN_CMS_KNOWLEDGE_BASE_TUTORIAL_EDITION_ROUTE}/${widgetId}`;
    case "smart_diagnostic":
      return `${ADMIN_CMS_SMART_DIAGNOSTIC_ROUTE}/${widgetId}`;
    default:
      return null;
  }
};

export const getApplicationTypesSelector = (state) => {
  const applicationsMapping = [
    { value: "catalog", label: "Catalogue" },
    { value: "diagnostic", label: "Diagnostic" },
    { value: "form", label: "Formulaire" },
    { value: "tutoriel", label: "Tutoriel" },
  ];
  state.companyEmployee.company.is_smart_diagnostic_enabled &&
    applicationsMapping.push({ value: "smart_diagnostic", label: "Diagnostic intelligent" });
  return applicationsMapping;
};

export function* redirectToApplicationWidgetGenerator(action) {
  const { appType, widgetId } = action.payload;
  const redirectionRoute = yield select(getApplicationWidgetRedirectionRoute, appType, widgetId);
  if (redirectionRoute) yield put(push(redirectionRoute));
}

export function* createTemplateWidget(action) {
  const { widgetType } = action.payload;
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    url: `${getAPIUrl()}/widget/${widgetType}/initialize-new-${widgetType}/`,
  };
  const toastrConfig = {
    success: { title: "Succès", message: "Nouvel élément créé" },
    error: { title: "Erreur", message: "Échec à la creation de l'élément" },
  };
  yield call(sendAuthenticatedRequestToAPI, config, createWidgetSuccess, createWidgetError, toastrConfig);
}

export function* deleteApplication(action) {
  const config = {
    method: "delete",
    url: `${process.env.REACT_APP_API_URL}/common/application/${action.payload.applicationId}/`,
  };
  const toastrConfig = {
    success: { title: "Succès", message: "Application supprimée" },
    error: { title: "Erreur", message: "L'application n'a pas été supprimée" },
  };
  yield call(sendAuthenticatedRequestToAPI, config, deleteApplicationSuccess, deleteApplicationError, toastrConfig, [
    action.payload.applicationId,
  ]);
}

export function* deleteApplicationFamily(action) {
  const config = {
    method: "delete",
    url: `${process.env.REACT_APP_API_URL}/common/application-family/${action.payload.applicationFamilyId}/`,
  };
  const toastrConfig = {
    success: { title: "Succès", message: "Famille d'application supprimée" },
    error: { title: "Erreur", message: "La famille d'application n'a pas été supprimée" },
  };
  yield call(
    sendAuthenticatedRequestToAPI,
    config,
    deleteApplicationFamilySuccess,
    deleteApplicationFamilyError,
    toastrConfig,
    [action.payload.applicationFamilyId]
  );
}

export function* deleteProduct(action) {
  const config = {
    method: "delete",
    url: `${process.env.REACT_APP_API_URL}/common/product/${action.payload.productId}/`,
  };
  const toastrConfig = {
    success: { title: "Succès", message: "Produit supprimé" },
    error: { title: "Erreur", message: "Le produit n'a pas été supprimé" },
  };
  yield call(sendAuthenticatedRequestToAPI, config, deleteProductSuccess, deleteProductError, toastrConfig, [
    action.payload.productId,
  ]);
}

export function* saveApplication(action) {
  const application = yield select(getApplicationSelector);
  const config = {
    method: application.id ? "patch" : "post",
    url: `${process.env.REACT_APP_API_URL}/common/application/${application.id ? `${application.id}/` : ""}`,
    data: application,
  };
  const toastrConfig = {
    success: { title: "Succès", message: "Modifications enregistrées" },
    error: { title: "Erreur", message: "Vos modififications n'ont pas été enregistrées" },
  };
  yield call(sendAuthenticatedRequestToAPI, config, saveApplicationSuccess, saveApplicationError, toastrConfig);
}

export function* saveApplicationFamily(action) {
  const applicationFamily = yield select(getApplicationFamilySelector);
  const config = {
    method: applicationFamily.id ? "patch" : "post",
    url: `${process.env.REACT_APP_API_URL}/common/application-family/${
      applicationFamily.id ? `${applicationFamily.id}/` : ""
    }`,
    data: applicationFamily,
  };
  const toastrConfig = {
    success: { title: "Succès", message: "Modifications enregistrées" },
    error: { title: "Erreur", message: "Vos modififications n'ont pas été enregistrées" },
  };
  yield call(
    sendAuthenticatedRequestToAPI,
    config,
    saveApplicationFamilySuccess,
    saveApplicationFamilyError,
    toastrConfig
  );
}

export function* saveProduct(action) {
  const product = yield select(getEditedProductSelector);
  const formData = convertIntoFormData(product);
  const config = {
    method: product.id ? "patch" : "post",
    url: `${process.env.REACT_APP_API_URL}/common/product/${product.id ? `${product.id}/` : ""}`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  };
  const toastrConfig = {
    success: { title: "Succès", message: "Modifications enregistrées" },
    error: { title: "Erreur", message: "Vos modififications n'ont pas été enregistrées" },
  };
  yield call(sendAuthenticatedRequestToAPI, config, saveProductSuccess, saveProductError, toastrConfig);
}

export function* deleteApplicationSaga() {
  yield takeEvery(DELETE_APPLICATION_REQUEST, deleteApplication);
}

export function* deleteApplicationFamilySaga() {
  yield takeEvery(DELETE_APPLICATION_FAMILY_REQUEST, deleteApplicationFamily);
}

export function* deleteProductSaga() {
  yield takeEvery(DELETE_PRODUCT_REQUEST, deleteProduct);
}

export function* redirectToApplicationWidgetSaga() {
  yield takeLatest(REDIRECT_TO_APPLICATION_WIDGET, redirectToApplicationWidgetGenerator);
}

export function* saveApplicationSaga() {
  yield takeLatest(SAVE_APPLICATION_REQUEST, saveApplication);
}

export function* saveApplicationFamilySaga() {
  yield takeLatest(SAVE_APPLICATION_FAMILY_REQUEST, saveApplicationFamily);
}

export function* saveProductSaga() {
  yield takeLatest(SAVE_PRODUCT_REQUEST, saveProduct);
}

export function* createTemplateWidgetSaga() {
  yield takeLatest(CREATE_NEW_WIDGET_REQUEST, createTemplateWidget);
}

const initialState = {
  application: null,
  applicationFamily: null,
  error: null,
  isAppFamilyModalOpen: false,
  isWidgetModalOpen: false,
  product: null,
  editedProduct: null,
};

/**
 * Following the duck pattern, the module.js file should export a reducer as a default function
 */
export function reducer(state = initialState, action) {
  switch (action.type) {
    case CLOSE_APPLICATION_FAMILY_MODAL:
      return {
        ...state,
        applicationFamily: null,
        isAppFamilyModalOpen: false,
      };
    case OPEN_APPLICATION_FAMILY_MODAL:
      return {
        ...state,
        isAppFamilyModalOpen: true,
        applicationFamily: action.payload.applicationFamily,
      };
    case CLOSE_WIDGET_MODAL:
      return {
        ...state,
        isWidgetModalOpen: false,
      };
    case OPEN_WIDGET_MODAL:
      return {
        ...state,
        isWidgetModalOpen: true,
      };
    case INITIALIZE_NEW_APPLICATION:
      return {
        ...state,
        application: {
          products: action.payload.productId ? [action.payload.productId] : [],
        },
      };
    case INITIALIZE_NEW_APPLICATION_FAMILY:
      return {
        ...state,
        applicationFamily: {},
        isAppFamilyModalOpen: true,
      };
    case INITIALIZE_NEW_PRODUCT:
      return {
        ...state,
        product: {},
        editedProduct: {},
      };
    case CREATE_NEW_WIDGET_SUCCESS:
      return {
        ...state,
        application: {
          ...state.application,
          widget: action.payload.widget.id,
        },
        isWidgetModalOpen: false,
      };
    case CREATE_NEW_WIDGET_ERROR:
      return {
        ...state,
        error: action.payload.error,
      };
    case DELETE_APPLICATION_REQUEST:
      return {
        ...state,
        error: null,
      };
    case DELETE_APPLICATION_SUCCESS:
      return {
        ...state,
        application: null,
      };
    case DELETE_APPLICATION_ERROR:
      return {
        ...state,
        error: action.payload.error,
      };
    case DELETE_APPLICATION_FAMILY_REQUEST:
      return {
        ...state,
        error: null,
      };
    case DELETE_APPLICATION_FAMILY_SUCCESS:
      return {
        ...state,
        applicationFamily: null,
      };
    case DELETE_APPLICATION_FAMILY_ERROR:
      return {
        ...state,
        error: action.payload.error,
      };
    case DELETE_PRODUCT_REQUEST:
      return {
        ...state,
        error: null,
      };
    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        product: null,
      };
    case DELETE_PRODUCT_ERROR:
      return {
        ...state,
        error: action.payload.error,
      };
    case SAVE_APPLICATION_REQUEST:
      return {
        ...state,
        error: null,
      };
    case SAVE_APPLICATION_SUCCESS:
      return {
        ...state,
        application: action.payload.application,
      };
    case SAVE_APPLICATION_ERROR:
      return {
        ...state,
        error: action.payload.error,
      };
    case SAVE_APPLICATION_FAMILY_REQUEST:
      return {
        ...state,
        error: null,
      };
    case SAVE_APPLICATION_FAMILY_SUCCESS:
      return {
        ...state,
        application: {
          ...state.application,
          application_family: action.payload.applicationFamily.id,
        },
        applicationFamily: action.payload.applicationFamily,
        isAppFamilyModalOpen: false,
      };
    case SAVE_APPLICATION_FAMILY_ERROR:
      return {
        ...state,
        error: action.payload.error,
      };
    case SAVE_PRODUCT_REQUEST:
      return {
        ...state,
        error: null,
      };
    case SAVE_PRODUCT_SUCCESS:
      return {
        ...state,
        product: action.payload.product,
      };
    case SAVE_PRODUCT_ERROR:
      return {
        ...state,
        error: action.payload.error,
      };
    case SET_APPLICATION_TYPE:
      return {
        ...state,
        application: {
          ...state.application,
          application_type: action.payload.type,
          widget: null,
        },
      };
    case SET_APPLICATION_FIELD:
      return {
        ...state,
        application: {
          ...state.application,
          [action.payload.fieldName]: action.payload.fieldValue,
        },
      };
    case SET_APPLICATION_FAMILY_FIELD:
      return {
        ...state,
        applicationFamily: {
          ...state.applicationFamily,
          [action.payload.fieldName]: action.payload.fieldValue,
        },
      };
    case SET_PRODUCT_FIELD:
      return {
        ...state,
        product: {
          ...state.product,
          [action.payload.fieldName]: action.payload.fieldValue,
        },
        editedProduct: {
          ...state.editedProduct,
          [action.payload.fieldName]: action.payload.fieldValue,
        },
      };
    case SET_PRODUCT_MEDIA:
      return {
        ...state,
        product: {
          ...state.product,
          image: action.payload.file,
          previewUrl: action.payload.previewUrl,
        },
        editedProduct: {
          ...state.editedProduct,
          image: action.payload.file,
        },
      };
    case SET_APPLICATION_TO_EDIT:
      return {
        ...state,
        application: action.payload.application,
      };
    case SET_APPLICATION_PRODUCTS:
      return {
        ...state,
        application: {
          ...state.application,
          products: action.payload.products.reduce((productsIds, product) => {
            productsIds.push(product.id);
            return productsIds;
          }, []),
        },
      };
    case RESET_APPLICATION_TO_EDIT:
      return {
        ...state,
        application: null,
        applicationFamily: null,
      };
    case SET_PRODUCT_TO_EDIT:
      return {
        ...state,
        product: action.payload.product,
        editedProduct: { id: action.payload.product.id },
      };
    case RESET_PRODUCT_TO_EDIT:
      return {
        ...state,
        product: null,
        editedProduct: null,
      };
    default:
      return state;
  }
}

export default reducer;
