import * as React from "react";
import { useState } from "react";

import {
  CheckboxGroupInput,
  SaveButton,
  SimpleForm,
  TextField,
  Toolbar,
  useCreate,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";

import { Box, Dialog, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import dataProvider from "dataProvider";

import { isOrderLineCancelled } from "components/OrderShow/utils";

import { AFTER_SALES, DELIVERY } from "constants/shipping";

const formStyles = {
  toolbar: {
    backgroundColor: "#FFFFFF",
    justifyContent: "center",
  },
};
const useStyles = makeStyles(formStyles);

const getProducts = async (filter) => {
  return dataProvider.getList("products", {
    sort: {},
    filter: filter,
  });
};

const CreateDeliveryDialog = ({ order, isOpen, setIsOpen }) => {
  const [nextTimeslot, setNextTimeslot] = useState();
  const [products, setProducts] = useState([]);
  const refresh = useRefresh();
  const redirect = useRedirect();

  const [create] = useCreate("shipping/deliveries");
  const notify = useNotify();

  let nonCancelledOrderLines = order.intent.transactions
    .filter((orderline) => !isOrderLineCancelled(orderline))
    .map((orderline) => ({
      ...orderline,
      custom_text: `${orderline.product.product_type} ${orderline.product.brand} ${orderline.product.barcode}`,
    }));

  let collectItems = order.items.filter((collectItem) => ![DELIVERY].includes(collectItem.delivery_type));
  collectItems = collectItems.map((collectItem) => ({
    ...collectItem,
    custom_text: `${collectItem.product_type} ${collectItem.brand || ""} ${collectItem.collection_code} ${
      (collectItem.product && collectItem.product.barcode) || ""
    }`,
  }));

  let cancelledOrderLines = order.intent.transactions
    .filter((orderline) => isOrderLineCancelled(orderline))
    .map((orderline) => ({
      ...orderline,
      custom_text: `${orderline.product.product_type} ${orderline.product.brand} ${orderline.product.barcode}`,
    }));

  cancelledOrderLines = cancelledOrderLines.filter(
    (orderline) => !collectItems.find((item) => item.product && item.product.barcode === orderline.product.barcode)
  );

  React.useEffect(() => {
    const orderLines = order.intent.transactions;
    const barcodeList = orderLines.map((line) => {
      return line.product.barcode;
    });
    let tempProducts = [];
    barcodeList.map((barcode) =>
      getProducts({ barcode: barcode }).then((response) => {
        // to avoid latancy caused by async state call
        tempProducts = [...tempProducts, ...response.data];
        if (tempProducts) {
          setProducts(tempProducts);
        }
      })
    );
  }, [order.id, order.intent.transactions]);

  React.useEffect(() => {
    if (products && products.length > 0) {
      dataProvider
        .getList("shipping-timeslots", {
          sort: {},
          filter: {
            address: order.address,
            shipping_type: DELIVERY,
            delivery_type: order.type,
            postal_code: order.zipcode,
            start_date: moment().format("YYYY-MM-DD"),
            end_date: moment().add(15, "days").format("YYYY-MM-DD"),
            product: products.map((product) => product.id),
            shipping_provider: order.last_mile_shipping_provider && order.last_mile_shipping_provider.id,
            store: order.store,
          },
          pagination: {},
          noPagination: true,
        })
        .then(({ data }) => {
          if (data) setNextTimeslot(data[0]);
        });
    }
  }, [products, order.last_mile_shipping_provider, order.type, order.zipcode, order.store, order.address]);

  const save = (values) => {
    const orderLineSelectedIds = values.delivery_products || [];
    const collectionItemsSelectedIds = values.collection_products || [];
    const cancelledOrderLinesSelectedIds = values.cancelled_orderlines || [];

    const selectedProductsFromOrderLines = nonCancelledOrderLines
      .filter((orderLine) => orderLineSelectedIds.includes(orderLine.id))
      .map((item) => {
        return products.find((product) => item.product.barcode === product.barcode);
      });

    const selectedCancelledProducts = cancelledOrderLines
      .filter((orderLine) => cancelledOrderLinesSelectedIds.includes(orderLine.id))
      .map((item) => {
        return products.find((product) => item.product.barcode === product.barcode);
      });
    const formattedDeliveryItem = selectedProductsFromOrderLines.map((product) => {
      return { product: product.id, delivery_type: DELIVERY };
    });

    const formattedCollectionItems = collectItems
      .filter((item) => collectionItemsSelectedIds.includes(item.id))
      .map((item) => (item.product && item.product.id ? { ...item, product: item.product.id } : { ...item }));

    const formattedCanceledOrderLines = selectedCancelledProducts.map((product) => {
      return { product: product.id, delivery_type: AFTER_SALES };
    });
    create(
      {
        payload: {
          data: {
            address: order.address,
            difficulties: order.difficulties,
            elevator: order.elevator,
            items: [...formattedDeliveryItem, ...formattedCollectionItems, ...formattedCanceledOrderLines],
            last_mile_shipping_provider: order.last_mile_shipping_provider.id,
            order: order.id,
            phone: order.phone,
            time_span_beginning: nextTimeslot.time_span_beginning,
            time_span_end: nextTimeslot.time_span_end,
            type: order.type,
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          notify("Livraison reprogrammée");
          refresh();
          redirect("show", "/ecom/orders", order.id);
          setIsOpen(false);
        },

        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };

  const SaveToolbar = (props) => {
    const classes = useStyles();
    return (
      <Toolbar classes={{ toolbar: classes.toolbar }} {...props}>
        <SaveButton disabled={!nextTimeslot} label="Reprogrammer" />
      </Toolbar>
    );
  };

  return (
    <Dialog
      disableEnforceFocus
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <Box px={4} py={2}>
        <SimpleForm
          save={save}
          width="100%"
          redirect={false}
          toolbar={<SaveToolbar />}
          record={order}
          resource="ecom/order"
        >
          <Box width="100% !important">
            <Box width="100%" mb={2} textAlign="center">
              <Typography variant="h2">Planifier une nouvelle commande</Typography>
            </Box>
            <Box mb={2} width="100%" borderBottom={"1px solid #DBDFE8"}>
              <Typography variant="subtitle2">Appareils à livrer</Typography>
              <CheckboxGroupInput
                row={false}
                source="delivery_products"
                label=""
                optionText="custom_text"
                choices={nonCancelledOrderLines}
              />
            </Box>
            <Box mb={2} width="100%" borderBottom={"1px solid #DBDFE8"}>
              <Typography variant="subtitle2">Appareils à reprendre</Typography>
              <CheckboxGroupInput
                row={false}
                label=""
                source="collection_products"
                optionText="custom_text"
                choices={collectItems}
                helperText={"Produits à collecter"}
              />
              <CheckboxGroupInput
                row={false}
                label=""
                source="cancelled_orderlines"
                optionText="custom_text"
                choices={cancelledOrderLines}
                helperText={"Produits annulés"}
              />
            </Box>
            <Typography variant="subtitle2">Prestataires</Typography>
            <TextField source="last_mile_shipping_provider.name" label="Prestataire dernier km" />
            <TextField source="interregional_shipping_provider.name" label="Prestataire interrégional" />

            <Typography variant="subtitle2">Prochain créneau programmé</Typography>
            <Typography variant="body1">
              {(nextTimeslot &&
                nextTimeslot.time_span_beginning &&
                moment(nextTimeslot.time_span_beginning).locale("fr").format("DD MMMM YYYY - HH:mm")) ||
                "Pas de créneau disponible"}
            </Typography>
          </Box>
        </SimpleForm>
      </Box>
    </Dialog>
  );
};

export default CreateDeliveryDialog;
