export { default as Title1 } from "./Title1";
export { default as Title2 } from "./Title2";
export { default as H1 } from "./H1";
export { default as H3 } from "./H3";
export { default as H2 } from "./H2";

export { default as Body } from "./Body";

export { default as Small } from "./Small";
export { default as SmallBold } from "./SmallBold";
export { default as BodyBold } from "./BodyBold";
