/**
 * Following the duck pattern, actions, constants and reducers are in the same file called module.js
 *
 * See: https://github.com/erikras/ducks-modular-redux
 *
 */
import get from "lodash/get";
import { sendUnauthenticatedGetRequestToAPI } from "modules/generators/requestsToAPI";
import { toastr } from "react-redux-toastr";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { getCompanySelector } from "shared/pages/Company/module";

import { getAPIUrl, request } from "utils/networking";

export const GET_MURFY_FORM_REQUEST = "MurfyForm/GET_MURFY_FORM_REQUEST";
export const GET_MURFY_FORM_SUCCESS = "MurfyForm/GET_MURFY_FORM_SUCCESS";
export const GET_MURFY_FORM_ERROR = "MurfyForm/GET_MURFY_FORM_ERROR";

export const GET_MURFY_FORM_TIMESLOTS_REQUEST = "MurfyForm/GET_MURFY_FORM_TIMESLOTS_REQUEST";
export const GET_MURFY_FORM_TIMESLOTS_SUCCESS = "MurfyForm/GET_MURFY_FORM_TIMESLOTS_SUCCESS";
export const GET_MURFY_FORM_TIMESLOTS_ERROR = "MurfyForm/GET_MURFY_FORM_TIMESLOTS_ERROR";

export const POST_MURFY_FORM_REQUEST = "Form/POST_MURFY_FORM_REQUEST";
export const POST_MURFY_FORM_SUCCESS = "Form/POST_MURFY_FORM_SUCCESS";
export const POST_MURFY_FORM_ERROR = "Form/POST_MURFY_FORM_ERROR";

export const SET_MURFY_FORM_VALUES = "MurfyForm/SET_MURFY_FORM_VALUES";

export const SET_MURFY_FORM_TIMESLOT = "Form/SET_FORM_TIMESLOT";

export const TRACK_MURFY_POSTAL_CODE_INQUIRY = "MurfyForm/TRACK_MURFY_POSTAL_CODE_INQUIRY";
export const TRACK_MURFY_FORM_SCREEN = "MurfyForm/TRACK_MURFY_FORM_SCREEN";
export const TRACK_EVENT_VALUE = "MurfyForm/TRACK_EVENT_VALUE";

export function getMurfyFormRequest(id) {
  return {
    type: GET_MURFY_FORM_REQUEST,
    payload: id,
  };
}

export function getMurfyFormSuccess(murfyForm) {
  return {
    type: GET_MURFY_FORM_SUCCESS,
    payload: murfyForm,
  };
}

export function getMurfyFormError(error) {
  return {
    type: GET_MURFY_FORM_ERROR,
    payload: error,
  };
}

export function getMurfyFormTimeslotsRequest() {
  return {
    type: GET_MURFY_FORM_TIMESLOTS_REQUEST,
  };
}

export function getMurfyFormTimeslotsSuccess(timeslots) {
  return {
    type: GET_MURFY_FORM_TIMESLOTS_SUCCESS,
    payload: timeslots,
  };
}

export function getMurfyFormTimeslotsError(error) {
  return {
    type: GET_MURFY_FORM_TIMESLOTS_ERROR,
    payload: error,
  };
}

export function trackMurfyPostalCodeInquiry(postalCode) {
  return {
    type: TRACK_MURFY_POSTAL_CODE_INQUIRY,
    meta: {
      analytics: {
        type: "SEND_EVENT",
        payload: {
          action: "GET_TIMESLOTS_FROM_POSTAL_CODE",
          category: "MURFY_FORM",
          label: postalCode,
          trackerName: "user",
        },
      },
    },
  };
}

/**
 * Generate an action to post the murfy form
 * @param {String} paymentToken the token to be used by Stripe to make a charge from the backend
 * @param {String} userDiagnostic  the steps of the user in the diagnostic before the murfy form
 * The userDiagnostic param should be kept optional as it might not be provided depending on whether
 * the murfy form is posted from a diagnostic or another application.
 */
export function postMurfyFormRequest(paymentToken, userDiagnostic = "") {
  return {
    type: POST_MURFY_FORM_REQUEST,
    payload: { paymentToken, userDiagnostic },
  };
}

export function postMurfyFormSuccess(paymentPrice) {
  return {
    type: POST_MURFY_FORM_SUCCESS,
    meta: {
      analytics: {
        type: "SEND_EVENT",
        payload: {
          action: "PAYMENT_MURFY_FORM_SUCCESS",
          category: "MURFY_FORM",
          trackerName: "user",
          value: paymentPrice / 100,
        },
      },
      facebookPixel: {
        type: "Purchase",
        payload: {
          value: paymentPrice / 100,
          content_name: "murfy form",
          currency: "EUR",
        },
      },
    },
  };
}

export function postMurfyFormError(error) {
  return {
    type: POST_MURFY_FORM_ERROR,
    payload: error,
  };
}

export function setMurfyFormValues(formValues) {
  return {
    type: SET_MURFY_FORM_VALUES,
    payload: formValues,
  };
}

export function setMurfyFormTimeslot(timeslotId) {
  return {
    type: SET_MURFY_FORM_TIMESLOT,
    payload: timeslotId,
  };
}

export function trackMurfyFormScreen(murfyScreen) {
  return {
    type: TRACK_MURFY_FORM_SCREEN,
    meta: {
      analytics: {
        payload: {
          category: "MURFY_FORM",
          action: "ACCESS_MURFY_SCREEN",
          label: murfyScreen,
          trackerName: "user",
          nonInteraction: 1,
        },
      },
    },
  };
}

export const getPostalCodeValueSelector = (state) => get(state.murfyBookingForm.formValues, "postal_code");

export const getPostalCodeFormFieldIdSelector = (state) =>
  state.murfyBookingForm &&
  state.murfyBookingForm.murfyForm.form_fields &&
  get(
    state.murfyBookingForm.murfyForm.form_fields.find((formField) => formField.name === "postal_code"),
    "id"
  );

export const getMurfyFormSelector = (state) => state.murfyBookingForm.murfyForm;

export const getMurfyFormPriceSelector = (state) => get(state.murfyBookingForm.murfyForm, "payment_price");

export const getFormValuesSelector = (state) => state.murfyBookingForm.formValues;

export const getDataFormInputsSelector = (state, company, paymentToken, userDiagnostic, murfyFormPrice) => {
  const { murfyForm, formValues, murfyTimeslot } = state.murfyBookingForm;
  const formFields = murfyForm.form_fields;

  const genericFieldsNames = ["email", "firstname", "lastname"];

  const genericData = {
    city: get(formValues, "city"),
    company: company.id,
    displayed_payment_price: murfyFormPrice,
    email: get(formValues, "mail"),
    extra_information: get(formValues, "complement"),
    firstname: get(formValues, "firstname"),
    form: murfyForm.id,
    is_payment_done: true,
    lastname: get(formValues, "lastname"),
    payment_method: "credit_card",
    phone: get(formValues, "tel"),
    postal_code: get(formValues, "postal_code"),
    street: get(formValues, "street"),
    street_number: get(formValues, "street_number"),
    ticket_state: murfyForm.ticket_state || null,
    ticket_description: murfyForm.ticket_description,
    timeslot: murfyTimeslot,
  };

  const formattedInputsData = [];

  formFields.map(
    (formField) =>
      !genericFieldsNames.includes(formField.name) &&
      get(formValues, formField.name) &&
      formattedInputsData.push({
        form_field: formField.id,
        value: get(formValues, formField.name),
      })
  );

  return {
    ...genericData,
    ticket_fields: JSON.stringify(formattedInputsData),
    stripe_token: paymentToken,
    user_diagnostic: userDiagnostic,
  };
};

export function* getMurfyForm(action) {
  const route = `/widget/murfy/form/${action.payload}/`;

  yield call(sendUnauthenticatedGetRequestToAPI, route, getMurfyFormSuccess, getMurfyFormError);
}

export function* getMurfyFormTimeslots(action) {
  const postalCodeValue = yield select(getPostalCodeValueSelector);
  const company = yield select(getCompanySelector);
  yield put(trackMurfyPostalCodeInquiry(postalCodeValue));

  const route = `/widget/timeslot/city/?company=${company.id}&postal_code=${postalCodeValue}`;

  yield call(sendUnauthenticatedGetRequestToAPI, route, getMurfyFormTimeslotsSuccess, getMurfyFormTimeslotsError);
}

export function* postMurfyForm(action) {
  const company = yield select(getCompanySelector);
  const murfyFormPrice = yield select(getMurfyFormPriceSelector);
  const formattedInputs = yield select(
    getDataFormInputsSelector,
    company,
    action.payload.paymentToken,
    action.payload.userDiagnostic,
    murfyFormPrice
  );

  const customerFileConfig = {
    method: "post",
    url: `${getAPIUrl()}/murfy-erp/customer-files/`,
    headers: {
      "Content-Type": "application/json",
    },
    data: formattedInputs,
  };

  try {
    yield call(request, customerFileConfig);
    yield put(postMurfyFormSuccess(murfyFormPrice));
  } catch (error) {
    yield put(postMurfyFormError(error));
    yield call(toastr.error, "Erreur", "Le formulaire n'a pas pu être envoyé");
  }
}

export function* getMurfyFormSaga() {
  yield takeEvery(GET_MURFY_FORM_REQUEST, getMurfyForm);
}

export function* getMurfyFormTimeslotSaga() {
  yield takeEvery(GET_MURFY_FORM_TIMESLOTS_REQUEST, getMurfyFormTimeslots);
}

export function* postMurfyFormSaga() {
  yield takeEvery(POST_MURFY_FORM_REQUEST, postMurfyForm);
}

const initialState = {
  error: null,
  formValues: {},
  isLoading: false,
  isMurfyFormSuccessfullyPosted: false,
  murfyForm: null,
  murfyTimeslot: null,
  paymentError: null,
  timeslots: null,
};

/**
 * Following the duck pattern, the module.js file should export a reducer as a default function
 */
export function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_MURFY_FORM_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case GET_MURFY_FORM_REQUEST:
      return {
        ...state,
        murfyForm: null,
        error: null,
      };
    case GET_MURFY_FORM_SUCCESS:
      return {
        ...state,
        murfyForm: action.payload,
      };
    case GET_MURFY_FORM_TIMESLOTS_ERROR:
      return {
        ...state,
        timeslots: [],
        error: action.payload,
      };
    case GET_MURFY_FORM_TIMESLOTS_REQUEST:
      return {
        ...state,
        error: null,
      };
    case GET_MURFY_FORM_TIMESLOTS_SUCCESS:
      return {
        ...state,
        timeslots: action.payload,
      };
    case POST_MURFY_FORM_REQUEST:
      return {
        ...state,
        isLoading: true,
        isMurfyFormSuccessfullyPosted: false,
        error: null,
        paymentError: null,
      };
    case POST_MURFY_FORM_SUCCESS:
      return {
        ...state,
        formValues: {},
        isLoading: false,
        isMurfyFormSuccessfullyPosted: true,
        murfyTimeslot: null,
      };
    case POST_MURFY_FORM_ERROR:
      if (action.payload.data === undefined) {
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
      } else {
        switch (action.payload.data.code) {
          case "INVALID_CVC_TRY_AGAIN":
          case "payment_failed":
            return {
              ...state,
              isLoading: false,
              error: action.payload,
              paymentError: action.payload.data.code,
            };
          default:
            return {
              ...state,
              isLoading: false,
              error: action.payload,
            };
        }
      }
    case SET_MURFY_FORM_VALUES:
      return {
        ...state,
        formValues: {
          ...state.formValues,
          ...action.payload,
        },
      };
    case SET_MURFY_FORM_TIMESLOT:
      return {
        ...state,
        murfyTimeslot: action.payload,
      };
    default:
      return state;
  }
}

export default reducer;
