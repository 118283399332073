import React from "react";

import { Datagrid, ReferenceManyField, useNotify, useRefresh } from "react-admin";

import { Box, Typography, withStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import RoomIcon from "@material-ui/icons/Room";

import { bookAppointment } from "utils/api";

import AddVisit from "./components/AddVisit";
import VisitDetails from "./components/VisitDetails";
import VisitSnapshotField from "./components/VisitSnapshotField";
import "./style.css";

const compareDate = (current, prev) => {
  if (current > prev) return -1;
  if (prev > current) return 1;
  return 0;
};
const StyledDatagrid = withStyles({
  headerRow: {
    display: "none",
  },
  expandIconCell: {
    display: "none",
  },
  rowCell: {
    marginBottom: "10px",
    background: "#FFFFFF",
    borderBottom: "10px solid #f4f3f8",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#E4E3E8",
    },
  },
  expandedPanel: {
    borderBottom: "10px solid #f4f3f8",
  },
  row: {
    height: "80px",
    background: "#FFFFFF",
  },
})(Datagrid);

const StyledRoomIcon = withStyles({
  root: {
    width: "24px",
    height: "24px",
    color: "#FCD660",
    marginLeft: "-5px",
    verticalAlign: "sub",
  },
})(RoomIcon);

export default function CustomerFileVisitsNew({ getCustomerFileDetail, customerFile, payments, employees }) {
  const notify = useNotify();
  const refresh = useRefresh();
  const [isLoading, setIsLoading] = React.useState(false);
  const handleVisitSubmission = async (values) => {
    setIsLoading(true);
    const { status } = await bookAppointment(customerFile.uuid, {
      payload: {
        timeslot: {
          id: 1, // TODO: backend does not accept a string
          beginning: values.timeslot.beginning,
          end: values.timeslot.end,
        },
        visit_type: values.visit_type,
        ...(values.employee && { employee: parseInt(values.employee) }),
        visit_status: values.visit_status,
        time_needed: values.time_needed,
      },
    });
    if (status === 202) {
      notify("Passage ajouté !", "success");
      getCustomerFileDetail(customerFile.id);
      refresh();
    } else {
      notify(`Une erreur est survenue`, "error");
    }
    setIsLoading(false);
  };
  const lastVisit = customerFile.visits.sort((slot1, slot2) =>
    compareDate(slot1.timeslot.beginning, slot2.timeslot.beginning)
  )[0];
  const lastAssignedRepairman = lastVisit ? lastVisit.assigned_repairman : "";

  return (
    <Box>
      <Box display="flex" mb={3} mt={1}>
        <StyledRoomIcon />
        <Typography variant="subtitle1">
          {customerFile.street_number} {customerFile.street},&nbsp;
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {customerFile.postal_code} {customerFile.city}
        </Typography>
      </Box>
      <Box width={600} bgcolor="#FFFFFF">
        {!isLoading && (
          <ReferenceManyField reference="visits" target="customer_file_id" record={customerFile} basePath="">
            <StyledDatagrid rowClick="expand" expand={<VisitDetails employees={employees} />}>
              <VisitSnapshotField />
            </StyledDatagrid>
          </ReferenceManyField>
        )}
        {isLoading && (
          <Box textAlign="center" pb={1}>
            <Box pt={2} mb={1}>
              <Typography height="100%" align="center" variant="body1">
                Chargement des passages en cours
              </Typography>
            </Box>
            <CircularProgress color="primary" />
          </Box>
        )}
        <AddVisit
          handleCreateVisit={handleVisitSubmission}
          defaultRepairmanId={lastAssignedRepairman}
          customerFile={customerFile}
        />
      </Box>
    </Box>
  );
}
