import React, { useState } from "react";

import { SimpleForm, TextInput } from "react-admin";

import { Box } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

import invariant from "tiny-invariant";
import { CustomerFileDetailed } from "types/customer-files";
import * as yup from "yup";

import { SimpleValidateToolbar } from "components/FormToolbars";
import Modal from "components/Modal";
import Button from "components/atoms/Button";
import InternationalPhoneInput from "components/atoms/PhoneInput/InternationalPhoneInput";
import { isPhoneNumberInvalid } from "components/atoms/PhoneInput/utils";

type CustomerFileUpdateModalProps = {
  customerFile: CustomerFileDetailed;
  updateCustomerFileDetail: (id: number, values: CustomerFileDetailed) => void;
};

const CustomerFileUpdateModal: React.FunctionComponent<CustomerFileUpdateModalProps> = ({
  customerFile,
  updateCustomerFileDetail,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const updateCustomerFile = (values: CustomerFileDetailed) => {
    setIsModalOpen(false);
    updateCustomerFileDetail(customerFile.id, values);
  };

  const schema = yup.object().shape({
    phone: yup
      .string()
      .test("isPhoneNumberInvalid", "Numéro de téléphone invalide", (value) => !isPhoneNumberInvalid(value)),
    postal_code: yup
      .string()
      .required("Code postal est requis")
      .length(5, "Code postal doit avoir exactement 5 caractères"),
  });

  const validationSchema = async (values: CustomerFileDetailed) => {
    try {
      await schema.validate(values, { abortEarly: false });
      return {};
    } catch (errors) {
      const errorMessages: Record<string, string> = {};

      invariant(errors instanceof yup.ValidationError);
      if (errors instanceof yup.ValidationError) {
        errors.inner.forEach((error) => {
          errorMessages[error.path || ""] = error.message;
        });
      }

      return errorMessages;
    }
  };
  return (
    <>
      {isModalOpen ? (
        <Modal
          modalClassName={`modal-small-content`}
          isOpen={isModalOpen}
          handleCloseModal={() => setIsModalOpen(false)}
        >
          <SimpleForm
            resource="customer-file"
            record={customerFile}
            validate={validationSchema}
            toolbar={
              <SimpleValidateToolbar onSave={updateCustomerFile} size="large" version="right" label="Enregistrer" />
            }
          >
            <Box width="100% !important">
              <Box display="flex" width="100%" paddingLeft="12px">
                <Box width="320px">
                  <TextInput source="lastname" label="Nom" InputProps={{ disableUnderline: true }} fullWidth />
                </Box>
                <Box width="320px" marginLeft="24px">
                  <TextInput source="firstname" label="Prénom" InputProps={{ disableUnderline: true }} fullWidth />
                </Box>
              </Box>
              <Box display="flex" width="100%" paddingLeft="12px">
                <Box width="320px">
                  <TextInput source="email" label="Adresse email" InputProps={{ disableUnderline: true }} fullWidth />
                </Box>
                <Box width="320px" marginLeft="24px" mb={2}>
                  <InternationalPhoneInput label={"N° de Téléphone"} source="phone" />
                </Box>
              </Box>
              <Box display="flex" width="100%" paddingLeft="12px">
                <Box width="120px">
                  <TextInput
                    multiline
                    source="street_number"
                    label="N°"
                    InputProps={{ disableUnderline: true }}
                    fullWidth
                  />
                </Box>
                <Box width="520px" marginLeft="24px">
                  <TextInput multiline source="street" label="Rue" InputProps={{ disableUnderline: true }} fullWidth />
                </Box>
              </Box>
              <Box display="flex" width="100%" paddingLeft="12px">
                <Box width="120px">
                  <TextInput
                    multiline
                    source="postal_code"
                    label="Code postal"
                    InputProps={{ disableUnderline: true }}
                    fullWidth
                  />
                </Box>
                <Box width="520px" marginLeft="24px">
                  <TextInput multiline source="city" label="Ville" InputProps={{ disableUnderline: true }} fullWidth />
                </Box>
              </Box>
              <Box display="flex" width="100%" paddingLeft="12px">
                <Box width="658px">
                  <TextInput
                    multiline
                    source="extra_information"
                    label="Complément"
                    InputProps={{ disableUnderline: true }}
                    fullWidth
                  />
                </Box>
              </Box>
            </Box>
          </SimpleForm>
        </Modal>
      ) : (
        <Button dimension="small" version="invisible" onClick={() => setIsModalOpen(true)}>
          <EditIcon />
        </Button>
      )}
    </>
  );
};
export default CustomerFileUpdateModal;
