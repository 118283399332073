const CustomerFileTargetStyles = {
  root: {
    display: "flex",
    padding: "8px",
  },
  actionButton: {
    boxShadow: "0px 1px 15px rgba(0, 0, 0, 0.08), 0px 0px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: 4,
    backgroundColor: "white",
    color: "#8795B3",
    cursor: "pointer",
    justifyContent: "space-around",
    padding: "4px 8px",
    whiteSpace: "nowrap",
    "&:hover": {
      background: "#f1f1f1",
    },
    margin: "0 4px 4px",
  },
  body2Bold: {
    fontWeight: 600,
    color: "#8795B3",
    marginRight: 8,
    marginTop: 6,
  },
  body2: {
    display: "inline",
    fontWeight: 500,
    color: "#8795B3",
  },
  flexWrapDiv: {
    display: "flex",
    flexWrap: "wrap",
  },
  temporisationReminderContainer: {
    fontSize: 14,
    lineHeight: "140%",
    color: "#2B3B5C",
    marginBottom: 16,
    display: "table",
  },
  temporisationReminderIcon: {
    color: "#F45B5B",
  },
  temporisationTypeOfMailContainer: {
    display: "table-cell",
    verticalAlign: "middle",
  },
  temporisationTypeOfMailText: {
    fontWeight: "bold",
    paddingLeft: 8,
  },
};

export default CustomerFileTargetStyles;
