import React, { Component } from "react";

import PropTypes from "prop-types";

export default class UserDiagnostic extends Component {
  static propTypes = {
    ticketUserDiagnostic: PropTypes.string,
  };

  render() {
    return (
      <div>
        <ul>
          {this.props.ticketUserDiagnostic &&
            this.props.ticketUserDiagnostic.split("%-%").map((ticketStep, index) => {
              return <li key={index}>{ticketStep}</li>;
            })}
        </ul>
      </div>
    );
  }
}
