import React from "react";

import PropTypes from "prop-types";

import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
// UI
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";

import { quoteElementList } from "pages/customerFiles/components/CustomerFileBillingQuotes/services/QuoteHelper.js";
// Services
import { getQuoteElementProperty } from "pages/customerFiles/components/CustomerFileBillingQuotes/services/getQuoteElementProperty.js";

//Utils
import { formatPriceCompact } from "utils/formatting";

//Types
import { QuoteElement } from "../../../services/types.js";

const SponshorshipReward = ({ element, remove }) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" p={1}>
      <Typography variant="body1">{getQuoteElementProperty(element.element_type, quoteElementList, "name")}</Typography>
      <Box display="flex" alignItems="center">
        <Typography variant="body1">{formatPriceCompact(element.amount_withtax)}</Typography>
        {remove && (
          <IconButton onClick={() => remove(element.id)}>
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

SponshorshipReward.propTypes = {
  element: QuoteElement,
  remove: PropTypes.func,
};

export default SponshorshipReward;
