import React from "react";

import WarningIcon from "@material-ui/icons/Warning";

import { Body, SmallBold } from "components/atoms/Typography";

import { usePaymentIssue } from "utils/hooks/usePaymentIssue";

import InformativeBlock from "../InformativeBlock";

type VisitPaymentStatusProps = {
  visitId: number;
  balance: number;
};

const VisitPaymentStatus: React.FC<VisitPaymentStatusProps> = ({ visitId, balance }) => {
  const { issue, isLoading, isError } = usePaymentIssue(visitId);

  if ((!isLoading && !isError && issue) || balance !== 0) {
    return (
      <InformativeBlock
        title="Attention, état du paiement du dossier à vérifier."
        content={
          <Body>
            {issue ? (
              <>
                <SmallBold fontStyle="italic">Information additionnelle indiquée par le/la technicien(ne):</SmallBold> ‣{" "}
                {issue.reason}
              </>
            ) : (
              "• La balance du dossier n'est pas nulle"
            )}
          </Body>
        }
        icon={<WarningIcon />}
        variant="error"
      />
    );
  }

  return null;
};

export default VisitPaymentStatus;
