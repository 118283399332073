import React from "react";

import { Box, Typography } from "@material-ui/core";

import Moment from "moment";
import { CustomerFileSlim } from "types/customer-files";

import ColorChip from "components/atoms/ColorChip";

import { REPORT_CHECK_REASONS_CHOICES } from "constants/reportCheckReasons";
import { TASK_STATE_TODO } from "constants/task";

type TasksToDoFileCardProps = {
  customerFile: CustomerFileSlim;
};

const TasksToDoFileCard: React.FunctionComponent<TasksToDoFileCardProps> = ({
  customerFile,
}: TasksToDoFileCardProps) => {
  const lastCheckToDo =
    customerFile &&
    customerFile.repair_report_checks
      .filter((checks) => checks.state === TASK_STATE_TODO)
      .slice(-1)
      .pop();

  const getVerboseCheck = (reason: string) => {
    const possibleReason = REPORT_CHECK_REASONS_CHOICES.find(
      (possibleReason: { id: string; name: string }) => reason === possibleReason.id
    );
    return possibleReason ? possibleReason.name : "";
  };

  if (!lastCheckToDo) return <></>;
  return (
    <Box>
      <Box mt={1}>
        {lastCheckToDo.reasons &&
          lastCheckToDo.reasons.map((reason: string) => (
            <Box margin="1px">
              <ColorChip color="grey" label={getVerboseCheck(reason)} />
            </Box>
          ))}
      </Box>
      <Box mt={1}>
        <Box>
          <Typography variant="body1">
            <b>Réparateur: </b>{" "}
            {customerFile.last_repairman
              ? `${customerFile.last_repairman.first_name} ${customerFile.last_repairman.last_name}`
              : "Aucun"}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1">
            <b>Demandeur: </b> {lastCheckToDo.created_by_name}
          </Typography>
        </Box>
      </Box>
      <Box mt={1}>
        <Typography variant="caption">
          Demande de contrôle <b> {Moment(lastCheckToDo.created_at).fromNow()} </b>
        </Typography>
      </Box>
    </Box>
  );
};
export default TasksToDoFileCard;
