/**
 * Following the duck pattern, actions, constants and reducers are in the same file called module.js
 *
 * See: https://github.com/erikras/ducks-modular-redux
 *
 */
import { call, put, takeEvery } from "redux-saga/effects";

import { getAPIUrl, request } from "utils/networking";

export const GET_ARTICLE_REQUEST = "Article/GET_ARTICLE_REQUEST";
export const GET_ARTICLE_SUCCESS = "Article/GET_ARTICLE_SUCCESS";
export const GET_ARTICLE_ERROR = "Article/GET_ARTICLE_ERROR";

export function getArticleRequest(id) {
  return {
    type: GET_ARTICLE_REQUEST,
    payload: id,
  };
}

export function getArticleSuccess(article) {
  return {
    type: GET_ARTICLE_SUCCESS,
    payload: article,
  };
}

export function getArticleError(error) {
  return {
    type: GET_ARTICLE_ERROR,
    payload: error,
  };
}

export function* getArticle(action) {
  const config = {
    method: "get",
    url: `${getAPIUrl()}/widget/article/${action.payload}/`,
  };
  try {
    const article = yield call(request, config);
    yield put(getArticleSuccess(article));
  } catch (error) {
    yield put(getArticleError(error));
  }
}

export function* getArticleSaga() {
  yield takeEvery(GET_ARTICLE_REQUEST, getArticle);
}

const initialState = {
  error: null,
  article: null,
};

/**
 * Following the duck pattern, the module.js file should export a reducer as a default function
 */
export function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ARTICLE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case GET_ARTICLE_REQUEST:
      return {
        ...state,
        article: null,
        error: null,
      };
    case GET_ARTICLE_SUCCESS:
      return {
        ...state,
        article: action.payload,
      };
    default:
      return state;
  }
}

export default reducer;
