import React from "react";

import { Box, styled } from "@material-ui/core";

import { useFormState } from "react-final-form";

import { Body } from "components/atoms/Typography";

const Container = styled(Box)({
  borderRadius: "7px 7px 7px 7px",
  boxShadow: "0 0 3px 0 rgba(0, 0, 0, 0.08), 0 1px 40px 0 rgba(0, 0, 0, 0.08)",
  display: "flex",
  flexDirection: "column",
  margin: "16px 0px",
  width: 672,
  height: 150,
  justifyContent: "center",
  alignItems: "center",
  padding: 16,
});

export const AvailableTimeSlot: React.FC = ({ children }) => {
  const { values } = useFormState();
  if (values && values.street_number) {
    return <> {children}</>;
  }
  return (
    <Container>
      <Body>Les créneaux s'afficheront une fois l'adresse complète </Body>{" "}
      <Body>(nom de rue, numéro de rue,...). </Body>
    </Container>
  );
};
export default AvailableTimeSlot;
