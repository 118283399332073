import React from "react";

import { Box, Typography } from "@material-ui/core";
import RoomIcon from "@material-ui/icons/Room";

type LocationProps = { city: string };

export default ({ city }: LocationProps) => (
  <Box display="flex" ml="-5.5px" alignItems="center">
    <RoomIcon />
    <Typography variant="body2">{city || "Inconnu"}</Typography>
  </Box>
);
