import React, { Component } from "react";

import PropTypes from "prop-types";

import { ElementaryBlock } from "admin/components";
import sortBy from "lodash/sortBy";

import "./style.css";

export default class CategorizedElementsGroup extends Component {
  static propTypes = {
    categorizedElementsGroupsList: PropTypes.shape({
      createElement: PropTypes.func,
      id: PropTypes.number.isRequired,
      categorizedElementsGroups: PropTypes.arrayOf(
        PropTypes.shape({
          categoryLabel: PropTypes.string.isRequired,
          category: PropTypes.string.isRequired,
          elements: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number.isRequired,
              order: PropTypes.number.isRequired,
              title: PropTypes.string.isRequired,
            })
          ).isRequired,
        })
      ).isRequired,
      title: PropTypes.string.isRequired,
    }),
    onPatchOrder: PropTypes.func.isRequired,
  };

  createElement = (category) => () => this.props.createElement(category);

  handleDecreaseOrder = (elementId, elementOrder) => () => this.props.onPatchOrder(elementId, elementOrder, true);

  handleIncreaseOrder = (elementId, elementOrder) => () => this.props.onPatchOrder(elementId, elementOrder, false);

  render() {
    const { categorizedElementsGroupsList } = this.props;
    return (
      <div className="row no-gutters categorized-elements-group-container">
        <div className="categorized-elements-title-container">{categorizedElementsGroupsList.title}</div>
        <div className="row categorized-elements-group-categories-container">
          {sortBy(categorizedElementsGroupsList.categorizedElementsGroups, "categoryLabel").map(
            (categorizedElementsGroup) => (
              <div
                className="col-4 categorized-elements-category-container"
                id={`checking-process-${categorizedElementsGroup.category}`}
                key={`checking-process-${categorizedElementsGroup.category}`}
              >
                <div className="categorized-elements-group-category-title">
                  {categorizedElementsGroup.categoryLabel}
                </div>
                {sortBy(categorizedElementsGroup.elements, "order").map((element) => (
                  <div
                    className="categorized-elements-group-element-container"
                    key={`checking-process-element-${element.id}`}
                  >
                    <ElementaryBlock
                      id={element.id}
                      onClick={() => {}}
                      onDecreaseOrder={this.handleDecreaseOrder(element.id, element.order)}
                      onIncreaseOrder={this.handleIncreaseOrder(element.id, element.order)}
                      onDeleteElement={this.props.onDeleteElement}
                      text={`${element.order} - ${element.title}`}
                    />
                  </div>
                ))}
                {this.props.createElement && (
                  <div
                    className="categorized-elements-element-container"
                    onClick={this.createElement(categorizedElementsGroup.category)}
                  >
                    <span>{"+ Ajouter un élément"}</span>
                  </div>
                )}
              </div>
            )
          )}
        </div>
      </div>
    );
  }
}
