export const historyGridStyles = {
  topContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  logDescription: {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: 16,
    color: "#2B3B5C",
  },
  bottomContainer: {
    display: "flex",
    flexDirection: "row",
    marginLeft: 12,
  },
  emptyNext: {
    width: 49,
    height: 40,
  },
  lineNext: {
    height: 20,
    width: 2,
    margin: 11,
    marginRight: 36,
    backgroundColor: "#8795B3",
  },
  logDate: {
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: 13,
    color: "#2B3B5C",
  },
  descriptionContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  employeesAvatarsFieldContainer: {
    padding: 8,
  },
};

export const historyWrapper = {
  Modal: {
    width: 570,
    height: 580,
    textAlign: "center",
  },
  titleContainer: {
    marginBottom: 24,
    marginTop: 24,
  },
  historyContainer: {
    margin: 32,
    marginBottom: 0,
  },
};
