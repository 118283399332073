import { push } from "connected-react-router";
import { connect } from "react-redux";

import { isElementsGroupActiveSelector, setActiveElementsGroup } from "pages/Cms/ContentManagement/module";

import QuickAccessElementsGroup from "./QuickAccessElementsGroup.component";

function mapStateToProps(state, props) {
  return {
    isSelected: isElementsGroupActiveSelector(
      state.cms.elementFilter,
      state.cms.activeElementsGroupsTitle,
      props.groupTitle
    ),
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    toggleSelectedElementsGroup(elementsGroupTitle) {
      dispatch(setActiveElementsGroup(elementsGroupTitle));
    },
    redirectToEditionPage(id) {
      dispatch(push(`${props.redirectionRoute}/${id}`));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuickAccessElementsGroup);
