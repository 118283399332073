import React, { useEffect, useState } from "react";

import { MenuItemLink, Responsive, getResources, usePermissions } from "react-admin";

import { withRouter } from "react-router-dom";

import { Divider, styled, withStyles } from "@material-ui/core";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import BookIcon from "@material-ui/icons/Book";
import BuildIcon from "@material-ui/icons/Build";
import CalendarToday from "@material-ui/icons/CalendarToday";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ChromeReaderModeIcon from "@material-ui/icons/ChromeReaderMode";
import Explore from "@material-ui/icons/Explore";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import InsertInvitationIcon from "@material-ui/icons/InsertInvitation";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import PublishIcon from "@material-ui/icons/Publish";
import ScheduleIcon from "@material-ui/icons/Schedule";
import SettingsApplications from "@material-ui/icons/SettingsApplications";
import ShareIcon from "@material-ui/icons/Share";
import StoreIcon from "@material-ui/icons/Store";
import DefaultIcon from "@material-ui/icons/ViewList";

import { stringify } from "query-string";
import { connect } from "react-redux";

import CustomerFileState from "constants/customerFileStates";
import {
  ADMIN_ACCOUNT_ROUTE,
  ADMIN_CMS_ROUTE,
  ADMIN_INTERVENTION_ROUTE,
  ADMIN_PRODUCTS_ROUTE,
  ADMIN_PRODUCT_MODELS_ROUTE,
  ADMIN_SHIPPING_ROUTE,
  ADMIN_SKU_STORE_ROUTE,
  ADMIN_WORKSHOPS_ROUTE,
} from "constants/routes";
import { TASK_STATE_DONE, TASK_STATE_ON_HOLD, TASK_STATE_TODO } from "constants/tasksStates";
import WorkshopTaskTypes from "constants/workshopTaskTypes";

import { getCustomerFileStateFromLabel } from "utils/getCustomerFileStateFromLabel/getCustomerFileStateFromLabel";
import { DEVELOPER_GROUP, ISAHIT_GROUP, OFFER_ADMIN_GROUP, WORKSHOP_ADMIN_GROUP, hasPerm } from "utils/permissions";

import MurfySubMenu from "./MurfySubMenu/MurfySubMenu";
import MurfySubMenuItem from "./MurfySubMenuItem";
import WorkshopFileSubMenuItem, { getWorkshopFileMenuLink } from "./WorkshopFileSubMenuItem";

const murfyMenuStyle = (theme) => ({
  root: {
    alignItems: "center",
    boxSizing: "content-box",
    color: theme.palette.primary.contrastText,
    fontSize: "16px",
    height: "32px",
    padding: "12px 16px",
    "&:hover": {
      color: theme.palette.primary.contrastText,
    },
  },
  active: {
    color: theme.palette.secondary.main,
    "& $icon": {
      color: theme.palette.secondary.main,
    },
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  icon: {
    color: theme.palette.primary.contrastText,
    height: "32px",
    fontSize: "16px",
    alignItems: "center",
  },
});

const MurfyMenuDivider = withStyles({
  root: {
    color: "#8795B3",
    margin: "auto",
    width: "80%",
  },
})(Divider);

const ADMIN_ROLE = "admin";
const REPAIRMAN_ROLE = "repairman";

const SKU_DEMANDS = "sku-demands";
const MARKETPLACE_EXPORT = "marketplace-export";
const REPORT_REVIEW = "report-review";
const INTERVENTION_PREPARATION = "intervention-preparation";

const LOCATION_USAGE_FINISHED = "finished_product";

const StyledIcon = styled(({ component: Icon, ...props }) => <Icon {...props} />)(({ size }) => ({
  maxWidth: size === "small" ? 16 : size === "medium" ? 20 : 24,
}));

function MurfyMenu({ classes, resources, onMenuClick, logout, location, customerFilesStates, account }) {
  const [fileStats, setFileStats] = useState({
    customerFileNumber: 0,
    awaitingAffectationFiles: 0,
    reportToProcess: 0,
    awaitingSparePart: 0,
    prequalReportToProcess: 0,
    requestForPlanning: 0,
  });

  const { loaded, permissions } = usePermissions();

  useEffect(() => {
    if (location.pathname.includes("/customer-files")) {
      const stats = calculateFileStats(customerFilesStates);
      setFileStats(stats);
    } else {
      setFileStats({ customerFileNumber: null });
    }
  }, [location, customerFilesStates]);

  const calculateFileStats = (states) => {
    let stats = {
      customerFileNumber: 0,
      awaitingAffectationFiles: 0,
      reportToProcess: 0,
      awaitingSparePart: 0,
      prequalReportToProcess: 0,
      requestForPlanning: 0,
    };

    for (const state of states) {
      stats.customerFileNumber += state.counter;
      switch (state.name) {
        case CustomerFileState.AWAITING_AFFECTATION:
          stats.awaitingAffectationFiles += state.counter;
          break;
        case CustomerFileState.REPORT_TO_PROCESS:
          stats.reportToProcess += state.counter;
          break;
        case CustomerFileState.AWAITING_SPARE_PART_ORDER:
          stats.awaitingSparePart += state.counter;
          break;
        case CustomerFileState.PREQUAL_REPORT_TO_PROCESS:
          stats.prequalReportToProcess += state.counter;
          break;
        case CustomerFileState.REQUEST_FOR_PLANNING_AFTER_SALES:
          stats.requestForPlanning += state.counter;
          break;
        default:
          break;
      }
    }

    return stats;
  };

  const isLinkActive = (viewPathname) => location && location.pathname.split("/").includes(viewPathname);

  const getCustomerFileStateFromStateName = (stateName) =>
    customerFilesStates && stateName ? getCustomerFileStateFromLabel(customerFilesStates, stateName) : undefined;

  const getCustomerFileMenuLink = (name, status = "") => {
    let defaultFilters = {};
    let userFilter = {};
    if (status !== "" && status.id) {
      defaultFilters = { state: status.id };
    }
    if (account && account.role !== ADMIN_ROLE && account.role !== REPAIRMAN_ROLE) {
      userFilter = { assigned_employees: account.id };
    }

    let searchParams = null;

    if (name !== "customer-files/affectation") {
      searchParams = stringify({
        filter: JSON.stringify({ ...defaultFilters, ...userFilter }),
        order: "DESC",
        page: 1,
        perPage: 20,
        sort: "id",
      });
    }
    return { pathname: `/${name}`, search: searchParams };
  };

  const getCustomerFilesToProcessMenuLink = (name, subUrl = null, defaultState = null) => {
    let userFilter = {};
    let defaultFilters = {};

    switch (subUrl) {
      case INTERVENTION_PREPARATION:
        if (account) {
          userFilter = {
            preparation_visit_task_assignee: account.id,
            displayedFilters: "preparation_visit_task_assignee",
          };
        }
        defaultFilters = {
          preparation_visit_task_state: defaultState || TASK_STATE_TODO,
        };
        break;
      case REPORT_REVIEW:
      default: {
        const crToCheckStatus = getCustomerFileStateFromStateName(CustomerFileState.CR_TO_CHECK);
        if (crToCheckStatus) {
          defaultFilters = { state: crToCheckStatus.id };
        }
        if (account) {
          userFilter = { assignee: account.id, displayedFilters: "assignee" };
        }
        defaultFilters = {
          ...defaultFilters,
          repair_report_check_state: TASK_STATE_TODO,
        };
      }
    }

    let searchParams = stringify({
      filter: JSON.stringify({ ...userFilter, ...defaultFilters }),
      order: "DESC",
      page: 1,
      perPage: 20,
      sort: "id",
    });

    return { pathname: `/${name}`, search: searchParams };
  };

  const renderResourceMenuItem = (resource) => {
    const menuItems = {
      "customer-files": () =>
        hasPerm(permissions, ["*"], [ISAHIT_GROUP]) && (
          <MurfySubMenu
            link={getCustomerFileMenuLink(resource.name)}
            key={resource.name}
            isOpen={isLinkActive(resource.name)}
            name={(resource.options && resource.options.label) || resource.name}
            icon={resource.icon ? <resource.icon /> : <DefaultIcon />}
          >
            <MurfySubMenuItem
              itemName="Tous mes dossiers"
              link={getCustomerFileMenuLink(resource.name)}
              icon={<AllInboxIcon />}
              matchWithQueryParams
              numberDisplayed={fileStats.customerFileNumber}
            />
            <MurfySubMenuItem
              itemName="Affectation"
              link={getCustomerFileMenuLink(`${resource.name}/affectation`)}
              icon={<ScheduleIcon />}
              numberDisplayed={fileStats.awaitingAffectationFiles}
            />
            <MurfySubMenuItem
              link={getCustomerFileMenuLink(
                `${resource.name}`,
                getCustomerFileStateFromStateName(CustomerFileState.REPORT_TO_PROCESS)
              )}
              itemName="CR à traiter"
              icon={<LibraryBooks />}
              matchWithQueryParams
              numberDisplayed={fileStats.reportToProcess}
            />
            <MurfySubMenuItem
              itemName="Commande de pièces"
              link={getCustomerFileMenuLink(
                `${resource.name}`,
                getCustomerFileStateFromStateName(CustomerFileState.AWAITING_SPARE_PART_ORDER)
              )}
              icon={<StoreIcon />}
              matchWithQueryParams
              numberDisplayed={fileStats.awaitingSparePart}
            />
            <MurfySubMenuItem
              link={getCustomerFileMenuLink(
                `${resource.name}`,
                getCustomerFileStateFromStateName(CustomerFileState.PREQUAL_REPORT_TO_PROCESS)
              )}
              itemName="CR de prequal à traiter"
              icon={<LibraryBooks />}
              matchWithQueryParams
              numberDisplayed={fileStats.prequalReportToProcess}
            />
            <MurfySubMenuItem
              link={getCustomerFileMenuLink(
                `${resource.name}`,
                getCustomerFileStateFromStateName(CustomerFileState.REQUEST_FOR_PLANNING_AFTER_SALES)
              )}
              itemName="À planifier - Réouverture RD & SAV RECO"
              icon={<LibraryBooks />}
              matchWithQueryParams
              numberDisplayed={fileStats.requestForPlanning}
            />
          </MurfySubMenu>
        ),
      "customer-files-to-process": () => (
        <MurfySubMenu
          link={getCustomerFilesToProcessMenuLink(resource.name)}
          key={resource.name}
          isOpen={isLinkActive(resource.name)}
          name={(resource.options && resource.options.label) || resource.name}
          icon={resource.icon ? <resource.icon /> : <DefaultIcon />}
          size="large"
        >
          <MurfySubMenu
            link={getCustomerFilesToProcessMenuLink(`${resource.name}`, REPORT_REVIEW)}
            key={resource.name}
            isOpen={location.search.includes("repair_report_check_state")}
            name="CR à vérifier"
            icon={<StyledIcon component={ChromeReaderModeIcon} size="medium" />}
            size="medium"
          />
          <MurfySubMenu
            link={getCustomerFilesToProcessMenuLink(`${resource.name}`, INTERVENTION_PREPARATION, TASK_STATE_TODO)}
            key={resource.name}
            isOpen={location.search.includes("preparation_visit_task_state")}
            name={"Préparation d'intervention"}
            icon={<StyledIcon component={InsertInvitationIcon} size="medium" />}
            size="medium"
          >
            <MurfySubMenu
              link={getCustomerFilesToProcessMenuLink(`${resource.name}`, INTERVENTION_PREPARATION, TASK_STATE_TODO)}
              isOpen={
                location.search.includes("preparation_visit_task_state") && location.search.includes(TASK_STATE_TODO)
              }
              name="À faire"
              icon={<StyledIcon component={FiberManualRecordIcon} size="small" />}
              size="small"
            />
            <MurfySubMenu
              link={getCustomerFilesToProcessMenuLink(`${resource.name}`, INTERVENTION_PREPARATION, TASK_STATE_ON_HOLD)}
              isOpen={
                location.search.includes("preparation_visit_task_state") && location.search.includes(TASK_STATE_ON_HOLD)
              }
              name="En attente"
              icon={<StyledIcon component={FiberManualRecordIcon} size="small" />}
              size="small"
            />
            <MurfySubMenu
              link={getCustomerFilesToProcessMenuLink(`${resource.name}`, INTERVENTION_PREPARATION, TASK_STATE_DONE)}
              isOpen={
                location.search.includes("preparation_visit_task_state") && location.search.includes(TASK_STATE_DONE)
              }
              name="Terminé"
              icon={<StyledIcon component={FiberManualRecordIcon} size="small" />}
              size="small"
            />
          </MurfySubMenu>
        </MurfySubMenu>
      ),
      "workshop-files": () => (
        <MurfySubMenu
          link={getWorkshopFileMenuLink(resource.name, {})}
          key={resource.name}
          isOpen={isLinkActive(resource.name)}
          name={(resource.options && resource.options.label) || resource.name}
          icon={resource.icon ? <resource.icon /> : <DefaultIcon />}
        >
          {hasPerm(permissions, ["*"], [ISAHIT_GROUP]) && (
            <WorkshopFileSubMenuItem
              itemName="Tous mes dossiers"
              filter={{}}
              icon={<AllInboxIcon />}
              matchWithQueryParams
            />
          )}
          <WorkshopFileSubMenuItem
            itemName="Photos à traiter"
            filter={{
              task: [WorkshopTaskTypes.PHOTO_POST_PROCESSING],
              location_usage: [LOCATION_USAGE_FINISHED],
            }}
            matchWithQueryParams
            icon={<AddPhotoAlternateIcon />}
          />
          <WorkshopFileSubMenuItem
            itemName="Caractéristiques à remplir"
            filter={{
              task: [WorkshopTaskTypes.PRODUCT_DATA_FILLING],
              location_usage: [LOCATION_USAGE_FINISHED],
            }}
            matchWithQueryParams
            icon={<LibraryBooks />}
          />
          <WorkshopFileSubMenuItem
            itemName="Vérification pré publication"
            filter={{
              task: [WorkshopTaskTypes.PRE_PUBLISHING_CHECK],
              task_exclude: [WorkshopTaskTypes.PHOTO_POST_PROCESSING, WorkshopTaskTypes.PRODUCT_DATA_FILLING],
              location_usage: [LOCATION_USAGE_FINISHED],
            }}
            matchWithQueryParams
            icon={<CheckBoxIcon />}
          />
          <WorkshopFileSubMenuItem
            itemName="Prêt à mettre en ligne"
            filter={{ publishable: true }}
            matchWithQueryParams
            icon={<PublishIcon />}
          />
          {hasPerm(permissions, ["*"], [ISAHIT_GROUP]) && (
            <MurfySubMenuItem
              itemName="Demande de pièces"
              link={getWorkshopFileMenuLink(`${resource.name}/${SKU_DEMANDS}`, {})}
              icon={<ScheduleIcon />}
            />
          )}
          {hasPerm(permissions, ["*"], [ISAHIT_GROUP]) && (
            <MurfySubMenuItem
              itemName="Imports/Exports de fichiers"
              link={getWorkshopFileMenuLink(`${resource.name}/${MARKETPLACE_EXPORT}`, {})}
              icon={<ShareIcon />}
            />
          )}
        </MurfySubMenu>
      ),
      "sku-demand": () => "",
      "spare-parts": () => "",
      "package-price": () =>
        hasPerm(permissions, [OFFER_ADMIN_GROUP]) && (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={(resource.options && resource.options.label) || resource.name}
            leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
            onClick={onMenuClick}
            classes={classes}
          />
        ),
    };

    return (
      menuItems[resource.name] ||
      (() =>
        hasPerm(permissions, ["*"], [ISAHIT_GROUP]) &&
        resource.hasList && (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={(resource.options && resource.options.label) || resource.name}
            leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
            onClick={onMenuClick}
            classes={classes}
          />
        ))
    )();
  };

  const renderAdditionalMenuItems = () =>
    hasPerm(permissions, ["*"], [ISAHIT_GROUP]) && (
      <>
        <MenuItemLink
          to={ADMIN_SHIPPING_ROUTE}
          primaryText="Transport"
          leftIcon={<LocalShippingIcon />}
          onClick={onMenuClick}
          classes={classes}
        />
        <MenuItemLink
          to={ADMIN_INTERVENTION_ROUTE}
          primaryText="Interventions"
          leftIcon={<CalendarToday />}
          onClick={onMenuClick}
          classes={classes}
        />
        <MenuItemLink
          to={ADMIN_SKU_STORE_ROUTE}
          primaryText="Magasin de pièces"
          leftIcon={<StoreIcon />}
          onClick={onMenuClick}
          classes={classes}
        />
        {hasPerm(permissions, [WORKSHOP_ADMIN_GROUP, DEVELOPER_GROUP]) && (
          <MenuItemLink
            to={ADMIN_WORKSHOPS_ROUTE}
            primaryText="Ateliers"
            leftIcon={<HomeOutlinedIcon />}
            onClick={onMenuClick}
            classes={classes}
          />
        )}
        <MenuItemLink
          to={ADMIN_CMS_ROUTE}
          primaryText="Edition Base de Co"
          leftIcon={<Explore />}
          onClick={onMenuClick}
          classes={classes}
        />
        <MenuItemLink
          to={ADMIN_PRODUCT_MODELS_ROUTE}
          primaryText="Expertise technique"
          leftIcon={<BookIcon />}
          onClick={onMenuClick}
          classes={classes}
        />
        <MurfyMenuDivider />
        <MenuItemLink
          to={ADMIN_ACCOUNT_ROUTE}
          primaryText="Admin"
          leftIcon={<SettingsApplications />}
          onClick={onMenuClick}
          classes={classes}
        />
        {hasPerm(permissions, [DEVELOPER_GROUP]) && (
          <MenuItemLink
            to={ADMIN_PRODUCTS_ROUTE}
            primaryText="Produits"
            leftIcon={<BuildIcon />}
            onClick={onMenuClick}
            classes={classes}
          />
        )}
      </>
    );

  return (
    loaded && (
      <div>
        {resources.map(renderResourceMenuItem)}
        {renderAdditionalMenuItems()}
        <Responsive small={logout} medium={null} />
      </div>
    )
  );
}

const mapStateToProps = (state) => ({
  location: state.router.location,
  resources: getResources(state),
  customerFilesStates: state.customerFiles.customerFilesStates,
  account: state.account.account,
});

export { MurfyMenu as TestableMurfyMenu };
export default withRouter(connect(mapStateToProps)(withStyles(murfyMenuStyle)(MurfyMenu)));
