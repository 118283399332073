import React from "react";

import { AutocompleteArrayInput, SimpleForm } from "react-admin";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Create from "@material-ui/icons/Create";

const assignedEmployeesModalStyles = (theme) => ({
  pen: {
    width: "24px",
    height: "24px",
  },
  iconButtonStyle: {
    marginRight: "20px",
  },
  textField: {
    marginLeft: "theme.spacing(2)",
    marginRight: "theme.spacing(2)",
    width: "100px",
  },
  dialogTitleStyle: {
    width: "686px",
    height: "72px",
    background: "#F7B349",
    color: "#FFF",
  },
  titleStyle: {
    position: "absolute",
    height: "18px",
    left: "48px",
    top: "26px",
    color: "#FFF",
    lineHeight: "18px",
  },
  autocompleteStyle: {
    marginTop: "43px",
    marginLeft: "48px",
  },
  cancelButtonStyle: {
    width: "135px",
    height: "48px",
    marginTop: "20px",
    marginLeft: "443px",
    color: "#8795B3",
  },
  submitButtonStyle: {
    width: "95px",
    height: "48px",
    marginTop: "20px",
    marginLeft: "27px",
    color: "#F7B349",
  },
  popperStyle: {
    zIndex: theme.zIndex.modal,
  },
  quoteModalError: {
    color: "red",
    marginLeft: "24px",
  },
});

const StyledDialog = withStyles({
  paper: {
    width: "686px",
    height: "283px",
    borderRadius: "16px",
  },
})(Dialog);

export class EmployeesAssignmentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isErrorDisplayed: false,
      assignedEmployeesIds: [],
    };
  }

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
      isErrorDisplayed: false,
    });
  };

  handleChange = (values) => {
    this.setState({ assignedEmployeesIds: values });
  };

  handleSubmit = (values) => {
    if (this.state.assignedEmployeesIds.length >= 1) {
      this.props.updateCustomerFileDetail(this.props.record.id, {
        assigned_employees: this.state.assignedEmployeesIds,
      });
      this.setState({
        open: false,
        isErrorDisplayed: false,
      });
    } else {
      this.setState({ isErrorDisplayed: true });
    }
  };

  render() {
    const { record = {}, classes } = this.props;
    const fullName = (employee) =>
      `${employee.first_name || "Prénom manquant"} ${
        employee.last_name ? employee.last_name.toUpperCase() : "Nom manquant"
      }`;
    return (
      <div>
        <IconButton onClick={this.handleClickOpen} className={classes.iconButtonStyle}>
          <Create className={classes.pen} />
        </IconButton>
        <StyledDialog onClose={this.handleClose} maxWidth={false} open={this.state.open}>
          <DialogTitle className={classes.dialogTitleStyle} disableTypography>
            <Typography variant="h1" className={classes.titleStyle}>
              Assigner le dossier
            </Typography>
          </DialogTitle>
          <DialogContent>
            <SimpleForm record={record} resource="customer-file" toolbar={null}>
              <AutocompleteArrayInput
                choices={this.props.employees}
                label="Assigné à"
                source="assigned_employees"
                onChange={this.handleChange}
                translateChoice={false}
                optionText={fullName}
                options={{
                  placeholder: "Ajouter une personne",
                  suggestionsContainerProps: {
                    className: classes.popperStyle,
                  },
                }}
              />
            </SimpleForm>

            {this.state.isErrorDisplayed ? (
              <span className={classes.quoteModalError}>Erreur : Merci de sélectionner au moins une personne.</span>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button className={classes.cancelButtonStyle} onClick={this.handleClose} color="primary">
              ANNULER
            </Button>
            <Button
              id="submitButton"
              className={classes.submitButtonStyle}
              onClick={this.handleSubmit}
              color="primary"
              autoFocus
            >
              VALIDER
            </Button>
          </DialogActions>
        </StyledDialog>
      </div>
    );
  }
}

export default withStyles(assignedEmployeesModalStyles)(EmployeesAssignmentModal);
