import { CRUD_UPDATE_SUCCESS, FETCH_END, UPDATE } from "react-admin";

import findIndex from "lodash/findIndex";
import unionBy from "lodash/unionBy";

import { addOrReplaceById } from "utils/addOrReplace";

export const changeStatusSparePart = (idSparePart, newStatus, customerFile = {}, storeStatusSparePartAction) => ({
  type: "CRUD_UPDATE",
  payload: { id: idSparePart, data: { state: newStatus } },
  meta: {
    resource: "murfy-erp/spare-part",
    fetch: UPDATE,
    redirectTo: false,
    onSuccess: {
      callback: ({ payload }) => {
        storeStatusSparePartAction(payload.data, customerFile);
      },
    },
  },
});

export const storeStatusAction = (payload, customerFile) => {
  let newCustomerFile = customerFile;
  const index = findIndex(customerFile.quotes, { id: payload.quote });
  const quote = { ...customerFile.quotes[index] };
  quote.spare_parts = addOrReplaceById(customerFile.quotes[index].spare_parts, payload.id, "state", payload.state);
  newCustomerFile = { ...customerFile, quotes: unionBy([quote], customerFile.quotes, "id") };

  return {
    type: CRUD_UPDATE_SUCCESS,
    payload: { id: customerFile.id, data: { ...newCustomerFile } },
    meta: {
      resource: "customer-file",
      fetchResponse: UPDATE,
      fetchStatus: FETCH_END,
      redirectTo: false,
    },
  };
};
