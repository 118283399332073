import * as React from "react";
import { useState } from "react";

import { Button } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import CreateDeliveryDialog from "./CreateDeliveryDialog";

const CreateDelivery = ({ order }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setIsOpen(true)} startIcon={<AddCircleIcon />}>
        Reprogrammer une livraison
      </Button>
      {isOpen && <CreateDeliveryDialog order={order} isOpen={isOpen} setIsOpen={setIsOpen} />}
    </>
  );
};

export default CreateDelivery;
