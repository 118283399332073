import React from "react";

import { SelectInput, required } from "react-admin";
import { SimpleForm } from "react-admin";

import { Box } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

import { SimpleValidateToolbar } from "components/FormToolbars";
import Modal from "components/Modal";

import { useCancellationFees } from "utils/api";
import { formatPriceCompact } from "utils/formatting";

const useStyles = makeStyles({
  modalContainer: {
    margin: "auto",
    width: 588,
  },
});

const CancellationFeesModal = ({ isModalOpen, closeModal, handleSubmitCancellation, customerFileUuid }) => {
  const classes = useStyles();
  const cancellationFees = useCancellationFees(customerFileUuid);

  if (!cancellationFees) {
    return <CircularProgress color="primary" />;
  }
  const displayPackagePrice = (value) => `${formatPriceCompact(value.amount_withtax)} ${value.package_name}`;
  return (
    <Modal
      isOpen={isModalOpen}
      handleCloseModal={closeModal}
      modalClassName={`modal-small-content ${classes.modalContainer}`}
    >
      <Box>
        <Box justifyContent="center" display="flex" width="100%">
          <Typography variant="h2">Annuler le rendez-vous</Typography>
        </Box>
        <SimpleForm
          submitOnEnter={false}
          redirect={false}
          toolbar={
            <SimpleValidateToolbar
              onSave={handleSubmitCancellation}
              size="large"
              label="Annuler le RDV"
              version="center"
            />
          }
          initialValues={{ cancellation_fee_id: null }}
        >
          <Box display="flex" width="100%">
            <Box width="350px" marginLeft="16px">
              <SelectInput
                source="cancellation_fee_id"
                label="Forfait annulation"
                choices={cancellationFees.filter((fee) => fee.amount_withtax > 0)}
                fullWidth
                optionText={displayPackagePrice}
                validate={[required()]}
              />
            </Box>
          </Box>
        </SimpleForm>
      </Box>
    </Modal>
  );
};

export default CancellationFeesModal;
