import React from "react";

import { AutocompleteInput, NumberInput, TextInput } from "react-admin";

import { Box, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import RemoveCircleIcon from "@material-ui/icons/Clear";

import { useForm, useFormState } from "react-final-form";

import ProductModelAttributes from "components/ProductModelAttributes";

const useSmallBodyStyles = makeStyles((theme) => ({
  root: {
    fontWeight: "600",
    color: theme.palette.secondary.contrastText,
  },
  gridBox: {
    display: "inline-flex",
    "flex-wrap": "wrap",
  },
  dimensionTitle: {
    display: "flex",
  },
  dimensionBox: {
    width: "192px",
    marginTop: "19px",
    marginLeft: "12px",
    marginRight: "12px",
  },
  regularBox: {
    width: "300px",
    marginLeft: "12px",
    marginRight: "12px",
  },
}));

const ProductFunctionalCharacteristicsInput = (props) => {
  const classes = useSmallBodyStyles();
  const { productsNomenclature } = props;

  const { change } = useForm();
  const {
    values: { product_type: ProductType },
  } = useFormState("product_type", { subscription: { product_type: true } });
  const productPublished = props.record && props.record.is_online;

  const getCharacterisitcs = (type) => {
    const product = productsNomenclature.find((product) => product.name === type);
    if (product) {
      return product.functionalCharacteristics;
    } else {
      return [];
    }
  };

  const getEnergyClassName = (characteristic) => {
    const displayName =
      characteristic.name === "Classe énergétique" ? "Ancienne classe énergétique" : characteristic.name;
    return displayName;
  };
  const getEnergyClassDisableStatus = (characteristic) => {
    const oneFieldRestriction =
      (props.record.functional_characteristics &&
        characteristic.name === "Classe énergétique" &&
        props.record.functional_characteristics["Nouvelle classe énergétique"]) ||
      (props.record.functional_characteristics &&
        characteristic.name === "Nouvelle classe énergétique" &&
        props.record.functional_characteristics["Classe énergétique"]);
    return productPublished || oneFieldRestriction;
  };

  const resetCharacteristic = (characteristic) => {
    change(`functional_characteristics.${characteristic.name}`, undefined);
  };

  const renderEnergyClassInputs = () => {
    return (
      <Box width="100%" display="flex">
        {getCharacterisitcs(ProductType)
          .filter((characteristic) => characteristic.name.includes("énergétique"))
          .map((characteristic, index) => (
            <Box className={classes.regularBox} display="flex" key={characteristic.name}>
              <AutocompleteInput
                source={`functional_characteristics.${characteristic.name}`}
                label={getEnergyClassName(characteristic)}
                InputProps={{ disableUnderline: true }}
                choices={characteristic.choices}
                optionText="name"
                optionValue="name"
                options={{ disabled: getEnergyClassDisableStatus(characteristic) }}
                fullWidth
              />
              <Box marginBottom={3} alignSelf="center">
                <IconButton onClick={() => resetCharacteristic(characteristic)}>
                  <RemoveCircleIcon />
                </IconButton>
              </Box>
            </Box>
          ))}
      </Box>
    );
  };

  const renderDimensions = () => {
    return getCharacterisitcs(ProductType)
      .filter((characteristic) => characteristic.name.includes("Dimensions"))
      .map((characteristic, index) => (
        <React.Fragment key={`characteristic ${index + 1}`}>
          <Box width="192px" marginLeft="12px" marginRight="12px" key={`${characteristic.name}.L`}>
            <Typography variant="body2" className={classes.dimensionTitle}>
              {characteristic.name}
            </Typography>
            <NumberInput
              source={`functional_characteristics.${characteristic.name}.L`}
              label="Largeur (en cm)"
              InputProps={{ disableUnderline: true }}
              disabled={productPublished}
              fullWidth
            />
          </Box>
          <Box className={classes.dimensionBox} key={`${characteristic.name}.H`}>
            <NumberInput
              source={`functional_characteristics.${characteristic.name}.H`}
              label="Hauteur (en cm)"
              InputProps={{ disableUnderline: true }}
              disabled={productPublished}
              fullWidth
            />
          </Box>
          <Box className={classes.dimensionBox} key={`${characteristic.name}.P`}>
            <NumberInput
              source={`functional_characteristics.${characteristic.name}.P`}
              label="Profondeur (en cm)"
              InputProps={{ disableUnderline: true }}
              disabled={productPublished}
              fullWidth
            />
          </Box>
        </React.Fragment>
      ));
  };

  return (
    <>
      <ProductModelAttributes record={props.record} productType={ProductType} />
      <Typography variant="body2" color="secondary.contrastText" className={classes.root}>
        Fonctionnelles
      </Typography>
      <Box className={classes.gridBox}>
        {renderDimensions()}
        {renderEnergyClassInputs()}
        {getCharacterisitcs(ProductType) &&
          getCharacterisitcs(ProductType)
            .filter((item) => !item.name.includes("Dimensions") && !item.name.includes("énergétique"))
            .map((characteristic, index) => (
              <React.Fragment key={`characteristic ${index + 1}`}>
                {characteristic.choices ? (
                  <Box className={classes.regularBox} key={ProductType}>
                    <AutocompleteInput
                      source={`functional_characteristics.${characteristic.name}`}
                      label={characteristic.name}
                      InputProps={{ disableUnderline: true }}
                      choices={characteristic.choices}
                      optionText="name"
                      optionValue="name"
                      options={{ disabled: productPublished }}
                      fullWidth
                    />
                  </Box>
                ) : (
                  <Box className={classes.regularBox} key={ProductType}>
                    {characteristic.name.includes("Capacité") ||
                    characteristic.name.includes("Poids") ||
                    characteristic.name.includes("Niveau sonore") ? (
                      <NumberInput
                        source={`functional_characteristics.${characteristic.name}`}
                        label={characteristic.name}
                        InputProps={{ disableUnderline: true }}
                        disabled={productPublished}
                        fullWidth
                      />
                    ) : (
                      <TextInput
                        source={`functional_characteristics.${characteristic.name}`}
                        label={characteristic.name}
                        InputProps={{ disableUnderline: true }}
                        disabled={productPublished}
                        fullWidth
                      />
                    )}
                  </Box>
                )}
              </React.Fragment>
            ))}
      </Box>
    </>
  );
};

export default ProductFunctionalCharacteristicsInput;
