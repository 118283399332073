import React, { Component } from "react";

import { Query } from "react-admin";

import { PropTypes } from "prop-types";

import { Box, Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { Button } from "admin/components";

import ColorChip from "components/atoms/ColorChip";

import UserDiagnostic from "pages/Tickets/components/UserDiagnostic";
import { newListRepairReportLabel } from "pages/customerFiles/components/CustomerFileRepairReports/components/ListRepairReportLabel.js";

import LeadOpinion from "./LeadOpinion";
import PaymentComponent from "./PaymentComponent";
import "./style.css";
import { interventionImpossibleReasonArray, notRepairedReasonArray, renderExplanation } from "./utils";

export const longInterventionReasonsArray = [
  { id: "multiple_products", name: "Double appareil" },
  { id: "recessed_appliance", name: "Appareil encastré" },
  { id: "contrained_environment", name: "Environnement contraint" },
  { id: "hard_spare_part_allocation", name: "Pose de piece fastidieuse" },
  { id: "other", name: "Autre" },
];

export default class Details extends Component {
  static propTypes = {
    checkoutPayment: PropTypes.arrayOf(
      PropTypes.shape({
        payment_id: PropTypes.string,
      })
    ),
    createIncentiveSystem: PropTypes.func.isRequired,
    patchTicketAdminComments: PropTypes.func.isRequired,
    patchTicketEmail: PropTypes.func.isRequired,
    patchIsPaymentDone: PropTypes.func,
    repairman: PropTypes.number,
    ticketAdminComments: PropTypes.string,
    ticketAttachments: PropTypes.arrayOf(PropTypes.object),
    ticketEmail: PropTypes.string.isRequired,
    ticketFields: PropTypes.arrayOf(
      PropTypes.shape({
        form_field: PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        }),
        value: PropTypes.string,
      })
    ),
    ticketId: PropTypes.number.isRequired,
    ticketUserDiagnostic: PropTypes.string,
    ticketIsPaymentDone: PropTypes.bool,
    ticketPaymentMethod: PropTypes.string.isRequired,
    updateTicket: PropTypes.func,
    isNewAdmin: PropTypes.bool,
    visit: PropTypes.object,
    getCustomerFileDetail: PropTypes.func,
    ticketPayload: PropTypes.object,
    visitPayment: PropTypes.object,
    customerFile: PropTypes.object,
    isWorkshopFile: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      adminComments: this.props.ticketAdminComments,
      email: this.props.ticketEmail,
      isEmailEditable: false,
      isReportCompliant: this.props.visit && this.props.visit.is_report_compliant,
      isStatusHot: this.props.visit ? this.props.visit.is_status_hot : false,
      repairReportLabel: this.props.visit && this.props.visit.label,
      labelModified: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visit !== this.props.visit) {
      this.setState({
        repairReportLabel: this.props.visit && this.props.visit.label,
      });
    }
  }

  renderFormFields = () =>
    this.props.ticketFields.map((field) => (
      <div key={field.form_field.id} className="col-12">
        <span className="ticket-detail ticket-detail-key"> {field.form_field.name} : </span>
        <span className="ticket-detail ticket-detail-value"> &nbsp;{field.value} </span>
      </div>
    ));

  renderHistoryFields = (userHistoryEntries) =>
    userHistoryEntries.map((history, index) => (
      <li key={index}>
        {history.touchedElements ? (
          <li key={history.stepId} className="col-12">
            <b title={history.stepId}>{history.stepQuestion} : </b>
            {typeof history.touchedElements === "object"
              ? JSON.stringify([history.touchedElements])
              : history.touchedElements}
          </li>
        ) : (
          <>
            <b>Élement {index}</b>
            {history &&
              history.map((step) => (
                <li key={step.stepId} className="col-12">
                  <b title={step.stepId}>{step.stepQuestion} : </b>
                  {typeof step.touchedElements === "object"
                    ? JSON.stringify([step.touchedElements])
                    : step.touchedElements}
                </li>
              ))}
          </>
        )}
      </li>
    ));

  shouldRefreshInterface = () => {
    this.setState({ shouldRefreshInterface: true });
    setTimeout(async () => {
      this.setState({ shouldRefreshInterface: false });
    }, 300);
  };

  renderTicketPayloadProducts = (
    customerFile,
    product,
    index,
    nameplatePictures,
    additionalPictures,
    hermeticUnitPicture,
    isNumberOfProductOne,
    getCustomerFileDetail
  ) => (
    <>
      <Query type="getOne" resource="products" payload={{ id: product.product_id }}>
        {({ data }) => {
          let options = {};
          if (isNumberOfProductOne) {
            options = { expanded: true };
          }

          let modifiedValue = null; // In case a specific label has alredy been modified. For display purpose.
          if (this.state.labelModified) {
            const modifiedLabelObject = this.state.labelModified.filter((label) => label.id === product.product_id);
            modifiedValue = modifiedLabelObject && modifiedLabelObject[0] && modifiedLabelObject[0].label;
          }
          let labelQualificationProduct = this.state.repairReportLabel; // will differenciate the result depending of the visit.label shape
          if (
            this.state.repairReportLabel &&
            this.state.repairReportLabel.includes("id") &&
            this.state.repairReportLabel.includes("label")
          ) {
            labelQualificationProduct = JSON.parse(this.state.repairReportLabel).filter(
              (element) => element.id === parseInt(product.product_id)
            );
          }
          return (
            <>
              <ExpansionPanel className="ticket-detail-history-container" {...options}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="col-12">
                    <span className="ticket-detail ticket-detail-key">
                      Produit {index + 1}{" "}
                      {data &&
                      data.subcategory1 &&
                      data.subcategory1.length > 0 &&
                      (data.product_type === "Congélateur" || data.product_type === "Réfrigérateur")
                        ? `- ${data.subcategory1}`
                        : `- ${product.product_type}`}
                    </span>
                    {data && data.identification_plate && (
                      <Box>
                        <a href={data.identification_plate} target="_blank" rel="noopener noreferrer">
                          <ColorChip label="Plaque signalétique" color="turquoise" />
                        </a>
                      </Box>
                    )}
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div>
                    <div className="col-12">
                      <span className="ticket-detail ticket-detail-key"> &nbsp;&nbsp;• &nbsp; Produit : </span>
                    </div>
                    <div className="col-12">
                      <span className="ticket-detail ticket-detail-key-bullet-point">Produit concerné : </span>
                      <span className="ticket-detail ticket-detail-value">
                        {" "}
                        &nbsp; {data && data.brand} {data && data.model_reference}{" "}
                      </span>
                    </div>
                    <div className="col-12">
                      <span className="ticket-detail ticket-detail-key-bullet-point">Symptomes : </span>
                      <span className="ticket-detail ticket-detail-value"> &nbsp; {product.symptoms}</span>
                    </div>
                    <div className="col-12">
                      <span className="ticket-detail ticket-detail-key-bullet-point">Diagnostic : </span>
                      <span className="ticket-detail ticket-detail-value"> &nbsp; {product.diagnosis}</span>
                    </div>
                    <div className="col-12">
                      <span className="ticket-detail ticket-detail-key-bullet-point">
                        Utilisation du stock embarqué :{" "}
                      </span>
                      <span className="ticket-detail ticket-detail-value">
                        {" "}
                        &nbsp;{product.own_stock_spare_part_used ? "Oui" : "Non"}
                      </span>
                    </div>
                    {product.spare_parts_installs && (
                      <div className="col-12">
                        {product.spare_parts_installs.map((part) => (
                          <div key={part} className="col-12">
                            <span className="ticket-detail ticket-detail-value"> &nbsp;{part}</span>
                          </div>
                        ))}
                      </div>
                    )}
                    <div className="col-12">
                      <span className="ticket-detail ticket-detail-key-bullet-point">Pose de pièce(s) du devis : </span>
                      <span className="ticket-detail ticket-detail-value">
                        {" "}
                        &nbsp;
                        {product.which_spare_part_used && product.which_spare_part_used.length > 0 ? "Oui" : "Non"}
                      </span>
                    </div>
                    {product.which_spare_part_used && (
                      <div className="col-12">
                        {product.which_spare_part_used.map((part) => (
                          <div key={part} className="col-12">
                            <span className="ticket-detail ticket-detail-value"> &nbsp;{part}</span>
                          </div>
                        ))}
                      </div>
                    )}
                    <div className="col-12">
                      <span className="ticket-detail ticket-detail-key-bullet-point">
                        Besoin d'une commande de pièces ?
                      </span>
                      <span className="ticket-detail ticket-detail-value">
                        {" "}
                        &nbsp;{product.spare_parts_orders ? "Oui" : "Non"}
                      </span>
                    </div>
                    {product.spare_parts_orders && (
                      <>
                        <div className="col-12">
                          <span className="ticket-detail ticket-detail-key-bullet-point">Pièce(s) à commander : </span>
                          {product.spare_parts_orders.map(
                            (sparePartsOrder) =>
                              sparePartsOrder && (
                                <div className="col-12" key={sparePartsOrder.ref}>
                                  <span className="ticket-detail ticket-detail-value">
                                    {" "}
                                    &nbsp;{sparePartsOrder.name} ({sparePartsOrder.ref})
                                  </span>
                                </div>
                              )
                          )}
                        </div>
                      </>
                    )}
                    <br />
                    {product.hermetic_unit_order &&
                      product.hermetic_unit_order.length > 0 &&
                      product.hermetic_unit_order.map((hermeticUnit, index) => (
                        <React.Fragment key={`hermeticUnit ${index + 1}`}>
                          <div className="col-12">
                            <span className="ticket-detail ticket-detail-key">
                              {" "}
                              &nbsp;&nbsp;• &nbsp; Unité hermétique :{" "}
                            </span>

                            <div className="col-12">
                              <span className="ticket-detail ticket-detail-key-bullet-point">
                                {" "}
                                Diamètre HP sortie compresseur: &nbsp;{" "}
                              </span>
                              <span className="ticket-detail ticket-detail-value">
                                {hermeticUnit.hp_diameter_compressor_outlet}{" "}
                              </span>
                            </div>
                            <div className="col-12">
                              <span className="ticket-detail ticket-detail-key-bullet-point">
                                {" "}
                                Diamètre HP entrée déshydrateur: &nbsp;{" "}
                              </span>
                              <span className="ticket-detail ticket-detail-value">
                                {hermeticUnit.hp_diameter_inlet_dehydrator}{" "}
                              </span>
                            </div>
                            <div className="col-12">
                              <span className="ticket-detail ticket-detail-key-bullet-point">
                                {" "}
                                Diamètre capillaire: &nbsp;{" "}
                              </span>
                              <span className="ticket-detail ticket-detail-value">
                                {hermeticUnit.capillary_diameter}{" "}
                              </span>
                            </div>
                            <div className="col-12">
                              <span className="ticket-detail ticket-detail-key-bullet-point">
                                {" "}
                                Diamètre BP aspiration: &nbsp;{" "}
                              </span>
                              <span className="ticket-detail ticket-detail-value">
                                {hermeticUnit.diameter_bp_aspiration}{" "}
                              </span>
                            </div>

                            {hermeticUnitPicture.length > 0 &&
                              hermeticUnitPicture.some((picture) =>
                                picture.attachment.includes(product.product_id)
                              ) && (
                                <span className="ticket-detail ticket-detail-key-bullet-point">
                                  {" "}
                                  Plaque du compresseur :&nbsp;
                                </span>
                              )}
                            {hermeticUnitPicture &&
                              hermeticUnitPicture
                                .filter((picture) => picture.attachment.includes(product.product_id))
                                .map((attachment, index) => (
                                  <>
                                    <div key={`attachment ${index + 1}`} className="col-12">
                                      <a
                                        className="ticket-detail ticket-detail-value"
                                        href={attachment.attachment}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {`${this.getFilenameShortened(attachment.attachment)}`}
                                      </a>
                                    </div>
                                  </>
                                ))}
                          </div>
                          <br />
                        </React.Fragment>
                      ))}
                    <div className="col-12">
                      <span className="ticket-detail ticket-detail-key"> &nbsp;&nbsp; • &nbsp; Détails : </span>
                    </div>
                    {product.task_duration && (
                      <div className="col-12">
                        <span className="ticket-detail ticket-detail-key-bullet-point">Temps passé :&nbsp; </span>
                        <span className="ticket-detail ticket-detail-value">
                          {" "}
                          {product.task_duration && product.task_duration}min
                        </span>
                      </div>
                    )}
                    {product.additional_information && (
                      <div className="col-12">
                        <p className="ticket-detail ticket-detail-key-bullet-point">Infos complémentaires :&nbsp;</p>
                        <p className="ticket-detail ticket-detail-value">{product.additional_information}</p>
                      </div>
                    )}
                    {product.is_next_operation_long_duration && (
                      <div className="col-12">
                        <span className="ticket-detail ticket-detail-key"> Passage suivant : </span>
                        <span className="ticket-detail ticket-detail-value"> &nbsp; PASSAGE LONG</span>
                      </div>
                    )}
                    {product.next_operation_explanation &&
                    product.next_operation_explanation === "other" &&
                    product.next_operation_explanation_details ? (
                      <div className="col-12">
                        <span className="ticket-detail ticket-detail-key"> Justification : </span>
                        <span className="ticket-detail ticket-detail-value">
                          {product.next_operation_explanation_details}{" "}
                        </span>
                      </div>
                    ) : (
                      product.next_operation_explanation && (
                        <div className="col-12">
                          <span className="ticket-detail ticket-detail-key"> Justification : </span>
                          <span className="ticket-detail ticket-detail-value">
                            {product.next_operation_explanation &&
                              this.renderJustificationLongIntervention(product.next_operation_explanation)}
                          </span>
                        </div>
                      )
                    )}
                    {nameplatePictures.some((picture) => picture.attachment.includes(product.product_id)) ? (
                      <div className="col-12">
                        <p className="ticket-detail ticket-detail-key-bullet-point">
                          {" "}
                          Plaque(s) signalétique(s) :&nbsp;
                        </p>
                        {nameplatePictures &&
                          nameplatePictures
                            .filter((picture) => picture.attachment.includes(product.product_id))
                            .map((attachment, index) => (
                              <div key={`attachment ${index + 1}`}>
                                <a
                                  className="ticket-detail ticket-detail-value"
                                  href={attachment.attachment}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {`${this.getFilenameShortened(attachment.attachment)}`}
                                </a>
                              </div>
                            ))}
                      </div>
                    ) : null}
                    {product.not_repaired_reason && (
                      <div className="col-12">
                        <span className="ticket-detail ticket-detail-key-bullet-point">
                          Justification de non réparable :&nbsp;{" "}
                        </span>
                        <span className="ticket-detail ticket-detail-value">
                          {" "}
                          {renderExplanation(product.not_repaired_reason, notRepairedReasonArray)}
                        </span>
                      </div>
                    )}
                    {product.intervention_impossible_reason && (
                      <div className="col-12">
                        <span className="ticket-detail ticket-detail-key-bullet-point">
                          Justification d'inter impossible :&nbsp;{" "}
                        </span>
                        <span className="ticket-detail ticket-detail-value">
                          {" "}
                          {renderExplanation(product.intervention_impossible_reason, interventionImpossibleReasonArray)}
                        </span>
                      </div>
                    )}
                    {additionalPictures.some((picture) => picture.attachment.includes(product.product_id)) ? (
                      <div className="col-12">
                        <p className="ticket-detail ticket-detail-key-bullet-point"> Pièce(s) jointe(s) :&nbsp;</p>
                        {additionalPictures &&
                          additionalPictures
                            .filter((picture) => picture.attachment.includes(product.product_id))
                            .map((attachment, index) => (
                              <div key={`attachment ${index + 1}`}>
                                <a
                                  className="ticket-detail ticket-detail-value"
                                  href={attachment.attachment}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {`${this.getFilenameShortened(attachment.attachment)}`}
                                </a>
                              </div>
                            ))}
                      </div>
                    ) : null}

                    {!this.props.isWorkshopFile && !this.state.shouldRefreshInterface && (
                      <LeadOpinion
                        productId={parseInt(product.product_id)}
                        customerFile={customerFile}
                        getCustomerFileDetail={getCustomerFileDetail}
                        shouldRefreshInterface={this.shouldRefreshInterface}
                      />
                    )}
                    {this.state.shouldRefreshInterface && (
                      <Typography variant="body2">Mise à jour des vérifications de CRs</Typography>
                    )}

                    <div className="col-12 detail-label-visit-container">
                      <FormControl fullWidth>
                        <InputLabel id="repair-report-label">Qualification Passage</InputLabel>
                        <Select
                          className="detail-label-visit-select"
                          value={
                            modifiedValue ||
                            (labelQualificationProduct &&
                              labelQualificationProduct[0] &&
                              labelQualificationProduct[0].label) ||
                            labelQualificationProduct
                          }
                          onChange={(e) => this.handleRepairReportLabelChange(e, product.product_id)}
                        >
                          {newListRepairReportLabel.map((repairReportLabel) => {
                            return (
                              <MenuItem value={repairReportLabel.id} key={repairReportLabel.id}>
                                {repairReportLabel.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </>
          );
        }}
      </Query>
    </>
  );

  handleAdminCommentsChange = (event) => {
    this.setState({ adminComments: event.target.value });
  };

  handleEmailChange = (event) => {
    this.setState({ email: event.target.value });
  };

  handleAdminCommentsSave = (event) => {
    if (this.props.isNewAdmin) {
      this.props.updateTicket(this.props.ticketId, {
        admin_comments: this.state.adminComments,
        id: this.props.ticketId,
      });
    } else {
      this.props.patchTicketAdminComments(this.props.ticketId, this.state.adminComments);
    }
    event.preventDefault();
  };

  handleEmailSave = () => {
    if (this.props.isNewAdmin) {
      this.props.updateTicket(this.props.ticketId, {
        email: this.state.email,
        id: this.props.ticketId,
      });
    } else {
      this.props.patchTicketEmail(this.props.ticketId, this.state.email);
    }
    this.setState({ isEmailEditable: !this.state.isEmailEditable });
  };

  handleIsPaymentDoneStatus = () => {
    this.props.patchIsPaymentDone(this.props.ticketId, !this.props.ticketIsPaymentDone);
  };

  handleClickEditPencil = () => this.setState({ isEmailEditable: !this.state.isEmailEditable });

  getFilenameShortened = (url) => {
    const filename = url && url.substring(url.lastIndexOf("/") + 1);
    return filename;
  };

  handleRepairReportLabelChange = (event, productId) => {
    const choice = { id: productId, label: event.target.value };
    if (this.state.labelModified.findIndex((label) => label.id === productId) === -1) {
      // keeping track of the modified label
      this.setState({
        labelModified: [...this.state.labelModified, choice],
      });
    } else {
      // if already requalified, keeps track of the last modification
      const indexOfModifiedLabel = this.state.labelModified.findIndex((label) => label.id === productId);
      const labelTmp = this.state.labelModified;
      labelTmp[indexOfModifiedLabel] = choice;
      this.setState({
        labelModified: labelTmp,
      });
    }

    this.props.createIncentiveSystem(
      parseInt(productId),
      this.props.repairman,
      this.props.visit.id,
      event.target.value
    );
    this.props.getCustomerFileDetail();
  };

  handleCheckCompliantReport = (event) => {
    this.setState({ isReportCompliant: !event.target.checked });
    this.props.createIncentiveSystem(
      null,
      this.props.repairman,
      this.props.visit.id,
      event.target.checked ? "non_compliant" : "compliant",
      "report_compliance"
    );
    this.props.getCustomerFileDetail();
  };

  handleCheckHotStatus = (event) => {
    this.setState({ isStatusHot: event.target.checked });
    this.props.createIncentiveSystem(
      null,
      this.props.repairman,
      this.props.visit.id,
      event.target.checked ? "hot_visit" : "non_hot_visit",
      "hot_status"
    );
    this.props.getCustomerFileDetail();
  };

  renderJustificationLongIntervention = (explanation) => {
    const reasonLabel = longInterventionReasonsArray.filter((reason) => reason.id === explanation);
    return reasonLabel[0] && reasonLabel[0].name;
  };

  render() {
    const nameplatePictures =
      (this.props.ticketAttachments &&
        this.props.ticketAttachments.length > 0 &&
        this.props.ticketAttachments.filter((picture) => picture.tag_identifier === "nameplate")) ||
      [];
    const additionalPictures =
      (this.props.ticketAttachments &&
        this.props.ticketAttachments.length > 0 &&
        this.props.ticketAttachments.filter(
          (picture) => picture.tag_identifier === "attachment" || picture.tag_identifier === null
        )) ||
      [];
    const hermeticUnitPicture =
      (this.props.ticketAttachments &&
        this.props.ticketAttachments.length > 0 &&
        this.props.ticketAttachments.filter((picture) => picture.tag_identifier === "hermetic_unit")) ||
      [];
    const longInterventionJustificationPicture =
      (this.props.ticketAttachments &&
        this.props.ticketAttachments.length > 0 &&
        this.props.ticketAttachments.filter(
          (picture) => picture.tag_identifier === "long_intervention_justification"
        )) ||
      [];

    return (
      <div className="row no-gutters">
        {!this.props.ticketPayload && (
          <>
            {this.state.isEmailEditable ? (
              <div className="col-12">
                <span className="ticket-detail ticket-detail-key"> Adresse mail : </span>
                <input
                  className="ticket-detail-input"
                  onChange={this.handleEmailChange}
                  type="text"
                  value={this.state.email}
                />
                <span>
                  <i className="fas fa-check ticket-detail-ico" onClick={this.handleEmailSave} />
                </span>
              </div>
            ) : (
              <div className="col-12">
                <span className="ticket-detail ticket-detail-key"> Adresse mail : </span>
                <span className="ticket-detail ticket-detail-value"> &nbsp;{this.props.ticketEmail} </span>
                <span>
                  <i className="fas fa-pencil ticket-detail-ico" onClick={this.handleClickEditPencil} />
                </span>
              </div>
            )}
            <div className="col-12">
              <span className="ticket-detail ticket-detail-key"> Parcours utilisateur : </span>
              <UserDiagnostic ticketUserDiagnostic={this.props.ticketUserDiagnostic} />
            </div>
          </>
        )}
        {this.renderFormFields()}
        {!this.props.ticketPayload ? (
          <>
            {!this.props.isNewAdmin && (
              <>
                <div className="details-title">
                  <i className="fas fa-money-bill-alt ico ico-large" /> Règlement
                  <br />
                </div>
                <div className="col-12">
                  <span className="ticket-detail ticket-detail-key">Passage réglé ? </span>
                  <span className="ticket-detail ticket-detail-value">
                    {" "}
                    &nbsp;{this.props.ticketIsPaymentDone ? "Oui" : "Non"}
                  </span>
                </div>
                {this.props.ticketIsPaymentDone && (
                  <div className="col-12">
                    <span className="ticket-detail ticket-detail-key"> Moyen de paiement: </span>
                    <span className="ticket-detail ticket-detail-value"> &nbsp; {this.props.ticketPaymentMethod} </span>
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <PaymentComponent
              payment={this.props.ticketPayload.payload.payment}
              visitPayment={this.props.visitPayment}
            />
          </>
        )}
        {this.props.ticketPayload && this.props.ticketPayload.payload.products && (
          <>
            <div className="details-title col-12">
              <br />
              <br />
              <i className="fas fa-mobile-alt ico ico-large" /> Produits
            </div>
            <div className="products-container col-12">
              {this.props.ticketPayload.payload.products.map((product, index) => (
                <div className="product-details" key={`${product.product_id} ${index + 1}`}>
                  {this.renderTicketPayloadProducts(
                    this.props.customerFile,
                    product,
                    index,
                    nameplatePictures,
                    additionalPictures,
                    hermeticUnitPicture,
                    this.props.ticketPayload.payload.products.length === 1,
                    this.props.getCustomerFileDetail
                  )}
                </div>
              ))}
            </div>
          </>
        )}
        {this.props.ticketAttachments && this.props.ticketAttachments.length > 0 && (
          <div className="col-12">
            {nameplatePictures.length > 0 && (
              <p className="ticket-detail ticket-detail-key"> Toutes les plaques signalétiques :&nbsp;</p>
            )}
            {nameplatePictures &&
              nameplatePictures.map((attachment, index) => (
                <React.Fragment key={`attachment ${index + 1}`}>
                  <div key={`attachment ${index + 1}`}>
                    <a
                      className="ticket-detail ticket-detail-value"
                      href={attachment.attachment}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {`${this.getFilenameShortened(attachment.attachment)}`}
                    </a>
                  </div>
                </React.Fragment>
              ))}
            {additionalPictures.length > 0 && (
              <p className="ticket-detail ticket-detail-key"> Toutes les pièces jointes :&nbsp;</p>
            )}
            {additionalPictures &&
              additionalPictures.map((attachment, index) => (
                <React.Fragment key={`attachment ${index + 1}`}>
                  <div key={`attachment ${index + 1}`}>
                    <a
                      className="ticket-detail ticket-detail-value"
                      href={attachment.attachment}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {`${this.getFilenameShortened(attachment.attachment)}`}
                    </a>
                  </div>
                </React.Fragment>
              ))}
            {hermeticUnitPicture.length > 0 && (
              <p className="ticket-detail ticket-detail-key"> Toutes les unités hermétiques :&nbsp;</p>
            )}
            {hermeticUnitPicture &&
              hermeticUnitPicture.map((attachment, index) => (
                <React.Fragment key={`attachment ${index + 1}`}>
                  <div key={`attachment ${index + 1}`}>
                    <a
                      className="ticket-detail ticket-detail-value"
                      href={attachment.attachment}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {`${this.getFilenameShortened(attachment.attachment)}`}
                    </a>
                  </div>
                </React.Fragment>
              ))}
          </div>
        )}
        {this.props.checkoutPayment.length > 0 && (
          <div className="col-12">
            <p className="ticket-detail ticket-detail-key"> Lien de paiement :&nbsp;</p>
            <a
              id="checkout-payment"
              className="ticket-detail ticket-detail-value"
              href={`https://murfy.fr/portal/paiement-murfy/${this.props.checkoutPayment[0].payment_id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Cliquer ici
            </a>
          </div>
        )}
        {this.props.ticketPayload &&
          this.props.ticketPayload.payload &&
          this.props.ticketPayload.payload.is_next_operation_long_duration === true && (
            <div className="col-12">
              <span className="ticket-detail ticket-detail-key"> Passage suivant : </span>
              <span className="ticket-detail ticket-detail-value"> &nbsp; PASSAGE LONG</span>
            </div>
          )}
        {this.props.ticketPayload &&
        this.props.ticketPayload.payload &&
        this.props.ticketPayload.payload.next_operation_explanation &&
        this.props.ticketPayload.payload.next_operation_explanation === "other" &&
        this.props.ticketPayload.payload.next_operation_explanation_details ? (
          <div className="col-12">
            <span className="ticket-detail ticket-detail-key"> Justification : </span>
            <span className="ticket-detail ticket-detail-value">
              {" "}
              {this.props.ticketPayload.payload.next_operation_explanation_details}{" "}
            </span>
          </div>
        ) : (
          this.props.ticketPayload &&
          this.props.ticketPayload.payload &&
          this.props.ticketPayload.payload.next_operation_explanation && (
            <div className="col-12">
              <span className="ticket-detail ticket-detail-key"> Justification : </span>
              <span className="ticket-detail ticket-detail-value">
                {this.props.ticketPayload.payload.next_operation_explanation &&
                  this.renderJustificationLongIntervention(this.props.ticketPayload.payload.next_operation_explanation)}
              </span>
            </div>
          )
        )}
        {longInterventionJustificationPicture && longInterventionJustificationPicture.length > 0 && (
          <p className="ticket-detail ticket-detail-key"> Justification de passage long :&nbsp;</p>
        )}
        {longInterventionJustificationPicture &&
          longInterventionJustificationPicture.map((attachment, index) => (
            <>
              <div key={`attachment ${index + 1}`}>
                <a
                  className="ticket-detail ticket-detail-value"
                  href={attachment.attachment}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {`${this.getFilenameShortened(attachment.attachment)}`}
                </a>
              </div>
            </>
          ))}

        <div className="col-12 ticket-detail-comments-container">
          <p className="ticket-detail ticket-detail-key"> Commentaires :</p>
          <textarea
            className="ticket-detail-comments"
            value={this.state.adminComments}
            onChange={this.handleAdminCommentsChange}
          />
          <div className="ticket-detail-button-container">
            <Button clickHandler={this.handleAdminCommentsSave} text="Enregistrer" />
          </div>
        </div>
        {this.props.ticketPayload &&
          this.props.ticketPayload.payload &&
          this.props.ticketPayload.payload.user_history &&
          this.props.ticketPayload.payload.user_history.length > 0 && (
            <ExpansionPanel className="ticket-detail-history-container">
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <span className="ticket-detail ticket-detail-key">Détail brut de la saisie du CR</span>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <ul>{this.renderHistoryFields(this.props.ticketPayload.payload.user_history)}</ul>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )}
        <div className="col-12 detail-label-visit-container">
          <div className="detail-label-visit-checkbox-container">
            <p>CR non conforme</p>
            <Checkbox
              color="inherit"
              checked={!this.state.isReportCompliant}
              onClick={this.handleCheckCompliantReport}
            />
          </div>
          <div className="detail-label-visit-checkbox-container">
            <p>Cas chaud</p>
            <Checkbox color="inherit" checked={this.state.isStatusHot} onClick={this.handleCheckHotStatus} />
          </div>
        </div>
      </div>
    );
  }
}
