import { getEmployeesRequest } from "admin/pages/CustomerFiles/module";
import { connect } from "react-redux";

import { getAccountRequest } from "pages/Account/module";

import SkuDemandList from "./SkuDemandList.component";

function mapStateToProps(state) {
  return {
    idEmployee: state.account.account && state.account.account.id,
    employees: state.customerFiles.employees || [],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAccount() {
      dispatch(getAccountRequest());
    },
    getEmployees() {
      dispatch(getEmployeesRequest());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SkuDemandList);
