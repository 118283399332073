import React, { useState } from "react";

import { useNotify, useUpdate } from "react-admin";

import moment from "moment";

import { VISIT_STATUSES, VISIT_TYPES } from "../../constants";
import { ButtonVisit } from "../ButtonVisit";
import EmployeeTimeslotVisitInput from "../EmployeeTimeslotVisitInput";
import StatusVisitInput from "../StatusVisitInput";
import VisitPreviewChanges from "./components/VisitPreviewChanges";

export default function VisitDetails({ record, employees }) {
  const [editable, setEditable] = useState(false);
  const [visitComment, setVisitComment] = useState(record.visit_comments);
  const [visitStatus, setVisitStatus] = useState(record.visit_status);
  const [visitEmployee, setVisitEmployee] = useState(record.assigned_repairman);
  const [visitBeginningUTCISOString, setVisitBeginningUTCISOString] = useState(record.timeslot.beginning);
  const [visitEndUTCISOString, setVisitEndUTCISOString] = useState(record.timeslot.end);
  const [timeNeeded, setTimeNeeded] = useState(record.time_needed);
  const [visitType, setVisitType] = useState(record.visit_type);

  const [isVisitPreviewOpened, setIsVisitPreviewOpened] = useState(false);

  const [updateVisit] = useUpdate("visits");
  const notify = useNotify();

  const timeslotDuration = moment(record.timeslot.end).diff(moment(record.timeslot.beginning));
  const updateVisitDetail = async (id, updateData) => {
    await updateVisit(
      {
        payload: { id: id, data: updateData },
      },

      {
        onSuccess: ({ data }) => {
          setVisitEmployee(data.assigned_repairman);
          notify("Passage mis à jour", "success");
        },
        onFailure: () => {
          notify("Une erreur est survenue", "error");
        },
      }
    );
  };

  const previewChanges = async () => {
    setIsVisitPreviewOpened(true);
  };

  const triggerVisitUpdate = () => {
    updateVisitDetail(record.id, {
      visit_comments: visitComment,
      visit_status: visitStatus,
      employee: visitEmployee,
      time_needed: timeNeeded,
      beginning: visitBeginningUTCISOString,
      end: visitEndUTCISOString,
      visit_type: visitType,
    });
    setEditable(!editable);
    setIsVisitPreviewOpened(false);
  };

  const resetChanges = () => {
    setEditable(false);
    setVisitComment(record.visit_comments);
    setVisitStatus(record.visit_status);
    setTimeNeeded(record.time_needed);
    setVisitBeginningUTCISOString(record.timeslot.beginning);
    setVisitType(record.visit_type);
  };

  return (
    <>
      <StatusVisitInput
        handleUpdate={(value) => setVisitStatus(value)}
        value={visitStatus}
        record={record}
        editable={editable}
        choices={VISIT_STATUSES}
        label="Statut"
      />
      <StatusVisitInput
        handleUpdate={(value) => setVisitType(value)}
        value={visitType}
        editable={editable}
        choices={VISIT_TYPES}
        label="Type"
      />
      <EmployeeTimeslotVisitInput
        employees={employees}
        visitTimeNeeded={timeNeeded}
        visitEmployee={visitEmployee}
        visitBeginningUTCISOString={visitBeginningUTCISOString}
        handleSaveEmployee={(value) => setVisitEmployee(value)}
        handleSaveDateHeureVisit={(value) => {
          setVisitBeginningUTCISOString(value);
          setVisitEndUTCISOString(moment(value).utc().add(timeslotDuration).format("YYYY-MM-DDTHH:mm:ss") + "Z");
        }}
        handleSaveTimeNeeded={(value) => setTimeNeeded(value)}
        editable={editable}
        record={record}
      />

      <ButtonVisit
        toggleEditSaveMode={() => setEditable(!editable)}
        handleSubmit={previewChanges}
        handleCancel={resetChanges}
        status={visitStatus}
        editable={editable}
      />

      {isVisitPreviewOpened && (
        <VisitPreviewChanges
          visitId={record.id}
          beginning={visitBeginningUTCISOString}
          end={visitEndUTCISOString}
          employee={visitEmployee}
          timeNeeded={timeNeeded}
          visitType={visitType}
          visitComment={visitComment}
          handleSubmit={triggerVisitUpdate}
          handleCancel={() => setIsVisitPreviewOpened(false)}
        />
      )}
    </>
  );
}
