export default class ReportCheckReason {
  static get AFTER_SALES() {
    return "after_sales";
  }
  static get REOPENING() {
    return "reopening";
  }
  static get P2() {
    return "p2";
  }
  static get TOO_MANY_SPARE_PARTS() {
    return "too_many_spare_parts";
  }
  static get EXPENSIVE_QUOTE() {
    return "expensive_quote";
  }
  static get UNCLEAR_REPORT() {
    return "unclear_report";
  }
  static get SPARE_PART_REFERENCE_NOT_FOUND() {
    return "spare_part_reference_not_found";
  }
  static get COLD_APPLIANCE() {
    return "cold_appliance";
  }
  static get TECHNICAL_HELP_REQUIRED() {
    return "technical_help_required";
  }
  static get LONG_INTERVENTION_REQUIRED() {
    return "long_intervention_required";
  }
  static get IMPOSSIBLE_INTERVENTION() {
    return "impossible_intervention";
  }
  static get OTHER() {
    return "other";
  }
  static get SPARE_PART_NEEDED() {
    return "spare_part_needed";
  }
  static get DIAGNOSTIC() {
    return "diagnostic";
  }
}

export const REPORT_CHECK_REASONS_CHOICES = [
  { id: ReportCheckReason.AFTER_SALES, name: "SAV RECO" },
  { id: ReportCheckReason.REOPENING, name: "Réouverture" },
  { id: ReportCheckReason.P2, name: "P2+ non cloturé" },
  { id: ReportCheckReason.TOO_MANY_SPARE_PARTS, name: "Beaucoup de pièces commandées" },
  { id: ReportCheckReason.EXPENSIVE_QUOTE, name: "Montant du devis élevé" },
  { id: ReportCheckReason.UNCLEAR_REPORT, name: "CR non compris" },
  { id: ReportCheckReason.SPARE_PART_REFERENCE_NOT_FOUND, name: "Pièce à commander introuvable" },
  { id: ReportCheckReason.OTHER, name: "Autre" },
  { id: ReportCheckReason.COLD_APPLIANCE, name: "Froid" },
  { id: ReportCheckReason.TECHNICAL_HELP_REQUIRED, name: "Aide technique demandée" },
  { id: ReportCheckReason.LONG_INTERVENTION_REQUIRED, name: "Intervention longue demandée" },
  { id: ReportCheckReason.IMPOSSIBLE_INTERVENTION, name: "Intervention Impossible" },
  { id: ReportCheckReason.SPARE_PART_NEEDED, name: "Besoin de pièce" },
  { id: ReportCheckReason.DIAGNOSTIC, name: "Diag" },
];
