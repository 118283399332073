import React from "react";

import { Box, Typography, useTheme } from "@material-ui/core";

const QuoteBalanceSummary = ({ customerFile, leftToPay, alreadyPaid, editionSession = false }) => {
  const theme = useTheme();

  return (
    <Box>
      <Box px={1}>
        {alreadyPaid > 0 && (
          <Box
            alignItems="center"
            height={64}
            color={theme.palette.secondary.green}
            display="flex"
            justifyContent="space-between"
            px={1}
          >
            <Typography color="inherit" variant="subtitle2">
              Déjà payé
            </Typography>
            <Typography id="amount-paid" color="inherit" variant="subtitle2">
              {alreadyPaid / 100}€
            </Typography>
          </Box>
        )}
        <Box
          display="flex"
          color={leftToPay >= 0 ? theme.palette.secondary.contrastText : theme.palette.secondary.red}
          justifyContent="space-between"
          height={64}
          alignItems="center"
          bgcolor="#F4F3F8"
          borderRadius={8}
          px={1}
        >
          <Typography id="total-amount-not-editable" variant="subtitle2" color="inherit">
            {leftToPay >= 0
              ? !customerFile.quote_accepted && !customerFile.quote_refused && !editionSession
                ? "Net à payer si accepté"
                : "Net à payer"
              : "Net à rembourser"}
          </Typography>
          <Typography id="amount-to-pay" color="inherit" variant="subtitle2">
            {Math.abs(leftToPay / 100)}€
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default QuoteBalanceSummary;
