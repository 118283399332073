import { SKU_SUBSTITUTION_TYPE_ORIGINAL, SKU_TYPE_NEW } from "constants/sku";
import { SUPPLIER_NAME_ASWO } from "constants/supplier";

/*
 * Sort the given sku array by priority:
 * - new before second_hand
 * - original before substitute
 * - then sort by id
 */
export function sortSkus(skus) {
  return skus.sort((sku1, sku2) => {
    if (sku1.type !== sku2.type) {
      return sku1.type === SKU_TYPE_NEW ? -1 : 1;
    }
    if (sku1.substitution_type !== sku2.substitution_type) {
      return sku1.substitution_type === SKU_SUBSTITUTION_TYPE_ORIGINAL ? -1 : 1;
    }
    return sku1.id < sku2.id ? -1 : 1;
  });
}

export const getAswoReference = (sku) =>
  sku.references
    .filter((skuModelReference) => skuModelReference.supplier_name === SUPPLIER_NAME_ASWO)
    .map((skuModelReference) => skuModelReference.reference)[0];
