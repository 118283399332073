import React from "react";

import { Box } from "@material-ui/core";

import { VisitOutcome } from "types/visits";

import { Body } from "components/atoms/Typography";

import { useProduct } from "utils/hooks/useProduct";

interface VisitOutcomeProps {
  visitOutcome: VisitOutcome;
}

const VisitOutcomeWithProduct: React.FC<VisitOutcomeProps> = ({ visitOutcome }: VisitOutcomeProps) => {
  const { data } = useProduct(visitOutcome.product_id);
  return (
    <Box display="flex" justifyContent="space-between">
      <Body>{data ? data.product_type : "..."}</Body>
      <Body>{visitOutcome.outcome_display}</Body>
    </Box>
  );
};

export default VisitOutcomeWithProduct;
