// React imports
import React, { useEffect, useState } from "react";

import { useCreate, useNotify } from "react-admin";

// MUI imports
import { Typography } from "@material-ui/core";
// Style imports
import { makeStyles } from "@material-ui/core/styles";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";

import moment from "moment";

import dataProvider from "dataProvider";

import CustomerFileTargetStyles from "../CustomerFileTargetStyles";

const useStyles = makeStyles(CustomerFileTargetStyles);

export function fetchMailTasks(customerFileId, setManualTemporisationEmailTask, setAutomaticTemporisationEmailTask) {
  dataProvider
    .getList("email-actions", {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "scheduled_date", order: "ASC" },
      filter: { customer_file: customerFileId },
    })
    .then(({ data }) => {
      const manualTemporisationTask =
        data && data.filter((task) => task.mail_template === "temporisation_mail_manual" && task.mail_sent === true);
      const automaticTemporisationTask =
        data && data.filter((task) => task.mail_template === "temporisation_mail_automatic" && task.mail_sent === true);
      setManualTemporisationEmailTask(manualTemporisationTask[manualTemporisationTask.length - 1]);
      setAutomaticTemporisationEmailTask(automaticTemporisationTask[automaticTemporisationTask.length - 1]);
    });
}

export default function CustomerFileEmailActions(props) {
  const classes = useStyles();
  const notify = useNotify();
  const [manualTemporisationEmailTask, setManualTemporisationEmailTask] = useState();
  const [automaticTemporisationEmailTask, setAutomaticTemporisationEmailTask] = useState();

  const [shouldGetMailTasks, setShouldGetMailTasks] = useState(false);
  const [postTemporisationEmail] = useCreate("send-temporisation-email");

  useEffect(() => {
    fetchMailTasks(props.customerFileId, setManualTemporisationEmailTask, setAutomaticTemporisationEmailTask);
    setShouldGetMailTasks(false);
  }, [props.customerFileId, props.customerFileStateId, shouldGetMailTasks]);

  const sendTemporisationEmail = (customerFileId) => {
    postTemporisationEmail(
      {
        payload: {
          data: {
            id: customerFileId,
          },
        },
      },
      {
        onSuccess: () => {
          notify("Mail de délais technique envoyé");
          setShouldGetMailTasks(true);
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };
  const getFormattedDate = (dateIsoString) => {
    return moment(dateIsoString).format("DD/MM/YY à HH:mm");
  };
  const reminderTemporisationMail =
    manualTemporisationEmailTask || automaticTemporisationEmailTask ? (
      manualTemporisationEmailTask ? (
        <>
          <span className={classes.temporisationTypeOfMailText}>Mail de temporisation manuel</span> envoyé le{" "}
          {getFormattedDate(manualTemporisationEmailTask.sent_date)}
        </>
      ) : automaticTemporisationEmailTask ? (
        <>
          <span className={classes.temporisationTypeOfMailText}>Mail de temporisation automatique </span>envoyé le
          {getFormattedDate(automaticTemporisationEmailTask.sent_date)}
        </>
      ) : null
    ) : null;

  return (
    <>
      <div className={classes.root}>
        <Typography className={classes.body2Bold} variant="body2">
          Actions d'emails&nbsp;:{" "}
        </Typography>
        <div className={classes.flexWrapDiv}>
          <div className={classes.actionButton} onClick={() => sendTemporisationEmail(props.customerFileId)}>
            <i className="fas fa-forward ico ico-small" />
            <Typography className={classes.body2} variant="body2">
              Mail de délais technique
            </Typography>
          </div>
        </div>
      </div>
      {reminderTemporisationMail && (
        <div className={classes.temporisationReminderContainer}>
          <NotificationImportantIcon className={classes.temporisationReminderIcon} />
          <span className={classes.temporisationTypeOfMailContainer}>{reminderTemporisationMail}</span>
        </div>
      )}
    </>
  );
}
