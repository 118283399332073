import React, { useEffect, useState } from "react";

import { AutocompleteInput, Create, SaveButton, SimpleForm, Toolbar } from "react-admin";

import { Box, Typography, makeStyles } from "@material-ui/core";

import { useForm, useFormState } from "react-final-form";
import { toastr } from "react-redux-toastr";
import CreatableSelect from "react-select/creatable";

import Modal from "components/Modal";

import { useProductBrands, useProductModels, useProductTypes } from "utils/api";
import { createProductModel } from "utils/api";

const autocompleteStyles = {
  menu: () => ({
    color: "#073331",
    textAlign: "left",
    border: "1px solid rgba(219, 223, 232, 0.4)",
    borderRadius: 12,
    width: "100%",
    height: 60,
    cursor: "pointer",
  }),
  control: () => ({
    height: 48,
    backgroundColor: "#f4f3f8",
    display: "flex",
    borderRadius: 12,
    alignItems: "center",
    textAlign: "left",
    marginTop: 10,
    border: "1px solid rgba(219, 223, 232, 0.4)",
  }),
  option: () => ({
    padding: 16,
    backgroundColor: "white",
    color: "black",
  }),
  indicatorSeparator: () => ({ display: "none" }),
  placeholder: () => ({ paddingLeft: 16, position: "absolute", fontSize: 16, color: "grey", paddingTop: 4 }),
  Input: () => ({}),
};

const ProductCreationFormInputs = (props) => {
  const { referenceList, productTypes, productBrands, setFilterText, setBrandId, setCategoryId, filterText } = props;
  const { change } = useForm();
  const { values } = useFormState();

  useEffect(() => {
    setBrandId(
      productBrands &&
        values.brand &&
        productBrands.find((item) => item.id === values.brand.id) &&
        productBrands.find((item) => item.id === values.brand.id).id
    );
    setCategoryId(
      productTypes &&
        values.product_type &&
        productTypes.find((item) => item.id === values.product_type.id) &&
        productTypes.find((item) => item.id === values.product_type.id).id
    );
    // eslint-disable-next-line
  }, [productBrands, productTypes]);

  return (
    <>
      <AutocompleteInput
        label="Catégorie"
        source="product_type.id"
        fullWidth
        choices={productTypes}
        onChange={(value) => {
          change("product_type.id", value);
          setCategoryId(value);
        }}
        optionValue="id"
        optionText="name"
      />
      <AutocompleteInput
        label="Marque"
        source="brand.id"
        fullWidth
        onChange={(value) => {
          change("brand.id", value);
          setBrandId(value);
        }}
        choices={productBrands}
        optionText="name"
      />
      <CreatableSelect
        styles={autocompleteStyles}
        formatCreateLabel={(inputValue) => (filterText.length < 3 ? "Entrez 3 caractères min." : `Créer ${inputValue}`)}
        name="reference"
        value={
          referenceList.find((item) => item.value === values.reference) || {
            label: values.reference,
            value: values.reference,
          }
        }
        onInputChange={(event) => {
          setFilterText(event);
        }}
        options={referenceList}
        onChange={(event) => {
          event && change("reference", event.value.toUpperCase());
        }}
        blurInputOnSelect
        placeholder="Référence"
        noOptionsMessage={() => "Commencez à écrire"}
      />
    </>
  );
};

const ProductModelEditionModal = (props) => {
  const classes = useStyle();
  const { record } = props;
  const [referenceList, setReferenceList] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [brandId, setBrandId] = useState();
  const [categoryId, setCategoryId] = useState();

  const productModels = useProductModels({
    q: filterText.length >= 3 && filterText.substring(0, 3),
    brand_id: brandId,
    product_type_id: categoryId,
  });
  const productTypes = useProductTypes();
  const productBrands = useProductBrands();

  useEffect(() => {
    if (productModels) {
      setReferenceList(
        productModels.map((reference) => ({
          label: reference.reference,
          value: reference.reference,
        }))
      );
    }
  }, [productModels, record]);

  const handleSuccess = (message, modelId, values) => {
    toastr.success("Succès", message);
    props.save(
      {
        product_model: modelId,
        model_reference: values.reference,
        product_type: productTypes.find((item) => item.id === values.product_type.id).name,
        brand: productBrands.find((item) => item.id === values.brand.id).name,
      },
      props.handleCloseModal()
    );
  };

  const handleSubmit = (values) => {
    createProductModel(values).then(({ status, data }) => {
      if (status === 200) {
        handleSuccess("Modèle trouvé avec succès", data.id, values);
      } else if (status === 201) {
        handleSuccess("Modèle créé avec succès", data.id, values);
      } else {
        toastr.error("Erreur" + status);
      }
    });
  };

  const getInitialValues = () => {
    if (!props.product) {
      return { ...record };
    } else {
      return {
        product_type: productTypes && productTypes.find((item) => item.name === props.product.product_type),
        brand: productBrands && productBrands.find((item) => item.name === props.product.brand),
        reference: props.product.model_reference,
      };
    }
  };

  return (
    <Modal isOpen={props.open} handleCloseModal={props.handleCloseModal} modalClassName="modal-medium-content">
      <Box justifyContent="center" p={4} pb={0}>
        <Typography variant="h2">{props.title}</Typography>
        <Create resource="product-models" basePath="">
          <SimpleForm
            initialValues={getInitialValues()}
            toolbar={<CustomToolbar save={handleSubmit} handleCloseModal={props.handleCloseModal} classes={classes} />}
            className={classes.editForm}
          >
            <ProductCreationFormInputs
              setBrandId={setBrandId}
              setCategoryId={setCategoryId}
              filterText={filterText}
              setFilterText={setFilterText}
              referenceList={referenceList}
              productTypes={productTypes}
              productBrands={productBrands}
            />
          </SimpleForm>
        </Create>
      </Box>
    </Modal>
  );
};

const CustomToolbar = (props) => {
  return (
    <Toolbar {...props} classes={{ toolbar: props.classes.toolbar }}>
      <SaveButton label="Confirmer" onSave={(values) => props.save(values)} className={props.classes.saveButton} />
    </Toolbar>
  );
};

const useStyle = makeStyles((theme) => ({
  editForm: {
    padding: 24,
    marginBottom: 140,
  },
  toolbar: {
    backgroundColor: "white",
    position: "absolute",
    bottom: 12,
    right: 0,
  },
  saveButton: {
    fontSize: 14,
    fontWeight: "bold",
    height: 48,
    justifyContent: "center",
    padding: "18px 14px",
    boxShadow: "none",
  },
}));

export default ProductModelEditionModal;
