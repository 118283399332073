export default [
  {
    id: -3,
    order: -3,
    label: "Prénom",
    field_type: "text",
    name: "firstname",
    placeholder: "Prénom",
    is_required: true,
  },
  {
    id: -2,
    order: -2,
    label: "Nom",
    field_type: "text",
    name: "lastname",
    placeholder: "Nom de famille",
    is_required: true,
  },
  {
    id: -1,
    order: -1,
    label: "Adresse mail",
    field_type: "mail",
    name: "email",
    placeholder: "Adresse mail",
    is_required: true,
  },
];
