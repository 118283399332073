import React from "react";

import { FormControl, InputLabel, MenuItem, Select, styled } from "@material-ui/core";

type SelectProps = {
  onChange: (event: React.ChangeEvent<{ value: string | unknown }>) => void;
  options: { value: string; label: string }[];
  label: string;
  defaultValue?: string;
};

const CustomSelect: React.FunctionComponent<SelectProps> = ({
  options,
  onChange,
  label,
  defaultValue,
}: SelectProps) => {
  const StyledInputLabel = styled(InputLabel)<any>(({ theme }) => ({
    backgroundColor: theme.palette.primary.white,
    padding: theme.spacing(0, 0.5),
  }));
  return (
    <FormControl variant="outlined" fullWidth>
      <StyledInputLabel id={label}>{label}</StyledInputLabel>
      <Select onChange={onChange} defaultValue={defaultValue}>
        {options.map((option) => {
          return <MenuItem value={option.value}>{option.label}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
};

export default CustomSelect;
