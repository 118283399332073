import React from "react";

import { Box, styled } from "@material-ui/core";

import { Field, FieldProps } from "formik";
import { CustomerFileDetailed } from "types/customer-files";
import { Timeslot } from "types/timeslot";

import { Body } from "components/atoms/Typography";
import { CalendarContainer } from "components/organisms/Calendar";

const Container = styled(Box)(({ theme }) => ({
  borderRadius: 8,
  boxShadow: "0 0 3px 0 rgba(0, 0, 0, 0.08), 0 1px 40px 0 rgba(0, 0, 0, 0.08)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(2),
  minHeight: 272,
}));

type CalendarFieldProps = { customerFile: CustomerFileDetailed };

const CalendarField: React.FunctionComponent<CalendarFieldProps> = ({ customerFile }: CalendarFieldProps) => (
  <Container>
    <Field name="timeslot">
      {({ form }: FieldProps) => {
        return (
          <>
            {form.values.visit_type !== "" && form.values ? (
              <>
                <CalendarContainer
                  minimumCapacity={form.values.time_needed ? 2 : 1}
                  selectedRepairmanId={form.values.employee}
                  onSelect={(timeslot: Timeslot) => form.setFieldValue("timeslot", timeslot)}
                  selectedType={form.values.visit_type}
                  isSelected={(timeslot: Timeslot) => form.values.timeslot && form.values.timeslot.id === timeslot.id}
                  customerFile={customerFile}
                />
              </>
            ) : (
              <>
                <Body> Veuillez renseigner les informations ci-dessus afin d'accéder</Body>
                <Body> aux créneaux disponibles </Body>
              </>
            )}

            {form.touched.timeslot && form.errors.timeslot && <Body color="red">{form.errors.timeslot}</Body>}
          </>
        );
      }}
    </Field>
  </Container>
);

export default CalendarField;
