import React from "react";

import { Typography, makeStyles } from "@material-ui/core";

import Modal from "components/Modal";

import CustomerFileCreateForm from "./CustomerFileCreateForm";

const useStyles = makeStyles(() => ({
  modal: {
    margin: "auto",
    width: "100vw",
    height: "100vh",
    paddingBottom: 24,
  },
  titleContainer: {
    textAlign: "center",
    paddingTop: 24,
  },
}));

type CustomerFileCreateModalProps = { closeModal: (fileNumber: string) => void; isOpen: boolean };

const CustomerFileCreateModal = (props: CustomerFileCreateModalProps) => {
  const classes = useStyles();

  const onCloseModal = (file_number: string) => {
    props.closeModal(file_number);
  };

  return (
    <Modal
      modalClassName={`modal-small-content ${classes.modal}`}
      isOpen={props.isOpen}
      handleCloseModal={onCloseModal}
      shouldCloseOnOverlayClick={false}
    >
      <div className={classes.titleContainer}>
        <Typography variant="h2">Créer un dossier client</Typography>
      </div>
      <CustomerFileCreateForm onSuccess={onCloseModal} />
    </Modal>
  );
};

export default CustomerFileCreateModal;
