import { createMuiTheme } from "@material-ui/core/styles";

const customFiltersTheme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        borderRadius: "4px",
        color: "#2B3B5C",
        padding: "16px",
        fontFamily: "Poppins, Roboto, Helvetica, Arial, sans-serif",
        fontSize: "12px",
        fontWeight: 600,
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: "#ffffff",
        border: "1px solid rgba(219, 223, 232, 0.4)",
        borderRadius: "12px",
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px",
        color: "#2B3B5C",
        width: "100%",
        height: 48,
      },
      underline: {
        "&:before": {
          borderBottom: "none",
        },
        "&:hover:not($disabled):before": {
          display: "none",
        },
        "&:after": {
          borderBottom: "none",
        },
      },
      inputMarginDense: {
        paddingTop: 20,
      },
    },
  },
});

export default customFiltersTheme;
