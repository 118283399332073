import Fuse from "fuse.js";

export default function filterByElement(listToFilter, filter, options) {
  let defautOptions = {
    threshold: 0.3,
    location: 0,
    distance: 215,
    maxPatternLength: 32,
    minMatchCharLength: 3,
    keys: [],
  };

  var fuse = new Fuse(listToFilter, { ...defautOptions, ...options });

  return filter ? fuse.search(filter) : listToFilter;
}
