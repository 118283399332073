import React, { Component } from "react";

import PropTypes from "prop-types";

import sortBy from "lodash/sortBy";

import "./style.css";

export default class QuickAccessMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayedMenuElementsGroupId: null,
    };
  }

  static propTypes = {
    activeMenuElementId: PropTypes.number,
    activeMenuSubElementId: PropTypes.number,
    menuElements: PropTypes.arrayOf(
      PropTypes.shape({
        counter: PropTypes.number,
        id: PropTypes.number,
        name: PropTypes.string,
        order: PropTypes.any,
        customStyle: PropTypes.any,
        elements: PropTypes.arrayOf(
          PropTypes.shape({
            counter: PropTypes.number.isRequired,
            id: PropTypes.number,
            name: PropTypes.string,
            order: PropTypes.number,
          })
        ),
      })
    ),
    handleMenuElementClick: PropTypes.func,
    handleMenuSubElementClick: PropTypes.func,
    title: PropTypes.string.isRequired,
  };

  toggleDisplayedElementsGroup = (menuElementId) => () => {
    this.setState((prevState) => ({
      displayedMenuElementsGroupId: menuElementId === prevState.displayedMenuElementsGroupId ? null : menuElementId,
    }));
  };

  handleClickElement = (menuElementId) => () => {
    this.props.handleMenuElementClick(menuElementId);
  };

  handleClickSubElement = (menuSubElementId) => () => {
    this.props.handleMenuSubElementClick(menuSubElementId);
  };

  /**
   * This function sorts a given array, based on the 'sorting' array
   * within the function. Any array element not matching the sorting array
   * is put at the end of the resulting array.
   *
   *
   * @param menuElement array containing all menu elements
   * @returns results   array containing sorted elements
   */
  sortMenuElement = (menuElements) => {
    const sorting = [
      "Tous les dossiers clients",
      "En cours de traitement",
      "Commande pièce",
      "Dossiers clos",
      "Paiement / Facturation",
      "Litiges",
      "Demandes de devis",
    ];
    const cloneMenuElements = [...menuElements];
    let results = [];

    for (let b = 0; b < sorting.length; b++) {
      var found = false;

      for (var i = 0; i < cloneMenuElements.length; i++) {
        if (!found && sorting[b] === cloneMenuElements[i].name) {
          results.push(cloneMenuElements[i]);
          found = true;
          cloneMenuElements.splice(i, 1);
        }
      }
    }

    results = results.concat(cloneMenuElements);

    return results;
  };

  renderMenuElement = (menuElement) => {
    const isActive = this.props.activeMenuElementId === menuElement.id;
    const isOpened = this.state.displayedMenuElementsGroupId === menuElement.id;
    const hasChildren = menuElement.elements && menuElement.elements.length > 0;
    return (
      <div key={menuElement.id}>
        <div
          className={`qa-menu-element ${!hasChildren && isActive && "qa-menu-element-active"}`}
          onClick={
            hasChildren ? this.toggleDisplayedElementsGroup(menuElement.id) : this.handleClickElement(menuElement.id)
          }
          style={menuElement.customStyle}
        >
          <i className={`${hasChildren ? (isOpened ? "fas fa-caret-down" : "fas fa-caret-right") : null}`} />
          <span>{menuElement.name}</span>
          {menuElement.counter !== 0 && menuElement.counter && (
            <div className="quick-access-menu-counter-container">{menuElement.counter}</div>
          )}
        </div>
        <div>
          {isOpened &&
            sortBy(menuElement.elements, "order").map((element) => (
              <div key={element.id} style={{ display: "flex" }}>
                <div
                  className={`qa-menu-subelement
                    ${this.props.activeMenuSubElementId === element.id && "qa-menu-subelement-active"}`}
                  onClick={this.handleClickSubElement(element.id)}
                >
                  {element.name}
                </div>
                {element.counter !== 0 && <span className="qa-menu-subelement-counter">({element.counter})</span>}
              </div>
            ))}
        </div>
      </div>
    );
  };

  render() {
    return (
      this.props.menuElements && (
        <div className="qa-menu-container">
          <p className="quick-access-title">{this.props.title}</p>
          <div className="qa-menu-elements-container">
            {this.sortMenuElement(this.props.menuElements).map((result) => this.renderMenuElement(result))}
          </div>
        </div>
      )
    );
  }
}
