/**
 * Following the duck pattern, actions, constants and reducers are in the same file called module.js
 *
 * See: https://github.com/erikras/ducks-modular-redux
 *
 */
import filter from "lodash/filter";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import get from "lodash/get";
import includes from "lodash/includes";
import { toastr } from "react-redux-toastr";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import {
  ADMIN_CMS_CATALOG_EDITION_ROUTE,
  ADMIN_CMS_KNOWLEDGE_BASE_ARTICLE_EDITION_ROUTE,
  ADMIN_CMS_KNOWLEDGE_BASE_FORM_EDITION_ROUTE,
  ADMIN_CMS_KNOWLEDGE_BASE_TUTORIAL_EDITION_ROUTE,
  ADMIN_CMS_SMART_DIAGNOSTIC_ROUTE,
  ADMIN_CMS_STEP_EDITION_ROUTE,
  ADMIN_CMS_STEP_ROUTE,
} from "constants/routes";

import { initializeNewArticle } from "pages/Cms/ArticleEdition/module";
import {
  CREATE_NEW_WIDGET_SUCCESS,
  DELETE_APPLICATION_FAMILY_SUCCESS,
  DELETE_APPLICATION_SUCCESS,
  DELETE_PRODUCT_SUCCESS,
  SAVE_APPLICATION_FAMILY_SUCCESS,
  SAVE_APPLICATION_SUCCESS,
  SAVE_PRODUCT_SUCCESS,
} from "pages/Cms/ContentManagement/components/ProductsList/module";
import { initializeNewForm } from "pages/Cms/FormEdition/module";
import { DELETE_STEP_SUCCESS, getStepDiagnosticId } from "pages/Cms/StepEdition/module";
import { initializeNewTutorial } from "pages/Cms/TutorialEdition/module";

import { addOrReplaceByKey } from "utils/addOrReplace";
import filterByElement from "utils/fuzzyService";
import groupElementsByKeys from "utils/groupElementsByKeys";
import { getTokenFromLocalStorage, request } from "utils/networking";

export const GET_APPLICATIONS_REQUEST = "CMS/GET_APPLICATIONS_REQUEST";
export const GET_APPLICATIONS_SUCCESS = "CMS/GET_APPLICATIONS_SUCCESS";
export const GET_APPLICATIONS_ERROR = "CMS/GET_APPLICATIONS_ERROR";

export const GET_APPLICATION_FAMILIES_REQUEST = "CMS/GET_APPLICATION_FAMILIES_REQUEST";
export const GET_APPLICATION_FAMILIES_SUCCESS = "CMS/GET_APPLICATION_FAMILIES_SUCCESS";
export const GET_APPLICATION_FAMILIES_ERROR = "CMS/GET_APPLICATION_FAMILIES_ERROR";

export const GET_STEPS_REQUEST = "CMS/GET_STEPS_REQUEST";
export const GET_STEPS_SUCCESS = "CMS/GET_STEPS_SUCCESS";
export const GET_STEPS_ERROR = "CMS/GET_STEPS_ERROR";

export const GET_WIDGETS_REQUEST = "CMS/GET_WIDGETS_REQUEST";
export const GET_WIDGETS_SUCCESS = "CMS/GET_WIDGETS_SUCCESS";
export const GET_WIDGETS_ERROR = "CMS/GET_WIDGETS_ERROR";

export const DELETE_WIDGET_REQUEST = "CMS/DELETE_WIDGET_REQUEST";
export const DELETE_WIDGET_SUCCESS = "CMS/DELETE_WIDGET_SUCCESS";
export const DELETE_WIDGET_ERROR = "CMS/DELETE_WIDGET_ERROR";

export const SAVE_EMPTY_STEP_REQUEST = "CMS/SAVE_EMPTY_STEP_REQUEST";
export const SAVE_EMPTY_STEP_SUCCESS = "CMS/SAVE_EMPTY_STEP_SUCCESS";

export const SET_ELEMENT_FILTER = "CMS/SET_ELEMENT_FILTER";
export const RESET_ELEMENT_FILTER = "CMS/RESET_ELEMENT_FILTER";

export const SET_ACTIVE_MENU_ELEMENT = "CMS/SET_ACTIVE_MENU_ELEMENT";
export const RESET_ACTIVE_MENU_ELEMENT = "CMS/RESET_ACTIVE_MENU_ELEMENT";

export const SET_ACTIVE_ELEMENTS_GROUP = "CMS/SET_ACTIVE_ELEMENTS_GROUP";

export function getApplicationsRequest() {
  return {
    type: GET_APPLICATIONS_REQUEST,
  };
}

export function getApplicationsSuccess(applications) {
  return {
    type: GET_APPLICATIONS_SUCCESS,
    payload: applications,
  };
}

export function getApplicationsError(error) {
  return {
    type: GET_APPLICATIONS_ERROR,
    payload: error,
  };
}

export function getApplicationFamiliesRequest() {
  return {
    type: GET_APPLICATION_FAMILIES_REQUEST,
  };
}

export function getApplicationFamiliesSuccess(applicationFamilies) {
  return {
    type: GET_APPLICATION_FAMILIES_SUCCESS,
    payload: applicationFamilies,
  };
}

export function getApplicationFamiliesError(error) {
  return {
    type: GET_APPLICATION_FAMILIES_ERROR,
    payload: error,
  };
}

export function getStepsRequest() {
  return {
    type: GET_STEPS_REQUEST,
  };
}

export function getStepsSuccess(steps) {
  return {
    type: GET_STEPS_SUCCESS,
    payload: steps,
  };
}

export function getStepsError(error) {
  return {
    type: GET_STEPS_ERROR,
    payload: error,
  };
}

export function getWidgetsRequest() {
  return {
    type: GET_WIDGETS_REQUEST,
  };
}

export function getWidgetsSuccess(widgets) {
  return {
    type: GET_WIDGETS_SUCCESS,
    payload: widgets,
  };
}

export function getWidgetsError(error) {
  return {
    type: GET_WIDGETS_ERROR,
    payload: error,
  };
}

export function deleteWidgetRequest(id) {
  return {
    type: DELETE_WIDGET_REQUEST,
    payload: id,
  };
}

export function deleteWidgetSuccess(id) {
  return {
    type: DELETE_WIDGET_SUCCESS,
    payload: id,
  };
}

export function deleteWidgetError(error) {
  return {
    type: DELETE_WIDGET_ERROR,
    payload: error,
  };
}

export function setElementFilter(filter) {
  return {
    type: SET_ELEMENT_FILTER,
    payload: filter,
  };
}

export function resetElementFilter() {
  return {
    type: RESET_ELEMENT_FILTER,
  };
}

export function setActiveMenuElement(menuElementId) {
  return {
    type: SET_ACTIVE_MENU_ELEMENT,
    payload: menuElementId,
  };
}

export function resetActiveMenuElement() {
  return {
    type: RESET_ACTIVE_MENU_ELEMENT,
  };
}

export function setActiveElementsGroup(elementsGroupTitle) {
  return {
    type: SET_ACTIVE_ELEMENTS_GROUP,
    payload: elementsGroupTitle,
  };
}

export function saveEmptyStepRequest(stepTitle, diagnostic) {
  return {
    type: SAVE_EMPTY_STEP_REQUEST,
    payload: { stepTitle, diagnostic },
  };
}

export function saveEmptyStepSucces(step) {
  return {
    type: SAVE_EMPTY_STEP_SUCCESS,
    payload: { step },
  };
}

/**
 * Selectors
 */

export const getWidgetNameSelector = (state, widgetId) => {
  // TODO: Replace title by name !!
  return state.cms.widgets && get(find(state.cms.widgets, { id: widgetId }), "title");
};

export const getApplicationsCMSSelector = (state, productId) => {
  if (productId) {
    return state.cms.applications && state.cms.applications.filter((app) => app.products.includes(productId));
  } else {
    return state.cms.applications && state.cms.applications.filter((app) => app.products.length === 0);
  }
};

export const getFilteredStepsSelector = (state) => {
  let options = { keys: ["title"] };
  return filterByElement(formatSmartDiagnosticStepsSelector(state.cms.steps), state.cms.elementFilter, options);
};

export const getWidgetsSelector = (state, widgetTypes) => {
  return state.cms.widgets.filter((widget) => widgetTypes.indexOf(widget.widget_type) > -1);
};

export const getFilteredWidgetsSelector = (state, widgetTypes) => {
  let options = { keys: ["title"] };
  return filterByElement(getWidgetsSelector(state, widgetTypes), state.cms.elementFilter, options);
};

/**
 * Return a boolean indicating if the application related to the given widget id has a product or not.
 * @param {number} applicationWidgetId The id of the application's widget
 */
export const hasApplicationWidgetAnyProduct = (state, applicationWidgetId) => {
  const application = get(state, "cms.applications", []).find(
    (application) => application.widget === applicationWidgetId
  );
  return get(application, "products", []).length > 0;
};

export const stepsGroupedByDiagnosticSelector = (state, diagnosticId) => {
  /* Groups steps by diagnostic in array of objects

    Input: [
      {id: 1, title: 'Step 1 - Diag 1', diagnostic: { widget: 1, title: 'Diagnostic 1' }},
      {id: 3, title: 'Step 3 - Diag 2', diagnostic: { widget: 2, title: 'Diagnostic 2' }},
    ]

    Output: [
      {
        groupId: 1,
        groupTitle: 'Diagnostic 1',
        elements: [
          {id: 1, title: 'Step 1 - Diag 1', diagnostic: { title: 'Diagnostic 1' }},
        ]
      },
      {
        groupId: 2,
        groupTitle: 'Diagnostic 2',
        elements: [
          {id: 3, title: 'Step 3 - Diag 2', diagnostic: { title: 'Diagnostic 2' }},
        ]
      },
    ]

    Reference: https://stackoverflow.com/a/23600960
  */
  const keys = [
    {
      keyName: "groupTitle",
      pathToKey: "diagnostic.name",
    },
    {
      keyName: "groupId",
      pathToKey: "diagnostic.id",
      isNumber: true,
    },
  ];
  let stepsGroupedByDiagnostic = groupElementsByKeys(getFilteredStepsSelector(state), keys);
  if (diagnosticId) {
    if (diagnosticId.constructor === Array) {
      stepsGroupedByDiagnostic = stepsGroupedByDiagnostic.filter((diagnostic) =>
        diagnosticId.includes(diagnostic.groupId)
      );
    } else {
      stepsGroupedByDiagnostic = stepsGroupedByDiagnostic.filter(
        (diagnostic) => diagnostic.groupId === parseInt(diagnosticId, 10)
      );
    }
  }
  return stepsGroupedByDiagnostic;
};

export const formatSmartDiagnosticStepsSelector = (steps) => {
  /* Format steps by transforming the 'symptoms' property into 'tags' for ElementsGroup */
  return (
    steps &&
    steps.map((step) => {
      let formattedStep = {};
      for (let key of Object.keys(step)) {
        if (key === "symptoms") {
          formattedStep["tags"] = step[key];
        } else {
          formattedStep[key] = step[key];
        }
      }
      return formattedStep;
    })
  );
};

export const getWidgetsGroupedByTypeSelector = (state, widgetTypes) => {
  const keys = [{ keyName: "groupTitle", pathToKey: "widget_type" }];
  return groupElementsByKeys(getFilteredWidgetsSelector(state, widgetTypes), keys);
};

export const getDiagnosticIdFromRouteSelector = (route) => {
  var diagnosticId = null;
  if (route.startsWith(`${ADMIN_CMS_STEP_ROUTE}/`) || route.startsWith(`${ADMIN_CMS_SMART_DIAGNOSTIC_ROUTE}/`)) {
    diagnosticId = route.substring(route.lastIndexOf("/") + 1);
  }
  return diagnosticId;
};

export const getFilteredQuickAccessElementsSelector = (state, route) => {
  if (includes(route, "/diagnostic")) {
    if (includes(route, ADMIN_CMS_STEP_EDITION_ROUTE)) {
      /* step edition case */
      var diagnosticId = getStepDiagnosticId(state);
    } else if (route.startsWith(`${ADMIN_CMS_STEP_ROUTE}/`)) {
      /* diagnostic edition case, beware the condition is also verified for step edition do not modify order */
      diagnosticId = getDiagnosticIdFromRouteSelector(route);
    }
    return stepsGroupedByDiagnosticSelector(state, diagnosticId);
  } else if (includes(route, "/knowledge-base")) {
    const widgetTypes = ["article", "tutoriel", "form"];
    return getWidgetsGroupedByTypeSelector(state, widgetTypes);
  } else if (includes(route, "/catalog")) {
    const widgetTypes = ["catalog"];
    return getWidgetsGroupedByTypeSelector(state, widgetTypes);
  } else {
    return null;
  }
};

export const getQuickAccessRedirectionRouteSelector = (route, quickAccessGroupType) => {
  if (includes(route, "/diagnostic")) {
    return ADMIN_CMS_STEP_EDITION_ROUTE;
  } else if (includes(route, "/knowledge-base")) {
    return widgetEditionRouteSelector(quickAccessGroupType);
  } else if (includes(route, "/catalog")) {
    return ADMIN_CMS_CATALOG_EDITION_ROUTE;
  } else {
    return null;
  }
};

export const widgetEditionRouteSelector = (widgetType) => {
  switch (widgetType) {
    case "article":
      return ADMIN_CMS_KNOWLEDGE_BASE_ARTICLE_EDITION_ROUTE;
    case "form":
      return ADMIN_CMS_KNOWLEDGE_BASE_FORM_EDITION_ROUTE;
    case "tutoriel":
      return ADMIN_CMS_KNOWLEDGE_BASE_TUTORIAL_EDITION_ROUTE;
    default:
      return "";
  }
};

export const widgetCreationActionSelector = (widgetType) => {
  switch (widgetType) {
    case "article":
      return initializeNewArticle;
    case "form":
      return initializeNewForm;
    case "tutoriel":
      return initializeNewTutorial;
    default:
      return undefined;
  }
};

/**
 * Return a list of widgets groups which are objects: [{groupKey, groupElements}]
 * Only the following widgets types are returned: article, tutoriel and form
 */
export const getWidgetsGroupedByType = (state) => {
  const keys = [
    {
      keyName: "groupKey",
      pathToKey: "widget_type",
    },
  ];
  return groupElementsByKeys(get(state, "cms.widgets"), keys).filter((group) =>
    ["article", "tutoriel", "form"].includes(group.groupKey)
  );
};

export const getFilteredStepsByDiagnostic = (state, diagnostic) => {
  return state.cms.steps && filter(state.cms.steps, (step) => step.diagnostic.id === diagnostic);
};

export const getStepTitleSelector = (state, stepId) => {
  return get(
    find(state.cms.steps, (step) => step.id === stepId),
    "title"
  );
};

export const getCMSMenuElementsSelector = () => {
  return [
    {
      id: 1,
      name: "Base de connaissances",
      order: 3,
      route: "/knowledge-base",
    },
    {
      id: 2,
      name: "Produits & applications",
      order: 1,
      route: "/product",
    },
    {
      id: 3,
      name: "Informations générales",
      order: 2,
      route: "/general-information",
    },
    {
      id: 4,
      name: "Gestion des images",
      order: 4,
      route: "/images-management",
    },
  ];
};

export const getCMSMenuElementRouteSelector = (menuElementId) => {
  const menuElements = getCMSMenuElementsSelector();
  return get(
    find(menuElements, (element) => element.id === menuElementId),
    "route"
  );
};

export const isElementsGroupActiveSelector = (elementFilter, activeElementsGroupsTitle, elementsGroupTitle) => {
  return !!elementFilter || findIndex(activeElementsGroupsTitle, (title) => title === elementsGroupTitle) >= 0;
};

export function* getApplications(action) {
  const token = yield call(getTokenFromLocalStorage);
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/common/application/`,
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  try {
    const applications = yield call(request, config);
    yield put(getApplicationsSuccess(applications));
  } catch (error) {
    yield put(getApplicationsError(error));
  }
}

export function* getApplicationFamilies(action) {
  const token = yield call(getTokenFromLocalStorage);
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/common/application-family/`,
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  try {
    const applicationFamilies = yield call(request, config);
    yield put(getApplicationFamiliesSuccess(applicationFamilies));
  } catch (error) {
    yield put(getApplicationFamiliesError(error));
  }
}

export function* getSteps(action) {
  const token = yield call(getTokenFromLocalStorage);
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/widget/diagnostic/step/`,
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  try {
    const steps = yield call(request, config);
    yield put(getStepsSuccess(steps));
  } catch (error) {
    yield put(getStepsError(error));
  }
}

export function* getWidgets(action) {
  const token = yield call(getTokenFromLocalStorage);
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/widget/`,
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  try {
    const widgets = yield call(request, config);
    yield put(getWidgetsSuccess(widgets));
  } catch (error) {
    yield put(getWidgetsError(error));
  }
}

export function* deleteWidget(action) {
  const token = yield call(getTokenFromLocalStorage);
  const config = {
    method: "delete",
    url: `${process.env.REACT_APP_API_URL}/widget/${action.payload}/`,
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  try {
    yield call(request, config);
    yield put(deleteWidgetSuccess(action.payload));
    yield call(toastr.success, "Succès", "L'élément a bien été supprimé");
  } catch (error) {
    yield put(deleteWidgetError(error));
    yield call(toastr.error, "Erreur", "L'élément n'a pas été supprimé");
  }
}

export function* saveEmptyStep(action) {
  const token = yield call(getTokenFromLocalStorage);
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/widget/diagnostic/step/`,
    headers: { Authorization: `Token ${token}` },
    data: {
      title: action.payload.stepTitle,
      diagnostic: action.payload.diagnostic,
      step_bar_content: `${action.payload.stepTitle.substring(0, 29)}...`,
    },
  };
  try {
    const step = yield call(request, config);
    yield put(saveEmptyStepSucces(step));
    yield call(toastr.success, "Succès", "Étape créée");
  } catch (error) {
    yield call(toastr.error, "Erreur", "L'étape n'a pas été créée");
  }
}

export function* saveEmptyStepSaga() {
  yield takeLatest(SAVE_EMPTY_STEP_REQUEST, saveEmptyStep);
}

export function* getApplicationsSaga() {
  yield takeEvery(GET_APPLICATIONS_REQUEST, getApplications);
}

export function* getApplicationFamiliesSaga() {
  yield takeEvery(GET_APPLICATION_FAMILIES_REQUEST, getApplicationFamilies);
}

export function* getStepsSaga() {
  yield takeEvery(GET_STEPS_REQUEST, getSteps);
}

export function* getWidgetsSaga() {
  yield takeLatest(GET_WIDGETS_REQUEST, getWidgets);
}

export function* deleteWidgetSaga() {
  yield takeLatest(DELETE_WIDGET_REQUEST, deleteWidget);
}

const initialState = {
  activeElementsGroupsTitle: [],
  activeMenuElementId: null,
  articles: [],
  applications: [],
  applicationFamilies: [],
  elementFilter: null,
  error: null,
  steps: [],
  widgets: [],
};

/**
 * Following the duck pattern, the module.js file should export a reducer as a default function
 */
export function reducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_NEW_WIDGET_SUCCESS:
      return {
        ...state,
        widgets: [
          ...state.widgets,
          {
            company: action.payload.widget.company,
            id: action.payload.widget.id,
            title: action.payload.widget.name,
            widget_type: action.payload.widget.widget_type,
          },
        ],
      };
    case DELETE_APPLICATION_SUCCESS:
      return {
        ...state,
        applications: state.applications.filter(
          (application) => application.id !== action.payload.deletedApplicationId
        ),
      };
    case DELETE_APPLICATION_FAMILY_SUCCESS:
      return {
        ...state,
        applicationFamilies: state.applicationFamilies.filter(
          (appFamily) => appFamily.id !== action.payload.deletedApplicationFamilyId
        ),
        applications: state.applications.map((application) => ({
          ...application,
          application_family:
            application.application_family === action.payload.deletedApplicationFamilyId
              ? null
              : application.application_family,
        })),
      };
    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: state.products.filter((product) => product.id !== action.payload.deletedProductId),
      };
    case SAVE_APPLICATION_SUCCESS:
      return {
        ...state,
        applications: addOrReplaceByKey(state.applications, "id", action.payload.application),
      };
    case SAVE_APPLICATION_FAMILY_SUCCESS:
      return {
        ...state,
        applicationFamilies: addOrReplaceByKey(state.applicationFamilies, "id", action.payload.applicationFamily),
      };
    case SAVE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: addOrReplaceByKey(state.products, "id", action.payload.product),
      };
    case GET_APPLICATIONS_SUCCESS:
      return {
        ...state,
        applications: action.payload,
      };
    case GET_APPLICATIONS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case GET_APPLICATION_FAMILIES_SUCCESS:
      return {
        ...state,
        applicationFamilies: action.payload,
      };
    case GET_APPLICATION_FAMILIES_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case GET_STEPS_SUCCESS:
      return {
        ...state,
        steps: action.payload,
      };
    case GET_STEPS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case GET_WIDGETS_SUCCESS:
      // TODO: Store directly the widgets list. The replacement of title by name is no longer needed
      return {
        ...state,
        widgets: action.payload.map((widget) => ({
          company: widget.company,
          id: widget.id,
          title: widget.name,
          widget_type: widget.widget_type,
        })),
      };
    case GET_WIDGETS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_WIDGET_REQUEST:
      return {
        ...state,
      };
    case DELETE_WIDGET_SUCCESS:
      return {
        ...state,
        widgets: filter(state.widgets, (widget) => widget.id !== action.payload),
      };
    case DELETE_WIDGET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SAVE_EMPTY_STEP_SUCCESS:
      const { diagnostic, diagnostic_name: diagnosticName, ...step } = action.payload.step;
      const newStep = {
        ...step,
        diagnostic: {
          id: diagnostic,
          name: diagnosticName,
        },
      };
      return {
        ...state,
        steps: [...state.steps, newStep],
      };
    case SET_ELEMENT_FILTER:
      return {
        ...state,
        elementFilter: action.payload,
      };
    case RESET_ELEMENT_FILTER:
      return {
        ...state,
        elementFilter: null,
      };
    case SET_ACTIVE_MENU_ELEMENT:
      return {
        ...state,
        activeMenuElementId: action.payload,
      };
    case RESET_ACTIVE_MENU_ELEMENT:
      return {
        ...state,
        activeMenuElementId: null,
      };
    case DELETE_STEP_SUCCESS:
      return {
        ...state,
        steps: filter(state.steps, (step) => step.id !== action.payload),
      };
    case SET_ACTIVE_ELEMENTS_GROUP:
      const elementsGroupTitle = action.payload;
      return {
        ...state,
        activeElementsGroupsTitle: isElementsGroupActiveSelector(
          state.elementFilter,
          state.activeElementsGroupsTitle,
          elementsGroupTitle
        )
          ? filter(state.activeElementsGroupsTitle, (title) => title !== elementsGroupTitle)
          : [...state.activeElementsGroupsTitle, elementsGroupTitle],
      };
    default:
      return state;
  }
}

export default reducer;
