import { styled } from "@material-ui/core";
import Box from "@material-ui/core/Box";

export default styled(Box)(({ theme }) => ({
  color: theme.palette.secondary.main,
  marginTop: 0,
  marginBottom: "0px",
  fontSize: "24px",
  [theme.breakpoints.up("md")]: {
    fontSize: "24px",
  },
  lineHeight: "110%",
  fontWeight: 500,
  letterSpacing: "-.05em",
}));
