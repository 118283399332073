import { useMemo } from "react";

import { Diagnostic, DiagnosticItem, FulfilledReplacement, SparePartFitting } from "types/diagnostic";

const extractSparePartFitting = (diagnostic: Diagnostic) => {
  function isFulFilled(item: DiagnosticItem): item is FulfilledReplacement {
    return item.type === "replacement" && !!item.spare_part_fitting;
  }
  function isSparePartFitting(item: DiagnosticItem): item is SparePartFitting {
    return item.type === "spare_part_fitting";
  }

  const nested_spare_part_fittings = diagnostic.diagnostic_items.filter(isFulFilled).map((replacement) => {
    return replacement.spare_part_fitting;
  });
  const root_spare_part_fittings = diagnostic.diagnostic_items.filter(isSparePartFitting);
  return root_spare_part_fittings.concat(nested_spare_part_fittings);
};

export const useDiagnosticSparePartFittings = (diagnostic: Diagnostic) => {
  return useMemo(() => extractSparePartFitting(diagnostic), [diagnostic]);
};
