import { Theme } from "@material-ui/core";
import Button, { ButtonProps as CoreButtonProps } from "@material-ui/core/Button";
import { styled } from "@material-ui/styles";

// sizes
export const JUMBO = "jumbo";
export const SMALL = "small";

// variants
export const FANCY = "fancy";
export const INVISIBLE = "invisible";
export const GHOST = "ghost";

// default
export const DEFAULT = "default";

type Variant = typeof FANCY | typeof INVISIBLE | typeof DEFAULT | typeof GHOST;
type Dimension = typeof JUMBO | typeof SMALL | typeof DEFAULT;

interface ButtonProps extends CoreButtonProps {
  version?: Variant;
  dimension?: Dimension;
}

const buttonSizes = (theme: Theme, dimension: Dimension) => {
  switch (dimension) {
    case JUMBO:
      return {
        height: theme.spacing(7),
      };
    case SMALL:
      return {
        height: theme.spacing(5),
      };
    default:
      return {
        height: theme.spacing(6),
      };
  }
};

const buttonVariant = (theme: any, variant: Variant) => {
  switch (variant) {
    case FANCY:
      return {
        background: "linear-gradient(90deg, #F64F6E 0%, #FF8787 100%)",
        color: "white",
        "&:hover": {
          boxShadow: "0px 4px 4px rgba(27, 27, 27, 0.1)",
        },
      };
    case INVISIBLE:
      return {
        color: theme.palette.primary.main,
        backgroundColor: "transparent",
        "&:hover": {
          color: theme.palette.primary.dark,
          backgroundColor: "transparent",
          boxShadow: "unset",
        },
      };
    case GHOST:
      return {
        color: theme.palette.secondary.grey900,
        backgroundColor: theme.palette.secondary.contrastBackground,
        border: `1px dashed ${theme.palette.secondary.grey900}`,
        "&:hover": {
          color: theme.palette.primary.dark,
          backgroundColor: "transparent",
          boxShadow: "unset",
        },
      };
    default:
      return {};
  }
};

export default styled(Button)<any, ButtonProps>(({ theme, version = DEFAULT, dimension = DEFAULT }) => {
  const sizeProps = buttonSizes(theme, dimension);
  const variantProps = buttonVariant(theme, version);
  return {
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      boxShadow: "0px 4px 4px rgba(27, 27, 27, 0.1)",
    },
    "&:active": {
      boxShadow: "0px 2px 2px rgba(27, 27, 27, 0.1)",
    },
    "&.Mui-disabled": {
      backgroundColor: theme.palette.secondary.greyHome,
      background: "None",
      color: theme.palette.secondary.grey700,
    },
    "&.Mui-label": {
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "150%",
    },
    whiteSpace: "nowrap",
    padding: `${theme.spacing(1)}px  ${theme.spacing(3)}px`,
    ...sizeProps,
    ...variantProps,
  };
});
