import React, { Component } from "react";

import List from "@material-ui/core/List";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core/styles";

import Moment from "moment";

import { getEmployeeFullName } from "utils/repairmenSortedList";

import PaymentEditionModal from "./PaymentEditionModal";

function getModeName(mode) {
  return modeList.find((label) => label.id === mode) && modeList.find((label) => label.id === mode).name;
}

function getPaymentNature(nature) {
  return natureList.find((label) => label.id === nature) && natureList.find((label) => label.id === nature).name;
}

const modeList = [
  { id: "prepayed_online", name: "Prépayé en ligne" },
  { id: "payed_online", name: "Payé en ligne" },
  { id: "credit_card", name: "Carte de crédit" },
  { id: "check", name: "Chèque" },
  { id: "cash", name: "Espèces" },
  { id: "bank_transfer", name: "Virement bancaire" },
  { id: "credit_card_deferred", name: "Empreinte de carte" },
];

const natureList = [
  { id: "payment", name: "Paiement" },
  { id: "refund", name: "Remboursement" },
];

const CustomTableCell = withStyles(() => ({
  head: {
    background: "rgba(135, 149, 179, 0.1)",
    color: "#2B3956",
    fontSize: 13,
    lineHeight: "160%",
    borderBottom: "1px solid #DBDFE8",
    padding: "4px 0px 4px 24px",
    height: "29px",
    fontFamily: "Poppins",
  },
  body: {
    color: "#2B3956",
    fontSize: 16,
    lineHeight: "140%",
    borderBottom: "1px solid #DBDFE8",
    padding: "4px 0px 4px 24px",
    fontFamily: "Poppins",
  },
}))(TableCell);

const CustomerFilePaymentDetailsStyles = {
  table: {
    color: "#2B3956",
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#FFF",
      color: "#2B3956",
      height: "54px",
    },
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#EEE",
    },
  },
  refundStyle: {
    color: "#F85B5B",
  },
  editIcon: {
    height: "auto",
    color: "#8795B3",
  },
};
const StyledTableRow = withStyles({
  root: {
    height: "29px",
    padding: "0 24px",
  },
})(TableRow);

const StyledList = withStyles({
  root: {
    padding: 0,
  },
})(List);

export class PaymentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditPaymentModalOpen: false,
    };
  }

  getEmployeeName(employeeId) {
    if (!employeeId) {
      return "-";
    }
    const employee = this.props.employees.find((employee) => parseInt(employee.id) === employeeId);
    return employee ? getEmployeeFullName(employee) : "Aucun";
  }

  handleOpenPaymentModificationModal = (id) => {
    this.setState({
      isEditPaymentModalOpen: true,
      selectedPaymentId: id,
    });
  };

  handleClosePaymentModificationModal = () => {
    this.setState({ isEditPaymentModalOpen: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <StyledList>
        <Table>
          <TableHead className={classes.head}>
            <StyledTableRow>
              <CustomTableCell>Date</CustomTableCell>
              <CustomTableCell>Mode</CustomTableCell>
              <CustomTableCell>Tech</CustomTableCell>
              <CustomTableCell>Type</CustomTableCell>
              <CustomTableCell>Montant</CustomTableCell>
              <CustomTableCell>Prestataire</CustomTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {this.props.ids &&
              this.props.ids.map(
                (id, index) =>
                  this.props.data &&
                  this.props.data[id] && (
                    <TableRow
                      className={classes.row}
                      key={index}
                      id={`row-${index}`}
                      onClick={() => this.handleOpenPaymentModificationModal(this.props.data[id])}
                    >
                      <CustomTableCell>
                        {this.props.data[id].payment_date
                          ? Moment(this.props.data[id].payment_date).format("DD/MM/YYYY")
                          : Moment(this.props.data[id].created_at).format("DD/MM/YYYY")}
                      </CustomTableCell>
                      <CustomTableCell>{getModeName(this.props.data[id].payment_method)}</CustomTableCell>
                      <CustomTableCell>{this.getEmployeeName(this.props.data[id].employee)}</CustomTableCell>
                      <CustomTableCell>
                        {this.props.data[id].payment_nature ? (
                          getPaymentNature(this.props.data[id].payment_nature)
                        ) : (
                          <span>Paiement</span>
                        )}
                      </CustomTableCell>
                      {this.props.data[id].payment_nature === "refund" ? (
                        <CustomTableCell className={classes.refundStyle}>
                          {this.props.data[id].amount ? this.props.data[id].amount / 100 : 0} €
                        </CustomTableCell>
                      ) : (
                        <CustomTableCell>
                          {this.props.data[id].amount ? this.props.data[id].amount / 100 : 0} €
                        </CustomTableCell>
                      )}
                      <CustomTableCell>{this.props.data[id].payment_solution}</CustomTableCell>
                    </TableRow>
                  )
              )}
          </TableBody>
        </Table>
        {this.state.isEditPaymentModalOpen ? (
          <PaymentEditionModal
            handleClosePaymentModificationModal={this.handleClosePaymentModificationModal}
            record={this.state.selectedPaymentId}
            paymentModeList={modeList}
            isOpen={this.state.isEditPaymentModalOpen}
            getRelatedPayments={this.props.getRelatedPayments}
            employees={this.props.employees}
          />
        ) : null}
      </StyledList>
    );
  }
}

export default withStyles(CustomerFilePaymentDetailsStyles)(PaymentList);
