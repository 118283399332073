export const PAYMENT_NATURE_REFUND = "refund";
export const PAYMENT_NATURE_PAYMENT = "payment";

export const PAYMENT_NATURES = [
  { id: PAYMENT_NATURE_PAYMENT, name: "Paiement" },
  { id: PAYMENT_NATURE_REFUND, name: "Remboursement" },
];

export const PAYMENT_MODE_CREDIT_CARD = "credit_card";
export const PAYMENT_MODE_BANK_TRANSFER = "bank_transfer";

export const PAYMENT_MODES = [
  { id: PAYMENT_MODE_CREDIT_CARD, name: "Carte bancaire" },
  { id: "check", name: "Chèque" },
  { id: "cash", name: "Espèces" },
  { id: "prepayed_online", name: "Prépayé en ligne" },
  { id: "payed_online", name: "Payé en ligne" },
  { id: PAYMENT_MODE_BANK_TRANSFER, name: "Virement bancaire" },
];

export const TVA_RATE = 0.2;
