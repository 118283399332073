import React from "react";

import { useField } from "react-final-form";

import GooglePlacesController from "components/GooglePlacesComponents/GooglePlacesController";
import { getFieldFromAddress } from "components/GooglePlacesComponents/utils";

/**
 * GooglePlacesInput is made to be used inside a react-final-form form as it relies on useField hook.
 * It enables address selection through GooglePlaces'autocomplete and maps it directly to Murfy's
 * address components inside the underlying form.
 * @param {*} props object passed directly to the underlying GooglePlacesController as inputProps
 */
const GooglePlacesInput = (props) => {
  const {
    input: { onChange: onStreetNumberChange },
  } = useField("street_number");
  const {
    input: { onChange: onStreetChange },
  } = useField("street");
  const {
    input: { onChange: onCityChange },
  } = useField("city");
  const {
    input: { onChange: onPostalCodeChange },
  } = useField("postal_code");
  const {
    input: { onChange: onAddressChange },
  } = useField("address");

  const handlePlaceChange = (props, formattedAddress) => {
    onStreetNumberChange(getFieldFromAddress(props, "street_number"));
    onStreetChange(getFieldFromAddress(props, "route"));
    onCityChange(getFieldFromAddress(props, "locality"));
    onPostalCodeChange(getFieldFromAddress(props, "postal_code"));
    onAddressChange(formattedAddress);
  };

  return (
    <GooglePlacesController
      onPlaceChange={handlePlaceChange}
      autocompleteElementId="user_input_autocomplete_address_customer_file_creation"
      inputProps={props.inputProps}
    />
  );
};

export default GooglePlacesInput;
