export const DelayFieldStyles = {
  wrapperBadge: {
    display: "inline-flex",
    alignItems: "baseline",
  },
  textBadge: {
    paddingLeft: 10,
    color: "#F85B5B",
  },
  delayBadge: {
    height: 10,
    width: 10,
    borderRadius: "50%",
    display: "inline-block",
  },
  red: {
    backgroundColor: "#F85B5B",
  },
  orange: {
    backgroundColor: "#F7B349",
  },
  green: {
    backgroundColor: "#81D89F",
  },
  gray: {
    backgroundColor: "#bbb",
  },
  textRed: {
    color: "#F85B5B",
  },
  textOrange: {
    color: "#F7B349",
  },
  textGreen: {
    color: "#81D89F",
  },
  textGray: {
    color: "#bbb",
  },
};
