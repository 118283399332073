import { connect } from "react-redux";

import MurfyCalendar3by3 from "./MurfyCalendar3by3";
import { getCalendarTimeslotsRequest, resetTimeslots, setFirstDisplayedDay, setSelectedTimeslot } from "./module";

function mapStateToProps(state) {
  return {
    firstDisplayedDay: state.calendar.firstDisplayedDay,
    isCalendarLoading: state.calendar.isCalendarLoading,
    nextTimeslot: state.calendar.nextTimeslot,
    selectedTimeslotId: state.calendar.selectedTimeslotId,
    timeslots: state.calendar.timeslots,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCalendarTimeslots(postalCode, beginningTimestamp, endTimestamp, city, street, streetNumber) {
      dispatch(getCalendarTimeslotsRequest(postalCode, beginningTimestamp, endTimestamp, city, street, streetNumber));
    },
    setFirstDisplayedDay(timestamp) {
      dispatch(setFirstDisplayedDay(timestamp));
    },
    setSelectedTimeslot(timeslot) {
      dispatch(setSelectedTimeslot(timeslot));
    },
    resetTimeslots() {
      dispatch(resetTimeslots());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MurfyCalendar3by3);
