// React imports
import React from "react";

// MUI
import { Box } from "@material-ui/core";

import ColorChip from "components/atoms/ColorChip";

import {
  ADDITIONAL_PACKAGE,
  AFTER_SALES_PACKAGE,
  BASIC_PACKAGE,
  COMMERCIAL_GESTURE,
  CONSUMABLE,
  DISCOUNTED_PACKAGE,
  HERMETIC_UNIT,
  LEGACY_QUOTE,
  REDUCTION_CODE,
  REPAIR_BONUS,
  SPARE_PART,
  SPONSORSHIP_REWARD,
  VISIO_DISCOUNT,
} from "../../services/constants";
import AdditionalPackage from "./AdditionalPackage";
import AfterSalesPackage from "./AfterSalesPackage";
import BasicPackage from "./BasicPackage";
import CommercialGesture from "./CommercialGesture";
import Consumable from "./Consumable";
import DefaultElementDisplay from "./DefaultElementDisplay";
import DiscountedPackage from "./DiscountedPackage";
import HermeticUnit from "./HermeticUnit";
import LegacyQuote from "./LegacyQuote";
import ReductionCode from "./ReductionCode/ReductionCode";
import SparePart from "./SparePart";
import SponsorshipReward from "./SponsorshipReward";

const QuoteElement = ({
  record,
  isModifiable = false,
  removeElementInQuote = null,
  index = -1,
  handleOpenPackageModificationModal,
  packageTypeList,
  isNewSparePartAdded,
  handleSparePartModification,
  addNewElementsInQuote,
  billingElementsList,
  customerFile,
  productId,
}) => {
  const isDeletable = (elementType) => elementType !== BASIC_PACKAGE;

  const renderElement = (element, packageTypeList, isModifiable) => {
    switch (element.element_type) {
      case ADDITIONAL_PACKAGE:
        return (
          <AdditionalPackage
            isModifiable={isModifiable}
            isDeletable={isDeletable(record.element_type)}
            element={element}
            removeElementInQuote={removeElementInQuote}
          />
        );
      case BASIC_PACKAGE:
        return (
          <BasicPackage
            index={index}
            handleOpenPackageModificationModal={handleOpenPackageModificationModal}
            isModifiable={isModifiable && !isNewSparePartAdded}
            element={element}
            packageTypeList={packageTypeList}
            productId={productId}
          />
        );

      case COMMERCIAL_GESTURE:
        return (
          <CommercialGesture
            isModifiable={isModifiable}
            isDeletable={isDeletable(record.element_type)}
            element={element}
            removeElementInQuote={removeElementInQuote}
          />
        );

      case DISCOUNTED_PACKAGE:
        return (
          <DiscountedPackage
            isModifiable={isModifiable}
            isDeletable={isDeletable(record.element_type)}
            element={element}
            removeElementInQuote={removeElementInQuote}
          />
        );

      case HERMETIC_UNIT:
        return (
          <HermeticUnit
            isModifiable={isModifiable}
            isDeletable={isDeletable(record.element_type)}
            element={element}
            removeElementInQuote={removeElementInQuote}
          />
        );
      case CONSUMABLE:
        return (
          <Consumable
            isModifiable={isModifiable}
            isDeletable={isDeletable(record.element_type)}
            element={element}
            removeElementInQuote={removeElementInQuote}
          />
        );

      case LEGACY_QUOTE:
        return <LegacyQuote element={element} />;

      case REDUCTION_CODE:
        return (
          <ReductionCode
            isModifiable={isModifiable}
            isDeletable={isDeletable(record.element_type)}
            element={element}
            removeElementInQuote={removeElementInQuote}
          />
        );
      case SPARE_PART:
        return (
          <SparePart
            element={element}
            customerFile={customerFile}
            isModifiable={isModifiable}
            addNewElementsInQuote={addNewElementsInQuote}
            billingElementsList={billingElementsList}
            handleSparePartModification={handleSparePartModification}
            isDeletable={isDeletable(record.element_type)}
            index={index}
            removeElementInQuote={removeElementInQuote}
            productId={productId}
          />
        );

      case AFTER_SALES_PACKAGE:
        return (
          <AfterSalesPackage
            isModifiable={isModifiable}
            isDeletable={isDeletable(record.element_type)}
            element={element}
            removeElementInQuote={removeElementInQuote}
          />
        );

      case SPONSORSHIP_REWARD:
        return isDeletable ? (
          <SponsorshipReward element={element} remove={removeElementInQuote} />
        ) : (
          <SponsorshipReward element={element} />
        );

      case REPAIR_BONUS:
      case VISIO_DISCOUNT:
        return <DefaultElementDisplay element={element} remove={removeElementInQuote} />;

      default:
        console.warn(`Received unexpected quote element type: ${element.element_type}`);
    }
  };

  return (
    <Box minHeight={64} display="grid" textAlign="initial" borderBottom="1px solid #DBDFE8">
      {renderElement(record, packageTypeList, isModifiable)}
      {record.auto_generated && (
        <Box mx={1} mb={1}>
          <ColorChip label="Généré automatiquement" hexColor="#F7B349" />
        </Box>
      )}
    </Box>
  );
};

export default QuoteElement;
