/**
 * Following the duck pattern, actions, constants and reducers are in the same file called module.js
 *
 * See: https://github.com/erikras/ducks-modular-redux
 *
 */
import get from "lodash/get";
import { sendAuthenticatedGetRequestToAPI, sendAuthenticatedRequestToAPI } from "modules/generators/requestsToAPI";
import { toastr } from "react-redux-toastr";
import { all, call, put, select, takeEvery } from "redux-saga/effects";

import groupElementsByKeys from "utils/groupElementsByKeys";
import { getAPIUrl, getTokenFromLocalStorage, request } from "utils/networking";

export const processTypeMapping = [
  { key: "symptom_checking", value: "Symptômes" },
  { key: "technical_fault", value: "Pannes techniques" },
  { key: "usage_fault", value: "Pannes d'usage" },
];

export const GET_FAULTS_REQUEST = "CMS/GET_FAULTS_REQUEST";
export const GET_FAULTS_SUCCESS = "CMS/GET_FAULTS_SUCCESS";
export const GET_FAULTS_ERROR = "CMS/GET_FAULTS_ERROR";

export const GET_SYMPTOMS_REQUEST = "CMS/GET_SYMPTOMS_REQUEST";
export const GET_SYMPTOMS_SUCCESS = "CMS/GET_SYMPTOMS_SUCCESS";
export const GET_SYMPTOMS_ERROR = "CMS/GET_SYMPTOMS_ERROR";

export const GET_SYMPTOMS_AND_FAULTS_FROM_CHECKING_PROCESS = "CMS/GET_SYMPTOMS_AND_FAULTS_FROM_CHECKING_PROCESS";

export const GET_CHECKING_PROCESSES_REQUEST = "CMS/GET_CHECKING_PROCESSES_REQUEST";
export const GET_CHECKING_PROCESSES_SUCCESS = "CMS/GET_CHECKING_PROCESSES_SUCCESS";
export const GET_CHECKING_PROCESSES_ERROR = "CMS/GET_CHECKING_PROCESSES_ERROR";

export const POST_SYMPTOM_REQUEST = "CMS/POST_SYMPTOM_REQUEST";
export const POST_SYMPTOM_SUCCESS = "CMS/POST_SYMPTOM_SUCCESS";
export const POST_SYMPTOM_ERROR = "CMS/POST_SYMPTOM_ERROR";

export const POST_CHECKING_PROCESS_REQUEST = "CMS/POST_CHECKING_PROCESS_REQUEST";
export const POST_CHECKING_PROCESS_ERROR = "CMS/POST_CHECKING_PROCESS_ERROR";

export const PATCH_SYMPTOM_CHECKING_PROCESS_ORDER_REQUEST = "CMS/PATCH_SYMPTOM_CHECKING_PROCESS_ORDER_REQUEST";
export const PATCH_SYMPTOM_CHECKING_PROCESS_ORDER_SUCCESS = "CMS/PATCH_SYMPTOM_CHECKING_PROCESS_ORDER_SUCCESS";
export const PATCH_SYMPTOM_CHECKING_PROCESS_ORDER_ERROR = "CMS/PATCH_SYMPTOM_CHECKING_PROCESS_ORDER_ERROR";

export const POST_SYMPTOM_CHECKING_PROCESS_REQUEST = "CMS/POST_SYMPTOM_CHECKING_PROCESS_REQUEST";
export const POST_SYMPTOM_CHECKING_PROCESS_ERROR = "CMS/POST_SYMPTOM_CHECKING_PROCESS_ERROR";

export const RELOAD_SYMPTOMS_AFTER_POST_SUCCESS = "CMS/RELOAD_SYMPTOM_AFTER_POST_SUCCESS";

export const DELETE_SYMPTOM_CHECKING_PROCESS_REQUEST = "CMS/DELETE_SYMPTOM_CHECKING_PROCESS_REQUEST";
export const DELETE_SYMPTOM_CHECKING_PROCESS_SUCCESS = "CMS/DELETE_SYMPTOM_CHECKING_PROCESS_SUCCESS";
export const DELETE_SYMPTOM_CHECKING_PROCESS_ERROR = "CMS/DELETE_SYMPTOM_CHECKING_PROCESS_ERROR";

export const POST_FAULT_REQUEST = "CMS/POST_FAULT_REQUEST";
export const POST_FAULT_SUCCESS = "CMS/POST_FAULT_SUCCESS";
export const POST_FAULT_ERROR = "CMS/POST_FAULT_ERROR";

export function getFaultsRequest(diagnosticId) {
  return {
    payload: diagnosticId,
    type: GET_FAULTS_REQUEST,
  };
}

export function getFaultsSuccess(faults) {
  return {
    type: GET_FAULTS_SUCCESS,
    payload: faults,
  };
}

export function getFaultsError(error) {
  return {
    type: GET_FAULTS_ERROR,
    payload: error,
  };
}

export function getSymptomsAndFaultsFromCheckingProcessAction(checkingProcessDiagnosticId) {
  return {
    type: GET_SYMPTOMS_AND_FAULTS_FROM_CHECKING_PROCESS,
    payload: checkingProcessDiagnosticId,
  };
}

export function getSymptomsRequest(diagnosticId) {
  return {
    payload: diagnosticId,
    type: GET_SYMPTOMS_REQUEST,
  };
}

export function getSymptomsSuccess(applications) {
  return {
    type: GET_SYMPTOMS_SUCCESS,
    payload: applications,
  };
}

export function getSymptomsError(error) {
  return {
    type: GET_SYMPTOMS_ERROR,
    payload: error,
  };
}

export function getCheckingProcessesRequest(diagnosticId) {
  return {
    payload: diagnosticId,
    type: GET_CHECKING_PROCESSES_REQUEST,
  };
}

export function getCheckingProcessesSuccess(checkingProcesses) {
  return {
    type: GET_CHECKING_PROCESSES_SUCCESS,
    payload: checkingProcesses,
  };
}

export function getCheckingProcessesError(error) {
  return {
    type: GET_CHECKING_PROCESSES_ERROR,
    payload: error,
  };
}

export function postSymptomRequest(diagnosticId, diagnosticType, symptomName) {
  return {
    payload: { diagnosticId, diagnosticType, symptomName },
    type: POST_SYMPTOM_REQUEST,
  };
}

export function postSymptomSuccess(symptom) {
  return {
    type: POST_SYMPTOM_SUCCESS,
    payload: symptom,
  };
}

export function postSymptomError(error) {
  return {
    type: POST_SYMPTOM_ERROR,
    payload: error,
  };
}

export function postCheckingProcessRequest(symptomId, elementCategory, elementTitle) {
  return {
    payload: { symptomId, elementCategory, elementTitle },
    type: POST_CHECKING_PROCESS_REQUEST,
  };
}

export function postCheckingProcessError(error) {
  return {
    type: POST_CHECKING_PROCESS_ERROR,
    payload: error,
  };
}

export function patchSymptomCheckingProcessOrderRequest(symptomCheckingProcessId, order, isIncreased) {
  return {
    type: PATCH_SYMPTOM_CHECKING_PROCESS_ORDER_REQUEST,
    payload: { symptomCheckingProcessId, order, isIncreased },
  };
}

export function patchSymptomCheckingProcessOrderSuccess(symptomCheckingProcess, isIncreased) {
  return {
    type: PATCH_SYMPTOM_CHECKING_PROCESS_ORDER_SUCCESS,
    payload: { ...symptomCheckingProcess, isIncreased },
  };
}

export function patchSymptomCheckingProcessOrderError(error) {
  return {
    type: PATCH_SYMPTOM_CHECKING_PROCESS_ORDER_ERROR,
    payload: error,
  };
}

export function postSymptomCheckingProcessRequest(symptomId, checkingProcessId) {
  return {
    type: POST_SYMPTOM_CHECKING_PROCESS_REQUEST,
    payload: { symptomId, checkingProcessId },
  };
}

export function postSymptomCheckingProcessError(error) {
  return {
    type: POST_SYMPTOM_CHECKING_PROCESS_ERROR,
    payload: error,
  };
}

export function deleteSymptomCheckingProcessRequest(symptomCheckingProcessId) {
  return {
    type: DELETE_SYMPTOM_CHECKING_PROCESS_REQUEST,
    payload: symptomCheckingProcessId,
  };
}

export function deleteSymptomCheckingProcessSuccess() {
  return {
    type: DELETE_SYMPTOM_CHECKING_PROCESS_SUCCESS,
  };
}

export function deleteSymptomCheckingProcessError(error) {
  return {
    type: DELETE_SYMPTOM_CHECKING_PROCESS_ERROR,
    payload: error,
  };
}

export function postFaultRequest(diagnosticId, diagnosticType, faultName) {
  return {
    type: POST_FAULT_REQUEST,
    payload: { diagnosticId, diagnosticType, faultName },
  };
}

export function postFaultSuccess(fault) {
  return {
    type: POST_FAULT_SUCCESS,
    payload: fault,
  };
}

export function postFaultError(error) {
  return {
    type: POST_FAULT_ERROR,
    payload: error,
  };
}

export function getSolutionTreesIdsSelector(state) {
  return state.smartDiagnosticEdition.faults && state.smartDiagnosticEdition.faults.map((fault) => fault.solution_tree);
}

export function getCheckingProcessesIdsSelector(state) {
  return state.smartDiagnosticEdition.checkingProcesses.map((checkingProcess) => checkingProcess.checking_process);
}

export function reloadSymptomsAfterPostSuccess() {
  return {
    type: RELOAD_SYMPTOMS_AFTER_POST_SUCCESS,
  };
}

export const getDiagnosticIdSelector = (state) => state.smartDiagnosticEdition.diagnosticId;

export const getFaultsSelector = (state) => state.smartDiagnosticEdition.faults;

export const getFaultTitleSelector = (state, faultId) => {
  const targetFault = state.smartDiagnosticEdition.faults.find((fault) => fault.id === faultId);
  return targetFault && targetFault.title;
};

export const getSymptomsSelector = (state) => state.smartDiagnosticEdition.symptoms;

export const getSymptomTitleSelector = (state, symptomId) => {
  const targetSymptom = state.smartDiagnosticEdition.symptoms.find((symptom) => symptom.id === symptomId);
  return targetSymptom && targetSymptom.title;
};

export const getSymptomsCheckingProcessesSelector = (state) => {
  const { checkingProcesses, symptoms } = state.smartDiagnosticEdition;
  /* The id of the syptoms' checking processes are replaced by the id of
   *  the corresponding SymptomCheckingProcess object to be able to request these objects
   */
  return (
    symptoms &&
    checkingProcesses &&
    symptoms.map((symptom) => ({
      ...symptom,
      checking_processes: symptom.checking_processes.map((orderedCheckingProcess) => ({
        ...checkingProcesses.find(
          (checkingProcess) => checkingProcess.id === orderedCheckingProcess.checking_process_id
        ),
        order: orderedCheckingProcess.order,
        id: orderedCheckingProcess.id,
      })),
    }))
  );
};

export const formatSymptomsCheckingProcessListSelector = (state) => {
  const keys = [{ keyName: "category", pathToKey: "process_type" }];
  return getSymptomsCheckingProcessesSelector(state).map((symptom) => {
    const { checking_processes: checkingProcesses, ...formattedSymptom } = symptom;
    return {
      ...formattedSymptom,
      categorizedElementsGroups: groupElementsByKeys(checkingProcesses, keys),
    };
  });
};

/** Gather checking processes (CP) by symptoms.
 * When there is no CP of a specific type for a given symptom, an empty group including only the category label
 * is added to the symptom.
 */
export const getSymptomsCheckingProcessesWithLabelSelector = (state) => {
  return formatSymptomsCheckingProcessListSelector(state).map((symptom) => {
    return {
      ...symptom,
      categorizedElementsGroups: processTypeMapping.map((processType) => {
        return {
          categoryLabel: processType.value,
          category: processType.key,
          ...symptom.categorizedElementsGroups.find((group) => group.category === processType.key),
        };
      }),
    };
  });
};

export const getGroupedCheckingProcessesSelector = (state) => {
  const { checkingProcesses } = state.smartDiagnosticEdition;
  const keys = [{ keyName: "category", pathToKey: "process_type" }];
  const processTypeMapping = [
    { key: "symptom_checking", value: "Symptômes" },
    { key: "technical_fault", value: "Pannes techniques" },
    { key: "usage_fault", value: "Pannes d'usage" },
  ];
  return groupElementsByKeys(checkingProcesses, keys).map((checkingProcess) => ({
    ...checkingProcess,
    categoryLabel: get(
      processTypeMapping.find((element) => element.key === checkingProcess.category),
      "value"
    ),
  }));
};

export function* getFaults(action) {
  const diagnosticId = action.payload;
  const route = `/smart-diagnostic/fault/?smart_diagnostic_id=${diagnosticId}`;
  yield call(sendAuthenticatedGetRequestToAPI, route, getFaultsSuccess, getFaultsError);
}

export function* getFaultsSaga() {
  yield takeEvery(GET_FAULTS_REQUEST, getFaults);
}

export function* postFault(action) {
  const { diagnosticType, diagnosticId, faultName } = action.payload;

  const data = {
    title: faultName,
  };
  const url = `${process.env.REACT_APP_API_URL}/smart-diagnostic/fault/`;

  const config = {
    data: diagnosticType === "smart_diagnostic" ? { ...data, smart_diagnostic: diagnosticId } : data,
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    url: diagnosticType === "checking_process" ? `${url}?checking-process-id=${diagnosticId}` : url,
  };
  const toastrConfig = {
    success: { title: "Succès", message: "Panne créée avec succès" },
    error: { title: "Erreur", message: "La création de la panne a échoué" },
  };

  yield call(sendAuthenticatedRequestToAPI, config, postFaultSuccess, postFaultError, toastrConfig);
}

export function* postFaultSaga() {
  yield takeEvery(POST_FAULT_REQUEST, postFault);
}

export function* getSymptoms(action) {
  const diagnosticId = yield select(getDiagnosticIdSelector);
  const route = `/smart-diagnostic/symptom/?diagnostic_id=${diagnosticId}`;
  yield call(sendAuthenticatedGetRequestToAPI, route, getSymptomsSuccess, getSymptomsError);
}

export function* getSymptomsSaga() {
  yield takeEvery(GET_SYMPTOMS_REQUEST, getSymptoms);
}

/**
 * Saga to get the faults and symptoms of the smart diagnostic related to a given checking_process diagnostic
 * First get the id of the smart diagnostic, then retrieve its faults and symptoms
 * @param {Object} action The action providing the id of the checking_process diagnostic
 */
export function* getSymptomsAndFaultsFromCheckingProcess(action) {
  const route = `/smart-diagnostic/checking-process/?checking_process_id=${action.payload}`;
  const token = yield call(getTokenFromLocalStorage);
  const config = {
    method: "get",
    url: `${getAPIUrl()}${route}`,
    headers: { Authorization: `Token ${token}` },
  };
  try {
    const checkingProcess = yield call(request, config);
    const smartDiagnosticId = checkingProcess[0].smart_diagnostic;
    yield all([put(getFaultsRequest(smartDiagnosticId)), put(getSymptomsRequest(smartDiagnosticId))]);
  } catch (error) {
    yield call(
      toastr.error,
      "Erreur",
      "Impossible de récupérer les pannes et symptômes associés à la procédure de vérification courante"
    );
  }
}

export function* getSymptomsAndFaultsFromCheckingProcessSaga() {
  yield takeEvery(GET_SYMPTOMS_AND_FAULTS_FROM_CHECKING_PROCESS, getSymptomsAndFaultsFromCheckingProcess);
}

export function* reloadSymptomsAfterSuccessSaga() {
  yield takeEvery(DELETE_SYMPTOM_CHECKING_PROCESS_SUCCESS, getSymptoms);
}

export function* getCheckingProcesses(action) {
  const diagnosticId = action.payload;
  const route = `/smart-diagnostic/checking-process/?smart_diagnostic_id=${diagnosticId}`;
  yield call(sendAuthenticatedGetRequestToAPI, route, getCheckingProcessesSuccess, getCheckingProcessesError);
}

export function* getCheckingProcessesSaga() {
  yield takeEvery(GET_CHECKING_PROCESSES_REQUEST, getCheckingProcesses);
}

export function* postCheckingProcess(action) {
  const { symptomId, elementCategory, elementTitle } = action.payload;
  const data = {
    process_type: elementCategory,
    symptom: symptomId,
    title: elementTitle,
  };
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/smart-diagnostic/checking-process/`,
  };

  const toastrConfig = {
    success: { title: "Succès", message: "Élément créé avec succès" },
    error: { title: "Erreur", message: "L'élément' n'a pas été enregistré" },
  };

  yield call(
    sendAuthenticatedRequestToAPI,
    config,
    reloadSymptomsAfterPostSuccess,
    postCheckingProcessError,
    toastrConfig,
    [symptomId]
  );
}

export function* postCheckingProcessSaga() {
  yield takeEvery(POST_CHECKING_PROCESS_REQUEST, postCheckingProcess);
}

export function* postSymptom(action) {
  const { diagnosticType, diagnosticId, symptomName } = action.payload;

  const data = {
    title: symptomName,
  };
  const url = `${process.env.REACT_APP_API_URL}/smart-diagnostic/symptom/`;

  const config = {
    data: diagnosticType === "smart_diagnostic" ? { ...data, smart_diagnostic: diagnosticId } : data,
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    url: diagnosticType === "checking_process" ? `${url}?checking-process-id=${diagnosticId}` : url,
  };

  const toastrConfig = {
    success: { title: "Succès", message: "Symptôme créé avec succès" },
    error: { title: "Erreur", message: "La création du symptôme a échoué" },
  };
  yield call(sendAuthenticatedRequestToAPI, config, postSymptomSuccess, postSymptomError, toastrConfig);
}

export function* postSymptomSaga() {
  yield takeEvery(POST_SYMPTOM_REQUEST, postSymptom);
}

export function* patchSymptomCheckingProcessOrder(action) {
  const { isIncreased, order, symptomCheckingProcessId } = action.payload;
  const config = {
    data: {
      order: isIncreased ? order + 1 : order - 1,
    },
    headers: {
      "Content-Type": "application/json",
    },
    method: "patch",
    url: `${process.env.REACT_APP_API_URL}/smart-diagnostic/symptoms-checking-processes/${symptomCheckingProcessId}/`,
  };
  const toastrConfig = {
    error: { title: "Erreur", message: "Modification impossible" },
  };
  yield call(
    sendAuthenticatedRequestToAPI,
    config,
    patchSymptomCheckingProcessOrderSuccess,
    patchSymptomCheckingProcessOrderError,
    toastrConfig,
    [isIncreased]
  );
}

export function* patchSymptomCheckingProcessOrderSaga() {
  yield takeEvery(PATCH_SYMPTOM_CHECKING_PROCESS_ORDER_REQUEST, patchSymptomCheckingProcessOrder);
}

export function* postSymptomCheckingProcess(action) {
  const { symptomId, checkingProcessId } = action.payload;
  const data = {
    symptom: symptomId,
    checking_process: checkingProcessId,
  };
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/smart-diagnostic/symptoms-checking-processes/`,
  };

  const toastrConfig = {
    success: { title: "Succès", message: "Élément enregistré avec succès" },
    error: { title: "Erreur", message: "L'élément' n'a pas été enregistré" },
  };

  yield call(
    sendAuthenticatedRequestToAPI,
    config,
    reloadSymptomsAfterPostSuccess,
    postSymptomCheckingProcessError,
    toastrConfig
  );
}

export function* postSymptomCheckingProcessSaga() {
  yield takeEvery(POST_SYMPTOM_CHECKING_PROCESS_REQUEST, postSymptomCheckingProcess);
}

export function* deleteSymptomCheckingProcess(action) {
  const symptomCheckingProcessId = action.payload;
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "delete",
    url: `${process.env.REACT_APP_API_URL}/smart-diagnostic/symptoms-checking-processes/${symptomCheckingProcessId}/`,
  };
  const toastrConfig = {
    success: { title: "Succès", message: "L'élément a bien été supprimé" },
    error: { title: "Erreur", message: "L'élément n'a pas été supprimé" },
  };

  yield call(
    sendAuthenticatedRequestToAPI,
    config,
    deleteSymptomCheckingProcessSuccess,
    deleteSymptomCheckingProcessError,
    toastrConfig
  );
}

export function* deleteSymptomCheckingProcessSaga() {
  yield takeEvery(DELETE_SYMPTOM_CHECKING_PROCESS_REQUEST, deleteSymptomCheckingProcess);
}

export function* reloadSymptomsAndCheckingProcessesList() {
  const diagnosticId = yield select(getDiagnosticIdSelector);
  yield put(getCheckingProcessesRequest(diagnosticId));
  yield put(getSymptomsRequest(diagnosticId));
}

export function* reloadSymptomsAndCheckingProcessesListSaga() {
  yield takeEvery(RELOAD_SYMPTOMS_AFTER_POST_SUCCESS, reloadSymptomsAndCheckingProcessesList);
}

export const initialState = {
  error: null,
  checkingProcesses: [],
  checkingProcess: null,
  diagnosticId: null,
  faults: [],
  symptoms: [],
};

/**
 * Following the duck pattern, the module.js file should export a reducer as a default function
 */
export function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_FAULTS_REQUEST:
      return {
        ...state,
        diagnosticId: action.payload,
      };
    case GET_FAULTS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case GET_FAULTS_SUCCESS:
      return {
        ...state,
        faults: action.payload,
      };
    case GET_SYMPTOMS_REQUEST:
      return {
        ...state,
        diagnosticId: action.payload,
      };
    case GET_SYMPTOMS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case GET_SYMPTOMS_SUCCESS:
      return {
        ...state,
        symptoms: action.payload,
      };
    case GET_CHECKING_PROCESSES_REQUEST:
      return {
        ...state,
        diagnosticId: action.payload,
      };
    case GET_CHECKING_PROCESSES_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case GET_CHECKING_PROCESSES_SUCCESS:
      return {
        ...state,
        checkingProcesses: action.payload,
      };
    case POST_SYMPTOM_REQUEST:
      return {
        ...state,
        error: null,
      };
    case POST_SYMPTOM_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case POST_SYMPTOM_SUCCESS:
      return {
        ...state,
        symptoms: [...state.symptoms, action.payload],
      };
    case POST_CHECKING_PROCESS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case PATCH_SYMPTOM_CHECKING_PROCESS_ORDER_SUCCESS:
      const { isIncreased, checking_process: patchedCheckingProcess, symptom: patchedSymptom, order } = action.payload;
      return {
        ...state,
        symptoms: state.symptoms.map((symptom) =>
          symptom.id === patchedSymptom
            ? {
                ...symptom,
                checking_processes:
                  symptom.checking_processes &&
                  symptom.checking_processes.map((checkingProcess) =>
                    checkingProcess.checking_process_id === patchedCheckingProcess
                      ? {
                          ...checkingProcess,
                          order: order,
                        }
                      : checkingProcess.order === order
                      ? {
                          ...checkingProcess,
                          order: isIncreased ? order - 1 : order + 1,
                        }
                      : checkingProcess
                  ),
              }
            : symptom
        ),
      };
    case PATCH_SYMPTOM_CHECKING_PROCESS_ORDER_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case POST_SYMPTOM_CHECKING_PROCESS_REQUEST:
      return {
        ...state,
        error: null,
      };
    case POST_SYMPTOM_CHECKING_PROCESS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case POST_FAULT_REQUEST:
      return {
        ...state,
        error: null,
      };
    case POST_FAULT_SUCCESS:
      return {
        ...state,
        faults: [...state.faults, action.payload],
      };
    case POST_FAULT_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_SYMPTOM_CHECKING_PROCESS_REQUEST:
      return {
        ...state,
        error: null,
      };
    case DELETE_SYMPTOM_CHECKING_PROCESS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default reducer;
