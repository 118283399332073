import { AFTER_SALES, COLLECTION, DELIVERY, RECYCLING } from "constants/shipping";

export const DELIVERY_TYPE_OPTIONS = [
  { id: "installation", label: "Installation" },
  { id: "sidewalk", label: "Au pied du domicile" },
  { id: "room", label: "Dans la pièce de son choix" },
];

// Delivery item types
export const DELIVERY_ITEM_TYPES = [
  { id: DELIVERY, name: "Livraison" },
  { id: COLLECTION, name: "Collecte" },
  { id: RECYCLING, name: "Recyclage" },
  { id: AFTER_SALES, name: "Reprise après-vente" },
];

// orderlines states
export const CANCELLED = "CANCELLED";
export const CANCELLED_REPUBLISHED = "CANCELLED_REPUBLISHED";

export const shouldOrderLineBeDisabled = (orderLine) => {
  return !orderLine || orderLine.state === CANCELLED || orderLine.state === CANCELLED_REPUBLISHED;
};

export const isOrderLineCancelled = (orderLine) => {
  return orderLine.state.startsWith(CANCELLED);
};

export const isOrderCancelled = (order) => {
  return order.state === CANCELLED;
};

export const hasCollectionItems = (order) =>
  order.items.any((item) => [COLLECTION, RECYCLING].includes(item.delivery_type));
