import React from "react";

import { useNotify } from "react-admin";

import { Box, styled } from "@material-ui/core";

import theme from "customTheme";
import { CustomerFileDetailed } from "types/customer-files";

import Button from "components/atoms/Button";
import { Small } from "components/atoms/Typography";
import Caption from "components/atoms/Typography/Caption";
import CustomerFileDetailsUpdateModal from "components/molecules/CustomerFileDetailsUpdateModal";

import { copyToClipboard } from "utils/CopyToClipboard.js";

type CustomerFileDetailsCardProps = {
  customerFile: CustomerFileDetailed;
  updateCustomerFileDetail: (id: number, values: CustomerFileDetailed) => void;
};

const StyledBox = styled(Box)(({ theme }: any) => ({
  marginY: "auto",
  color: theme.palette.secondary.contrastText,
  display: "grid",
}));

const StyledRow = styled(Box)({
  display: "grid",
  gridTemplateColumns: "0.5fr 1fr 2fr 0.25fr",
  columnGap: theme.spacing(3),
});

const StyledContainer = styled(Box)(({ theme }: any) => ({
  border: "1px solid #DBDFE8",
  borderRadius: 12,
  padding: theme.spacing(3),
}));

const StyledCaption = styled(Caption)({
  textDecoration: "underline",
});

const CustomerFileDetailsCard: React.FunctionComponent<CustomerFileDetailsCardProps> = ({
  customerFile,
  updateCustomerFileDetail,
}) => {
  const notify = useNotify();

  return (
    <StyledContainer>
      <StyledRow>
        <StyledBox my="auto">
          <Button
            onClick={(event) =>
              copyToClipboard(customerFile.file_number, event, notify, "Référence du dossier client copiée")
            }
            version="invisible"
          >
            <StyledCaption>{customerFile.file_number} </StyledCaption>
          </Button>
        </StyledBox>
        <StyledBox>
          <Small my="auto">
            <b>Mail: &nbsp;</b>
            {customerFile.email}
          </Small>
          <Small my="auto">
            <b>Téléphone: &nbsp;</b> {customerFile.phone}
          </Small>
        </StyledBox>
        <StyledBox>
          <Small my="auto">
            <b>Adresse: &nbsp;</b>
            {customerFile.address}
          </Small>
          <Small my="auto">
            <b>Complément adresse: &nbsp;</b> {customerFile.extra_information}
          </Small>
        </StyledBox>
        <Box margin="auto">
          <CustomerFileDetailsUpdateModal
            customerFile={customerFile}
            updateCustomerFileDetail={updateCustomerFileDetail}
          />
        </Box>
      </StyledRow>
    </StyledContainer>
  );
};

export default CustomerFileDetailsCard;
