import * as React from "react";

import { SelectInput, SimpleForm } from "react-admin";
import { useDataProvider, useNotify, useRefresh } from "react-admin";

import { makeStyles } from "@material-ui/core";

import { EXCLUDED_STATES, ORDER_STATES } from "components/OrderStateField";

import Toolbar from "./Toolbar";

const useStyle = makeStyles((theme) => ({
  stateForm: {
    display: "flex",
    marginLeft: "auto",
    "& > div": {
      margin: 0,
      display: "flex",
    },
  },
}));

export default (props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const classes = useStyle();

  const update = (record) => {
    dataProvider
      .update("ecom/orders", { id: record.id, data: { state: record.state } })
      .then((response) => {
        notify("Statut modifié");
        refresh();
      })
      .catch((error) => {
        alert(error.message);
        notify(error.message, "warning");
      });
  };

  return (
    <SimpleForm
      {...props}
      redirect={false}
      toolbar={<Toolbar label="Modifier le statut" />}
      className={classes.stateForm}
      save={update}
    >
      <SelectInput
        source="state"
        optionText="label"
        label="Statut"
        choices={ORDER_STATES.filter((s) => !EXCLUDED_STATES.includes(s.id))}
      />
    </SimpleForm>
  );
};
