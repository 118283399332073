import React, { Component } from "react";

import PropTypes from "prop-types";

import { toastr } from "react-redux-toastr";

export default class DeleteOrReorderElements extends Component {
  static propTypes = {
    handleDelete: PropTypes.func,
    handleOrderDecrease: PropTypes.func,
    handleOrderIncrease: PropTypes.func,
    id: PropTypes.number,
  };

  confirmDelete() {
    const toastrConfirmOptions = {
      onOk: () => (this.props.id ? this.props.handleDelete(this.props.id) : this.props.handleDelete()),
      okText: "Oui",
      onCancel: () => {},
      cancelText: "Non",
    };
    toastr.confirm("Êtes-vous sûr de vouloir supprimer cet élément ?", toastrConfirmOptions);
  }

  handleDelete = (event) => {
    this.confirmDelete();
    event.stopPropagation();
  };

  handleOrderDecrease = (event) => {
    this.props.handleOrderDecrease();
    event.stopPropagation();
  };

  handleOrderIncrease = (event) => {
    this.props.handleOrderIncrease();
    event.stopPropagation();
  };

  render() {
    return (
      <div>
        {this.props.handleDelete && <i className="fal fa-trash-alt selector-field-icon" onClick={this.handleDelete} />}
        {this.props.handleOrderIncrease && (
          <i className="fal fas fa-arrow-alt-up selector-field-icon" onClick={this.handleOrderDecrease} />
        )}
        {this.props.handleOrderDecrease && (
          <i className="fal fas fa-arrow-alt-down selector-field-icon" onClick={this.handleOrderIncrease} />
        )}
      </div>
    );
  }
}
