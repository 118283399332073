import React, { Component } from "react";

import PropTypes from "prop-types";

import "./style.css";

export default class DropdownEditionFieldOption extends Component {
  static propTypes = {
    children: PropTypes.any.isRequired,
    option: PropTypes.any,
    onSelect: PropTypes.func.isRequired,
  };

  onClick = (event) => this.props.onSelect(this.props.option, event);

  render() {
    return (
      <div className="dropdown-edition-field-container" onClick={this.onClick}>
        {this.props.children}
      </div>
    );
  }
}
