import * as React from "react";
import { useEffect, useState } from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import dataProvider from "dataProvider";

import RefundRow from "./RefundRow";

export default ({ order_id }) => {
  const [refunds, setRefunds] = useState([]);

  useEffect(() => {
    dataProvider.getList("refunds", { sort: {}, filter: { order: order_id } }).then(({ data }) => {
      setRefunds(data);
    });
  }, [order_id]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Motif</TableCell>
          <TableCell>Montant</TableCell>
          <TableCell>Date</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {refunds.map((refund) => (
          <RefundRow refund={refund} />
        ))}
      </TableBody>
    </Table>
  );
};
