import React from "react";

import { Route } from "react-router-dom";

import Loadable from "react-loadable";

import {
  ADMIN_ACCOUNT_ROUTE,
  ADMIN_AFFECTATION_ROUTE_CUSTOMER_FILES,
  ADMIN_CMS_ROUTE,
  ADMIN_CUSTOMER_FILES_ROUTE,
  ADMIN_INTERVENTION_ROUTE,
  ADMIN_PRODUCTS_ROUTE,
  ADMIN_PRODUCT_MODELS_ROUTE,
  ADMIN_SHIPPING_ROUTE,
  ADMIN_SKU_STORE_ROUTE,
  ADMIN_WORKSHOPS_ROUTE,
  MARKETPLACE_EXPORT_ROUTE,
  SKU_DEMANDS,
  WORKSHOP_FILES_ROUTE,
} from "constants/routes";

const Account = Loadable({
  loader: () => import(/* webpackChunkName: "Account" */ "pages/Account"),
  loading() {
    return <div />;
  },
});

const ContentManagement = Loadable({
  loader: () => import(/* webpackChunkName: "ContentManagement" */ "pages/Cms/ContentManagement"),
  loading() {
    return <div />;
  },
});

const Interventions = Loadable({
  loader: () => import(/* webpackChunkName: "Interventions" */ "pages/Interventions"),
  loading() {
    return <div />;
  },
});

const CustomerFiles = Loadable({
  loader: () => import(/* webpackChunkName: "CustomerFiles" */ "admin/pages/CustomerFiles"),
  loading() {
    return <div />;
  },
});

const WorkshopFiles = Loadable({
  loader: () => import(/* webpackChunkName: "WorkshopFiles" */ "pages/workshopFiles"),
  loading() {
    return <div />;
  },
});

const SkuStore = Loadable({
  loader: () => import(/* webpackChunkName: "SkuStore" */ "pages/SkuStore"),
  loading() {
    return <div />;
  },
});

const SkuStoreWorkshop = Loadable({
  loader: () => import(/* webpackChunkName: "SkuStoreWorkshop" */ "pages/SkuStoreWorkshop"),
  loading() {
    return <div />;
  },
});

const MyTasks = Loadable({
  loader: () => import(/* webpackChunkName: "MyTasks" */ "pages/Tasks"),
  loading() {
    return <div />;
  },
});

const MarketplaceExport = Loadable({
  loader: () => import(/* webpackChunkName: "MarketplaceExport" */ "pages/MarketplaceExport"),
  loading() {
    return <div />;
  },
});

const Shipping = Loadable({
  loader: () => import(/* webpackChunkName: "Shipping" */ "pages/Shipping"),
  loading() {
    return <div />;
  },
});

const Products = Loadable({
  loader: () => import(/* webpackChunkName: "Products" */ "pages/Products"),
  loading() {
    return <div />;
  },
});

const Workshops = Loadable({
  loader: () => import(/* webpackChunkName: "Workshops" */ "pages/Workshops"),
  loading() {
    return <div />;
  },
});

const ProductModels = Loadable({
  loader: () => import(/* webpackChunkName: "ProductModels" */ "pages/ProductModels"),
  loading() {
    return <div />;
  },
});

export default [
  <Route path={ADMIN_ACCOUNT_ROUTE} component={Account} />,
  <Route path={`${ADMIN_CMS_ROUTE}`} component={ContentManagement} />,
  <Route path={`${ADMIN_INTERVENTION_ROUTE}`} component={Interventions} />,
  <Route path={`${ADMIN_CUSTOMER_FILES_ROUTE}`} component={CustomerFiles} />,
  <Route path={`${ADMIN_SKU_STORE_ROUTE}`} component={SkuStore} />,
  <Route path={`${ADMIN_AFFECTATION_ROUTE_CUSTOMER_FILES}`} component={MyTasks} />,
  <Route path={`${MARKETPLACE_EXPORT_ROUTE}`} component={MarketplaceExport} />,
  <Route path={`${SKU_DEMANDS}`} component={SkuStoreWorkshop} />,
  <Route path={`${WORKSHOP_FILES_ROUTE}`} component={WorkshopFiles} />,
  <Route path={`${ADMIN_SHIPPING_ROUTE}`} component={Shipping} />,
  <Route path={`${ADMIN_PRODUCTS_ROUTE}`} component={Products} />,
  <Route path={`${ADMIN_WORKSHOPS_ROUTE}`} component={Workshops} />,
  <Route path={`${ADMIN_PRODUCT_MODELS_ROUTE}`} component={ProductModels} />,
];
