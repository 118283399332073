import React, { useMemo } from "react";

import { Box, Grid } from "@material-ui/core";

import moment from "moment";
import { Timeslot } from "types/timeslot";

import ChoiceButton from "components/atoms/ChoiceButton";
import { Body } from "components/atoms/Typography";
import BodyBold from "components/atoms/Typography/BodyBold";

import { formatDate } from "utils/dates";

type TimeslotsPerDateProps = {
  timeslots: Timeslot[];
  selectedDate: string;
  handleSelectTimeslot: (timeslot: Timeslot) => void;
  isSelected: (timeslot: Timeslot) => boolean;
};

const TimeslotsPerDate: React.FC<TimeslotsPerDateProps> = ({
  timeslots,
  selectedDate,
  handleSelectTimeslot,
  isSelected,
}) => {
  const dateHeaders = useMemo(() => {
    const headers = [];
    for (let i = 0; i < 3; i++) {
      headers.push(moment(selectedDate).add(i, "days"));
    }
    return headers;
  }, [selectedDate]);

  const reorderTimeslots = (a: Timeslot, b: Timeslot) => {
    const aBeginning = moment(a.beginning);
    const bBeginning = moment(b.beginning);
    if (aBeginning.isBefore(bBeginning)) {
      return -1;
    }
    if (aBeginning.isAfter(bBeginning)) {
      return 1;
    }
    return 0;
  };

  const groupedTimeslots = useMemo(() => {
    return dateHeaders.map((header) =>
      timeslots.filter((timeslot) => moment(timeslot.beginning).isSame(header, "day")).sort(reorderTimeslots)
    );
  }, [dateHeaders, timeslots]);

  const isSelectedTimeslot = (timeslot: Timeslot) => {
    return isSelected(timeslot);
  };

  return (
    <>
      {dateHeaders.map((header, index) => {
        const timeslotsForDate = groupedTimeslots[index];
        const hasTimeslots = timeslotsForDate.length > 0;
        return (
          <Grid key={index} item xs style={{ textAlign: "center" }}>
            <Box minHeight={240}>
              <BodyBold>{formatDate(header)}</BodyBold>
              <Box mt={2}>
                {hasTimeslots ? (
                  timeslotsForDate.map((timeslot) => (
                    <ChoiceButton
                      key={timeslot.id}
                      onClick={() => handleSelectTimeslot(timeslot)}
                      isActive={isSelectedTimeslot(timeslot)}
                      my={1}
                    >
                      {moment(timeslot.beginning).format("HH")}h - {moment(timeslot.end).format("HH")}h
                      {timeslot.score && (
                        <>
                          <br />
                          <i>{timeslot.score.toFixed(3)}</i>
                        </>
                      )}
                    </ChoiceButton>
                  ))
                ) : (
                  <Body>-</Body>
                )}
              </Box>
            </Box>
          </Grid>
        );
      })}
    </>
  );
};

export default TimeslotsPerDate;
