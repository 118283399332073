import { QuoteElement } from "types/quote-element";

import { VAT_RATE } from "./constants";

export const getPretaxPrice = (priceWithTax: number): number => {
  return Math.ceil(priceWithTax / (1 + VAT_RATE));
};

export const sortByIdAndHomeRepair = (elements: QuoteElement[]): QuoteElement[] => {
  return elements.sort((element1, element2) => {
    if (element1.home_repair_product !== element2.home_repair_product) {
      return (element1.home_repair_product || 0) > (element2.home_repair_product || 0) ? -1 : 1;
    }
    return element1.id < element2.id ? -1 : 1;
  });
};

export const homeRepairPaletteArray = ["#F64F6E", "#1A8E96", "#0000FF", "#757576"];
