import React, { Component } from "react";

import PropTypes from "prop-types";

import ElementaryBlock from "admin/components/ElementaryBlock";
import sortBy from "lodash/sortBy";

import "./style.css";

export default class ElementsGroup extends Component {
  static propTypes = {
    elements: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        tags: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number.isRequired,
            title: PropTypes.string.isRequired,
          })
        ),
        title: PropTypes.string.isRequired,
      }).isRequired
    ),
    newElementLabel: PropTypes.string,
    selectedElement: PropTypes.number,
    title: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    onDeleteElement: PropTypes.func,
    onCreateElement: PropTypes.func,
  };

  static defaultProps = {
    newElementLabel: "Ajouter un nouvel élément",
  };

  handleClick = (element) => () => {
    this.props.onClick(element);
  };

  render() {
    return (
      <div className="pc-group-container">
        <p className="pc-group-title">{this.props.title}</p>
        <div className="row">
          {this.props.elements &&
            sortBy(this.props.elements, "title").map((element) => (
              <div className="col-sm-6 col-xs-12 pc-block-container" key={element.id}>
                <ElementaryBlock
                  id={element.id}
                  text={element.title}
                  onClick={this.handleClick(element)}
                  onDeleteElement={this.props.onDeleteElement}
                  isSelected={this.props.selectedElement === element.id}
                  tags={element.tags}
                />
              </div>
            ))}
          {this.props.onCreateElement && (
            <div className="col-sm-6 col-xs-12 pc-block-container">
              <div className="pc-new-element-container" onClick={this.props.onCreateElement}>
                <span>{`+ ${this.props.newElementLabel}`}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
