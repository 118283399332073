import Moment from "moment";

export const getWarrantyStatus = (warrantyEndDate: string) => {
  const today = Moment().format("DD-MM-YYYY");

  if (Moment(today).isAfter(warrantyEndDate)) {
    return "Garantie expirée";
  }
  return "Garantie valable";
};
