import React from "react";

import { FunctionField } from "react-admin";

import { getEmployeeFullName } from "utils/repairmenSortedList";

const EmployeeRepairReportField = ({ record, employees }) => {
  const employee = employees.find((employee) => record.employee === employee.id) || {};
  return <FunctionField render={(record) => getEmployeeFullName(employee)} />;
};

export default EmployeeRepairReportField;
