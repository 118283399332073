import React from "react";

const ProductRepairReportField = ({ customerFile }) => {
  return (
    <>
      {customerFile &&
        customerFile.products &&
        customerFile.products.map((product, index) => <div key={index}>{product && product.product_type}</div>)}
    </>
  );
};
export default ProductRepairReportField;
