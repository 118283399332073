import React from "react";

import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { Theme, createStyles, withStyles } from "@material-ui/core/styles";

interface StyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

const StyledTabs = withStyles({
  root: {
    border: "1px solid #DBDFE8",
    borderRadius: 8,
    padding: 4,
    height: 40,
    width: "fit-content",
  },
  indicator: {
    display: "none",
  },
  flexContainer: {
    height: 38,
    "& > button": {
      minHeight: 40,
      marginRight: 0,
    },
  },
})((props: StyledTabsProps) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

interface StyledTabProps {
  label: string;
}

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: "none",
      color: "#DBDFE8",
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),
      borderRadius: 8,
    },
    selected: {
      color: "#F7B349",
      fontWeight: theme.typography.fontWeightBold,
      background: "#f7b34933",
    },
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

type FancyTabsProps = {
  tabs: { label: string }[];
  handleChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
  activeTab: number;
};
const FancyTabs: React.FunctionComponent<FancyTabsProps> = ({ tabs, handleChange, activeTab }) => {
  return (
    <StyledTabs value={activeTab} onChange={handleChange}>
      {tabs.map((tab) => (
        <StyledTab label={tab.label} />
      ))}
    </StyledTabs>
  );
};

export default FancyTabs;
