// React imports
import React, { useState } from "react";

import { Query } from "react-admin";

import { Box, Typography } from "@material-ui/core";
// MUI imports
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
// Style imports
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";

// Component Imports
import Modal from "components/Modal";

// Module imports
import { mailSendingTaskRequest } from "pages/customerFiles/CustomerFileDetail/module.js";

import { usePackagePrices } from "utils/hooks/useRepairPackagePrices";

import { closureModalValidation } from "./CustomerFileClosureModal.utils";
import CustomerFileClosureModalStyles from "./CustomerFileClosureModalStyles";

const useStyles = makeStyles(CustomerFileClosureModalStyles);

export default function CustomerFileClosureModal({
  updateCustomerFileDetail,
  getCustomerFileDetail,
  isOpen,
  newCustomerFileStatusId,
  id,
  handleCloseCustomerFileClosureModal,
  customerFilesStates,
  handleCustomerFileStatusChange,
  shouldMailSelectorsBeDisabled,
  customerFilesTags,
  customerFile,
  resetClosureForm,
  isCancellingStatus,
}) {
  const classes = useStyles();

  const [isSendingClosureMailChecked, setIsSendingClosureMailChecked] = useState(true);
  const [isClosureModalValidationButtonDisabled, setIsClosureModalValidationButtonDisabled] = useState(false);
  const [isEditingCouponAmount, setIsEditingCouponAmount] = useState(false);

  const isMultipleProducts = customerFile && customerFile.has_multiple_products;
  const isReopened = customerFile && customerFile.is_reopened;

  const { data: repairPrices } = usePackagePrices(customerFile && customerFile.postal_code);
  const basicPackagePrice = repairPrices && repairPrices["murfy_repair_package_price"];

  const [couponAmount, setCouponAmount] = useState(
    !isMultipleProducts &&
      !isReopened &&
      customerFile &&
      // Coupon amount is initialized to zero if it was to be below repair package price
      (customerFile.total_paid + Math.min(customerFile.balance, 0) < basicPackagePrice
        ? 0
        : customerFile.total_paid + Math.min(customerFile.balance, 0)) / 100
  );

  const closureModalAndValidation = () => {
    if (isSendingClosureMailChecked && !shouldMailSelectorsBeDisabled) {
      mailSendingTaskRequest(
        id,
        parseInt(newCustomerFileStatusId.state),
        isSendingClosureMailChecked,
        shouldDisplayCoupon ? couponAmount * 100 : null
      );
    }
    updateCustomerFileDetail(id, newCustomerFileStatusId);

    handleCloseModal();
  };

  const handleCloseModal = () => {
    handleCloseCustomerFileClosureModal();
    resetClosureForm();
    setIsSendingClosureMailChecked(true);
  };

  const accountBalanceInfo = () => {
    return (
      <Query type="getOne" resource="account-balance" payload={{ id: id }}>
        {({ data, loading, error }) => {
          if (loading) {
            return <CircularProgress variant="static" />;
          } else if (error) {
            return <Typography variant="body1">Erreur</Typography>;
          } else {
            setIsClosureModalValidationButtonDisabled(
              closureModalValidation(
                customerFilesStates,
                newCustomerFileStatusId,
                data,
                customerFile,
                customerFilesTags
              )
            );
          }

          return <Typography variant="body1">{parseFloat(data).toFixed(2) / 100} €</Typography>;
        }}
      </Query>
    );
  };

  const groupedAndFilteredCustomerFilesStatesByType = customerFilesStates.filter(
    (elements) => elements.type === "Terminé non réparé" || elements.type === "Terminé réparé"
  );

  const isStateValidForCoupon = (state) =>
    state.name === "Pièce indispo" || state.name === "Non réparable" || state.name === "Refus du devis";

  const couponPropositionStateIds = customerFilesStates
    .filter((state) => isStateValidForCoupon(state))
    .map((state) => state.id);

  const shouldDisplayCoupon = couponPropositionStateIds.includes(parseInt(newCustomerFileStatusId.state));

  return (
    <Modal
      isOpen={isOpen}
      handleCloseModal={handleCloseModal}
      modalClassName={`modal-small-content ${classes.closureModalContainer}`}
    >
      <Typography className={classes.titleContainer} variant="h2">
        Clôturer le dossier
      </Typography>
      {!isCancellingStatus && (
        <div className={classes.accountBalanceInfoContainer}>
          <Typography variant="body1">Montant dû</Typography>
          {accountBalanceInfo()}
        </div>
      )}
      {shouldDisplayCoupon && (
        <div className={classes.accountBalanceInfoContainer}>
          <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="body1">Montant de l'avoir</Typography>
            {isEditingCouponAmount && (
              <TextField
                type="number"
                value={couponAmount}
                InputProps={{ disableUnderline: true }}
                className={classes.couponAmount}
                onChange={(event) => setCouponAmount(event.target.value)}
                onBlur={() => setIsEditingCouponAmount(false)}
              />
            )}
            {!isEditingCouponAmount && (
              <>
                <Typography variant="body1">{couponAmount} €</Typography>
                <EditIcon
                  style={{ position: "absolute", right: 70, cursor: "pointer" }}
                  onClick={() => setIsEditingCouponAmount(true)}
                />
              </>
            )}
          </Box>
        </div>
      )}
      {shouldDisplayCoupon && isMultipleProducts && (
        <div className={classes.accountBalanceInfoContainer}>
          <Typography textAlign="center" color="error">
            Atention ce dossier comprend plusieurs machines, le montant de l'avoir doit être vérifié.
          </Typography>
        </div>
      )}
      {shouldDisplayCoupon && isReopened && (
        <div className={classes.accountBalanceInfoContainer}>
          <Typography textAlign="center" color="error">
            Atention ce dossier fait l'objet d'une réouverture, le montant de l'avoir doit être vérifié.
          </Typography>
        </div>
      )}
      <div className={isCancellingStatus ? classes.checkboxFieldContainerCancellation : classes.checkboxFieldContainer}>
        <FormControlLabel
          className={classes.checkboxField}
          control={
            <Checkbox
              color="inherit"
              className={classes.checkboxInput}
              disabled={shouldMailSelectorsBeDisabled}
              checked={isSendingClosureMailChecked && !shouldMailSelectorsBeDisabled}
              onChange={() => setIsSendingClosureMailChecked(!isSendingClosureMailChecked)}
            />
          }
          label="Envoyer le mail de clôture"
        />
      </div>
      <div className={classes.checkboxFieldContainer}>
        {newCustomerFileStatusId && (
          <TextField
            select
            label="Issue du dossier"
            value={newCustomerFileStatusId.state}
            fullWidth
            variant="filled"
            className={classes.fieldSelection}
            onChange={(e) => handleCustomerFileStatusChange(e.target.value)}
          >
            {groupedAndFilteredCustomerFilesStatesByType.map((option) => (
              <MenuItem key={option.value} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        )}
      </div>
      <Button
        id="Validate"
        variant="contained"
        color="secondary"
        className={classes.validateButton}
        onClick={closureModalAndValidation}
        disabled={isClosureModalValidationButtonDisabled}
      >
        Clôturer le dossier
      </Button>
    </Modal>
  );
}
