// React imports
import React, { Component } from "react";

import PropTypes from "prop-types";

// MUI imports
import { Box, Typography } from "@material-ui/core";
// Style imports
import { withStyles } from "@material-ui/core/styles";

import "moment/locale/fr";

import dataProvider from "dataProvider";

import {
  ADMIN_CANCEL_WITHOUT_FEES,
  ADMIN_CANCEL_WITH_FEES,
  ADMIN_REOPENING,
  RESCHEDULE,
  SUGGEST_TIMESLOT,
} from "constants/events";

import CustomerFileMultiSuggestion from "pages/Interventions/components/CustomerFileMultiSuggestion";

import CancellationVisitWithFees from "../CancellationVisitWithFees";
import CancellationVisitWithoutFees from "../CancellationVisitWithoutFees";
import ChargeCustomerComponent from "../ChargeCustomerComponent/ChargeCustomerComponent";
import CustomerFileClosureModal from "../CustomerFileClosureModal/CustomerFileClosureModal";
import ReopeningCustomerFile from "../ReopeningCustomerFile";
// Component imports
import CustomerFileEmailActions from "./CustomerFileEmailActions/CustomerFileEmailActions";
import CustomerFileTargetStyles from "./CustomerFileTargetStyles";
import VerificationCheckActions from "./VerificationCheckActions";

export class CustomerFileTargetStatesInput extends Component {
  static propTypes = {
    classes: PropTypes.object,
    customerFile: PropTypes.object,
    customerFileId: PropTypes.number,
    customerFileStateId: PropTypes.number,
    newCustomerFileStateId: PropTypes.number,
    customerFilesStates: PropTypes.array,
    getCustomerFileDetail: PropTypes.func,
    updateCustomerFileDetail: PropTypes.func,
    isCustomerFileClosureModalOpen: PropTypes.bool,
  };

  static defaultProps = {
    classes: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      loadIframe: false,
      isIframeLoaded: false,
      isCustomerFileClosureModalOpen: false,
      isChargeSetupCardModalOpen: false,
      shoudlMailSelectorsBeDisabled: false,
      newCustomerFileStateId: null,
      hasPaymentMethod: false,
    };
  }

  resetClosureForm() {
    this.setState({
      shoudlMailSelectorsBeDisabled: false,
      isCustomerFileClosureModalOpen: false,
      isCardBeingCharged: false,
    });
  }

  getCustomerFileTargetStates(currentStateId, states = []) {
    const currentState = states.find((state) => state.id === currentStateId);
    const targetStates = (currentState && currentState.target_states) || [];
    return states.filter((state) => targetStates.includes(state.id));
  }

  handleCustomerFileStatusChange = (id, targetStateId) => {
    dataProvider.getOne("closure-mail-template", { id: parseInt(targetStateId.state) }).then((response) => {
      if (response && response.data && response.data.length > 0 && this.props.customerFile.category === "home_repair") {
        this.setState({
          newCustomerFileStateId: targetStateId,
          isCancellingStatus: response.data[0].mail_template === "closure_mail_cancellation",
          isCustomerFileClosureModalOpen: true,
          shoudlMailSelectorsBeDisabled: false,
        });
      } else if (this.state.isCustomerFileClosureModalOpen) {
        this.setState({
          isCancellingStatus: false,
          newCustomerFileStateId: targetStateId,
          shoudlMailSelectorsBeDisabled: true,
        });
      } else {
        this.props.updateCustomerFileDetail(this.props.customerFileId, targetStateId);
      }
    });
  };

  handleCloseCustomerFileClosureModal = () => {
    this.setState({ isCustomerFileClosureModalOpen: false });
  };

  render() {
    const { classes } = this.props;
    const targetStates = this.getCustomerFileTargetStates(
      this.props.customerFileStateId,
      this.props.customerFilesStates
    );

    return (
      <>
        <div className={classes.root}>
          {(targetStates.length > 0 || this.state.hasPaymentMethod) && (
            <Typography className={classes.body2Bold} variant="body2">
              Actions&nbsp;:{" "}
            </Typography>
          )}
          <div className={classes.flexWrapDiv}>
            {targetStates
              .sort((state1, state2) => (state1.order || 0) - (state2.order || 0))
              .map((targetState) => (
                <div
                  key={targetState.id}
                  className={classes.actionButton}
                  onClick={() =>
                    this.handleCustomerFileStatusChange(this.props.customerFileId, {
                      state: targetState.id,
                    })
                  }
                >
                  <i className={`${targetState.icon} ico ico-small`} />
                  <Typography className={classes.body2} variant="body2">
                    {targetState.name}
                  </Typography>
                </div>
              ))}
            <VerificationCheckActions
              getCustomerFileDetail={this.props.getCustomerFileDetail}
              customerFile={this.props.customerFile}
            />
          </div>
        </div>
        <div>
          <Box display="flex">
            <ChargeCustomerComponent
              customerFile={this.props.customerFile}
              getCustomerFileDetail={this.props.getCustomerFileDetail}
            />

            {this.props.customerFile.available_events.includes(ADMIN_CANCEL_WITH_FEES) && (
              <CancellationVisitWithFees
                customerFile={this.props.customerFile}
                getCustomerFileDetail={this.props.getCustomerFileDetail}
              />
            )}

            {this.props.customerFile.available_events.includes(ADMIN_CANCEL_WITHOUT_FEES) && (
              <CancellationVisitWithoutFees
                customerFile={this.props.customerFile}
                getCustomerFileDetail={this.props.getCustomerFileDetail}
              />
            )}
            {this.props.customerFile.available_events.includes(SUGGEST_TIMESLOT) && (
              <CustomerFileMultiSuggestion
                customerFile={this.props.customerFile}
                typeButton="BUTTON"
                getCustomerFileDetail={this.props.getCustomerFileDetail}
              />
            )}
            {this.props.customerFile.available_events.includes(RESCHEDULE) && (
              <CustomerFileMultiSuggestion
                customerFile={this.props.customerFile}
                typeButton="BUTTON"
                getCustomerFileDetail={this.props.getCustomerFileDetail}
                title="Proposer d’autres créneaux pour le prochain passage"
                route="reschedule"
              />
            )}
            {this.props.customerFile.available_events.includes(ADMIN_REOPENING) && (
              <ReopeningCustomerFile
                customerFile={this.props.customerFile}
                getCustomerFileDetail={this.props.getCustomerFileDetail}
              />
            )}
          </Box>
          {this.state.isCustomerFileClosureModalOpen && (
            <CustomerFileClosureModal
              updateCustomerFileDetail={this.props.updateCustomerFileDetail}
              getCustomerFileDetail={this.props.getCustomerFileDetail}
              isOpen={this.state.isCustomerFileClosureModalOpen}
              newCustomerFileStatusId={this.state.newCustomerFileStateId}
              id={this.props.customerFileId}
              handleCloseCustomerFileClosureModal={this.handleCloseCustomerFileClosureModal}
              customerFilesStates={this.props.customerFilesStates}
              handleCustomerFileStatusChange={this.handleCustomerFileStatusChange}
              shoudlMailSelectorsBeDisabled={this.state.shoudlMailSelectorsBeDisabled}
              customerFilesTags={this.props.customerFilesTags}
              customerFile={this.props.customerFile}
              resetClosureForm={() => this.resetClosureForm}
              isCancellingStatus={this.state.isCancellingStatus}
            />
          )}
          <CustomerFileEmailActions
            customerFileId={this.props.customerFileId}
            customerFileStateId={this.props.customerFileStateId}
          />
        </div>
      </>
    );
  }
}

export default withStyles(CustomerFileTargetStyles)(CustomerFileTargetStatesInput);
