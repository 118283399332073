import * as React from "react";

import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import Moment from "moment";

import { formatPrice } from "utils/price";

export default ({ refund }) => {
  return (
    <TableRow>
      <TableCell>{refund.reason}</TableCell>
      <TableCell>{formatPrice(refund.amount)}</TableCell>
      <TableCell>{Moment(refund.created_at).locale("fr").format("DD/MM/YYYY à HH:mm")}</TableCell>
    </TableRow>
  );
};
