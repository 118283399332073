const ChargeSetupCardModalStyles = {
  closureModalContainer: {
    margin: "auto",
    width: 588,
  },
  titleContainer: {
    textAlign: "center",
    paddingTop: 24,
  },
  subtitleContainer: {
    display: "flex",
    textAlign: "center",
  },
  subtitle: {
    color: "#2B3B5C",
    margin: "8px  auto 56px",
    display: "flex",
  },
  icon: {
    color: "#81D89F",
    marginLeft: 4,
  },
  amountContainer: {
    width: "60%",
    margin: "auto",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#F4F3F8",
    padding: "16px 24px",
    borderRadius: 16,
    textAlign: "center",
    marginBottom: 72,
  },
  amount: {
    color: "#2B3B5C",
    fontWeight: "bold",
  },
  buttonContainer: {
    display: "flex",
    margin: "auto",
    width: "fit-content",
  },
  cancelButton: {
    color: "#8795B3",
    background: "#fff",
    boxShadow: "unset",
    "&:hover": {
      backgroundColor: "#D7D7D7",
    },
  },
  validateButton: {
    borderRadius: 8,
    marginRight: 4,
  },
};

export default ChargeSetupCardModalStyles;
