const ButtonsQuoteAcceptedStyles = {
  acceptedQuoteButtonWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 24,
  },
  addNewQuoteButton: {
    color: "#2B3B5C",
    marginTop: "24px 32px 32px 0",
    boxShadow: "none",
  },
  editQuoteButton: {
    color: "#8795B3",
    background: "#fff",
    boxShadow: "none",
    marginLeft: 16,
  },
  createIcon: {
    marginLeft: "12px",
    fontSize: 16,
  },
  buttonPaiementReceived: {
    background: "#4A55FE",
    color: "#fff",
    boxShadow: "none",
    marginLeft: 16,
    "&:hover": {
      backgroundColor: "#3b44cb",
    },
  },
};
export default ButtonsQuoteAcceptedStyles;
