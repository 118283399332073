import React from "react";

import { FunctionField } from "react-admin";

import { CustomerFileSlim } from "types/customer-files";

type CustomerFileTitleFieldProps = {
  variant: string;
  record: CustomerFileSlim;
  className: string;
};

export default ({ variant, record, className }: CustomerFileTitleFieldProps) => (
  <FunctionField
    variant={variant}
    record={record}
    className={className}
    render={(record: CustomerFileSlim) => `${record.firstname} ${record.lastname}`}
  />
);
