// React imports
import React from "react";

export default function PaymentComponent(props) {
  const notPayedReasonsArray = [
    { id: "already_payed", name: "Déjà réglé en ligne" },
    { id: "customer_warranty", name: "Client sous garantie" },
    { id: "customer_service_reco", name: "SAV Reco" },
    { id: "reopening", name: "Réouverture" },
  ];

  const paymentModeArray = [
    { id: "cash", name: "En espèces" },
    { id: "credit_card", name: "Par carte" },
    { id: "check", name: "Par chèque" },
  ];

  const isPaymentDone = () => {
    if (
      (props.payment && props.payment.is_payment_done) ||
      (props.payment && notPayedReasonsArray.map((elt) => elt.id).includes(props.payment.why_payment_not_done)) ||
      props.visitPayment
    ) {
      return "Oui";
    }
    return "Non";
  };
  const renderMethodPaymentOrNotPayedReason = () => {
    if (props.payment && props.payment.is_payment_done) {
      return (
        <>
          <div className="col-12">
            <span className="ticket-detail ticket-detail-key">Montant :&nbsp; </span>
            <span className="ticket-detail ticket-detail-value">{(props.payment.amount / 100).toFixed(2)}€</span>
          </div>
          <div className="col-12">
            <span className="ticket-detail ticket-detail-key"> Moyen de paiement: </span>
            <span className="ticket-detail ticket-detail-value">
              {" "}
              &nbsp;
              {paymentModeArray.find((paymentMode) => paymentMode.id === props.payment.payment_method) &&
                paymentModeArray.find((paymentMode) => paymentMode.id === props.payment.payment_method).name}
            </span>
          </div>
        </>
      );
    }
    if (props.visitPayment) {
      return (
        <>
          <div className="col-12">
            <span className="ticket-detail ticket-detail-key">Montant : </span>
            <span className="ticket-detail ticket-detail-value">{(props.visitPayment.amount / 100).toFixed(2)}€</span>
          </div>
          <div className="col-12">
            <span className="ticket-detail ticket-detail-key"> Moyen de paiement: </span>
            <span className="ticket-detail ticket-detail-value">
              {" "}
              &nbsp;
              {paymentModeArray.find((paymentMode) => paymentMode.id === props.visitPayment.payment_method) &&
                paymentModeArray.find((paymentMode) => paymentMode.id === props.visitPayment.payment_method).name}
            </span>
          </div>
        </>
      );
    }
    if (props.payment && props.payment.why_payment_not_done) {
      return (
        <div className="col-12">
          <span className="ticket-detail ticket-detail-key"> Raison: </span>
          <span className="ticket-detail ticket-detail-value">
            {" "}
            &nbsp;
            {notPayedReasonsArray.map((elt) => elt.id).includes(props.payment.why_payment_not_done)
              ? notPayedReasonsArray.find((paymentMode) => paymentMode.id === props.payment.why_payment_not_done) &&
                notPayedReasonsArray.find((paymentMode) => paymentMode.id === props.payment.why_payment_not_done).name
              : props.payment.explanation}
          </span>
        </div>
      );
    }
  };

  return (
    <>
      <div className="details-title">
        <i className="fas fa-money-bill-alt ico ico-large" /> Règlement
        <br />
      </div>
      <div className="col-12">
        <span className="ticket-detail ticket-detail-key">Passage réglé ? &nbsp;</span>
        <span className="ticket-detail ticket-detail-value">{isPaymentDone()}</span>
      </div>
      {renderMethodPaymentOrNotPayedReason()}
    </>
  );
}
