import React from "react";

import { FunctionField } from "react-admin";

const TagRepairReportField = ({ record }) => {
  return (
    <FunctionField
      render={(tag) => {
        return (
          (tag && (
            <div className="tag-container">
              <span className="tag" style={{ backgroundColor: record && record.color }}>
                {record.text}
              </span>
            </div>
          )) ||
          record
        );
      }}
    />
  );
};

export default TagRepairReportField;
