/* global ga */

export const dimensions = {
  admin: {
    TRACKING_VERSION: "dimension1",
    PLATFORM_ENVIRONMENT: "dimension2",
    CLIENT_ID: "dimension3",
    COMPANY_ID: "dimension4",
    TIME_STAMP: "dimension5",
  },
  user: {
    TRACKING_VERSION: "dimension1",
    PLATFORM_ENVIRONMENT: "dimension2",
    CLIENT_ID: "dimension3",
    COMPANY_ID: "dimension4",
    TIME_STAMP: "dimension5",
  },
};

const TRACKING_ID = {
  admin: "UA-112675874-2",
  user: "UA-112675874-1",
};

const TRACKING_VERSION = {
  admin: "1",
  user: "2",
};

const PLATFORM_ENVIRONMENT = process.env.REACT_APP_GA_PLATFORM_ENVIRONMENT;

/**
 * Create a Google Analytics tracker with the given name and trigger callback if provided
 * load enhanced ecommerce module
 * @param {string} trackerName can be 'user' or 'admin', used to get the tracker information
 * @param {function} initializationCallback a callback to be fired at the end of the function
 */
export const init = (trackerName, initializationCallback) => {
  window.ga = window.ga || ((...args) => (ga.q = ga.q || []).push(args));
  ga("create", TRACKING_ID[trackerName], "auto", trackerName);
  ga(`${trackerName}.require`, "ec");
  ga(`${trackerName}.require`, "GTM-WNNCD5B");
  ga(`${trackerName}.set`, "transport", "beacon");
  ga(`${trackerName}.set`, dimensions[trackerName].TRACKING_VERSION, TRACKING_VERSION[trackerName]);
  ga(`${trackerName}.set`, dimensions[trackerName].PLATFORM_ENVIRONMENT, PLATFORM_ENVIRONMENT);
  ga(() => {
    const tracker = ga.getByName(trackerName);
    let clientId = tracker.get("clientId");
    tracker.set(dimensions[trackerName].CLIENT_ID, clientId);
  });
  if (initializationCallback) {
    initializationCallback();
  }
};

/**
 * Proxy function to execute actions in ga in order to avoid calling the global ga from anywhere
 * @param {string} action the action to be executed: set, ec:addProduct...
 * @param {string} trackerName the name of the tracker to be used, 'user' or 'admin'
 * @param  {...any} optionals the arguments to be passed to ga
 */
export const gaExecute = (action, trackerName, ...optionals) => {
  ga(`${trackerName}.${action}`, ...optionals);
};

export default init;
