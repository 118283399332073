import { useQuery } from "@tanstack/react-query";
import { get } from "lib/http";
import { ProductDetails } from "types/products";

export const useProductDetails = (productId: number) => {
  const fetchProductDetails = async (productId: number): Promise<ProductDetails[]> => {
    return await get(
      `operations-data-provider/cobble-flow-read-api-proxy/murfy_erp_product?select=*,product_model:sku_productmodel(modelReference:reference,brand:product_brand(*), product_type:product_producttype(*))&id=eq.${productId}`
    );
  };

  const { data, isLoading, isError } = useQuery<ProductDetails[]>(["product-details-read-api", productId], async () =>
    fetchProductDetails(productId)
  );
  return { productDetails: data && data[0], isLoading, isError };
};
