import * as React from "react";

import { SimpleForm, TextInput } from "react-admin";
import { useDataProvider, useNotify, useRefresh } from "react-admin";

import Toolbar from "components/OrderShow/components/OrderShowLayout/Toolbar";

export default (props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const save = (record, redirect) => {
    dataProvider
      .create("refunds", {
        data: { order: props.record.intent.id, reason: record.reason, amount: record.amount },
      })
      .then((response) => {
        notify("Remboursement effectué");
        refresh();
      })
      .catch((error) => {
        alert(error.message);
        notify(error.message, "warning");
      });
  };

  return (
    <SimpleForm
      record={props.record}
      resource="ecom/refunds"
      redirect={false}
      toolbar={<Toolbar label="Ajouter un remboursement" />}
      save={save}
      className={props.style.refundForm}
    >
      <TextInput required source="reason" label="Raison" />
      <TextInput source="amount" label="Montant (en centimes)" width={1} />
    </SimpleForm>
  );
};
