import React, { Component } from "react";

import PropTypes from "prop-types";

import ReactModal from "react-modal";

import "./style.css";

export default class Modal extends Component {
  static propTypes = {
    children: PropTypes.node,
    displayCloseIcon: PropTypes.bool,
    handleCloseModal: PropTypes.func,
    isOpen: PropTypes.bool.isRequired,
    modalClassName: PropTypes.string,
    customStyle: PropTypes.shape({
      content: PropTypes.any,
      overlay: PropTypes.any,
    }),
    overlayClassName: PropTypes.string,
    shouldCloseOnEsc: PropTypes.bool,
    shouldCloseOnOverlayClick: PropTypes.bool,
  };

  static defaultProps = {
    displayCloseIcon: true,
    isOpen: false,
    modalClassName: "modal-content",
    overlayClassName: "",
    shouldCloseOnEsc: true,
    shouldCloseOnOverlayClick: true,
  };

  render() {
    return (
      <ReactModal
        ariaHideApp={false}
        isOpen={this.props.isOpen}
        className={`modal-content-base ${this.props.modalClassName}`}
        overlayClassName={`modal-overlay ${this.props.overlayClassName}`}
        shouldCloseOnOverlayClick={this.props.shouldCloseOnOverlayClick}
        shouldCloseOnEsc={this.props.shouldCloseOnEsc}
        onRequestClose={this.props.handleCloseModal}
        style={this.props.customStyle}
      >
        {this.props.displayCloseIcon && (
          <i className="fal fa-times modal-close-icon" onClick={this.props.handleCloseModal} />
        )}
        <div className="modal-content-scroll-container">
          <div className="modal-content-padding-container">{this.props.children}</div>
        </div>
      </ReactModal>
    );
  }
}
