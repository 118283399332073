import React from "react";

import { SaveButton, Toolbar } from "react-admin";

import { styled } from "@material-ui/core";

export default function SimpleValidateToolbar(props) {
  const getToolbarClass = (version) => {
    switch (version) {
      case "right":
        return {
          justifyContent: "end",
        };
      case "center":
        return {
          justifyContent: "center",
        };
      case "left":
      default:
        return {
          justifyContent: "start",
        };
    }
  };

  const StyledToolbar = styled(Toolbar)({
    backgroundColor: props.backgroundColor || "transparent",
    ...getToolbarClass(props.version),
  });

  return (
    <StyledToolbar variant="dense" disableGutters {...props}>
      <SaveButton
        size={props.size || "small"}
        variant={props.variant || "text"}
        label={props.label}
        onSave={props.onSave}
        disabled={props.disabled}
      />
    </StyledToolbar>
  );
}
