import React from "react";

import { FunctionField } from "react-admin";

import { withStyles } from "@material-ui/core/styles";

import StatusFieldStyles from "shared/styles/StatusFieldStyles";

import SkuDemandStatus from "constants/skuDemandStatus";

export default withStyles(StatusFieldStyles)(({ record, classes, fromReorder }) => {
  record = fromReorder ? record.demand_item && record.demand_item.demand : record;

  const render = () => {
    switch (record.status) {
      case SkuDemandStatus.EMITTED:
        return "Émise";
      case SkuDemandStatus.WAITING_FOR_SKU:
        return "En attente de pièces";
      case SkuDemandStatus.WAITING_FOR_AFFECTATION:
        return "En attente d'affectation";
      case SkuDemandStatus.WAITING_FOR_PACKING:
        return "En attente de colisage";
      case SkuDemandStatus.BEING_PREPARED:
        return "En préparation";
      case SkuDemandStatus.SENT:
        return "Expédié";
      case SkuDemandStatus.AVAILABLE:
        return "Livré";
      case SkuDemandStatus.LOSS_DECLARED:
        return "Perte déclarée";
      case SkuDemandStatus.CANCELED:
        return "Annulé";
      default:
        return "";
    }
  };

  const getClass = () => {
    switch (record.status) {
      case SkuDemandStatus.EMITTED:
        return classes.grey;
      case SkuDemandStatus.WAITING_FOR_SKU:
        return classes.purple;
      case SkuDemandStatus.WAITING_FOR_AFFECTATION:
        return classes.dark_blue;
      case SkuDemandStatus.WAITING_FOR_PACKING:
        return classes.orange;
      case SkuDemandStatus.BEING_PREPARED:
        return classes.yellow;
      case SkuDemandStatus.SENT:
        return classes.light_blue;
      case SkuDemandStatus.AVAILABLE:
        return classes.green;
      case SkuDemandStatus.LOSS_DECLARED:
      case SkuDemandStatus.CANCELED:
        return classes.red;
      default:
        return classes.grey;
    }
  };

  return <FunctionField render={render} className={`${classes.label} ${getClass()}`} />;
});
