import React, { Component } from "react";

import PropTypes from "prop-types";

import DeleteOrReorderElements from "admin/components/DeleteOrReorderElements";

import "./style.css";

export default class EditionBox extends Component {
  static propTypes = {
    handleDelete: PropTypes.func,
    handleOrderDecrease: PropTypes.func,
    handleOrderIncrease: PropTypes.func,
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpened: true,
    };
  }

  toggleBox = () => this.setState({ isOpened: !this.state.isOpened });

  render() {
    return (
      <div className="edition-box-container">
        <div className="edition-box-title-container" onClick={this.toggleBox}>
          <span>{this.props.title}</span>
          <div className="edition-box-icons-container">
            {(this.props.handleDelete || this.props.handleOrderDecrease || this.props.handleOrderIncrease) && (
              <DeleteOrReorderElements
                handleDelete={this.props.handleDelete}
                handleOrderDecrease={this.props.handleOrderDecrease}
                handleOrderIncrease={this.props.handleOrderIncrease}
              />
            )}
            <i className={`edition-box-icon ${this.state.isOpened ? "fal fa-chevron-down" : "fal fa-chevron-right"}`} />
          </div>
        </div>
        {this.state.isOpened && this.props.children}
      </div>
    );
  }
}
