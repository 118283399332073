import dataProvider from "dataProvider";

export const getPackageTypesList = () =>
  dataProvider
    .getList("package-types", {
      filter: {},
      sort: {},
      pagination: {},
      noPagination: true,
    })
    .catch(console.error);

export const getTotalPaymentAmount = (customerFileId) =>
  dataProvider
    .getList("paymentbilling-history", {
      filter: { customer_file_id: customerFileId },
      sort: {},
      pagination: {},
      noPagination: true,
    })
    .catch(console.error);

export const getLastPaymentIntentAmount = (customerFileId) =>
  dataProvider.getOne("payment-intent-last", { id: customerFileId });
