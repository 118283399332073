import {
  ASWO,
  CONSTRUCTOR,
  EPS,
  RepairAssistantClient,
  SDS,
  SOGEDIS,
  SUPPLIER,
  SparePartNeed,
  VEDELEC,
} from "lib/repair_assitant_client";
import { toastr } from "react-redux-toastr";

interface CustomerFile {
  home_repairs: number[];
}

interface SparePartOrder {
  name: string;
  ref: string;
  supplier: string;
  productId: number;
}

export class RepairAssistantService {
  private _client: RepairAssistantClient;
  private _repair_assistant_url: string | undefined;
  static KNOWN_SUPPLIERS: SUPPLIER[] = [ASWO, CONSTRUCTOR, SDS, EPS, SOGEDIS, VEDELEC];

  constructor() {
    this._repair_assistant_url = process.env.REACT_APP_ASSSITANT_URL;
    // should be check with an invariant rather than forced on typescript
    // but this is not supported by the typescript version used by the admin
    this._client = new RepairAssistantClient(this._repair_assistant_url!, (_) => {
      toastr.warning("Attention", "La récupération des pièces de l'assistant ne sera possible qu'après reconnexion");
    });
  }

  _sparePartNeedToSparePartOrders(sparePartNeeds: SparePartNeed[], productId: number): SparePartOrder[] {
    return sparePartNeeds.map((sparePartNeed) => ({
      name: sparePartNeed.model.designation,
      ref: sparePartNeed.model.supplier_reference,
      supplier: this._chooseSupplier(sparePartNeed),
      productId,
    }));
  }

  async getSparePartOrder(customerFile: CustomerFile, relativeTo: string): Promise<SparePartOrder[]> {
    let sparePartOrders: SparePartOrder[] = [];
    for (const homeRepair of customerFile.home_repairs) {
      try {
        const appliance_repair = await this._client.getApplianceRepair(homeRepair);
        const spare_part_needs = await this._client.getSparePartNeeds(appliance_repair.id, relativeTo);
        sparePartOrders = sparePartOrders.concat(
          this._sparePartNeedToSparePartOrders(spare_part_needs, appliance_repair.home_repair.product)
        );
      } catch (e) {
        if (!(e instanceof Error)) {
          throw e;
        }
        console.error(e);
        toastr.warning("Attention", "La récupération des pièces de l'assistant à échouée");
      }
    }
    return sparePartOrders;
  }

  _chooseSupplier(sparePartNeed: SparePartNeed) {
    // Model inputed through the manual declaration in the Repair Assistant App
    // Sometimes containes erroneous suppliers. The reference used by the repairmen is
    // ASWO most of the time.
    // In case ASWO is wrong the aswo search will simply fail
    return RepairAssistantService.KNOWN_SUPPLIERS.includes(sparePartNeed.model.brand)
      ? sparePartNeed.model.brand
      : "ASWO";
  }
}
