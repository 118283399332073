import React from "react";

import { FileInput } from "react-admin";

import { makeStyles } from "@material-ui/core/styles";
import AddCircleTwoToneIcon from "@material-ui/icons/AddCircleTwoTone";

import ShippingNoteField from "pages/Shipping/ShippingList/ShippingDetail/ShippingNotes/ShippingNoteField";

export default function FileUpload() {
  const classes = useStyles();

  return (
    <FileInput
      source="file"
      label=""
      accept="application/pdf"
      placeholder={<AddCircleTwoToneIcon className={classes.addIcon} />}
    >
      <ShippingNoteField source="file" />
    </FileInput>
  );
}

const useStyles = makeStyles({
  addIcon: {
    fontSize: "4em",
    color: "#1A8E96",
  },
});
