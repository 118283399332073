import { ErrorData, OIDCHttpClient } from "./oidc_http_client";

export const ASWO = "ASWO";
export const CONSTRUCTOR = "CONSTRUCTOR";
export const SDS = "SDS";
export const EPS = "EPS";
export const SOGEDIS = "SOGEDIS";
export const VEDELEC = "VEDELEC";

export type SUPPLIER = typeof ASWO | typeof CONSTRUCTOR | typeof SDS | typeof EPS | typeof SOGEDIS | typeof VEDELEC;

export interface SparePartNeed {
  model: { brand: SUPPLIER; supplier_reference: string; designation: string };
}

interface ApplianceRepair {
  id: string;
  home_repair: {
    product: number;
    id: number;
  };
}

export class RepairAssistantClient {
  private _base_url: string;
  private _httpClient: OIDCHttpClient;

  constructor(repairAssistantUrl: string, onAuthError: (e: ErrorData) => void) {
    this._base_url = repairAssistantUrl;
    this._httpClient = new OIDCHttpClient(onAuthError);
  }

  async getApplianceRepair(homeRepairId: number): Promise<ApplianceRepair> {
    return await this._httpClient.get(`${this._base_url}/appliance-repair/${homeRepairId}`);
  }

  async getSparePartNeeds(applianceRepairId: string, relativeTo: string): Promise<SparePartNeed[]> {
    return await this._httpClient.get(
      `${this._base_url}/appliance-repair/${applianceRepairId}/history/spare-part-needs?relative-to=${relativeTo}`
    );
  }
}
