import React from "react";

import { Box, Button, Collapse } from "@material-ui/core";

import BodyBold from "components/atoms/Typography/BodyBold";

import Comment from "./components/Comment";
import NewComment from "./components/NewComment";

const CommentsIterator = ({
  customerFile,
  ids,
  data,
  displayMore,
  setDisplayMore,
  attachmentsEnabled,
  recipientRole,
  title,
  maxNumberOfComments,
  updateEnabled,
}) => {
  // replaces useGetOne("account") since it seems to be usefull only in "stable" states conditions
  // https://githubmemory.com/repo/marmelab/react-admin/issues/6240

  return (
    <Box py={2}>
      <BodyBold variant="subtitle1">{title}</BodyBold>
      {(maxNumberOfComments > ids.length || !maxNumberOfComments) && (
        <Box my={2}>
          <NewComment
            customerFile={customerFile}
            attachmentsEnabled={attachmentsEnabled}
            recipientRole={recipientRole}
          />
        </Box>
      )}
      {data && ids && (
        <>
          {ids.length > 0 &&
            ids.slice(0, 3).map((commentId) => {
              return (
                <Comment
                  displayMore={displayMore}
                  setDisplayMore={setDisplayMore}
                  comment={data[commentId]}
                  attachmentsEnabled={attachmentsEnabled}
                  maxNumberOfComments={maxNumberOfComments}
                  updateEnabled={updateEnabled}
                />
              );
            })}
          {ids.length > 3 && (
            <>
              {!displayMore && <Button onClick={() => setDisplayMore(true)}>{`Afficher plus`}</Button>}
              <Collapse in={displayMore}>
                {ids &&
                  ids.length > 0 &&
                  data &&
                  ids
                    .slice(3)
                    .map((id) => (
                      <Comment
                        displayMore={displayMore}
                        setDisplayMore={setDisplayMore}
                        comment={data[id]}
                        attachmentsEnabled={attachmentsEnabled}
                        maxNumberOfComments={maxNumberOfComments}
                        updateEnabled={updateEnabled}
                      />
                    ))}
                <Button onClick={() => setDisplayMore(false)}>{"Afficher moins"}</Button>
              </Collapse>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default CommentsIterator;
