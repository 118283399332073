import React, { useState } from "react";

import { Box, Button, makeStyles } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CreateIcon from "@material-ui/icons/Create";

import GenericConfirmationModal from "../../modal/GenericConfirmationModal.js";

const useStyles = makeStyles({
  addNewQuoteButtonWrapper: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "18px",
  },
  buttonQuoteAccepted: {
    background: "#4A55FE",
    color: "#fff",
    boxShadow: "none",
    marginLeft: 16,
    "&:hover": {
      backgroundColor: "#3b44cb",
    },
  },
  buttonQuoteRefused: {
    background: "#fff",
    color: "#F85B5B",
    border: "none",
    boxShadow: "none",
  },
});

const ButtonsPendingAnswerQuote = ({
  handleDisplayNewQuoteForm,
  handleUpdateQuote,
  sendConfirmationEmail,
  handleChangeSendConfirmationEmail,
}) => {
  const [isValidationModalOpen, setIsValidationModalOpen] = useState(false);
  const [dataValidation, setDataValidation] = useState(null);

  const onClickOpenValidationModal = (dataValidation) => {
    setIsValidationModalOpen(true);
    setDataValidation(dataValidation);
  };

  const handleValidate = async () => {
    await handleUpdateQuote(dataValidation);
    setIsValidationModalOpen(false);
  };

  const classes = useStyles();
  return (
    <div className={classes.addNewQuoteButtonWrapper}>
      <Button
        id="create-new-quote-button"
        variant="contained"
        className={classes.editQuoteButton}
        onClick={handleDisplayNewQuoteForm}
      >
        Modifier le devis {<CreateIcon className={classes.createIcon} />}
      </Button>
      <div>
        <Button
          id="refused-quote-button"
          variant="contained"
          className={classes.buttonQuoteRefused}
          onClick={() => onClickOpenValidationModal({ quote_refused: true })}
        >
          Devis refusé
        </Button>
        <Button
          id="accepted-quote-button"
          variant="contained"
          color="primary"
          className={classes.buttonQuoteAccepted}
          onClick={() => onClickOpenValidationModal({ quote_accepted: true })}
        >
          Devis accepté
        </Button>
        {dataValidation && (
          <GenericConfirmationModal
            isValidationModalOpen={isValidationModalOpen}
            handleCloseValidationModal={() => setIsValidationModalOpen(false)}
            handleValidateAction={handleValidate}
            confirmationModalTitle={dataValidation.quote_accepted ? "Devis accepté" : "Devis refusé"}
            confirmationModalSubtitle={
              dataValidation.quote_accepted
                ? "Confirmez-vous que le client a bien accepté le devis ?"
                : "Confirmez-vous que le client a bien refusé le devis ?"
            }
            sendConfirmationEmail={sendConfirmationEmail}
            handleChangeSendConfirmationEmail={handleChangeSendConfirmationEmail}
          >
            {dataValidation && dataValidation.quote_accepted && (
              <Box my={0} mx={9} height={80} borderTop="1px solid #DBDFE8" display="flex" alignItems="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={sendConfirmationEmail}
                      onChange={handleChangeSendConfirmationEmail}
                    />
                  }
                  label="Envoyer le mail de confirmation"
                />
              </Box>
            )}
          </GenericConfirmationModal>
        )}
      </div>
    </div>
  );
};
export default ButtonsPendingAnswerQuote;
