import React, { useState } from "react";

import { Labeled } from "react-admin";

import { Grid } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

import axios from "axios";
import { toastr } from "react-redux-toastr";

import dataProvider from "dataProvider";

const useStyles = makeStyles((theme) => ({
  pictureContainer: {
    minWidth: "180px",
    minHeight: "240px",
    position: "relative",
    "&:hover": {
      "& $deleteButton": {
        visibility: "visible",
      },
    },
  },
  circularProgressContainer: {},
  deleteButton: {
    color: theme.palette.secondary.red,
    backgroundColor: "white",
    borderRadius: "50%",
    top: "8px",
    right: "8px",
    position: "absolute",
    minWidth: "16px",
    visibility: "hidden",
  },
  label: {
    cursor: "pointer",
    textAlign: "center",
    backgroundColor: theme.palette.secondary.lightGrey,
    width: "100%",
    borderRadius: "4px",
    paddingBottom: "2px",
    paddingTop: "2px",
    fontSize: "14px",
  },
  img: {
    maxWidth: "210px",
  },
  imgLoading: {
    maxWidth: "180px",
    filter: "grayscale(1)",
  },
  inputFile: {
    display: "none",
  },
}));

const PictureInput = ({ picture, label, disabled, index, save, product }) => {
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const createPicture = (picture_file) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", picture_file[0]);
    formData.append("upload_preset", process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET_NAME);

    axios
      .post(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        dataProvider.create(`ecom/pictures`, { data: { url: response.data.secure_url, product: product.id } }).then(
          () => {
            save();
          },
          () => {
            toastr.error("Erreur", "Impossible d'ajouter la photo");
          }
        );
        setIsLoading(false);
        toastr.success("Succès", "Photo ajoutée");
      });
  };
  const deletePicture = () => {
    setIsLoading(true);
    dataProvider.delete(`ecom/pictures`, { id: picture.id, data: {} }).then(
      () => {
        save();
        setIsLoading(false);
        toastr.success("Succès", "Photo supprimée");
      },
      () => {
        toastr.error("Erreur", "Impossible de supprimer la photo");
      }
    );
  };
  const updatePicture = (picture_file) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", picture_file[0]);
    formData.append("upload_preset", process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET_NAME);

    axios
      .post(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(
        (response) => {
          dataProvider.update(`ecom/pictures`, { id: picture.id, data: { url: response.data.secure_url } }).then(() => {
            save();
          });
          setIsLoading(false);
          toastr.success("Succès", "Photo mise à jour");
        },
        () => {
          toastr.error("Erreur", "Impossible de mettre à jour la photo");
        }
      );
  };

  return (
    <Grid container direction="column">
      <Grid
        item
        onDrop={(event) => {
          if (!picture) {
            createPicture([event.dataTransfer.items[0].getAsFile()]);
          } else {
            updatePicture([event.dataTransfer.items[0].getAsFile()]);
          }
        }}
      >
        <Labeled label={`Photo ${index}`}>
          <input
            id={`photo-upload-${index}`}
            type="file"
            onChange={(event) => {
              if (!picture) {
                createPicture(event.currentTarget.files);
              } else {
                updatePicture(event.currentTarget.files);
              }
            }}
            className={classes.inputFile}
          />
        </Labeled>
        <label for={`photo-upload-${index}`} className={classes.label}>
          Glissez une image ici
        </label>
      </Grid>
      <Grid item justifyContent="center" alignItems="center">
        {isLoading && <CircularProgress color="primary" />}
        {!isLoading && (
          <div className={classes.pictureContainer}>
            {picture && (
              <IconButton className={classes.deleteButton} onClick={deletePicture}>
                <RemoveCircleIcon />
              </IconButton>
            )}
            {picture && (
              <img src={picture.url} alt="preview" className={!isLoading ? classes.img : classes.imgLoading} />
            )}
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default PictureInput;
