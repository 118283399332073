import { connect } from "react-redux";

import QuickAccessMenu from "./QuickAccessMenu.component";

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(QuickAccessMenu);
