import React, { useState } from "react";

import { Button, useNotify, usePermissions, useRefresh, useUnselectAll } from "react-admin";

import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Edit from "@material-ui/icons/Edit";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import NotListedLocationIcon from "@material-ui/icons/NotListedLocation";

import { SKU_STORE_ADMIN_GROUP, WORKSHOP_ADMIN_GROUP, hasPerm } from "utils/permissions";

import { updateManySkus } from "../../../../api";
import BulkExportButton from "../BulkExportButton";
import BulkSkuUpdateDialog from "../BulkSkuUpdateDialog";

const PACKED = "packed";
const LOST = "lost";

const CustomBulkButtons = ({ classes, selectedIds, ...props }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [isSkuBulkUpdateOpen, setIsBulkSkuUpdateOpen] = useState(false);

  const { loaded, permissions } = usePermissions();
  const handleConfirm = async (values) => {
    try {
      await updateManySkus(selectedIds, values);
      refresh();
      notify("Statut actualisé");
      unselectAll("spare-parts");
      setIsBulkSkuUpdateOpen(false);
    } catch (e) {
      notify("Erreur", e);
    }
  };

  return (
    <Box display="flex" justifyContent="space-between" width="calc(100vw - 48px)" pb={2}>
      <Typography variant="subtitle2" alignSelf="center">
        {selectedIds.length > 1
          ? selectedIds.length + " appareils sélectionnés"
          : selectedIds.length + " appareil sélectionné"}
      </Typography>
      <Box>
        {loaded && hasPerm(permissions, [WORKSHOP_ADMIN_GROUP, SKU_STORE_ADMIN_GROUP]) && (
          <Button marginRight={3} label="Modifier" onClick={() => setIsBulkSkuUpdateOpen(true)}>
            <Edit />
          </Button>
        )}

        <Button marginRight={3} label="À Expédier" onClick={() => handleConfirm({ status: PACKED })}>
          <LocalShippingIcon />
        </Button>
        <Button marginRight={3} label="Signaler une perte" onClick={() => handleConfirm({ status: LOST })}>
          <NotListedLocationIcon />
        </Button>
        <BulkExportButton
          marginRight={3}
          resource={props.resource}
          selectedIds={selectedIds}
          exporter={props.exporter}
          onClick={() => unselectAll("spare-parts")}
        />
      </Box>
      <BulkSkuUpdateDialog
        handleConfirm={handleConfirm}
        handleClose={() => setIsBulkSkuUpdateOpen(false)}
        open={isSkuBulkUpdateOpen}
      />
    </Box>
  );
};

export default CustomBulkButtons;
