import React from "react";

import { Layout } from "react-admin";

import { withStyles } from "@material-ui/core/styles";

import MurfyAppBar from "./MurfyAppBar";
import MurfySidebar from "./MurfySidebar";

const MurfyLayoutStyles = {
  content: {
    padding: "0",
    backgroundColor: "#F4F3F8",
    overflow: "auto",
  },
  contentWithSidebar: {
    height: `calc(100vh - 48px)`, // 48px refers to the min height of Material UI AppBar which applies to ours
  },
};

const MurfyLayout = (props) => <Layout {...props} appBar={MurfyAppBar} sidebar={MurfySidebar} />;

export default withStyles(MurfyLayoutStyles)(MurfyLayout);
