import * as React from "react";

import { Datagrid, DateField, FunctionField, List, Pagination, TextField } from "react-admin";

import { Box, Typography } from "@material-ui/core";

import CurrencyField from "components/CurrencyField";
import OrdersFilter from "components/OrderList/components/OrdersFilter";
import OrderStateField from "components/OrderStateField";

const getCustomerName = (order) => `${order.customer_firstname} ${order.customer_lastname}`;

function OrderList(props) {
  return (
    <Box margin={5}>
      <Typography variant="h2">Commandes reconditionnés</Typography>
      <List
        {...props}
        bulkActionButtons={false}
        perPage={25}
        exporter={false}
        filters={<OrdersFilter />}
        pagination={<Pagination rowsPerPageOptions={[25, 100, 250]} />}
        sort={{ field: "id", order: "DESC" }}
      >
        {
          // We check here if a delivery is associated to the order by checking if there is a delivery date
        }
        <Datagrid rowClick={(id, basePath, record) => record.date && "show"}>
          <TextField source="reference" label="Référence" />
          <FunctionField label="Client" render={getCustomerName} sortBy="customer_name" />
          <TextField source="zipcode" label="Code postal" sortable={false} />
          <FunctionField
            render={(order) => order.last_mile_shipping_provider_name || ""}
            label="Prestataire (dernier km)"
            sortable={false}
          />
          <FunctionField
            render={(order) => order.interregional_shipping_provider_name || ""}
            label="Prestataire interrégional"
            sortable={false}
          />
          <OrderStateField source="state" label="Statut" />
          <TextField source="intent.store" label="Revendeur" sortBy="store" />
          <DateField source="date" label="Date de livraison" locales="fr-FR" sortable={false} />
          <CurrencyField source="intent.total" label="Total" sortable={false} />
        </Datagrid>
      </List>
    </Box>
  );
}

export default OrderList;
