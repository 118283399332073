import React, { useEffect, useState } from "react";

import { useRefresh } from "react-admin";

import { Link, Redirect, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import TabBillingQuote from "pages/customerFiles/components/CustomerFileBillingQuotes";
import TabCustomerFilePayment from "pages/customerFiles/components/CustomerFilePaymentNew/CustomerFilePayment.component";
import TabRepairReports from "pages/customerFiles/components/CustomerFileRepairReports/CustomerFileRepairReports.component";
import CustomerFileTargetStatesInput from "pages/customerFiles/components/CustomerFileTargetStatesInput";
import TabVisitsNew from "pages/customerFiles/components/CustomerFileVisitsNew/CustomerFileVisitsNew.component";

import ProductInformationTab from "./ProductInformationTab";
import { HomeRepairSkuDemands } from "./components/SkuDemands";

export default function CustomerFileTabs({
  details = {},
  updateCustomerFileDetail,
  updateTicketDetail,
  getCustomerFileDetail,
  createIncentiveSystem,
  employees,
  getEmployees,
  customerFilesStates,
  idEmployee,
  customerFilesTags,
  defaultTab = "infos",
}) {
  let location = useLocation();
  const refresh = useRefresh();
  const [activeTab, setActiveTab] = useState(
    location.pathname.split("/").pop().includes("_") ? defaultTab : location.pathname.split("/").pop()
  );

  const classes = useStyles();

  const handleChange = (event, activeTab) => {
    setActiveTab(activeTab);
    refresh();
  };

  useEffect(() => {
    if (!employees) {
      getEmployees();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <AppBar position="static" className={classes.customerFileAppBar}>
        <Tabs value={activeTab} onChange={handleChange}>
          <StyledTab
            value="infos"
            label="Informations"
            component={Link}
            to={{
              pathname: `/${details.file_number}/infos`,
              search: location.search,
            }}
          />
          <StyledTab
            value="visits"
            label="Passages"
            component={Link}
            to={{
              pathname: `/${details.file_number}/visits`,
              search: location.search,
            }}
          />
          <StyledTab
            value="quotes"
            label="Devis"
            component={Link}
            to={{
              pathname: `/${details.file_number}/quotes`,
              search: location.search,
            }}
          />
          <StyledTab
            value="payments"
            label="Paiements"
            component={Link}
            to={{
              pathname: `/${details.file_number}/payments`,
              search: location.search,
            }}
          />
          <StyledTab
            value="demands"
            label="Demandes Pièces"
            component={Link}
            to={{
              pathname: `/${details.file_number}/demands`,
              search: location.search,
            }}
          />
          <StyledTab
            value="reports"
            label="Comptes Rendus"
            component={Link}
            to={{
              pathname: `/${details.file_number}/reports`,
              search: location.search,
            }}
          />
        </Tabs>
      </AppBar>

      <div className={classes.customerFileContainerActve}>
        <CustomerFileTargetStatesInput
          customerFile={details}
          customerFileId={details.id}
          customerFileStateId={details.state}
          customerFilesStates={customerFilesStates}
          getCustomerFileDetail={getCustomerFileDetail}
          updateCustomerFileDetail={updateCustomerFileDetail}
          customerFilesTags={customerFilesTags}
        />
        <Route path="">
          <Redirect
            to={{
              pathname: `/${details.file_number}/${activeTab}`,
              search: location.search,
            }}
          />
        </Route>
        <Route
          path={`/${details.file_number}/infos`}
          render={() => (
            <ProductInformationTab
              details={details}
              getCustomerFileDetail={getCustomerFileDetail}
              idEmployee={idEmployee}
            />
          )}
        />
        <Route
          path={`/${details.file_number}/reports`}
          render={() => (
            <TabRepairReports
              createIncentiveSystem={createIncentiveSystem}
              getCustomerFileDetail={getCustomerFileDetail}
              customerFile={details}
              employees={employees}
              visits={details.visits}
              updateTicketDetail={updateTicketDetail}
              getEmployees={getEmployees}
            />
          )}
        />
        <Route
          path={`/${details.file_number}/visits`}
          render={() => (
            <TabVisitsNew
              customerFile={details}
              customerFileId={details.id}
              payments={details.payments}
              postalCode={details.postal_code}
              employees={employees}
              getCustomerFileDetail={getCustomerFileDetail}
            />
          )}
        />
        <Route
          path={`/${details.file_number}/quotes`}
          render={() => (
            <TabBillingQuote
              customerFile={details}
              customerFileId={details.id}
              customerFilesStates={customerFilesStates}
              employees={employees}
              visits={details.visits}
            />
          )}
        />

        <Route
          path={`/${details.file_number}/payments`}
          render={() => <TabCustomerFilePayment customerFile={details} employees={employees} />}
        />
        <Route
          path={`/${details.file_number}/demands`}
          render={() => <HomeRepairSkuDemands customerFile={details} />}
        />
      </div>
    </>
  );
}

const styles = () => ({
  root: {
    flexGrow: 1,
  },
  customerFileAppBar: {
    background: "#F4F3F8",
    color: "#2B3956",
    boxShadow: "none",
    marginLeft: 24,
    width: "auto",
  },
  customerFileContainerActve: {
    padding: "0 24px 24px",
  },
});
const styledTab = {
  root: {
    minWidth: 0,
    fontSize: 13,
    textTransform: "none",
    fontFamily: "Poppins",
  },
};
const StyledTab = withStyles(styledTab)(Tab);
const useStyles = makeStyles(styles);
