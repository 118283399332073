/**
 * Following the duck pattern, actions, constants and reducers are in the same file called module.js
 *
 * See: https://github.com/erikras/ducks-modular-redux
 *
 */
import { sendAuthenticatedGetRequestToAPI, sendAuthenticatedRequestToAPI } from "modules/generators/requestsToAPI";
import { call, takeLatest } from "redux-saga/effects";

export const GET_ACCOUNT_ERROR = "ACCOUNT/GET_ACCOUNT_ERROR";
export const GET_ACCOUNT_REQUEST = "ACCOUNT/GET_ACCOUNT_REQUEST";
export const GET_ACCOUNT_SUCCESS = "ACCOUNT/GET_ACCOUNT_SUCCESS";
export const SAVE_ACCOUNT_ERROR = "ACCOUNT/SAVE_ACCOUNT_ERROR";
export const SAVE_ACCOUNT_REQUEST = "ACCOUNT/SAVE_ACCOUNT_REQUEST";
export const SAVE_ACCOUNT_SUCCESS = "ACCOUNT/SAVE_ACCOUNT_SUCCESS";

export function getAccountRequest() {
  return {
    type: GET_ACCOUNT_REQUEST,
  };
}

export function getAccountSuccess(account) {
  return {
    type: GET_ACCOUNT_SUCCESS,
    payload: account,
  };
}

export function getAccountError(error) {
  return {
    type: GET_ACCOUNT_ERROR,
    payload: error,
  };
}

export function saveAccountRequest(fieldName, fieldValue, passwordField, confirmPassword) {
  return {
    type: SAVE_ACCOUNT_REQUEST,
    payload: { fieldName, fieldValue, passwordField, confirmPassword },
  };
}

export function saveAccountSuccess(account) {
  return {
    type: SAVE_ACCOUNT_SUCCESS,
    payload: account,
  };
}

export function saveAccountError(error) {
  return {
    type: SAVE_ACCOUNT_ERROR,
    payload: error,
  };
}

export function* getAccountSaga() {
  const accountAPIPath = "/common/account/";
  yield takeLatest(
    GET_ACCOUNT_REQUEST,
    sendAuthenticatedGetRequestToAPI,
    accountAPIPath,
    getAccountSuccess,
    getAccountError
  );
}

export function* saveAccount(action) {
  const data = {
    user: {
      [action.payload.fieldName]: action.payload.fieldValue,
      ...(action.payload.passwordField && {
        [action.payload.passwordField]: action.payload.confirmPassword,
      }),
    },
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
    method: "patch",
    url: `${process.env.REACT_APP_API_URL}/common/account/`,
  };

  const toastrConfig = {
    success: { title: "Succès", message: "Modification enregistrée" },
    error: { title: "Erreur", message: "La modification n'a pas été enregistrée" },
  };

  yield call(sendAuthenticatedRequestToAPI, config, saveAccountSuccess, saveAccountError, toastrConfig);
}

export function* saveAccountSaga() {
  yield takeLatest(SAVE_ACCOUNT_REQUEST, saveAccount);
}

/**
 * Following the duck pattern, the module.js file should export a reducer as a default function
 */
const initialState = {
  error: null,
  account: null,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ACCOUNT_REQUEST:
      return initialState;
    case GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        account: action.payload,
      };
    case GET_ACCOUNT_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SAVE_ACCOUNT_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SAVE_ACCOUNT_SUCCESS:
      return {
        ...state,
        account: action.payload,
      };
    default:
      return state;
  }
}

export default reducer;
