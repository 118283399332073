import React from "react";

import { Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { makeStyles } from "@material-ui/styles";

const useToolbarStyles = makeStyles({
  root: {
    float: "right",
  },
});

export const CustomerFilesListPagination = ({ page, perPage, total, setPage }) => {
  const nbPages = Math.ceil(total / perPage) || 1;
  const firstItemDisplayed = (page - 1) * perPage + 1;
  const lastItemDisplayed = page * perPage > total ? total : page * perPage;
  const toolbarClasses = useToolbarStyles();
  return (
    nbPages > 1 && (
      <Toolbar classes={toolbarClasses}>
        <Typography variant="caption">
          {firstItemDisplayed} - {lastItemDisplayed} sur {total}
        </Typography>
        <IconButton color="primary" key="prev" onClick={() => setPage(page - 1)} disabled={page === 1}>
          <ChevronLeft />
        </IconButton>
        <IconButton color="primary" key="next" onClick={() => setPage(page + 1)} disabled={page === nbPages}>
          <ChevronRight />
        </IconButton>
      </Toolbar>
    )
  );
};
