import React, { useState } from "react";

import { Button } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import { toastr } from "react-redux-toastr";
import { Transaction } from "types/transaction";

import ConvertHomeRepairModal from "components/ConvertHomeRepairModal";

import { convertToCustomerFile } from "utils/api";

type ConvertToHomeRepairProps = {
  orderId: number;
  transactions: Transaction[];
};

const ConvertToHomeRepair: React.FC<ConvertToHomeRepairProps> = ({ orderId, transactions }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const convert = async (transactions_ids?: number[]) => {
    setIsSubmitted(true);
    const { data, status } = await convertToCustomerFile({
      order: orderId,
      transactions_ids: transactions_ids,
    });

    if (status < 200 || status >= 300) {
      toastr.error("Erreur", "Un problème est survenu sur la création du dossier client");
    } else {
      setIsOpen(false);
      window.open(`/customer-files/${data.file_number}`, "_blank");
    }
    setIsSubmitted(false);
  };

  if (!orderId || !transactions) {
    return null;
  }

  const getAction = () => {
    if (Object.keys(transactions).length === 1) {
      return convert;
    }
    return handleOpenModal;
  };

  const action = getAction();

  return (
    <>
      <Button color="primary" onClick={() => action()} startIcon={<AddCircleIcon />} disabled={isSubmitted}>
        CREER UN DOSSIER RD
      </Button>
      {isOpen && (
        <ConvertHomeRepairModal
          isOpen={isOpen}
          handleCloseModal={() => setIsOpen(false)}
          transactions={transactions}
          handleSubmit={convert}
        />
      )}
    </>
  );
};

export default ConvertToHomeRepair;
