import React, { useEffect, useState } from "react";

import { SelectInput, TextInput } from "react-admin";

import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import EcommerceProductLink from "components/EcommerceProductLink";
import ProductFunctionalCharacteristicsInput from "components/ProductFunctionalCharacteristicsInput";
import ProductGeneralInputs from "components/ProductGeneralInputs";
import ProductToggleGroup from "components/ProductToggleGroup";
import UserGuideInput from "components/UserGuideInput";

const useSmallBodyStyles = makeStyles((theme) => ({
  root: {
    fontWeight: "600",
    color: theme.palette.secondary.contrastText,
  },
  descriptionBlock: {
    padding: "24px 40px",
    width: "100%",
    borderLeft: "5px solid",
    borderLeftColor: "#2B3B5C",
    backgroundColor: "#E5E5E5",
  },
}));

export const LIKE_NEW = "like_new";
export const GOOD_CONDITION = "good_condition";
export const PROPER_CONDITION = "proper_condition";
export const WEARY = "weary";

export const AppearancesChoices = [
  { id: LIKE_NEW, name: "Excellent état" },
  { id: GOOD_CONDITION, name: "Très bon état" },
  { id: PROPER_CONDITION, name: "Bon état" },
  { id: WEARY, name: "État correct" },
];

export const AppearancesDefaultDescriptions = [
  {
    id: LIKE_NEW,
    description:
      "Cet appareil n'a que très peu servi en plus d'être testé à nouveau par nos soins. Il fonctionne parfaitement !",
  },
  { id: GOOD_CONDITION, description: "Cet appareil se porte très bien, il est prêt à vivre sa seconde vie !" },
  { id: PROPER_CONDITION, description: "Cet appareil a déjà un peu servi, mais il fonctionne très bien !" },
  {
    id: WEARY,
    description:
      "Cet appareil a déjà servi et présente des défauts esthétiques, mais il n’a pas dit son dernier “bip”.",
  },
];

export const getAppearanceDescription = (appearance = "", productDescription = "") => {
  const desc = AppearancesDefaultDescriptions.find((a) => {
    return a.id === appearance;
  });

  if (desc) {
    if (productDescription !== "") {
      return <>{productDescription}</>;
    }
    return desc.description;
  }
  return productDescription;
};

const ProductCaracteristicsInputs = (props) => {
  const classes = useSmallBodyStyles();
  const productPublished = props.record && props.record.is_online;
  const [appearancesDescription, setAppearancesDescription] = useState();
  useEffect(() => {
    setAppearancesDescription(getAppearanceDescription(props.record.appearance, props.record.appearance_comments));
  }, [props.record.appearance, props.record.appearance_comments]);
  return (
    <>
      {props.isWorkshopFile && (
        <ProductToggleGroup
          tasks={props.tasks}
          record={props.record}
          refreshWorkshopFileData={props.refreshWorkshopFileData}
        />
      )}
      <ProductGeneralInputs record={props.record} />
      <Typography variant="body2" color="secondary.contrastText" className={classes.root}>
        Visuelles
      </Typography>
      <Box display="flex" width="100%">
        <Box width="320px">
          <SelectInput
            label="Apparence"
            source="appearance"
            choices={AppearancesChoices}
            disabled={productPublished}
            fullWidth
          />
        </Box>
        <Box width="320px" marginLeft="24px">
          <TextInput
            source="color"
            label="Couleur"
            InputProps={{ disableUnderline: true }}
            disabled={productPublished}
            fullWidth
          />
        </Box>
      </Box>
      <Typography variant="body2" color="secondary.contrastText" className={classes.root}>
        Présentation de l'apparance produit
      </Typography>
      <Box>
        <TextInput
          label="Défauts / commentaires"
          source="appearance_comments"
          disabled={productPublished}
          fullWidth
          multiline
        />
        {appearancesDescription && (
          <Typography component="pre" className={classes.descriptionBlock}>
            {appearancesDescription}
          </Typography>
        )}
      </Box>
      {props.isWorkshopFile && (
        <>
          <ProductFunctionalCharacteristicsInput
            productsNomenclature={props.productsNomenclature}
            record={props.record}
          />
          <UserGuideInput product_model_id={props.record.product_model} />
          <EcommerceProductLink product={props.record} />
        </>
      )}
    </>
  );
};

export default ProductCaracteristicsInputs;
