import React from "react";

import { useCreate, useNotify, useUpdate } from "react-admin";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { makeStyles } from "@material-ui/core/styles";

import WorkshopTaskTypes from "constants/workshopTaskTypes";

const DONE = "done";
const TODO = "todo";

const getTaskState = (taskList, taskType) => {
  return taskList && taskList.find((task) => task.type === taskType);
};

const ProductToggleGroup = ({ record, tasks, refreshWorkshopFileData }) => {
  const classes = useStyles();
  const notify = useNotify();
  const photoProcessingTask = getTaskState(tasks, WorkshopTaskTypes.PHOTO_POST_PROCESSING);
  const productDataFillingTask = getTaskState(tasks, WorkshopTaskTypes.PRODUCT_DATA_FILLING);
  const prePublishingCheckTask = getTaskState(tasks, WorkshopTaskTypes.PRE_PUBLISHING_CHECK);

  const VERIFICATION_TOGGLES = [
    {
      id: "pre-publishing-toggle",
      task: prePublishingCheckTask,
      label: "Vérification pré publication",
      underlyingTasks: [
        { id: "caracteristics-toggle", task: productDataFillingTask, label: "Caractéristiques" },
        { id: "photos-toggle", task: photoProcessingTask, label: "Photos" },
      ],
      shouldBeDisplayed:
        photoProcessingTask &&
        productDataFillingTask &&
        photoProcessingTask.state === DONE &&
        productDataFillingTask.state === DONE,
    },
  ];

  const [create] = useCreate("workshop-tasks");

  const [updateTask] = useUpdate("workshop-tasks");
  const updateTaskState = (id) => {
    updateTask(
      { payload: { id, data: { state: DONE } } },
      {
        onSuccess: () => {
          notify("Tâche mise à jour");
          refreshWorkshopFileData();
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };
  const createTask = (task) => {
    create(
      { payload: { data: { workshop_file: task.workshop_file, type: task.type, state: TODO } } },
      {
        onSuccess: () => {
          notify(`Tâche mise à jour`);
          refreshWorkshopFileData();
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };

  const underlyingTasksDone = (task) => {
    return task.underlyingTasks.every((underlyingTask) => underlyingTask.task && underlyingTask.task.state === DONE);
  };

  const isDone = (toggle) => {
    return toggle.task && toggle.task.state === DONE;
  };

  const renderUnderlyingTask = (underlyingTask) => {
    return (
      <FormControlLabel
        id={underlyingTask.id}
        key={underlyingTask.id}
        disabled={record.is_online}
        onChange={() =>
          isDone(underlyingTask) ? createTask(underlyingTask.task) : updateTaskState(underlyingTask.task.id)
        }
        className={classes.toggle}
        control={<Switch checked={isDone(underlyingTask)} />}
        label={underlyingTask.label}
        labelPlacement="start"
      />
    );
  };

  const renderVerificationTask = (toggle) => {
    return (
      <FormControlLabel
        id={toggle.id}
        key={toggle.id}
        disabled={record.is_online}
        onChange={() => (isDone(toggle) ? createTask(toggle.task) : updateTaskState(toggle.task.id))}
        className={classes.toggle}
        control={<Switch checked={isDone(toggle)} />}
        label={toggle.label}
        labelPlacement="start"
      />
    );
  };

  return (
    <div className={classes.root}>
      {!record.is_online && (
        <>
          {VERIFICATION_TOGGLES.map((toggle) => {
            return toggle &&
              toggle.shouldBeDisplayed &&
              toggle.task &&
              underlyingTasksDone(toggle) &&
              isDone(toggle) ? (
              renderVerificationTask(toggle)
            ) : (
              <>
                {toggle &&
                  toggle.underlyingTasks.map((underlyingTask) => {
                    return underlyingTask.task && renderUnderlyingTask(underlyingTask);
                  })}

                {underlyingTasksDone(toggle) && !isDone(toggle) && renderVerificationTask(toggle)}
              </>
            );
          })}

          {VERIFICATION_TOGGLES.every(
            (verificationTask) =>
              verificationTask &&
              verificationTask.task &&
              isDone(verificationTask) &&
              underlyingTasksDone(verificationTask)
          )}
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    borderBottom: "1px solid #DBDFE8",
    margin: theme.spacing(1, 0, 3, 0),
    padding: theme.spacing(0, 0, 1, 0),
  },
  toggle: {
    margin: theme.spacing(0, 2, 1, 0),
  },
  toggleRa: {
    flexDirection: "row",
    "& > label": {
      flexDirection: "row-reverse",
      margin: theme.spacing(0, 2, 1, 0),
    },
  },
}));

export default ProductToggleGroup;
