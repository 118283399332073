import React from "react";

import { Box, TextField } from "@material-ui/core";

import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik";
import { CustomerFileDetailed } from "types/customer-files";
import { RepairReportCheck } from "types/repair-report-checks";
import * as yup from "yup";

import Button from "components/atoms/Button";
import { BodyBold } from "components/atoms/Typography";

import { TASK_STATE_TODO } from "constants/task";

import { useRepairReportChecks } from "utils/api";

type FormValues = {
  task: number | null;
  comment: string;
};
type OpinionFormProps = {
  submitOpinion: (values: FormValues) => void;
  customerFile: CustomerFileDetailed;
  productId: number;
};
const OpinionForm: React.FunctionComponent<OpinionFormProps> = ({
  submitOpinion,
  productId,
  customerFile,
}: OpinionFormProps) => {
  const { data: repairReportChecks } = useRepairReportChecks(customerFile.id, TASK_STATE_TODO);
  const relatedTask =
    repairReportChecks &&
    repairReportChecks
      .filter((check: RepairReportCheck) => check.product === productId)
      .slice(-1)
      .pop();
  const initialValues: FormValues = {
    task: relatedTask ? relatedTask.id : null,
    comment: "",
  };

  const validationSchema = yup.object().shape({
    task: yup.number().required(),
    comment: yup.string().required("Le commentaire est obligatoire"),
  });

  return relatedTask ? (
    <Formik initialValues={initialValues} onSubmit={submitOpinion} validationSchema={validationSchema}>
      {({ isSubmitting }) => (
        <Form translate={undefined}>
          <Field name="comment">
            {({ field }: FieldProps) => (
              <Box mt={2} width="100%">
                <BodyBold>Avis du chef d'équipe</BodyBold>
                <Box my={1}>
                  <TextField fullWidth variant="outlined" multiline rows={5} {...field} />
                  <Box color="red">
                    <ErrorMessage name="comment" />
                  </Box>
                </Box>
              </Box>
            )}
          </Field>
          <Button disabled={isSubmitting} version="fancy" dimension="small" type="submit">
            Envoyer l'avis
          </Button>
        </Form>
      )}
    </Formik>
  ) : null;
};
export default OpinionForm;
